import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileService, LoggerService, MoneyAccount, MoneyService, SectionReport } from '@rollit/shared/data';
import { ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SuperannuationService } from '../../superannuation.service';
import { SCSS_VARS } from '@rollit/shared';
import * as moment_ from 'moment';
import { Router } from '@angular/router';
const moment = moment_;


@Component({
  selector: 'app-superannuation-hero',
  templateUrl: './superannuation-hero.component.html',
  styleUrls: ['./superannuation-hero.component.scss']
})
export class SuperannuationHeroComponent implements OnInit {
  @Input() acc: MoneyAccount;
  @Input() propertyListId: number;
  @Input() propertyCount: number;
  @Input() inView: boolean;
  @Output() clickNext = new EventEmitter();
  @Output() clickPrev = new EventEmitter();
  @Output() expandedProjections = new EventEmitter();

  log: any;
  expanded = false;
  destroy = new Subject();
  isDesktop: boolean;
  imagePath: string;

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Super balance',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: false
    },
    {
      title: 'Retirement balance',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: false
    }
  ];

  projections: any;
  projectionChartData = [];
  projectionChartCategories = [];
  projectedRetirementBalance: number = 0;
  projectedRetirementAge: number = 0;

  constructor(
    private logger: LoggerService,
    private dialog: MatDialog,
    private moneyService: MoneyService,
    private superService: SuperannuationService,
    private fileService: FileService,
    private uiService: UIManagerService,
    private router: Router,
  ) {
    this.log = this.logger.info('SuperannuationHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.setupPropertyImage();
    this.setupProjections();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }


  setupPropertyImage() {

    // TODO: Handle multiple image, requires a carousel

    this.moneyService.getAccountImages(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(accountImagesResult => {
      console.log('getAccountImages', accountImagesResult);
      if (accountImagesResult && accountImagesResult.length > 0) {
        this.fileService.getFilePath(accountImagesResult[0]['id']).pipe(takeUntil(this.destroy)).subscribe(
          path => {
            this.imagePath = path;
          }
        );
      } else {
        this.imagePath = "/assets/images/default/default_loan.jpg"
      }
    });
  }



  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.acc.currentBalance;
    this.titleGroupDetails[1]['data'] = this.projectedRetirementBalance;
  }

  closest(array, num) {
    let minDiff = 1000;
    let ans;
    array.forEach((nameVal, i) => {
      nameVal.values.push(null);
      const m = Math.abs(num - array[i].label);
      if (m < minDiff) {
        minDiff = m;
        ans = i;
      }
    });
    return ans;


  }

  setupProjections() {
    this.superService.getAccountProjections(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(proj => {
      this.log('Got projections', proj);


      this.projections = proj;
      const projections = proj;

      this.projectedRetirementBalance = projections.extra[0].balance;
      this.log('projectedRetirementBalance', this.projectedRetirementBalance, projections.extra[0].balance);

      this.projectedRetirementAge = projections.extra[0].age;
      this.log('projectedRetirementAge', this.projectedRetirementAge, projections.extra[0].age);

      const colorArray = [SCSS_VARS['$rollit-property'], SCSS_VARS['$rollit-growth']];
      const chartCategoriesMap = [
        { short: 'PROP', section: 'property', title: 'Property' },
        { short: 'MTG', section: 'property', title: 'Mortgage' },
        { short: 'RTM', section: '', title: 'Retirement' },
      ];



      // add retirement series
      projections.names.push('Retirement');
      const goalDate = projections.extra[0].age;
      const closestRetirementDate = this.closest(projections.data, goalDate);
      this.log('closestRetirementDate', closestRetirementDate);
      let retirementPosition = 0;
      if (+projections.data[closestRetirementDate].label > +goalDate) {
        retirementPosition = -0.2;
      } else if (+projections.data[closestRetirementDate].label < +goalDate) {
        retirementPosition = 0.2;
      }
      this.log('closestRetirementDate', projections.data[closestRetirementDate].label);
      //projections.data[closestRetirementDate].values[1] = projections.extra[0].balance;
      this.log('closestRetirementDate array', projections.data[3].values);

      var tempObj = {
        "from": "2037-05-01T00:00:00+10:00",
        "to": "2045-05-01T00:00:00+10:00",
        "label": goalDate,
        "values": [
          0,
          projections.extra[0].balance
        ]
      };

      projections.data.splice(closestRetirementDate + 1, 0, tempObj);

      projections.data.splice(-1, 1);

      const temp_array = [];

      projections.names.forEach((nameVal, nameIndex) => {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            this.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
          }
        });

        if (nameVal === 'Retirement') {
          temp_object['type'] = 'column';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
          //temp_object['pointWidth'] = 10;
          //temp_object['pointPlacement'] = retirementPosition;
          //temp_object['pointRange'] = 1;
          temp_object['custom'] = { retirementAge: goalDate, type: 'retirement' };
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        this.log(nameVal, nameIndex, colorArray[nameIndex]);

        projections.data.forEach((dataVal, dataIndex) => {
          this.projectionChartCategories.push(projections.data[dataIndex].label);
          temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
        });

        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      this.log(temp_array);

      this.projectionChartData = temp_array;
      this.log('this.chartData', this.projectionChartData);
      this.setupTitleGroupDetails();
    });

  }


  // User actions

  graphClicked(category, name): void {
    this.log(category, name);
  }

  expandClicked() {
    this.expanded = !this.expanded;
    this.expandedProjections.emit(this.expanded);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  uploadComplete(file) {
    this.log('upload complete', file);
    this.moneyService.setAccountImages(this.acc.id, [file]).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('uploadComplete', result);
    });
  }

  onEditAccount(accountId: number) {
    this.superService.startUpdateAccount(this.acc.id);
  }

  onRemoveAccount() {
    this.log('Remove account clicked');
    this.openRemovePropertyConfirmationDialog(this.acc.id.toString());
  }

  openRemovePropertyConfirmationDialog(accountId: string) {
    this.log('Delete dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove account',
        copy: 'Are you sure you want to remove this account?',
        buttonMessage: 'Yes, remove account',
        type: 'Loan',
        showCancel: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result[0] === 'accept') {
        this.removeAccount(accountId);
      }
    });
  }

  removeAccount(propertyId: string): void {
    this.log('Remove property init');
    this.moneyService.removeAccount(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(account => {
      this.log('Property removed', account);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/superannuation']));
    });
  }

  scrollPrev() {
    this.clickPrev.next();
    this.log('clickPrev');
  }

  scrollNext() {
    this.clickNext.next();
    this.log('scrollNext');
  }

  onSendDocs(account: MoneyAccount) {
    this.log('sending docs for super account: ', account);
    const optionId: number = account.properties['super'].optionId;
    this.superService.sendDocs({ id: optionId });
  }

}
