import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { InputComponent } from '../input.component';
import { LoggerService, TypeDef, NumberFormat, NumberTypeDef } from '@rollit/shared/data';
import { InputNumberComponent } from '../input-number/input-number.component';

/**
 * TODO show textarea in case of long text.
 */
@Component({
  selector: 'lib-input-sum',
  templateUrl: './input-sum.component.html',
  styleUrls: ['./input-sum.component.scss'],
})
export class InputSumComponent extends InputComponent implements AfterViewInit {
  log: any;
  formControl: FormControl = new FormControl();
  type = "text";
  length = 100;
  value: string;
  validatorList: Array<any> = [];
  isValid: boolean = true;
  validationMessage: string;
  firstRun = true;
  sumValue: number = 0;
  format: NumberFormat = null;
  negate = false;   // whether to negate the entered value for output

  constructor(
    private logger: LoggerService,
    private ref: ChangeDetectorRef,
  ) {
    super();
    this.log = this.logger.info("sumInputComponent");
  }

  ngAfterViewInit() {
    this.getData();
  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log("Setup sum input with def", typeDef);
    const validators = [];
    const def: NumberTypeDef = typeDef as NumberTypeDef;

    this.format = NumberFormat[def.format];    // TODO handle different number formats
    this.negate = def.negate === true;
  }

  handleData(data: any) {
    const value = this.toDisplayNumber(data);
    this.log('handleData', data, value);
    this.formControl.setValue(value);
    this.sumValue = value;
  }

  getData() {
    let sum = 0;
    if (this.context) {
      this.context.answerComponents.forEach(c => {
        if (c.inputComponent instanceof InputNumberComponent) {
          const input = c.inputComponent as InputComponent;
          sum += input.data;
        }
      });
    }
    this.handleData(sum);
    return sum;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

  private toDisplayNumber(value: any) {
    if (typeof value === 'undefined') {
      return value;
    }
    if (this.negate) {
      value = -value;
    }
    if (this.format === NumberFormat.Percentage) {
      value = value * 100;                                        // percentage out of 100.
      value = Math.round((value + Number.EPSILON) * 100) / 100;    // round to 2 decimal places
    }

    return value;
  }

}
