import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { APP_ENVIRONMENT } from "@rollit/shared";
import { User } from "../../model/user";
import { Observable } from "rxjs";
import { ResultList } from '../../model/result';


export interface CommsLogEntry {
    timestamp?: string;
    user?: User;
    to?: string;       // The address comms sent to
    subject?: string;
    content?: string;
}

/**
 * Admin API
 */
@Injectable()
export class CommsLogService {
    apiUrl = this.environment.apiUrl;
    constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient) {
    }

    /**
     *
     */
    public getLogs(userId: number, offset: number, max: number): Observable<ResultList<CommsLogEntry>> {
        const path = this.apiUrl + '/admin/comms';
        let params = new HttpParams()
            .set('offset', '' + offset)
            .set('max', max == null ? '100' : max.toString());
        if (userId) {
            params = params.set("user", "" + userId);
        }

        return this.http.get<ResultList<CommsLogEntry>>(path, { params: params });
    }

    public getLog(logId: number): Observable<CommsLogEntry> {
        const path = this.apiUrl + '/admin/comms/' + logId;

        return this.http.get<CommsLogEntry>(path);
    }
}
