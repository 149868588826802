<nav class="mat-tab-nav-bar bottom-nav"
  [ngClass]="{'inverse': backgroundIsBlue, 'desktop': isDesktop, 'hasContent':navigationLinks.length > 0}"
  *ngIf="isVisible && navigationLinks">
  <div class="mat-tab-links">
    <a *ngFor="let item of navigationLinks" id="nav-{{item.title | lowercase}}" (click)="$event.preventDefault()"
      [fxFlex]="itemWidth" class="mat-tab-link footer" [routerLink]="item.rActiveLink ? item.rActiveLink : item.rLink"
      routerLinkActive="active" [routerLinkActiveOptions]="{exact: item.exact}">
      <span (click)="onNavClick(item, $event)" fxLayout="column"
        fxLayoutAlign="center center">
        <i *ngIf="item.rLink === 'chat' && hasNotifications" class="notification-icon"></i>
        <ng-container *ngIf="item.rLink === 'chat'; then chatItem; else nextItem"></ng-container>
        <ng-template #chatItem>
          <ng-container *ngIf="intercomOpen; then iShow; else iHide"></ng-container>
          <ng-template #iShow>
            <i class="material-icons">chat</i>
          </ng-template>
          <ng-template #iHide>
            <i class="material-icons">{{item.icon}}</i>
          </ng-template>
        </ng-template>
        <ng-template #nextItem>
          <ng-container *ngIf="item.rLink === 'filter';then filterItem; else normalItem"></ng-container>
        </ng-template>
        <ng-template #filterItem>
          <span class="icon-filter filter-icon"></span>
        </ng-template>
        <ng-template #normalItem>
          <i class="material-icons">{{item.icon}}</i>
        </ng-template>
        <span>{{item.title}}</span>
      </span>
    </a>
  </div>
</nav>
