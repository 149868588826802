import { Injectable } from '@angular/core';
import { DialogService, Fact, Goal, LoggerService, MoneyAccount, MoneyService, Nudge, NudgeService, NudgeStatus, ReportService, ResultList, SectionReport, ValueType } from '@rollit/shared/data';
import { Observable } from 'rxjs';
import * as moment_ from 'moment';
import { Router } from '@angular/router';

const moment = moment_;

@Injectable()
export class DashboardService {
  log: any;
  sessionId: string;    // survey session

  constructor(
    private moneyService: MoneyService,
    private nudgeService: NudgeService,
    private dialogService: DialogService,
    private reportService: ReportService,
    private router: Router,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('dashboardService');
  }

  /**
   * Determine the user's financial position. Balance of assets and borrowings, and net position.
   * Will also provide movement ("Growth") since last month.
   */
  getPosition(): Observable<any> {
    return this.moneyService.getBalances("2");
  }

  /**
   * Fetch overall projections from the web service.
   */
  getProjections(period): Observable<any> {
    const category = null;
    const from = moment().startOf('month');
    //period = null; //'P3Y';     // TODO shorten this period for older users.  Examples: P1Y, P2Y, P5Y, P1M, P6M
    const numPeriods = 10;    // TODO should not go beyond reasonable life expectancy

    return this.moneyService.getProjections(category, from, period, numPeriods);
  }

  /**
   * Fetch summary data for a section.
   * 
   * @param section 
   */
  getSectionSummary(section: string): Observable<SectionReport> {
    const from = moment().startOf('month');
    return this.reportService.getSectionSummary(section, from);
  }

  getSectionSummaries(): Observable<SectionReport>[] {
    const sections = ['savings', 'lifestyle', 'investments', 'superannuation', 'property', 'loans'];
    const result = [];
    sections.forEach(section => {
      result.push(this.getSectionSummary(section));
    });
    return result;
  }

  /**
   * Fetch a nudge
   * @returns 
   */
  getNudges(): Observable<ResultList<Nudge>> {
    return this.nudgeService.getNudges();
  }

  /**
   * Dismiss a nudge
   * @param nudge 
   * @returns 
   */
  dismissNudge(nudge: Nudge): Observable<Nudge> {
    nudge.status = NudgeStatus.dismissed;
    return this.nudgeService.updateNudge(nudge.id, nudge);
  }

  /**
   * Fetch goals from the web service.
   * 
   * @param section The section to query goals for
   * @param offset Index of the first goal to fetch
   * @param max  The maximum number of goals to return
   * @returns 
   */
  getGoals(section?: string, offset = 0, max = 100): Observable<ResultList<Goal>> {
    const sections: string[] = section ? [section] : null;
    return this.moneyService.getGoals(sections, offset, max);
  }

  /**
   * Remove a goal.
   * 
   * @param goalId 
   * @returns 
   */
  deleteGoal(goalId: number): Observable<void> {
    return this.moneyService.deleteGoal(goalId);
  }

  /**
   * Begin a survey to create a new goal.
   * 
   * @param category 
   */
  startAddGoal(category: string) {
    const dialog = "new";    // just a test dialog at this point
    const facts: Fact[] = [];   // TODO add category in initial facts
    const returnPath = this.router.url;
    this.startNewSurvey(dialog, facts, returnPath, '_first');
  }

  /**
   * Begina survey to edit a goal.
   * 
   * @param goalId 
   */
  startUpdateGoal(goalId: number) {
    // TODO get goal category from goal
    const dialog = 'edit';    // just a test dialog at this point
    const facts: Fact[] = [
      { slug: 'goal_id', type: ValueType.Long, value: goalId, '@type': 'fact' },  // the goal ID fact
      { slug: 'goal_edit_type', type: ValueType.Select, value: 'goal', '@type': 'fact' },  // edit the goals, not progress
    ];
    const returnPath = this.router.url;
    this.startNewSurvey(dialog, facts, returnPath);
  }

  private startNewSurvey(dialog: string, facts: Fact[], returnPath: string, start?: string) {
    this.sessionId = this.dialogService.generateSessionId();
    let queryParams = { 'session': this.sessionId, 'return': returnPath }
    if (start) {
      queryParams['start'] = start;
    }
    // initialise dialog with existing facts
    this.dialogService.initDialog('goals', dialog, facts, this.sessionId).subscribe(result => {
      // redirect to survey path
      this.router.navigate(['survey', 'goals', dialog], { queryParams });
    });
  }

  /**
   * Fetch a list of pending accounts.
   * 
   * @returns 
   */
  public getPendingAccounts(): Observable<MoneyAccount[]> {
    return this.moneyService.getPendingAccounts();
  }
}