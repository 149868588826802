import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Budget, BudgetProgress, DialogService, Fact, LoggerService, MoneyAccount, MoneyService, Period, ReportService, SavingsOverview, TimeIntervalTable, ValueType } from '@rollit/shared/data';
import { Moment } from 'moment';
import * as moment_ from 'moment';
import { Observable } from 'rxjs';

const moment = moment_;

@Injectable()
export class SavingsService {
  log: any;
  sessionId: string;

  constructor(
    private moneyService: MoneyService,
    private reportService: ReportService,
    private dialogService: DialogService,
    private router: Router,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('SavingsService');
  }

  getSavings(): Observable<MoneyAccount[]> {
    return this.moneyService.getAccounts({ type: 'savings' });
  }

  getSavingsProjections(): Observable<TimeIntervalTable> {
    const category = 'savings';
    return this.moneyService.getProjections(category);
  }
  
  /**
   * Fetch projections for property account.
   * 
   * @param accountId 
   */
  getAccountProjections(accountId: number): Observable<TimeIntervalTable> {
    const from = moment().startOf('month');
    return this.moneyService.getAccountProjections(accountId, from);
  }

  /**
   * Fetch an overview of user's savings.  Includes budgets, income details, annual amounts, projections.
   */
  getSummary(): Observable<SavingsOverview> {
    const from = moment().startOf('month');
    return this.reportService.getSavingsOverview(from);
  }

  /**
   * Fetch monthly budget progress for the month starting from 'from'
   * @param from start of the month of interest.
   */
  getBudgetProgress(from: Moment = null): Observable<BudgetProgress> {
    return this.moneyService.getBudgetProgress(from);
  }

  /**
   * Fetch monthly income budget items.
   * Budget item names will be:
   * - income_individual: For the user's net monthly income
   * - income_partner: For user partner's net monthly income
   * - income_other: For other monthly income.
   */
  getIncomeBudgets(): Observable<Budget[]> {
    return this.moneyService.getBudgets('income', Period.year);
  }

  /**
   * Start a dialog to update realty and mortgage accounts. 
   * 
   * @param id The ID of the existing property account.
   */
  startUpdateBudget() {
    const facts: Fact[] = [];
    const returnPath = this.router.url;
    this.startNewSurvey('track', 'savings', facts, returnPath, true);
  }

  /**
   * Start an income update dialog.
   */
  startUpdateIncome() {
    // facts to ensure partner and other income questions are shown
    const facts: Fact[] = [
      { slug: "savings_selection", value: "budget", type: ValueType.Select, '@type': 'fact' },
      { slug: "budget_selection",  value: "income", type: ValueType.Select, '@type': 'fact' }
    ];
    const returnPath = this.router.url;
    this.startNewSurvey('track', 'income', facts, returnPath, true);
  }

  /**
   * Start dialog to create a new savings goal.
   */
  startNewGoal() {
    this.log('starting new savings goal');
    const facts: Fact[] = [{slug: 'goal_category', type: ValueType.String, value: 'savings', '@type': 'fact'}];
    const returnPath = this.router.url;
    this.startNewSurvey('goals', 'new', facts, returnPath);
  }

  /**
   * Start a edit goal dialog
   */
   startEditGoal(goalId: number) {
    const facts: Fact[] = [{slug: 'goal_id', type: ValueType.Long, value: goalId, '@type': 'fact'}];
    const returnPath = this.router.url;
    this.startNewSurvey('goals', 'edit', facts, returnPath, true);
  }

  /**
   * Navigate to a survey after setting some facts in a new session.
   * 
   * @param module 
   * @param dialog 
   * @param facts 
   * @param returnPath 
   */
  private startNewSurvey(module: string, dialog: string, facts: Fact[], returnPath: string, first? : boolean) {
    this.sessionId = this.dialogService.generateSessionId();
    let queryParams = { 'session': this.sessionId, 'return': returnPath };
    if (first) {
      queryParams['start'] = '_first';
    }

    // initialise dialog with existing facts
    this.dialogService.initDialog(module, dialog, facts, this.sessionId).subscribe(result => {
      // redirect to survey path
      this.router.navigate(['survey', module, dialog], { queryParams });
    });
  }

}
