import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';

@Component({
  selector: 'lib-home-situation-state-percentage-chart',
  templateUrl: './home-situation-state-percentage-chart.component.html',
  styleUrls: ['./home-situation-state-percentage-chart.component.scss']
})
export class HomeSituationStatePercentageChartComponent implements OnInit {
  log: any;
  @Input() assetsHomeOwnershipStatePercentage: number = 0;
  @Input() assetsHomeOwnershipStatePercentageResult: number;
  @Input() profileAddressState: string;
  homeOwnershipStatePercentageFinal = 0;
  homeOwnershipStatePercentageAnim = 0;
  homeOwnershipStatePercentageCountUp = 0;
  homeOwnershipStatePercentageMaskFinal = 0;
  homeOwnershipStatePercentageMaskAnim = 0;
  mapMaskXPos = 0;

  constructor(private logger: LoggerService,) {
    this.log = this.logger.info('HomeSituationStatePercentageChartComponent');
  }

  ngOnInit() {
    if (this.profileAddressState === 'WA') {
      this.mapMaskXPos = 18;
      this.homeOwnershipStatePercentageMaskFinal = Math.round(this.assetsHomeOwnershipStatePercentage * 65);
    } else if (this.profileAddressState === 'NT') {
      this.mapMaskXPos = 20;
      this.homeOwnershipStatePercentageMaskFinal = Math.round(this.assetsHomeOwnershipStatePercentage * 60);
    } else if (this.profileAddressState === 'QLD') {
      this.mapMaskXPos = 15;
      this.homeOwnershipStatePercentageMaskFinal = Math.round(this.assetsHomeOwnershipStatePercentage * 69);
    } else {
      this.homeOwnershipStatePercentageMaskFinal = Math.round(this.assetsHomeOwnershipStatePercentage * 100);
    }

    this.homeOwnershipStatePercentageFinal = Math.round(this.assetsHomeOwnershipStatePercentage * 100);
    /*
        setTimeout(() => {
          this.homeOwnershipStatePercentageAnim = this.homeOwnershipStatePercentageFinal;
          this.animateValue(this.homeOwnershipStatePercentageCountUp, this.homeOwnershipStatePercentageFinal, 200);
    
          this.homeOwnershipStatePercentageMaskAnim = this.homeOwnershipStatePercentageMaskFinal;
          this.animateValue(this.homeOwnershipStatePercentageCountUp, this.homeOwnershipStatePercentageMaskFinal, 200);
        }, 100);
    */

  }

  animateValue(start, end, duration) {
    //this.log(start, end);
    if (!Number.isNaN(start) && !Number.isNaN(end)) {
      if (start === end) {
        return;
      }
      const range = end - start;
      let current = start;
      const increment = end > start ? 1 : -1;
      const stepTime = Math.abs(Math.floor(duration / range));
      this.log(increment, stepTime);
      const self = this;
      const timer = setInterval(function () {
        current += increment;
        // this.log(current);
        self.homeOwnershipStatePercentageCountUp = current;
        if (current === end) {
          clearInterval(timer);
        }
      }, stepTime);
    }
  }
}
