import { Component, Input, OnInit } from '@angular/core';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { Fund, FundService, InvestmentOption, LoggerService } from '@rollit/shared/data';

@Component({
  selector: 'app-superannuation-fund-detail',
  templateUrl: './superannuation-fund-detail.component.html',
  styleUrls: ['./superannuation-fund-detail.component.scss']
})
export class SuperannuationFundDetailComponent implements OnInit {
  @Input() fundId: number;
  fund: InvestmentOption;
  log: any;

  constructor(
    private logger: LoggerService,
    private fundService: FundService,) {
    this.log = this.logger.info('SuperannuationFundDetailComponent');
  }

  ngOnInit(): void {
    this.log('fundId', this.fundId);
    this.getInvestmentOption(this.fundId);
  }

  getInvestmentOption(fundId) {

    this.fundService.getInvestmentOption(fundId).subscribe(investmentOption => {
      this.log('getInvestmentOption', investmentOption);
      this.fund = investmentOption;
    });
  }
}
