import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Budget, BudgetCategoryProgress, BudgetProgress, FileService, LoggerService, MoneyAccount, MoneyService, TimeIntervalTable } from '@rollit/shared/data';
import { BudgetElement, ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavingsService } from '../../savings.service';
import { SCSS_VARS } from '@rollit/shared';
import * as moment_ from 'moment';
import { Router } from '@angular/router';
const moment = moment_;


@Component({
  selector: 'app-savings-hero',
  templateUrl: './savings-hero.component.html',
  styleUrls: ['./savings-hero.component.scss']
})
export class SavingsHeroComponent implements OnInit, OnDestroy {
  @Input() budgets: BudgetProgress;
  @Input() projection: TimeIntervalTable;
  @Input() income: any;
  @Input() budget: any;
  @Input() balance: number;   // savings balance

  budgetsLastMonth: BudgetCategoryProgress[];
  log: any;
  expanded = false;
  destroy = new Subject();
  isDesktop: boolean;
  imagePath: string;
  budgetArray: BudgetElement[] = [];
  now = moment();
  selectedMonth = moment();
  dateDetails: string;
  yodleeConnection: boolean;

  budgetCategories = [
    {
      name: 'Income',
      icon: 'favorite',
      color: '#05A8DB',
    },
    {
      name: 'Living',
      icon: 'shopping_cart',
      color: '#A31B5C',
    },
    {
      name: 'Lifestyle',
      icon: 'tv',
      color: '#DB0A6E',
    },
    {
      name: 'Housing',
      icon: 'home',
      color: '#761744',
    },
    {
      name: 'Savings',
      icon: 'account_balance',
      color: '#05A8DB',
    },
  ];
  budgetCategoryMap = [
    { slug: 'income_individual_gross', name: 'Individual gross income' },
    { slug: 'income_individual', name: 'Individual income' },
    { slug: 'income_partner_gross', name: 'Partner gross income' },
    { slug: 'income_partner', name: 'Partner income' },
    { slug: 'income_other_gross', name: 'Gross income' },
    { slug: 'income_other', name: 'Other income' },
    { slug: 'expenses_living', name: 'Living expenses' },
    { slug: 'expenses_lifestyle', name: 'Lifestyle expenses' },
    { slug: 'savings', name: 'Savings' },
    { slug: 'payment_loan_vehicle', name: 'Vehicle loans' },
    { slug: 'payment_loan_vehicle_other', name: 'Other Vehicle loans' },
    { slug: 'payment_loan_lifestyle', name: 'Lifestyle loans' },
    { slug: 'payment_creditcard', name: 'Creditcards' },
    { slug: 'payment_loan_personal', name: 'Personal loans' },
    { slug: 'payments_debt_property', name: 'Mortgage' },
    { slug: 'payment_mortgage_home', name: 'Mortgage' },
    { slug: 'payment_mortgage_other', name: 'Other Mortgage' },
    { slug: 'expenses_rent', name: 'Rent' },
    { slug: 'income_total', name: 'Total income' },
    { slug: 'expenses_housing', name: 'Household expenses' },
  ];

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Savings last month',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true
    },
    {
      title: 'Savings this year',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true
    }

  ];


  projectionChartData = [];
  projectionChartCategories = [];
  projectedMortgageEndDate: any;
  projectedMortgageEndAge: any;

  constructor(
    private logger: LoggerService,
    private dialog: MatDialog,
    private moneyService: MoneyService,
    private savingsService: SavingsService,
    private fileService: FileService,
    private uiService: UIManagerService,
    private router: Router,
  ) {
    this.log = this.logger.info('SavingsHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('budgets', this.budgets);
    this.log('budget', this.budget);
    this.log('income', this.income);
    this.log('projection', this.projection);

    this.setupProjections();
    this.setupBudgets();
    this.getYodleeStatus();

    const a = moment().endOf('month');
    const b = moment();

    this.dateDetails = this.now.format('M Y') === this.selectedMonth.format('M Y') ? a.diff(b, 'days') + ' days left in<br> the month' : '';



  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  getYodleeStatus() {
    this.moneyService.getYodleeProfile().pipe(takeUntil(this.destroy))
      .subscribe((result) => {
        this.log('getYodleeStatus', result);
        if (result.id) {
          this.yodleeConnection = true;
        }
      });
  }

  getLastMonthBudget() {
    const pastMonth = moment(this.selectedMonth).subtract(1, 'months').startOf('month');
    this.savingsService.getBudgetProgress(pastMonth).subscribe(result => {
      this.log('Got budget progress budgetsLastMonth', result);
      this.budgetsLastMonth = result.items.filter(i => i.category.slug === 'savings');
      this.log('Got filter budgetsLastMonth', this.budgetsLastMonth);
      this.setupTitleGroupDetails();
    });
  }

  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.budgetsLastMonth[0].progress;
    this.titleGroupDetails[0]['hide'] = false;
    this.titleGroupDetails[1]['data'] = this.budget.annual.totals.actual.savings;
    this.titleGroupDetails[1]['hide'] = false;
  }

  setupBudgets() {
    this.budgetArray = [];
    this.budgets.items.forEach((item: BudgetCategoryProgress) => {

      let budgetColor = '#A31B5C';
      const budgetItems = [];

      item.items.forEach((itemVal) => {
        const obj = {
          title: itemVal.name, value: itemVal.progress
        };
        budgetItems.push(obj);
      });

      this.budgetCategories.forEach((budgetCat) => {
        if (budgetCat.name === item.category.name) {
          budgetColor = budgetCat.color;
        }
      });

      const budgetItem: BudgetElement = {
        name: item.category.name,
        current: item.progress,
        budget: item.total,
        color: budgetColor,
        items: budgetItems
      };

      this.budgetArray.push(budgetItem);
      this.log('setupBudgets', this.budgetArray);
    });

  }


  setupProjections() {
    const projections = this.projection;
    this.log('projections', this.projection);
    this.log('projections', this.projection);
    this.log('projections', this.projection);

    const colorArray = [SCSS_VARS['$rollit-savings'], SCSS_VARS['$rollit-mortgage'], SCSS_VARS['$rollit-growth']];
    const chartCategoriesMap = [
      { short: 'PROP', section: 'property', title: 'Property' },
      { short: 'MTG', section: 'property', title: 'Mortgage' },
      { short: 'NET', section: '', title: 'Net' },
    ];
    const temp_array = [];

    projections.names.forEach((nameVal, nameIndex) => {
      const temp_object = {};
      const temp_data_array = [];

      temp_object['name'] = nameVal;
      chartCategoriesMap.forEach((chartVal, chartIndex) => {
        if (nameVal === chartVal.title) {
          this.log(nameVal + '===' + chartVal.title);
          temp_object['section'] = chartVal.section;
        }
      });

      if (nameVal === 'Net') {
        temp_object['type'] = 'spline';
        temp_object['borderColor'] = 'white';
        temp_object['borderWidth'] = 0.2;
        temp_object['zIndex'] = 5;
      } else {
        temp_object['type'] = 'column';
      }


      temp_object['color'] = colorArray[nameIndex];

      this.log(nameVal, nameIndex, colorArray[nameIndex]);

      projections.data.forEach((dataVal, dataIndex) => {
        this.projectionChartCategories.push(projections.data[dataIndex].label);
        temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
      });

      temp_object['data'] = temp_data_array;
      temp_array.push(temp_object);


    });
    this.log(temp_array);

    this.projectionChartData = temp_array;
    this.log('this.chartData', this.projectionChartData);
    this.getLastMonthBudget();



  }

  // User actions
  graphClicked(category, name): void {
    this.log(category, name);
  }

  expandClicked() {
    this.expanded = !this.expanded;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  getBudgetProgress(from) {
    // fetch budget progress
    this.savingsService.getBudgetProgress(from).subscribe(result => {
      this.log('Got budget progress', result);
      this.budgets = result;
      this.setupBudgets();
    });
  }

  onIncomeEdit() {
    this.savingsService.startUpdateIncome();
  }

  onBudgetEdit() {
    this.savingsService.startUpdateBudget();
  }

  onAccounts() {

    this.router.navigate(['/savings/accounts']);

  }

  onTransactions() {
    this.router.navigate(['/savings/transactions']);
  }

  scrollPrev() {
    // this.clickPrev.next();
    const pastMonth = moment(this.selectedMonth).subtract(1, 'months');
    this.selectedMonth = moment(pastMonth).startOf('month');
    this.getBudgetProgress(this.selectedMonth);
    const a = moment().endOf('month');
    const b = moment();
    this.dateDetails = this.now.format('M Y') === this.selectedMonth.format('M Y') ? a.diff(b, 'days') + ' days left in<br> the month' : '';
    this.log('clickPrev', this.now, this.selectedMonth);
  }

  scrollNext() {
    if (this.selectedMonth.format('MM-YYYY') !== this.now.format('MM-YYYY')) {
      const futureMonth = moment(this.selectedMonth).add(1, 'months');
      this.selectedMonth = moment(futureMonth).startOf('month');
      const a = moment().endOf('month');
      const b = moment();
      this.dateDetails = this.now.format('M Y') === this.selectedMonth.format('M Y') ? a.diff(b, 'days') + ' days left in<br> the month' : '';
      this.log('clickNext', this.now, this.selectedMonth);
      this.getBudgetProgress(this.selectedMonth);
    }
  }

  updateDate(event) {
    this.log(event);
    this.selectedMonth = moment(event);
    this.log('clickNext', this.now, this.selectedMonth);
    this.getBudgetProgress(this.selectedMonth);
  }
}
