import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService, MeService, MoneyAccount, User, } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvestmentsService } from '../investments.service';
import { APP_ENVIRONMENT } from "@rollit/shared";

interface PortfolioDetails {
  nickname?: string;
  accountSubtype?: string;
  currentBalance?: number;
  annualReturn?: number;
  contributions?: number;
  growth?: number;
  accounts?: Array<MoneyAccount>;
  id?: number;
  timeCreated?: Date;

}
@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop = false;
  private isDestroyed = new Subject();
  sliding = false;
  sectionValue: Subject<string> = new Subject();
  totalAccountValue = 0;
  currentSlide = 0;
  currentViewSlide = 0;
  businessAccountCount = 0;
  investmentAccountCount = 0;
  expanded = false;
  useLatest = false;
  ready: boolean;
  mobileAnimating: boolean;
  me: User;

  portfolioDetails: PortfolioDetails = {
    nickname: 'Investment portfolio',
    accountSubtype: "portfolio",
    currentBalance: 0,
    annualReturn: 0,
    contributions: 0,
    growth: 0,
    accounts: [],
    id: 0
  };
  accounts: any[] = [];
  activeRoute = 'property';

  accountSubTypes = ['investment', 'business'];

  constructor(
    private investmentsService: InvestmentsService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: MeService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.log = this.logger.info('InvestmentsComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });

    this.userService.me$.pipe(takeUntil(this.isDestroyed)).subscribe(
      value => {
        if (value) {
          this.me = value;
          this.log(this.me);
        }
      }
    );


  }

  ngOnInit() {
    this.investmentsService.getAccounts().pipe(takeUntil(this.isDestroyed)).subscribe(result => {
      // this.accounts = result;
      const businessAccounts = [];
      let investmentReturn = 0;
      let currentBalance = 0;
      result.forEach((ac) => {
        if (ac.accountSubtype !== "business") {
          this.log('investmentAcc', ac);
          this.investmentAccountCount++;
          currentBalance = ac.currentBalance ? ac.currentBalance : 0;
          const annualReturn = ac?.properties?.investment?.annualReturn ? ac?.properties?.investment?.annualReturn : 0.0876;
          this.portfolioDetails.currentBalance += currentBalance;
          investmentReturn += currentBalance * annualReturn;
          this.portfolioDetails.contributions += ac?.properties?.investment?.contributions ? ac?.properties?.investment?.contributions : 0;


          this.portfolioDetails.accounts.push(ac);


        } else {
          businessAccounts.push(ac);
          this.businessAccountCount++;
          this.totalAccountValue += ac.currentBalance;
        }

      });

      this.log('investmentAcc portfolioDetails.accounts', this.portfolioDetails.accounts);

      this.portfolioDetails.accounts.sort((a, b) => a.nickname < b.nickname ? -1 : a.nickname > b.nickname ? 1 : 0);

      this.log('portfolioDetails.accounts', this.portfolioDetails.accounts);

      this.portfolioDetails.id = 0;
      this.portfolioDetails.annualReturn = investmentReturn / this.portfolioDetails.currentBalance;
      const tempArray = Object.assign([], this.portfolioDetails.accounts);

      this.log('sort portfolioDetails this.accounts', tempArray, this.portfolioDetails.accounts);

      const latestObj = tempArray.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0];

      if (latestObj) {
        this.portfolioDetails.timeCreated = latestObj.timeCreated;

        this.log('sort portfolioDetails this.accounts', tempArray, this.portfolioDetails.accounts);

        this.accounts.push(this.portfolioDetails);
      }


      businessAccounts.forEach((acc) => {
        this.accounts.push(acc);
      });

      this.totalAccountValue += this.portfolioDetails.currentBalance;
      this.ready = true;
      this.checkSlideIndex();
    });

  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }


  checkSlideIndex() {
    const activeId = this.activatedRoute.snapshot.paramMap.get('id');
    // this.log('activeId', activeId);
    if (activeId === 'latest') {
      // this.log('checkSlideIndex latest yes');
      this.useLatest = true;
      this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      }).then(() => {
        this.setSlideIndex();
      });
    } else if (activeId && !Number.isNaN(+activeId)) {
      // this.log('activeId yes', +activeId);
      this.currentSlide = +activeId;
      if (!this.isDesktop) {
        this.mobileScrollToSection(this.currentSlide);
      }

    } else {
      this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  setSlideIndex() {
    const tempArray = Object.assign([], this.accounts);
    // this.log('sort this.accounts', tempArray, this.accounts);
    const latestObj = tempArray.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0];
    // this.log('sort latest', latestObj);
    const isNew = this.isNew(latestObj.timeCreated);
    // this.log('sort isNew', isNew);
    if (isNew) {
      const index = this.accounts.findIndex(x => x.id === latestObj.id);
      // this.log('sort index', index);
      // this.log('sort this.accounts', tempArray, this.accounts);
      this.currentSlide = index;
    }
  }

  isNew(createdDate) {
    const today = new Date().getTime();
    createdDate = new Date(createdDate).getTime();
    // this.log('sort', today, createdDate, today - createdDate, 5 * 60 * 1000);
    return Math.abs(today - createdDate) < 5 * 60 * 1000;
  }

  onAddInvestment() {
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/latest'], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    }).then(() => {
      this.investmentsService.startAddInvestment();
    });
  }

  onAddBusiness() {
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/latest'], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    }).then(() => {
      this.investmentsService.startAddBusiness();
    });
  }

  onEditClick(account: MoneyAccount) {
    // this.log('edit investment/business account', account);
    if (account.accountSubtype === 'investment') {
      this.investmentsService.startUpdateInvestment(account.id);
    }
    else if (account.accountSubtype === 'business') {
      this.investmentsService.startUpdateBusiness(account.id);
    }
    else {
      // unhandled account type
    }
  }

  openSixPark() {
    window.open(this.environment.sixpark.landingUrl);
  }

  expandedEvent(expanded) {
    this.expanded = expanded;
    this.onElementScroll();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    if (this.accounts.length > 0) {
      this.sectionValue.next(this.accounts[this.currentSlide].id.toString());
    }

  }

  scrollPrev() {
    this.log('scrollPrev()', this.currentSlide + ' <> ' + this.accounts.length);
    if (this.currentSlide > 0) {
      this.sliding = true;
      this.currentSlide--;
      this.sectionValue.next(this.accounts[this.currentSlide].id.toString());

    }

  }

  scrollNext() {
    this.log('scrollNext()', this.currentSlide + ' <> ' + this.accounts.length);
    if (this.currentSlide < this.accounts.length - 1) {
      this.sliding = true;
      this.currentSlide++;
      this.log(this.currentSlide);
      this.sectionValue.next(this.accounts[this.currentSlide].id.toString());

    }
  }

  animationCallback(event) {
    this.log('animationCallback', event);
    this.sliding = false;
    this.currentViewSlide = this.currentSlide;
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + this.currentViewSlide], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }


  mobileScrollToSection(slideId) {
    this.log('mobileScrollToSection', slideId);
    if (slideId > 0) {
      this.mobileAnimating = true;
      const topTargetId = this.accounts[slideId].id;
      this.log('mobileScrollToSection topTargetId', topTargetId);
      const topTarget = document.getElementById(topTargetId.toString());
      this.log('mobileScrollToSection topTarget', topTarget);

      if (topTarget !== null) {
        setTimeout(() => {
          topTarget.scrollIntoView({
            block: "start",
            behavior: 'smooth',
          });
          setTimeout(() => {
            this.mobileAnimating = false;
          }, 500);
        }, 300);
      } else {
        this.log('mobileScrollToSection not ready');
        setTimeout(() => {
          this.mobileScrollToSection(slideId);
        }, 50);
      }
    }
  }


  onElementScroll() {
    if (!this.isDesktop && !this.mobileAnimating) {
      const scrollTarget = document.getElementsByClassName("section-content")[0];
      const allSections = document.getElementsByTagName('app-investments-hero');

      let lastEl;
      for (let i = 0; i < allSections.length; i++) {
        const htmlElement = allSections[i] as HTMLElement;
        const elBottomAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > (htmlElement.offsetTop + htmlElement.offsetHeight);
        const elTopAboveBottomView = (scrollTarget.scrollTop + scrollTarget.clientHeight) > htmlElement.offsetTop;
        const elTopAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > htmlElement.offsetTop;

        if (!elBottomAboveHalfView && elTopAboveBottomView && elTopAboveHalfView) {
          this.log('htmlElement', htmlElement.id);
          lastEl = htmlElement;
          this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + i], {
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      }

      this.log('scroll', lastEl);
    }
  }

}
