import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';

@Component({
  selector: 'lib-report-cashflow-management',
  templateUrl: './cashflow-management.component.html',
  styleUrls: ['./cashflow-management.component.scss']
})
export class CashflowManagementComponent implements OnInit, OnDestroy {
  log: any;
  @Input() useQuery: boolean;
  @Input() expensesStress: string;
  expensesStressResult: string;
  @Input() expensesStressAgePercentage: string;
  expensesStressAgePercentageResult: string;
  @Input() assetsSavingsSpan: string;
  @Input() assetsSavingsSpanAgePercentage: string;
  assetsSavingsSpanAgePercentageResult: string;
  @Input() assetsSavingsAgeAvgBalance: string;
  assetsSavingsAgeAvgBalanceResult: string;

  @Input() reportText: any;

  constructor(private logger: LoggerService, private uiService: UIManagerService, private route: ActivatedRoute, private currencyPipe: CurrencyPipe, private dialogService: DialogService) {
    this.log = this.logger.info('CashflowManagementComponent');
    this.uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit(): void {
    this.log('CashflowManagementComponent useQuery', this.useQuery);
    if (this.useQuery === false) {
      this.log('CashflowManagementComponent expensesStress', this.expensesStress);
      this.log('CashflowManagementComponent expensesStressAgePercentage', this.expensesStressAgePercentage);
      this.log('CashflowManagementComponent assetsSavingsSpan', this.assetsSavingsSpan);
      this.log('CashflowManagementComponent assetsSavingsSpanAgePercentage', this.assetsSavingsSpanAgePercentage);
      this.log('CashflowManagementComponent assetsSavingsAgeAvgBalance', this.assetsSavingsAgeAvgBalance);
      this.setupData();
    } else {
      this.route.queryParams.subscribe(params => {
        this.log('params', params);
        this.expensesStress = params['expenses_stress'];
        this.expensesStressAgePercentage = params['expenses_stress_age_percentage'];
        this.assetsSavingsSpan = params['assets_savings_span'];
        this.assetsSavingsSpanAgePercentage = params['assets_savings_span_age_percentage'];
        this.assetsSavingsAgeAvgBalance = params['assets_savings_age_avg_balance'];
        this.dialogService.getReportText().subscribe(data => {
          this.reportText = data;
          this.log('getReportText', data);
          this.setupData();
        });
      });
    }
  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }

  setupData() {
    this.expensesStressResult = this.reportText.cashflow_management.expenses_stress[this.expensesStress].expenses_stress;
    this.log('this.expensesStressResult', this.reportText.cashflow_management.expenses_stress[this.expensesStress]);

    this.expensesStressAgePercentageResult =
      this.reportText.cashflow_management.expenses_stress[this.expensesStress].expenses_stress_age_percentage
        .replace("[expenses_stress_age_percentage]", Math.round(+this.expensesStressAgePercentage * 100) + '%');

    this.assetsSavingsAgeAvgBalanceResult =
      this.reportText.cashflow_management.assets_savings_span[this.assetsSavingsSpan].assets_savings_age_avg_balance
        .replace("[assets_savings_age_av_balance]", this.currencyPipe.transform(this.assetsSavingsAgeAvgBalance, '$', 'symbol', '1.0-0'));

    this.assetsSavingsSpanAgePercentageResult =
      this.reportText.cashflow_management.assets_savings_span[this.assetsSavingsSpan].assets_savings_span_age_percentage
        .replace("[assets_savings_span_age_percentage]", Math.round(+this.assetsSavingsSpanAgePercentage * 100) + '%');
  }
}
