import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, LoggerService, MeService, Progress } from '@rollit/shared/data';
import { UIManagerService, } from '@rollit/shared/services';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-setup-status',
  templateUrl: './setup-status.component.html',
  styleUrls: ['./setup-status.component.scss']
})
export class SetupStatusComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop: boolean;
  module = 'setup';
  progress: Progress[] = []; // quick setup dialog progress
  setupComplete = false;
  private isDestroyed = new Subject();
  private subscriptions: Subscription[] = [];
  listOrder = ['Profile', 'Home & Property', 'Money & Lifestyle', 'Credit Cards & Loans', 'Super & Investments'];

  constructor(
    private dialogService: DialogService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private userService: MeService,
    private router: Router,
  ) {
    this.log = this.logger.info('SetupStatusComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.topNavIsVisible.next(false);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.isDesktop = mediaSizeIsDesktop;
    });
  }

  ngOnInit(): void {

    this.dialogService.getModuleProgress('setup').subscribe(progress => {
      this.setupComplete = true;
      //this.progress = progress;
      this.listOrder.forEach((order) => {

        progress.forEach((prog: Progress) => {
          // TODO also check if dialog is compulsory or optional
          if (order === prog.dialog.name) {
            this.progress.push(prog);
          }
          if (prog.completed < prog.total) {
            this.setupComplete = false;
          }
        });

      });
    });
  }


  ngOnDestroy(): void {
    this.log('Destroying SetupStatus');

    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  onClose() {
    // update user before returning home
    this.userService.getMe().subscribe(result => {
      this.log('onClose', result);
      this.router.navigate(['/']);
    });

  }


}
