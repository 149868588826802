<div class="progress-bar">
  <div class="progress-bar__bar">
    <div class="progress-bar__progress section-background-color__{{section}}" [ngStyle]="{
        width: progressPercent + '%'
      }">
      <div class="progress-bar__text section-color__{{section}}" [ngClass]="{'light' : progressPercent >= 50}" [ngStyle]="{
        left: progressPercent >= 50 ? 'calc(' + progressPercent + '% - 50px)' : 'calc(' + progressPercent + '% + 10px)'
      }">
        {{ progressPercent | number: '1.0-0' }}%
      </div>
    </div>
  </div>
  <div class="progress-bar__sumup paragraph3">
    <div>
      <div class="paragraph2">Progress: {{ progressSum | currency:'AUD':'symbol-narrow':'1.0-0' }}</div>
    </div>

    <div class="progress-bar__sumup--right paragraph3">
      <div class="paragraph2">Target: {{ targetSum | currency:'AUD':'symbol-narrow':'1.0-0' }}</div>
    </div>
  </div>
</div>
