<div class="main-content-section">
  <div id="section-content" class="section-content">
    <div class="form-panel">
      
      <div>
      <img class="logo" src="/assets/images/hey-coach-logo-trim.png" />
      <div class="heading heading2">Financial life set-up</div>
      <div class="copy paragraph1">Your set-up will take a couple of minutes to<br> complete. If you are unsure of an answer, take<br> a guess and update it later.</div>
    </div>
      <div class="status-list">
      

        <div class="status-item" [ngClass]="{'done': p.completed == p.total}" *ngFor="let p of progress; index as i"
          [routerLink]="[ '/survey/'+ p.dialog.slug]" [queryParams]="{start: '_first', return: '/survey/setup'}">
          <div class=" left">
            <div class="dot-point paragraph2">{{i+1}}</div>
            <div class="prompt paragraph1">{{p.dialog.name}}</div>
          </div>
          <i *ngIf="p.completed < p.total" class="status material-icons">keyboard_arrow_right</i>
          <i *ngIf="p.completed == p.total" class="status material-icons">check</i>
        </div>
      </div>

      <button *ngIf="setupComplete" mat-stroked-button class="complete-button" (click)="onClose()">
        <span class="paragraph1">Complete</span>
      </button>

    </div>
  </div>
</div>
