import { Component, Inject, OnInit } from '@angular/core';
import { PlatformService } from '@rollit/shared/data';
import { APP_ENVIRONMENT } from '@rollit/shared';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'app-contact',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  agreementUrl: string;
  agreementTitle: string;
  privacyUrl: string;
  FSGUrl: string;

  constructor(@Inject(APP_ENVIRONMENT) private environment: any, private platformService: PlatformService) {

    this.agreementUrl = this.platformService.embedded ? this.environment.agreementUrl.app : this.environment.agreementUrl.web;
    this.agreementTitle = this.platformService.embedded ? 'Terms of Use' : 'Agreement';
    this.privacyUrl = this.environment.privacyUrl;
    this.FSGUrl = this.environment.FSGUrl;
  }

  ngOnInit() {
  }

  onOpenIntercomChat() {
    Intercom('trackEvent', 'web-app-profile-contact-click-chat');
    Intercom('showNewMessage', 'Hi!');
  }

}
