import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LifestyleAssetsComponent } from './components/lifestyle-assets.component';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';

const lifestyleAssetsRoutes: Routes = [
  {
    path: 'lifestyle',
    component: LifestyleAssetsComponent,
    data: {
      title: 'Lifestyle assets',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
  {
    path: 'lifestyle/:id',
    component: LifestyleAssetsComponent,
    data: {
      title: 'Lifestyle assets',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(lifestyleAssetsRoutes)],
  exports: [RouterModule],
})
export class LifestyleAssetsRoutingModule { }
