<span class="heading1">About Hey Coach</span>

<p class="paragraph1">At Hey Coach we’re building the world’s smartest AI financial coach. Our mission is to help people
  get ahead, no
  matter what they earn.
  <a href="https://hey.coach/about" target="_blank">Find out more</a>
</p>



<span class="heading heading2">Contact information</span>

<p class="paragraph1">Hey Coach is powered by Rollit Wealth<br>

  710 Collins Street, Docklands, VIC 3008<br>
  <a href="mailto:support@hey.coach">support@hey.coach</a>
</p>




<span class="heading heading2">Useful links</span>
<p class="paragraph1">
  <a target="_blank" href="{{agreementUrl}}">Terms of use</a><br>
  <a target="_blank" href="{{privacyUrl}}">Privacy Policy</a><br>
  <a target="_blank" href="{{FSGUrl}}">Financial Services Guide</a><br>
</p>

<button mat-raised-button class="rollit-button" color="primary" (click)="onOpenIntercomChat()"><i
    class="material-icons">chat_bubble_outline</i> Chat with the Hey Coach
  team</button>
