import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID, Compiler, COMPILER_OPTIONS, CompilerFactory } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from '@rollit/shared/utils';
import { RequestCountInterceptor, RequestCounter } from '@rollit/shared/utils';
import { NotificationService } from '@rollit/shared/data';

import { AppComponent } from '@app-rollit/app.component';

// import { ExploreModule } from '@rollit/shared/explore';
// import { PropertyLegacyModule } from '@rollit/shared/property-legacy';
import { AccountsModule } from '@rollit/shared/accounts';
import { PropertyModule } from '@rollit/shared/property';
import { SavingsModule } from '@rollit/shared/savings';
import { SuperannuationModule } from '@rollit/shared/superannuation';
import { InvestmentsModule } from '@rollit/shared/investments';
import { CreditCardsLoansModule } from '@rollit/shared/credit-cards-loans';
import { LifestyleAssetsModule } from '@rollit/shared/lifestyle-assets';
import { PrivacyDisclaimerModule } from '@rollit/shared/privacy-disclaimer';
import { InfoModule } from '@rollit/shared/info';

// import { ActionsModule } from '@rollit/shared/actions'; 

// import { GuideModule } from '@rollit/shared/guide';
// import { TrackModule } from '@rollit/shared/track';
// import { RewardModule } from '@rollit/shared/reward';
// import { SavedModule } from '@rollit/shared/saved';
import { DashboardModule } from '@rollit/shared/dashboard';

import { AppRoutingModule } from '@app-rollit/app-routing.module';
import { DataServicesModule } from '@rollit/shared/data';

import { RollitCommonModule } from '@rollit/shared/common';
import { UIManagerService } from '@rollit/shared/services';
// import { ChartsModule } from 'ng2-charts';
// import { HomeModule } from '@rollit/shared/home';
import { NouisliderModule } from 'ng2-nouislider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileModule } from '@rollit/shared/profile';
import { AppAuthGuardService } from '@rollit/shared/services';
import { DisclaimerDialogComponent } from '@rollit/shared/info';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@src-rollit/environments/environment';
import { AddHomeScreenIosComponent } from '@rollit/shared/info';
import { AddHomeScreenAndroidComponent } from '@rollit/shared/info';
import { MatDialogModule } from '@angular/material/dialog';
import { EnableCookiesComponent } from '@rollit/shared/info';
import { UrlSerializer } from '@angular/router';
import { StandardUrlSerializer } from '@rollit/shared/utils';
import { LoggerService } from '@rollit/shared/data';
import { ConsoleLoggerService } from '@rollit/shared/data';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { registerLocaleData } from '@angular/common';
// import { AddSuperFundComponent } from '@rollit/shared/info';
import { PlatformService } from '@rollit/shared/data';
import { AuthService } from '@rollit/shared/data';
//import { PropertyTypeComponent } from '@rollit/shared/property-legacy';
import { SubscriptionModule } from '@rollit/shared/subscription';
import { SurveyModule } from '@rollit/shared/survey';
// import { InvestmentsLegacyModule } from '@rollit/shared/investments-legacy';
// import { ExportXls } from '@rollit/shared/utils';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { JitCompilerFactory } from "@angular/platform-browser-dynamic";
import localeEnAu from '@angular/common/locales/en-AU';
registerLocaleData(localeEnAu, 'en-AU');

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

/**
 * Main application module.
 */

@NgModule({
  imports: [
    BrowserModule,
    // ExploreModule,
    // PropertyLegacyModule,
    AccountsModule,
    PropertyModule,
    SavingsModule,
    SuperannuationModule,
    InvestmentsModule,
    CreditCardsLoansModule,
    LifestyleAssetsModule,
    PrivacyDisclaimerModule,
    // ActionsModule,
    ProfileModule,
    InfoModule,
    // GuideModule,
    // TrackModule,
    // RewardModule,
    SurveyModule,
    // SavedModule,
    DashboardModule,
    AppRoutingModule,
    //HomeModule,
    // ChartsModule,
    RollitCommonModule,
    DataServicesModule,
    KeycloakAngularModule,
    FormsModule,
    ReactiveFormsModule,
    NouisliderModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.useServiceWorker,
    }),
    MatDialogModule,
    SubscriptionModule,
    // InvestmentsLegacyModule,
  ],
  exports: [RollitCommonModule, BrowserAnimationsModule],
  declarations: [
    AppComponent,

  ],

  providers: [
    UIManagerService,
    RequestCounter,
    NotificationService,
    AppAuthGuardService,
    PlatformService,
    AuthService,
    // ExportXls,
    DynamicScriptLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [APP_ENVIRONMENT, PlatformService, AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCountInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: StandardUrlSerializer,
    },
    {
      provide: LoggerService,
      useClass: ConsoleLoggerService,
    },
    { provide: LOCALE_ID, useValue: environment.locale },
    { provide: APP_ENVIRONMENT, useValue: environment },

    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DisclaimerDialogComponent,
    AddHomeScreenIosComponent,
    AddHomeScreenAndroidComponent,
    // AddSuperFundComponent,
    EnableCookiesComponent,
    //PropertyTypeComponent,
  ],
})
export class AppModule { }
