import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'add-home-screen-ios',
  templateUrl: './add-homescreen-ios.component.html',
  styleUrls: ['./add-homescreen-ios.component.scss']
})
export class AddHomeScreenIosComponent implements OnDestroy {
  isDesktop: Boolean;
  private isDestroyed = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddHomeScreenIosComponent>,
    private uiService: UIManagerService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop => this.isDesktop = isDesktop);
    Intercom('trackEvent', 'web-app-ios-gettheapp-open');
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  openStore(event) {
    event.stopPropagation();
    window.open('https://apps.apple.com/app/id1478690772', "_blank");
    this.dialogRef.close();
    const currentDate = new Date().toDateString();
    localStorage.setItem("addtohomescreen_update_date", currentDate);
    Intercom('trackEvent', 'web-app-ios-gettheapp-open');
  }

  closeDialog(): void {
    this.dialogRef.close();
    const currentDate = new Date().toDateString();
    localStorage.setItem("addtohomescreen_update_date", currentDate);
    Intercom('trackEvent', 'web-app-ios-gettheapp-close');
  }
}
