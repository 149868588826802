<span class="title heading2">Automated valuations*</span>
<mat-radio-group [(ngModel)]="preferredAccountMatch" class="estimate-types" (change)="onPreferredAccountChange($event)">
  <ng-container *ngFor="let account of accountTypes; let i=index;">
    <div class="radio-item">
      <mat-radio-button color="primary" [value]="i">

        <p *ngIf="i !== 0" class="paragraph1 section-color__secondary">{{account.title}}</p>


        <div class="edit-input" *ngIf="i === 0">
          <mat-form-field appearance="outline" class="input-select">
            <mat-select placeholder="Select an existing account" [formControl]="formControl"
              (selectionChange)="handleSelect($event.value)">
              <mat-option *ngFor="let ac of accounts" value="{{ac.id}}">
                {{ac.nickname}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </mat-radio-button>
    </div>
  </ng-container>
</mat-radio-group>

<div class="description paragraph2">{{ description }}</div>
