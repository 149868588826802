import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SCSS_VARS } from '@rollit/shared';
import { ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { LoggerService, MoneyAccount, MoneyService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
const currencyPipe = new CurrencyPipe('en-AU');
const percentPipe = new PercentPipe('en-AU');
@Component({
  selector: 'app-investments-projections',
  templateUrl: './investments-projections.component.html',
  styleUrls: ['./investments-projections.component.scss']
})
export class InvestmentsProjectionsComponent implements OnInit {

  @Input() acc: any;
  @Input() projectionChartData: any = [];
  @Input() projectionChartCategories: any = [];
  @Input() inView: boolean;
  projectionChartDataSum = [];

  isDesktop: boolean;

  destroy = new Subject();
  log: any;

  investmentTableData: Array<any> = [];
  investmentTableTitles = ['Name', 'Units', 'Price', '1YR Return', 'Value', 'Del'];

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Age',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isNumber: true,
      hide: false
    },
    {
      title: 'Business value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: false
    }
  ];



  constructor(private logger: LoggerService, private uiService: UIManagerService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private router: Router,
    private moneyService: MoneyService,) {
    this.log = this.logger.info('InvestmentsProjectionsComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    if (this.acc.accountSubtype !== 'business') {
      this.titleGroupDetails[1]['title'] = 'Portfolio value';
      this.setupInvestmentData();
    }
    this.setupTitleGroupDetails();
  }

  setupTitleGroupDetails(): void {

    this.titleGroupDetails[0]['data'] = this.projectionChartCategories[0];
    this.titleGroupDetails[1]['data'] = this.acc.currentBalance;

  }


  setupInvestmentData() {
    //this.log('portfolioDetails setupInvestmentData()', this.acc.accounts);
    const dataArray = [];
    this.projectionChartData.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

    //this.log('portfolioDetails this.projectionChartData', this.projectionChartData);
    this.projectionChartData.forEach(projection => {



      this.acc.accounts.forEach(data => {

        if (projection.name === data.nickname) {
          //this.log('portfolioDetails setupInvestmentData', projection.name, data.nickname);
          const dataObj = {
            name: projection.name,
            units: data.properties?.investment?.units ? data.properties?.investment?.units : 1,
            price: data.properties?.investment?.unitPrice ? data.properties?.investment?.unitPrice : projection.data[0],
            annualReturn: data.properties?.investment?.annualReturn ? data.properties?.investment?.annualReturn : 0.0876,
            value: projection.data[0],
            del: 'del',
          };
          dataArray.push(dataObj);
        }

      });

    });


    //this.log('portfolioDetails dataArray', dataArray);
    this.investmentTableData = [];
    this.investmentTableTitles.forEach((element, index) => {
      //this.log('investmentTableTitles', element, index);
      const name = element;
      const elementArray = [];
      dataArray.forEach((data) => {
        //this.log(element.toLowerCase(), data);
        let propName = element.toLowerCase();

        if (propName === '1yr return') {
          propName = 'annualReturn';
        }
        let propData = data[propName];


        switch (propName) {
          case 'name': {
            //statements; 
            break;
          }
          case 'units': {
            //statements; 
            break;
          }
          case 'price': {
            propData = currencyPipe.transform(Math.abs(propData), 'AUD', 'symbol-narrow', '1.0-0');
            break;
          }
          case 'annualReturn': {
            propData = percentPipe.transform(propData, '1.2');
            break;
          }
          case 'value': {
            propData = currencyPipe.transform(Math.abs(propData), 'AUD', 'symbol-narrow', '1.0-0');
            break;
          }
          default: {
            // statements; 
            break;
          }
        }


        elementArray.push(propData);

      });
      const tableObj = {
        columnHeader: name,
        elements: elementArray
      };
      this.investmentTableData.push(tableObj);


    });
    this.log('portfolioDetails investmentTableData', this.investmentTableData);
  }

  remove(id) {
    this.log('remove', this.projectionChartData[id]);
    const name = this.projectionChartData[id].name;
    this.acc.accounts.forEach(data => {
      if (name === data.nickname) {
        this.log('removeAccount', name, data.nickname);
        this.onRemoveAccount(data.id);
      }
    });
  }


  onRemoveAccount(accountId: number) {
    this.log('Remove account clicked', accountId);
    this.openRemovePropertyConfirmationDialog(accountId);
  }

  openRemovePropertyConfirmationDialog(accountId: number) {
    this.log('Delete dialog opened', accountId);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove account',
        copy: 'Are you sure you want to remove this account?',
        buttonMessage: 'Yes, remove account',
        type: 'Investment',
        showCancel: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result[0] === 'accept') {

        this.removeAccount(accountId);
      }
    });
  }

  removeAccount(accountId: number): void {
    this.log('Remove property init', accountId);

    this.moneyService.removeAccount(accountId).pipe(takeUntil(this.destroy)).subscribe(account => {
      this.log('Property removed', account);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/investments']));
    });
  }

  graphClicked(category, data, val): void {
    this.log(category, data, val);

    let newArray = [];
    let dataReturn = +data;

    if (this.acc.accountSubtype !== 'business') {
      dataReturn = 0;

      data.forEach((val) => {
        this.log('val', val);

        const transVal = currencyPipe.transform(Math.abs(val), 'AUD', 'symbol-narrow', '1.0-0');
        newArray.push(transVal);

        dataReturn += val;
      });

      this.investmentTableData[4]['elements'] = newArray;
      let tempArray = this.investmentTableData;
      this.investmentTableData = [];
      setTimeout(() => {
        // this.log('size changed.', event);
        this.investmentTableData = tempArray;
      }, 100);
      this.log(this.investmentTableData);

    }
    this.titleGroupDetails[0]['data'] = category;
    this.titleGroupDetails[1]['data'] = dataReturn;
  }
}