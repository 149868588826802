import { DefaultUrlSerializer, UrlSerializer, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, Params } from '@angular/router';
import { Injectable } from "@angular/core";

const appConstants = {
  outlets: ['list', 'detail']
};

const TRACE = false;

@Injectable()
export class StandardUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    if (TRACE) {
      // console.log('parse url', url);
    }
    appConstants.outlets.forEach(outletName => {
      const reg = new RegExp('\\/(' + outletName + ')\\/([^\/]*)');
      url = url.replace(reg, '/($1:$2)');
    });
    const reg = new RegExp('\\)\\/\\(');
    url = url.replace(reg, '//');

    if (TRACE) {
      // console.log('altered url', url);
    }

    const tree: UrlTree = this._defaultUrlSerializer.parse(url);

    if (TRACE) {
      const seg = logSegment(tree.root, true);
      // console.log('!!!', seg);
    }
    return tree;
  }

  serialize(tree: UrlTree): string {
    let url = this._defaultUrlSerializer.serialize(tree);
    if (TRACE) {
      // console.log('serialize url', url);
      const seg = logSegment(tree.root, true);
      // console.log('!!!', seg);
    }

    appConstants.outlets.forEach(outletName => {
      const reg = new RegExp('[\\(]?' + outletName + ':([^\/]*)[\\)|\\/]');
      url = url.replace(reg, outletName + '/$1');
    });
    if (TRACE) {
      // console.log('========> url', url);
    }

    return url;
  }

  /*
    parse(url: string): UrlTree {
      const p = new UrlParser(url);
      const tree: UrlTree = new UrlTree();
      tree.root        = p.parseRootSegment();
      tree.queryParams = p.parseQueryParams();
      tree.fragment    = p.parseFragment();
      return tree;
    }
  
    serialize(tree: UrlTree): string {
      const segment = `/${mySerializeSegment(tree.root, true)}`;
      const query = serializeQueryParams(tree.queryParams);
      const fragment =
          typeof tree.fragment === `string` ? `#${encodeUriFragment(tree.fragment !)}` : '';
  
      return `${segment}${query}${fragment}`;
    }
  */
}

export function logPath(path: UrlSegment): void {
  // console.log('path', path.path, path.parameters);
  //    return `${encodeUriSegment(path.path)}${serializeMatrixParams(path.parameters)}`;
}

function logPaths(segment: UrlSegmentGroup): string[] {
  let result: string[] = [];
  // // console.log('UrlSegmentGroup', segment);
  segment.segments.map(p => {
    logPath(p);
    result.push(p.path);
  });
  return result;
}

function logSegment(segment: UrlSegmentGroup, root: boolean): string[] {
  let result: string[] = [];

  if (!segment.hasChildren) {
    return logPaths(segment);
  }

  if (root) {
    if (segment.children['primary']) {
      // console.log('-> root primary', segment.children['primary']);
      result = result.concat(logSegment(segment.children['primary'], false));
    }

    for (const k in segment.children) {
      if (k !== 'primary') {
        // console.log('-> root ' + k, segment.children[k]);
        const v = segment.children[k];
        result = result.concat(logSegment(v, false));
      }
    }
    // TODO piece together the url
  }
  else {
    for (const childOutlet in segment.children) {
      if (childOutlet === 'primary') {
        const child: UrlSegmentGroup = segment.children[childOutlet];
        // console.log('-- primary', child);
        result = result.concat(logSegment(child, false));
      }
      else {
        const child: UrlSegmentGroup = segment.children[childOutlet];
        // console.log('-- ' + childOutlet, child);
        result = result.concat(logSegment(child, false));   // this is where 'k:v' is added
      }
    }
    result = logPaths(segment).concat(result);
  }

  return result;
}

/*
function mySerializeSegment(segment: UrlSegmentGroup, root: boolean): string {
    if (!segment.hasChildren()) {
        return serializePaths(segment);
    }

    if (root) {
        const primary = segment.children[PRIMARY_OUTLET] ?
            mySerializeSegment(segment.children[PRIMARY_OUTLET], false) :
            '';
        const children: string[] = [];

        forEach(segment.children, (v: UrlSegmentGroup, k: string) => {
        if (k !== PRIMARY_OUTLET) {
            children.push(`${k}:${mySerializeSegment(v, false)}`);
        }
        });

        return children.length > 0 ? `${primary}(${children.join('//')})` : primary;

    } else {
        const children = mapChildrenIntoArray(segment, (v: UrlSegmentGroup, k: string) => {
        if (k === PRIMARY_OUTLET) {
            return [mySerializeSegment(segment.children[PRIMARY_OUTLET], false)];
        }

        return [`${k}:${mySerializeSegment(v, false)}`];

    });

        return `${serializePaths(segment)}/(${children.join('//')})`;
    }
}
*/

/*



function serializeQueryParams(params: {[key: string]: any}): string {
    const strParams: string[] = Object.keys(params).map((name) => {
      const value = params[name];
      return Array.isArray(value) ?
          value.map(v => `${encodeUriQuery(name)}=${encodeUriQuery(v)}`).join('&') :
          `${encodeUriQuery(name)}=${encodeUriQuery(value)}`;
    });

    return strParams.length ? `?${strParams.join("&")}` : '';
  }

const SEGMENT_RE = /^[^\/()?;=#]+/;
function matchSegments(str: string): string {
  const match = str.match(SEGMENT_RE);
  return match ? match[0] : '';
}

const QUERY_PARAM_RE = /^[^=?&#]+/;
// Return the name of the query param at the start of the string or an empty string
function matchQueryParams(str: string): string {
  const match = str.match(QUERY_PARAM_RE);
  return match ? match[0] : '';
}

const QUERY_PARAM_VALUE_RE = /^[^?&#]+/;
// Return the value of the query param at the start of the string or an empty string
function matchUrlQueryParamValue(str: string): string {
  const match = str.match(QUERY_PARAM_VALUE_RE);
  return match ? match[0] : '';
}

class UrlParser {
    private remaining: string;

    constructor(private url: string) { this.remaining = url; }

    parseRootSegment(): UrlSegmentGroup {
      this.consumeOptional('/');

      if (this.remaining === '' || this.peekStartsWith('?') || this.peekStartsWith('#')) {
        return new UrlSegmentGroup([], {});
      }

      // The root segment group never has segments
      return new UrlSegmentGroup([], this.parseChildren());
    }

    parseQueryParams(): Params {
      const params: Params = {};
      if (this.consumeOptional('?')) {
        do {
          this.parseQueryParam(params);
        } while (this.consumeOptional('&'));
      }
      return params;
    }

    parseFragment(): string|null {
      return this.consumeOptional('#') ? decodeURIComponent(this.remaining) : null;
    }

    private parseChildren(): {[outlet: string]: UrlSegmentGroup} {
      if (this.remaining === '') {
        return {};
      }

      this.consumeOptional('/');

      const segments: UrlSegment[] = [];
      if (!this.peekStartsWith('(')) {
        segments.push(this.parseSegment());
      }

      while (this.peekStartsWith('/') && !this.peekStartsWith('//') && !this.peekStartsWith('/(')) {
        this.capture('/');
        segments.push(this.parseSegment());
      }

      let children: {[outlet: string]: UrlSegmentGroup} = {};
      if (this.peekStartsWith('/(')) {
        this.capture('/');
        children = this.parseParens(true);
      }

      let res: {[outlet: string]: UrlSegmentGroup} = {};
      if (this.peekStartsWith('(')) {
        res = this.parseParens(false);
      }

      if (segments.length > 0 || Object.keys(children).length > 0) {
        res[PRIMARY_OUTLET] = new UrlSegmentGroup(segments, children);
      }

      return res;
    }

    // parse a segment with its matrix parameters
    // ie `name;k1=v1;k2`
    private parseSegment(): UrlSegment {
      const path = matchSegments(this.remaining);
      if (path === '' && this.peekStartsWith(';')) {
        throw new Error(`Empty path url segment cannot have parameters: '${this.remaining}'.`);
      }

      this.capture(path);
      return new UrlSegment(decode(path), this.parseMatrixParams());
    }

    private parseMatrixParams(): {[key: string]: any} {
      const params: {[key: string]: any} = {};
      while (this.consumeOptional(';')) {
        this.parseParam(params);
      }
      return params;
    }

    private parseParam(params: {[key: string]: any}): void {
      const key = matchSegments(this.remaining);
      if (!key) {
        return;
      }
      this.capture(key);
      let value: any = '';
      if (this.consumeOptional('=')) {
        const valueMatch = matchSegments(this.remaining);
        if (valueMatch) {
          value = valueMatch;
          this.capture(value);
        }
      }

      params[decode(key)] = decode(value);
    }

    // Parse a single query parameter `name[=value]`
    private parseQueryParam(params: Params): void {
      const key = matchQueryParams(this.remaining);
      if (!key) {
        return;
      }
      this.capture(key);
      let value: any = '';
      if (this.consumeOptional('=')) {
        const valueMatch = matchUrlQueryParamValue(this.remaining);
        if (valueMatch) {
          value = valueMatch;
          this.capture(value);
        }
      }

      const decodedKey = decodeQuery(key);
      const decodedVal = decodeQuery(value);

      if (params.hasOwnProperty(decodedKey)) {
        // Append to existing values
        let currentVal = params[decodedKey];
        if (!Array.isArray(currentVal)) {
          currentVal = [currentVal];
          params[decodedKey] = currentVal;
        }
        currentVal.push(decodedVal);
      } else {
        // Create a new value
        params[decodedKey] = decodedVal;
      }
    }

    // parse `(a/b//outlet_name:c/d)`
    private parseParens(allowPrimary: boolean): {[outlet: string]: UrlSegmentGroup} {
      const segments: {[key: string]: UrlSegmentGroup} = {};
      this.capture('(');

      while (!this.consumeOptional(')') && this.remaining.length > 0) {
        const path = matchSegments(this.remaining);

        const next = this.remaining[path.length];

        // if is is not one of these characters, then the segment was unescaped
        // or the group was not closed
        if (next !== '/' && next !== ')' && next !== ';') {
          throw new Error(`Cannot parse url '${this.url}'`);
        }

        let outletName: string = undefined !;
        if (path.indexOf(':') > -1) {
          outletName = path.substr(0, path.indexOf(':'));
          this.capture(outletName);
          this.capture(':');
        } else if (allowPrimary) {
          outletName = PRIMARY_OUTLET;
        }

        const children = this.parseChildren();
        segments[outletName] = Object.keys(children).length === 1 ? children[PRIMARY_OUTLET] :
                                                                    new UrlSegmentGroup([], children);
        this.consumeOptional('//');
      }

      return segments;
    }

    private peekStartsWith(str: string): boolean { return this.remaining.startsWith(str); }

    // Consumes the prefix when it is present and returns whether it has been consumed
    private consumeOptional(str: string): boolean {
      if (this.peekStartsWith(str)) {
        this.remaining = this.remaining.substring(str.length);
        return true;
      }
      return false;
    }

    private capture(str: string): void {
      if (!this.consumeOptional(str)) {
        throw new Error(`Expected "${str}".`);
      }
    }
  }
*/