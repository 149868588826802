import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cost-accordion',
  templateUrl: './cost-accordion.component.html',
  styleUrls: ['./cost-accordion.component.scss']
})
export class CostAccordionComponent implements OnInit {

  isExpanded = false;

  constructor() { }

  ngOnInit() {
  }

}
