import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appOnlyNumberFormControl]'
})
export class OnlyNumberFormControlDirective {

    regexStr = '^[0-9]*$';

    constructor(private control: NgControl) {
        console.log('constructed InputTextFilterDirective');
    }

    @Input() OnlyNumber: boolean;

    @HostListener('input', ['$event']) onInput(event) {
        this.checkString();
    }

    @HostListener('change') onChange() {
        this.checkString();
    }

    private checkString() {
        const string = this.control.value;
        const lastChar = string.substr(string.length - 1);
        const regEx = new RegExp(this.regexStr);
        const testString = regEx.test(lastChar);
        if (testString) {
            return;
        } else {
            this.control.control.setValue(string.slice(0, -1));
        }
    }
}
