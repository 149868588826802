import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { Dialog, Section, Progress, Fact, QuestionFact } from '../model/dialog';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { Result } from '../model/result';
import { Router } from '@angular/router';

@Injectable()
export class DialogService {

  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any, 
    private http: HttpClient) {
  }

  public getDialogs(module: string): Observable<Dialog[]> {
    const path = '/dialog/' + module;
    return this.http.get<Dialog[]>(this.environment.apiUrl + path);
  }

  public getDialog(module: string, slug: string): Observable<Dialog> {
    const path = '/dialog/' + module + '/' + slug;
    return this.http.get<Dialog>(this.environment.apiUrl + path);
  }

  public getModuleProgress(module: string, session?: string): Observable<Progress[]> {
    const path = '/me/dialog/' + module;
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.get<Progress[]>(this.environment.apiUrl + path, { params });
  }

  /**
   * Submit user answers to a section of the user dialog.
   * Section should have answer values in all answers to questions.
   */
  public initDialog(module: string, dialogSlug: string, facts: Fact[], session: string): Observable<Progress> {
    const path = '/me/dialog/' + module + '/' + dialogSlug;
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.post<Progress>(this.environment.apiUrl + path, facts, { params });
  }

  /**
   * Get user progress through dialog
   */
  public getProgress(module: string, dialogSlug: string, sectionSlug: string = null, session: string = null): Observable<Progress> {
    if (!module) {
      module = "signup"
    }
    let path = '/me/dialog/' + module + '/' + dialogSlug;
    if (sectionSlug) {
      path += '/section/' + sectionSlug;
    }
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.get<Progress>(this.environment.apiUrl + path, { params });
  }

  /**
   * Submit user answers to a section of the user dialog.
   * Section should have answer values in all answers to questions.
   */
  public setFacts(module: string, dialogSlug: string, sectionSlug: string, section: Section, session?: string): Observable<Progress> {
    const path = '/me/dialog/' + module + '/' + dialogSlug + '/section/' + sectionSlug;
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.post<Progress>(this.environment.apiUrl + path, section, { params });
  }

  /**
   *
   * @param categorySlug Sluf for the category.  e.g. "onboard"
   */
  public pauseDialogCategory(categorySlug: string, session?: string): Observable<Result<void>> {
    const path = '/me/dialog/' + categorySlug;
    const status = "paused";
    let params = new HttpParams().append('category', categorySlug);
    if (session) {
      params = params.append('session', session);
    }

    return this.http.put<Result<void>>(this.environment.apiUrl + path, status, { params });
  }

  /**
   * Fetch all user facts collected.
   */
  public getFacts(session?: string): Observable<Fact[]> {
    const path = '/me/facts';
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.get<Fact[]>(this.environment.apiUrl + path, { params });
  }

  public getDialogFacts(session?: string): Observable<QuestionFact[]> {
    const path = '/me/facts/dialog';
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.get<QuestionFact[]>(this.environment.apiUrl + path, { params });
  }

  /**
   * Only for admin users.
   */
  public clearFacts(session?: string): Observable<void> {
    const path = '/me/facts';
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.delete<void>(this.environment.apiUrl + path, { params });
  }

  /**
   * Fetch report data for a dialog.
   * @param dialogSlug The dialog.
   */
  public getReport(module: string, dialogSlug: string, session?: string): Observable<any> {
    const path = '/me/dialog/' + module + '/' + dialogSlug + '/report';
    let params = new HttpParams();
    if (session) {
      params = params.append('session', session);
    }

    return this.http.get<any>(this.environment.apiUrl + path, { params });
  }

  public getReportText(): Observable<string[]> {
    const path = '/assets/data/health-report.json';
    return this.http.get<string[]>(path);
  }

  /* ------------- for unauthenticated dialog interaction ------------------- */

  /**
   * Unauthenticated dialog interaction using cookie for session tracking.
   * 
   * @param dialogSlug 
   */
  public getAnonProgress(dialogSlug: string, sectionSlug?: string): Observable<Progress> {
    console.log('getting session facts progress: ' + dialogSlug + " - " + sectionSlug);
    const path = sectionSlug
      ? ('/signup/survey/' + dialogSlug + '/section/' + sectionSlug)
      : ('/signup/survey/' + dialogSlug);
    return this.http.get<Progress>(this.environment.apiUrl + path);
  }

  public setAnonFacts(dialogSlug: string, sectionSlug: string, section: Section): Observable<Progress> {
    const path = '/signup/survey/' + dialogSlug + '/section/' + sectionSlug;
    return this.http.post<Progress>(this.environment.apiUrl + path, section);
  }

  public getAnonReport(dialogSlug: string): Observable<any> {
    const path = '/signup/survey/' + dialogSlug + '/report';
    return this.http.get<any>(this.environment.apiUrl + path);
  }

  public getAnonFacts(): Observable<Fact[]> {
    const path = '/signup/survey/facts';
    return this.http.get<Fact[]>(this.environment.apiUrl + path);
  }

  public getAnonDialogFacts(): Observable<QuestionFact[]> {
    const path = '/signup/survey/facts/dialog';
    return this.http.get<QuestionFact[]>(this.environment.apiUrl + path);
  }

  public generateSessionId(): string {
    return this.randomString(48);
  }

  private randomString(length: number, characters?: string): string {
    if (characters) {
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    } else {
      return this.randomString(length, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
    }
  }
}
