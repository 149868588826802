import { InvestmentOptionFilter } from '@rollit/shared/data';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { compare } from '@rollit/shared/utils';
import { APP_ENVIRONMENT } from '@rollit/shared';


/**
 * Provides the current investment option filter.
 */
@Injectable()
export class FundFilterService {

  constructor(@Inject(APP_ENVIRONMENT) private environment: any) {

  }

  private _filter = new ReplaySubject<InvestmentOptionFilter>(1);

  get filter$() {
    return this._filter.pipe(distinctUntilChanged(compare));
  }

  set filter(value: InvestmentOptionFilter) {
    // if configured, force short-list of super funds
    if (this.environment['explore'] && this.environment['explore']['funds']) {
      value.funds = this.environment['explore']['funds'];
    }
    // console.log('FundFilterService', value);
    this._filter.next(value);
  }

  getCurrentSectorFiltersMessage(sectors: string[]): string {
    let fundType: string;
    if (sectors.length === 3) {
      fundType = 'all funds';
    } else {
      fundType = '';
      for (let _i = 0; _i < sectors.length; _i++) {
        fundType += sectors[_i].toLowerCase();
        if (_i !== sectors.length - 1) {
          fundType += ', ';
        }
      }
    }
    // console.log('getCurrentSectorFiltersMessage');
    return fundType;
  }
}
