import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { FileUploadModule } from 'ng2-file-upload';
import { ProfileRoutingModule } from './profile-routing.module';
import { UserProfileComponent } from './user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmployerInformationComponent } from './employer-information/employer-information.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ProfileLinksComponent } from './profile-links/profile-links.component';
import { ProfileLinkItemComponent } from './profile-links/profile-link-item/profile-link-item.component';
import { ProfileListAndDetailComponent } from './profile-list-and-detail/profile-list-and-detail.component';
import { EmployerModule } from '@rollit/shared/employer';
import { SubscriptionInformationComponent } from './subscription-information/subscription-information.component';
import { PaymentComponent } from './payment/payment.component';
import { DataServicesModule } from '@rollit/shared/data';
//import { ActionsModule } from '@rollit/shared/actions';

@NgModule({
  imports: [
    //ActionsModule,
    RollitCommonModule,
    CommonModule,
    FlexLayoutModule,
    FileUploadModule,
    ProfileRoutingModule,
    EmployerModule,
    DataServicesModule,
  ],
  declarations: [
    UserProfileComponent,
    ChangePasswordComponent,
    EmployerInformationComponent,
    PersonalInformationComponent,
    ProfileLinksComponent,
    ProfileListAndDetailComponent,
    ProfileLinkItemComponent,
    SubscriptionInformationComponent,
    PaymentComponent,
  ],
  providers: [],
  entryComponents: [
    PaymentComponent,
  ]

})
export class ProfileModule { }
