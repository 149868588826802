import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogService, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-report-inline',
  templateUrl: './report-inline.component.html',
  styleUrls: ['./report-inline.component.scss']
})
export class ReportInlineComponent implements OnInit, OnDestroy {
  @Input() dialogSlug: any;
  @Input() authenticated: any;
  @Input() module: any;
  @Input() sessionId: any;
  private isDestroyed = new Subject();
  log: any;
  reportText: any;
  report: any;
  isDesktop: any;
  reportComplete = false;
  reportTextComplete = false;
  constructor(private logger: LoggerService,
    private uiService: UIManagerService, private dialogService: DialogService,) {

    this.log = this.logger.info('ReportInlineComponent');

    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.isDesktop = mediaSizeIsDesktop;
    });


  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogService.getReportText().subscribe(data => {
        this.reportText = data;
        this.log('getReportText', data);
        this.reportTextComplete = true;
      });

      this.getSurveyReport(this.dialogSlug).subscribe(result => {
        this.log("report data", result);
        this.report = result;
        this.reportComplete = true;
      });
    }, 200);


  }

  ngOnDestroy(): void {

  }

  getSurveyReport(dialogSlug: string): Observable<any> {
    if (this.authenticated) {
      return this.dialogService.getReport(this.module, dialogSlug, this.sessionId);
    }
    else {
      return this.dialogService.getAnonReport(dialogSlug);
    }
  }



}
