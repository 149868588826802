import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileService, LoggerService, MoneyAccount, MoneyService } from '@rollit/shared/data';
import { ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LifestyleAssetsService } from '../../lifestyle-assets.service';
import { SCSS_VARS } from '@rollit/shared';
import * as moment_ from 'moment';
import { Router } from '@angular/router';
const moment = moment_;

@Component({
  selector: 'app-lifestyle-assets-hero',
  templateUrl: './lifestyle-assets-hero.component.html',
  styleUrls: ['./lifestyle-assets-hero.component.scss']
})
export class LifestyleAssetsHeroComponent implements OnInit {
  @Input() acc: MoneyAccount;
  @Input() propertyListId: number;
  @Input() propertyCount: number;
  @Input() inView: boolean;
  @Output() clickNext = new EventEmitter();
  @Output() clickPrev = new EventEmitter();
  @Output() expandedProjections = new EventEmitter();

  log: any;
  expanded = false;
  destroy = new Subject();
  isDesktop: boolean;
  imagePath: string;
  loanAccount: any;
  loanAccountReady = false;
  loanExists = false;

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Net value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: true,
    },

    {
      title: 'Lifestyle asset value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true,
    },
    {
      title: 'Loan repaid',
      data: new Date(),
      dataColor: SCSS_VARS['$rollit-growth'],
      isDate: true,
      hide: true,
    },
    {
      title: 'Lifestyle asset loan',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isCurrency: true,
      hide: true,
    }
  ];


  projections: any;
  projectionChartData = [];
  projectionChartCategories = [];
  projectedDebtFreeDate: any;
  projectedDebtEndAge: any;

  constructor(
    private logger: LoggerService,
    private dialog: MatDialog,
    private moneyService: MoneyService,
    private lifestyleAssetsService: LifestyleAssetsService,
    private fileService: FileService,
    private uiService: UIManagerService,
    private router: Router,
  ) {
    this.log = this.logger.info('LifestyleAssetsHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('acc', this.acc);
    this.setupPropertyImage();
    this.setupProjections();

  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }


  setupPropertyImage() {

    // TODO: Handle multiple image, requires a carousel

    this.moneyService.getAccountImages(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(accountImagesResult => {
      console.log('getAccountImages', accountImagesResult);
      if (accountImagesResult && accountImagesResult.length > 0) {
        this.fileService.getFilePath(accountImagesResult[0]['id']).pipe(takeUntil(this.destroy)).subscribe(
          path => {
            this.imagePath = path;
          }
        );
      } else {
        if (this.acc.accountSubtype === "vehicle") {
          this.imagePath = "/assets/images/default/default_lifestyle_assets_vehicle.jpg";
        } else {
          this.imagePath = "/assets/images/default/default_lifestyle_assets_other.jpg";
        }

      }
    });
  }

  setupLoanData() {
    if (this.acc?.properties?.loan?.id) {
      this.log('There is a loan');
      this.loanExists = true;
      this.moneyService.getAccount(this.acc.properties.loan.id).pipe(takeUntil(this.destroy)).subscribe(result => {
        this.loanAccount = result;
        this.log('loanAccount', this.loanAccount);
        this.setupTitleGroupDetails(true);
        this.loanAccountReady = true;
      });
    } else {
      this.log('There is no loan');
      this.setupTitleGroupDetails(false);
      this.loanAccountReady = true;
    }
  }

  setupTitleGroupDetails(hasLoan): void {
    this.log('setupTitleGroupDetails this.projectedMortgageEndDate', this.projectedDebtFreeDate);
    if (hasLoan) {
      this.titleGroupDetails[0]['data'] = this.acc.currentBalance + this.loanAccount.currentBalance;
      this.titleGroupDetails[0]['hide'] = false;

      this.titleGroupDetails[1]['data'] = this.acc.currentBalance;
      this.titleGroupDetails[1]['hide'] = false;

      this.titleGroupDetails[2]['data'] = this.projectedDebtFreeDate;

      this.titleGroupDetails[3]['data'] = this.loanAccount.currentBalance;
      this.titleGroupDetails[3]['hide'] = false;
    } else {
      this.titleGroupDetails[0]['data'] = this.acc.currentBalance;
      this.titleGroupDetails[0]['hide'] = false;

      this.titleGroupDetails[1]['data'] = this.acc.currentBalance;
      this.titleGroupDetails[1]['hide'] = false;
    }

  }



  setupProjections() {
    this.lifestyleAssetsService.getProjections(this.acc.id, 6).pipe(takeUntil(this.destroy)).subscribe(proj => {
      this.log('Got projections', proj);

      const self = this;

      this.projections = proj;
      const projections = proj;

      if (projections?.extra) {
        projections?.extra.forEach(element => {
          if (element?.debtFree) {
            this.projectedDebtFreeDate = element?.debtFree;
            this.titleGroupDetails[2]['hide'] = false;
          }

        });
      }


      const colorArray = [SCSS_VARS['$rollit-property'], SCSS_VARS['$rollit-mortgage'], SCSS_VARS['$rollit-growth']];
      const chartCategoriesMap = [
        { short: 'PROP', section: 'property', title: 'Property' },
        { short: 'MTG', section: 'property', title: 'Mortgage' },
        { short: 'NET', section: '', title: 'Net' },
      ];
      const temp_array = [];

      projections.names.forEach(function (nameVal, nameIndex) {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            self.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
          }
        });

        if (nameVal === 'Net') {
          temp_object['type'] = 'spline';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        self.log(nameVal, nameIndex, colorArray[nameIndex]);

        projections.data.forEach(function (dataVal, dataIndex) {
          self.projectionChartCategories.push(projections.data[dataIndex].label);
          temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
        });

        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      self.log(temp_array);

      this.projectionChartData = temp_array;
      self.log('this.chartData', this.projectionChartData);

      this.setupLoanData();
    });

  }


  // User actions

  graphClicked(category, name): void {
    this.log(category, name);
  }

  expandClicked() {
    this.expanded = !this.expanded;
    this.expandedProjections.emit(this.expanded);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  uploadComplete(file) {
    this.log('upload complete', file);
    this.moneyService.setAccountImages(this.acc.id, [file]).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('uploadComplete', result);
    });
  }

  onEditAccount(accountId: number) {
    this.lifestyleAssetsService.startUpdateAsset(accountId);
  }

  onRemoveAccount() {
    this.log('Remove account clicked');
    this.openRemovePropertyConfirmationDialog(this.acc.id.toString());
  }

  openRemovePropertyConfirmationDialog(accountId: string) {
    this.log('Delete dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove account',
        copy: 'Are you sure you want to remove this account?',
        buttonMessage: 'Yes, remove account',
        type: 'Loan',
        showCancel: true,
        checkboxLabel: 'Also remove associated loan',
        showCheckbox: this.loanExists,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result[0] === 'accept') {
        this.removeAccount(accountId, result[1]);
      }
    });
  }

  removeAccount(propertyId: string, removeRelated: boolean): void {
    this.log('Remove property init', propertyId, removeRelated);

    this.moneyService.removeAccount(this.acc.id, removeRelated).pipe(takeUntil(this.destroy)).subscribe(account => {
      this.log('Property removed', account);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/lifestyle']));
    });
  }

  scrollPrev() {
    this.clickPrev.next();
    this.log('clickPrev');
  }

  scrollNext() {
    this.clickNext.next();
    this.log('scrollNext');
  }


}
