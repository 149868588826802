import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BillingCycle, DiscountCode, Order, Price, Product, Signup, CustomerType, TrialCode } from '@rollit/shared/data';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionService } from '../question.service';
import { NotificationService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { MeService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlatformService } from '@rollit/shared/data';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { Router } from '@angular/router';
import { User } from '@rollit/shared/data';
import { SubscriptionService } from '@rollit/shared/data';

declare var Intercom; // defined by included Intercom javascript.

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  type: CustomerType;
  billingCycle: BillingCycle;
  signup: Signup;
  numberOfEmployees: number;
  product: Product;  // product based on type, billingCycle and number of employees.
  price: Price;
  trial: TrialCode;
  discount: DiscountCode;
  discountCode: string;
  signupError: boolean = false;
  noSignup: boolean = false;
  user: User;
  showUpgrade = false;

  billingCycleFormGroup: FormGroup;

  annualSubscription: number;
  monthlySubscription: number;
  annualCostPerEmployee: number;
  monthlyCostPerEmployee: number;
  numberOfEmployeesControl = new FormControl(); // for 'Employer' type only

  mediaSizeIsDesktop: boolean;
  billingCycleEnum = BillingCycle;

  isLoaded: boolean;
  isFree: boolean;
  isEmployer: boolean;

  text1 = "";
  text2 = "";
  planPrice = '$5.00+GST';    // TODO make this dynamic

  private log: any;
  private isDestroyed = new Subject();

  /**
   * Constructor
   */
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private ss: SubscriptionService,
    private qs: QuestionService,
    private userService: MeService,
    private notificationService: NotificationService,
    private uiService: UIManagerService,
    private logger: LoggerService,
    private platformService: PlatformService,
    private router: Router,
  ) {
    this.log = this.logger.info('paymentFormComponent');
    this.showUpgrade = this.environment.embedded.inAppPurchases || !(this.platformService.embedded && this.platformService.isIos);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop =>
      this.mediaSizeIsDesktop = mediaSizeIsDesktop
    );
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.appBackgroundIsPrimary.next(true);

    // fetch the in-progress signup.
    this.ss.getMySignup().subscribe((progress: Signup) => {
      this.isLoaded = true;
      this.log('My Signup', progress);
      this.signup = progress;
      if (this.signup.complete === true) {
        this.router.navigate(['/'], { skipLocationChange: false });
        this.log('Signup is complete, send user to home');
      }
      if (progress && progress.type) {
        this.type = progress.type;
        this.user = progress.user;
        this.trial = progress.trialCode;
        this.discount = progress.discountCode;
        if (this.discount) {
          this.discountCode = this.discount.code;
        }
        this.isEmployer = this.type === CustomerType.employer;
        this.product = progress.product;
        if (progress.product) {        // determine billing period from the product ID.
          if (progress.product.properties) {
            this.billingCycle = progress.product.properties['billingPeriod'];
            this.isFree = progress.product.properties['unitPrice'] === 0;
          }
          // if (progress.product.id === 1 || progress.product.id === 3) {
          //   this.billingCycle = BillingCycle.monthly;
          // }
          // else {
          //   this.billingCycle = BillingCycle.annually;
          // }
          this.log('got billingCycle', this.billingCycle);
        }
        if (this.type === CustomerType.employer) {
          this.numberOfEmployeesControl.setValue(progress.employer['numEmployees']);
        }
        this.checkProduct();    // get price of product
        this.qs.getBillingCycleQuestions().subscribe(questions => {
          this.log('billing cycle questions', questions);
        });
      } else {
        // Current user has no saved signup progress data.
        // This could happen if user is logged in wirth a different accoutn to the one setup during signup
        // this.notificationService.error('Couldn\'t load your sign-up information, please contact customer services.', '');
        this.noSignup = true;   // this will show message
        this.userService.me$.pipe(takeUntil(this.isDestroyed)).subscribe(
          value => this.user = value
        );
      }

      if (this.isFree && !this.noSignup) {
        const order: Order = {
          items: [],
          payments: [{
            // type: PaymentMethodType.EFT,
            // save: true,
          }],
          customer: {},
          discountCode: {},
        };
        this.submitOrder(order);
      }

      this.updateText();
    });

    Intercom('trackEvent', 'web-app-signup-payment-form-visit');
  }

  ngOnInit() {
    this.numberOfEmployeesControl.valueChanges.subscribe(numberOfEmployees => {
      if (numberOfEmployees) {
        this.numberOfEmployees = parseInt(numberOfEmployees, 10);
        this.calculateAnnualCost(this.numberOfEmployees);
        this.checkProduct();    // get price for product
      } else {
        // Input value is blank, so reset subscription values
        this.numberOfEmployees = null;
        this.annualSubscription = null;
        this.monthlySubscription = null;
        this.annualCostPerEmployee = null;
        this.monthlyCostPerEmployee = null;
      }
    });

  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  updateText() {
    this.text1 = "Clear and simple plan.";
    this.text2 = (this.planPrice)
      + ((this.type === CustomerType.employer)
        ? " per user, per month billed over 12 months."
        : " per month billed over 12 months.");
  }

  onDiscountCode(code: string) {
    this.log('got discount code', code);
    this.discountCode = code;

    const order: Order = {
      items: [{
        product: this.product
      }],
      trialCode: this.trial,
      discountCode: this.discountCode ? { code: this.discountCode } : null,
    };

    this.checkOrder(order);      // calculate price of order.
  }

  onOrderSubmit(order: Order) {
  }

  /**
   * Calculate price of product.
   *
   * If product does not yet exist, create a product instance based on subscription
   * type and selected billing cycle.
   */
  checkProduct() {
    let product = this.product;
    if (!product) {
      product = {};
      product.id = this.ss.getProductId(this.type, (this.isFree ? 'free' : 'basic'), this.billingCycle);
      this.product = product;
      this.log('Created product', product);
    }

    if (this.type === CustomerType.employer) {
      if (this.numberOfEmployees) {
        product.attributes = [];
        product.attributes.push({ name: 'Number of Employees', value: this.numberOfEmployees });
      }
    }

    // get prices
    const order: Order = {
      items: [{ product: this.product }],
      trialCode: this.trial,
      discountCode: this.discountCode ? { code: this.discountCode } : null,
    };

    this.checkOrder(order);      // calculate price of order.
  }

  calculateAnnualCost(numberOfEmployees: number) {
    this.annualCostPerEmployee = null;
    this.monthlyCostPerEmployee = null;
    switch (true) {
      case numberOfEmployees === 1:
        this.annualCostPerEmployee = 20;
        this.monthlyCostPerEmployee = 25;
        break;
      case numberOfEmployees > 1 && numberOfEmployees <= 20:
        this.annualCostPerEmployee = 17.5;
        this.monthlyCostPerEmployee = 20;
        break;
      case numberOfEmployees > 20 && numberOfEmployees <= 50:
        this.annualCostPerEmployee = 15;
        this.monthlyCostPerEmployee = 17.5;
        break;
      case numberOfEmployees > 50 && numberOfEmployees <= 250:
        this.annualCostPerEmployee = 12.5;
        this.monthlyCostPerEmployee = 15;
        break;
      case numberOfEmployees > 250 && numberOfEmployees <= 500:
        this.annualCostPerEmployee = 10;
        this.monthlyCostPerEmployee = 12.5;
        break;
      case numberOfEmployees > 500 && numberOfEmployees <= 1000:
        this.annualCostPerEmployee = 8;
        this.monthlyCostPerEmployee = 10;
        break;
      case numberOfEmployees > 1000:
        this.annualCostPerEmployee = 6.80;
        this.monthlyCostPerEmployee = 8.50;
        break;
    }
    this.annualSubscription = numberOfEmployees * this.annualCostPerEmployee * 12;
    this.monthlySubscription = numberOfEmployees * this.monthlyCostPerEmployee * 12;
  }

  /**
   * Get price of selected product
   */
  checkOrder(order: Order) {
    this.log('checking order', order);

    this.ss.checkOrder(order)
      .subscribe(
        result => {
          this.log('Got check-order response', result);
          this.price = result.price;
          this.discount = result.discountCode;
        },
        err => {
          console.error('Problem checking  order', err);
          this.notificationService.error('Problem checking order', err.error.message);
        }
      );
  }

  /**
   * Save card payment information against user.
   *
   * @param order - the order with missing order.items
   */
  submitOrder(order: Order) {
    this.log('submitting order', order);

    if (this.signup) {
      order.customer = {
        type: this.signup.type,
        employer: this.signup.employer,
      };
    }

    // Add the product onto the order received from PaymentDetailsComponent
    order.items.push({ product: this.product });

    this.ss.submitOrder(order)
      .subscribe(
        result => {
          this.log('Got order response', result);
          Intercom('trackEvent', 'web-app-signup-payment-made');
          if (this.signup.type === CustomerType.employer) {
            // the user is an employer
            location.pathname = "/employer/dashboard"; // reload the app so we can refresh the data
          } else {
            location.pathname = "/"; // reload the app so we can refresh the data
          }
        },
        err => {
          console.error('Problem processing order', err);
          this.notificationService.error('Problem processing order', err.error.message);
          this.signupError = true;
        }
      );
  }

  closeDialog(): void {
    window.history.back();
  }

  logout() {
    this.userService.logout();
  }

}
