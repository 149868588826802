<div class="main-content-section">
  <div id="section-content" class="section-content">
    <div class="form-panel">
      <div class="holder">

        <span class="heading heading1 section-color__lightest">We provide general financial advice</span>
        <span class="copy paragraph1 section-color__lightest">
          The Hey Coach platform provides general information only. </span>
        <span class="copy paragraph1 section-color__lightest">
          It does not take into account your objectives, financial situation or needs. 
          You should assess whether the information is appropriate for you having regard to your objectives, financial situation and needs. 
          You should consider obtaining independent professional advice before making an investment decision.
        </span>
        <span class="copy paragraph1 section-color__lightest">
          If information relates to a specific financial product you should obtain a copy of the product disclosure statement for that 
          product and consider that statement before making a decision whether to acquire the product. 
          AFSL Authorised Representative No. 1273703 of MSC Advisory Pty Ltd. ACN 607 459 441. AFSL 480649 (MSCA).
        </span>
        <button mat-flat-button class="rollit-button" (click)="onAcceptDisclaimer(true)">
          I understand, do not show again
        </button>
        <button mat-flat-button class="rollit-button" (click)="onAcceptDisclaimer(false)">
          I understand
        </button>

      </div>
    </div>
  </div>
</div>
