import { Component } from '@angular/core';
import { InputComponent } from '../input.component';
import { TypeDef, NumberTypeDef, NumberFormat, LoggerService } from '@rollit/shared/data';
import { FormControl, Validators, ValidationErrors } from '@angular/forms';

/**
 * TODO handle different number format definitions.  Show slider in some cases.
 */
@Component({
  selector: 'lib-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
})
export class InputNumberComponent extends InputComponent {
  log: any;
  formControl: FormControl = new FormControl();
  type = "number";
  format: NumberFormat = null;
  negate = false;   // whether to negate the entered value for output
  validatorList: Array<any> = [];
  isValid: boolean = true;
  validationMessage: string;
  placeholderText: string;

  constructor(private logger: LoggerService) {
    super();
    this.log = this.logger.info("InputNumberComponent");
  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    const validators = [];
    const def: NumberTypeDef = typeDef as NumberTypeDef;

    this.format = NumberFormat[def.format];    // TODO handle different number formats
    this.negate = def.negate === true;


    if (this.placeholder) {
      this.placeholderText = this.placeholder;
    } else if (this.format === NumberFormat.Percentage) {
      this.placeholderText = 'Enter percentage';
    } else {
      this.placeholderText = 'Enter amount';
    }

    if (typeof (def.min) !== 'undefined' && def.min !== null) {
      let min = def.min;
      if (this.format === NumberFormat.Percentage) {
        min = 100 * def.min;
      }
      if (this.negate) {
        validators.push(Validators.max(-min));
        this.validatorList.push(['max', 'A maximum of ' + (-min) + ' is required']);
      } else {
        validators.push(Validators.min(min));
        this.validatorList.push(['min', 'A minimum of ' + min + ' is required']);
      }
    }

    if (typeof (def.max) !== 'undefined' && def.max !== null) {
      let max = def.max;
      if (this.format === NumberFormat.Percentage) {
        max = 100 * def.max;
      }
      if (this.negate) {
        validators.push(Validators.min(-max));
        this.validatorList.push(['min', 'A minimum of ' + (-max) + ' is required']);
      } else {
        validators.push(Validators.max(max));
        this.validatorList.push(['max', 'A maximum of ' + max + ' is required']);
      }
    }

    validators.push(Validators.pattern("^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$"));

    // if not optional
    validators.push(Validators.required);
    this.formControl.setValidators(validators);
    this.validatorList.push(['required', 'Field is required']);
  }


  /**
   * Handle existing numeric value for this input.
   * @param data 
   */
  handleData(data: any) {
    const value = this.toDisplayNumber(data);
    this.formControl.setValue(value);
  }

  update() {
    this.validatorList.forEach((val) => {
      const isError = this.hasError(val[0]);
      if (isError) {
        this.isValid = false;
        this.validationMessage = val[1];
      }
    });
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

  /**
   * Provide the numeric value, which may have been modified by the user.
   */
  getData(): any {
    return this.fromDisplayNumber(this.formControl.value);
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

  private toDisplayNumber(value: any) {
    if (typeof value === 'undefined') {
      return value;
    }

    if (this.negate) {
      value = -value;
    }

    if (this.format === NumberFormat.Percentage) {
      value = value * 100;                                        // percentage out of 100.
      value = Math.round((value + Number.EPSILON) * 100) / 100;    // round to 2 decimal places
    }

    return value;
  }

  private fromDisplayNumber(value: any) {
    if (this.format === NumberFormat.Percentage) {
      value = value / 100;
    } else if (this.format === NumberFormat.Currency) {
      value = parseFloat(value);
    }

    return this.negate ? -value : value;
  }

}
