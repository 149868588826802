import { APP_ENVIRONMENT } from '@rollit/shared';
import { Inject, Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { LoggerService } from '../other/logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MoneyAccount } from '../model/money';
import { Lender, LoanScore, Profile, Finances, ScorePreferences, LoanScoreSubmission, LoanDetails } from '../model/loan1';

/**
 *
 * Things the client will need to update in the service:
 * - user "profile" - includes credit history and employment
 * - finances - household income
 * - selecting the loan account to score.
 * - attaching property to the loan account as collateral (setting up property details and estimates already covered)
 * - attaching other loan details to loan account - repayment terms and interest rate
 * - loan score preferences - loan features and lender types to filter on
*/

@Injectable()
export class Loan1Service {
    apiUrl = this.environment.apiUrl;
    private log: any;

    private _loanScoreSubject = new BehaviorSubject<LoanScore>(null);

    private _fetchingLoanScore: boolean = false;

    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private http: HttpClient,
        private logger: LoggerService,

    ) {
        this.log = this.logger.info('LoanService');
    }

    public get loanScore$(): Observable<LoanScore> {
        if (this._loanScoreSubject.getValue() == null && !this._fetchingLoanScore) {
            this.log('fetching loan score for subject');
            this.getLoanScore().subscribe();
        }

        return this._loanScoreSubject.asObservable();
    }

    /**
     * Fetch a list of lenders.
     */
    public getLenders(): Observable<Lender[]> {
        const path = '/me/loan1/lenders';

        this.log('fetching lenders');

        return this.http.get<Lender[]>(this.apiUrl + path);
    }

    /**
     * Create a loan score in one shot, giving all information necessary.
     * 
     * @param submission
     */
    public createLoanScore(submission: LoanScoreSubmission): Observable<LoanScore> {
        const path = '/me/loan1/score';

        this.log('creating loanScore');

        return this.http.post<LoanScore>(this.apiUrl + path, submission).pipe(
            map(value => {
                this._loanScoreSubject.next(value);
                this._fetchingLoanScore = false;
                return value;
            })
        );
    }


    /**
     * Get existing loan score for the user.
     */
    public getLoanScore(refresh?: boolean): Observable<LoanScore> {
        const path = '/me/loan1/score';

        this.log('fetching existing loan score');
        this._fetchingLoanScore = true;

        let params = new HttpParams();
        if (refresh) {
            params = params.set('refresh', '' + refresh);
        }

        return this.http.get<LoanScore>(this.apiUrl + path, { params: params }).pipe(
            map(value => {
                this._loanScoreSubject.next(value);
                this._fetchingLoanScore = false;
                return value;
            })
        );
    }

    /**
     * Profile and finances.
     */
    public updateProfile(profile: Profile): Observable<Profile> {
        const path = '/me/loan1/score/profile';

        return this.http.put<Profile>(path, profile);
    }

    /**
     * 
     * @param finances
     */
    public updateFinances(finances: Finances): Observable<Finances> {
        const path = '/me/loan1/score/finances';

        return this.http.put<Finances>(path, finances);
    }

    /**
     *
     * @param accountId The ID of the account to set collateral for.
     * @param collateral An account offered as security for the given account. Collateral is usually a property account.
     */
    public setCollateral(accountId: number, collateral: MoneyAccount[]): Observable<MoneyAccount> {
        const path = '/money/account/' + accountId + '/collateral';
        return this.http.put<MoneyAccount>(path, collateral);
    }

    /**
     * Update loan details.  Call this after setting collateral for loan account.
     *
     * @param accountId ID of the loan account to set loan details for.
     * @param details Loan details sich as repayment terms, interest rate.
     */
    public updateLoan(accountId: number, details: LoanDetails): Observable<MoneyAccount> {
        const path = '/me/loan1/' + accountId;

        return this.http.put<MoneyAccount>(path, details);
    }

    /**
     * Score preferences.
     * @param prefs Loan scxore preferences.
     */
    public updatePreferences(loanId: number, prefs: ScorePreferences): Observable<ScorePreferences> {
        const path = '/me/loan1/score/loan/' + loanId + '/preferences';

        return this.http.put<ScorePreferences>(path, prefs);
    }



    /* ---------------------- */

    /**
     * Get an account that the given account is collateral for.
     * This will usually be a property account.
     *
     * @param accountId
     */
    public getSecuredAccount(accountId: number): Observable<MoneyAccount> {
        // const path = null;
        // return this.http.get<MoneyAccount>(path);

        return null;
    }

    /**
     * Fetch the Rollit loan account being used for loanScore.
     */
    public getLoanAccount() {

    }

}
