import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuardService } from '@rollit/shared/services';

import { OnboardEmployeesComponent } from './onboard-employees/onboard-employees.component';
import { EmployerHomeComponent } from './employer-home/employer-home.component';
import { EmployerContainerComponent } from './employer-container/employer-container.component';
import { EmployerSubscriptionsComponent } from './employer-subscriptions/employer-subscriptions.component';
import { EmployerBillingComponent } from './employer-billing/employer-billing.component';
import { EmployeeCsvUploaderComponent } from './onboard-employees/employee-csv-uploader.component';
import { EmployerPayrollComponent } from './employer-payroll/employer-payroll.component';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';


const routes: Routes = [
  {
    path: 'employer',
    component: EmployerContainerComponent,
    data: {
      title: 'Employer administration',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    canActivate: [AppAuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'prefix'
      }, {
        path: 'dashboard',
        component: EmployerHomeComponent,
        data: { title: 'Employer Dashboard' },
        canActivate: [AppAuthGuardService],
      }, {
        path: 'onboard',
        component: OnboardEmployeesComponent,
        data: { title: 'Manage Employees' },
        canActivate: [AppAuthGuardService],
      }, {
        path: 'payroll',
        component: EmployerPayrollComponent,
        data: { title: 'Manage Payroll' },
        canActivate: [AppAuthGuardService],
      }, {
        path: 'onboard-csv',
        component: EmployeeCsvUploaderComponent,
        data: { title: 'Upload Employees' },
        canActivate: [AppAuthGuardService],
      }, {
        path: 'subscriptions',
        component: EmployerSubscriptionsComponent,
        data: { title: 'Employee Subscriptions' },
        canActivate: [AppAuthGuardService],
      }, {
        path: 'billing',
        component: EmployerBillingComponent,
        data: { title: 'Employer Billing' },
        canActivate: [AppAuthGuardService],
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class EmployerRoutingModule { }
