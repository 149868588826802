import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardInnerTopMenuComponent, InvestmentChangeComponent } from '@rollit/shared/common';
import { InvestmentsComponent } from '../investments/components/investments.component';
import { SixParkComponent } from './components/six-park/six-park.component';


const investmentsRoutes: Routes = [
  {
    path: 'investments',
    component: InvestmentsComponent,
    data: {
      title: 'Investments',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
  {
    path: 'investments/:id',
    component: InvestmentsComponent,
    data: {
      title: 'Investments',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
  {
    path: 'six-park',
    component: SixParkComponent,
    data: {
      title: '',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(investmentsRoutes)],
  exports: [RouterModule],
})
export class InvestmentsRoutingModule { }
