<mat-expansion-panel [ngClass]="{
    'child-accordion-expansion': child,
    'parent-accordion-expansion': !child
  }" class="rollit-accordion-new__panel" [expanded]="expanded" [disabled]="disabled" ngClass.gt-sm="desktop"
  (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header *ngIf="!panelOpenState" expandedHeight="*" collapsedHeight="*"
    class="rollit-accordion-new__header">
    <mat-panel-title class="rollit-accordion-new__title">
      {{ panelTitle }}
    </mat-panel-title>
    <mat-panel-description>
      {{ panelDescription }}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div *ngIf="accordionDescription" class="rollit-accordion-description padding" [innerHTML]="accordionDescription">
  </div>

  <ng-content></ng-content>
</mat-expansion-panel>
