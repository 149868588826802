import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-intercom-fab',
  templateUrl: './custom-intercom-fab.component.html',
  styleUrls: ['./custom-intercom-fab.component.scss']
})
export class CustomIntercomFabComponent implements OnInit, OnDestroy {

  intercomWindowFrame;
  intercomFrame;
  intercomDiscoveryFrame;
  intercomButton;

  constructor() { }

  ngOnInit() {
    this.intercomWindowFrame = window.frames['intercom-launcher-frame'];
    this.intercomButton = this.intercomWindowFrame.document.querySelectorAll('.intercom-launcher')[0];
    this.intercomFrame = document.querySelectorAll('iframe[name="intercom-launcher-frame"]')[0];
    this.intercomDiscoveryFrame = document.querySelectorAll('iframe[name="intercom-launcher-discovery-frame"]')[0];
    this.intercomFrame.classList.add('rollit-hide-intercom');
    this.intercomDiscoveryFrame.classList.add('rollit-hide-intercom');
  }

  ngOnDestroy() {
    this.intercomFrame.classList.remove('rollit-hide-intercom');
    this.intercomDiscoveryFrame.classList.remove('rollit-hide-intercom');
  }

  onOpenIntercom() {
    this.intercomButton.click();
  }

}
