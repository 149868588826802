import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-report-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  @Input() bg: number;
  @Input() title: string;
  constructor() { }

  ngOnInit() {
  }

}
