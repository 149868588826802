<svg version="1.1" baseProfile="full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" class="donut"
  preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 127">
  <circle class="donut-ring" cx="50%" cy="40" fill="transparent" r="42" stroke="#d2d3d4" stroke-location="inside"
    stroke-width="12"></circle>
  <circle class="donut-segment" cx="50%" cy="40" fill="transparent" r="42" stroke="#0c71b9" stroke="#0c71b9"
    stroke-dashoffset="-65" attr.stroke-dasharray="calc({{scoreIndex}} * 263.76 / 100) 263.76" stroke-width="12">
  </circle>
  <foreignObject height="60" width="40" x="29" y="11">
    <xhtml:div class="top">
      {{scoreIndex}}
    </xhtml:div>
    <xhtml:div class="bottom">
      100
    </xhtml:div>
  </foreignObject>
  <foreignObject width="100%" x="0" height="20" y="92">
    <xhtml:div class="title">
      My score
    </xhtml:div>
  </foreignObject>
</svg>
