import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { ResultList } from '../../model/result';
import { Order } from '../../model/subscription';
import { APP_ENVIRONMENT } from '@rollit/shared';


export interface DataTable {
    name?: string;
    total?: number;
    offset?: number;
    fields?: any[];
    data?: any[][];

}

@Injectable()
export class AdminReportService {
    apiUrl = this.environment.apiUrl;
    constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient, private keycloakService: KeycloakService) {
    }

    public getUnpaidOrders(): Observable<ResultList<Order>> {
        const path = '/report/rollit.InvoicesOutstanding';
        return this.http.get<ResultList<Order>>(this.apiUrl + path);
    }

    public getUserProgress(offset: number = 0, max: number = 100): Observable<DataTable> {
        const path = '/report/rollit.UserReport';
        const params = new HttpParams()
            .set('offset', '' + offset)
            .set('max', max == null ? '100' : max.toString());

        return this.http.get<DataTable>(this.apiUrl + path, { params: params });
    }

    public getSubscriptionsSummary(): Observable<any> {
        const path = '/report/rollit.SubscriptionsReport';
        const params = new HttpParams();
        return this.http.get<any>(this.apiUrl + path, { params: params });
    }
}
