import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-cashflow-expenses-stress-graphic',
  templateUrl: './cashflow-expenses-stress-graphic.component.html',
  styleUrls: ['./cashflow-expenses-stress-graphic.component.scss']
})
export class CashflowExpensesStressGraphicComponent implements OnInit {
  @Input() expensesStressResult: string;
  constructor() { }

  ngOnInit() {
  }

}
