<h1 mat-dialog-title>GENERAL ADVICE</h1>
<div mat-dialog-content>
    <p>This platform provides general information only and does not take into account your personal objectives, financial
        situation or needs. You should assess whether the information is appropriate for you having regard to your
        objectives, financial situation and needs and consider obtaining independent professional advice before making an
        investment decision. If information relates to a specific financial product you should obtain a copy of the product
        disclosure statement for that product and consider that statement before make a decision whether to acquire the
        product.</p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class='rollit-button ' color="primary" mat-dialog-close cdkFocusInitial>ACKNOWLEDGE</button>
</div>