import { QuestionBase } from './question-base';

export class CheckboxQuestion extends QuestionBase<string> {
  controlType = 'checkbox';
  isChecked = false;

  constructor(options: {} = {}) {
    super(options);
    this.isChecked = options['isChecked'] || false;
  }
}
