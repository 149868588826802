import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { SuperannuationComponent } from './components/superannuation.component';
import { SuperannuationRoutingModule } from './superannuation-routing.module';
import { SuperannuationService } from './superannuation.service';
import { SuperannuationHeroComponent } from './components/superannuation-hero/superannuation-hero.component';
import { SuperannuationProjectionsComponent } from './components/superannuation-projections/superannuation-projections.component';
import { SuperannuationFundDetailComponent } from './components/superannuation-fund-detail/superannuation-fund-detail.component';


@NgModule({
  declarations: [
    SuperannuationComponent,
    SuperannuationHeroComponent,
    SuperannuationProjectionsComponent,
    SuperannuationFundDetailComponent,
  ],
  imports: [
    CommonModule, RollitCommonModule, SuperannuationRoutingModule
  ],
  providers: [
    SuperannuationService
  ]
})
export class SuperannuationModule { }
