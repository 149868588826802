import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService, MoneyService, Transaction } from "@rollit/shared/data";

import { UIManagerService } from "@rollit/shared/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { trackById } from '@rollit/shared/utils';
import { each } from "highcharts";

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit, OnDestroy {
  private log: any;
  destroy = new Subject();
  trackById = trackById;
  transaction: Transaction;
  selectedTransaction: any;
  mediaSizeIsDesktop: boolean;
  transactionId: number;
  accountId: number;

  topCategoriesActive: boolean;
  categoriesActive: boolean;

  topCategories: any = [];
  categories: any = [];

  selectedTopCategory: any;
  selectedCategory: any;

  constructor(
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
  ) {
    this.log = this.logger.info('TransactionsComponent');
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.mediaSizeIsDesktop = mediaSizeIsDesktop;
      });
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: { id: string }) => {
      this.log('route params', params);
      if (params.id === 'topCategories') {
        this.topCategoriesActive = true;
        this.getCategories();
        console.log('selectedTransaction', this.selectedTransaction)
      } else if (params.id === 'categories') {
        this.categoriesActive = true;
      } else if (params.id !== 'none') {
        const paramSplit = params.id.split('-');
        this.accountId = +paramSplit[0];
        this.transactionId = +paramSplit[1];
      } else {
        this.router.navigate(['/transactions']);
      }
    });
  }

  ngOnInit() {
    if (this.transactionId) {
      this.moneyService.getTransaction(this.accountId, this.transactionId).subscribe(response => {
        this.log('getTransaction', response);
        this.transaction = response;
        let topCategory = 'Uncategorised';
        let category = 'Uncategorised';
        if (response?.topCategory?.name) {
          topCategory = response?.topCategory?.name;
        }
        if (response?.category?.name) {
          category = response?.category?.name;
        }
        const newTransaction = {
          id: response.id,
          accountId: response.account.id,
          nickname: response.account.nickname,
          date: response.date,
          amount: response.amount,
          description: response.descriptionPretty,
          category: category,
          topCategory: topCategory,
        };
        this.selectedTransaction = newTransaction;
        this.getCategories();
      });
    }

  }

  ngOnDestroy() {
    this.uiService.sideNavIsVisible.next(false);
    this.destroy.next(); this.destroy.complete();
  }

  getCategories() {
    this.moneyService.getCategories().subscribe(categoriesResponse => {
      this.log('getCategories', categoriesResponse);
      //this.categories = response;



      categoriesResponse.forEach(categoryResponse => {

        if (categoryResponse.name === this.selectedTransaction.topCategory) {
          this.selectedTopCategory = categoryResponse;
        }
        if (categoryResponse.name === this.selectedTransaction.category) {
          this.selectedCategory = categoryResponse;
        }

        if (categoryResponse.source === 'user') {
          this.topCategories.push(categoryResponse);
        }

        if (categoryResponse.source === 'yodlee') {
          this.categories.push(categoryResponse);
        }

      });

      this.log('topCategories', this.topCategories);
      this.log('categories', this.categories);

    });
  }

  manageAccounts() {
    this.router.navigate(['/accounts/manage-accounts']);
  }

  openTopCategories() {
    this.log('openTopCategories');
    this.topCategoriesActive = !this.topCategoriesActive;
  }

  openCategories() {
    this.log('openCategories');
    this.categoriesActive = !this.categoriesActive;

  }

  selectTopCategory(category) {
    this.log(category);
    this.topCategoriesActive = false;
    this.transaction.topCategory = category;

    this.moneyService.updateTransaction(this.transaction.id, this.transaction).subscribe(response => {
      this.log('updateTransaction', response);
      this.selectedTransaction.topCategory = category.name;
      this.getCategories();
    });
  }

  selectCategory(category) {
    this.log(category);
    this.categoriesActive = false;
    this.transaction.category = category;

    this.moneyService.updateTransaction(this.transaction.id, this.transaction).subscribe(response => {
      this.log('updateTransaction', response);
      this.selectedTransaction.category = category.name;
      this.getCategories();
    });
  }

  onReturnClick() {
    this.topCategoriesActive = false;
    this.categoriesActive = false;
    this.router.navigate(['/transactions']);
  }


}
