import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Nudge, NudgeCard } from '@rollit/shared/data';
@Component({
  selector: 'app-nudge-summary',
  templateUrl: './nudge-summary.component.html',
  styleUrls: ['./nudge-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NudgeSummaryComponent implements OnInit {

  @Input() nudge: NudgeCard;
  @Input() closeButton: boolean;
  @Input() activeItem: boolean;

  @Output() closed = new EventEmitter();
  @Output() buttonPressed = new EventEmitter();

  headingCurrency: boolean;
  icon: string;
  title: string;

  iconNames = [
    { 'section': 'savings', 'icon': 'savings', 'title': 'Savings coach' },
    { 'section': 'lifestyle', 'icon': 'directions_car', 'title': 'Lifestyle asset coach' },
    { 'section': 'investments', 'icon': 'stacked_line_chart', 'title': 'Investment coach' },
    { 'section': 'investment', 'icon': 'stacked_line_chart', 'title': 'Investment coach' },
    { 'section': 'superannuation', 'icon': 'insights', 'title': 'Super coach' },
    { 'section': 'property', 'icon': 'domain', 'title': 'Property coach' },
    { 'section': 'realty', 'icon': 'domain', 'title': 'Property coach' },
    { 'section': 'credit', 'icon': 'credit_card', 'title': 'Debt coach' },
    { 'section': 'loan', 'icon': 'credit_card', 'title': 'Debt coach' }
  ];

  ngOnInit(): void {
    this.iconNames.forEach((item) => {
      if (this.nudge.section === item.section) {
        this.icon = item.icon;
        this.title = item.title;
      }
    });

    // console.log('Nudge component: ', this.nudge.section);
  }

  onClosed() {
    this.closed.next();
  }

  onButtonPressed() {
    this.buttonPressed.next();
  }
}
