export enum PropertyType {
    String = 'String',
    Number = 'Number',
    Boolean = 'Boolean',
    Date = 'Date',
    Object = 'Object',
    List = 'List',
  }

export interface Property {
    name: string;
    value: any;
    type: PropertyType;
}
