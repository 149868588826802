import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { PaymentMethod, PaymentDetails } from '../model/payment';


@Injectable()
export class PaymentService {

  constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient) {
  }

  public submitPaymentMethod(paymentDetails: PaymentDetails): Observable<PaymentMethod> {
    const path = '/me/payment/method';
    return this.http.post<PaymentMethod>(this.environment.apiUrl + path, paymentDetails);
  }

  public getPaymentMethods(id: number): Observable<PaymentMethod> {
    const path = '/me/payment/method';
    return this.http.get<PaymentMethod>(this.environment.apiUrl + path);
  }

  public getPaymentMethod(id: number): Observable<PaymentMethod> {
    const path = '/payment/method/' + id;
    return this.http.get<PaymentMethod>(this.environment.apiUrl + path);
  }

  public removePaymentMethod(id: number): Observable<void> {
    const path = '/payment/method/' + id;
    return this.http.delete<void>(this.environment.apiUrl + path);
  }
}
