import { Inject, Injectable } from "@angular/core";
import { DynamicScriptLoaderService } from "./dynamic-script-loader.service";
import { KeycloakService, KeycloakEventType, KeycloakEvent, KeycloakInitOptions } from "keycloak-angular";
import { AuthService, TokenInfo } from "./auth.service";
import { LoggerService } from './logger.service';
import { APP_ENVIRONMENT } from '@rollit/shared';

const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
];


@Injectable()
export class PlatformService {

    private _embedded: boolean = false;
    private _platform: string = null;

    private log: any;
    private webViewInterface: any;
    private _hostVersion: number;

    constructor(
        private scriptLoader: DynamicScriptLoaderService,
        private keycloakService: KeycloakService,
        private auth: AuthService,
        private logger: LoggerService,
        @Inject(APP_ENVIRONMENT) private environment: any
    ) {

        this.log = this.logger.info('platformService');
        this.log("constructing PlatformService");
        this.log("constructing PlatformService environment prod", environment.production);
        this.checkPlatform();

        if (this.embedded /*&& this.isIos*/) {
            this._initEmbedded();
        }
    }

    get embedded(): boolean {
        return this._embedded;
    }

    get platform(): string {
        return this._platform;
    }

    get isIos() {
        let isIos = this._platform === 'ios';

        if (!isIos && !!navigator.platform) {
            isIos = iDevices.some(value => navigator.platform === value);
            if (isIos) {
                this._platform = 'ios'; // for quicker check
            }
        }
        return isIos;
    }

    get isAndroid() {
        return this._platform === 'android';
    }

    /**
     * Check what platform the app is running in.
     */
    private checkPlatform() {
        this.log('Navigator userAgent', navigator.userAgent);
        this.log('Navigator platform', navigator.platform);

        const embeddedParam = window && new URL(window.location.href).searchParams.get("embedded");        // check for standlone query parameter

        if (navigator.userAgent.indexOf("Hey Coach") >= 0 || navigator.userAgent.indexOf("Roll-it") >= 0) {            // running from within the Hey Coach App
            this.log("Agent value means this app is embeded");

            this._embedded = true;
            try {
                const agent = navigator.userAgent;
                const version = agent.replace(/[^0-9]/g, '');
                this._hostVersion = Number(version);
            } catch (e) {
            }
        } else if (embeddedParam === 'true') {
            this._embedded = true;
        } else if (embeddedParam === 'false') {
            this._embedded = false;
        }

        // check client platform
        if (navigator.userAgent.indexOf('iOS') >= 0) {
            this._platform = "ios";
        } else if (navigator.userAgent.indexOf('Android') >= 0) {
            this._platform = "android";
        }
        this.log('this._platform', this._platform);
    }

    public get useInAppPurchases() {
        return this.embedded && this.isIos;
    }

    public get hostVersion() {
        return this._hostVersion;
    }

    /**
     * Emit an event to native platform embedding this web app.
     *
     * @param event Name of the event
     * @param value A value to pass with the event
     */
    public emit(event: string, value: string) {
        if (this.webViewInterface) {
            this.webViewInterface.emit(event, value);
        }
    }

    /**
     * Set up the embedded Javascript bridge.
     */
    private _initEmbedded() {
        // load the web view interface library
        this.scriptLoader.load("nativescriptWebview")
            .then(() => {
                this.log("loaded webview interface");
                this.webViewInterface = window['nsWebViewInterface']; // get the webview interface object

                // ensure access token is emitted
                // this.keycloakService.getToken().then(token => {
                //     theToken = token;
                //     this.emit('accessToken', token);
                // });
                this.keycloakService.keycloakEvents$.subscribe((event: KeycloakEvent) => {
                    if (event.type === KeycloakEventType.OnAuthSuccess || event.type === KeycloakEventType.OnAuthRefreshSuccess) {
                        const accessToken = this.keycloakService.getKeycloakInstance().token;
                        const refreshToken = this.keycloakService.getKeycloakInstance().refreshToken;
                        this.keycloakService.getToken().then(token => {
                            this.emit('token', JSON.stringify({
                                access_token: accessToken,
                                refresh_token: refreshToken,
                            }));
                        });
                    }
                });

                // listen for the auth token info from the host
                this.webViewInterface.on("token", (value: string) => {
                    this.log("web app received token", value);

                    const keycloak = this.keycloakService.getKeycloakInstance();
                    const tokens: TokenInfo = JSON.parse(value);
                    if (!keycloak || !keycloak.authenticated) {
                        this.auth.initWithToken(tokens);
                    } else if (keycloak.token !== tokens.access_token) {
                        keycloak.token = tokens.access_token;
                        keycloak.refreshToken = tokens.refresh_token;
                    }
                });

                this.webViewInterface.emit("loaded", "");   // let host know that the webview interface has been loaded.
            })
            .catch(reason => {
                this.log("+++ failed to load nativescript webview script", reason);
            });
    }
}
