import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FundService } from './service/fund.service';
import { MoneyService } from './service/money.service';
import { MeService } from './service/me.service';
import { FileService } from './service/file.service';
import { PaymentService } from './service/payment.service';
import { MessageService } from './service/message.service';
import { SubscriptionService } from './service/subscription.service';
import { EmployerService } from './service/employer.service';
import { Loan1Service } from './service/loan1.service';
import { DialogService } from './service/dialog.service';
import { RealtyService } from './service/realty.service';
import { LoanService } from './service/loan.service';
import { PayAndBenefitsService } from './service/benefits.service';
import { GuideService } from './service/guide.service';
import { PlatformService } from './other/platform.service';
import { DynamicScriptLoaderService } from './other/dynamic-script-loader.service';
import { NotificationService } from './other/notification.service';
import { LoggerService } from './other/logger.service';
import { ConsoleLoggerService } from './other/console-logger.service';
import { AdminEmployerService } from './service/admin/admin-employer.service';
import { AdminReportService } from './service/admin/admin-report.service';
import { AdminFundService } from './service/admin/admin-fund.service';
import { CommsLogService } from './service/admin/comms-log.service';
import { OrderService } from './service/order.service';
import { ReportService } from './service/report.service';
import { YodleeService } from './service/yodlee.service';
import { NudgeService } from './service/nudge.service';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    // Web API services
    PayAndBenefitsService,
    DialogService,
    EmployerService,
    FileService,
    FundService,
    GuideService,
    LoanService,
    Loan1Service,         // newer loan score service
    MessageService,
    MoneyService,
    NudgeService,
    OrderService,
    PaymentService,
    RealtyService,
    ReportService,
    SubscriptionService,
    MeService,
    YodleeService,

    // Admin-related API services
    AdminEmployerService,
    AdminFundService,
    CommsLogService,
    AdminReportService,

    // Other services
    LoggerService,
    NotificationService,
    PlatformService,
    DynamicScriptLoaderService,
    {
      provide: LoggerService,
      useClass: ConsoleLoggerService
    }
  ],
})
export class DataServicesModule {}
