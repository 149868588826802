import { MeService, Notification } from '@rollit/shared/data';
import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { environment } from '@src-rollit/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { UIManagerService } from '@rollit/shared/services';
import { RequestCounter } from '@rollit/shared/utils';
import { Subscription, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { timer } from 'rxjs/internal/observable/timer';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { MatDialog } from '@angular/material/dialog';
import { AddHomeScreenIosComponent } from '@rollit/shared/info';
import { AddHomeScreenAndroidComponent } from '@rollit/shared/info';
import { EnableCookiesComponent } from '@rollit/shared/info';
import { LoggerService } from '@rollit/shared/data';
import "hammerjs";
import { NotificationService } from '@rollit/shared/data';
import { PlatformService } from '@rollit/shared/data';
import { HttpClient } from '@angular/common/http';
import { User } from '@rollit/shared/data';
import { Angulartics2 } from 'angulartics2';


declare var Intercom; // defined by included Intercom javascript.
declare var build: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private title = 'app';
  siteReady: boolean;
  loadingScreenIsVisible = true;
  requestCount = 0;
  loaderTimer: any;
  loaderSubscription: any;

  mediaSizeIsDesktop = false;

  topNavIsPrimary: boolean;
  appBackgroundIsPrimary: boolean;

  showTopNav = false;
  showSideNav = false;
  showFooterNav = false;

  loaderIsVisible = false;

  displayContentAsFullPage: boolean;

  filterMenuIsOpen: boolean;
  createLoanIsOpen: boolean;
  drawerNavigationIsOpen: boolean;

  scrollBottom = false;

  shownAddToHomeDialog = false;
  addHomeScreenIOS = false;
  addHomeScreenAndroid = false;

  isIos = false;
  isAndroid = false;
  isEmbedded = false;
  platform: string;

  debugParams: any;
  me: User;
  intercomStarted = false;
  cookiesAreEnabled: boolean;

  setupChecked: boolean;

  path = '';
  private log: any;
  buildVersion = build;
  private userSubscription: Subscription;
  @ViewChild('contentMain') contentMain: ElementRef;
  @ViewChild('body') intercomContainer: ElementRef;

  //
  // App component constructor.
  //
  constructor(
    private router: Router,
    private uiService: UIManagerService,
    private requestCounter: RequestCounter,
    private renderer: Renderer2,
    private userService: MeService,
    private titleService: Title,
    private logger: LoggerService,
    // private messageService: MessageService,       // for async messages from the web service
    private notificationService: NotificationService,
    private platformService: PlatformService,
    private angulartics2: Angulartics2,
    public dialog: MatDialog,
    private http: HttpClient,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private cdr: ChangeDetectorRef,) {

    // setup custom logger
    this.log = this.logger.info('appComponent');

    // setup request counter
    this.requestCounter.subscribe(
      (count: number) => {
        this.requestCount = count;
      }
    );

    this.isIos = this.platformService.isIos;
    this.isAndroid = this.platformService.isAndroid;
    this.isEmbedded = this.platformService.embedded;
    this.platform = this.platformService.platform;

    this.log("isIos: " + this.isIos + ", isAndroid: " + this.isAndroid + ", embedded: " + this.isEmbedded + ", platform: " + this.platform);
    this.log("### User-agent header sent: " + navigator.userAgent);


    // this.messageService.messages$.subscribe((notification: Notification) => {
    //   this.log('Got notification', notification);
    //if (notification.type === 'track') {
    //  this.notificationService.info("Your money", notification.message, '/track/connect');
    //}
    // });

  }

  //
  // setup listeners

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const newSize = event.target.innerWidth > 1240;
    if (newSize !== this.mediaSizeIsDesktop) {
      this.log(event.target.innerWidth);
      if (newSize) {
        this.uiService.mediaSizeIsDesktop = true;
      } else {
        this.uiService.mediaSizeIsDesktop = false;
      }
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    this.log('beforeinstallprompt: event');
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMove(e) {
    const p = e.target as HTMLElement;
    if (p.classList.contains('add-touch')) {
      e.preventDefault();
    }
  }


  ngOnInit() {

    this.userService.getMe().subscribe(value => {
      this.me = value;
      this.log('getMe()', value);
      this.initialise();
    }); // fetch the current user.

    // de-register existing service workers when we are not using service worker.
    if (!environment.useServiceWorker) {
      this.log('looking for service workers to de-register');
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              //this.log('deregistering service worker');
              registration.unregister();
            }
          });
      }
    }

    // fade out the loader
    this.fadeOutLoader();

    // If in standalone mode make the status bar on iOS $primary by setting body background to $primary
    if (this.uiService.isStandalone) {
      this.renderer.addClass(document.body, 'standalone');
    }

    // If iPhone remove link to manifest
    const iOS = !!navigator.platform && /iPhone/.test(navigator.platform);
    if (iOS) {
      this.renderer.setAttribute(document.querySelector('link[rel="manifest"]'), 'rel', 'no-on-ios');
    }

  }


  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  initialise() {

    this.userSubscription = this.userService.me$.subscribe(
      value => {
        if (value) {
          this.log('userSubscription me', value);
          this.me = value;
          this.siteReady = true;
          this.subscribeToUIEvents();
        }
      }
    );


    // start google analytics
    this.angulartics2.setUsername.next(this.me.id.toString());
    this.angulartics2GoogleAnalytics.startTracking();

    // start Intercom
    this.startIntercom();


    this.subscribeToRouter();
    //

    // Check disclaimer
    if (!this.me.extra.acceptedDisclaimer && this.me.subscriptions.length > 0) {
      this.router.navigate(['/privacy']);
    } else {

      this.checkUserSetupProgress();

      this.checkCookiesEnabled();

    }
  }

  subscribeToUIEvents() {
    // setup UI vars
    this.uiService.appBackgroundIsPrimary.subscribe(val => this.appBackgroundIsPrimary = val);
    this.uiService.mediaSizeIsDesktop$.subscribe(mediaSizeIsDesktop => this.mediaSizeIsDesktop = mediaSizeIsDesktop);
    this.uiService.loadingScreenIsVisible.subscribe(val => this.loadingScreenIsVisible = val);
    this.uiService.loaderIsVisible.subscribe(val => this.loaderIsVisible = val);
  }



  subscribeToRouter() {

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        this.log('Router.events', event);

        this.checkNewBuild();

        if (!event.url.includes("survey")) {
          this.checkUserSetupProgress();
        }

        this.setBrowserTitleOnNavigationChange();
        this.path = (<NavigationEnd>event).url;

      }
    });
  }

  checkUserSetupProgress() {
    this.log('checkUserSetupProgress', this.me);
    if (this.me?.extra?.setupStatus !== 'completed' && environment.production) {
      this.router.navigate(['/complete-setup']);
    } else {
      this.setupChecked = true;
    }
  }

  //
  // Start the intercom.io chat service.
  //
  startIntercom() {
    if (this.intercomStarted === false) {
      this.intercomStarted = true;
      Intercom('boot', {
        app_id: environment.intercom.appId,
        name: this.me.firstName + ' ' + this.me.lastName,
        email: this.me.email,
      });

      if (this.isEmbedded) {
        Intercom('trackEvent', 'web-app-loaded-mobile');
      } else {
        Intercom('trackEvent', 'web-app-loaded-web');
      }

    }
  }

  //
  // Check whether there is a new build.
  //
  checkNewBuild() {

    const nowDate = new Date();
    let lastDate = new Date();
    const lastUpdate = localStorage.getItem('last_build_update_date');

    if (lastUpdate === null) {
      lastDate.setMinutes(lastDate.getMinutes() + 30);
      localStorage.setItem('last_build_update_date', lastDate.toString());
    } else {
      lastDate = new Date(lastUpdate);
    }

    this.log('now_date ' + nowDate + ' > ' + 'last_date' + lastDate);

    if (nowDate > lastDate) {
      this.log('now_date this.checkNewBuild');
      nowDate.setMinutes(nowDate.getMinutes() + 30);
      localStorage.setItem('last_build_update_date', nowDate.toString());
      // check the new build number


      this.getVersionJSON().subscribe(data => {
        this.log('getVersionJSON', data);
        if (data.build !== null) {
          const nowBuild = data.build;
          const lastBuild = this.buildVersion;
          this.log('current: ' + lastBuild + ' JSON: ' + nowBuild);
          if (lastBuild < nowBuild) {
            caches.keys().then(cs => cs.forEach(c => caches.delete(c)));
            // force reload
            const random = Date.now() + '-' + Math.floor(Math.random() * 500000 + 1000000);
            const uri = this.updateQueryStringParameter(window.location.href, 'cache', random);
            // console.log(uri);
            window.location.href = uri;
          }
        }
      });

    }

  }

  getVersionJSON(): Observable<any> {
    const randomVer = Math.floor(Math.random() * 500000 + 1000000);
    const versionURI = "/version.json?cache=" + randomVer;
    return this.http.get(versionURI);
  }

  updateQueryStringParameter(uri, key, value) {
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      return uri + separator + key + "=" + value;
    }
  }
  //
  // Check whether cookies are enabled.
  //
  checkCookiesEnabled() {
    try {
      localStorage.setItem('checkCookiesEnabled', 'testValue');
      if (!localStorage.getItem('checkCookiesEnabled')) {
        this.cookiesAreEnabled = false;
        const dialogRef = this.dialog.open(EnableCookiesComponent);
      } else {
        this.cookiesAreEnabled = true;
      }
    } catch (e) {
      this.cookiesAreEnabled = false;
      const dialogRef = this.dialog.open(EnableCookiesComponent);
    }
  }

  //
  // Prompt iOS users to add to home screen.
  //
  checkiOSAddtoHomeScreen() {
    const isIos = this.isIos;
    const now_date = new Date();
    const last_update = localStorage.getItem("addtohomescreen_update_date");
    const last_date = new Date(last_update);
    last_date.setDate(last_date.getDate() + 30);
    const winNavigator: any = window.navigator;
    const standaloneParam = new URL(window.location.href).searchParams.get("standalone");
    let isStandalone = false;
    let showMessage = false;

    if (winNavigator.standalone || standaloneParam === 'true') {
      isStandalone = true;
      const currentDate = new Date().toDateString();
      localStorage.setItem("addtohomescreen_update_date", currentDate);
    }
    if ((now_date > last_date) || last_update === null) {
      showMessage = true;
    }
    this.log('this.me.extra.acceptedDisclaimer', this.me.extra.acceptedDisclaimer);
    // Checks if should display install popup notification:
    if (isIos && this.me.extra.acceptedDisclaimer && this.me.subscriptions.length > 0 && showMessage === true && isStandalone === false && !this.isEmbedded) {
      this.addHomeScreenIOS = true;
      const dialogRef = this.dialog.open(AddHomeScreenIosComponent);
      Intercom('trackEvent', 'web-app-ios-addtohomescreen-open');
    }
  }

  // Prompt Android users to add to home screen.
  //
  checkAndroidAddtoHomeScreen() {
    this.log('checkAndroidAddtoHomeScreen');
    const isAndroid = this.isAndroid;
    const now_date = new Date();
    const last_update = localStorage.getItem("downloadapp_android_update_date");
    const last_date = new Date(last_update);
    last_date.setDate(last_date.getDate() + 30);
    const winNavigator: any = window.navigator;
    const standaloneParam = new URL(window.location.href).searchParams.get("standalone");
    let isStandalone = false;
    let showMessage = false;

    if (winNavigator.standalone || standaloneParam === 'true') {
      isStandalone = true;
      const currentDate = new Date().toDateString();
      localStorage.setItem("downloadapp_android_update_date", currentDate);
    }
    if ((now_date > last_date) || last_update === null) {
      showMessage = true;
    }

    // Checks if should display install popup notification:
    if (isAndroid && this.me.extra.acceptedDisclaimer && this.me.subscriptions.length > 0 && showMessage === true && isStandalone === false && !this.isEmbedded) {
      this.addHomeScreenAndroid = true;
      const dialogRef = this.dialog.open(AddHomeScreenAndroidComponent);
      Intercom('trackEvent', 'web-app-android-addtohomescreen-open');
    }
  }

  //
  // Fade out the loader
  //
  fadeOutLoader() {
    // Remove the initial loader
    this.renderer.removeChild(document.body, document.getElementById('loader'));

    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }

    // Remove the loader
    this.loaderTimer = timer(1200);
    this.loaderSubscription = this.loaderTimer.subscribe(() => {
      this.uiService.loadingScreenIsVisible.next(false);
    });
  }

  //
  // Set the page title and ensure the page is scrolled to 0
  //
  setBrowserTitleOnNavigationChange() {
    const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' - ');
    this.titleService.setTitle(title ? title + ' - Hey coach' : 'Hey coach');
    document.querySelector('.main').scrollTop = 0;
    document.querySelector('body').scrollTop = 0;
    this.log('scrollTop');
  }

  //
  // collect that title data properties from all child routes
  // there might be a better way but this worked for me.
  //
  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  private shouldShowTopNav() {
    this.log('shouldShowTopNav', !this.path.startsWith('/?', 0), (this.me && this.me.subscriptions.length > 0));
    return !this.path.startsWith('/?', 0) || (this.me && this.me.subscriptions.length > 0);
  }

}
