<div class="rollit-grid">
  <div class="rollit-grid-2">

    <div [ngClass]="!isDesktop  ? '':'group-panel'"
         *ngIf="nudges.length > 0 && ready">
      <app-nudge-expandable *ngFor="let nudge of nudges; let i = index"
                            [id]="i"
                            [activeItem]="i === activeItem"
                            [nudge]="nudge"
                            [isDesktop]="isDesktop"
                            (closed)="onClosed(nudge)"
                            (buttonPressed)="onButtonPressed(nudge)"
                            (click)="i !== activeItem && expandItem($event, i)"
                            [ngClass]="{'active':i === activeItem}">
      </app-nudge-expandable>
    </div>



    <div *ngIf="nudges.length === 0 && ready"
         class="group-panel blank">
      <span class="copy heading1">Coach insights</span>

      <span class="copy paragraph1">Hey Coach searches for new opportunities to help you make your next financial move
        with confidence. Check back each week for new coaching insights personalised to your financial situation.</span>

      <img class="image"
           src="/assets/images/example/nudges-example.png" />
    </div>


  </div>
  <div class="rollit-grid-1"></div>
</div>