<div class="main-content-section">
  <div class="section-content">
    <form id="second-step-form" *ngIf="isLoaded">

      <!-- <div *ngIf="isFree && !noSignup" class="free">
        <div *ngIf="showUpgrade">
          <h1>Hi {{user?.firstName}}</h1>
          <h2>Upgrade your subscription at anytime to build wealth faster.</h2>
          <h4>Your upgrade benefits</h4>
          <div class="items">

            <div class="item">
              <i class="material-icons icon">shopping_cart</i>
              <div class="copy">
                <h3>Everyday rewards</h3>
                <p>- Program increases from 200 to 4,500 discounted offers
                  <br>- No check-out merchant fees saving you $150* a year.
                  <br><span class="small">*Assumes average spend of $250 per week on groceries and petrol.</span></p>
              </div>
            </div>

            <div class="item">
              <i class="material-icons icon">attach_money</i>
              <div class="copy">
                <h3>Your money</h3>
                <p>- Stay connected with automated data feeds from your bank, super fund, and credit card providers.</p>
              </div>
            </div>

            <div class="item">
              <span class="icon-super_fund filter-icon icon"></span>
              <div class="copy">
                <h3>Super fund</h3>
                <p>- Sign-up connection and ATO choice of fund form generation to simplify changing super funds.</p>
              </div>
            </div>

            <div class="item">
              <span class="icon-house filter-icon icon"></span>
              <div class="copy">
                <h3>Property and lending</h3>
                <p>- Add multiple properties to create your property portfolio.</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!showUpgrade">
          <h1>Hi {{user?.firstName}}</h1>
          <h2>It looks like we're good to go!</h2>
        </div>
        <app-free-details [product]="product" [subscriberType]="type" [signupError]="signupError"
          [price]="price" (submitOrder)="submitOrder($event)"></app-free-details>
      </div> -->

      <div *ngIf="!isFree && !noSignup">
        <h1><strong>Payment.</strong> Access your platform.</h1>

        <div class="user" *ngIf="!isEmployer">
          For {{user?.firstName}} {{user?.lastName}}, username {{user?.username}}.
        </div>
        <div class="user" *ngIf="isEmployer">
          For employer {{signup?.employer?.name}}
        </div>

        <h3>{{text1}} <span>{{text2}}</span></h3>

        <div fxLayout="row" fxLayoutAlign="space-around center" id="employee-number" *ngIf="isEmployer">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutGap="15px">
            <div class="label">CONFIRM NUMBER OF EMPLOYEES</div>
            <mat-form-field>
              <input matInput [formControl]="numberOfEmployeesControl">
            </mat-form-field>
          </div>
          <div class="spacer" [ngStyle.lt-sm]="{'display': 'none'}"></div>
        </div>

        <app-individual-plan-information *ngIf="!isEmployer" [product]="product" [price]="price" [discount]="discount"
          [billingCycle]="billingCycle" (discountCode)="onDiscountCode($event)" (submitOrder)="submitOrder($event)"
          [signupError]="signupError"></app-individual-plan-information>
        <app-company-plan-information *ngIf="isEmployer" [product]="product" [price]="price" [discount]="discount"
          [billingCycle]="billingCycle" [annualSubscription]="annualSubscription"
          [monthlySubscription]="monthlySubscription" [numberOfEmployeesControl]="numberOfEmployeesControl"
          [numberOfEmployees]="numberOfEmployees" (discountCode)="onDiscountCode($event)"
          (submitOrder)="submitOrder($event)" [signupError]="signupError"></app-company-plan-information>
      </div>

      <div *ngIf="noSignup">
        <div>
          Welcome to Rollit {{user?.username}}.
        </div>
        <br />
        <button mat-raised-button class="rollit-button " color="primary" [routerLink]="[ '/' ]">Get started</button>
        <div>
          <a [routerLink]="" (click)="logout()">Logout</a>
        </div>
      </div>

    </form>
  </div>
</div>
