<div class="holder" [ngClass]="{'desktop':isDesktop}">

  <div class="bar-chart">
    <app-bar-chart-time *ngIf="projectionChartData?.length > 0 && inView" class="chart"
      [seriesData]="projectionChartData" [seriesCategories]="projectionChartCategories" [title]="'Property projections'"
      (graphClicked)="graphClicked($event[0], $event[1])" [showExpand]="false" [smallTitle]="true">
    </app-bar-chart-time>
    <div *ngIf="!inView" class="heading1 heading2">Property projections</div>
  </div>

  <div class="details">
    <div class="titleGroup">
      <app-title-group [details]="titleGroupDetails">
      </app-title-group>
    </div>

    <div class="detail-list" *ngIf="propertyInformation">

      <div class="list-item">
        <span class="paragraph2">Your price (current)</span>
        <span class="paragraph2"
          *ngIf="propertyInformation.userEstimateValue">{{propertyInformation.userEstimateValue  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <ng-container *ngIf="propertyInformation.estimate">

        <div class="list-item sub">
          <span class="paragraph2">Automated valuation</span>
          <span class="paragraph2"
            *ngIf="propertyInformation.estimate">{{propertyInformation.estimate  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
        </div>

        <div class="list-item sub">
          <span class="paragraph2">High range estimate</span>
          <span class="paragraph2"
            *ngIf="propertyInformation.estimateHigh">{{propertyInformation.estimateHigh  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
        </div>

        <div class="list-item sub">
          <span class="paragraph2">Low range estimate</span>
          <span class="paragraph2"
            *ngIf="propertyInformation.estimateLow">{{propertyInformation.estimateLow  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
        </div>
      </ng-container>

      <div class="list-item" *ngIf="propertyInformation.growthRate">
        <span class="paragraph2">Valuation growth</span>
        <span class="paragraph2">{{propertyInformation.growthRate  | percent:'1.2'}}</span>
      </div>

      <div class="list-item" *ngIf="propertyInformation.loanOutstanding">
        <span class="paragraph2">Outstanding mortgage (current)</span>
        <span
          class="paragraph2">{{ propertyInformation.loanOutstanding | currency: 'AUD':'symbol-narrow':'1.0-0' }}</span>
      </div>

      <div class="list-item" *ngIf="propertyInformation.interestRate">
        <span class="paragraph2">Mortgage interest rate</span>
        <span class="paragraph2">{{propertyInformation.interestRate | percent:'1.2'}}</span>
      </div>

      <div class="list-item" *ngIf="propertyInformation.repaymentAmount">
        <span class="paragraph2">Monthly repayments</span>
        <span class="paragraph2">{{propertyInformation.repaymentAmount | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>
    </div>
  </div>
</div>
