import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { AppAuthGuardService } from '@rollit/shared/services';

const infoRoutes: Routes = [
  { path: 'info/about', component: AboutComponent, canActivate: [AppAuthGuardService] },
];

@NgModule({
  imports: [
    RouterModule.forChild(infoRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class InfoRoutingModule { }
