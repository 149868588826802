<div class="wrapper" fxLayout="column" [ngClass]="{'desktop': isDesktop}">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>Your personal information</h1>
  </div>

  <form id="titleFormGroup" [formGroup]="titleFormGroup" (submit)="onSubmitPersonalInformation(titleFormGroup, 'title')"
    *ngIf="titleFormGroup">
    <app-form-field-item [(editMode)]="editMode.title" [isLocked]="false" (completeClicked)="hiddenTitleButton.click()"
      (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusSelect(titleSelect)"
      [isValid]="titleFormGroup.controls['title'].valid">
      <div class="detail">
        <span class="label">Title</span>
        <span class="value title">{{ profile.title }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <mat-select #titleSelect placeholder="Title" formControlName="title" required>
          <mat-option value="Mr">Mr</mat-option>
          <mat-option value="Mrs">Mrs</mat-option>
          <mat-option value="Miss">Miss</mat-option>
          <mat-option value="Ms">Ms</mat-option>
          <mat-option value="Prof">Prof</mat-option>
        </mat-select>
        <mat-error *ngIf="titleFormGroup.controls['title'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenTitleButton>Hidden Button</button>
  </form>

  <form id="firstNameFormGroup" [formGroup]="firstNameFormGroup"
    (submit)="onSubmitPersonalInformation(firstNameFormGroup, 'firstName')" *ngIf="firstNameFormGroup">
    <app-form-field-item [(editMode)]="editMode.firstName" [isLocked]="false"
      (completeClicked)="hiddenFirstNameButton.click()" (cancelClicked)="setupPersonalInformationForm()"
      (editClicked)="focusInput(firstNameInput)" [isValid]="firstNameFormGroup.controls['firstName'].valid">
      <div class="detail">
        <span class="label">First name</span>
        <span class="value">{{ profile.firstName }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #firstNameInput matInput maxCharacters [maxChars]="50" placeholder="First name"
          formControlName="firstName" required>
        <mat-error *ngIf="firstNameFormGroup.controls['firstName'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenFirstNameButton>Hidden Button</button>
  </form>

  <form id="lastNameFormGroup" [formGroup]="lastNameFormGroup"
    (submit)="onSubmitPersonalInformation(lastNameFormGroup, 'lastName')" *ngIf="lastNameFormGroup">
    <app-form-field-item [(editMode)]="editMode.lastName" (completeClicked)="hiddenLastNameButton.click()"
      [isLocked]="false" (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusInput(lastNameInput)"
      [isValid]="lastNameFormGroup.controls['lastName'].valid">
      <div class="detail">
        <span class="label">Last name</span>
        <span class="value">{{ profile.lastName }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #lastNameInput matInput maxCharacters [maxChars]="50" placeholder="Last name" formControlName="lastName"
          required>
        <mat-error *ngIf="lastNameFormGroup.controls['lastName'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenLastNameButton>Hidden Button</button>
  </form>

  <form id="sexFormGroup" [formGroup]="sexFormGroup" (submit)="onSubmitPersonalInformation(sexFormGroup, 'sex')"
    *ngIf="sexFormGroup">
    <app-form-field-item [(editMode)]="editMode.sex" [isLocked]="false" (completeClicked)="hiddenSexButton.click()"
      (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusSelect(sexSelect)"
      [isValid]="sexFormGroup.controls['sex'].valid">
      <div class="detail">
        <span class="label">Gender</span>
        <span class="value">{{ profile.sex }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <mat-select #sexSelect placeholder="Gender" formControlName="sex" required>
          <mat-option value="Male">Male</mat-option>
          <mat-option value="Female">Female</mat-option>
          <mat-option value="Other">Other</mat-option>
        </mat-select>
        <mat-error *ngIf="sexFormGroup.controls['sex'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenSexButton>Hidden Button</button>
  </form>

  <form id="dobFormGroup" [formGroup]="dobFormGroup" (submit)="onSubmitPersonalInformation(dobFormGroup, 'dob')"
    *ngIf="dobFormGroup">
    <app-form-field-item [(editMode)]="editMode.dob" [isLocked]="false" (completeClicked)="hiddenDobButton.click()"
      (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusInput(dobInput)"
      [isValid]="dobFormGroup.controls['dob'].valid">
      <div class="detail">
        <span class="label">Date of birth</span>
        <span class="value">{{ profile.dob | date: 'dd MMM yyyy' }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #dobInput matInput [matDatepicker]="picker" [max]="today" placeholder="Date of birth"
          formControlName="dob" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dobFormGroup.controls['dob'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenDobButton>Hidden Button</button>
  </form>

  <form id="mobileFormGroup" [formGroup]="mobileFormGroup"
    (submit)="onSubmitPersonalInformation(mobileFormGroup, 'mobile')" *ngIf="mobileFormGroup">
    <app-form-field-item [(editMode)]="editMode.mobile" (completeClicked)="hiddenMobileButton.click()"
      (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusInput(mobileInput)"
      [isValid]="mobileFormGroup.controls['mobile'].valid">
      <div class="detail">
        <span class="label">Mobile</span>
        <span class="value">{{ profile.phone }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #mobileInput matInput appOnlyNumberFormControl maxCharacters [maxChars]="13" placeholder="Mobile number"
          formControlName="mobile" type="tel" required>
        <mat-error *ngIf="mobileFormGroup.controls['mobile'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>

      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenMobileButton>Hidden Button</button>
  </form>

  <form id="emailFormGroup" [formGroup]="emailFormGroup" (submit)="onSubmitPersonalInformation(emailFormGroup, 'email')"
    *ngIf="emailFormGroup">
    <app-form-field-item [isLocked]="true" (completeClicked)="hiddenEmailButton.click()"
      (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusInput(emailInput)"
      [isValid]="emailFormGroup.controls['email'].valid">
      <div class="detail">
        <span class="label">Email</span>
        <span class="value">{{ profile.email }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #emailInput matInput maxCharacters [maxChars]="65" placeholder="Email" formControlName="email" required>
        <mat-error *ngIf="emailFormGroup.controls['email'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
        <mat-error
          *ngIf="emailFormGroup.controls['email'].hasError('email') && !emailFormGroup.controls['email'].hasError('required')">
          Email not valid
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenEmailButton>Hidden Button</button>
  </form>

  <form id="addressFormGroup" [formGroup]="addressFormGroup"
    (submit)="onSubmitPersonalInformation(addressFormGroup, 'address')" *ngIf="addressFormGroup" autocomplete="off">
    <app-form-field-item [(editMode)]="editMode.address" (completeClicked)="hiddenAddressButton.click()"
      (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusInput(address1Input)"
      [isValid]="addressFormGroup.controls['address1'].valid && addressFormGroup.controls['postcode'].valid && addressFormGroup.controls['address2'].valid && addressFormGroup.controls['address3'].valid">
      <div class="detail">
        <span class="label">Address</span>
        <span
          class="value">{{ (profile.homeAddress.street1 || '') + '\n' + (profile.homeAddress.postcode || '') + '\n' + (profile.homeAddress.town || '') + '\n' + (profile.homeAddress.state || '') }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #address1Input type="text" aria-label="question.label" matInput placeholder="Street name and number"
          formControlName="address1" [matAutocomplete]="auto" autocomplete="nope">
        <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
          <mat-option *ngFor="let option of autoCompleteOptions" [value]="option.description"
            (onSelectionChange)="getAddressDetails(option)">
            {{ option.description }}
          </mat-option>
        </mat-autocomplete>
        <mat-spinner matPrefix [diameter]="14" [strokeWidth]="1" *ngIf="autocompleteLoadingValues"></mat-spinner>
        <mat-error *ngIf="addressFormGroup.controls['address1'].hasError('valid')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mat-form-field-full-width edit">
        <input matInput placeholder="Postcode" formControlName="postcode" required>
        <mat-error *ngIf="addressFormGroup.controls['postcode'].hasError('valid')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mat-form-field-full-width edit">
        <input matInput placeholder="Suburb or town" formControlName="address2" required>
        <mat-error *ngIf="addressFormGroup.controls['address2'].hasError('valid')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mat-form-field-full-width edit">
        <input matInput placeholder="State or territory" formControlName="address3" required>
        <mat-error *ngIf="addressFormGroup.controls['address3'].hasError('valid')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenAddressButton>Hidden Button</button>
  </form>
  <!-- Hide TFN until further notice
  <form [formGroup]="tfnFormGroup" (submit)="onSubmitPersonalInformation(tfnFormGroup, 'tfn')" *ngIf="tfnFormGroup">
    <app-form-field-item [(editMode)]="editMode.tfn" (completeClicked)="hiddenTfnButton.click()" (cancelClicked)="setupPersonalInformationForm()" (editClicked)="focusInput(tfnInput)" [isValid]="tfnFormGroup.controls['tfn'].valid">
      <div class="detail">
        <span class="label">Tax file number (TFN)</span>
        <span class="value">{{ profile.tfn }}</span>
      </div>
      <mat-form-field class="mat-form-field-full-width edit">
        <input #tfnInput matInput placeholder="Tax file number (TFN)" formControlName="tfn" required>
        <mat-error *ngIf="tfnFormGroup.controls['tfn'].hasError('required')">
          This field is required
          <i class="material-icons">error</i>
        </mat-error>
      </mat-form-field>
    </app-form-field-item>
    <button [hidden]="true" #hiddenTfnButton>Hidden Button</button>
  </form>
-->
  <form id="passwordGroup" [formGroup]="passwordGroup" (submit)="onSubmitPassword('password')" *ngIf="passwordGroup">
    <app-form-field-item [isLocked]="false" (click)="openChangePassword()">
      <div class="detail">
        <span class="label">Password</span>
        <span class="value">Change password</span>
      </div>

    </app-form-field-item>
    <button [hidden]="true" #hiddenPasswordButton>Hidden Button</button>
  </form>

</div>
