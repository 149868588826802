<div *ngIf="employer; else noEmployer" class="wrapper">
    <div class="add-holder" *ngIf="!file">
        <div id="employee-add">

            <h2>Add individual employee</h2>
            <button type="button" mat-raised-button class="rollit-button " color="primary" (click)="addUser()"><i class="material-icons button">add</i>ADD
                EMPLOYEE</button>
            <h3>New subscription will be billed pro-rata</h3>

        </div>
        <div id="info-block">

            <h2>Why we ask for employee data</h2>
            <p>To ensure everyone has a smooth first time login and user experience, we need some basic employee
                information.
                This information includes personal and super fund details.</p>

            <h2>What will we do with this information? </h2>
            <p>All details, including current super fund details, will be used to set up employee profiles within the
                platform. We will not use this information to make any changes their superannuation.</p>
            <p>The information will ensure everything is ready to go when an employee logs into the platform, making it as
                easier for them to use features, such as exploring super fund options.</p>

            <h2>Privacy and security</h2>
            <p>The information you provide will be secure and only accessible by each individual employee. For more
                information, please refer to our privacy policy.</p>
        </div>
    </div>
    <div class="upload-holder" [ngClass]="{'file-open': file, 'upload-holder':true}">



        <div id="employee-info-sample" *ngIf="!file">
            <h2><b>Step 1.</b> Download the CSV template file, populate it with your emplayees and then upload above.</h2>
            <button mat-raised-button class="rollit-button " color="primary" (click)="onDownloadSample()"><i class="material-icons button">cloud_download</i> Download CSV Template</button>
            <h2>The following data fields are included in the template.</h2>
            <p>Title</p>
            <p>First name</p>
            <p>Last name</p>
            <p>Sex <span>('Male','Female','Other')</span></p>
            <p>Date of birth <span>(DD/MM/YYYY)</span></p>
            <p>Mobile number</p>
            <p>Email address</p>
            <p>Payrol number</p>
        </div>

        <div id="employee-info">
            <h2><b>Step 2.</b> Upload your employee data from a CSV file</h2>

            <button type="button" mat-raised-button class="rollit-button " color="primary" (click)="imgFileInput.click()" [disabled]="showLoader"><i class="material-icons button">cloud_upload</i>{{fileUpLoadStatus}}</button>
            <input hidden type="file" #imgFileInput (change)="fileChangeListener($event)" accept="text/csv" />

            <img alt="Loading" *ngIf="showLoader" class="loader blue" src="assets/icons/loading-icon.svg">
            <p id="file-name" *ngIf="file?.name">{{ file.name }}</p>

        </div>

        <div id="employee-data-request" *ngIf="!file">

        </div>
    </div>



    <div id="validation-block" *ngIf="file" class="full-width">
        <div *ngIf="validationErrors?.length > 0">
            <h2>Validation Issues</h2>
            <div class="error-holder">
                <div class="error-item" *ngFor="let e of validationErrors  | unique : 'err.message'">
                    <ng-container *ngIf="e.err.message !== undefined">
                        <i class="material-icons">error</i>
                        <p>{{e.err.message}}</p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-employee-csv-uploader class="full-width" [file]="file" (errors)="onCsvErrors($event)" (loaded)="onCsvLoaded()">
</app-employee-csv-uploader>


<ng-template #noEmployer>
    <h2>Not an administrator</h2>
    <p>You are not a manager of an employer's Rollit services.</p>
</ng-template>