<div>
    <div class="content-holder">
        <div class="content-left">
            <div class="content-item">
                <span class="title">Subscription type:</span>
                <span class="description">{{employer.subscription?.product?.name}}</span>
            </div>
            <div class="content-item">
                <span class="title">No. subscriptions:</span>
                <span class="description">{{employeeCount}}</span>
            </div>
            <div class="content-item">
                <span class="title">Subscription period:</span>
                <span class="description">{{employer.subscription?.timeStarted | date:'dd MMM y'}} -
                    {{employer.subscription?.timeDue | date:'dd MMM y'}}</span>
            </div>
            <div class="content-item">
                <span class="title">Agreement:</span>
                <span class="description"><a target="_blank" href="https://about.rollitwealth.com/privacy-and-terms/roll-it-super-employer-agreement">Customer
                        agreement</a></span>
            </div>
        </div>
        <div class="content-right">
            <div class="content-item">
                <span class="title">{{employer.subscription?.product?.properties?.billingPeriod | titlecase }}ly amount:</span>
                <span class="description">{{currentOrder?.price?.total | currency:'AUD'}} Inc. gst</span>
            </div>
            <div class="content-item">
                <span class="title">Billed to:</span>
                <span class="description">
                    Card: #### #### #### {{paymentMethod?.last4}}
                    <button mat-raised-button color="primary" class="rollit-button mat-tiny-button mat-icon-only-button" (click)="editPaymentMethod()">
                        <i class="material-icons">edit</i>
                    </button>
                </span>
            </div>
            <div class="content-item">
                <span class="title">Card expires:</span>
                <span class="description">{{ paymentMethod?.expMonth + '/01/' + paymentMethod?.expYear  | date:'MMM y'}}</span>
            </div>
            <div class="content-item">
                <span class="title">Billing date:</span>
                <span class="description">{{employer.subscription?.timeDue | date:'dd MMM y'}}</span>
            </div>
            <div class="content-item">
                <span class="title">Billing email:</span>
                <span class="description">{{employer.contact.email}}</span>
            </div>
        </div>
    </div>
</div>

<table mat-table class="rollit-table" [dataSource]="orders" [trackBy]="trackById">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Invoice number </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef > Date </th>
        <td mat-cell *matCellDef="let row"> {{row.timeCreated | date}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount (incl. gst) </th>
        <td mat-cell *matCellDef="let row"> {{row.price.total | currency:'AUD'}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let row"> {{row.status}} </td>
    </ng-container>

    <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef> Download </th>
        <td mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" class="rollit-button mat-tiny-button mat-icon-only-button" (click)="downloadInvoice(row.id)">
                <i class="material-icons">get_app</i>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>