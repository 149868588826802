<div class="main-content-section ">
  <div id="section-content" class="section-content">
    <app-top-menu></app-top-menu>
    <div class="top-navigation">
      <div class="back-button-content paragraph2" (click)="onReturnClick()"><i
          class="material-icons">arrow_back_ios_new</i> Back </div>
    </div>
    <div class="rollit-grid">

      <div class="rollit-grid-2">

        <div class="form-panel">

          <div>
            <div class="group-panel">


              <div class="heading heading2">Transaction detail</div>


              <!-- Transaction detail -->

              <div *ngIf="selectedTransaction" class="transaction-detail">

                <div class="header">
                  <i
                    class="material-icons category-icon {{selectedTransaction?.topCategory}}  budget-color__{{selectedTransaction?.topCategory}}"></i>
                  <div class="detail">
                    <span class="paragraph2 nickname budget-color__{{selectedTransaction?.topCategory}}"

                      [ngClass]="{'section-color__negative': selectedTransaction?.amount < 0 && selectedTransaction?.topCategory === 'primary'}">{{selectedTransaction.description}}</span>
                      

                    <span class="paragraph2  budget-color__{{selectedTransaction?.topCategory}}"
                      [ngClass]="{'section-color__negative': selectedTransaction?.amount < 0 && selectedTransaction?.topCategory === 'primary'}">{{selectedTransaction?.amount | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
                  </div>
                </div>
                <div class="sundry">
                  <span
                    class="last-updated paragraph2 description section-color__secondary">Account: {{selectedTransaction?.nickname | lowercase }}</span>
                  <span
                    class="last-updated paragraph2 description section-color__secondary">{{selectedTransaction?.date | date:'EEEE d MMMM y' }}</span>
                </div>

                <div class="list"  *ngIf="!topCategoriesActive && !categoriesActive">
                  <div class="list-item paragraph2" (click)="openTopCategories()">
                    <span>{{selectedTransaction?.topCategory}}</span> <i class="material-icons">keyboard_arrow_right</i>
                  </div>
                  <div class="list-item paragraph2" (click)="openCategories()">
                    <span>{{selectedTransaction?.category}}</span><i class="material-icons">keyboard_arrow_right</i>
                  </div>
                  </div>
                  
                  <div *ngIf="topCategoriesActive" class="list">
                    <span class="category-header paragraph2" (click)="openTopCategories()"><i class="material-icons">keyboard_arrow_left</i> Back</span>
                    <div class="list-item paragraph2" (click)="selectTopCategory(cat)" *ngFor="let cat of topCategories">
                      <span>{{cat.name}}</span> <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                  </div>
                  
                  
                  <div *ngIf="categoriesActive" class="list">
                    <span class="category-header paragraph2" (click)="openCategories()"><i class="material-icons">keyboard_arrow_left</i> Back</span>
                    <div class="list-item paragraph2" (click)="selectCategory(cat)" *ngFor="let cat of categories">
                      <span>{{cat.name}}</span> <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                  </div>
                  
                  
                  <!-- <div class="list-item paragraph1"><span>Included in budget</span>
                    <mat-slide-toggle color="primary"></mat-slide-toggle>
                  </div>
                  <div class="list-item paragraph1"><span>Tax deductible</span>
                    <mat-slide-toggle></mat-slide-toggle>
                  </div> -->
             
              </div>




            </div>
          </div>
        </div>
      </div>

      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button (click)="manageAccounts()" mat-flat-button class="new-account" color="primary">
            Add account
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
