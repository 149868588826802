<div class="main-content-section">
  <div id="section-content" class="section-content">
    <app-top-menu></app-top-menu>

    <div class="top-navigation">
      <div class="back-button-content paragraph2" (click)="onReturnClick()"><i
          class="material-icons">arrow_back_ios_new</i> Back </div>
    </div>

    <div class="rollit-grid">

      <div class="rollit-grid-2">

        <div class="form-panel">


          <div *ngIf="gotAccounts === true" class="accounts">
            <div class="group-panel">

              <div class="provider paragraph2">{{selectedAccount.providerName}}</div>

              <div class="item">
                <span class="paragraph2">{{selectedAccount.nickname}}</span><span
                  class="paragraph2">{{selectedAccount.currentBalance | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
                <span class="last-updated paragraph2 section-color__secondary">Last sync
                  {{selectedAccount.lastUpdated | date}}</span>
              </div>

            </div>
          </div>

          <button (click)="manageAccounts()" mat-flat-button class="rollit-button mat-full-width-button"
            color="primary">
            Yes stop sharing
          </button>

        </div>
      </div>
      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button (click)="manageAccounts()" mat-flat-button class="rollit-button mat-full-width-button"
            color="primary">
            Add account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
