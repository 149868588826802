import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubscriptionService } from '@rollit/shared/data';
import { Router } from '@angular/router';
import { MeService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { EmployerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'app-subscription-check',
  templateUrl: './subscription-check.component.html',
  styleUrls: ['./subscription-check.component.scss']
})
export class SubscriptionCheckComponent implements OnInit, OnDestroy {
  private log: any;
  subscriptionChecked = false;

  constructor(private router: Router,
    private subscriptionSignup: SubscriptionService,
    private userService: MeService,
    private logger: LoggerService,
    private employerService: EmployerService,
    private uiService: UIManagerService,
  ) {
    this.log = this.logger.info('SubscriptionCheckComponent');
    this.uiService.topNavIsVisible.next(false);
  }

  ngOnInit() {
    this.checkSignup();
  }

  ngOnDestroy() {
    this.uiService.topNavIsVisible.next(true);
  }

  onLogout() {
    this.userService.logout();
  }

  private checkSignup() {
    this.log('checkSignup()');
    this.subscriptionSignup.getMySignup().subscribe(
      (signup) => {
        this.log('checkSignup() getMySignup()', signup);
        if (signup) {
          if (signup.complete !== true) {
            this.log('checkSignup() signup.complete !== true');
            this.router.navigate(['/signup/pay'], { skipLocationChange: true });
          } else {
            this.log('checkSignup() signup.complete === true');
            this.checkSubscription();
          }

        } else {
          this.checkSubscription();
        }
      }, (error) => {
        this.log('checkSignup() getMySignup() error', error);
      });
  }

  private checkSubscription() {
    // TODO this will not determine if employee or employer subscription has lapsed.
    // TODO to do this properly, check user.subscriptions and query those.
    this.log('checkSubscription ()');

    this.userService.getProfile().subscribe(
      (profile) => {
        if (profile.employment.length > 0) {
          this.subscriptionChecked = true;
        } else {
          this.employerService.getEmployeesManagedByMe().subscribe(employees => {
            this.log('checkSubscription () getEmployeesManagedByMe', employees);
            if (employees.length > 0) {
              // This is an employer admin without a valid user subscription
              this.router.navigate(['/employer/dashboard']);
            } else {
              // Show message
              this.subscriptionChecked = true;
            }
          });
        }
      }
    );
  }

  onOpenIntercomChat() {
    Intercom('trackEvent', 'web-app-check-subscription-click-chat');
    Intercom('showNewMessage', 'Hi!');
  }


}
