
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  @Input() photoIsVisible: boolean;
  mediaSizeIsDesktop:      string;
  randomNumber                = Math.floor(Math.random() * 5 + 1);
  isUnsubscribed              = new Subject();

  constructor(private uiManagerService: UIManagerService) {
    this.uiManagerService.mediaSizeIsDesktop$.pipe(takeUntil(this.isUnsubscribed)).subscribe(mediaSizeIsDesktop => {
      this.mediaSizeIsDesktop = mediaSizeIsDesktop ? 'desktop' : 'mobile';
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.isUnsubscribed.next();
    this.isUnsubscribed.complete();
  }

}
