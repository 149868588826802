import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Goal, LoggerService } from '@rollit/shared/data';

@Component({
  selector: 'app-savings-goal-summary',
  templateUrl: './savings-goal-summary.component.html',
  styleUrls: ['./savings-goal-summary.component.scss'],
})
export class SavingsGoalSummaryComponent implements OnInit {
  @Input() goal: Goal = {};
  @Input() section: string;
  @Output() newGoal = new EventEmitter<string>();

  progressSum: number;
  targetSum: number;

  log: any;

  constructor(private logger: LoggerService) {
    this.log = this.logger.info('SavingsGoalSummaryComponent');
  }

  ngOnInit(): void {
    this.log(this.goal);
    this.log(this.section);
    if (this.goal) {
      if (this.goal.startAmount >= 0) {
        this.progressSum = this.goal.startAmount + this.goal.data['progress'];
        this.targetSum = this.goal.targetAmount;
      } else {
        // start amount is negative, so assume debt
        this.progressSum = this.goal.data['progress'];
        this.targetSum = this.goal.targetAmount - this.goal.startAmount;
      }
    }
  }
}
