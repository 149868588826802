<div id="user-information" fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <h3 id="profile-links-name">{{profile ? (profile?.firstName + ' ' + profile?.lastName) : ''}}</h3>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="40">
    <div id="uploadButton" class="img-container" [ngStyle]="{backgroundImage:'url('+avatar+')'}"
      (click)="onFireFileUpload()">
      <label class="custom-file-upload">
        <i class="material-icons">collections</i>
      </label>
    </div>

    <app-file-upload #fileUpload text="Upload profile image" accept="image/*" [hideUploadButton]="true"
      (complete)="onFileUploaded($event)"></app-file-upload>
  </div>
</div>

<div class="action-list">
  <app-profile-link-item id="employer-administration" title="Employer administration"
    (click)="trackIntercomEvent('employer-administration')" [linkIsInternal]="true" link="/employer"
    [active]="currentDetailPage === 'employer-administration'" *ngIf="employer"></app-profile-link-item>
  <app-profile-link-item id="personal-information" title="Your personal information"
    (click)="trackIntercomEvent('personal-information')" link="personal-information"
    [active]="currentDetailPage === 'personal-information'"></app-profile-link-item>
  <app-profile-link-item id="employer-information" title="Your employer information"
    (click)="trackIntercomEvent('employer-information')" link="employer-information"
    [active]="currentDetailPage === 'employer-information'" *ngIf="employment !== 0"></app-profile-link-item>

  <app-profile-link-item id="subscription-information" title="Your subscription"
    (click)="trackIntercomEvent('personal-information')" link="subscription-information"
    [active]="currentDetailPage === 'subscription-information'" *ngIf="subscription"></app-profile-link-item>
  <app-profile-link-item id="about-hey-coach" title="About Hey Coach" (click)="trackIntercomEvent('about-information')"
    link="about-information" [active]="currentDetailPage === 'about-information'"></app-profile-link-item>
</div>

<button mat-raised-button class="rollit-button mat-full-width-button" color="primary" (click)="onLogout()">Log
  out</button>
<a class="build" target="_blank" href="{{agreementUrl}}">{{agreementTitle}}</a> <a class="build" target="_blank"
  href="{{privacyUrl}}">Privacy Policy</a><br>
<p class="build">Build: {{buildVersion}} : {{buildDate}}</p>
