<div class="top-menu">
  <div *ngIf="!isDesktop && sideNavIsVisible" class="icon top-menu__icon" (click)="onDrawerNavigationPressed()">
    <i class="material-icons">menu</i>
  </div>
  <ng-template appInnerTopMenu></ng-template>

  <div class="menu-chat">
    <i *ngIf="hasNotifications" class="notification-icon"></i>
    <i class="material-icons" (click)="onChatClick($event)">chat_bubble_outline</i>
  </div>

</div>

<div class="sub-menu-holder" *ngIf="showSubNav">
  <app-dashboard-inner-sub-menu [data]="navData" class="sub-menu" [ngClass]="{'desktop': isDesktop}">
  </app-dashboard-inner-sub-menu>
</div>
