import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FundService, LoggerService, MoneyAccount, SectionReport } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SuperannuationService } from '../superannuation.service';

@Component({
  selector: 'app-superannuation',
  templateUrl: './superannuation.component.html',
  styleUrls: ['./superannuation.component.scss']
})
export class SuperannuationComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop = false;
  private isDestroyed = new Subject();
  sliding = false;
  sectionValue: Subject<string> = new Subject();
  totalAccountValue = 0;
  currentSlide = 0;
  currentViewSlide = 0;
  accounts: MoneyAccount[] = [];
  superannuationSummary: SectionReport;
  expanded = false;
  useLatest = false;
  ready: boolean;
  activeRoute = 'property';
  mobileAnimating: boolean;

  constructor(
    private superService: SuperannuationService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.log = this.logger.info('SurveyFormComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe((mediaSizeIsDesktop) => {
      this.isDesktop = mediaSizeIsDesktop;
    });


  }

  ngOnInit() {
    this.superService.getAccounts().subscribe(result => {
      this.accounts = result;
      this.accounts.forEach((ac) => {
        this.totalAccountValue += ac.currentBalance;
      });
      this.ready = true;
      this.checkSlideIndex();
    });

    // Use this to get summary info
    this.superService.getSummary().subscribe(result => {
      this.log('For supperannuation summary', result);
      this.superannuationSummary = result;
    });
  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  checkSlideIndex() {
    const activeId = this.activatedRoute.snapshot.paramMap.get('id');
    this.log('activeId', activeId);
    if (activeId === 'latest') {
      this.log('checkSlideIndex latest yes');
      this.useLatest = true;
      this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      }).then(() => {
        this.setSlideIndex();
      });
    } else if (activeId && !Number.isNaN(+activeId)) {
      this.log('activeId yes', +activeId);
      this.currentSlide = +activeId;
      if (!this.isDesktop) {
        this.mobileScrollToSection(this.currentSlide);
      }
    } else {
      this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  setSlideIndex() {
    const tempArray = Object.assign([], this.accounts);
    this.log('sort this.accounts', tempArray, this.accounts);
    const latestObj = tempArray.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0];
    this.log('sort latest', latestObj);
    const isNew = this.isNew(latestObj.timeCreated);
    this.log('sort isNew', isNew);
    if (isNew) {
      const index = this.accounts.findIndex(x => x.id === latestObj.id);
      this.log('sort index', index);
      this.log('sort this.accounts', tempArray, this.accounts);
      this.currentSlide = index;
    }
  }

  isNew(createdDate) {
    const today = new Date().getTime();
    createdDate = new Date(createdDate).getTime();
    this.log('sort', today, createdDate, today - createdDate, 5 * 60 * 1000);
    return Math.abs(today - createdDate) < 5 * 60 * 1000;
  }

  onCreateAccount() {
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/latest'], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    }).then(() => {
      this.superService.startAddAccount();
    });
  }

  onEditAccount(id: number) {
    this.superService.startUpdateAccount(id);
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {

    this.sectionValue.next(this.accounts[this.currentSlide].id.toString());

  }

  expandedEvent(expanded) {
    this.expanded = expanded;
    this.onElementScroll();
  }

  scrollPrev() {
    this.log('scrollPrev()', this.currentSlide + ' <> ' + this.accounts.length);
    if (this.currentSlide > 0) {
      this.sliding = true;
      this.currentSlide--;
      this.sectionValue.next(this.accounts[this.currentSlide].id.toString());

    }

  }

  scrollNext() {
    this.log('scrollNext()', this.currentSlide + ' <> ' + this.accounts.length);
    if (this.currentSlide < this.accounts.length - 1) {
      this.sliding = true;
      this.currentSlide++;
      this.log(this.currentSlide);
      this.sectionValue.next(this.accounts[this.currentSlide].id.toString());


    }
  }

  animationCallback(event) {
    this.log(event);
    this.sliding = false;
    this.currentViewSlide = this.currentSlide;
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + this.currentViewSlide], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  mobileScrollToSection(slideId) {
    this.log('mobileScrollToSection', slideId);
    if (slideId > 0) {
      this.mobileAnimating = true;
      const topTargetId = this.accounts[slideId].id;
      this.log('mobileScrollToSection topTargetId', topTargetId);
      const topTarget = document.getElementById(topTargetId.toString());
      this.log('mobileScrollToSection topTarget', topTarget);

      if (topTarget !== null) {
        setTimeout(() => {
          topTarget.scrollIntoView({
            block: "start",
            behavior: 'smooth',
          });
          setTimeout(() => {
            this.mobileAnimating = false;
          }, 500);
        }, 300);
      } else {
        this.log('mobileScrollToSection not ready');
        setTimeout(() => {
          this.mobileScrollToSection(slideId);
        }, 50);
      }
    }
  }


  onElementScroll() {
    if (!this.isDesktop && !this.mobileAnimating) {
      const scrollTarget = document.getElementsByClassName("section-content")[0];
      const allSections = document.getElementsByTagName('app-superannuation-hero');

      let lastEl;
      for (let i = 0; i < allSections.length; i++) {
        const htmlElement = allSections[i] as HTMLElement;
        const elBottomAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > (htmlElement.offsetTop + htmlElement.offsetHeight);
        const elTopAboveBottomView = (scrollTarget.scrollTop + scrollTarget.clientHeight) > htmlElement.offsetTop;
        const elTopAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > htmlElement.offsetTop;

        if (!elBottomAboveHalfView && elTopAboveBottomView && elTopAboveHalfView) {
          this.log('htmlElement', htmlElement.id);
          lastEl = htmlElement;
          this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + i], {
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      }

      this.log('scroll', lastEl);
    }
  }
}
