import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { RollitCommonModule } from '@rollit/shared/common';
import { CommonModule } from '@angular/common';
import { AccountsComponent } from './components/accounts/accounts.component';
import { AccountsRoutingModule } from './accounts-routing.module';
import { ManageAccountsComponent } from './components/manage-accounts/manage-accounts.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { AccountDetailComponent } from './components/account-detail/account-detail.component';
import { TransactionsFilterComponent } from './components/transactions-filter/transactions-filter.component';
import { RemoveAccountsComponent } from './components/remove-accounts/remove-accounts.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { IncomingAccountsComponent } from './components/incoming-accounts/incoming-accounts.component';




@NgModule({
  imports: [
    CommonModule, RollitCommonModule, AccountsRoutingModule, InfiniteScrollModule
  ],
  declarations: [
    AccountsComponent,
    ManageAccountsComponent,
    TransactionsComponent,
    AccountDetailComponent,
    TransactionsFilterComponent,
    RemoveAccountsComponent,
    TransactionDetailComponent,
    IncomingAccountsComponent,

  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'AUD' }
  ]
})
export class AccountsModule { }
