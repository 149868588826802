import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { Realty, DetailedEstimate } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { trackById } from '@rollit/shared/utils';

declare var google: any;

@Component({
  selector: 'app-property-compare',
  templateUrl: './property-compare.component.html',
  styleUrls: ['./property-compare.component.scss']
})
export class PropertyCompareComponent implements OnInit, OnDestroy {
  @Input() property: Realty;
  @Input() propertyEstimate: DetailedEstimate;
  @Input() updateCompareProperty: Subject<boolean>;
  tabIndex = 0;
  log: any;
  map: any;
  allMyMarkers = [];
  currentCompareProperties = [];
  isDesktop: boolean;
  destroy = new Subject();
  tabClickCheck: boolean = false;
  loadedMaps = [];
  trackById = trackById;

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private titlecasePipe: TitleCasePipe,

  ) {
    this.log = this.logger.info('PropertyCompareComponent');
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.destroy)).subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() {
    this.log('ngOnInit', this.property);
    this.loadGoogleMaps();

  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  loadGoogleMaps() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.log('loadGoogleMaps');
    this.dynamicScriptLoader.load('googleMaps').then(data => {
      this.log('dynamicScriptLoader', data);
      this.log('google', google);
      this.log('property', this.property);
      this.tabIndex = 0;
      if (this.property.occupancy_type === 'renting') {
        this.tabIndex = 1;
      } else {
        this.tabIndex = 0;
      }
      setTimeout(() => {
        this.setUpCompareProperties(this.tabIndex);
      }
        , 1000);
    }).catch(error => this.log(error));
  }

  comparableTabChange(event: MatTabChangeEvent) {
    this.log('comparableTabChange', event);
    this.log(event);
    this.log('this.tabClickCheck', this.tabClickCheck);
    // check that this is an actual click on the tab
    if (this.tabClickCheck) {
      this.log('this.tabClickCheck true');
      this.tabClickCheck = false;
      setTimeout(() => {
        this.setUpCompareProperties(event.index);
      },
        1000);
    }
  }

  tabClick(event: Event) {
    event.preventDefault();
    this.tabClickCheck = true;
    this.log('tabCLick', event);
  }

  setUpCompareProperties(tabIndex) {
    // ensure the element is on page
    let alreadyLoaded = false;
    this.log('setUpCompareProperties', tabIndex);
    this.log('loadedMaps', this.loadedMaps);
    this.log('propertyEstimate', this.propertyEstimate);

    for (let i = 0; i < this.loadedMaps.length; i++) {
      this.log('loadedMap', this.loadedMaps[i]);
      if (this.loadedMaps[i] === tabIndex) {
        alreadyLoaded = true;
        this.log('loadedMap loaded', this.loadedMaps[i]);
      }
    }

    this.map = null;
    this.allMyMarkers = [];
    const markerBounds = new google.maps.LatLngBounds();
    this.currentCompareProperties = [];
    let tempObject = [];

    this.log('lat: ' + this.property.address_details.location.point.lat + ' lon: ' + this.property.address_details.location.point.lon);
    this.log('map' + tabIndex + '-' + this.property.property_id);
    if (document.getElementById('map' + tabIndex + '-' + this.property.property_id) && alreadyLoaded === false) {
      this.log('if map' + tabIndex + '-' + this.property.property_id);
      this.loadedMaps.push(tabIndex);

      this.map = new google.maps.Map(document.getElementById('map' + tabIndex + '-' + this.property.property_id), {
        center: new google.maps.LatLng(this.property.address_details.location.point.lat, this.property.address_details.location.point.lon),
        mapTypeControl: false,
        draggable: false,
        scaleControl: false,
        scrollwheel: false,
        navigationControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        zoom: 4,
      });
      this.log('map', this.map);



      switch (tabIndex) {
        case 0: {
          tempObject = this.propertyEstimate.comparable_sales;

          for (let i = 0; i < tempObject.length; i++) {
            const forObject = tempObject[i];
            const salePrice = this.currencyPipe.transform(forObject.sale_price.toString(), '', 'symbol-narrow', '1.0');
            const saleDate = this.datePipe.transform(forObject.sale_date.toString(), 'MMMM yyyy');
            const saleAddress = this.titlecasePipe.transform(forObject.address);
            const saleLat = forObject.location.lat;
            const saleLon = forObject.location.lon;
            this.currentCompareProperties.push({
              position: new google.maps.LatLng(saleLat, saleLon),
              type: 'info',
              title: salePrice + '\n' + saleDate + '\n' + saleAddress,
            });
          }
          break;
        }
        case 1: {
          tempObject = this.propertyEstimate.comparable_rental_listings;
          for (let i = 0; i < tempObject.length; i++) {
            const forObject = tempObject[i];
            const salePrice = this.currencyPipe.transform(forObject.rental_price.toString(), '', 'symbol-narrow', '1.0');
            const saleDate = this.datePipe.transform(forObject.list_date.toString(), 'MMMM yyyy');
            const saleAddress = this.titlecasePipe.transform(forObject.address);
            const saleLat = forObject.location.lat;
            const saleLon = forObject.location.lon;
            this.currentCompareProperties.push({
              position: new google.maps.LatLng(saleLat, saleLon),
              type: 'info',
              title: salePrice + '\n' + saleDate + '\n' + saleAddress,
            });
          }
          break;
        }
      }

      // add home property
      const userObject = this.property.address_details;
      const userSalePrice = this.currencyPipe.transform(this.propertyEstimate.estimate.toString(), '', 'symbol-narrow', '1.0');
      const userSaleAddress = this.titlecasePipe.transform(userObject.address);
      const userSaleLat = userObject.location.point.lat;
      const userSaleLon = userObject.location.point.lon;
      this.currentCompareProperties.push({
        position: new google.maps.LatLng(userSaleLat, userSaleLon),
        type: 'property',
        title: userSalePrice + '\n' + userSaleAddress,
      });

      this.log("features", this.currentCompareProperties);

      // Create markers.
      const self = this;
      for (let i = 0; i < self.currentCompareProperties.length; i++) {
        let icon = { url: '/assets/icons/marker-inactive.png', scaledSize: new google.maps.Size(15, 22) };
        let originalicon = { url: '/assets/icons/marker-inactive.png', scaledSize: new google.maps.Size(15, 22) };
        let hovericon = { url: '/assets/icons/marker-active.png', scaledSize: new google.maps.Size(20, 29) };
        if (self.currentCompareProperties[i].type === 'property') {
          icon = { url: '/assets/icons/marker-property.png', scaledSize: new google.maps.Size(15, 22) };
          originalicon = { url: '/assets/icons/marker-property.png', scaledSize: new google.maps.Size(15, 22) };
          hovericon = { url: '/assets/icons/marker-active.png', scaledSize: new google.maps.Size(20, 29) };
        }
        const marker = new google.maps.Marker({
          position: self.currentCompareProperties[i].position,
          title: self.currentCompareProperties[i].title,
          icon: icon,
          originalicon: originalicon,
          hovericon: hovericon,
          opacity: 1,
          id: i,
          map: self.map,
          type: self.currentCompareProperties[i].type,
        });
        self.allMyMarkers.push(marker);
        google.maps.event.addListener(marker, 'mouseover', function () {
          this.setIcon(this.hovericon);
          if (marker.type !== 'property') {
            self.highlightRow(this.id);
          }
        });
        google.maps.event.addListener(marker, 'mouseout', function () {
          this.setIcon(this.originalicon);
          if (marker.type !== 'property') {
            self.defaultRow(this.id);
          }
        });
        markerBounds.extend(self.currentCompareProperties[i].position);
      }
      const center = new google.maps.LatLng(this.property.address_details.location.point.lat, this.property.address_details.location.point.lon);
      // using global variable:
      this.map.panTo(center);
      this.map.fitBounds(markerBounds);
      // setTimeout(() => {
      //   this.log('zoom', this.map.getZoom());
      //   //this.map.setZoom(this.map.getZoom() + 1);
      // }, 200);
    }

  }

  highlightRow(id) {
    const rowElement = document.getElementById('row' + id);
    const parentElement = document.getElementById('row' + id).parentElement;

    rowElement.classList.add('active');
    const scrollTo = rowElement.offsetTop - parentElement.offsetTop;
    parentElement.scrollTo({
      top: scrollTo,
      behavior: 'smooth'
    });

  }

  defaultRow(id) {
    document.getElementById('row' + id).classList.remove('active');
  }

  highlightMarker(id) {
    for (let i = 0; i < this.allMyMarkers.length; i++) {
      if (this.allMyMarkers[i].id === id) {
        this.allMyMarkers[i].setIcon(this.allMyMarkers[i].hovericon);
        break;
      }
    }
  }

  addressClick(address: string) {
    let newAddress = address
      .toLowerCase()
      .replace(/street/g, "st")
      .replace(/drive/g, "dr")
      .replace(/road/g, "rd")
      .replace(/grove/g, "gr")
      .replace(/crescent/g, "cres")
      .replace(/terrace/g, "tce")
      .replace(/court/g, "ct")
      .replace(/\./g, "");
    this.log('newAddress', newAddress);
    this.log('newAddress.indexOf("avenue", 3)', newAddress.indexOf('avenue', 3));
    if (newAddress.indexOf('avenue', 3) !== -1) {
      newAddress = newAddress.replace(/avenue/g, "ave");
    }
    newAddress = newAddress.replace(/ /g, "-");
    this.log(newAddress);
    window.open('https://www.realestate.com.au/property/' + newAddress);
  }

  defaultMarker(id) {
    for (let i = 0; i < this.allMyMarkers.length; i++) {
      if (this.allMyMarkers[i].id === id) {
        this.allMyMarkers[i].setIcon(this.allMyMarkers[i].originalicon);
        break;
      }
    }
  }

}
