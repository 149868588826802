<div class="main-content-section">
  <div class="section-content" (scroll)="onElementScroll()">
    <app-top-menu></app-top-menu>

    <div class="rollit-grid">

      <app-section-title *ngIf="accounts.length > 0" [title]="'Super balance'" [value]="totalAccountValue">
      </app-section-title>

      <div *ngIf="isDesktop && accounts.length > 0" class="rollit-grid-2">
        <app-horizontal-scroll-snap *ngIf="ready" [scrollToSection]="sectionValue" [fullWidth]="true"
          (animFinished)="animationCallback($event)">
          <section *ngFor="let account of accounts; let i = index">
            <app-superannuation-hero *ngIf="superannuationSummary" [inView]="currentViewSlide === i" [id]="account.id"
              [acc]="account" [propertyListId]="i" [propertyCount]="accounts.length - 1" (clickNext)="scrollNext()"
              (clickPrev)="scrollPrev()">
            </app-superannuation-hero>
          </section>
        </app-horizontal-scroll-snap>
      </div>

      <div *ngIf="!isDesktop && accounts.length > 0" class="rollit-grid-1">
        <ng-container *ngFor="let account of accounts">
          <app-superannuation-hero [id]="account.id" [inView]="expanded" [acc]="account" (clickNext)="scrollNext()"
            (clickPrev)="scrollPrev()" (expandedProjections)="expandedEvent($event)">
          </app-superannuation-hero>
        </ng-container>
      </div>


      <div *ngIf="accounts.length === 0 && ready" class="rollit-grid-2">
        <div class="group-panel blank">
          <span class="copy heading1">Superannuation</span>

          <span class="copy paragraph1">Set yourself up for a stress-free retirement. Add your super fund details and we
            will track your super balance, investment performance and fees</span>

          <span class="copy paragraph1">Hey Coach runs the numbers and projects your super savigns at retirement age.
            We’ll help you to stay on track for a comfortable lifestyle in retirement.</span>

          <img class="image" src="/assets/images/example/super-example.png" />
        </div>
      </div>

      <div class="rollit-grid-1">
        <div class="sundry-holder">

          <button mat-stroked-button class="new-account" (click)="onCreateAccount()">
            <span *ngIf="accounts && accounts.length > 0" class="paragraph1">Add another super fund</span>
            <span *ngIf="accounts && accounts.length === 0" class="paragraph1">Add a super fund</span>
          </button>

          <div class="group-panel fund-detail"
            *ngIf="ready && isDesktop && accounts[currentSlide].properties?.super?.optionId && !sliding">
            <app-superannuation-fund-detail id="compare" [fundId]="accounts[currentSlide].properties?.super?.optionId">
            </app-superannuation-fund-detail>
          </div>

        </div>

      </div>
    </div>

  </div>
</div>
