import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-new-savings-goal',
  templateUrl: './new-savings-goal.component.html',
  styleUrls: ['./new-savings-goal.component.scss'],
})
export class NewSavingsGoalComponent implements OnInit {
  @Input() text: string;

  @Output() newGoalClicked = new EventEmitter();
  @Input() section: string;
  icon: string;
  title: string;

  iconNames = [
    { 'section': 'savings', 'icon': 'savings', 'title': 'Set a savings goal' },
    { 'section': 'lifestyle', 'icon': 'directions_car', 'title': 'Set a lifestyle goal' },
    { 'section': 'investments', 'icon': 'stacked_line_chart', 'title': 'Set an investment goal' },
    { 'section': 'investment', 'icon': 'stacked_line_chart', 'title': 'Investment goal' },
    { 'section': 'superannuation', 'icon': 'insights', 'title': 'Set a super goal' },
    { 'section': 'property', 'icon': 'domain', 'title': 'Set a property goal' },
    { 'section': 'credit', 'icon': 'credit_card', 'title': 'Create debt repayment goal' }
  ];


  ngOnInit() {
    this.iconNames.forEach((item) => {
      if (this.section === item.section) {
        this.icon = item.icon;
        this.title = item.title;
      }
    });
  }

  onNewClick() {
    this.newGoalClicked.next();

  }
}
