<!-- <div class="label">{{ label }}</div>-->

<mat-form-field appearance="outline" class="input-select">
  <mat-label *ngIf="prompt && multi" class="paragraph1">{{prompt}}</mat-label>
  <mat-error *ngIf="hasError('required')">
    Field is required
  </mat-error>

  <mat-select placeholder="{{placeholder}}" [formControl]="formControl" (selectionChange)="handleData($event.value)"
    [ngClass]="{'disabled':selected === ''}">
    <mat-option *ngFor="let item of visibleOptions" value="{{item.value || item.slug}}">
      {{item.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="description paragraph2" [ngClass]="{'single': !multi}">{{ description }}</div>
