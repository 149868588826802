<mat-form-field class="input-number" appearance="outline">
  <mat-label *ngIf="prompt && multi" class="paragraph1">{{prompt}}</mat-label>

  <input *ngIf="format !== 'Year'" matInput type="text" [formControl]="formControl" class="paragraph1" [placeholder]="placeholderText" value=""
    mask="separator.2" thousandSeparator="," separatorLimit="99999999999999" [allowNegativeNumbers]="true" (keyup)="update()" />
  <input *ngIf="format === 'Year'" matInput type="text" [formControl]="formControl" class="paragraph1" [placeholder]="placeholderText" value=""
    separatorLimit="9999" (keyup)="update()" />

  <span *ngIf="format === 'Currency'" matPrefix>$&nbsp;</span>
  <span *ngIf="format === 'Percentage'" matSuffix>%&nbsp;</span>
  <mat-error>
    {{validationMessage}}
  </mat-error>
</mat-form-field>

<div class="description paragraph2" [ngClass]="{'error':!isValid,'single': !multi}">{{ description }}</div>
