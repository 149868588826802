import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Progress, Section, Fact, SectionType, DialogService, LoggerService } from '@rollit/shared/data';
import { AnswerComponent } from '../../input/answer.component';
import { UIManagerService } from '@rollit/shared/services';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { KeycloakService } from 'keycloak-angular';
import { SectionContext } from '../../survey.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from "@rollit/shared/common";

const TRACK = 'track';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnInit, OnDestroy {
  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChildren(AnswerComponent) answerChildren: QueryList<AnswerComponent>;

  log: any;
  dialogSlug: string = null;
  progress: Progress;
  section: Section;   // current section
  context: SectionContext;
  facts: Fact[] = [];
  dialogFacts: Fact[] = [];
  report: any;
  public isDesktop: boolean;
  isInformation = false;
  showDebugInfo = false;
  formValid = false;
  setupComplete = false;
  AnimStatus = '';
  public module: string = null;      // survey module: null or 'track'
  public sessionId: string = null;   // dialog session ID
  private subscriptions: Subscription[] = [];
  private isDestroyed = new Subject();
  checkingForm: boolean;
  isProd = this.environment.production;
  isMarketing: boolean;
  returnPath = '/';     // where to navigate at the completion of the survey.
  reportText: any;
  showTip: boolean = true;
  positioningDescription: boolean;
  answerElementHeight: number;

  private startSection = null;
  public authenticated = false;

  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private dialogService: DialogService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private keycloak: KeycloakService,
    private dialog: MatDialog,
  ) {
    this.log = this.logger.info('SurveyFormComponent');
    this.log('this.environment', this.environment);
    if (this.environment.siteUrl.indexOf('www') !== -1) {
      this.log('isMarketing');
      this.isMarketing = true;

    }
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.topNavIsVisible.next(false);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.isDesktop = mediaSizeIsDesktop;
    });

  }

  ngOnInit() {
    this.route.queryParamMap.pipe(takeUntil(this.isDestroyed)).subscribe(map => {
      // this.log('Query map', map);
      const rp = map.get('return');
      this.returnPath = rp ? rp : this.isMarketing ? '/signup' : '/survey/setup';
      this.showDebugInfo = !!map.get('debug');
      this.sessionId = map.get('session');      // dialog session ID
      this.startSection = map.get('start');
      this.context = { answerComponents: [] };    // reset context
    });

    this.route.paramMap.pipe(takeUntil(this.isDestroyed)).subscribe(map => {
      this.dialogSlug = map.get('slug');
      const module = map.get('module');
      if (module) {
        this.module = module;
      }
      else {
        this.module = "setup";
      }
      // check if client is authenticated.
      this.keycloak.isLoggedIn().then(val => {
        // this.log('Is logged in: ' + val);
        this.authenticated = val;
        this.log('getSurveyProgress', this.dialogSlug, this.startSection);

        this.getSurveyProgress(this.dialogSlug, this.startSection).subscribe(progress => {
          this.handleProgress(progress);
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  updateAnswerValue() {
     this.log('updateAnswerValue start answerChildren', this.answerChildren);
    if (this.subscriptions && this.subscriptions.length > 0) {
      // this.log('usubscribe');
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    }
    // this.log('subscription', this.subscriptions);
    this.answerChildren.forEach((comp, i) => {
      const question = comp;
      // this.log("updateAnswerValue question.answer", question.answer);

      this.subscriptions.push(
        comp.inputComponent.formControl.valueChanges.subscribe((value) => {
          // this.log('valueChanges Form value ' + value);
          this.inputChange();
        })
      );

      if (question.answer.value) {
        // this.log("updateAnswerValue question.answer.value", question.answer.value);
        comp.value = question.answer.value;
      } else if (question.answer.default) {
        // this.log("updateAnswerValue question.answer.default question.answer.default", question.answer.default);
        comp.value = question.answer.default;
      }
    });
    this.formValidCheck();
  }

  inputChange() {
    // this.log("inputChange e", this.checkingForm);
    if (this.checkingForm === false) {
      this.formValidCheck();
    }
  }

  formValidCheck() {
    this.checkingForm = true;
    let validCount = 0;   // TODO check valid from answer component
    let valid = false;
    // this.log("formValidCheck answerChildren : ", this.answerChildren);
    this.answerChildren.forEach((comp, i) => {
      const question = comp;
      comp.inputComponent.formControl.updateValueAndValidity();

      // this.log('formValidCheck comp', comp.inputComponent.formControl);
      if (comp.inputComponent.formControl.status === 'INVALID' || comp.inputComponent.formControl.value === undefined) {
        // this.log('formValidCheck comp invalid', comp.inputComponent.formControl);
        // this.log('formValidCheck comp invalid status', comp.inputComponent.formControl.status);
        // this.log('formValidCheck comp invalid value', comp.inputComponent.formControl.value);
        validCount++;
      }
      else {
        question.answer.value = comp.value;
      }
    });

    if (validCount === 0) {
      valid = true;
    }
    this.formValid = valid;
    // this.log('formValidCheck valid', valid);
    this.checkingForm = false;
    return valid;
  }



  onNextTap(e) {
    // this.log('onNextTap(), formValidCheck valid', this.formValid);

    e.preventDefault();
    e.stopPropagation();
    this.log('this.formValidCheck', this.formValidCheck());
    if (this.formValidCheck()) {
      this.AnimStatus = 'outNext';
      setTimeout(() => {
        // this.log('setSurveyFacts', this.dialogSlug, this.section);
        this.setSurveyFacts(this.dialogSlug, this.section).subscribe(progress => {
          // this.log('setSurveyFacts', this.dialogSlug, this.section);
          this.AnimStatus = 'inNext';
          this.handleProgress(progress);
        });
      }, 200);
    }
    return false;
  }

  onBackTap() {
    if (this.progress && this.progress.previous) {
      this.AnimStatus = 'outPrev';

      setTimeout(() => {
        this.getSurveyProgress(this.dialogSlug, this.progress.previous.slug).subscribe(progress => {
          this.AnimStatus = 'inPrev';
          this.handleProgress(progress);
        });
      }, 200);
    }
  }

  /**
   * When action button is tapped.
   * @param e 
   * @param action 
   * @returns 
   */
  onActionTap(e, action: string) {
    e.preventDefault();
    e.stopPropagation();

    switch (action) {
      case 'submit':
        if (this.formValidCheck()) {
          this.AnimStatus = 'outNext';
          setTimeout(() => {
            this.setSurveyFacts(this.dialogSlug, this.section).subscribe(progress => {
              this.AnimStatus = 'inNext';
              this.handleProgress(progress);
            });
          }, 200);
        }
        break;
      case 'close':
        this.onReturnClick();
        break;
      default:
        this.log('TODO handle different actions');
    }
    return 0;
  }

  handleProgress(progress: Progress) {
    this.progress = progress;
    this.log('handleProgress progress', this.progress);

    if (progress) {
      if (progress.total === 0) {
        this.initialiseDialog();    // try inititialise
        return;
      }
      // this.log('handleProgress progressComplete', progressComplete);
      // this.log('handleProgress progress.next', progress.next);

      if (progress.next) {
        const next = progress.next;
        this.showTip = true;
        if (next.type === SectionType.Information) {
          this.section = next;
          this.isInformation = true;
          this.uiService.appBackgroundIsPrimary.next(true);
          this.context = { answerComponents: [] };    // reset context
        } else if (next.type === SectionType.Question) {
          this.isInformation = false;
          this.uiService.appBackgroundIsPrimary.next(false);
          this.section = next;
          if (this.section) {
            if (this.formDirective) {
              this.formDirective.resetForm();
            }
          }
          this.context = { answerComponents: [] };    // reset context
        } else if (next.type === SectionType.Redirect) {
          this.log('Got progress redirect section', next);
          const category = next.content['category'];
          const dialog = next.content['dialog'];
          this.redirect(category, dialog);
        }
      } else if (progress.previous) {
        const previous = progress.previous;
        if (previous.type === SectionType.Redirect) {
          this.log('Got progress redirect section', previous);
          const category = previous.content['category'];
          const dialog = previous.content['dialog'];
          this.redirect(category, dialog);
        } else if (this.returnPath) {
          this.onReturnClick();
        } else {
          this.getSurveyProgress(this.dialogSlug, previous.slug).subscribe(value => {
            this.handleProgress(value);
          });
        }
      }
    } else {
      this.section = null;
      this.initialiseDialog();    // try inititialise
    }

    const contentEl = document.getElementById('section-content');
    if (contentEl) {
      const agent = window.navigator.userAgent.toLowerCase();
      const isIE = agent.indexOf('trident') > -1;

      if (isIE) {
        contentEl.scrollLeft = 0;
        contentEl.scrollTop = 0;
      } else {
        contentEl.scroll(0, 0);
      }
    }

    if (this.showDebugInfo) {
      if (this.authenticated) {
        this.dialogService.getDialogFacts(this.sessionId).subscribe(dialogFacts => {
          this.dialogFacts = dialogFacts;
          this.dialogService.getFacts(this.sessionId).subscribe(facts => {
            this.facts = facts;
          });
        });
      }
      else {
        this.dialogService.getAnonDialogFacts().subscribe(dialogFacts => {
          this.dialogFacts = dialogFacts;
          this.dialogService.getAnonFacts().subscribe(facts => {
            this.facts = facts;
          });
        });
      }
    }
    setTimeout(() => {
      this.AnimStatus = 'end';
      this.setupComplete = true;
      this.updateAnswerValue();

      setTimeout(() => {
        this.findAnswerPosition(true);
        this.findTermsLink();
      }, 200);
    }, 200);
  }

  /**
   * For TRACK dialogs that need to be initialised
   */
  initialiseDialog(facts: Fact[] = []) {
    if (this.authenticated) {
      if (TRACK === this.module) {
        // null progress means session not initialised, so initialise dialog session
        // this.log('initialising dialog session', this.sessionId);
        this.dialogService.initDialog(this.module, this.dialogSlug, facts, this.sessionId).subscribe(result => {
          if (result && result.total > 0) {
            this.handleProgress(result);
          }
        });
      }
    }
  }

  /**
   * Redirect the survey form to a new dialog with path {category}/{dialog}
   * 
   * @param category 
   * @param dialog 
   */
  redirect(category: string, dialog: string) {
    const queryParams = {};
    if (this.returnPath) {
      queryParams['return'] = this.returnPath;
    }
    if (this.sessionId) {
      queryParams['session'] = this.sessionId;
    }
    queryParams['start'] = '_first';    // start the new dialog at the _first section

    // ensure the dialog is initialised for the session before navigating to it
    this.dialogService.initDialog(category, dialog, [], this.sessionId).subscribe(result => {
      this.router.navigate(['survey', category, dialog], { queryParams, replaceUrl: true });
    });
  }

  onClearFacts() {
    // this.log("clearing facts");
    this.dialogService.clearFacts().subscribe(() => {
      this.dialogService.getProgress(this.module, this.dialogSlug, this.sessionId).subscribe(progress => {
        this.handleProgress(progress);
        // this.log('progress', progress);
      });
    });
  }

  stringify(obj) {
    return JSON.stringify(obj);
  }



  getSurveyProgress(dialogSlug: string, sectionSlug?: string): Observable<Progress> {
    if (this.authenticated) {
      return this.dialogService.getProgress(this.module, dialogSlug, sectionSlug, this.sessionId);
    }
    else {
      return this.dialogService.getAnonProgress(dialogSlug, sectionSlug);
    }
  }

  setSurveyFacts(dialogSlug: string, section: Section): Observable<Progress> {
    if (this.authenticated) {
      return this.dialogService.setFacts(this.module, dialogSlug, section.slug, section, this.sessionId);
    }
    else {
      return this.dialogService.setAnonFacts(dialogSlug, section.slug, section);
    }
  }

  // @HostListener('window:resize', ['$event'])
  // sizeChange(event) {
  //   // this.log('window:resize');
  //   setTimeout(() => {
  //     this.findAnswerPosition(false);
  //   }, 500);
  // }

  findTermsLink() {
      const termsElements = document.getElementsByClassName('terms');
      this.log('termsElement',termsElements)
      if (termsElements.length > 0) {
        const termsElement = termsElements[0]  as HTMLBaseElement;
        this.log(termsElement)
        const self = this;
        termsElement['self'] = self;
        termsElement.addEventListener("click", self.openTerms);
      }
  }

  /**
   * Handler for when a user clicks "terms" in the dialog.
   * @param e 
   */
  openTerms(e) {
    console.log('termsElement openTerms',e)
    const self = e.currentTarget.self;
    const title = (self.section && self.section.content && self.section.content.termsTitle)
      ? self.section.content.termsTitle 
      : "Terms";

    const content = (self.section && self.section.content && self.section.content.terms)
      ? self.section.content.terms 
      : "...";
      // : 'As with any financial scenario, there are risks involved. The savings illustration provides an overview or summary only and it should not be considered a comprehensive analysis. You should before acting in reliance upon this illustration seek independent professional lending or taxation advice as appropriate specific to your objectives, financial circumstances or needs. The information included in the illustration has been sourced from third parties and has not been independently verified. <br><br>Accordingly, neither Roll-it Super Pty Ltd ABN 57 611 756 140 or Loan Market Pty Ltd ABN 89 105 230 019, Australian Credit Licence 390222 accepts liability, nor is responsible for, nor provides any warranty express or implied as to its accuracy. Terms, conditions, fees and charges may apply. Normal lending criteria apply. Rates subject to change. Approved applicants only.<br><br>By continuing you consent to your data being provided to Loan Market to perform a loan review. You agree to Loan Market’s Terms and to Loan Market using your information as per the Privacy Policy, and to Loan Market giving you documents electronically under their e-consent. Rollit may receive a fee for introducing you to Loan Market.<br><br>Loan Market privacy and terms<br><a target="_blank" href="https://www.loanmarket.com.au/privacy-policy">https://www.loanmarket.com.au/privacy-policy</a><br><a target="_blank" href="https://www.loanmarket.com.au/terms-and-conditions">https://www.loanmarket.com.au/terms-and-conditions</a><br><br>';

    console.log("Showing terms for section", self.section);
    const dialogRef = self.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: title,
        copy: content,
        buttonMessage: 'Ok',
        type: 'Terms',
        showCancel: false,
        cancelMessage: 'Ok',
        showButtonTick: false,
      }
    });

    // dialogRef.afterClosed().pipe(takeUntil(self.isDestroyed)).subscribe(result => {
    //   console.log('Remove account dialog closed', result);
    //   if (result) {
    //     if (result[0] === 'accept') {
    //     }
    //   }
    // });
  }


  findAnswerPosition(reset) {
    if (!this.positioningDescription) {
      this.positioningDescription = true;

      //const answerElement = document.getElementsByClassName('lib-answer')[0] as HTMLBaseElement;
      //const scrollElement = document.getElementsByClassName('section-content')[0] as HTMLBaseElement;
      const descriptionElement = document.getElementById('description') as HTMLBaseElement;

      //if (answerElement && scrollElement && descriptionElement) {
      if (descriptionElement) {
        // const scrollElementTop = scrollElement.scrollTop;
        // const answerElementTop = answerElement.offsetTop - scrollElementTop;
        // if (!this.answerElementHeight || reset) {
        //   this.answerElementHeight = answerElement.offsetHeight;
        // }

        // const descriptionTopTarget = (answerElementTop + this.answerElementHeight) - 50;
        // //this.log('answerElement', answerElement, answerElementTop, this.answerElementHeight, descriptionTopTarget);


        // const descriptionElementHeight = descriptionElement.offsetHeight - 50;
        // //this.log('answerElement', descriptionElement);

        // if (reset) {

        //   descriptionElement.style.top = descriptionTopTarget + 'px';
        descriptionElement.style.opacity = '1';
        //   answerElement.style.height = this.answerElementHeight + descriptionElementHeight + 'px';
        // } else {
        //   descriptionElement.style.top = descriptionTopTarget + 'px';
        //   descriptionElement.style.opacity = '1';
        //   answerElement.style.height = this.answerElementHeight + descriptionElementHeight + 'px';
        // }
      }

      this.positioningDescription = false;
    }
  }

  /**
   * Clear the session and navigate to the return path
   */
  onReturnClick() {
    if (this.sessionId) {
      // clear facts from the temporary session.
      this.dialogService.clearFacts(this.sessionId).subscribe();
    }
    this.router.navigate([this.returnPath], { replaceUrl: true });    // navigate to the return path
  }

  closeTip(e) {
    this.showTip = false;
  }
}
