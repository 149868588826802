<div class="main-content-section">
  <div class="section-content"
       (scroll)="onElementScroll()">
    <app-top-menu></app-top-menu>

    <div class="rollit-grid">

      <app-section-title *ngIf="properties.length > 0"
                         [title]="'Property value'"
                         [value]="totalPropertyValue">
      </app-section-title>

      <div *ngIf="isDesktop && properties.length > 0"
           class="rollit-grid-2">
        <app-horizontal-scroll-snap *ngIf="ready"
                                    [scrollToSection]="sectionValue"
                                    [fullWidth]="true"
                                    (animFinished)="animationCallback($event)">
          <section *ngFor="let prop of properties; let i = index">
            <app-property-hero [inView]="currentViewSlide === i"
                               [id]="prop.account.id"
                               [propertyListId]="i"
                               [propertyCount]="properties.length - 1"
                               [prop]="prop"
                               (clickNext)="scrollNext()"
                               (clickPrev)="scrollPrev()">
            </app-property-hero>
          </section>
        </app-horizontal-scroll-snap>
      </div>

      <div *ngIf="!isDesktop && properties.length > 0"
           class="rollit-grid-1">
        <ng-container *ngFor="let prop of properties">

          <app-property-hero [id]="prop.account.id"
                             [prop]="prop"
                             [inView]="expanded"
                             (expandedProjections)="expandedEvent($event)">
          </app-property-hero>

        </ng-container>
      </div>

      <div *ngIf="properties.length === 0 && ready"
           class="rollit-grid-2">
        <div class="group-panel blank">
          <span class="copy heading1">Property</span>

          <span class="copy paragraph1">Make your next move with clarity and confidence. Sit back and relax as Hey Coach
            takes out the hard work of property financial management.</span>

          <span class="copy paragraph1">Each month we automatically update the value of your property, provide local
            sales
            insights, and monitor home loan interest rates. Our analysis is quantitative and insights unbiased.</span>

          <img class="image"
               src="/assets/images/example/property-example.png" />
        </div>
      </div>

      <div class="rollit-grid-1">
        <div class="sundry-holder">

          <button mat-stroked-button
                  class="new-property"
                  (click)="onAddProperty()">
            <span *ngIf="properties && properties.length > 0"
                  class="paragraph1">Add another property</span>
            <span *ngIf="properties && properties.length === 0"
                  class="paragraph1">Add a property</span>
          </button>

          <button mat-stroked-button
                  class="new-property"
                  (click)="onStartReviewLoan()"
                  [ngStyle]="{'margin-top':'20px'}"
                  *ngIf="isDesktop">
            <span *ngIf="properties && properties.length > 0"
                  class="paragraph1">Start loan review</span>

          </button>

          <div class="group-panel compare"
               *ngIf="isDesktop && properties[currentSlide]?.estimate?.comparable_sale_listings && !sliding">
            <app-property-compare id="compare"
                                  [property]="properties[currentSlide].property"
                                  [propertyEstimate]="properties[currentSlide].estimate">
            </app-property-compare>
          </div>

        </div>

      </div>
    </div>

  </div>
</div>