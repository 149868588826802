<div class="mobile-prompt">
  <h1 mat-dialog-title>Add you mobile number<i (click)="closeDialog()" class="material-icons">close</i></h1>

  <div mat-dialog-content>
    <h3>Hey Coach Rewards uses 2-factor authentication for your security.</h3>
    <p>Please provide your mobile number to continue.
    </p>
    <div class="add-holder">
      <form (submit)="onSubmit(formDirective)" [formGroup]="mobileForm" #formDirective="ngForm">
        <mat-form-field class="mat-form-field-full-width edit">
          <input #mobileInput matInput appOnlyNumberFormControl maxCharacters [maxChars]="13" [minChars]="10"
            placeholder="Mobile number" formControlName="mobile" type="tel" required>
          <mat-error *ngIf="mobileForm.controls['mobile'].hasError('required')">
            This field is required
            <i class="material-icons">error</i>
          </mat-error>

        </mat-form-field>
        <button mat-raised-button class="rollit-button" color="primary" class="rollit-button mat-full-width-button"
          [disabled]="mobileForm.invalid">Submit</button>
      </form>

    </div>
  </div>
