import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-financial-security-barchart',
  templateUrl: './financial-security-barchart.component.html',
  styleUrls: ['./financial-security-barchart.component.scss']
})
export class FinancialSecurityBarchartComponent implements OnInit {
  @Input() financialSecurityPercent: string;
  @Input() financialSecurityPercentFinal: number = 0;
  @Input() financialSecurityCopy: string;
  constructor() { }

  ngOnInit() {
    this.financialSecurityPercentFinal = Math.round(+this.financialSecurityPercent * 100);
  }

}
