import { User } from './user';
import { File } from './file';
import { Property } from './core';

/**
 * Superannuation model objects
 */
export interface Fund {
    id?: number;
    name?: string;
    description?: string;
    logo?: File;
    abn?: string;
}

export interface InvestmentProduct {
    id?: number;
    name?: string;
    description?: string;
    type?: string;
    category?: string;
    usi?: string;
    website?: string;
    enquiriesNumber?: string;
    establishedDate?: string;  // should be date
    assessmentStatus?: string;
    productDisclosure?: string;
    statementOfCompliance?: File;
    signupUrl?: string;
    investmentOptions?: InvestmentOption[];
    fund?: Fund;
    services?: { id: number, name: string }[];
    logo?: File;
}

export interface InvestmentOption {
    id?: number;
    name?: string;
    description?: string;
    number?: number;                // the investment option number - ID with fund
    product?: InvestmentProduct;
    logo?: File;
    sector?: string;
    ethical?: boolean;
    indexed?: boolean;
    mySuper?: boolean;
    growthAssetWeighting?: number;
    maxFees?: InvestmentOptionFees;
    feeStructure?: any;               // TODO provide interface for this.
    performance?: OptionSnapshot[];
    isPerformanceCurrent?: boolean;
    rank?: number;
}

export interface InvestmentOptionFees {
    buySpread?: Fee;
    sellSpread?: Fee;
    investmentManagementFees?: Fee;
    memberManagementFees?: Fee;    // $ minimum administration (use maximum)
    ongoingManagementFees?: Fee;    // % member management fee
    indirectCostRatio?: Fee;
    totalCostRatio?: Fee;
    others: Fee[];
}

export interface Fee {
    name?: string;
    feeType?: string;   // Percentage or Dollar
    value?: number;
}

export interface OptionSnapshot {
    timestamp: string;     // date
    values: { [name: string]: Property };
}

export class InvestmentOptionFilter {
    // tslint:disable-next-line:no-inferrable-types
    returnYears?: number; // = 3;   // the number of years for the return rate.
    returnMin?: number;        // the minimum return rate
    returnMax?: number;        // the maximum return rate
    feesMin?: number;
    feesMax?: number;
    funds?: number[];      // list of find IDs to filter on.
    products?: number[];      // list of product IDs to filter on.
    sectors?: string[];
    ethical?: boolean;       // whether this option is an ethical investment
    indexed?: boolean;       // whether this option is indexed
    mySuper?: boolean;
    name?: string;        // a partial to match with name of option
    countOnly?: boolean;       // whether to only return the total number of options that match the criteria
    sortField?: string; // = 'return';
    sortDir?: string; // = 'desc';
}

export interface RateHistogram {
    filter?: InvestmentOptionFilter; // the filter applied to investments
    interval?: number;                 // the interval between bins
    rates: number[];               // the bins of rates of return
    counts: number[];               // the count of options that belong in the associated bin
}

/**
 * A user superannuation investment
 */
export interface UserInvestment {
    user?: User;
    investment?: InvestmentOption;
    memberNumber?: string;
    nominations?: Nomination[];
}

/**
 * An investment binding nomination.
 */
export interface Nomination {
    id?: number;
    name: string;   // name of the nominee
    percentage: number;   // percentage of the death benefits from the investment
}


/**
 * Superannuatiuon summary used as detail for a MoneyAccount.
 */
export interface Superannuation {
	fundType?: string;				// SMSF or SF (Retail or Industry fund)
	fundName?: string;				// name either entered by user, or matched in Rollit DB.
	productId?: number;				// Rollit ID of super product, if attached
	optionId?: number;				// Rollit ID of super investment option, if attached
	annualReturn?: number;			// percentage return
	contributions?: number;		    // percentage of income
	drawdown?: number;				// drawdown $ on retirement
}