<ng-container>
  <div class="group-panel">

    <div class="title heading2">Monthly budget</div>

    <div class="navigation">
      <div class="item prev paragraph2" [ngClass]="{'disabled': false }" (click)="true ? scrollPrev() : false">
        <i class="material-icons">navigate_before</i> Previous
      </div>

      <div class="item next paragraph2"
        [ngClass]="{'disabled': selectedMonth.format('MM-YYYY') === now.format('MM-YYYY') }"
        (click)="selectedMonth.format('MM-YYYY') !== now.format('MM-YYYY') ? scrollNext() : false">
        Next <i class="material-icons">navigate_next</i>
      </div>
    </div>

    <app-budget *ngIf="budgetArray.length > 0" [title]="'Monthly budget'" [month]="selectedMonth"
      [dateDetails]="dateDetails" [budgets]="budgetArray" (date)="updateDate($event)"></app-budget>



    <div class="holder">
      <div class="titleGroup">
        <app-title-group [details]="titleGroupDetails" [displayFullWidth]="true"></app-title-group>

      </div>
      <div class="action-holder">
        <button mat-stroked-button (click)="onBudgetEdit()">
          <span class="paragraph2"> Edit </span>
        </button>

      </div>
    </div>

    <div class="projections-holder">
      <app-savings-projections
        *ngIf="projectionChartCategories.length > 0 && projectionChartData.length > 0 && income && budget"
        [projectionChartData]="projectionChartData" [projectionChartCategories]="projectionChartCategories"
        [income]="income" [budget]="budget" [balance]="balance">
      </app-savings-projections>
    </div>

    <div class="action-holder">
      <button mat-stroked-button (click)="onIncomeEdit()">
        <span class="paragraph2"> Edit </span>
      </button>

    </div>

  </div>
</ng-container>
