import { Component, Inject, OnInit } from '@angular/core';
import { APP_ENVIRONMENT } from '@rollit/shared';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  fileUrl = this.environment.apiUrl + '/file';

  constructor(@Inject(APP_ENVIRONMENT) private environment: any) {
  }

  ngOnInit() {
  }

}
