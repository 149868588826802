import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-home-situation-ownership-graphic',
  templateUrl: './home-situation-ownership-graphic.component.html',
  styleUrls: ['./home-situation-ownership-graphic.component.scss']
})
export class HomeSituationOwnershipGraphicComponent implements OnInit {
  @Input() assetsHomeOwnershipPercentage: string;
  @Input() assetsHomeOwnershipResult: string;
  constructor() { }

  ngOnInit() {
  }

}
