import { User } from './user';
import { Moment } from 'moment';

export interface YodleeProfile {
  id?: number;
  name?: string;
  email?: string;
  address?: any;     // { address1, address2, fullAddress, state, city, zip, country }
  segmentName?: string;
  preferences?: any;
  loginName?: string;
  roleType?: string;
}

export interface Balances {
  total: Balance;
  balances: Balance[];
}

export interface Balance {
  name: string;
  currency?: string;
  amount: number;       // The balance amount, in the given currency. May be a sum of this.balances amounts;
  balances?: Balance[];  // Sub-balances
}

export interface Spending {
  /**
   * The start date for which this spending has occurred
   */
  from: Date;

  /**
   * The end date for which this spending has occurred
   */
  to: Date;

  /**
   * User spending money
   */
  user: User;

  /**
   * The currency that the amounts are in.
   */
  currency: string;

  /**
   * A total spending budget for the user
   */
  budget?: number;

  /**
   * Total amount spent
   */
  amount: number;

  /**
   * List of categories with amounts spent.
   */
  categorySpending?: CategorySpending[];
}

export interface CategorySpending {
  /**
   * The start date for which this spending has occurred.
   */
  from: Date;

  /**
   * The end date for which this spending has occurred.
   */
  to: Date;

  /**
   * The category under which spending was done.
   */
  category: TransactionCategory;

  /**
   * The currency for the amount
   */
  currency: string;

  /**
   * A spending budget for the category
   */
  budget?: number;

  /**
   * Amount spent on this category
   */
  amount: number;

  /**
   * A percentage of a whole, when this is part of a list of category spending.
   */
  percentage: number;
}

export interface AccountMovement {
  name: string;
  account?: MoneyAccount;
  from?: Moment;    // Moment or Date
  to?: Moment;
  currency: string;
  amount: number;
  movements?: AccountMovement[];
  transactions?: Transaction[];
}


export interface MoneyAccount {
  id?: number;
  visible?: boolean;   // whether the account should be included in tracking.

  type?: AccountType;

  source?: AccountSource;     // the source of the account data
  sourceAccountId?: string;   // the ID of the account at the source
  accountGroup?: number;
  accountType?: string;
  accountSubtype?: string;

  aggregationState?: string;
  aggregationStatus?: string;
  lastAggregatedAt?: string;       // ISO8601 date
  lastAggregatedSuccess?: string;  // ISO8601 date

  currentBalance?: number;
  currency?: string;
  institutionId?: number;
  institutionEntityKey?: string;
  institutionAccountName?: string;
  institutionAccountNumber?: string;
  nickname?: string;
  branchName?: string;

  timeCreated?: string;   // ISO8601 date
  timeUpdated?: string;   // ISO8601 date

  isInEdit?: boolean; // Used and set in front-end to determine whether to list input fields for the user to edit this account
  properties?: any;     // extra properties for the account
  friendlyName?: string;
}

export enum AccountType {
  savings = 'savings',
  lifestyle = 'lifestyle',
  investment = 'investment',
  superannuation = 'superannuation',
  realty = 'realty',        // for real-estate
  mortgage = 'mortgage',
  loan = 'loan',
  creditcard = 'creditcard',

  // @deprecated
  bank = 'bank',
  credit_card = 'credit_card',
  stored_value = 'Electronic',   // (electronic money)
  point = 'point',
  pension = 'pension',
  property = 'property',
}

export interface AccountBalance {
  id?: number;
  balanceId?: number;
  date?: string;
  balance?: number;
  balance_type?: number;
  due_amount?: number;
  due_date?: string;
  account_id?: number;
}

export interface Transaction {
  id?: number;
  transactionId?: number;
  expenseType?: number;
  amount?: number;
  date?: string;
  descriptionGuest?: string;
  descriptionPretty?: string;
  descriptionRaw?: string;
  account?: MoneyAccount;
  category?: TransactionCategory;
  topCategory?: TransactionCategory;
  pairedTransaction?: any;
  //  "attributes": {
  //    "balance": "12345.0"
  //   },
  createdAt?: string; // ISO8601 date
  updatedAt?: string; // ISO8601 date
}

export enum AccountSource {
  user = 'user',         // A user-editable account
  moneytree = 'moneytree',    // An account sourced from Moneytree
  yodlee = 'yodlee',
  ids = 'ids',          // IDS property valuations
}

/**
 * A Transaction Category
 */
export interface TransactionCategory {
  id?: number;
  categoryId?: number;
  categoryType?: string;
  name?: string;
  slug?: string;
  parent?: TransactionCategory;
  children?: TransactionCategory[];
  isSystem?: boolean;
  createdAt?: string;
  updatedAt?: string;
  source?: string;
}

export interface Budget {
  user?: User;
  name?: string;
  category?: TransactionCategory;
  account?: MoneyAccount;       // optional account
  period?: Period;
  timeStart?: string;   // for temporary budget items
  timeEnd?: string;     // for temporary budget items
  amount?: number;
  progress?: number
  currency?: string;
}

export enum Period {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year'
}

export const aggregationStatusMessage = {
  'success': 'Most recent data refresh was successful.',
  'running.auth': 'Trying to access target site',
  'running.data': 'Successfully authenticated with target site and currently downloading new data.',
  'running.intelligence': 'Processing new data into Moneytree\'s system.',
  'auth.creds.otp.invalid': 'Wrong OTP password',
  'auth.creds.otp.missing': 'Timed out waiting for OTP password',
  'auth.creds.captcha.invalid': 'Wrong CAPTCHA answer',
  'auth.creds.puzzle.invalid': 'Wrong puzzle answer',
  'auth.creds.invalid': 'Failed to login with the registered details.',
  'auth.creds.security.invalid': 'Moneytree has wrong answer for security questions.',
  'auth.creds.locked.temporary': 'Access to the site is locked temporarily.',
  'auth.creds.locked.permanent': 'Access to the site is locked. Guest needs to contact the institution.',
  'error.permanent': 'Fatal error, Moneytree needs to take action.',
  'error.temporary': 'Temporary error, will be retried at a later time or on request.',
  'error.session': 'Session was interrupted during data aggregation.',
  'error.network': 'Network error',
  'error.service.unavailable': 'Site is not available.',
  'guest.intervention.required': 'Guest needs to log into their financial institution directly.',
  'inactive': 'Moneytree no longer updates this account\'s data.',
  'suspended.missing-answer.auth.security': 'Waiting for authentication(security questions) by guest.',
  'suspended.missing-answer.auth.otp': 'Waiting for a OTP password.',
  'suspended.missing-answer.auth.captcha': 'Waiting for CAPTCHA answer.',
  'suspended.missing-answer.auth.puzzle': 'Waiting for puzzle captcha answer.',
};

export interface TimeInterval {
  from: string;     // ISO 9660 date
  to: string;     // ISO 9660 date
}

export interface TimeIntervalValues extends TimeInterval {
  label: string;
  values: number[];
}

export interface TimeIntervalTable {
  /**
   * The whole date range these values are for
   */
  interval: TimeInterval;

  /**
   * Period between data values.
   */
  period: Period;

  /**
   * The names of values.
   * Each TimeIntervalValue has a tuple of values for these names.
   */
  names: string[];

  /**
 * The names of values.
 * Each TimeIntervalValue has a tuple of values for these names.
 */
  extra?: any;

  /**
   * List of time interval values.  These are values for sub ranges within the overall date range.
   */
  data: TimeIntervalValues[];
}

export interface BudgetProgress {
  startDate?: string,
  endDate?: string,
  period?: Period,
  items?: BudgetCategoryProgress[],
}

export interface BudgetCategoryProgress {
  category?: TransactionCategory,
  startDate?: string,
  endDate?: string,
  period?: Period,
  total?: number;      // total budget amount for the category
  progress?: number;   // the amount achieved so-far for the time period.
  items?: Budget[],
}

export interface GoalType {
  slug?: string;
  name?: string;
  category?: string;   // category slug
  dialogType?: GoalDialogType;
  accountCategory?: AccountType;
  measure?: GoalMeasure;
}

export enum GoalDialogType {
  savings = 'savings',
  property = 'property',
  debt = 'debt',
  retirement = 'retirement',
}

export enum ContributionType {
  monthly_amount = 'monthly_amount',
  annual_amount = 'annual_amount',
  salary_percentage = 'salary_percentage',
}

export enum GoalMeasure {
  date = 'date',
  contributions = 'contributions',
}

export enum GoalStatus {
  achieved = 'achieved',
  cancelled = 'cancelled',
}

export enum GoalTarget {
  date = 'date',
  contributions = 'contributions',
}


/**
 * A financial goal
 */
export interface Goal {
  id?: number
  name?: string;                // A name given to this goal.
  description?: string;         // Details about the goal.
  status?: GoalStatus;

  type?: GoalType;              // GoalType
  category?: AccountType;	      // Account category: investment, superannuation, lifestyle, property, mortgage, loan, creditcard 
  dialogType?: GoalDialogType;	// the sub-type of goal. Drives survey flow.

  startAmount?: number;			    // starting balance
  startDate?: string;           // LocalDate: 'YYYY-MM-DD'

  targetAmount?: number;			  // the amount to target for this goal.  (targetTotal * targetPercentage)
  targetTotal?: number;			    // the total target amount
  targetPercentage?: number;		// percentage of target balance to achieve. null = 100%.  [0..1]
  targetDate?: string;          // LocalDate: 'YYYY-MM-DD'

  targetContrib?: number;		    // the target contribution amount or percentage
  contribType?: ContributionType;	// the type of contribution. defaults to monthly amount
  target?: GoalTarget;
  measure?: GoalMeasure;			  // fix a target date or target contribution
  currentBalance?: number;		  // the current balance towards the target
  balanceInterest?: number;	    // the interest rate on any balance.

  account?: MoneyAccount;			  // optional associated account
  data?: any;			              // extra data for this goal

  // TODO These were part of prototype. Merge these with service fields.
  section?: string,
  sectionColor?: string;
  /*
  completed?: boolean,
  title?: string,
  target?: {
    deadline: Date,
    monthlySaving: number,
    sum: number,
  },
  progress?: {
    deadline: Date,
    monthlySaving: number,
    sum: number,
  },
  */
}

export interface Report {
  name: string;
  params?: any;
  title?: string;
  description?: string;
  content?: any;
}

export interface SectionReport {
  section?: string;   // section slug
  position?: { assets?: number; debt?: number; net?: number; categories?: { creditcard?: number, loan?: number } };
  movement?: {
    budget?: { income?: number; spending?: number; savings?: number; };
    actual?: { income?: number; spending?: number; savings?: number; };
  };
  projection?: any;
  goal?: Goal;
  amount?: number;
}

export interface SavingsOverview {
  from?: string;        // date for start of month, in ISO8601 format
  age?: number;         // user's age, in years
  balance?: number;     // balance of all savings

  // budget details
  budget?: {
    month?: {
      categories?: any;                   // budgets and actuals for each budget category
      totals?: { budget?: any; actual?: any }
    };
    annual?: {
      categories?: any;
      totals?: { budget?: any; actual?: any }
    }
  }

  // income details
  income?: {
    finances_scope?: string;
    income_individual_gross?: number;     // annual income before tax
    income_partner_gross?: number;        // partner's annual income before tax
    income_other_gross?: number;
    total?: number;                       // total income
    income_growth_rate?: number;          // percentage annual growth of income
    income_peak_earnings_age?: number;
    income_career_break_start?: string;   // date of start of career break
    income_career_break_end?: string;     // date of break end 0..5
    income_career_break_days?: number;    // number of days per week working during break
    retirement_age?: number;
  }

  projection?: TimeIntervalTable;         // savings projection
}

export interface ProgressSummary {
  slug: string;
  heading: string;
  content: string;
  values: any;
}
