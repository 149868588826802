import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { LoggerService } from '../other/logger.service';
import { Result } from '../model/result';
import { MyHttpParameterCodec } from './money.service';
import { Report, SavingsOverview, SectionReport } from '../model/money';
import * as moment_ from 'moment';
import { Moment } from 'moment';
const moment = moment_;


/**
 * UserService
 */
@Injectable({ providedIn: 'root' })
export class ReportService {
  apiUrl = this.environment.apiUrl;
  private log: any;

  /**
   * Constructor
   */
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private http: HttpClient,
    private logger: LoggerService
  ) {
    this.log = this.logger.info('reportService');
  }

  /**
   * Fetch metrics for indicators.
   * @param request 
   */
  public getMetrics(request: Report[]): Observable<Result<Report>[]> {
    const path = '/me/metrics';
    return this.http.put<Result<Report>[]>(this.apiUrl + path, request);
  }

  /**
   * Fetch summary data for a section.
   * 
   * @param section 
   * @param from 
   */
  public getSectionSummary(section: string, from?: Moment): Observable<SectionReport> {
      let path = '/me/report/rollit.SectionSummary';
      let params = new HttpParams({ encoder: new MyHttpParameterCodec() })
        .append('section', section);
      if (from) {
        params.append('from', from.toISOString());
      }
      return this.http.get<SectionReport>(this.apiUrl + path, { params });
  }

  public getSavingsOverview(from?: Moment): Observable<SavingsOverview> {
    let path = '/me/report/rollit.SavingsOverview';
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params.append('from', from.toISOString());
    }
    return this.http.get<SectionReport>(this.apiUrl + path, { params });
}
}
