import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-inner-sub-menu',
  templateUrl: './dashboard-inner-sub-menu.component.html',
  styleUrls: ['./dashboard-inner-sub-menu.component.scss']
})
export class DashboardInnerSubMenuComponent implements OnInit, OnDestroy {
  @Input() data: any;
  childData: any;
  parent: string;
  path: string;
  log: any;
  private _isDestroyed = new Subject();
  isDesktop: boolean;

  constructor(private logger: LoggerService, private route: ActivatedRoute, private uiService: UIManagerService) {
    this.log = this.logger.info('DashboardInnerSubMenuComponent');
    this.uiService.mediaSizeIsDesktop$.subscribe(isDesktop => this.isDesktop = isDesktop);
    this.route.url.pipe(takeUntil(this._isDestroyed)).subscribe((segment) => {
      if (this.data) {
        this.childData = this.data.snapshot.routeConfig.children.slice(1);
        this.log(this.childData);
        this.parent = this.data.data._value.title;
        this.path = this.data.snapshot._urlSegment.segments[1].path;
      }
    }
    );
  }

  ngOnInit(): void {
    this.childData = this.data.snapshot.routeConfig.children.slice(1);
    this.parent = this.data.data._value.title;
    this.path = this.data.snapshot._urlSegment.segments[1].path;
  }

  ngOnDestroy(): void {
    this._isDestroyed.next();
    this._isDestroyed.complete();
  }

}
