import { Component, Input, OnInit } from '@angular/core';
import { MeService } from '@rollit/shared/data';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerDialogComponent } from '../disclaimer/disclaimer-dialog/disclaimer-dialog.component';
import { User } from '@rollit/shared/data';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  @Input() me: User;

  constructor(private userService: MeService, public dialog: MatDialog) { }

  ngOnInit() {
    if (!this.me.extra.acceptedDisclaimer && this.me.subscriptions.length > 0) {
      setTimeout(() => this.openDisclaimerDialog(), 50); // Workaround for bug @ https://github.com/angular/material2/issues/5268
    }
  }

  onAcceptDisclaimer() {
    this.userService.acceptDisclaimer().subscribe();
  }

  openDisclaimerDialog(): void {
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, { disableClose: true, closeOnNavigation: false });

    dialogRef.afterClosed().subscribe(() => {
      this.onAcceptDisclaimer();
      Intercom('trackEvent', 'web-app-disclaimer-accepted');
    });
  }

}
