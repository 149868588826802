<div class="goal-summary" *ngIf="goal" [routerLink]="['/coach/goals']">
  <div class="goal-summary__title paragraph2">Goal: {{ goal?.name }}</div>
  <app-progress-bar [progressSum]="progressSum" [targetSum]="targetSum" [section]="section">
  </app-progress-bar>
</div>

<div class="goal-summary" *ngIf="!goal">
  <app-new-savings-goal icon="home" [section]="section" (newGoalClicked)="newGoal.next(section)">
  </app-new-savings-goal>
</div>
