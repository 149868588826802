<!-- Company plan info -->
<div fxLayout="row" fxLayoutAlign="space-between start" class="plan-wrapper" [ngClass]="{'desktop': mediaSizeIsDesktop}">

    <div *ngIf="billingCycle === billingCycleEnum.monthly" class="plan" [ngClass]="{'active': billingCycle === billingCycleEnum.monthly}">
        <div *ngIf="numberOfEmployeesControl.value && billingCycle === billingCycleEnum.monthly">
            <h2 class="payment-info">Payment information</h2>
            <app-payment-details [product]="product" [subscriberType]="subscriberType" [numberOfEmployees]="numberOfEmployees" [numberOfEmployeesControl]="numberOfEmployeesControl" [signupError]="signupError" [billingCycle]="billingCycleEnum.monthly" [price]="price" [discount]="discount" (discountCode)="onDiscountCode($event)" (submitOrder)="onSubmitOrder($event)"></app-payment-details>
        </div>
    </div>

    <div *ngIf="billingCycle === billingCycleEnum.annually" class="plan" [ngClass]="{'active': billingCycle === billingCycleEnum.annually}">
        <div *ngIf="numberOfEmployeesControl.value && billingCycle === billingCycleEnum.annually">
            <h2 class="payment-info">Payment information</h2>
            <app-payment-details [product]="product" [subscriberType]="subscriberType" [numberOfEmployees]="numberOfEmployees" [numberOfEmployeesControl]="numberOfEmployeesControl" [signupError]="signupError" [billingCycle]="billingCycleEnum.annually" [price]="price" [discount]="discount" (discountCode)="onDiscountCode($event)" (submitOrder)="onSubmitOrder($event)"></app-payment-details>
        </div>
    </div>

</div>