
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UIManagerService, FundFilterService } from '@rollit/shared/services';
import { InvestmentOptionFilter } from '@rollit/shared/data';
import { Subject } from 'rxjs';

@Component({
  selector: 'filter-button-verbose',
  templateUrl: './filter-button-verbose.component.html',
  styleUrls: ['./filter-button-verbose.component.scss']
})
export class FilterButtonVerboseComponent implements OnInit, OnDestroy {

  displayFilterInMenu: boolean;
  filter: InvestmentOptionFilter;    // the filter used to fetch funds
  fundYears: number;
  fundType: string;
  scrollUpMenuButton: boolean;
  isUnsubscribed = new Subject();

  constructor(private _uIManagerService: UIManagerService, private fundFilterService: FundFilterService) {
    this.fundFilterService.filter$.pipe(takeUntil(this.isUnsubscribed)).subscribe((filter: InvestmentOptionFilter) => {
      this.filter = filter;
      this.fundYears = this.filter.returnYears;
      this.fundType = this.fundFilterService.getCurrentSectorFiltersMessage(this.filter.sectors);
    });
  }

  onClick(): void {
    this._uIManagerService.filterMenuIsOpen = true;
  }

  ngOnInit() {
    this._uIManagerService.displayFilterInMenu.pipe(takeUntil(this.isUnsubscribed)).subscribe(val => this.displayFilterInMenu = val);
    this._uIManagerService.scrollUpMenuButton.pipe(takeUntil(this.isUnsubscribed)).subscribe(val => this.scrollUpMenuButton = val);
  }

  ngOnDestroy(): void {
    this.isUnsubscribed.next();
    this.isUnsubscribed.complete();
  }

}
