export enum EmploymentType {
    PAYE = 'PAYE',
    SoleTrader = 'SoleTrader', // self emplyed
    Partnership = 'Partnership', // self emplyed
    Company = 'Company', // self emplyed
    Student = 'Student', // unemployed
    HomeDuties = 'HomeDuties', // unemployed
    Unemployed = 'Unemployed', // unemployed
}
export enum LoanType {
    Purchase = 'Purchase',
    Refinance = 'Refinance',
    Construction = 'Construction',
}
export enum RepaymentType {
    PrincipalAndInterest = 'PrincipalAndInterest',
    InterestOnly = 'InterestOnly',
}
export enum Purpose {
    OwnerOccupied = 'OwnerOccupied',
    Investment = 'Investment',
}

export interface LoanUser {
    emailAddress: string;
    firstName: string;
    lastName: string;
}

export interface Loan {
    loanType: LoanType;           // "Refinance"
    repaymentType: RepaymentType;      // "PrincipalAndInterest"
}

export interface ExistingLoan {
    loanAmount: number;    // The balance of the existing loan.
    remainingLoanTerm: number;    // Remaining term in years
    remainingLoanTermMonths: number;    // Remaining months left
    lender?: string;    // Lender name
    interestRate: number;    // Interest rate of loan.
}

export interface LoanProperty {
    estimatedValue: number;
    existingLoan: ExistingLoan;
    purpose: Purpose;           // "OwnerOccupied"
}

export interface LoanEmployment0 {
    employed: boolean;          // might be string instead of boolean, with "true" == true
    employmentType: EmploymentType;   // "PAYE"
    grossAnnualSalary: number;           // Salary in $
}

export interface Applicant {
    emailAddress: string;
    employments: LoanEmployment0[];
    firstName: string;
    lastName: string;
    mobileNumber: string;
}

export interface Account {
    newUser: LoanUser;
    newLoan: Loan;
    property: LoanProperty;
    applicants: Applicant[];
}

export interface LoanScore0 {
    loanScore?: number;
    loanScoreRound?: number;
    newLenderInterestSaving?: number;
    loanScoreWebUrl?: string;
}

