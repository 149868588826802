<mat-dialog-content>
    <div>
        <form id="nonce-form" novalidate action="https://localhost/api/payment/test" method="post">
            Save credit card
            <div class="input-wrapper">
                <span>Card Number:</span>
                <div id="sq-card-number"></div>
            </div>
            <div class="input-wrapper">
                <span>CVV:</span>
                <div id="sq-cvv"></div>
            </div>
            <div class="input-wrapper">
                <span>Expiration Date:</span>
                <div id="sq-expiration-date"></div>
            </div>
            <div class="input-wrapper">
                <span>Postcode:</span>
                <div id="sq-postal-code"></div>
            </div>

            <button id="sq-creditcard" mat-raised-button class="rollit-button " color="primary" (click)="requestCardNonce($event)" [disabled]="!formLoaded">Save card details</button>

            <!--
			After a nonce is generated it will be assigned to this hidden input field.
			-->
            <input type="hidden" id="card-nonce" name="nonce" [value]="nonce">
        </form>

    </div>

</mat-dialog-content>