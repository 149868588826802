import { Component, OnInit, Input, Inject } from '@angular/core';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { SubscriptionService } from '@rollit/shared/data';
import { PaymentDetails, PaymentMethodType } from '@rollit/shared/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { SCSS_VARS } from '@rollit/shared';
import { MeService } from '@rollit/shared/data';
import { Subscription } from '@rollit/shared/data';

declare var SqPaymentForm: any; // script for this is include in <head> for now
const TRACE = true;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() employerId: number;
  @Input() subscripton: Subscription;
  log: any;
  paymentForm: any;
  formLoaded: boolean = false;
  nonce: any;
  errors: string[];

  /**
   * Constructor
   */
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private userService: MeService,
    private subscriptionservice: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<PaymentComponent>,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('paymentCOmponent');
    this.employerId = data.employerId;
    this.subscripton = data.subscription;
  }

  /**
   * Initialise this component
   */
  ngOnInit() {
    this.loadSquarePayments();

  }

  private setupSquarePaymentForm() {
    const self = this;

    // Create and initialize a payment form object
    this.paymentForm = new SqPaymentForm({

      // Initialize the payment form elements
      applicationId: this.environment.square.applicationId,
      locationId: this.environment.square.locationId,
      autoBuild: false,
      inputClass: 'sq-input',

      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [{
        fontSize: '1em',
        color: SCSS_VARS['$secondary-alpha']
      }],
      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: '•••• •••• •••• ••••'
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV'
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY'
      },
      postalCode: {
        elementId: 'sq-postal-code'
      },

      // SqPaymentForm callback functions
      callbacks: {

        /*
         * callback function: methodsSupported
         * Triggered when: the page is loaded.
         */
        methodsSupported: function (methods) {
          if (TRACE) {
            self.log('payment methods supported', methods);
          }
        },

        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: function (errors, nonce, cardData, billingContact, shippingContact) {

          self.log('cardNonceResponse', errors, nonce);


          self.nonce = nonce;
          self.errors = errors;

          if (errors) {
            // Log errors from nonce generation to the Javascript console
            errors.forEach(function (error) {
              if (error.field === 'cardNumber') {
                const el = document.createElement("span");
                el.innerHTML = error.message;
                el.setAttribute('style', 'color: red; font-size: 12px;');
                const div = document.getElementById('sq-card-number');
                if (!div.nextElementSibling) {
                  div.parentNode.insertBefore(el, div.nextSibling);
                }

              }
              if (error.field === 'cvv') {
                const el = document.createElement("span");
                el.innerHTML = error.message;
                el.setAttribute('style', 'color: red; font-size: 12px;');
                const div = document.getElementById('sq-cvv');
                if (!div.nextElementSibling) {
                  div.parentNode.insertBefore(el, div.nextSibling);
                }
              }
              if (error.field === 'expirationDate') {
                const el = document.createElement("span");
                el.innerHTML = error.message;
                el.setAttribute('style', 'color: red; font-size: 12px;');
                const div = document.getElementById('sq-expiration-date');
                if (!div.nextElementSibling) {
                  div.parentNode.insertBefore(el, div.nextSibling);
                }
              }
              if (error.field === 'postalCode') {
                const el = document.createElement("span");
                el.innerHTML = error.message;
                el.setAttribute('style', 'color: red; font-size: 12px;');
                const div = document.getElementById('sq-postal-code');
                if (!div.nextElementSibling) {
                  div.parentNode.insertBefore(el, div.nextSibling);
                }
              }
              self.log(' ', error);
            });
            return;
          }

          if (TRACE) {
            self.log('Nonce received: ' + nonce); /* FOR TESTING ONLY */
          }

          const paymentDetails: PaymentDetails = {
            type: PaymentMethodType.Card,
            cardNonce: nonce,
            billingAddress: billingContact,
            shippingAddress: shippingContact,
            save: true,
          };

          self.submitPaymentDetails(paymentDetails);   // send details
        },

        /*
         * callback function: unsupportedBrowserDetected
         * Triggered when: the page loads and an unsupported browser is detected
         */
        unsupportedBrowserDetected: function () {
          if (TRACE) {
            self.log('unsupported browser');
          }
          /* TODO PROVIDE FEEDBACK TO SITE VISITORS */
        },

        /*
         * callback function: inputEventReceived
         * Triggered when: visitors interact with SqPaymentForm iframe elements.
         */
        inputEventReceived: function (inputEvent) {
          // self.log('inputEvent', inputEvent);
          switch (inputEvent.eventType) {
            case 'focusClassAdded':
              const div = document.getElementById(inputEvent.elementId);
              div.classList.remove("sq-input--error");
              if (div.nextElementSibling) {
                div.nextElementSibling.remove();
              }
              break;
            case 'focusClassRemoved':
              /* HANDLE AS DESIRED */
              break;
            case 'errorClassAdded':
              /* HANDLE AS DESIRED */
              break;
            case 'errorClassRemoved':
              /* HANDLE AS DESIRED */
              break;
            case 'cardBrandChanged':
              /* HANDLE AS DESIRED */
              break;
            case 'postalCodeChanged':
              /* HANDLE AS DESIRED */
              break;
          }
        },

        /*
         * callback function: paymentFormLoaded
         * Triggered when: SqPaymentForm is fully loaded
         */
        paymentFormLoaded: function () {
          if (TRACE) {
            self.log('payment form loaded');
          }
          self.log(document.getElementById('sq-card-number'));
          self.formLoaded = true;
        }
      }
    });

    self.paymentForm.build();
  }

  private loadSquarePayments() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('squarePayments').then(data => {
      this.log('dynamicScriptLoader', data);
      this.log('SqPaymentForm', SqPaymentForm);
      setTimeout(() => this.setupSquarePaymentForm(), 100);
    }).catch(error => this.log(error));
  }
  /**
   * function: requestCardNonce
   *
   * requestCardNonce is triggered when the "Pay with credit card" button is
   * clicked
   *
   * Modifying this function is not required, but can be customized if you
   * wish to take additional action when the form button is clicked.
   */
  public requestCardNonce(event) {
    if (TRACE) {
      this.log('requesting card nonce', event);
    }

    // Request a nonce from the SqPaymentForm object
    this.paymentForm.requestCardNonce();
  }

  public submitPaymentDetails(paymentDetails: PaymentDetails) {
    this.log('submitting payment details');

    // TODO add payment details and associate with employer subscription.
    this.userService.createPaymentMethod(paymentDetails).subscribe(
      paymentMethod => {
        this.subscriptionservice.setSubscriptionPaymentMethod(this.subscripton.id, paymentMethod).subscribe(
          subscription => {
            this.log('Subscription has new payment method', subscription);
            this.dialogRef.close(subscription);
          }
        );
      });
  }

}
