<ng-container>

  <div class="group-panel">

    <div class="navigation" *ngIf="isDesktop && propertyCount > 0">
      <div class="item prev paragraph2" [ngClass]="{'disabled': propertyListId === 0 }"
        (click)="propertyListId !== 0 ? scrollPrev() : false">
        <i class="material-icons">navigate_before</i> Previous
      </div>

      <div class="item next paragraph2" [ngClass]="{'disabled': propertyListId  === propertyCount }"
        (click)="propertyListId !== propertyCount ? scrollNext() : false">
        Next <i class="material-icons">navigate_next</i>
      </div>
    </div>

    <app-section-hero [title]="'Super fund'" [description]="acc?.properties?.super?.fundName" imageCaption=""
      [showDelete]="true" [imagePath]="imagePath" [expanded]="isDesktop" (expandClicked)="expandClicked()"
      (uploadComplete)="uploadComplete($event)">


      <div class="titleGroup">
        <app-title-group [details]="titleGroupDetails" [displayAsColumn]="isDesktop"></app-title-group>
      </div>

      <div class="heroContent">

        <app-superannuation-projections *ngIf="projectionChartData.length > 0 && projectionChartCategories.length > 0"
          [inView]="(inView && isDesktop) || expanded" [acc]="acc" [projectionChartData]="projectionChartData"
          [projectionChartCategories]="projectionChartCategories" [projectedRetirementAge]="projectedRetirementAge">
        </app-superannuation-projections>

        <div class="action-holder">
          <button mat-stroked-button (click)="onEditAccount(acc.id)">
            <span class="paragraph2"> Edit </span>
          </button>

          <i class="delete material-icons" (click)="onRemoveAccount()">
            delete
          </i>
        </div>

        <!-- this is for demo -->
        <!--
        <div *ngIf="acc.properties?.super?.optionId">
          <button mat-stroked-button (click)="onSendDocs(acc)">
            <span class="paragraph2">Send Choice of Fund form</span>
          </button>
        </div>
        -->

        <div class="fund-detail" *ngIf="expanded && !isDesktop && acc?.properties?.super?.optionId">
          <app-superannuation-fund-detail id="compare" [fundId]="acc?.properties?.super?.optionId">
          </app-superannuation-fund-detail>
        </div>


      </div>

    </app-section-hero>
  </div>
</ng-container>
