
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { RollitCommonModule } from '@rollit/shared/common';
import { DataServicesModule } from '@rollit/shared/data';
import { RollitUtilsModule } from '@rollit/shared/utils';

import { EmployerHomeComponent } from './employer-home/employer-home.component';
import { OnboardEmployeesComponent } from './onboard-employees/onboard-employees.component';
import { EmployerRoutingModule } from './employer-routing.module';
import { EmployerBillingComponent } from './employer-billing/employer-billing.component';
import { EmployerContainerComponent } from './employer-container/employer-container.component';
import { EmployerSubscriptionsComponent } from './employer-subscriptions/employer-subscriptions.component';
// import { ChartsModule } from 'ng2-charts';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import {
  EmployeeCsvUploaderComponent,
  BirthDateValidatorDirective,
  TelephoneNumberFormatValidatorDirective,
  SexFormatValidatorDirective,
  SpecialCharacterFormatValidatorDirective,
} from './onboard-employees/employee-csv-uploader.component';
import { PaymentComponent } from './employer-billing/payment/payment.component';
import { EmployerPayrollComponent } from './employer-payroll/employer-payroll.component';
import { XeroService } from './service/xero.service';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    EmployerRoutingModule,
    RollitCommonModule,
    // ChartsModule,
    MaterialFileInputModule,
    MatIconModule,
    MatMenuModule,
    DataServicesModule,
    RollitUtilsModule,
    HighchartsChartModule
  ],
  exports: [
  ],
  declarations: [
    EmployerContainerComponent,
    EmployerHomeComponent,
    OnboardEmployeesComponent,
    EmployerBillingComponent,
    EmployerSubscriptionsComponent,
    AddEmployeeComponent,
    EmployeeCsvUploaderComponent,
    BirthDateValidatorDirective,
    TelephoneNumberFormatValidatorDirective,
    SexFormatValidatorDirective,
    PaymentComponent,
    SpecialCharacterFormatValidatorDirective,
    EmployerPayrollComponent,
  ],
  providers: [
    XeroService,
  ],
  entryComponents: [
    AddEmployeeComponent,
    PaymentComponent,
  ]
})
export class EmployerModule { }
