import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, Fact, LoggerService, MoneyAccount, MoneyService, ValueType } from '@rollit/shared/data';
import * as moment_ from 'moment';
import { randomString } from '@rollit/shared/utils';
import { Observable } from 'rxjs';

const moment = moment_;

@Injectable()
export class PropertyService {
  log: any;
  sessionId: string;

  constructor(
    private moneyService: MoneyService,
    private dialogService: DialogService,
    private router: Router,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('PropertyService');
  }

  getProperties(): Observable<MoneyAccount[]> {
    return this.moneyService.getAccounts({ type: 'realty' });
  }

  /**
   * Fetch projections for property account.
   * 
   * @param accountId 
   */
  getProjections(accountId: number, numPeriods: number): Observable<any> {
    const from = moment().startOf('month');
    return this.moneyService.getAccountProjections(accountId, from, null, numPeriods);
  }

  /**
   * start a dialog to create a new realty and (optionally) mortgage
   */
  startAddProperty() {
    const returnPath = this.router.url;
    this.startNewSurvey([], returnPath);
  }

  /**
   * Start a dialog to update realty and mortgage accounts. 
   * 
   * @param id The ID of the existing property account.
   */
  startUpdateProperty(id: number) {
    const facts: Fact[] = [{ slug: 'assets_property_id', type: ValueType.Long, value: id, '@type': 'fact' }];  // the property ID fact
    const returnPath = this.router.url;
    this.startNewSurvey(facts, returnPath);
  }

  startReviewLoan(id: number) {
    const facts: Fact[] = [{ slug: 'assets_property_id', type: ValueType.Long, value: id, '@type': 'fact' }];  // the property ID fact
    const returnPath = this.router.url;
    this.startNewSurvey(facts, returnPath, "loan_review");
  }

  private startNewSurvey(facts: Fact[], returnPath: string, start: string = "_first") {
    this.sessionId = this.dialogService.generateSessionId();

    // initialise dialog with existing facts
    this.dialogService.initDialog('track', 'property', facts, this.sessionId).subscribe(result => {
      // redirect to survey path
      this.router.navigate(['survey', 'track', 'property'], { queryParams: { 'session': this.sessionId, 'return': returnPath, 'start': start } });
    });
  }

}
