import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { InputComponent } from '../input.component';
import { TypeDef, BooleanTypeDef } from '@rollit/shared/data';
import { ValidationErrors, FormControl } from '@angular/forms';

@Component({
  selector: 'lib-input-boolean',
  templateUrl: './input-boolean.component.html',
  styleUrls: ['./input-boolean.component.scss'],
})
export class InputBooleanComponent extends InputComponent implements OnInit {

  formControl: FormControl = new FormControl();

  falseName = "False";
  trueName  = "True";

  constructor() {
    super();
  }

  ngOnInit() {
    this.handleData(true);
  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    const def: BooleanTypeDef = typeDef as BooleanTypeDef;
    this.trueName  = def.trueName;
    this.falseName = def.falseName;
  }

  handleData(data: any) {
    console.log('InputBooleanComponent data',data)
    this.formControl.setValue(data);
  }

  getData() {
    return true;
  }

  hasError(type: any): boolean {
    return false;
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

}
