<div class="section-hero group-panel" [ngClass]="{'desktop':isDesktop}">
  <div class="section-hero__title heading2" title="{{ title }}">{{ title }}</div>
  <div class="section-hero__description heading3" title="{{ description }}">{{ description }}</div>


  <div class="section-hero__image-caption paragraph1">{{ imageCaption }}</div>
  <div class="section-hero__holder" [ngClass]="{'desktop':isDesktop}">
    <div class="section-hero__upload-wrapper">
      <div class="section-hero__upload" [ngStyle]="{ backgroundImage: imagePath ? 'url(' + imagePath + ')' : 'none' }">

      </div>
      <app-file-upload accept="image/*" text="Upload image" (complete)="imgUploaded($event)"></app-file-upload>
    </div>
    <div class="section-hero__title-group">
      <ng-content select=".titleGroup"></ng-content>
    </div>

  </div>
  <div class="section-hero__content">

    <div *ngIf="expanded" [@inOutAnimation]>
      <ng-content select=".heroContent"></ng-content>
    </div>
    <div class="section-hero__content-expand">
      <span *ngIf="!expanded && !isDesktop" class="section-hero__content-show-more paragraph2" (click)="expand()">
        Show more
      </span>
      <span *ngIf="expanded && !isDesktop" class="section-hero__content-show-more paragraph2" (click)="expand()">
        Show less
      </span>
    </div>

  </div>
</div>
