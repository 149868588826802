import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, LoggerService, Progress } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-start-healthcheck',
  templateUrl: './start-healthcheck.component.html',
  styleUrls: ['./start-healthcheck.component.scss']
})
export class StartHealthCheckComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop: boolean;
  progress: Progress[] = []; // quick setup dialog progress
  setupComplete = false;
  private isDestroyed = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private logger: LoggerService,
    private uiService: UIManagerService
  ) {
    this.log = this.logger.info('StartHealthCheckComponent');
    this.log('StartHealthCheckComponent');
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.isDesktop = mediaSizeIsDesktop;
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.log('Destroying SetupStatus');

    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

}
