<div class="main-content-section">
  <div class="section-content start-healthcheck">
    <app-top-menu></app-top-menu>
    <div class="holder">
      <span class="heading2">Financial health check</span>
      <span class="paragraph1">What is your score?</span>
      <span class="paragraph2">Compare your financial health in just a few taps. (2mins)</span>
      <button mat-raised-button color="primary" class="rollit-button" routerLink="/survey/signup/health-check" [queryParams]="{start: '_first', return: '/start-healthcheck'}">Get started</button>
    </div>
  
    <div class="image-holder" [ngClass]="{'desktop':isDesktop}">
      <!-- <img src="/assets/images/HC-Set-up-iphone.png" /> -->
    </div>

  </div>
</div>
