<!-- Individual plan info -->
<div fxLayout="column" fxLayoutAlign="space-between start" class="plan-wrapper"
  [ngClass]="{'desktop': mediaSizeIsDesktop}">
  <div class="plan">
    <div>
      <app-payment-details [product]="product" [billingCycle]="billingCycle" [signupError]="signupError" [subscriberType]="subscriberType"
        [price]="price" [discount]="discount" (discountCode)="onDiscountCode($event)"
        (submitOrder)="onSubmitOrder($event)"></app-payment-details>
    </div>
  </div>
</div>