import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-link-item',
  templateUrl: './profile-link-item.component.html',
  styleUrls: ['./profile-link-item.component.scss']
})
export class ProfileLinkItemComponent implements OnInit {
  @Input() link: string;
  @Input() linkIsExternal: boolean;
  @Input() linkIsInternal: boolean;
  @Input() title: string;
  @Input() active: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onRedirectToLink() {
    if (this.linkIsExternal) {
      window.open(this.link);
    } else if (this.linkIsInternal) {
      this.router.navigateByUrl(this.link);
    } else {
      this.router.navigate(['/profile', { 'outlets': { 'list': ['profile-links'], 'detail': [this.link] } }]);
    }
  }

}
