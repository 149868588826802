import { Compiler, CompilerFactory, COMPILER_OPTIONS, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { SurveyRoutingModule } from './survey-routing.module';
import { SurveyFormComponent } from './form/survey-form/survey-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnswerComponent } from './input/answer.component';
import { InputBooleanComponent } from './input/input-boolean/input-boolean.component';
import { InputNumberComponent } from './input/input-number/input-number.component';
import { InputTextComponent } from './input/input-text/input-text.component';
import { InputSelectComponent } from './input/input-select/input-select.component';
import { InputDateComponent } from './input/input-date/input-date.component';
import { InputMultiComponent } from './input/input-multi/input-multi.component';
import { InputDirective } from './input/input.directive';
import { InputButtonGroupComponent } from './input/input-button-group/input-button-group.component';
import { DataServicesModule } from '@rollit/shared/data';

import { ReportComponent } from './report/report.component';
import { HeaderComponent } from './report/sections/header/header.component';
import { FooterComponent } from './report/sections/footer/footer.component';
import { HeadingComponent } from './report/elements/heading/heading.component';
import { FinancialHealthScoreComponent } from './report/sections/financial-health-score/financial-health-score.component';
import { HomeSituationComponent } from './report/sections/home-situation/home-situation.component';
import { CashflowManagementComponent } from './report/sections/cashflow-management/cashflow-management.component';
import { FinancialSecurityComponent } from './report/sections/financial-security/financial-security.component';
import { HealthScoreChartComponent } from './report/elements/health-score-chart/health-score-chart.component';
import { HomeSituationOwnershipGraphicComponent } from './report/elements/home-situation-ownership-graphic/home-situation-ownership-graphic.component';
import { HomeSituationAgePercentageChartComponent } from './report/elements/home-situation-age-percentage-chart/home-situation-age-percentage-chart.component';
import { CashflowExpensesStressGraphicComponent } from './report/elements/cashflow-expenses-stress-graphic/cashflow-expenses-stress-graphic.component';
import { FinancialSecurityBarchartComponent } from './report/elements/financial-security-barchart/financial-security-barchart.component';
import { CashflowExpensesChartComponent } from './report/elements/cashflow-expenses-chart/cashflow-expenses-chart.component';
import { FinancialSecurityGraphicComponent } from './report/elements/financial-security-graphic/financial-security-graphic.component';
import { HealthScoreGraphicComponent } from './report/elements/health-score-graphic/health-score-graphic.component';
import { HomeSituationStatePercentageChartComponent } from './report/elements/home-situation-state-percentage-chart/home-situation-state-percentage-chart.component';
import { SetupStatusComponent } from './setup-status/setup-status.component';
import { UIManagerService } from '@rollit/shared/services';
import { InputRealtyComponent } from './input/input-realty/input-realty.component';
import { InputRealtyEstimateComponent } from './input/input-realty-estimate/input-realty-estimate.component';
import { InputSuperComponent } from './input/input-super/input-super.component';
import { ReportInlineComponent } from './report/report-inline/report-inline.component';
import { CompileDirective } from './input/compile.directive';
import { JitCompilerFactory } from "@angular/platform-browser-dynamic";
import { InputAccountComponent } from './input/input-account/input-account.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { InputSumComponent } from './input/input-sum/input-sum.component';
import { InputLenderComponent } from './input/input-lender/input-lender.component';
import { InputAccountMatchComponent } from './input/input-account-match/input-account-match.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}
@NgModule({
  imports: [
    CommonModule,
    SurveyRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RollitCommonModule,
    DataServicesModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
  ],
  declarations: [
    SurveyFormComponent,
    AnswerComponent,
    InputBooleanComponent,
    InputNumberComponent,
    InputTextComponent,
    InputSelectComponent,
    InputButtonGroupComponent,
    InputDateComponent,
    InputMultiComponent,
    InputRealtyComponent,
    InputRealtyEstimateComponent,
    InputSuperComponent,
    InputAccountComponent,
    InputSumComponent,
    InputLenderComponent,
    InputAccountMatchComponent,
    InputDirective,
    CompileDirective,
    SetupStatusComponent,
    ReportComponent,
    ReportComponent,
    HeaderComponent,
    FooterComponent,
    HeadingComponent,
    FinancialHealthScoreComponent,
    HomeSituationComponent,
    CashflowManagementComponent,
    FinancialSecurityComponent,
    HealthScoreChartComponent,
    HealthScoreGraphicComponent,
    HomeSituationOwnershipGraphicComponent,
    HomeSituationAgePercentageChartComponent,
    HomeSituationStatePercentageChartComponent,
    CashflowExpensesStressGraphicComponent,
    FinancialSecurityBarchartComponent,
    CashflowExpensesChartComponent,
    FinancialSecurityGraphicComponent,
    ReportInlineComponent,
  ],
  entryComponents: [
    InputBooleanComponent,
    InputNumberComponent,
    InputTextComponent,
    InputSelectComponent,
    InputButtonGroupComponent,
    InputDateComponent,
    InputMultiComponent,
    InputRealtyComponent,
    InputRealtyEstimateComponent,
    InputSuperComponent,
    InputAccountComponent,
    InputLenderComponent,
    InputAccountMatchComponent,
  ],
  providers: [
    UIManagerService,
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
    CurrencyPipe,
    PercentPipe,
    DatePipe,
  ]
})
export class SurveyModule { }
