import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, BehaviorSubject, empty, of } from 'rxjs';
import { mergeMap, map, take, switchMap, distinctUntilChanged, filter } from 'rxjs/operators';
import { FundService, MeService } from "@rollit/shared/data";
import { InvestmentOption, InvestmentProduct } from '@rollit/shared/data';


@Injectable()
export class UserDataService {
    private _products$ = new ReplaySubject(1);    // Used to make sure that fetchProducts() has completed before continuing.
    private _products = {};
    private _compareOption = new BehaviorSubject<InvestmentOption>(null);
    private _returnDollarValue = new BehaviorSubject<number>(100000);

    constructor(
        private userService: MeService,
        private fundService: FundService
    ) {
        this.fetchProducts().subscribe();

        this.userService.properties$.pipe(
            switchMap(properties => {
                if (properties) {
                    // dollar amount for net-return
                    if (properties.compareAmount && this._returnDollarValue.value !== properties.compareAmount) {
                        this._returnDollarValue.next(properties.compareAmount);
                    }

                    // comparison invesment option
                    if (this._compareOption.value !== null && (!properties.compareInvestment || this._compareOption.value.id === properties.compareInvestment.id)) {
                        return of(this._compareOption.value);
                    }
                    else if (properties.compareInvestment && (this._compareOption.value === null || this._compareOption.value.id !== properties.compareInvestment.id)) {
                        return this.fundService.getInvestmentOption(properties.compareInvestment.id);
                    }
                    else {
                        return of(null);
                    }
                }
            }),
            map(option => {
                this._compareOption.next(option);
                return option;
            })
        ).subscribe();
    }

    /*
     * Value of dollar balance for determining net return.
     */
    public get returnDollarValue$(): Observable<number> {
        return this._returnDollarValue.pipe(distinctUntilChanged());
    }

    /**
     * Investment option selected for comparison.
     */
    public get comparisonOption$(): Observable<InvestmentOption> {
        // do not emit nulls or values that are not a change.
        return this._compareOption.asObservable().pipe(filter(option => option !== null && !!option.id), distinctUntilChanged());
    }

    private set products(products: InvestmentProduct[]) {
        for (const p of products) {
            this._products[p.id] = p;
        }
        this._products$.next(this._products);
    }

    private get products(): InvestmentProduct[] {
        // return Object.values(this._products);
        return Object.keys(this._products).map(function (itm) { return this._products[itm]; });
    }

    get product$(): Observable<InvestmentProduct[]> {
        return this._products$.pipe(map((prods) => {
            // return Object.values(prods);
            return Object.keys(prods).map(function (itm) { return prods[itm]; });
            // return this.products;
        }));
    }

    /**
     * Must be called first before any other calls made to this service.
     */
    fetchProducts(): Observable<InvestmentProduct[]> {
        return this.userService.getSavedProducts().pipe(map(
            (products: InvestmentProduct[]) => {
                this.products = products;
                return products;
            }
        ));
    }

    saveProduct(product: InvestmentProduct): Observable<InvestmentProduct[]> {
        return this._products$.pipe(take(1), mergeMap(() => {
            // const products: InvestmentProduct[] = Object.values(this._products);
            const products: InvestmentProduct[] = Object.keys(this._products).map(function (itm) { return this._products[itm]; });
            products.push(product);

            // update list of products on server
            return this.userService.updateSavedProducts(products).pipe(map(
                value => {
                    this.products = value;  // ensure current list is up to date
                    return value;
                }
            ));
        }));
    }

    unsaveProduct(product: InvestmentProduct): Observable<InvestmentProduct[]> {
        return this._products$.pipe(take(1), mergeMap(() => {
            const products = this._products;
            delete products[product.id];
            // const updatedProducts: InvestmentProduct[] = Object.values(products);
            const updatedProducts: InvestmentProduct[] = Object.keys(products).map(function (itm) { return products[itm]; });

            // update list of products on server
            return this.userService.updateSavedProducts(updatedProducts).pipe(map(
                value => {
                    this.products = value;  // ensure current list is up to date
                    return value;
                }
            ));
        }));
    }

    isProductSaved(id: number): Observable<boolean> {
        return this._products$.pipe(take(1), map(() => {

            return !!this._products[id];
        }));

    }

    getCompareOption(): Observable<InvestmentOption> {
        return this.userService.getUserProperties().pipe(
            switchMap(properties => {
                if (properties && properties.compareInvestment) {
                    return this.fundService.getInvestmentOption(properties.compareInvestment.id);
                }
                else {
                    return of(null);
                }
            }),
            map(option => {
                this._compareOption.next(option);
                return option;
            })
        );
    }

    updateCompareOption(investmentOption: InvestmentOption) {
        this.userService.updateUserProperty('compareInvestment', { id: investmentOption.id }).subscribe(
            props => {
                // updated user properties.

            }
        );
        this._compareOption.next(investmentOption);
    }
}
