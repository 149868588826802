import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { HttpClient } from '@angular/common/http';
import { Order } from '@rollit/shared/data';
import { PaymentDetails, PaymentResult } from '@rollit/shared/data';

const TRACE = true;


@Injectable()
export class CustomerOrderService {
    apiUrl = this.environment.apiUrl;
    constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient) {
    }

    /**
     * This will calculate the price of the order, including any discounts.
     * Price is returned in the resulting Order.
     *
     * @param order
     */
    public checkOrder(order: Order): Observable<Order> {
        const path = '/order';
        return this.http.put<Order>(this.apiUrl + path, order);
    }

    /**
     * submit and pay for order.
     *
     * @param order
     */
    public submitOrder(order: Order): Observable<Order> {
        const path = '/order';
        return this.http.post<Order>(this.apiUrl + path, order);
    }

    /**
     *
     * @param details
     */
    public processPayment(details: PaymentDetails): Observable<PaymentDetails> {
        const path = '/payment/transaction';
        return this.http.post<PaymentResult>(this.apiUrl + path, details);
    }

    public savePaymentDetails(cardDetails: PaymentDetails): Observable<PaymentDetails> {
        const path = '/payment/method';
        return this.http.post<PaymentDetails>(this.apiUrl + path, cardDetails);
    }

    public getPaymentDetails(): Observable<PaymentDetails[]> {
        const path = '/payment/method';
        return this.http.get<PaymentDetails[]>(this.apiUrl + path);
    }
}
