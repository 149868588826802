import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, HostListener, OnDestroy } from '@angular/core';
import { EmployerService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { trackById } from '@rollit/shared/utils';
import { Subscription } from 'rxjs';
import * as moment_ from 'moment';
const moment = moment_;
import { SCSS_VARS } from '@rollit/shared';
import { Employer } from '@rollit/shared/data';
import * as Highcharts from 'highcharts';


const doughnutChartLabels: string[] = [];
@Component({
  selector: 'app-employer-home',
  templateUrl: './employer-home.component.html',
  styleUrls: ['./employer-home.component.scss']
})
export class EmployerHomeComponent implements OnInit, OnDestroy {
  employer: Employer;     // the selected employer to work with.
  activeEmployees: number;       // the number of employees this employer has registered with Rollit
  totalEmployees: number;
  inactiveEmployees: number;
  notOnboarded: number;
  notAccepted: number;
  log: any;
  trackById = trackById;
  chart: any;
  Highcharts: typeof Highcharts = Highcharts; // required
  showChart = false;
  getInstance: any;



  private _employerSubscription: Subscription;


  chartOptions: Highcharts.Options = {
    chart: {
      renderTo: 'container',
      type: 'pie'
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      pie: {
        shadow: false
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y;
      }
    },
    series: [{
      type: 'pie',
      name: 'Subscriptions',
      data: [],
      size: '100%',
      innerSize: '80%',
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }]

  };


  constructor(
    private employerService: EmployerService,
    private logger: LoggerService,
    private ref: ChangeDetectorRef,
  ) {
    //this.doughnutChartLabels = doughnutChartLabels;
    this.log = this.logger.info("EmployerHomeComponent");
    const self = this;
    this.getInstance = chart => {
      self.chart = chart;

      self.initChart();
    };
  }

  ngOnInit(): void {
    this._employerSubscription = this.employerService.currentEmployer$().subscribe(value => {
      this.employer = value;
      if (value) {
        this.employerService.getEmployeeCounts(value.id).subscribe(employeeResult => {
          this.totalEmployees = employeeResult.active + employeeResult.inactive + employeeResult.notOnboarded + employeeResult.notAccepted;
          this.notOnboarded = employeeResult.notOnboarded;
          this.notAccepted = employeeResult.notAccepted;
          this.inactiveEmployees = employeeResult.inactive + this.notOnboarded + this.notAccepted;
          this.activeEmployees = employeeResult.active;
          this.log('employeeResult', employeeResult);
          this.log('totalEmployees', this.totalEmployees);
          this.log('inactiveEmployees', this.inactiveEmployees);
          this.log('activeEmployees', this.activeEmployees);
          //
          // this.doughnutChartData[0] = this.activeEmployees;
          // this.doughnutChartLabels[0] = 'Active';
          // this.doughnutChartData[1] = this.inactiveEmployees;
          // this.doughnutChartLabels[1] = 'Inactive';
          this.chartOptions.series[0]['data'] = [{
            y: this.activeEmployees,
            color: SCSS_VARS['$primary'],
            name: 'active',
          },
          {
            y: this.inactiveEmployees,
            color: SCSS_VARS['$color-wheel-3'],
            name: 'Inactive',
          }];

        });
      }
    });


  }

  ngOnDestroy(): void {
    this._employerSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    setTimeout(() => {
      this.log('size changed.', event);
      this.showChart = true;
      this.ref.detectChanges();
    }, 200);
  }

  initChart() {
    this.log('initChart');
    setTimeout(() => {
      if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        const evt: any = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      } else {
        window.dispatchEvent(new Event('resize'));
      }
    }, 500);
  }


}
