<div class="report">

  <lib-report-header></lib-report-header>

  <lib-report-heading [title]="'My financial health'"></lib-report-heading>

  <lib-report-financial-health-score></lib-report-financial-health-score>

  <lib-report-heading [bg]="1" [title]="'My home situation'"></lib-report-heading>

  <lib-report-home-situation></lib-report-home-situation>

  <lib-report-heading [title]="'My cashflow management'"></lib-report-heading>

  <lib-report-cashflow-management></lib-report-cashflow-management>

  <lib-report-heading [bg]="2" [title]="'My long-term financial security'"></lib-report-heading>

  <lib-report-financial-security></lib-report-financial-security>

  <lib-report-footer></lib-report-footer>
</div>
