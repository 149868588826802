<div class="main-content-section">
  <div id="section-content" class="section-content">
    <app-top-menu></app-top-menu>


    <div class="rollit-grid">

      <div class="rollit-grid-2">
        <div class="form-panel">



          <div *ngIf="connectedAccounts.length === 0 && gotAccounts === true" class="no-accounts">
            <div class="group-panel">


              <div class="security">
                <img src="/assets/icons/trusted.svg">
                <span class="heading2 section-color__growth">Securely link all your<br> accounts across different<br>
                  providers in one
                  place</span>
                <span class="link paragraph1 section-color__secondary" (click)="openLink()">How we protect your
                  data</span>
              </div>
              <div class="features">
                <div class="item"><i class="material-icons">savings</i><span
                    class="paragraph1 section-color__secondary">Banking</span></div>
                <div class="item"><i class="material-icons">credit_card</i><span
                    class="paragraph1 section-color__secondary">Loans and<br> credit cards</span></div>
                <div class="item"><i class="material-icons">insights</i><span
                    class="paragraph1 section-color__secondary">Superannuation</span></div>
                <div class="item"><i class="material-icons">stacked_line_chart</i><span
                    class="paragraph1 section-color__secondary">Investments</span></div>
              </div>
            </div>
          </div>

          <div *ngIf="connectedAccounts.length > 0 && gotAccounts === true" class="accounts">
            <div class="group-panel">

              <div class="heading heading2">Accounts</div>

              <div class="item-holder" [ngClass]="{'desktop': isDesktop }">
                <div class="item" *ngFor="let connectedAccount of connectedAccounts; trackBy: trackById">

                  <div class="provider paragraph2">{{connectedAccount.providerName}}</div>

                  <div class="item-sub" *ngFor="let account of connectedAccount.accounts; trackBy: trackById" [ngClass]="{'pending':account.aggregationState === 'pending'}">
                    <mat-checkbox *ngIf="account.aggregationState !== 'pending'" class="checkbox" [checked]="account?.visible" (change)="showOptions($event)"
                      [value]="account.id">
                    </mat-checkbox>
                    <div class="details" [ngClass]="{'pending':account.aggregationState === 'pending'}" [routerLink]=" account.aggregationState !== 'pending' ? '/transactions/'+account.id : null">
                      <span class="nickname paragraph2" >{{account.nickname}} <span *ngIf="account.aggregationState === 'pending'">(Pending)</span></span>
                      <span
                        class="paragraph2">{{account.currentBalance | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
                      <span class="last-updated paragraph2 section-color__secondary">Last sync
                        {{account.lastUpdated | date}}</span>
                    </div>
                  </div>

                </div>
              </div>

              <!-- <div class="item-holder" [ngClass]="{'desktop': isDesktop }">
                <div class="item" >

                  <div class="provider paragraph2">{{userAccounts[0].providerName}}</div>

                  <div class="item-sub" *ngFor="let account of userAccounts; trackBy: trackById">
                    <mat-checkbox *ngIf="account.aggregationState !== 'pending'" class="checkbox" [checked]="account?.visible" (change)="showOptions($event)"
                      [value]="account.id">
                    </mat-checkbox>
                    <div class="details" [routerLink]="'/transactions/'+account.id">
                      <span class="nickname paragraph2" [ngClass]="{'pending':account.aggregationState === 'pending'}">{{account.nickname}} <span *ngIf="account.aggregationState === 'pending'">(Pending)</span></span>
                      <span
                        class="paragraph2">{{account.currentBalance | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
                      <span class="last-updated paragraph2 section-color__secondary">Last sync
                        {{account.lastUpdated | date}}</span>
                    </div>
                  </div>

                </div>
              </div> -->

            </div>
          </div>
        </div>


      </div>
      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button (click)="manageAccounts()" mat-flat-button class="new-account" color="primary">
            Add account
          </button>
          <button *ngIf="connectedAccounts.length > 0 && gotAccounts === true" routerLink="/accounts/remove-accounts" mat-flat-button class="new-account"
            color="primary">
            Stop sharing
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
