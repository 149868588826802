import { Component, OnInit, Inject, AfterViewInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from '@rollit/shared/data';
import { FormControl, Validators, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { CustomValidators, PasswordErrorStateMatcher } from '@rollit/shared/utils';
import { UserProfile } from '@rollit/shared/data';
import { MeService } from '@rollit/shared/data';

declare global {
  interface Window {
    PasswordCredential: any;
    FederatedCredential: any;
  }
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {

  @ViewChild('formDirective') private formDirective: NgForm;

  profile: UserProfile;
  log: any;
  incorrectCurrentPassword = false;
  errorMessage = '';

  errorStateMatcher = new PasswordErrorStateMatcher();

  currentPassword = new FormControl('', [
    Validators.required
  ]);
  password = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    CustomValidators.matchingPasswords,
  ]);
  password2 = new FormControl('', [
    Validators.required,
    CustomValidators.matchingPasswords,
  ]);

  formGroup: FormGroup = new FormGroup({
    currentPassword: this.currentPassword,
    password: this.password,
    password2: this.password2,
  });

  constructor(
    private userService: MeService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('changePasswordComponent');

    if (data && data.url) {
      this.log('setting profile ', data.profile);
      this.profile = this.data.profile;
    }
  }

  ngOnInit() {

  }

  onFocus(event) {
    if ((this.errorMessage.length > 0) || (this.incorrectCurrentPassword = true)) {
      this.incorrectCurrentPassword = false;
      this.errorMessage = '';
    }
    console.log('focus', event);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Form has been submitted
   */
  onSubmit(event, formDirective: FormGroupDirective) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.formGroup.valid) {
      this.log('Submit password change');
      const creds = {
        password: this.password.value,
        oldPassword: this.currentPassword.value
      };
      this.userService.updateCredentials(creds).subscribe(
        result => {
          if (window.PasswordCredential) {
            // store thje credential
            this.log('storing credential');
            const cred = new window.PasswordCredential({
              id: this.profile.user.username,
              password: creds.password as string
            });
            return navigator.credentials.store(cred).finally(() => {
              this.closeDialog();
            });
          } else {
            this.closeDialog();
          }
        },
        err => {
          // HTTP interceptor will pick up any errors.
          const message = err.error ? (err.error.message ? err.error.message : err.error) : err;
          // window.alert('Problem updating password: ' + err.error.message);
          this.log(err.error);
          if (message === 'Could not validate credentials') {
            this.incorrectCurrentPassword = true;
            this.formGroup.controls['currentPassword'].setValue('');
            this.formGroup.controls['currentPassword'].updateValueAndValidity();
          } else {
            this.errorMessage = "Something went wrong. Double check your details are correct and try again.";
            this.formGroup.reset();
            formDirective.resetForm();
          }

        });
    }
    return false;
  }
}

