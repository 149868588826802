import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';

export interface TitleItemData {
  title: string;
  data: number | Date | string;
  additionalInfo?: string;
  isCurrency?: boolean;
  isDate?: boolean;
  isString?: boolean;
  isNumber?: boolean;
  dataColor: string;
  hide: boolean;
}
@Component({
  selector: 'app-title-group',
  templateUrl: './title-group.component.html',
  styleUrls: ['./title-group.component.scss'],
})
export class TitleGroupComponent implements OnInit {
  @Input() details: Array<TitleItemData>;
  @Input() displayAsColumn: boolean = false;
  @Input() displayFullWidth: boolean = false;
  log: any;

  constructor(private logger: LoggerService) {
    this.log = this.logger.info('TitleGroupComponent');
  }

  ngOnInit(): void {
    //this.log('TitleGroupComponent', this.details);
  }
}
