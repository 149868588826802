import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';
import { SavingsComponent } from './components/savings.component';



const savingsRoutes: Routes = [
  {
    path: 'savings',
    component: SavingsComponent,
    data: {
      title: 'Savings',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(savingsRoutes)],
  exports: [RouterModule],
})
export class SavingsRoutingModule { }
