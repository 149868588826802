import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { CreditCardsLoansRoutingModule } from './credit-cards-loans-routing.module';
import { CreditCardsLoansComponent } from './components/credit-cards-loans.component';
import { LoanService } from './loan.service';
import { CreditCardsLoansHeroComponent } from './components/credit-cards-loans-hero/credit-cards-loans-hero.component';
import { CreditCardsLoansProjectionsComponent } from './components/credit-cards-loans-projections/credit-cards-loans-projections.component';


@NgModule({
  imports: [CommonModule, RollitCommonModule, CreditCardsLoansRoutingModule],
  declarations: [
    CreditCardsLoansComponent,
    CreditCardsLoansHeroComponent,
    CreditCardsLoansProjectionsComponent,
  ],
  providers: [
    LoanService
  ]
})
export class CreditCardsLoansModule { }
