import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Nudge, NudgeCard } from '@rollit/shared/data';
import { SCSS_VARS } from '@rollit/shared';
@Component({
  selector: 'app-nudge-expandable',
  templateUrl: './nudge-expandable.component.html',
  styleUrls: ['./nudge-expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NudgeExpandableComponent implements OnInit {
  @ViewChild('nudgeRef') nudgeRef : ElementRef;
  @Input() nudge: NudgeCard;
  @Input() closeButton: boolean;
  @Input() activeItem: boolean;
  @Input() isDesktop: boolean;

  @Output() closed = new EventEmitter();
  @Output() buttonPressed = new EventEmitter();

  headingCurrency: boolean;
  icon: string;
  title: string;
  backgroundColor: string;
  height = 370;

  iconNames = [
    { 'section': 'savings', 'icon': 'savings', 'title': 'Savings coach' },
    { 'section': 'lifestyle', 'icon': 'directions_car', 'title': 'Lifestyle asset coach' },
    { 'section': 'investments', 'icon': 'stacked_line_chart', 'title': 'Investment coach' },
    { 'section': 'investment', 'icon': 'stacked_line_chart', 'title': 'Investment coach' },
    { 'section': 'superannuation', 'icon': 'insights', 'title': 'Super coach' },
    { 'section': 'property', 'icon': 'domain', 'title': 'Property coach' },
    { 'section': 'realty', 'icon': 'domain', 'title': 'Property coach' },
    { 'section': 'credit', 'icon': 'credit_card', 'title': 'Debt coach' },
    { 'section': 'loan', 'icon': 'credit_card', 'title': 'Debt coach' }
  ];

  constructor(

    private cdr: ChangeDetectorRef,

  ) {

  }

  ngOnInit(): void {

    this.backgroundColor = SCSS_VARS['$rollit-' + this.nudge.section];

    
    // console.log('nudge SCSS_VARS', '$rollit-' + this.nudge.section);
    // console.log('nudge SCSS_VARS', '$rollit-' + this.nudge.section);
    // console.log('nudge section', this.nudge.section);
    // console.log('nudge nudge', this.nudge);
    this.iconNames.forEach((item) => {
      if (this.nudge.section === item.section) {
        this.icon = item.icon;
        this.title = item.title;
      }
    });

    //console.log('nudge activeItem: ', this.activeItem);


  }

  ngAfterViewInit() {
  
      this.resize();

    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize() 
  }

  resize() {

    //console.log('cardwidth',this.nudgeRef.nativeElement.offsetWidth)
    const aspectRatio = 66/37;
    const height = (this.nudgeRef.nativeElement.offsetWidth / aspectRatio )
    //console.log('cardwidth width',height)
    this.height = height; 
    this.cdr.detectChanges();

  }



  onClosed() {
    this.closed.next();
    //console.log('onClosed')
  }

  onButtonPressed() {
    this.buttonPressed.next();
    //console.log('onClosed')
  }
}
