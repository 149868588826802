import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { BudgetElement } from '../../public-api';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],

})
export class BudgetComponent implements OnInit {
  @Input() budgets: Array<BudgetElement> = [
    {
      name: 'Income',
      color: '#05A8DB',
      budget: 3000,
      current: 2000,
      items: [],
    },
    {
      name: 'Living',
      color: '#A31B5C',
      budget: 1000,
      current: 800,
      items: [],
    },
    {
      name: 'Lifestyle',
      color: '#DB0A6E',
      budget: 500,
      current: 500,
      items: [
        { title: 'Eating out', value: 120 },
        { title: 'Entertainment', value: 80 },
        { title: 'Media', value: 200 },
        { title: 'Holiday and leisure', value: 300 },
      ],
    },
    {
      name: 'Housing',
      color: '#761744',
      budget: 1000,
      current: 400,
      items: [],
    },
    {
      name: 'Savings',
      color: '#05A8DB',
      budget: 200,
      current: 200,
      items: [],
    },
  ];

  startDate = new FormControl(new Date(), Validators.required);
  maxDate = new Date();

  @Input() title: string = 'Title';
  @Input() month: Date = new Date();
  @Input() dateDetails: string = '2 days left in the month';

  @Output() date: EventEmitter<void> = new EventEmitter<void>();

  @Output() editBudget: EventEmitter<void> = new EventEmitter<void>();
  @Output() transactions: EventEmitter<void> = new EventEmitter<void>();
  @Output() accounts: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    console.log('BudgetComponent', this.budgets);
  }

  chosenYearHandler(event) {
    //console.log(event);
    //const ctrlValue = this.date.value;
    //ctrlValue.year(normalizedYear.year());
    //this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(event, datepicker: MatDatepicker<any>) {
    //console.log(event);
    //const ctrlValue = this.date.value;
    ///ctrlValue.month(normalizedMonth.month());
    //this.date.setValue(ctrlValue);
    this.date.emit(event);
    datepicker.close();
  }
}
