import { Component, OnInit, ViewChild, Directive, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { EmployerService } from '@rollit/shared/data';
import { Papa } from 'ngx-papaparse';
import { FormControl, NG_VALIDATORS, Validator, ValidationErrors, FormGroup } from '@angular/forms';
import * as moment_ from 'moment';
const moment = moment_;
import * as FuzzySet from 'fuzzyset.js';
import { NotificationService } from '@rollit/shared/data';
import { Subscription } from 'rxjs';
import { LoggerService } from '@rollit/shared/data';
import { Employer, Employee } from '@rollit/shared/data';


const HEADER_ROW = 'Row';
const HEADER_TITLE = 'Title';
const HEADER_FIRSTNAME = 'First Name';
const HEADER_LASTNAME = 'Last Name';
const HEADER_SEX = 'Sex';
const HEADER_DOB = 'Date of Birth';
const HEADER_PHONE = 'Mobile Number';
const HEADER_EMAIL = 'Email Address';
const HEADER_EMPNUM = 'Payroll Number';

const REQUIRED_HEADERS = [
    { name: HEADER_ROW, prop: 'row', type: 'row', optional: true, alts: [] },
    { name: HEADER_TITLE, prop: 'title', type: 'string', optional: true, alts: ['Prefix'] },
    { name: HEADER_FIRSTNAME, prop: 'firstName', type: 'string', optional: false, alts: ['Christian Name'] },
    { name: HEADER_LASTNAME, prop: 'lastName', type: 'string', optional: false, alts: ['Surname'] },
    { name: HEADER_SEX, prop: 'sex', type: 'sex', optional: false, alts: ['Gender'] },
    { name: HEADER_DOB, prop: 'dob', type: 'date', optional: false, alts: ['DOB'] },
    { name: HEADER_PHONE, prop: 'phone', type: 'phone', optional: true, alts: ['Phone', 'Telephone'] },
    { name: HEADER_EMAIL, prop: 'email', type: 'email', optional: false, alts: [] },
    { name: HEADER_EMPNUM, prop: 'payrollNumber', type: 'string', optional: true, alts: ['Employee Number', 'GPID', 'Payroll Number'] },
];


function toDate(value: string) {
    const m = moment(value, 'DD/MM/YYYY');
    return m.format('YYYY-MM-DD');
}


@Component({
    selector: 'app-employee-csv-uploader',
    templateUrl: './employee-csv-uploader.component.html',
    styleUrls: ['./employee-csv-uploader.component.scss']
})
export class EmployeeCsvUploaderComponent implements OnInit, OnDestroy {
    @ViewChild('dataForm') form: FormGroup;
    @ViewChild('fileImportInput') fileImportInput: any;
    log: any;
    requiredHeaders = REQUIRED_HEADERS;
    headerSet;
    csvColumns: string[] = [];
    headers: any[];
    employer: Employer;
    csvRecords: any[] = [];
    fileGroup: FormGroup;
    existingEmployees: any[] = [];
    uploaderHasRun = false;

    uploading = false;
    emailFormControls: FormControl[] = [];
    wasValid = true;        // whether all data was previously valid\

    private _employerSubscription: Subscription;
    @Output() loaded = new EventEmitter<any[]>();
    @Output() errors = new EventEmitter<any[]>();

    @Input()
    set file(value: File) {
        this.handleFile(value);
    }

    /**
     * constructor
     */
    constructor(
        private employerService: EmployerService,
        private notificationService: NotificationService,
        private papa: Papa,
        private logger: LoggerService,
    ) {
        this.log = this.logger.info('employeeCsvUploader');
        this.headerSet = FuzzySet();
        this.requiredHeaders.forEach(element => {
            this.headerSet.add(element.name);
        });
        for (const h of this.requiredHeaders) {
            this.csvColumns.push(h.name);
        }

        this.fileGroup = new FormGroup({
            acceptFile: new FormControl()
        });
    }

    ngOnInit(): void {
        this._employerSubscription = this.employerService.currentEmployer$().subscribe(value => {
            this.employer = value;
        });
    }

    ngOnDestroy(): void {
        this._employerSubscription.unsubscribe();
    }

    fileChangeListener(event) {
        const files = event.srcElement.files;
        if (files && files.length > 0) {
            const file: File = files.item(0);
            this.handleFile(file);
        }
    }

    handleFile(file: File) {
        if (!file) {
            return;
        }
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            const csvData: string = reader.result as string;
            this.papa.parse(csvData, {
                header: true,
                // worker: true,
                complete: (result) => {
                    for (const field of result.meta.fields) {
                        const header = this.matchHeader(field);
                        if (header) {
                            header.field = field;
                        }
                    }
                    this.headers = result.meta.fields;
                    this.csvRecords = [];
                    this.existingEmployees = [];

                    this.employerService.getEmployees(this.employer.id, null, null, null, 0, 10000).subscribe(allResult => {
                        // remove null objects
                        this.log('all employees', result.data);
                        for (let i = result.data.length - 1; i >= 0; i--) {
                            for (const field of allResult.data) {

                                if (result.data[i]["Email Address"] === field.email) {
                                    this.log(result.data[i]["Email Address"], field.email);
                                    this.log('match');
                                    this.existingEmployees.push(result.data[i]);
                                    result.data.splice(i, 1);
                                }
                            }
                        }
                        this.uploaderHasRun = true;
                        this.csvRecords = result.data.slice(0, -1);
                        this.log('this.csvRecords', this.csvRecords);
                        this.log('existing employees', this.existingEmployees);


                        // force validation by marking all controls as touched.
                        setTimeout(() => {
                            for (const n in this.form.controls) {
                                if (n) {
                                    const c = this.form.controls[n];
                                    c.markAsTouched();
                                    this.log(c);
                                }
                            }
                            this.loaded.next();

                        }, 1500);

                    });




                },
                error: (err) => {
                    // logerror('Error', err);
                    this.notificationService.error('Problem parsing CSV file', err);
                }
            });
        };
    }

    /**
     *
     */
    fileReset() {
        this.fileImportInput.nativeElement.value = '';
        this.headers = [];
        this.csvRecords = [];
    }

    onSubmit() {
        this.log('form valid: ' + this.form.valid);
        this.submitEmployees();
    }

    /**
     * Submit employee records for on-boarding.
     */
    submitEmployees() {
        // check headers are all mapped
        for (const h of this.requiredHeaders) {
            if (!this.getHeader(h.name).field && h.name !== 'Row') {
                this.notificationService.error('Header not mapped', 'Required value ' + h.name + ' has not been mapped to a column.');
                return;
            }
        }

        const employees = this.toEmployees();   // convert records to Employee objects
        if (employees) {
            this.uploading = true;
            this.log(employees);

            this.employerService.putEmployees(this.employer.id, employees).subscribe(
                value => {
                    this.notificationService.info('Success', value.length + ' employees onboarded');
                    this.uploading = false;
                },
                err => {
                    this.notificationService.error('Error onboarding  employees', err);
                    this.uploading = false;
                }
            );

        }
    }

    /**
     * Whether the data is ready to submit.
     */
    isDataValid() {
        if (this.form.invalid || this.wasValid === false) {
            const errs: any[] = [];
            for (const n of Object.keys(this.form.controls)) {
                const c = this.form.controls[n];
                if (c.errors) {
                    for (const v of Object.keys(c.errors)) {
                        let e: any;
                        if (v === 'required') {
                            // special message for 'required' validator
                            const cellNamePosition = n.match(/([a-zA-Z]*)([0-9\.]+)/);
                            e = { message: cellNamePosition[1] + ' at row ' + cellNamePosition[2] + ': Please complete all required fields' };
                        }
                        else {
                            e = c.errors[v];
                        }
                        errs.push({
                            name: n,           // control name
                            validator: v,           // control validator
                            err: e,           // returned by validator
                            value: c.value,     // the value of the control
                        });
                    }
                }
            }
            this.errors.next(errs);
        }
        this.wasValid = this.form.valid;    // set old value

        return this.form.valid;
    }

    /**
     * Checks whether the named form control is valid.
     */
    isValid(name: string) {
        const c = this.form.controls[name];
        return c ? c.valid : false;
    }

    /**
     * Get's first error message for named form control.
     */
    getMessage(name: string) {
        let msg = null;
        const c = this.form.controls[name];
        if (c) {
            for (const n in c.errors) {
                if (n) {
                    const e = c.errors[n];
                    msg = e.message;
                    break;
                }
            }
        }
        return msg;
    }

    closeEnough(s1: string, s2: string) {
        return s1 === s2;
    }

    matchHeader(s: string) {
        const result = this.headerSet.get(s);
        if (result && result.length > 0 && result[0].length > 1) {
            return this.getHeader(result[0][1]);
        } else {
            return {};
        }
    }

    getHeader(name: string) {
        let result = null;
        for (const element of this.requiredHeaders) {
            if (element.name === name) {
                result = element;
                break;
            }
        }

        return result;
    }

    textValidator(p, t) {
        // this.log('text validator', p, t);
    }

    /**
     * Convert CSV data to Employee objects
     */
    toEmployees(): Employee[] {

        const result: Employee[] = [];

        if (this.csvRecords) {
            for (const record of this.csvRecords) {
                const employee: Employee = {};
                employee.title = record[this.getHeader(HEADER_TITLE).field];
                employee.firstName = record[this.getHeader(HEADER_FIRSTNAME).field];
                employee.lastName = record[this.getHeader(HEADER_LASTNAME).field];
                employee.sex = record[this.getHeader(HEADER_SEX).field];
                employee.dob = toDate(record[this.getHeader(HEADER_DOB).field]);
                employee.email = record[this.getHeader(HEADER_EMAIL).field];
                employee.phone = record[this.getHeader(HEADER_PHONE).field];
                employee.payrollNumber = record[this.getHeader(HEADER_EMPNUM).field];
                result.push(employee);
            }
        }

        return result;
    }

    trackByIndex(index: number, item: any) {
        return index;
    }

}



@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[birthDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: BirthDateValidatorDirective, multi: true }]
})
export class BirthDateValidatorDirective implements Validator {

    validate(c: FormControl): ValidationErrors {
        const isValid = moment(c.value, 'DD/MM/YYYY').isValid();
        const message = {
            'birthDate': {
                'message': 'The date must be in format DD/MM/YYYY'
            }
        };

        return isValid ? null : message;
    }
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[telephoneNumber]',
    providers: [{ provide: NG_VALIDATORS, useExisting: TelephoneNumberFormatValidatorDirective, multi: true }]
})
export class TelephoneNumberFormatValidatorDirective implements Validator {

    validate(c: FormControl): ValidationErrors {
        const isValid = c.value === '' ||
            /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
                .test(c.value);
        const message = {
            'telephoneNumber': {
                'message': 'The phone number must be a valid Australian phone number'
            }
        };

        return isValid ? null : message;
    }
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[sex]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SexFormatValidatorDirective, multi: true }]
})
export class SexFormatValidatorDirective implements Validator {
    static message = {
        'sex': {
            'message': 'Sex must be one of Male, Female or Other'
        }
    };

    validate(c: FormControl): ValidationErrors {
        const isValid = c.value === 'Male' || c.value === 'Female' || c.value === 'Other';

        return isValid ? null : SexFormatValidatorDirective.message;
    }
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[alphaNumeric]',
    providers: [{ provide: NG_VALIDATORS, useExisting: SpecialCharacterFormatValidatorDirective, multi: true }]
})
export class SpecialCharacterFormatValidatorDirective implements Validator {
    static message = {
        'alphaNumeric': {
            'message': 'Field must be alphanumeric'
        }
    };
    regexStr = '^[a-zA-Z0-9_]*$';


    validate(c: FormControl): ValidationErrors {
        const isValid = new RegExp(this.regexStr).test(c.value);

        return isValid ? null : SpecialCharacterFormatValidatorDirective.message;
    }


}
