import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APP_ENVIRONMENT } from "@rollit/shared";

@Component({
  selector: 'app-six-park',
  templateUrl: './six-park.component.html',
  styleUrls: ['./six-park.component.scss']
})
export class SixParkComponent implements OnInit, OnDestroy {
  destroy = new Subject();
  isDesktop: boolean;


  constructor(
    private uiService: UIManagerService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
  }

  ngOnInit(): void {
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnDestroy(): void {
      this.destroy.next();
      this.destroy.complete();
  }

  openSixPark() {
    window.open(this.environment.sixpark.landingUrl);
  }

}
