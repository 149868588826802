<mat-dialog-content>
    <div class="close-row"><i (click)="closeDialog()" class="material-icons close">close</i></div>
    <div *ngIf="!userAdded && !userRemovedConfirm && !userRemoved">
        <span *ngIf="!isEdit; then thenAddBlock else elseEditBlock"></span>
        <ng-template #thenAddBlock>
            <h1>Add Individual employee</h1>
        </ng-template>
        <ng-template #elseEditBlock>
            <h1>Edit Individual employee</h1>
        </ng-template>
        <form (ngSubmit)="onSubmit()" [formGroup]="newEmployeeForm">
            <div class="form-panel-holder">
                <div class="form-panel-first">
                    <mat-form-field class="form-field">
                        <mat-label>Title</mat-label>
                        <mat-select formControlName="title">
                            <mat-option value="Mr">Mr</mat-option>
                            <mat-option value="Mrs">Mrs</mat-option>
                            <mat-option value="Miss">Miss</mat-option>
                            <mat-option value="Ms">Ms</mat-option>
                            <mat-option value="Prof">Prof</mat-option>
                        </mat-select>
                        <mat-error *ngIf="newEmployeeForm.controls.title.invalid">{{getErrorMessage('title')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-field">
                        <input matInput maxCharacters [maxChars]="50" placeholder="Enter first name" formControlName="firstName" required="true">
                        <mat-error *ngIf="newEmployeeForm.controls.firstName.invalid">{{getErrorMessage('firstName')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-field">
                        <input matInput maxCharacters [maxChars]="50" placeholder="Enter last name" formControlName="lastName" required="true">
                        <mat-error *ngIf="newEmployeeForm.controls.lastName.invalid">{{getErrorMessage('lastName')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-field">
                        <mat-label>Sex</mat-label>
                        <mat-select formControlName="sex" >
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                        <mat-error *ngIf="newEmployeeForm.controls.sex.invalid">{{getErrorMessage('sex')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-field">
                        <input matInput [matDatepicker]="picker" [max]="today" placeholder="Enter DOB" formControlName="dob" required="true">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="newEmployeeForm.controls.dob.invalid">{{getErrorMessage('dob')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="form-field">
                        <input matInput appOnlyNumberFormControl maxCharacters [maxChars]="13" placeholder="Enter mobile number" formControlName="phone">
                        <mat-error *ngIf="newEmployeeForm.controls.phone.invalid">{{getErrorMessage('phone')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>


                </div>
                <div class="form-panel-second">

                    <mat-form-field class="form-field">
                        <input matInput  maxCharacters [maxChars]="65" placeholder="Email address" formControlName="email" [readonly]="isEdit" required="true">
                        <mat-error *ngIf="newEmployeeForm.controls.email.invalid">{{getErrorMessage('email')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="form-field">
                        <input matInput maxCharacters [maxChars]="20"  placeholder="Enter payroll number" formControlName="payrollNumber">
                        <mat-error *ngIf="newEmployeeForm.controls.payrollNumber.invalid">
                            {{getErrorMessage('payrollNumber')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-field">
                        <input matInput maxCharacters [maxChars]="50" placeholder="Enter super fund name" formControlName="superFundName">
                        <mat-error *ngIf="newEmployeeForm.controls.superFundName.invalid">
                            {{getErrorMessage('superFundName')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-field">
                        <input matInput maxCharacters [maxChars]="20" placeholder="Enter super fund USI" formControlName="superFundUsi">
                        <mat-error *ngIf="newEmployeeForm.controls.superFundUsi.invalid">
                            {{getErrorMessage('superfundUsi')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field class="form-field">
                        <input matInput maxCharacters [maxChars]="30" placeholder="Enter super fund member number" formControlName="superFundMemberNumber">
                        <mat-error *ngIf="newEmployeeForm.controls.superFundMemberNumber.invalid">
                            {{getErrorMessage('superFundMemberNumber')}}<i class="material-icons">error</i>
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <button type="submit" [disabled]="newEmployeeForm.invalid" mat-raised-button class="rollit-button " color="primary">
                <span *ngIf="!isEdit; then thenBlock else elseBlock"></span>
                <ng-template #thenBlock><i class="material-icons">add</i>ADD EMPLOYEE
                </ng-template>
                <ng-template #elseBlock><i class="material-icons">edit</i>EDIT EMPLOYEE</ng-template>
            </button>
            <button type="button" (click)="removeEmployee()" mat-stroked-button class="rollit-button " color="warn" *ngIf="isEdit">
                <i class="material-icons">delete_forever</i>REMOVE EMPLOYEE
            </button>


        </form>

    </div>
    <div *ngIf="userAdded">
        <span *ngIf="!isEdit; then thenBlock else elseBlock"></span>
        <ng-template #thenBlock>
            <h1>Individual employee added.</h1>
            <p>Employee "{{newEmployeeForm.controls.firstName.value + ' ' + newEmployeeForm.controls.lastName.value}}"
                successfully added.</p>
            <button (click)="addAnotherUser()" mat-raised-button class="rollit-button " color="primary"><i class="material-icons">add</i>ADD
                ANOTHER
                EMPLOYEE</button>
        </ng-template>
        <ng-template #elseBlock>
            <h1>Individual employee edited.</h1>
            <p>Employee "{{newEmployeeForm.controls.firstName.value + ' ' + newEmployeeForm.controls.lastName.value}}"
                successfully edited.</p>
        </ng-template>
    </div>

    <div *ngIf="userRemovedConfirm">
        <h1 class="error">Confirm employee removal.</h1>
        <p>Are you sure you want to remove employee: "{{newEmployeeForm.controls.firstName.value + ' ' + newEmployeeForm.controls.lastName.value}}"</p>
        <button (click)="removeEmployeeConfirm()" mat-stroked-button class="rollit-button " color="warn">
            <i class="material-icons">delete_forever</i>REMOVE EMPLOYEE
        </button>
        <button (click)="closeDialog()" mat-stroked-button class="rollit-button " color="primary">
            <i class="material-icons">close</i>Cancel
        </button>
    </div>

    <div *ngIf="userRemoved">
        <h1>Individual employee removed.</h1>
        <p>Employee "{{newEmployeeForm.controls.firstName.value + ' ' + newEmployeeForm.controls.lastName.value}}"
            successfully removed.</p>
    </div>
</mat-dialog-content>