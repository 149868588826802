import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { ReplaySubject, Observable } from 'rxjs';
import { File } from '../../model/file';
import { ResultList } from '../../model/result';
import { Fund, InvestmentOption, InvestmentOptionFilter, InvestmentProduct, RateHistogram } from '../../model/fund';

/*
export interface Fund {
  id?: number;
  name?: string;
  description?: string;
  abn?: string;
  logo?: File;
}

export interface InvestmentProduct {
  id?: number;
  name?: string;
  description?: string;
  type?: string;
  category?: string;
  usi?: string;
  website?: string;
  productDisclosure?: string;
  statementOfCompliance?: File;
  signupUrl?: string;
  enquiriesNumber?: string;
  establishedDate?: string;  // should be date
  assessmentStatus?: string;
  fund?: Fund;              // the fund this product belongs to
  logo?: File;
  featured?: boolean;
}

export interface InvestmentOption {
  id?: number;
  name?: string;
  description?: string;
  sector?: string;
  ethical?: boolean;
  indexed?: boolean;
  mySuper?: boolean;
  growthAssetWeighting?: number;
  investmentManagementFees?: number;
  buySpread?: number;
  sellSpread?: number;
  indirectCostRatio?: number;
  product?: InvestmentProduct;                // the product this investment option belongs to
  maxFees?: InvestmentOptionFees;
  feeStructure?: any;               // TODO provide interface for this.
  performance?: OptionSnapshot[];
  rank?: number;
  logo?: File;
  "number"?: number;   // ID from source.
}

export interface InvestmentOptionFees {
  buySpread?: Fee;
  sellSpread?: Fee;
  investmentManagementFees?: Fee;
  memberManagementFees?: Fee;    // $ minimum administration (use maximum)
  ongoingManagementFees?: Fee;    // % member management fee
  indirectCostRatio?: Fee;
  totalCostRatio?: Fee;
  others: Fee[];
}

export interface Fee {
  name?: string;
  feeType?: string;   // Percentage or Dollar
  value?: number;
}

export interface OptionSnapshot {
  timestamp: string;  // date
  values: { [name: string]: Property };
}

export class InvestmentOptionFilter {
  returnYears?: number  = 1;   // the number of years for the return rate.
  returnMin?:   number;        // the minimum return rate
  returnMax?:   number;        // the maximum return rate
  feesMin?:     number;
  feesMax?:     number;
  products?:    number[];      // list of product IDs to filter on.
  sectors?:     string[];
  ethical?:     boolean;       // whether this option is an ethical investment
  indexed?:     boolean;       // whether this option is indexed
  mySuper?:     boolean;
  countOnly?:   boolean;       // whether to only return the total number of options that matxh the criteria
  sortField?:   string  = 'return';
  sortDir?:     string  = 'desc';
}

export enum PropertyType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  Object = 'Object',
  List = 'List',
}

export interface Property {
  name: string;
  value: any;
  type: PropertyType;
}

export interface RateHistogram {
  filter?: InvestmentOptionFilter; // the filter applied to investments
  interval?: number;   // the interval between bins
  rates: number[];     // the bins of rates of return
  counts: number[];    // the count of options that belong in the associated bin
}
*/

@Injectable()
export class AdminFundService {
  apiUrl: string;
  investmentOptions = new ReplaySubject<Array<InvestmentOption>>(1);

  constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient) {
    this.apiUrl = this.environment.apiUrl;
  }

  public createFund(fund: Fund): Observable<Fund> {
    const path = this.apiUrl + '/super/fund';
    return this.http.post<Fund>(path, fund);
  }

  /**
   * Fetch a list of funds.
   */
  public getFunds(offset: number, max: number): Observable<ResultList<Fund>> {
    const path = '/super/fund';
    let params = new HttpParams();
    if (max != null) {
      params = params.set('max', max.toString());
    }

    return this.http.get<ResultList<Fund>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch details of a fund.
   */
  public getFund(fundId: number): Observable<Fund> {
    const path = '/super/fund/' + fundId;

    return this.http.get<Fund>(this.apiUrl + path);
  }

  public addFund(fund: Fund): Observable<Fund> {
    const httpOptions = {
      //        headers: new HttpHeaders({
      //          'Content-Type':  'application/json',
      //          'Authorization': 'my-auth-token'
      //        })
    };

    //    return this.http.post<Fund>(this.apiUrl + '/fund', fund, httpOptions)
    //    .pipe(
    //      catchError(this.handleError('addHero', hero))
    //    );
    return null;
  }

  /**
   * Update a fund
   */
  public updateFund(fundId: number, fund: Fund): Observable<Fund> {
    const path = '/super/fund/' + fundId;

    return this.http.put<InvestmentProduct>(this.apiUrl + path, fund);
  }

  /**
   * Create a new super product.
   * 
   * @param product 
   */
  public createProduct(product: InvestmentProduct): Observable<InvestmentProduct> {
    const path = this.apiUrl + '/super/product';
    return this.http.post<InvestmentProduct>(path, product);
  }

  /**
   * Fetch a list of products.
   */
  public getProducts(fundId: number, offset: number, max: number): Observable<ResultList<InvestmentProduct>> {
    const path = '/super/product';
    let params = new HttpParams();
    if (fundId != null) {
      params = params.set('fund', fundId.toString());
    }
    if (offset != null) {
      params = params.set('offset', offset.toString());
    }
    if (max != null) {
      params = params.set('max', max.toString());
    }

    return this.http.get<ResultList<InvestmentProduct>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch details of a product.
   */
  public getProduct(productId: number): Observable<InvestmentProduct> {
    const path = '/super/product/' + productId;

    return this.http.get<InvestmentProduct>(this.apiUrl + path);
  }

  /**
   * Update a product
   */
  public updateProduct(productId: number, product: InvestmentProduct): Observable<InvestmentProduct> {
    const path = '/super/product/' + productId;

    return this.http.put<InvestmentProduct>(this.apiUrl + path, product);
  }

  public createInvestmentOption(option: InvestmentOption): Observable<InvestmentOption> {
    const path = this.apiUrl + '/super/investment-option';
    return this.http.post<InvestmentOption>(path, option);
  }

  /**
   * Fetch featured investment options.
   */
  public getFeaturedInvestmentOptions(count?: number): Observable<ResultList<InvestmentOption>> {
    const path = '/super/investment-option';
    let params = new HttpParams();
    params = params.set('featured', '' + true);
    params = params.set('max', count == null ? '5' : count.toString());

    return this.http.get<ResultList<InvestmentOption>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch the top investment options.
   */
  public getTopInvestmentOptions(count?: number): Observable<ResultList<InvestmentOption>> {
    const path = '/super/investment-option';
    const params = new HttpParams().set('max', count == null ? '5' : count.toString());

    return this.http.get<ResultList<InvestmentOption>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch a filtered list of investment options.
   */
  public getInvestmentOptions(filter: InvestmentOptionFilter, offset: number, max: number): Observable<ResultList<InvestmentOption>> {
    const path = '/super/investment-option';
    let params = new HttpParams();
    if (filter.returnYears != null) {
      params = params.set('returnYears', '' + filter.returnYears);
    }
    if (filter.returnMin != null) {
      params = params.set('returnMin', '' + filter.returnMin);
    }
    if (filter.returnMax != null) {
      params = params.set('returnMax', '' + filter.returnMax);
    }
    if (filter.feesMin != null) {
      params = params.set('feesMin', '' + filter.feesMin);
    }
    if (filter.feesMax != null) {
      params = params.set('feesMax', '' + filter.feesMax);
    }
    if (filter.sectors != null && filter.sectors.length > 0) {
      for (const sector of filter.sectors) {
        params = params.append('sector', sector);
      }
    }
    if (filter.ethical != null) {
      params = params.set('ethical', '' + filter.ethical);
    }
    if (filter.indexed != null) {
      params = params.set('indexed', '' + filter.indexed);
    }
    if (filter.mySuper != null) {
      params = params.set('mySuper', '' + filter.mySuper);
    }
    if (filter.products != null && filter.products.length > 0) {
      for (const productId of filter.products) {
        params = params.append('product', '' + productId);
      }
    }
    if (filter.countOnly != null) {
      params = params.set('countOnly', '' + filter.countOnly);
    }
    if (filter.sortField != null) {
      params = params.set('sortField', filter.sortField);
    }
    if (filter.sortDir != null) {
      params = params.set('sortDir', filter.sortDir);
    }
    if (offset != null) {
      params = params.set('offset', '' + offset);
    }
    if (max != null) {
      params = params.set('max', '' + max);
    }

    return this.http.get<ResultList<InvestmentOption>>(this.apiUrl + path, { params: params });
  }

  public getInvestmentOption(id: number): Observable<InvestmentOption> {
    const path = '/super/investment-option/' + id;
    return this.http.get<InvestmentOption>(this.apiUrl + path);
  }

  public updateInvestmentOption(id: number, option: InvestmentOption): Observable<InvestmentOption> {
    const path = '/super/investment-option/' + id;

    return this.http.put<InvestmentOption>(this.apiUrl + path, option);
  }

  public getSectors(): Observable<Array<String>> {
    const path = '/super/investment-option/sectors';
    return this.http.get<Array<String>>(this.apiUrl + path);
  }

  /**
   * Histogram of investment returns
   */
  public getReturnHistogram(filter: InvestmentOptionFilter): Observable<RateHistogram> {
    const path = '/super/investment-option/return-histogram';
    let params = new HttpParams();
    if (filter.returnYears != null) {
      params = params.set('returnYears', '' + filter.returnYears);
    }
    if (filter.sectors != null && filter.sectors.length > 0) {
      for (const sector of filter.sectors) {
        params = params.append('sector', sector);
      }
    }
    if (filter.ethical != null) {
      params = params.set('ethical', '' + filter.ethical);
    }
    if (filter.indexed != null) {
      params = params.set('indexed', '' + filter.indexed);
    }

    return this.http.get<RateHistogram>(this.apiUrl + path, { params: params });
  }

  /**
   * Histogram of investment fees
   */
  public getFeeHistogram(filter: InvestmentOptionFilter): Observable<RateHistogram> {
    const path = '/super/investment-option/fee-histogram';
    let params = new HttpParams();
    if (filter.returnYears != null) {
      params = params.set('returnYears', '' + filter.returnYears);
    }
    // TODO also filter by sectors

    return this.http.get<RateHistogram>(this.apiUrl + path, { params: params });
  }

  /**
   * Upload a performance file.
   *
   * @param data
   */
  public uploadPerformanceFile(data: any): Observable<any> {
    const path = '/super/product/performance';
    return this.http.post(this.apiUrl + path, data);
  }
}
