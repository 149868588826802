<div class="holder" [ngClass]="{'desktop':isDesktop}">


  <app-bar-chart-time *ngIf="projectionChartData.length > 0" class="bar-chart" [seriesData]="projectionChartData"
    [seriesCategories]="projectionChartCategories" [title]="'Savings projections'" [smallTitle]="true"
    (graphClicked)="graphClicked($event[0], $event[1])" [showExpand]="false" [smallTitle]="true">
  </app-bar-chart-time>

  <div class="details">
    <div class="titleGroup">
      <app-title-group [details]="titleGroupDetails"></app-title-group>
    </div>

    <div class="detail-list">

      <span class="heading2">Income</span>

      <div *ngIf="income?.income_individual_gross" class="list-item">
        <span class="paragraph2">Your income (gross)</span>
        <span class="paragraph2">{{income?.income_individual_gross  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div *ngIf="income?.income_partner_gross" class="list-item ">
        <span class="paragraph2">Your partner's income (gross)</span>
        <span class="paragraph2">{{income?.income_partner_gross  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Other income (gross)</span>
        <span
          class="paragraph2">{{income?.income_other_gross ?  income?.income_other_gross : 0 | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div *ngIf="income?.income_growth_rate" class="list-item">
        <span class="paragraph2">Est. annual income growth</span>
        <span class="paragraph2">{{income?.income_growth_rate  | percent:'1.2'}}</span>
      </div>

      <div *ngIf="income?.income_peak_earnings_age" class="list-item">
        <span class="paragraph2">Earnings peak age</span>
        <span class="paragraph2">{{income?.income_peak_earnings_age  | number}} years</span>
      </div>

      <div *ngIf="income?.retirement_age" class="list-item">
        <span class="paragraph2">Retirement age</span>
        <span class="paragraph2">{{income?.retirement_age  | number}} years</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Career break</span>
        <span *ngIf="careerBreakDays" class="paragraph2">{{ careerBreakDays  }}</span>
        <span *ngIf="!income?.income_career_break_start" class="paragraph2">None</span>
      </div>

      <!-- finances_scope?: string;
      income_individual_gross?: number;     // annual income before tax
      income_partner_gross?: number;        // partner's annual income before tax
      income_other_gross?: number;
      total?: number;                       // total income
      income_growth_rate?: number;          // percentage annual growth of income
      income_peak_earnings_age?: number;
      income_career_break_start?: string;   // date of start of career break
      income_career_break_end?: string;     // date of break end 0..5
      income_career_break_days?: number;    // number of days per week working during break
      retirement_age?: number; -->

    </div>
  </div>
</div>
