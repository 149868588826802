import { APP_ENVIRONMENT } from '@rollit/shared';
import { AuthService, PlatformService } from '@rollit/shared/data';
import { take } from 'rxjs/operators';

export function initializer(
  environment: any,
  platform: PlatformService,
  auth: AuthService,
): () => Promise<any> {

  return (): Promise<any> => {
    environment.embedded.waitForToken = platform.embedded && platform.hostVersion && platform.hostVersion >= 104;
    environment.embedded.inAppPurchases = platform.embedded && platform.hostVersion && platform.hostVersion >= 104;

    if (environment.embedded.waitForToken) {      // do not initialise keycloak straight away when embedded, wait for access token.
      return new Promise(async (resolve, reject) => {
        console.log("Platform is embedded iOS, so do not init keycloak yet");
        auth.init$.pipe(take(1)).subscribe(v => {
          resolve({});
        });
      }).then(reason => { initIntercom(); });
    } else {
      return auth.init().then(reason => { initIntercom(); });
    }
  };
}

function initIntercom() {
  const intercom_id = (location.hostname === "app.rollitsuper.com") ? "mh93i6lg" : "msfec4lf";

  window['intercomSettings'] = {
    app_id: intercom_id,
    vertical_padding: 70,
  };

  const ic = window['Intercom'];

  if (typeof ic === "function") {
    ic('reattach_activator');
    ic('update', window['intercomSettings']);
  } else {
    const i = function () {
      i['c'](arguments);
    };
    i['q'] = [];
    i['c'] = function (args) {
      i['q'].push(args);
    };

    window['Intercom'] = i;

    function load() {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + intercom_id;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    load();
  }
}
