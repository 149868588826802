
export function trackById(index: number, item: any) {
  return item.id;
}


/**
 * Compare objects
 */
export function compare(obj1: any, obj2: any) {

  // Loop through properties in object 1
  // tslint:disable-next-line: forin
  for (const p in obj1) {
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
      return false;
    }

    switch (typeof (obj1[p])) {
      case 'object':      // Deep compare objects
        if (!compare(obj1[p], obj2[p])) {
          return false;
        }
        break;
      case 'function':      // Compare function code
        if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) {
          return false;
        }
        break;
      default:      // Compare values
        if (obj1[p] !== obj2[p]) {
          return false;
        }
    }
  }

  // Check object 2 for any extra properties
  for (const p in obj2) {
    if (typeof (obj1[p]) === 'undefined') { console.log('compare extra', false); return false; }
  }
  if (obj1 === null) { return obj1 == obj2 }
  return true;
}

/**
 * Create a string of random characters chosen from the given character string.
 * If a character string is not given, return an alphanumeric string of the
 * given length.
 *
 * @param length The length of the string to create.
 * @param characters Characters to use.
 */
export function randomString(length: number, characters?: string): string {
  if (characters) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } else {
    return randomString(length, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
  }
}

