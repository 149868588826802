<div class="dashboard-inner-top-menu">
  <mat-button-toggle-group *ngIf="data.type === 'multi'" class="rollit-toggle" [value]="activeRoute"
    aria-label="Toggle to show 'position' or 'assistant' view">
    <mat-button-toggle #listItem *ngFor="let item of data.primaryItems; let last = last" [value]="item"
      routerLink="/{{item}}">
      <div>
        <span class="item paragraph2"> {{item}}</span>
      </div>

    </mat-button-toggle>

  </mat-button-toggle-group>

  <div *ngIf="data.type === 'multi'  && data.subNav" id="arrow" class="arrow"></div>

  <div class="title" *ngIf="data.type !== 'multi'">{{activatedRoute?.data?._value?.title}}</div>

</div>
