<div class='score'>
  <div class="info">
    <div class="chart">
      <lib-health-score-chart [scoreIndex]="scoreIndex"></lib-health-score-chart>
    </div>
    <div class="graphic">
      <lib-health-score-graphic [profileFinancialGoalResult]="profileFinancialGoalResult"
        [scoreIndexCategoryResult]="scoreIndexCategoryResult">
      </lib-health-score-graphic>
    </div>
  </div>
</div>
