import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService, MeService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-disclaimer-overlay',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop: boolean;
  private isDestroyed = new Subject();

  constructor(
    private logger: LoggerService,
    private uiService: UIManagerService,
    private userService: MeService,
    private router: Router,
  ) {
    this.log = this.logger.info('DisclaimerComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.topNavIsVisible.next(false);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.isDesktop = mediaSizeIsDesktop;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.log('Destroying DisclaimerComponent');

    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();

  }

  onAcceptDisclaimer(remove: boolean) {
    if (remove) {
      this.userService.acceptDisclaimer().pipe(takeUntil(this.isDestroyed)).subscribe(response => {
        this.router.navigate(['/']);
      });
    } else {
      this.router.navigate(['/']);
    }
  }
}
