<app-top-menu></app-top-menu>
<div class="profile holder">
  <div class="main-content-section list" *ngIf="isDesktop || (isDesktop === false && detailIsDisplayed === false )">
    <div class="section-content">
      <div id="list-container">
        <router-outlet name="list"></router-outlet>
      </div>
    </div>
  </div>

  <div class="main-content-section" *ngIf="detailIsDisplayed">
    <div class="section-content">
      <div id="detail-container">
        <i class="material-icons" *ngIf="!isDesktop"
          [routerLink]="['/profile', {'outlets': {'list': ['profile-links'], 'detail': ['none']}}]">arrow_back</i>
        <router-outlet name="detail"></router-outlet>
      </div>
    </div>
  </div>
</div>
