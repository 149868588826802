import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InvestmentOption, InvestmentProduct, UserInvestment } from '../model/fund';
import { PaymentDetails, PaymentMethod } from '../model/payment';
import { Result, ResultList } from '../model/result';
import { Features, Order } from '../model/subscription';
import { Credentials, User, UserProfile, UserProperties } from '../model/user';
import { TOKENS } from '../other/auth.service';
import { LoggerService } from '../other/logger.service';
import { PlatformService } from '../other/platform.service';
import { SubscriptionService } from './subscription.service';

/**
 * return a deep copy of an object.
 */
export function deepCopy(obj: any): any {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" !== typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = deepCopy(obj[attr]);
            }
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * A web service interface for basic things relating to the current user.
 */
@Injectable({ providedIn: 'root' })
export class MeService {
    apiUrl = this.environment.apiUrl;
    private log: any;

    // Subject of details of the current user
    private _investment: UserInvestment;
    private _meSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private _profileSubject: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
    private _propsSubject: BehaviorSubject<UserProperties> = new BehaviorSubject<UserProperties>({});

    private _fetchingProfile: boolean = false;    // whether fetching profile is already in progress

    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private http: HttpClient,
        private keycloakService: KeycloakService,
        private platformService: PlatformService,
        private subscriptionService: SubscriptionService,
        private logger: LoggerService
    ) {
        this.log = this.logger.info('meService');
    }

    public get me$(): Observable<User> {
        return this._meSubject.asObservable();
    }

    public get profile$(): Observable<User> {
        if (this._profileSubject.getValue() == null && !this._fetchingProfile) {
            this.getProfile().subscribe();
        }

        return this._profileSubject.asObservable();
    }

    public get properties$(): Observable<UserProperties> {
        return this._propsSubject.asObservable();
    }

    /**
     * Fetch details for the current user. Send user to subject.
     */
    public getMe(): Observable<User> {
        //  this.log('Getting me');
        const path = '/me';
        return this.http.get<User>(this.apiUrl + path).pipe(
            map(value => {
                const me = this._meSubject.value;

                if (this._investment) {  // attach investments to user
                    value.investment = this._investment;
                }
                this._meSubject.next(value);

                return value;
            })
        );
    }

    public updateMe(user: User): Observable<User> {
        const path = '/me';

        return this.http.put<User>(this.apiUrl + path, user).pipe(
            // publish result to meSubject
            map(value => { this._meSubject.next(value); return value; })
        );
    }

    /**
     * Accept disclaimer for terms of use
     */
    public acceptDisclaimer() {
        const path = '/me';
        return this.http.put(this.apiUrl + path, { extra: { acceptedDisclaimer: true } }).pipe(
            map(value => {
                this.log(value);
            })
        );
    }

    public getProfile(): Observable<UserProfile> {
        const path = '/me/profile';
        this._fetchingProfile = true;

        return this.http.get<UserProfile>(this.apiUrl + path).pipe(
            map(value => {
                this._profileSubject.next(value);
                if (value.properties) {
                    this._propsSubject.next(value.properties);
                }
                this._fetchingProfile = false;
                return value;
            })
        );
    }

    public updateProfile(user: UserProfile): Observable<UserProfile> {
        const path = '/me/profile';

        return this.http.put<UserProfile>(this.apiUrl + path, user).pipe(
            map(value => {
                this._profileSubject.next(value);
                if (value.properties) {
                    this._propsSubject.next(value.properties);  // publish result properties to _propsSubject
                }
                return value;
            })
        );
    }

    /**
     * Fetch user properties.
     */
    public getUserProperties(): Observable<UserProperties> {
        return this.getProfile().pipe(
            map(value => {
                return value.properties;
            })
        );
    }

    /**
     * Update all user properties.
     * @param properties The properties
     */
    public updateUserProperties(properties: UserProperties): Observable<UserProperties> {
        const userProfile = { properties: properties };
        return this.updateProfile(userProfile).pipe(
            map(value => {
                return value.properties;
            })
        );
    }

    /**
     * Update a single user property.
     * @param name Name fo the property
     * @param value User property value
     */
    public updateUserProperty(name: string, value: any): Observable<UserProperties> {
        const userProperties = this._propsSubject.value;

        const props = deepCopy(userProperties ? userProperties : {});
        const userProfile = { properties: props };
        props[name] = value;

        return this.updateProfile(userProfile).pipe(
            map(profile => {
                return profile.properties;
            })
        );
    }

    /**
     * Update user password
     * 
     * @param creds 
     */
    public updateCredentials(creds: Credentials): Observable<Result<void>> {
        const path = '/me/credentials';

        return this.http.put<Result<void>>(this.apiUrl + path, creds);
    }

    public updateSavedProducts(products: InvestmentProduct[]): Observable<InvestmentProduct[]> {
        const path = '/me/products';
        return this.http.put<InvestmentProduct[]>(this.apiUrl + path, products);
    }

    public getSavedProducts(): Observable<InvestmentProduct[]> {
        const path = '/me/products';
        return this.http.get<InvestmentProduct[]>(this.apiUrl + path);
    }

    /**
     * Set the user's selected investment details.
     */
    public updateCurrentInvestment(investment: UserInvestment): Observable<UserInvestment> {
        const path = '/me/investment';
        return this.http.put<UserInvestment>(this.apiUrl + path, investment).pipe(
            // update investment property for user in meSubject
            // and publish result to meSubject
            map(value => {
                this._investment = value;
                const me = this._meSubject.value;
                if (me) {
                    me.investment = value;
                    this._meSubject.next(me);
                }
                return value;
            })
        );
    }

    /**
     * Fetch the user's current investment details.
     */
    public getCurrentInvestment(): Observable<UserInvestment> {
        const path = '/me/investment';
        return this.http.get<UserInvestment>(this.apiUrl + path).pipe(
            // update investment property for user in meSubject
            // and publish result to meSubject
            map(value => {
                this._investment = value;
                const me = this._meSubject.value;
                if (me) {
                    me.investment = value;
                    this._meSubject.next(me);
                }
                return value;
            })
        );
    }

    /**
     * Fetch the orders/invoices associated with the current user.
     */
    public getOrders(): Observable<ResultList<Order>> {
        const path = this.apiUrl + '/me/order';

        return this.http.get<ResultList<Order>>(path);
    }

    public downloadInvoice(id: number): Observable<string> {
        return from(
            this.keycloakService.getToken().then((token) => {
                return this.apiUrl + '/order/' + id + '.pdf?access_token=' + token;
            })
        );
    }

    /**
     * Fetch the user's payment methods.
     */
    public getPaymentMethods(): Observable<PaymentMethod[]> {
        const path = this.apiUrl + '/me/payment/method';
        return this.http.get<PaymentMethod[]>(path);
    }

    /**
     * Create a new payment method from the given details.
     */
    public createPaymentMethod(details: PaymentDetails): Observable<PaymentMethod> {
        const path = this.apiUrl + '/me/payment/method';
        return this.http.post<PaymentMethod>(path, details);
    }


    /**
     * Checks whether user has at least one of the given named features.
     * @param names Feature names
     */
    public hasSomeFeature$(names: string[]): Observable<boolean> {

        return this.me$.pipe(
            map(user => {
                if (!user) {
                    return null;
                }
                return this.subscriptionService.hasSomeFeatures(names, user.features);
            }),
            filter(val => {
                return val !== null;
            })
        );
    }

    /**
     * Checks whether user has all of the given named features.
     *
     * @param names Feature names
     */
    public hasAllFeature$(names: string[]): Observable<boolean> {

        return this.me$.pipe(
            map(user => {
                if (!user) {
                    return null;
                }
                return this.subscriptionService.hasAllFeatures(names, user.features);
            }),
            filter(val => {
                return val !== null;
            })
        );
    }

    public hasPremiumFeature$(): Observable<boolean> {
        return this.hasAllFeature$([Features.TRACK_MT, Features.REWARDS_ALL, Features.SUPER_CHOICE, Features.PROPERTY_PREMIUM]);
    }

    /**
     * Send an email to the user with docs for given investment
     */
    public sendInvestmentDocs(option: InvestmentOption): Observable<any> {
        const path = '/me/investment/docs';
        return this.http.put<any>(this.apiUrl + path, option);
    }

    /**
     *
     */
    public logout(): void {
        localStorage.removeItem(TOKENS);  // remove local tokens, if they exist
        if (this.platformService.embedded) {
            this.platformService.emit("logout", "");
            this._meSubject.next(null);
            // this.keycloakService.logout().then(
            //     value => {
            //         // this.keycloakService.clearToken();
            //         this._meSubject.next(null);
            //     }
            // );
        }
        else {
            this.keycloakService.logout(this.environment.siteUrl).then(
                value => {
                    // this.keycloakService.clearToken();
                    this._meSubject.next(null);
                }
            );
        }
    }
}
