import { Input, Directive, Output, EventEmitter } from "@angular/core";
import { TypeDef } from '@rollit/shared/data';
import { ValidationErrors, FormControl } from '@angular/forms';
import { SectionContext } from "../survey.service";

@Directive()
export abstract class InputComponent {

  @Input() prompt: string;

  @Input() placeholder: string;

  @Input() description: string;

  @Input() multi: boolean;

  @Input() set typeDef(value: TypeDef) {
    this.handleTypeDef(value);
  }

  @Input() set data(value: any) {
    this.handleData(value);
  }
  get data(): any {
    return this.getData();
  }

  @Input() formControl: FormControl;

  @Input() context: SectionContext;

  @Output() onData = new EventEmitter<any>();

  constructor() {
  }

  /**
   * When TypeDef for this input is received, handle it in this function.  This can set formats, validations, possibly style classes.
   *
   * @param typeDef The type definition
   */
  abstract handleTypeDef(typeDef: TypeDef): void;

  /**
   * When data is set, the input component should handle it here.
   *
   * @param data The data to show in this input component.
   */
  abstract handleData(data: any): void;

  /**
   * Return the data for this input compoennt
   */
  abstract getData(): any;

  /**
   * Whether there is a validation error for the given type.
   *
   * @param type The type of validation error to check for.
   */
  abstract hasError(type: any): boolean;

  /**
   * A map of validastion errors for this input.
   */
  abstract errors(): ValidationErrors;

}
