import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { Answer, LoggerService, SelectTypeDef, ValueType } from '@rollit/shared/data';
import { InputComponent } from './input.component';
import { InputDirective } from './input.directive';
import { InputTextComponent } from './input-text/input-text.component';
import { InputBooleanComponent } from './input-boolean/input-boolean.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputMultiComponent } from './input-multi/input-multi.component';
import { InputButtonGroupComponent } from './input-button-group/input-button-group.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputRealtyComponent } from './input-realty/input-realty.component';
import { InputRealtyEstimateComponent } from './input-realty-estimate/input-realty-estimate.component';
import { InputSuperComponent } from './input-super/input-super.component';
import { InputAccountComponent } from './input-account/input-account.component';
import { InputSumComponent } from './input-sum/input-sum.component';
import { SectionContext } from '../survey.service';


let count = 0;

@Component({
  selector: 'lib-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnswerComponent implements OnInit {

  @ViewChild(InputDirective, { static: true }) inputHost: InputDirective;
  @Input() set context(value: SectionContext) {
    this.log('set context', value);
    this._context = value;
    this._context.answerComponents.push(this);
    if (this.inputComponent) {
      this.log('setting inputComponent context', this.context);
      this.inputComponent.context = this._context;
    }
  }
  get context() {
    return this._context;
  }
  @Input() multi: boolean;
  @Input() description: string;
  @Input() prompt: string;
  @Input() set answer(value: Answer) {
    // this.log("setting answer " + this.inst + " def", value);
    this.ans = value;
  }
  get answer(): Answer {
    return this.ans;
  }

  public set value(value: any) {
    // this.log("setting answer " + this.inst + " value", value);
    this.val = value;
    if (this.inputComponent) {
      this.inputComponent.data = value;
    }
  }
  public get value(): any {
    // this.log('getting value');

    return this.inputComponent ? this.inputComponent.data : this.val;
  }

  log: any;

  _context: SectionContext;      // the section this answer is contained in
  private ans: Answer;
  private val: any;
  private inst = count++;

  public inputComponent: InputComponent;

  /**
   * Constructor
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private logger: LoggerService
  ) {
    this.log = this.logger.info("answerComponent");
  }

  ngOnInit() {
    this.log("init description", this.description);
    //this.log("multi", this.multi);

    this.loadInputComponent(this.ans);

  }

  doChange() {
    //this.log('change');
  }

  private loadInputComponent(answer: Answer) {
    const typeDef = answer.typeDef;

    switch (typeDef.type) {
      case ValueType.Boolean:
        this.loadComponent(InputBooleanComponent);
        break;
      case ValueType.Number:
        this.loadComponent(InputNumberComponent);
        break;
      case ValueType.Long:
        this.loadComponent(InputNumberComponent);
        break;
      case ValueType.String:
        this.loadComponent(InputTextComponent);
        break;
      case ValueType.Select:
        const typeDefSelect = answer.typeDef as SelectTypeDef;
        this.log('typeDefSelect', typeDefSelect);
        if (this.multi === true || typeDefSelect.options.length > 5) {
          this.loadComponent(InputSelectComponent);
        } else {
          this.loadComponent(InputButtonGroupComponent);
        }
        break;
      case ValueType.Date:
        this.loadComponent(InputDateComponent);
        break;
      case ValueType.Composite:
        this.loadComponent(InputMultiComponent);
        break;
      case ValueType.Realty:
        this.loadComponent(InputRealtyComponent);
        break;
      case ValueType.RealtyEstimate:
        this.loadComponent(InputRealtyEstimateComponent);
        break;
      case ValueType.Superannuation:
        this.loadComponent(InputSuperComponent);
        break;
      case ValueType.MoneyAccount:
        this.loadComponent(InputAccountComponent);
        break;
      case ValueType.Sum:
        this.loadComponent(InputSumComponent);
        break;
    }
  }
  /**
   * Load the indicator component and set its data.
   */
  private loadComponent(component: Type<InputComponent>) {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.inputHost.viewContainerRef;
    viewContainerRef.clear();


    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.inputComponent = componentRef.instance as InputComponent;
    this.inputComponent.typeDef = this.answer.typeDef;
    this.inputComponent.prompt = this.prompt;
    this.inputComponent.data = this.answer.value;
    this.inputComponent.placeholder = this.answer.placeholder;
    this.inputComponent.multi = this.multi;
    this.inputComponent.description = this.description;
    if (this.context) {
      this.log('setting inputComponent context 1', this.context);
      this.inputComponent.context = this.context;
    }
    this.log('loadComponent', this.prompt);
  }
}
