import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '../input.component';
import { TypeDef, StringTypeDef } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';

/**
 * TODO show textarea in case of long text.
 */
@Component({
  selector: 'lib-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent extends InputComponent implements OnInit {

  log: any;
  formControl: FormControl = new FormControl();
  type = "text";
  length = 100;
  value: string;
  validatorList: Array<any> = [];
  isValid: boolean = true;
  validationMessage: string;
  constructor(
    private logger: LoggerService
  ) {
    super();
    this.log = this.logger.info("textInputComponent");
  }

  ngOnInit() { }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log("Setup text input with def", typeDef);
    const def: StringTypeDef = typeDef as StringTypeDef;
    const validators: ValidatorFn[] = [];

    if (def.length) {
      this.length = def.length;
    }
    if (def.type) {
      this.type = def.type;
      if (def.class === 'email') {
        validators.push(Validators.email);
        this.validatorList.push(['email', 'A valid email is required']);
      }
      if (def.class === 'phone') {
        validators.push(Validators.pattern('^[0-9]{10}$'));
        this.validatorList.push(['pattern', 'A 10 digit phone number is required']);
      }
      // add other validators
    }
    validators.push(Validators.required);
    this.validatorList.push(['required', 'Field is required']);
    this.formControl.setValidators(validators);
  }

  handleData(data: any) {
    this.formControl.setValue(data);
    this.formControl.updateValueAndValidity();
  }

  update() {
    this.validatorList.forEach((val) => {
      const isError = this.hasError(val[0]);
      if (isError) {
        this.isValid = false;
        this.validationMessage = val[1];
      }
    });
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

  getData() {
    return this.formControl.value;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

}
