<ng-container>
    <div *ngFor="let subscriptionItem of subscriptionsList">

        <div>

            <div class="holder">
                <h4>Subscription: <span class="weightNormal">{{ subscriptionItem.product.description }}</span></h4>
                <h5 *ngIf="subscriptionItem.customer?.employer?.name">Employer: <span class="weightNormal">{{ subscriptionItem.customer?.employer?.name }}</span></h5>
                <h5 *ngIf="subscriptionItem.customer?.user?.username">User: <span class="weightNormal">{{ subscriptionItem.customer?.user?.username }}</span></h5>
                <h5>Status: <span class="weightNormal">{{ subscriptionItem.status }}</span></h5>
                <h5 *ngIf="subscriptionItem.statusMessage">Status message: <span class="weightNormal">{{ subscriptionItem.statusMessage }}</span></h5>
                <div class="content-holder">

                    <div class="content-left">
                        <div class="content-item">
                            <span class="title">Subscription:</span>
                            <span class="description">{{subscriptionItem.product?.name}}</span>
                        </div>
                        <div class="content-item">
                            <span class="title">Subscription period:</span>
                            <span class="description">{{subscriptionItem.timeStarted | date:'dd MMM y'}} -
                                {{subscriptionItem.timeDue | date:'dd MMM y'}}</span>
                        </div>
                        <div class="content-item" *ngIf="subscriptionItem.product.name.indexOf('Employer') === -1">
                            <span class="description">
                                <a target="_blank" href="{{agreementUrl}}">{{agreementTitle}}</a>
                            </span>
                        </div>
                        <div class="content-item" *ngIf="subscriptionItem.product.name.indexOf('Employer') === -1">
                            <span class="description">
                                <a target="_blank" href="{{privacyUrl}}">Privacy Policy</a>
                            </span>
                        </div>
                    </div>
                    <div class="content-right" *ngIf="!isAppStoreSubscription && paymentMethod && subscriptionItem.product.name.indexOf('Employer') === -1">
                        <div class="content-item">
                            <span class="title">{{subscriptionItem.product?.properties?.billingPeriod | titlecase }}ly amount:</span>
                            <span class="description">{{currentOrder?.price?.total | currency:'AUD'}} Inc. gst</span>
                        </div>
                        <div class="content-item">
                            <span class="title">Billed to:</span>
                            <span class="description">
                                Card: #### #### #### {{paymentMethod?.last4}}
                                <button mat-raised-button color="primary" class="rollit-button mat-tiny-button mat-icon-only-button" (click)="editPaymentMethod()">
                                    <i class="material-icons">edit</i>
                                </button>
                            </span>
                        </div>
                        <div class="content-item">
                            <span class="title">Card expires:</span>
                            <span class="description">{{ paymentMethod?.expMonth + '/01/' + paymentMethod?.expYear  | date:'MMM y'}}</span>
                        </div>
                        <div class="content-item">
                            <span class="title">Billing date:</span>
                            <span class="description">{{subscriptionItem.timeDue | date:'dd MMM y'}}</span>
                        </div>
                        <div class="content-item">
                            <span class="title">Billing email:</span>
                            <span class="description">{{me?.email}}</span>
                        </div>
                    </div>
                    <div class="content-right" *ngIf="isAppStoreSubscription">
                        <div class="content-item">
                            <span class="title">AppStore subscription</span>
                            <span class="description"></span>
                        </div>
                    </div>
                </div>
                <a *ngIf="hasPremiumFeature != null && !hasPremiumFeature && showUpgrade" mat-raised-button color="primary" class="rollit-button upgrade-button" (click)="onUpgradeClick($event)">Upgrade now</a>

            </div>


        </div>
        <table mat-table class="rollit-table" [dataSource]="orders" matSort [trackBy]="trackById" *ngIf="!isAppStoreSubscription && subscriptionItem === subscription">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice number </th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let row"> {{row.timeCreated | date}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount (incl. gst) </th>
                <td mat-cell *matCellDef="let row"> {{row.price.total | currency:'AUD'}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
            </ng-container>

            <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Download </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-raised-button color="primary" class="rollit-button mat-tiny-button mat-icon-only-button" (click)="downloadInvoice(row.id)">
                        <i class="material-icons">get_app</i>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>


</ng-container>