<span class="title heading2">Automated valuations*</span>
<mat-radio-group [(ngModel)]="preferredEstimate"
                 class="estimate-types"
                 (change)="onPreferredEstimateChange($event)">
  <ng-container *ngFor="let estimate of estimateTypes; let i = index">
    <div class="radio-item">
      <mat-radio-button color="primary"
                        [value]="i">
        <p *ngIf="i !== 3"
           class="paragraph1 section-color__secondary">
          {{ estimate.title }}
        </p>
        <p class="paragraph1 section-color__secondary"
           *ngIf="i !== 3">
          {{ estimate.value | currency: '':'symbol-narrow':'1.0' }}
        </p>

        <div class="edit-input"
             *ngIf="i === 3">
          <mat-form-field class="input-realty"
                          appearance="outline">
            <input matInput
                   value="{{ estimateUser.value }}"
                   mask="separator.2"
                   thousandSeparator=","
                   separatorLimit="99999999999999"
                   (input)="onUserEstimateChange($event)"
                   placeholder="Enter your price" />
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
        </div>
      </mat-radio-button>
    </div>
  </ng-container>
</mat-radio-group>

<div class="paragraph"
     [ngStyle]="{ 'margin-top': '50px' }">
  <strong>Valuation confidence:
    {{ detailedEstimate ? detailedEstimate.confidence : 'Unknown' }}
  </strong>
</div>

<div class="ids-header"
     (click)="toggleIds()">
  <span class="paragraph2">Valuation terms </span>
  <span class="arrow material-icons"
        [ngClass]="{ open: idsOpen }">arrow_right</span>
</div>

<div *ngIf="idsOpen">
  <div class="ids-holder">
    <span class="ids-copy paragraph3 section-color__secondary"><strong>Important information about the estimated
        value.</strong><br />
      The confidence with which the estimated valuation is calculate may be low,
      medium or high, depending on the availability of information relating to
      the property. <br />
      <strong>Estimate date:
        {{ detailedEstimate.estimate_date | date: 'MMMM y' }}</strong>
    </span>
    <div class="ids-logo">
      <span class="powered paragraph3 section-color__secondary">Powered by</span>
      <img src="/assets/images/ids_triangles_small.png"
           width="30"
           alt="Powered by IDS" />
    </div>
  </div>
  <div>
    <div class="terms-copy paragraph3 section-color__secondary"></div>

    <div class="terms-copy paragraph3 section-color__secondary">
      <strong> Property valuation</strong><br>
      The 'estimated value' is a computer generated price calculated and
      supplied monthly by Insight Data Solutions using automated statistical
      models based on available local property data, including the type of
      property, recent sales and local price trends. It is not a price
      prediction and is only used as an indication of the potential value of a
      property. The 'estimated value' is current at the time of publication and
      should not be relied upon as an accurate representation of the market
      value of a property.
    </div>

    <div class="terms-copy paragraph3 section-color__secondary">
      ©2021 Insight Data Solutions Pty Limited ('IDS') and its suppliers and
      licensors. Copyright in elements of this data may be held by Commonwealth,
      State and Local governments. All rights reserved. Further Copyright
      notices and Disclaimers. The information provided is current as at the
      publication date only. IDS does not warrant the accuracy or completeness
      of the information it supplies and to the full extent allowed by law
      excludes any liability for any loss or damage arising from or in
      connection with the use or reliance upon the whole or any part of the
      information through any cause whatsoever.
    </div>

    <div class="terms-copy paragraph3 section-color__secondary">
      Roll-it Super Pty Ltd makes no warranty as to the accuracy or reliability
      of the information contained in the 'estimated value' and Roll-it Super
      Pty Ltd and its related entities, directors, officers and agents disclaim
      all liability and responsibility for any direct or indirect loss or damage
      which may be suffered by the recipient through relying on anything
      contained in or omitted from an 'estimated value'. We encourage homeowners
      and property seekers to supplement this information with an appraisal from
      a local real estate agent.</div>
  </div>
</div>

<div class="description paragraph2">{{ description }}</div>