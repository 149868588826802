import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard.component';
import { DashboardGoalsComponent } from './components/dashboard-goals/dashboard-goals.component';
import { DashboardPositionComponent } from './components/dashboard-position/dashboard-position.component';
import { DashboardProjectionComponent } from './components/dashboard-projection/dashboard-projection.component';
import { DashboardInsightsComponent } from './components/dashboard-insights/dashboard-insights.component';
import { DashboardService } from './dashboard.service';
import { CompleteSetupComponent } from './components/complete-setup/complete-setup.component';
import { StartHealthCheckComponent } from './components/start-healthcheck/start-healthcheck.component';
import { DashboardlandingComponent } from './components/dashboard-landing/dashboard-landing.component';


@NgModule({
  imports: [CommonModule, RollitCommonModule, DashboardRoutingModule],
  declarations: [
    DashboardComponent,
    DashboardGoalsComponent,
    DashboardPositionComponent,
    DashboardProjectionComponent,
    DashboardInsightsComponent,
    DashboardlandingComponent,
    CompleteSetupComponent,
    StartHealthCheckComponent,
  ],
  providers: [
    DashboardService,
  ]
})
export class DashboardModule { }
