import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { Inject, Injectable } from '@angular/core';
import { Order } from '../model/subscription';
import { ResultList } from '../model/result';

/*
export class MailingAddress {
    street1?:    string;
    street2?:    string;
    town?:       string;
    state?:      string;
    postcode?:   string;
    country?:    string;    // 2-character country code.
}

export enum CustomerType {
    employer   = 'Employer',
    individual = 'Individual'
}

export interface Customer {
    type?:          CustomerType;
    user?:          User;        // for individual customer type
    employer?:      Employer;    // for employer customer type
    subscriptions?: any[];       // list of subscriptions for this customer
}

export interface PaymentDetails {
    email?:           string;
    billingAddress?:  MailingAddress;
    shippingAddress?: MailingAddress;
    cardNonce?:       string;
    cardholderName?:  string;
    save?:            boolean;  // whether to save the payment details
    discountCode?:    string;   // a discount code
    amount?:          number;   // used when making a payment
}

export interface PaymentResult {
    id?:           string;           // transaction ID
    status?:       string;           // status
    message?:      string;           // transaction text
    amount?:       number;
}

export interface Price {
    net?:          number;   // amount before discount and tax
    discount?:     number;   // discount before tax
    tax?:          number;   // the amount of tax
    total?:        number;   // total price
}

export interface DiscountCode {
    code?:         string;
    expiry?:       string;        // date
    message?:      string;
}

// Roll-it product on offer.  Subscription plans.
export interface Product {
    id?:          number;
    name?:        string;
    description?: string;
    properties?:  any;
    attributes?:  ProductAttribute[];
}

export interface ProductAttribute {
    id?:          number;
    name?:        string;
    description?: string;
    type?:        string;
    value?:       any;
}

export enum OrderStatus {
    Provisional  = 'Provisional',
    Quoted       = 'Quoted',
    Accepted     = 'Accepted',
    Confirmed    = 'Confirmed',
    Paid         = 'Paid',
    Cancelled    = 'Cancelled',
}

export interface OrderItem {
    id?:           number;
    product?:      Product;
    discountCode?: DiscountCode;
}

export interface Order {
    id?:           number;
    customer?:     Customer;
    items?:        OrderItem[];
    status?:       OrderStatus;
    discountCode?: DiscountCode;
    price?:        Price;
    payments?:     PaymentDetails[];        // to pay for the order.
    timeCreated?:  string;
    timeUpdated?:  string;
}

export interface DiscountCode {
    id?: number;
    code?: string;
    description?: string;
    expiry?: string;        // date
    message?: string;
}

export interface TrialCode {
    id?: number;
    code?: string;
    description?: string;
    expiry?: string;        // date
    message?: string;
}
*/


@Injectable()
export class OrderService {
    apiUrl = this.environment.apiUrl;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private environment: any) {
    }

    public getUnpaidOrders(): Observable<ResultList<Order>> {
        const path = '/report/rollit.InvoicesOutstanding';
        return this.http.get<ResultList<Order>>(this.apiUrl + path);
    }

    public getOrder(id: number): Observable<Order> {
        const path = '/order/' + id;
        return this.http.get<Order>(this.apiUrl + path);
    }

    public updateOrder(id: number, order: Order): Observable<Order> {
        const path = '/order/' + id;
        return this.http.put<Order>(this.apiUrl + path, order);
    }

    public getEmployerOrders(employerId: number): Observable<ResultList<Order>> {
        const path = '/order';
        const params = new HttpParams()
            .set('employer', '' + employerId);

        return this.http.get<ResultList<Order>>(this.apiUrl + path, { params: params });
    }

    public getUserOrders(userId: number): Observable<ResultList<Order>> {
        const path = '/order';
        const params = new HttpParams()
            .set('user', '' + userId);

        return this.http.get<ResultList<Order>>(this.apiUrl + path, { params: params });
    }
}
