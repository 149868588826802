
import { finalize, catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LoggerService, NotificationService } from '@rollit/shared/data';

/**
 * A store of the number of outstanding requests.
 */
@Injectable()
export class RequestCounter {
  private _counter = 0;
  private _subject: Subject<number> = new Subject();

  public set count(count: number) {
    this._counter = count;
    this._subject.next(this._counter);
  }

  public get count(): number {
    return this._counter;
  }

  public subscribe(oOrOnNext?: (value: number) => void, onError?: (error: any) => void, onCompleted?: () => void): Subscription {
    return this._subject.subscribe(oOrOnNext, onError, onCompleted);
  }
}

/**
 * Http interceptor used to increase or decrease request count.
 */
@Injectable()
export class RequestCountInterceptor implements HttpInterceptor {
  private log: any;

  constructor(
    private router: Router,
    private requestCounter: RequestCounter,
    private notificationService: NotificationService,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('RequestCountInterceptor');
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCounter.count++;    // start request; increment the number of outstanding requests
    // this.log(req.url, req);
    let nextReq: any;
    let isIE = false;
    try {
      const agent = window.navigator.userAgent.toLowerCase();
      isIE = agent.indexOf('trident') > -1;
    }
    catch (e) {
    }

    if (req.url.indexOf('/api/') >= 0 && isIE) {
      this.log('intercept ie');
      nextReq = req.clone({
        withCredentials: true,    // ensure cookies are sent.
        headers: req.headers.set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
      });
    } else {
      nextReq = req.clone({
        withCredentials: true     // ensure cookies are sent.
      });
    }
    return next.handle(nextReq).pipe(
      catchError((err: HttpErrorResponse) => {
        this.log(err);
        // send error to notification service.
        if (err.error && err.error.name === 'InvalidSubscriptionException') {
          // redirect to subscription component - this will check if a sign-up is in progress, or whether user needs to update payment details.
          this.log('intercept - Invalid subscription > subscription-check');
          this.router.navigate(['/signup/subscription-check'], { skipLocationChange: true });
          // only allow one redirect
        }
        else if (!req.url.indexOf('estimate')) {
          if (err.error && err.error.message) {
            this.notificationService.error('Error', err.error.message);
          } else if (err.status === 0) {
            this.notificationService.error('Error', 'Service not available');
          }
          else {
            this.notificationService.error('Error ' + err.status, err.statusText + ', please try again');
          }
        }
        return throwError(err);
      }),
      finalize(() => {
        this.requestCounter.count--;        // request complete; decrement the number of outstanding requests
      })
    );
  }
}
