import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'add-home-screen-android',
  templateUrl: './add-homescreen-android.component.html',
  styleUrls: ['./add-homescreen-android.component.scss']
})
export class AddHomeScreenAndroidComponent implements OnDestroy {
  isDesktop: Boolean;
  private isDestroyed = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddHomeScreenAndroidComponent>,
    private uiService: UIManagerService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop => this.isDesktop = isDesktop);
    Intercom('trackEvent', 'web-app-android-gettheapp-open');
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  openStore(event) {
    event.stopPropagation();
    window.open('https://play.google.com/store/apps/details?id=com.rollitsuper.rollitapp', "_blank");
    this.dialogRef.close();
    const currentDate = new Date().toDateString();
    localStorage.setItem("downloadapp_android_update_date", currentDate);
    Intercom('trackEvent', 'web-app-android-gettheapp-open');
  }

  closeDialog(): void {
    this.dialogRef.close();
    const currentDate = new Date().toDateString();
    localStorage.setItem("downloadapp_android_update_date", currentDate);
    Intercom('trackEvent', 'web-app-android-gettheapp-close');
  }
}
