import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BillingCycle, CustomerType, DiscountCode, Order, Price, Product } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-company-plan-information',
  templateUrl: './company-plan-information.component.html',
  styleUrls: ['./company-plan-information.component.scss']
})
export class CompanyPlanInformationComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Input() price: Price;
  @Input() discount: DiscountCode;
  @Input() billingCycle: BillingCycle;
  @Input() annualSubscription;
  @Input() monthlySubscription;
  @Input() numberOfEmployees: number;
  @Input() numberOfEmployeesControl: FormControl;
  @Input() signupError: Boolean;
  mediaSizeIsDesktop: boolean;
  subscriberType = CustomerType.employer;
  @Output() discountCode = new EventEmitter<string>();
  @Output() submitOrder = new EventEmitter<Order>();

  billingCycleEnum = BillingCycle;
  private isDestroyed = new Subject();

  constructor(private uiManagerService: UIManagerService) {
    this.uiManagerService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.mediaSizeIsDesktop = mediaSizeIsDesktop;
    });
  }

  ngOnInit() {
    // console.log('numberOfEmployeesControl', this.numberOfEmployeesControl);
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  onDiscountCode(code) {
    this.discountCode.emit(code);
  }

  onSubmitOrder(order) {
    this.submitOrder.emit(order);
  }
}
