import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { DataServicesModule } from '@rollit/shared/data';

import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { AboutComponent } from './about/about.component';
import { InfoRoutingModule } from './info-routing.module';
import { DisclaimerDialogComponent } from './disclaimer/disclaimer-dialog/disclaimer-dialog.component';
import { EnableCookiesComponent } from './enable-cookies/enable-cookies.component';
// import { AddSuperFundComponent } from './add-super-fund/add-super-fund.component';
import { AddHomeScreenAndroidComponent } from './add-homescreen-android/add-homescreen-android.component';
import { AddHomeScreenIosComponent } from './add-homescreen-ios/add-homescreen-ios.component';
//import { TrackModule } from '@rollit/shared/track';

@NgModule({
  imports: [
    CommonModule,
    InfoRoutingModule,
    DataServicesModule,
    RollitCommonModule,
  ],
  exports: [
    DisclaimerComponent,
    DisclaimerDialogComponent,
    AboutComponent,
    // AddSuperFundComponent,
  ],
  declarations: [
    DisclaimerComponent,
    DisclaimerDialogComponent,
    AboutComponent,
    EnableCookiesComponent,
    // AddSuperFundComponent,
    AddHomeScreenAndroidComponent,
    AddHomeScreenIosComponent,
  ]
})
export class InfoModule { }
