import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FileService, Goal, LoggerService, TimeIntervalTable } from '@rollit/shared/data';
import { SCSS_VARS } from '@rollit/shared';
import { GoalService } from '../goal.service';
import { TitleItemData } from '../../title-group/title-group.component';
import * as moment_ from 'moment';
import { UIManagerService } from '@rollit/shared/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const moment = moment_;


export interface SavingsBudget {
  deadline?: Date;
  monthlySaving?: number;
  sum: number;
}

@Component({
  selector: 'app-savings-goal',
  templateUrl: './savings-goal.component.html',
  styleUrls: ['./savings-goal.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, 'overflow': 'hidden' }),
        animate('100ms ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*', 'overflow': 'hidden' }),
        animate('100ms ease-out', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class SavingsGoalComponent implements OnInit, OnDestroy {
  @Input() goal: Goal;

  @Output() editGoal: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeGoal: EventEmitter<number> = new EventEmitter<number>();
  @Output() detailsOpened: EventEmitter<number> = new EventEmitter<number>();

  private isDestroyed = new Subject();

  sectionColor: string;
  titleGroupData: Array<TitleItemData> = [];
  imagePath: string;
  projectionsFetched: boolean;
  isDesktop: boolean;

  @Input() goalListId: number;
  @Input() goalCount: number;
  @Input() inView: boolean;
  @Output() clickNext = new EventEmitter();
  @Output() clickPrev = new EventEmitter();

  progressSum: number;
  targetSum: number;

  public showDetails = false;
  projection: TimeIntervalTable;
  chartData = [];
  chartCategories = [];
  timescale: string;
  log: any;

  constructor(
    private goalService: GoalService,
    private logger: LoggerService,
    private fileService: FileService,
    private uiService: UIManagerService,

  ) {
    this.log = this.logger.info('SavingsGoalComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;

      });
  }
  ngOnDestroy(): void {

    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  ngOnInit(): void {
    this.log('goal', this.goal);

    this.goal.sectionColor = SCSS_VARS['$rollit-' + this.goal.section];
    this.setupTarget();
    this.setupGoalImage();
    if (this.isDesktop) {
      this.detailsSwitch();
    }
  }

  setupTarget() {
    this.titleGroupData.push({
      title: 'Progress',
      dataColor: SCSS_VARS['$rollit-growth'],
      data: this.goal.data['progress'],
      isCurrency: true,
      hide: false
    });

    this.titleGroupData.push({
      title: 'Goal',
      dataColor: SCSS_VARS['$rollit-primary'],
      data: this.goal.targetAmount,
      isCurrency: true,
      hide: false
    });

    this.titleGroupData.push({
      title: 'Monthly actual',
      dataColor: SCSS_VARS['$rollit-growth'],
      data: this.goal.data['monthlyProgress'],    // TODO monthly progress (???)
      isCurrency: true,
      hide: false
    });

    this.titleGroupData.push({
      title: 'Monthly target',
      dataColor: SCSS_VARS['$rollit-primary'],
      data: this.goal.targetContrib,
      isCurrency: true,
      hide: false
    });

    this.log('estimate', this.goal.data['estimatedDate']);

    if (this.goal.data['estimatedDate'] === undefined) {
      this.titleGroupData.push({
        title: 'Actual date',
        dataColor: SCSS_VARS['$rollit-growth'],
        data: 'TBC',
        isString: true,
        hide: false
      });
    } else {
      this.titleGroupData.push({
        title: 'Actual date',
        dataColor: SCSS_VARS['$rollit-growth'],
        data: new Date(this.goal.data['estimatedDate']),     // TODO calculated progress date (????)
        isDate: true,
        hide: false
      });
    }



    this.titleGroupData.push({
      title: 'Target date',
      dataColor: SCSS_VARS['$rollit-primary'],
      data: new Date(this.goal.targetDate),
      isDate: true,
      hide: false
    });

    if (this.goal.startAmount >= 0) {
      this.progressSum = this.goal.startAmount + this.goal.data['progress'];
      this.targetSum = this.goal.targetAmount;
    } else {
      // start amount is negative, so assume debt
      this.progressSum = this.goal.data['progress'];
      this.targetSum = this.goal.targetAmount - this.goal.startAmount;
    }
  }

  public detailsSwitch() {
    this.showDetails = !this.showDetails;
    if (this.showDetails) {
      this.detailsOpened.next(this.goal.id);
      if (!this.projectionsFetched) {
        this.getChartData();
      }
    }
  }

  public imgUploaded(file) {
    this.fileService.getFilePath(file.id).subscribe((filePath) => {
      this.imagePath = filePath;
    });
  }

  setupGoalImage() {
    this.imagePath = "/assets/images/default/default_goal_" + this.goal.dialogType + ".jpg";
  }

  graphClicked(category, name): void {
    this.log(category, name);
  }

  onEditClick() {
    this.editGoal.next(this.goal.id);
  }

  onDeleteClick() {
    this.removeGoal.next(this.goal.id);
  }

  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  getChartData() {
    this.projectionsFetched = true;
    this.goalService.getProjection(this.goal).subscribe(result => {
      this.log('Got goal projection', result);
      this.projection = result;

      // TODO use goal projection data

      this.log('Got projections', this.projection);

      const proj = this.projection;
      this.log('Got projections', proj);

      this.timescale = 'Age';

      const timedays = this.datediff(new Date(proj.data[0]['from']), new Date(proj.data[0]['to']));
      this.log('timedays', timedays);

      if (timedays < 32 || timedays < 184) {
        this.log('timescale months');
        this.timescale = 'Date';
      }

      this.projection = proj;
      const projections = proj;

      const colorArray = [SCSS_VARS['$rollit-property'], SCSS_VARS['$rollit-growth']];
      const chartCategoriesMap = [
        { short: 'PROP', section: 'property', title: 'Property' },
      ];
      const temp_array = [];

      projections.names.forEach((nameVal, nameIndex) => {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            this.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
          }
        });

        if (nameVal === 'Progress') {
          temp_object['type'] = 'spline';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        this.log(nameVal, nameIndex, colorArray[nameIndex]);

        projections.data.forEach((dataVal, dataIndex) => {
          if (this.timescale === 'Date') {
            const date = moment(projections.data[dataIndex].from.replace('+11:00', ''));
            const month = date.format('MMM');
            const year = date.format('YY');
            //this.log('dates', projections.data[dataIndex].from);
            //this.log('dates', this.acc.nickname, date);
            this.chartCategories.push(month + ' ' + year);
          } else {
            this.chartCategories.push(projections.data[dataIndex].label);
          }

          temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
        });

        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      this.log(temp_array);

      this.chartData = temp_array;
      this.log('this.chartData', this.chartData);

    });
  }

  scrollPrev() {
    this.clickPrev.next();
    this.log('clickPrev');
  }

  scrollNext() {
    this.clickNext.next();
    this.log('scrollNext');
  }

}




/* ------------------- put this outside of the component class to remove clutter ------------------------ */


