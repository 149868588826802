/**
 * Interfaces for the IDS property service.
 */

import { SafeStyle } from '@angular/platform-browser';
import { File } from './file';
import { MoneyAccount } from './money';

export interface LatLon {
    lat: number;
    lon: number;
}

export interface Location {
    point: LatLon;
}

export interface Address {
    address?: string;

    location?: Location;        // location of the address.

    unit_number?: string;
    street_number?: string;
    street_number_high?: string;

    street?: string;
    street_type?: string;
    street_suffix?: string;

    locality?: string;
    state?: string;
    postcode?: string;

    lga?: string;
    lga_code?: string;

    meshblock?: number;
    sa1?: number;
    sa2?: number;
    sa3?: number;
    sa4?: number;
}

export enum RealtyType {
    House = "House",
    Unit = "Unit",
    Unmapped = "Unmapped",
}

export enum OccupancyType {
    OwnerOccupied = "owner occupied",
    Investment = "investment",
    Renting = "renting",
    Tracking = "tracking",
}

export enum EstimateSource {
    Automatic = "Automatic",
    Lower = "Lower",
    Upper = "Upper",
    Manual = "Manual",
}

/**
 * A property
 */
export interface Realty {
    property_id?: string;
    property_type?: RealtyType;
    address?: string;
    state?: string;
    address_details?: Address;

    bathrooms?: number;
    bedrooms?: number;
    carparks?: number;
    landarea?: number;       // in square-metres

    occupancy_type?: OccupancyType;
    months_occupancy?: number;

    // extra
    gnaf_address_details_pid?: string;
    message?: string;

    // fields for Roll-it service
    estimate_source?: EstimateSource;     // what value to use for the estimated value
    estimate?: number;                    // estimated value in dollars, for manual estimate
    images?: File[];                      // user-defined image files

    // @deprecated use account.visible and account.properties['loan']
    visible?: boolean;
    loans?: MoneyAccount[];               // list of loan accounts for this property
}

export interface Estimate {
    property_id?: string;
    address?: string;
    state?: string;
    estimate_date?: string;      // YYYY-MM-DD
    estimate?: number;      // value in $
    confidence?: string;
    percent_fsd?: number;
    comparable_sales?: any;
}

export interface DetailedEstimate extends Estimate {
    location?: LatLon;  // lat, lon
    estimate_high?: number;
    estimate_low?: number;
    property_type?: string;
    landarea?: number;  // in square metres
    bedrooms?: number;
    bathrooms?: number;
    carparks?: number;

    last_sale_price?: number;
    last_sale_date?: string;

    is_on_market?: boolean;
    list_price?: number;
    list_date?: string;

    weekly_rent?: number;
    rent_date?: string;
    weekly_rent_estimate?: number;
    percent_rent_yield_estimate?: number;
    coverage_probability_percent?: number;

    last_12_mnths_locality_data_similar_properties?: any;  // SalesSummary
    comparable_sales?: any[];        // Sale[]
    comparable_sale_listings?: any[];        // Listing[]
    comparable_rental_listings?: any[];        // RentalListing[]

    growth_rate?: number;

    // fields for Roll-it service
    estimate_user?: number;               // user estimated value in dollars, for manual estimate
    estimate_source?: EstimateSource;     // what value to use for the estimated value
}

/**
 * A picture of a property
 */
export interface RealtyImage {
    url: string;        // URL to retrieve the image
    date: string;        // date that the image was created
    rank: number;        // the order from low to high in which the images can be displayed, use this to make sure you don’t show a picture of the bathroom as the primary image
    sanUrl: SafeStyle;
}
