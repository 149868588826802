import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type TableInput = Array<{
  columnHeader: string;
  elements: Array<string | number>;
}>;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  displayedColumns: string[] = [];
  dataSource = [];
  @Output() removeElement = new EventEmitter<number>();

  /* example input:  [
    { columnHeader: 'Name', elements: ['Six Park', 'NAB', 'BHP'] },
    { columnHeader: 'Units', elements: [1, 100, 40] },
    { columnHeader: 'Price', elements: ['$4000', '$30.00', '$50.00'] },
    { columnHeader: '1YR Return', elements: ['8.00%', '6.00%', '7.00%'] },
    { columnHeader: 'Value', elements: ['$4000', '$3000', '$2000'] },
  ]; */
  @Input() set data(data: TableInput) {
    this.transformToTableData(data);
  }
  constructor() { }

  ngOnInit(): void { }

  private transformToTableData(data: TableInput) {
    this.dataSource = [];
    for (let i = 0; i < data.length; i++) {
      this.displayedColumns.push(data[i].columnHeader);
    }
    for (let i = 0; i < data[0].elements.length; i++) {
      this.dataSource.push({});
      for (let j = 0; j < data.length; j++) {
        this.dataSource[this.dataSource.length - 1][data[j].columnHeader] =
          data[j].elements[i];
      }
    }
  }

  remove(event) {
    console.log('remove', event.target.id);
    this.removeElement.emit(event.target.id);
  }
}
