import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { ResultList } from '../../model/result';
import { Employee, EmployeeCounts, Employer } from '../../model/employer';
import { User } from '../../model/user';

@Injectable()
export class AdminEmployerService {
    apiUrl = this.environment.apiUrl;
    constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient) {
    }

    /**
     * Get a list of employers
     */
    getEmployers(offset: number = 0, max: number = 100): Observable<ResultList<Employer>> {
        const path = this.apiUrl + '/employer';
        const params = new HttpParams()
            .set('offset', '' + offset)
            .set('max', max == null ? '100' : max.toString());

        return this.http.get<ResultList<Employer>>(path, { params: params });
    }

    /**
     * Get a list of employers
     */
    getEmployer(id: number): Observable<Employer> {
        const path = this.apiUrl + '/employer/' + id;

        return this.http.get<Employer>(path);
    }

    /**
     * Upload employee details, but do not on-board them.
     * @param employerId 
     * @param employees 
     */
    putEmployeeList(employerId: number, employees: Employee[]): Observable<Employee[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee-list';

        return this.http.post<Employee[]>(path, employees);
    }

    /**
     * Get employer details uploaded (may or may not have been on-boarded)
     * @param employerId 
     * @param offset 
     * @param max 
     */
    getEmployeeList(employerId: number, onboarded: boolean = null, offset: number = 0, max: number = 100): Observable<ResultList<Employee>> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee-list';

        let params = new HttpParams()
            .set('offset', '' + offset)
            .set('max', max == null ? '100' : max.toString());
        if (onboarded != null) {
            params = params.set('onboarded', String(onboarded));
        }

        return this.http.get<ResultList<Employee>>(path, { params: params });
    }

    /**
     * Onboard the employees.
     */
    putEmployees(employerId: number, employees: Employee[]): Observable<Employee[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee';

        return this.http.post<Employee[]>(path, employees);
    }

    /**
     * Get employee users.
     * @param employerId 
     * @param offset 
     * @param max 
     */
    getEmployees(employerId: number, offset: number = 0, max: number = 100): Observable<ResultList<Employee>> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee';
        const params = new HttpParams()
            .set('offset', '' + offset)
            .set('max', max == null ? '100' : max.toString());

        return this.http.get<ResultList<Employee>>(path, { params: params });
    }

    getEmployeeCounts(employerId: number): Observable<EmployeeCounts> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee-count';

        return this.http.get<EmployeeCounts>(path);
    }

    /**
     * Get admin users of the employer
     * @param employerId The ID of the employer
     */
    getAdmins(employerId: number): Observable<User[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/admin';

        return this.http.get<User[]>(path);
    }

    /**
     * Add user as admin of the employer
     * @param employerId The ID of the meployer
     * @param userId The ID of the user to add as admin
     */
    addAdmin(employerId: number, userId: number): Observable<User[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/admin';

        return this.http.post<User[]>(path, { id: userId });
    }

    /**
     * Remove user as admin of the employer
     * @param employerId The ID of the employer
     * @param userId The ID of the user
     */
    removeAdmin(employerId: number, userId: number): Observable<void> {
        const path = this.apiUrl + '/employer/' + employerId + '/admin/' + userId;

        return this.http.delete<void>(path);
    }
}
