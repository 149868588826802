<ng-container>

  <div class="group-panel">

    <div class="navigation" *ngIf="isDesktop && propertyCount > 0">
      <div class="item prev paragraph2" [ngClass]="{'disabled': propertyListId === 0 }"
        (click)="propertyListId !== 0 ? scrollPrev() : false">
        <i class="material-icons">navigate_before</i> Previous
      </div>

      <div class="item next paragraph2" [ngClass]="{'disabled': propertyListId  === propertyCount }"
        (click)="propertyListId !== propertyCount ? scrollNext() : false">
        Next <i class="material-icons">navigate_next</i>
      </div>
    </div>
    <app-section-hero [title]="acc.nickname" [description]="''" imageCaption="" [showDelete]="true"
      [imagePath]="imagePath" [expanded]="isDesktop" (expandClicked)="expandClicked()"
      (uploadComplete)="uploadComplete($event)">


      <div class="titleGroup">
        <app-title-group [details]="titleGroupDetails" [displayAsColumn]="!loanExists &&  isDesktop"></app-title-group>
      </div>

      <div class="heroContent">

        <app-lifestyle-assets-projections id="projections-{{propertyListId}}"
          *ngIf="projectionChartCategories.length > 0 && projectionChartData.length > 0 && loanAccountReady"
          [inView]="(inView && isDesktop) || expanded" [acc]="acc" [loanAccount]="loanAccount"
          [projectionChartData]="projectionChartData" [projectionChartCategories]="projectionChartCategories">
        </app-lifestyle-assets-projections>

        <div class="action-holder">
          <button mat-stroked-button (click)="onEditAccount(acc.id)">
            <span class="paragraph2"> Edit </span>
          </button>

          <i class="delete material-icons" (click)="onRemoveAccount()">
            delete
          </i>
        </div>
      </div>
    </app-section-hero>
  </div>
</ng-container>
