<mat-form-field class="input-text" appearance="outline">
  <mat-label *ngIf="label" class="paragraph1">{{label}}</mat-label>
  <!-- <mat-error *ngIf="!investmentValid && !isSearch">
    Please enter a valid address
  </mat-error>
  <mat-error *ngIf="!investmentValid && isSearch && showSearchOutput">
    Please select a valid address
  </mat-error> -->

  <input matInput id="formInput" [formControl]="formControl" [type]="type"
    [placeholder]="isSearch ? 'Search by fund name' : 'Enter fund name'" autocomplete="off" autocorrect="off"
    (keyup)="search($event.target.value)" (focus)="removeSuper()" />

</mat-form-field>

<span *ngIf="!isSearch" class="set-search paragraph2" (click)="setSearchInvestment()">Search for your fund
  instead</span>

<div class="output-holder" *ngIf="isSearch === true && showSearchOutput === true">
  <div class="output">
    <ng-container *ngFor="let investment of searchOptions; trackBy: trackById">
      <div class="item-property" (click)="onYourInvestmentClick(investment)"
        [ngClass]="{'unknown':investment.description === 'unknown'}">

        <span *ngIf="display === 'option'" class="item-property-text paragraph2 "
          title="{{investment.name | titlecase}}">{{investment.name}}</span>

        <span *ngIf="display === 'product'" class="item-property-text paragraph2 "
          title="{{investment.product.name | titlecase}}">{{investment.name}}</span>

        <span *ngIf="display === 'fund'" class="item-property-text paragraph2 "
          title="{{investment.product.fund.name | titlecase}}">{{investment.name}}</span>

      </div>
    </ng-container>

    <div *ngIf="searchOptions.length === 0 && !investmentValid && formControl.value.length >= 3" class="item-property">

      <span class="item-property-text paragraph2">No results found.</span>

    </div>

  </div>
  <div *ngIf="searchOptions.length === 0 && !investmentValid && formControl.value.length >= 3"
    class="item-property unknown" (click)="setManualInvestment()">Enter fund name manually</div>
</div>
