<div class="sub-menu-holder">
  <div class="item-holder" [ngClass]="{'desktop': isDesktop}">
    <ng-container *ngFor="let item of childData">
      <div *ngIf="item.data.show !== false" class="item" [ngClass]="{'active': item.path === path}"
        routerLink="/{{parent}}/{{item.path}}">

        <span class="title paragraph2">{{item.path}} </span>
      </div>
    </ng-container>

  </div>
</div>
