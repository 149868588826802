import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { Observable, from } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { File } from '../model/file';

@Injectable()
export class FileService {
  apiUrl = this.environment.apiUrl;
  constructor(private http: HttpClient, private keycloak: KeycloakService, @Inject(APP_ENVIRONMENT) private environment: any) {
  }

  /**
   * upload file
   */
  public uploadFile(data: File): Observable<File> {
    const path = '/file';
    return this.http.post<File>(this.apiUrl + path, data);
  }

  /**
   * Fetch file information for identified file.
   */
  public getFile(id: number): Observable<File> {
    const path = '/file/' + id;
    let params = new HttpParams();
    params = params.set('meta', '' + true);

    return this.http.get<File>(this.apiUrl + path, { params: params });
  }

  public getFilePath(id: number): Observable<string> {
    return from(
      this.keycloak.getToken().then((token) => {
        return this.apiUrl + '/file/' + id + '?access_token=' + token;
      })
    );
  }
}
