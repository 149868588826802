<div class="main" [ngClass]="{
  'primary': appBackgroundIsPrimary,
  'isAddressBar': (isIos || isAndroid) 
  }">


  <div class="loader-wrapper" [ngClass]="{'hide': (requestCount <= 0 && !loaderIsVisible)}">
    <div class="loader"><span class="icon-loader"></span></div>
  </div>

  <drawer-navigation *ngIf="siteReady"></drawer-navigation>

  <!-- <main-navigation *ngIf="false" [ngClass]="{'secondary': mainNavigationIsSecondary}"></main-navigation> -->

  <div *ngIf="siteReady" class="main-content">

    <side-navigation class="side-nav"></side-navigation>


    <router-outlet></router-outlet>

  </div>

</div>
