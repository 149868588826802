import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Goal } from '@rollit/shared/data';

@Component({
  selector: 'app-section-summary-card',
  templateUrl: './section-summary-card.component.html',
  styleUrls: ['./section-summary-card.component.scss'],
})
export class SectionSummaryCardComponent implements OnInit {
  // TODO shouldn't this be an input?
  date = new Date();
  @Input() cashIn;
  @Input() cashOut;
  @Input() savings;
  @Input() totalSavings;
  @Input() section: string;
  @Input() title: string;
  @Input() padding: string;
  @Input() sectionTitles: Array<any>;
  @Input() goal: Goal;                // a section goal to display in the summary

  @Output() morePressed = new EventEmitter<void>();
  @Output() newGoal = new EventEmitter<string>();     // "section" string passed

  constructor() { }

  ngOnInit(): void {
  }
}
