import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileService, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-section-hero',
  templateUrl: './section-hero.component.html',
  styleUrls: ['./section-hero.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, 'overflow': 'hidden' }),
        animate('100ms ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*', 'overflow': 'hidden' }),
        animate('100ms ease-out', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class SectionHeroComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() imageCaption?: string;
  @Input() expanded?: boolean;
  @Input() imagePath?: string = '/assets/images/desktop-splash-5.jpg';
  @Input() showDelete?= false;
  @Output() expandClicked = new EventEmitter();
  @Output() uploadComplete = new EventEmitter<any>();
  log: any;
  destroy = new Subject();
  isDesktop: boolean;

  constructor(private fileService: FileService, private logger: LoggerService, private uiService: UIManagerService,) {
    this.log = this.logger.info('SectionHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  imgUploaded(file) {
    this.log(file);
    this.uploadComplete.emit({ id: file.id, path: file.path });
    this.fileService.getFilePath(file.id).subscribe((filePath) => {
      this.imagePath = filePath;
    });
  }

  expand() {
    this.expanded = !this.expanded;
    this.expandClicked.emit();
  }
}
