import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-cashflow-expenses-chart',
  templateUrl: './cashflow-expenses-chart.component.html',
  styleUrls: ['./cashflow-expenses-chart.component.scss']
})
export class CashflowExpensesChartComponent implements OnInit {
  @Input() cashflowExpensesPercent: string;
  @Input() cashflowExpensesResult: string;
  @Input() cashflowExpensesPercentFinal: number;
  @Input() graphicType: number = 1;

  constructor() { }

  ngOnInit() {
    this.cashflowExpensesPercentFinal = Math.round(+this.cashflowExpensesPercent * 100);
  }

}
