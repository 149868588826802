<div class="wrapper" fxFlex.gt-sm="100">
    <div>
        <h1>Your employer information</h1>
    </div>

    <form [formGroup]="employerNameGroup" *ngIf="employerNameGroup" (submit)="onSubmit(employerNameGroup, 'name')">
        <app-form-field-item [(editMode)]="editMode.name" [isLocked]="inputsAreLocked" (completeClicked)="hiddenNameButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(nameInput)" [isValid]="employerNameGroup.controls['employerName'].valid">
            <div class="detail">
                <span class="label">Employer name</span>
                <span class="value">{{employerNameGroup.controls['employerName'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #nameInput matInput placeholder="Employer name" formControlName="employerName" required>
                <mat-error *ngIf="employerNameGroup.controls['employerName'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenNameButton>Hidden Button</button>
    </form>

    <form [formGroup]="contactFirstNameGroup" *ngIf="contactFirstNameGroup" (submit)="onSubmit(contactFirstNameGroup, 'firstName')">
        <app-form-field-item [(editMode)]="editMode.firstName" [isLocked]="inputsAreLocked" (completeClicked)="hiddenFirstNameButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(firstNameInput)" [isValid]="contactFirstNameGroup.controls['contactFirstName'].valid">
            <div class="detail">
                <span class="label">Contact first name</span>
                <span class="value">{{contactFirstNameGroup.controls['contactFirstName'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #firstNameInput matInput placeholder="Contact first name" formControlName="contactFirstName" required>
                <mat-error *ngIf="contactFirstNameGroup.controls['contactFirstName'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenFirstNameButton>Hidden Button</button>
    </form>

    <form [formGroup]="contactLastNameGroup" *ngIf="contactLastNameGroup" (submit)="onSubmit(contactLastNameGroup, 'lastName')">
        <app-form-field-item [(editMode)]="editMode.lastName" [isLocked]="inputsAreLocked" (completeClicked)="hiddenLastNameButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(lastNameInput)" [isValid]="contactLastNameGroup.controls['contactLastName'].valid">
            <div class="detail">
                <span class="label">Contact last name</span>
                <span class="value">{{contactLastNameGroup.controls['contactLastName'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #lastNameInput matInput placeholder="Contact last name" formControlName="contactLastName" required>
                <mat-error *ngIf="contactLastNameGroup.controls['contactLastName'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenLastNameButton>Hidden Button</button>
    </form>

    <form [formGroup]="contactEmailGroup" *ngIf="contactEmailGroup" (submit)="onSubmit(contactEmailGroup, 'email')">
        <app-form-field-item [(editMode)]="editMode.email" [isLocked]="inputsAreLocked" (completeClicked)="hiddenEmailButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(emailInput)" [isValid]="contactEmailGroup.controls['contactEmail'].valid">
            <div class="detail">
                <span class="label">Contact email</span>
                <span class="value">{{contactEmailGroup.controls['contactEmail'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #emailInput matInput placeholder="Contact email" formControlName="contactEmail" required>
                <mat-error *ngIf="contactEmailGroup.controls['contactEmail'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenEmailButton>Hidden Button</button>
    </form>

    <form [formGroup]="contactMobileGroup" *ngIf="contactMobileGroup" (submit)="onSubmit(contactMobileGroup, 'mobile')">
        <app-form-field-item [(editMode)]="editMode.mobile" [isLocked]="inputsAreLocked" (completeClicked)="hiddenMobileButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(mobileInput)" [isValid]="contactMobileGroup.controls['contactMobile'].valid">
            <div class="detail">
                <span class="label">Contact mobile</span>
                <span class="value">{{contactMobileGroup.controls['contactMobile'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #mobileInput matInput placeholder="Contact mobile" formControlName="contactMobile" required>
                <mat-error *ngIf="contactMobileGroup.controls['contactMobile'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenMobileButton>Hidden Button</button>
    </form>

    <form [formGroup]="abnGroup" *ngIf="abnGroup" (submit)="onSubmit(abnGroup, 'abn')">
        <app-form-field-item [(editMode)]="editMode.abn" [isLocked]="inputsAreLocked" (completeClicked)="hiddenABNButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(abnInput)" [isValid]="abnGroup.controls['companyABN'].valid">
            <div class="detail">
                <span class="label">Company ABN</span>
                <span class="value">{{abnGroup.controls['companyABN'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #abnInput matInput placeholder="Company ABN" formControlName="companyABN" required>
                <mat-error *ngIf="abnGroup.controls['companyABN'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenABNButton>Hidden Button</button>
    </form>

    <form [formGroup]="employeeNumberGroup" *ngIf="employeeNumberGroup" (submit)="onSubmit(employeeNumberGroup, 'employeeNumber')">
        <app-form-field-item [(editMode)]="editMode.employeeNumber" [isLocked]="inputsAreLocked" (completeClicked)="hiddenEmployeeNumberButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(employeeNumberInput)" [isValid]="employeeNumberGroup.controls['employeeNumber'].valid">
            <div class="detail">
                <span class="label">Employee number</span>
                <span class="value">{{employeeNumberGroup.controls['employeeNumber'].value}}</span>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input #employeeNumberInput matInput placeholder="Employee number" formControlName="employeeNumber" required>
                <mat-error *ngIf="employeeNumberGroup.controls['employeeNumber'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenEmployeeNumberButton>Hidden Button</button>
    </form>

    <form [formGroup]="addressGroup" *ngIf="addressGroup" (submit)="onSubmit(addressGroup, 'address')" autocomplete="off">
        <app-form-field-item [(editMode)]="editMode.address" [isLocked]="inputsAreLocked" (completeClicked)="hiddenAddressButton.click()" (cancelClicked)="setupForm()" (editClicked)="focusInput(address1Input)" [isValid]="addressGroup.controls['a1'].valid && addressGroup.controls['pc'].valid && addressGroup.controls['a2'].valid && addressGroup.controls['a3'].valid">
            <div class="detail">
                <span class="label">Business address</span>
                <div class="value">{{ user.employment[0].employer.address.street1 }}</div>
                <div class="value">{{ user.employment[0].employer.address.town }}</div>
                <div class="value">{{ user.employment[0].employer.address.postcode }}</div>
                <div class="value">{{ user.employment[0].employer.address.state }}</div>
            </div>
            <mat-form-field class="mat-form-field-full-width edit">
                <input type="text" #address1Input matInput placeholder="Street name and number" formControlName="a1" autocomplete="nope" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
                    <mat-option *ngFor="let option of autoCompleteOptions" [value]="option.description" (onSelectionChange)="getAddressDetails(option.place_id)">
                        {{ option.description }}
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner matPrefix [diameter]="14" [strokeWidth]="1" *ngIf="autocompleteLoadingValues"></mat-spinner>
                <mat-error *ngIf="addressGroup.controls['a1'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-full-width edit">
                <input matInput placeholder="Postcode" formControlName="pc" required autocomplete="nope">
                <mat-error *ngIf="addressGroup.controls['pc'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-full-width edit">
                <input matInput placeholder="Suburb or town" formControlName="a2" required autocomplete="nope">
                <mat-error *ngIf="addressGroup.controls['a2'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mat-form-field-full-width edit">
                <input matInput placeholder="State or territory" formControlName="a3" required autocomplete="nope">
                <mat-error *ngIf="addressGroup.controls['a3'].hasError('required')">
                    This field is required
                    <i class="material-icons">error</i>
                </mat-error>
            </mat-form-field>
        </app-form-field-item>
        <button [hidden]="true" #hiddenAddressButton>Hidden Button</button>
    </form>

    <!-- <app-investment-change [investmentId]="1"></app-investment-change> -->

</div>