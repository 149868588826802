import { Component, Input, OnInit } from '@angular/core';
import { SCSS_VARS } from '@rollit/shared';
import { TitleItemData } from '@rollit/shared/common';
import { LoggerService, MoneyAccount } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-credit-cards-loans-projections',
  templateUrl: './credit-cards-loans-projections.component.html',
  styleUrls: ['./credit-cards-loans-projections.component.scss']
})
export class CreditCardsLoansProjectionsComponent implements OnInit {

  @Input() acc: MoneyAccount;
  @Input() projectionChartData: any = [];
  @Input() projectionChartCategories: any = [];
  @Input() projectedDebtFreeDate: any;
  @Input() inView: boolean = false;
  @Input() timescale: string;

  isDesktop: boolean;

  destroy = new Subject();
  log: any;
  titleGroupDetails: Array<TitleItemData> = [

    {
      title: 'Outstanding balance',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      additionalInfo: '',
      isCurrency: true,
      hide: false
    },

    {
      title: '',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isNumber: true,
      hide: false
    }];

  constructor(private logger: LoggerService, private uiService: UIManagerService,) {
    this.log = this.logger.info('CreditCardsLoansProjectionsComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('projectionChartCategories', this.projectionChartCategories);
    this.log('projectionChartData', this.projectionChartData);
    this.log('acc', this.acc);
    this.setupTitleGroupDetails();
  }

  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.acc.currentBalance;
    this.titleGroupDetails[1]['title'] = this.timescale === 'Age' ? 'Age' : 'Projection Date';
    this.titleGroupDetails[1]['data'] = this.projectionChartCategories[0];

  }


  graphClicked(category, data, index): void {
    this.log(category, data, index);
    this.titleGroupDetails[0]['data'] = data[0];
    this.titleGroupDetails[1]['data'] = category;

  }
}