import { NgModule } from '@angular/core';
import { RollitCommonModule } from '@rollit/shared/common';
import { CommonModule } from '@angular/common';
import { SavingsComponent } from './components/savings.component';
import { SavingsRoutingModule } from './savings-routing.module';
import { SavingsService } from './savings.service';
import { SavingsProjectionsComponent } from './components/savings-projections/savings-projections.component';
import { SavingsHeroComponent } from './components/savings-hero/savings-hero.component';







@NgModule({
  imports: [
    CommonModule, RollitCommonModule, SavingsRoutingModule
  ],
  declarations: [
    SavingsComponent, SavingsProjectionsComponent, SavingsHeroComponent,

  ],
  providers: [
    SavingsService,
  ]
})
export class SavingsModule { }
