
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MeService, FileService, EmployerService, LoggerService, Subscription, SubscriptionStatus, PlatformService, User, UserProfile, Employer } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';


declare var Intercom; // defined by included Intercom javascript.
declare var build: any;
declare var buildDate: any;

@Component({
  selector: 'app-profile-links',
  templateUrl: './profile-links.component.html',
  styleUrls: ['./profile-links.component.scss']
})
export class ProfileLinksComponent implements OnInit, OnDestroy {
  log: any;
  profile: UserProfile;
  currentDetailPage: string;
  // me: User;
  avatar: string = '/assets/images/default-profile.jpg';  // Profile image
  hasBaseDropZoneOver: boolean = false;
  selectedFileName = '';
  employment: number = 0;
  private isDestroyed = new Subject();
  employer: Employer;
  subscription: Subscription; // user Rollit subscription
  buildVersion = build;
  buildDate = buildDate;
  dateNow: number;

  agreementUrl: string;
  agreementTitle: string;
  privacyUrl: string;

  @ViewChild('fileUpload') fileUpload;

  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private router: Router,
    private userService: MeService,
    private fileService: FileService,
    private employerService: EmployerService,
    private logger: LoggerService,
    private platformService: PlatformService
  ) {
    this.log = this.logger.info('profileLinksComponent');
    let url = router.url;
    this.currentDetailPage = url.substring(url.lastIndexOf('/') + 1);
    this.router.events.pipe(takeUntil(this.isDestroyed)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        url = e.url;
        this.currentDetailPage = url.substring(url.lastIndexOf('/') + 1);
        this.log('currentDetailPage', this.currentDetailPage);
      }
    });


    this.userService.getProfile().subscribe(value => {
      this.profile = value;
      this.log('profile', this.profile);
      this.log('profile', this.profile.employment.length);
      this.employment = this.profile.employment.length;

      if (this.profile.picture) {
        this.fileService.getFilePath(this.profile.picture.id).subscribe(path => {
          this.avatar = path;
        });
      }

      if (this.profile.user.subscriptions) {
        // get individual subscription if it exists
        for (const s of this.profile.user.subscriptions) {
          if (!s.numEmployees) {
            this.subscription = s;
            break;
          }
        }
      }

    });

    this.agreementUrl = this.platformService.embedded ? this.environment.agreementUrl.app : this.environment.agreementUrl.web;
    this.agreementTitle = this.platformService.embedded ? 'Terms of Use' : 'Agreement';
    this.privacyUrl = this.environment.privacyUrl;
  }



  ngOnInit() {
    this.log('build', build);
    this.dateNow = Date.now();
    this.employerService.getEmployeesManagedByMe().subscribe(employers => {
      this.log('Got employers', employers);
      if (employers && employers.length > 0) {
        this.employerService.getEmployer(employers[0].id).subscribe(value => {
          this.log('Got employer', value);
          this.employer = value;
        });
      }
    });

    this.subscription = {
      customer: {}
    };
  }

  ngOnDestroy() {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
  }

  onLogout() {
    Intercom('trackEvent', 'web-app-profile-click-logout');
    this.userService.logout();
  }

  trackIntercomEvent(name: String) {
    Intercom('trackEvent', 'web-app-profile-click-link');
  }

  onFileUploaded(file) {
    this.log('upload success', file);
    this.fileService.getFilePath(file.id).subscribe(filePath => {
      this.avatar = filePath;
    });
    const updatedUser: User = {
      id: this.profile.user.id,
      picture: file
    };
    this.userService.updateMe(updatedUser).subscribe(
      (user) => { this.profile.user.picture = user.picture; Intercom('trackEvent', 'web-app-profile-uploaded-profile-image'); }
    );
  }

  onFireFileUpload() {
    this.fileUpload.onFileUploadClick();
  }

}
