import { Component, OnInit } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { InputComponent } from '../input.component';
import { TypeDef, EstimateSource, RealtyEstimateTypeDef, DetailedEstimate } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';

const TEST_ESTIMATE: DetailedEstimate = {
  "address": "22 / 1 - 11 GEORGE STREET ST MARYS NSW 2760",
  "bedrooms": 3,
  "carparks": 1,
  "estimate": 369769,
  "landarea": 8368,
  "location": {
    "lat": -33.7709,
    "lon": 150.7652
  },
  "bathrooms": 1,
  "confidence": "Medium",
  "percent_fsd": 10.5,
  "property_id": "37743481b091057d",
  "estimate_low": 331321,
  "is_on_market": false,
  "estimate_date": "2020-11-30",
  "estimate_high": 408217,
  "property_type": "Unit",
  "comparable_sales": [
    {
      "state": "NSW",
      "address": "11 / 1 - 11 GEORGE STREET ST MARYS NSW 2760",
      "sale_id": "cebd33a402bb85784838372be41715b73cdbe055",
      "bedrooms": 3,
      "carparks": 1,
      "landarea": 8419,
      "location": {
        "lat": -33.7715,
        "lon": 150.7649
      },
      "bathrooms": 2,
      "sale_date": "2020-02-22",
      "list_price": 430000,
      "sale_price": 432500,
      "property_id": "47f34252af0eab43",
      "days_on_market": 121,
      "metres_from_target": 72,
      "percent_vendor_expectation_error": 0.6
    },
    {
      "state": "NSW",
      "address": "4 / 29 PAGES ROAD ST MARYS NSW 2760",
      "sale_id": "a717d8365ff8c0e67a6016629832647c05afb32f",
      "bedrooms": 3,
      "carparks": 1,
      "landarea": 227,
      "location": {
        "lat": -33.7732,
        "lon": 150.7669
      },
      "bathrooms": 1,
      "sale_date": "2020-08-08",
      "list_price": 500000,
      "sale_price": 510000,
      "property_id": "e40e57831cb6b495",
      "days_on_market": 12,
      "metres_from_target": 293,
      "percent_vendor_expectation_error": 2.0
    },
    {
      "state": "NSW",
      "address": "8 / 94 SADDINGTON STREET ST MARYS NSW 2760",
      "sale_id": "6b32aa9fd13a1d3fc606ff0496684af787f554db",
      "bedrooms": 3,
      "carparks": 1,
      "landarea": 3400,
      "location": {
        "lat": -33.7728,
        "lon": 150.7658
      },
      "bathrooms": 1,
      "sale_date": "2020-06-29",
      "list_price": 450000,
      "sale_price": 485000,
      "property_id": "82f4c336a8bb1519",
      "days_on_market": 84,
      "metres_from_target": 217,
      "percent_vendor_expectation_error": 7.5
    },
    {
      "state": "NSW",
      "address": "9 / 54 PUTLAND STREET ST MARYS NSW 2760",
      "sale_id": "5addfb1d25bd5bab41b1640c9a011c4acb79aff5",
      "bedrooms": 2,
      "carparks": 1,
      "landarea": 1988,
      "location": {
        "lat": -33.7715,
        "lon": 150.7677
      },
      "bathrooms": 1,
      "sale_date": "2020-09-03",
      "sale_price": 300000,
      "property_id": "304a0387dd3c4f85",
      "metres_from_target": 237
    },
    {
      "state": "NSW",
      "address": "4 / 66 SADDINGTON STREET ST MARYS NSW 2760",
      "sale_id": "3af2825492507a6ff44476b47bf23b11ceab9c64",
      "bedrooms": 3,
      "carparks": 1,
      "landarea": 1225,
      "location": {
        "lat": -33.7728,
        "lon": 150.7682
      },
      "bathrooms": 2,
      "sale_date": "2020-06-22",
      "sale_price": 484500,
      "property_id": "b268024b67dd215f",
      "metres_from_target": 345
    },
    {
      "state": "NSW",
      "address": "13 / 83 - 85 SADDINGTON STREET ST MARYS NSW 2760",
      "sale_id": "1511284a2f81261b2a4a8be7ad3ac1ec380288f9",
      "bedrooms": 2,
      "carparks": 1,
      "landarea": 2440,
      "location": {
        "lat": -33.7719,
        "lon": 150.7666
      },
      "bathrooms": 1,
      "sale_date": "2020-05-21",
      "list_price": 295000,
      "sale_price": 315000,
      "property_id": "454a04e8b27a0233",
      "days_on_market": 42,
      "metres_from_target": 165,
      "percent_vendor_expectation_error": 6.6
    },
    {
      "state": "NSW",
      "address": "7 / 27 JOHN STREET ST MARYS NSW 2760",
      "sale_id": "0d985a40ca800fe7f0c7633b812fde193c4d1d7a",
      "bedrooms": 3,
      "carparks": 2,
      "location": {
        "lat": -33.7742,
        "lon": 150.7681
      },
      "bathrooms": 2,
      "sale_date": "2020-07-22",
      "list_price": 475000,
      "sale_price": 515000,
      "property_id": "917e213cf07d4e5e",
      "days_on_market": 42,
      "metres_from_target": 445,
      "percent_vendor_expectation_error": 8.1
    },
    {
      "state": "NSW",
      "address": "34 / 26 - 32 PRINCESS MARY STREET ST MARYS NSW 2760",
      "sale_id": "0f0a45e327368d73a9fe71bd12fa44dd6063d4b4",
      "bedrooms": 2,
      "carparks": 1,
      "landarea": 119,
      "location": {
        "lat": -33.7712,
        "lon": 150.7706
      },
      "bathrooms": 2,
      "sale_date": "2020-08-12",
      "list_price": 369950,
      "sale_price": 380000,
      "property_id": "59aa27d0b36bb190",
      "days_on_market": 78,
      "metres_from_target": 500,
      "percent_vendor_expectation_error": 2.7
    },
    {
      "state": "NSW",
      "address": "8 / 71 - 73 SADDINGTON STREET ST MARYS NSW 2760",
      "sale_id": "b3efde6fb136aef2cea4074b2d1a417d09f3a631",
      "bedrooms": 2,
      "carparks": 1,
      "landarea": 2375,
      "location": {
        "lat": -33.772,
        "lon": 150.7677
      },
      "bathrooms": 1,
      "sale_date": "2020-02-03",
      "list_price": 369000,
      "sale_price": 376000,
      "property_id": "a6360bdd35de4ce0",
      "days_on_market": 12,
      "metres_from_target": 258,
      "percent_vendor_expectation_error": 1.9
    },
    {
      "state": "NSW",
      "address": "15 / 71 - 73 SADDINGTON STREET ST MARYS NSW 2760",
      "sale_id": "3acdd8e22bb84a298996a14313cdb0ecf3ba4119",
      "bedrooms": 2,
      "carparks": 2,
      "landarea": 2375,
      "location": {
        "lat": -33.772,
        "lon": 150.7677
      },
      "bathrooms": 1,
      "sale_date": "2020-02-06",
      "list_price": 355000,
      "sale_price": 357000,
      "property_id": "935a94df2af32470",
      "days_on_market": 14,
      "metres_from_target": 258,
      "percent_vendor_expectation_error": 0.6
    }
  ],
  "weekly_rent_estimate": 325,
  "comparable_sale_listings": [
    {
      "state": "NSW",
      "address": "11 / 44 - 46 PUTLAND STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "location": {
        "lat": -33.7715,
        "lon": 150.768
      },
      "bathrooms": 1,
      "list_date": "2020-10-14",
      "is_auction": 0,
      "list_price": "$309,950",
      "listing_id": "4d672acb64ff7736c7bffa40254b735f9ecabfdc",
      "property_id": "9301e201580895db",
      "metres_from_target": 263
    },
    {
      "state": "NSW",
      "address": "4 / 64 SADDINGTON STREET ST MARYS NSW 2760",
      "bedrooms": 3,
      "carparks": 1,
      "location": {
        "lat": -33.7728,
        "lon": 150.7684
      },
      "bathrooms": 2,
      "list_date": "2020-10-21",
      "is_auction": 0,
      "list_price": "$585,000",
      "listing_id": "85f8fa3d4ad52ac5e97db6c15e631c2aa54db3f7",
      "property_id": "e6ce988eb6dbd1bd",
      "metres_from_target": 366
    },
    {
      "state": "NSW",
      "address": "38 / 91 SADDINGTON STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "location": {
        "lat": -33.7718,
        "lon": 150.7662
      },
      "bathrooms": 1,
      "list_date": "2020-08-10",
      "is_auction": 0,
      "list_price": "$269,950 - $299,950",
      "listing_id": "b2f01632650c14a0b25bf4e9886eeda71f461902",
      "property_id": "27959d63ef5e165a",
      "metres_from_target": 133
    },
    {
      "state": "NSW",
      "address": "6 / 22 JOHN STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "location": {
        "lat": -33.7748,
        "lon": 150.7682
      },
      "bathrooms": 1,
      "list_date": "2020-10-27",
      "is_auction": 0,
      "list_price": "PRICE RANGE $389,000-$409,000",
      "listing_id": "44f5069b907038da9d365c2374f5da3dff2aad8a",
      "property_id": "8830ea82782e80cb",
      "metres_from_target": 511
    },
    {
      "state": "NSW",
      "address": "9 / 40 - 42 PUTLAND STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "location": {
        "lat": -33.7715,
        "lon": 150.7684
      },
      "bathrooms": 1,
      "list_date": "2020-07-28",
      "is_auction": 0,
      "list_price": "$284,950 - $309,950",
      "listing_id": "bc93c2814ca9b3b3472809ef69a4d46f6ddf7a85",
      "property_id": "25b47daa3fea0b57",
      "metres_from_target": 300
    },
    {
      "state": "NSW",
      "address": "40 / 64 PUTLAND STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 2,
      "location": {
        "lat": -33.7711,
        "lon": 150.7662
      },
      "bathrooms": 1,
      "list_date": "2020-05-15",
      "is_auction": 0,
      "list_price": "$299,950 - $319,950",
      "listing_id": "b0f1bf82fa2a0585f1fe0087241cc2f19aedeaf6",
      "property_id": "8095ee04ec054742",
      "metres_from_target": 95
    }
  ],
  "comparable_rental_listings": [
    {
      "state": "NSW",
      "address": "7 / 64 - 66 PUTLAND STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "landarea": 1114,
      "location": {
        "lat": -33.7712,
        "lon": 150.766
      },
      "bathrooms": 1,
      "list_date": "2020-09-04",
      "listing_id": "2fe2b6983f0af7fc8400c330c910ddeb2118e23d",
      "property_id": "5bf4255a00cb97ef",
      "rental_price": 280,
      "rental_period": "WEEK",
      "metres_from_target": 75
    },
    {
      "state": "NSW",
      "address": "18 / 99 - 103 SADDINGTON STREET ST MARYS NSW 2760",
      "bedrooms": 3,
      "carparks": 1,
      "landarea": 8419,
      "location": {
        "lat": -33.7712,
        "lon": 150.765
      },
      "bathrooms": 2,
      "list_date": "2020-04-04",
      "listing_id": "244f282fdb90dc1a5c9cab16051df98b4995e91f",
      "property_id": "a30d001a1417f2e6",
      "rental_price": 325,
      "rental_period": "WEEK",
      "metres_from_target": 39
    },
    {
      "state": "NSW",
      "address": "2 / 64 - 66 PUTLAND STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "location": {
        "lat": -33.7712,
        "lon": 150.766
      },
      "bathrooms": 1,
      "list_date": "2020-06-24",
      "listing_id": "3032961bb1b4c53d22a6a73d38a98b1619fa516f",
      "property_id": "d0d025d34ecc419b",
      "rental_price": 265,
      "rental_period": "WEEK",
      "metres_from_target": 75
    },
    {
      "state": "NSW",
      "address": "3 / 1 - 11 GEORGE STREET ST MARYS NSW 2760",
      "bedrooms": 3,
      "carparks": 1,
      "location": {
        "lat": -33.7715,
        "lon": 150.7653
      },
      "bathrooms": 2,
      "list_date": "2019-10-31",
      "listing_id": "ec6e02569a606f492e7d3015d72846a92eea9229",
      "property_id": "bf8a9580186e0340",
      "rental_price": 350,
      "rental_period": "WEEK",
      "metres_from_target": 63
    },
    {
      "state": "NSW",
      "address": "7 / 68 PUTLAND STREET ST MARYS NSW 2760",
      "bedrooms": 2,
      "carparks": 1,
      "location": {
        "lat": -33.7712,
        "lon": 150.7658
      },
      "bathrooms": 1,
      "list_date": "2020-03-10",
      "listing_id": "ec3496d4d3e4e0c836c3b1006c0062de19b6b9fc",
      "property_id": "61eb3cbb0fade000",
      "rental_price": 300,
      "rental_period": "WEEK",
      "metres_from_target": 56
    },
    {
      "state": "NSW",
      "address": "4 / 99 - 103 SADDINGTON STREET ST MARYS NSW 2760",
      "bedrooms": 3,
      "carparks": 1,
      "location": {
        "lat": -33.7716,
        "lon": 150.7653
      },
      "bathrooms": 2,
      "list_date": "2019-11-14",
      "listing_id": "a67fb58d59c66b4e84129024667cb3fb094d5220",
      "property_id": "19b534de2d44c1d1",
      "rental_price": 360,
      "rental_period": "WEEK",
      "metres_from_target": 75
    }
  ],
  "percent_rent_yield_estimate": 4.58,
  "coverage_probability_percent": 68.3,
  "last_12_mnths_locality_data_similar_properties": {
    "sales": 59,
    "low_price": 430000,
    "high_price": 561800,
    "median_price": 484750,
    "mean_days_on_market": 47,
    "mean_percent_return": 1.1,
    "mean_percent_rental_yield": 4.6,
    "number_similar_properties": 1988,
    "percent_vendor_expectation_error": -2.1
  }
};

/**
 * Provided a text input with address search - drop-down for matched addresses.
 * 
 * Uses the Rollit web service to search for properties.
 * 
 * Submits Property
 */
@Component({
  selector: 'lib-input-realty-estimate',
  templateUrl: './input-realty-estimate.component.html',
  styleUrls: ['./input-realty-estimate.component.scss'],
})
export class InputRealtyEstimateComponent extends InputComponent implements OnInit {

  log: any;
  formControl: FormControl = new FormControl();
  value: string;
  label: string;
  idsOpen: boolean = false;

  detailedEstimate: DetailedEstimate;

  estimateValuation = { title: "Automated valuation", value: 0, source: EstimateSource.Automatic };
  estimateHigh = { title: "High range estimate", value: 0, source: EstimateSource.Upper, active: false };
  estimateLow = { title: "Low range estimate", value: 0, source: EstimateSource.Lower, active: false };
  estimateUser = { title: "Your price", value: 0, source: EstimateSource.Manual, active: false };
  estimateTypes = [
    this.estimateValuation,
    this.estimateHigh,
    this.estimateLow,
    this.estimateUser,
  ];
  preferredEstimate: number = 0;
  estimateUserEdit = false;


  constructor(
    private logger: LoggerService
  ) {
    super();
    this.log = this.logger.info("InputRealtyEstimateComponent");
  }

  ngOnInit() {
    this.detailedEstimate = TEST_ESTIMATE;
  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log("Setup realty input with def", typeDef);
    const def = typeDef as RealtyEstimateTypeDef;
  }

  /**
   * Estimate received from dialog service.
   * 
   * @param data 
   */
  handleData(data: DetailedEstimate) {
    this.detailedEstimate = data;

    this.log('handleData', data);

    // FOR TESTING
    //if (!this.detailedEstimate) {
    //  this.detailedEstimate = TEST_ESTIMATE;    // force the test
    //}

    if (this.detailedEstimate) {
      this.estimateValuation.value = this.detailedEstimate.estimate;
      this.estimateLow.value = this.detailedEstimate.estimate_low;
      this.estimateHigh.value = this.detailedEstimate.estimate_high;
      this.estimateUser.value = this.detailedEstimate.estimate_user;

      switch (this.detailedEstimate.estimate_source) {
        case EstimateSource.Automatic:
          this.preferredEstimate = 0;
          break;
        case EstimateSource.Upper:
          this.preferredEstimate = 1;
          break;
        case EstimateSource.Lower:
          this.preferredEstimate = 2;
          break;
        case EstimateSource.Manual:
          this.preferredEstimate = 3;
          break;
      }
    }
  }

  /**
   * Return the data for this component.
   */
  getData() {
    // this.log('returning data', this.detailedEstimate);
    return this.detailedEstimate;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

  /**
   * When user-defined estimate is changed.
   * 
   * @param amount 
   */
  onUserEstimateChange(e) {
    this.log('onUserEstimateChange', e);
    this.preferredEstimate = 3;
    this.detailedEstimate.estimate_source = EstimateSource.Manual;
    this.detailedEstimate.estimate_user = e.target.value.replace(/[, ]+/g, "").trim();
    this.log('onUserEstimateChange', this.detailedEstimate);
  }

  /**
   * When estimate source has changed.
   * 
   * @param e
   */
  onPreferredEstimateChange(e) {
    // TODO select the estimate to use.
    // this.detailedEstimate.estimate_user = 1000000;
    // this.detailedEstimate.estimate_source = EstimateSource.Manual;
    this.log('onPreferredEstimateChange', e);
    const sourceId = e.value;
    switch (sourceId) {
      case 0:
        this.detailedEstimate.estimate_source = EstimateSource.Automatic;
        break;
      case 1:
        this.detailedEstimate.estimate_source = EstimateSource.Upper;
        break;
      case 2:
        this.detailedEstimate.estimate_source = EstimateSource.Lower;
        break;
      case 3:
        this.detailedEstimate.estimate_source = EstimateSource.Manual;
        break;
    }
  }

  toggleIds() {
    this.idsOpen = !this.idsOpen;
  }


  // ------------------- old functions from old property component
  /*
    saveUserEstimate(event) {
      this.onManualEstimateChange(this.estimateUser.value);
    }
  
    closeUserEstimate(event) {
  
    }
  
    editUserEstimate(event) {
  
    }
  
  
  */
}
