import { Directive, HostListener, Input, ElementRef } from '@angular/core';

const   regexStr = '^[0-9]*$';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {


  constructor(private el: ElementRef) {
    console.log('constructed InputTextFilterDirective');
  }

  @HostListener('input', ['$event']) onInput(event) {
    this.checkString();
  }

  @HostListener('change') onChange() {
    this.checkString();
  }

  private checkString() {
    const string = (this.el.nativeElement as HTMLInputElement).value;
    const lastChar = string.substr(string.length - 1);
    const regEx = new RegExp(regexStr);
    const testString = regEx.test(lastChar);
    if (testString) {
      return;
    } else {
      (this.el.nativeElement as HTMLInputElement).value = string.slice(0, -1);
      console.log('slice', string.slice(0, -1));
      console.log((this.el.nativeElement as HTMLInputElement).value);
    }
  }
}
