
import { Component, Inject, OnInit } from '@angular/core';
import { LoggerService, Nudge } from '@rollit/shared/data';
import { DashboardService } from '../../dashboard.service';
import { UIManagerService } from '@rollit/shared/services';
import { TitleItemData, TrackService } from '@rollit/shared/common';
import { Subject } from 'rxjs';
import { trackById } from '@rollit/shared/utils';
import { APP_ENVIRONMENT } from '@rollit/shared';

@Component({
  selector: 'app-dashboard-projection',
  templateUrl: './dashboard-projection.component.html',
  styleUrls: ['./dashboard-projection.component.scss']
})

export class DashboardProjectionComponent implements OnInit {
  chartData = [];
  chartCategories = [];
  log: any;
  sectionValue: Subject<string> = new Subject();
  currentIndex = 0;
  currentSection = 'savings';
  isDesktop: boolean;
  trackById = trackById;
  nudges: Nudge[];
  togglePeriod = 'P1Y';
  toggleDisabled = false;
  gotFirstProjection = false;
  titleGroupDetails: Array<TitleItemData> = [

    {
      title: 'Age',
      data: 0,
      dataColor: '#509E72',
      isNumber: true,
      hide: true
    },
    {
      title: 'Assets',
      data: 0,
      dataColor: '#182844',
      isCurrency: true,
      hide: true
    },
    {
      title: 'Net wealth',
      data: 0,
      dataColor: '#509E72',
      isCurrency: true,
      hide: true
    },
    {
      title: 'Borrowings',
      data: 0,
      dataColor: '#a31b5c',
      isCurrency: true,
      hide: true
    }
  ];
  disclaimerUrl = 'ddd';



  constructor(
    private dashboardService: DashboardService,
    private trackService: TrackService,
    private logger: LoggerService,
    private uiManagerService: UIManagerService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.log = this.logger.info('DashboardProjectionComponent');
    this.uiManagerService.mediaSizeIsDesktop$.subscribe(isDesktop => this.isDesktop = isDesktop);
    this.disclaimerUrl = this.environment.projectionDisclaimerUrl;
  }

  ngOnInit(): void {

    this.getProjections(this.togglePeriod);


    this.dashboardService.getNudges().subscribe(result => {
      this.nudges = result.data.length <= 3 ? result.data : result.data.slice(0, 3);
    });

  }

  getProjections(period) {
    this.chartData = [];
    this.toggleDisabled = true;
    const colorArray = ['#05A8DB', '#0D71B9', '#01518B', '#1B365F', '#182844', '#DB0A6E', '#A31B5C', '#761744', '#509E72'];
    this.chartCategories = [];
    const chartCategoriesMap = [
      { short: 'SAV', section: 'savings', coreSection: 'savings', title: 'Savings' },
      { short: 'LIFE', section: 'lifestyle', coreSection: 'lifestyle', title: 'Lifestyle Assets' },
      { short: 'INVT', section: 'investments', coreSection: 'investments', title: 'Investments' },
      { short: 'SUPR', section: 'superannuation', coreSection: 'superannuation', title: 'Superannuation' },
      { short: 'PROP', section: 'property', coreSection: 'property', title: 'Property' },
      { short: 'NET', section: '', coreSection: 'growth', title: 'Net' },
      { short: 'MTG', section: 'property', coreSection: 'mortgage', title: 'Mortgage' },
      { short: 'LOAN', section: 'credit', coreSection: 'loans', title: 'Loan' },
      { short: 'CC', section: 'credit', coreSection: 'credit', title: 'Credit Card' }
    ];

    const temp_array = [];

    this.dashboardService.getProjections(period).subscribe(value => {
      this.log('Got projections', value);

      const self = this;
      value.names.forEach(function (nameVal, nameIndex) {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            self.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
            temp_object['coreSection'] = chartVal.coreSection;
          }
        });

        if (nameVal === 'Net') {
          temp_object['type'] = 'spline';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        self.log(nameVal, nameIndex, colorArray[nameIndex]);





        value.data.forEach(function (dataVal, dataIndex) {
          self.chartCategories.push(value.data[dataIndex].label);
          temp_data_array.push(value.data[dataIndex].values[nameIndex]);
        });

        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      self.log(temp_array);

      this.chartData = temp_array;
      self.log('this.chartData', this.chartData);
      self.log('this.chartCategories', this.chartCategories);
      this.toggleDisabled = false;
      this.gotFirstProjection = true;
      this.setupTitleGroupDetails();
    });
  }

  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.chartCategories[0];
    this.titleGroupDetails[0]['hide'] = false;

    this.titleGroupDetails[1]['data'] = this.chartData[0].data[0] + this.chartData[1].data[0] + this.chartData[2].data[0] + this.chartData[3].data[0] + this.chartData[4].data[0];
    this.titleGroupDetails[1]['hide'] = false;

    this.titleGroupDetails[2]['data'] = this.chartData[8].data[0];
    this.titleGroupDetails[2]['hide'] = false;

    this.titleGroupDetails[3]['data'] = this.chartData[5].data[0] + this.chartData[6].data[0] + this.chartData[7].data[0];
    this.titleGroupDetails[3]['hide'] = false;
    this.log(this.titleGroupDetails);
  }

  graphClicked(category, data, index): void {
    this.log(category, data, index);
    this.currentSection = category;
    this.sectionValue.next(category);
    this.currentIndex = index;

    this.titleGroupDetails[0]['data'] = category;

    this.titleGroupDetails[1]['data'] = data[0] + data[1] + data[2] + data[3] + data[4];
    this.titleGroupDetails[2]['data'] = data[8];
    this.titleGroupDetails[3]['data'] = data[5] + data[6] + data[7];
  }

  toggleChange() {
    if (!this.toggleDisabled) {

      if (this.togglePeriod === 'P1Y') {
        this.togglePeriod = null;
      } else {
        this.togglePeriod = 'P1Y';
      }
      this.getProjections(this.togglePeriod);
      this.log('toggle', this.togglePeriod);
    }

  };

  onNudgeClosed(nudge: Nudge) {
    // set nudge status to "dismissed"
    this.dashboardService.dismissNudge(nudge).subscribe(result => {
      // remove nudge from list
      const index = this.nudges.indexOf(nudge, 0);
      if (index > -1) {
        this.nudges.splice(index, 1);
      }
    });
  }

  onNudgeButtonPressed(nudge: Nudge) {
    this.trackService.performNudgeAction(nudge);
  }
}
