import { Component, OnInit, OnDestroy } from '@angular/core';
import { XeroService, XeroEmployee } from '../service/xero.service';
import { EmployerService } from '@rollit/shared/data';
import { Subscription } from 'rxjs';
import { LoggerService } from '@rollit/shared/data';
import * as moment_ from 'moment';
const moment = moment_;
import { NotificationService } from '@rollit/shared/data';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@rollit/shared/common';
import { Employer, Employee } from '@rollit/shared/data';


@Component({
  selector: 'employer-payroll',
  templateUrl: './employer-payroll.component.html',
  styleUrls: ['./employer-payroll.component.scss']
})
export class EmployerPayrollComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['Email', 'FirstName', 'LastName', 'Mobile', 'Status', 'UpdatedDateUTC', 'DateOfBirth', 'Action'];
  employer: Employer;
  xeroEmployees: XeroEmployee[];
  rollitEmployees: Employee[];
  cred: any;  // credential for Xero connection
  xeroOrganisation: any;
  private _subscriptions = new Subscription();
  private log: any;
  connectionStatusClicked: boolean;

  constructor(
    private employerService: EmployerService,
    private xero: XeroService,
    private logger: LoggerService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    this.log = this.logger.info('employerPayroll');
  }

  ngOnInit() {
    this._subscriptions.add(
      this.employerService.currentEmployer$().subscribe(value => {
        this.employer = value;

        // Check whether we have Xero credential
        this.employerService.getCredential(value.id, 'Xero').subscribe(cred => {
          this.log("Got xero credential", cred);
          this.cred = cred;
          if (this.validCred) {
            this.xero.getOrganisations(this.employer.id).subscribe(orgs => {
              this.log("got Xero orgs", orgs);
              if (orgs && orgs.length > 0) {
                this.xeroOrganisation = orgs[0];
              }
              this.fetchXeroEmployees();
            },
              error => {
                if (error.error && error.error.cause.name === 'XeroForbiddenException') {
                  console.log(error.error.cause.name);
                  this.cred = false;
                }
              });
            this.getRollitEmployees();
          } else {
            this.xeroEmployees = null;
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * Whether this component has received valid Xero credentials.
   */
  get validCred(): boolean {
    return this.cred && this.cred.valid !== false;
  }

  /**
   * Authorise Rollit to fetch employee data from a Xero user's organisation.
   */
  onConnectXero() {
    this.xero.authorise(this.employer.id);
  }

  /**
   * De-authorise rollit from accessing the Xero organisation.
   */
  onDisconnectXero() {
    this.xero.removeConnection(this.employer.id).subscribe(() => {
      this.log("Xero Disconnected");
      this.cred = null;
      this.xeroEmployees = null;
    });
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove Xero connection',
        copy: 'Are you sure you want to remove the connection to Xero?',
        buttonMessage: 'Yes, remove account',
        type: 'Connection',
        showCancel: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result[0] === 'accept') {
        this.onDisconnectXero();
      }
    });
  }

  onCloseConnectionClick() {
    this.connectionStatusClicked = true;
  }
  onCancelCloseConnectionClick() {
    this.connectionStatusClicked = false;
  }

  private getRollitEmployees() {
    this.employerService.getEmployees(this.employer.id, null, null, null, 0, 100).subscribe(result => {
      this.rollitEmployees = result.data;
      // numEmployees = result.total;
      this.matchEmployees();
    });
  }

  private fetchXeroEmployees() {
    this.xero.getEmployees(this.employer.id).subscribe(employees => {
      this.log("got Xero employees", employees);
      // this.employees = employees;
      for (const value of employees) {
        // convert dates
        value.DateOfBirth = moment(value.DateOfBirth);
        value.UpdatedDateUTC = moment(value.UpdatedDateUTC);

        // TODO match with existing employees in Rollit.
        // ---
      }

      // TODO handle paging.

      this.xeroEmployees = employees;
      this.matchEmployees();
    });
  }

  private matchEmployees() {
    if (this.xeroEmployees && this.rollitEmployees) {
      for (const xemp of this.xeroEmployees) {
        const emp = this.matchEmployee(xemp, this.rollitEmployees);
        if (emp) {
          xemp.extra = emp;
        }
      }
    }
  }

  private matchEmployee(xemp: XeroEmployee, employees: Employee[]): Employee {
    if (!xemp.Email) {
      return null;
    }
    const email = xemp.Email.toLowerCase();
    for (const emp of employees) {
      if (emp.email && emp.email.toLowerCase() === email) {
        return emp;
      }
    }
    return null;
  }

  /**
   * Onboard a Xero employee into Rollit.
   *
   * @param employee The xero employee record.
   */
  onboardEmployee(employee: XeroEmployee) {
    this.log("onboarding employee", employee);

    const employees: Employee[] = [this.xeroToRollitEmployee(employee)];
    this.employerService.putEmployees(this.employer.id, employees).subscribe(
      value => {
        this.log('Employee onboarded', value);
        employee.extra = value[0];
        this.notificationService.info('Success', 'Employee, ' + employee.FirstName + ' ' + employee.LastName + ', on-boarded');
      },
      err => {
        this.log('Error onboarding employees', err);
        this.notificationService.error('Problem on-boarding employees', err);
      }
    );
  }

  /**
   * Remove an employee from an employer's Rollit subscription.
   * @param employeeId The Rollit ID of the employee.
   */
  removeEmployeeConfirm(employeeId: number) {
    this.log('removing employee', employeeId);
    this.employerService.removeEmployee(this.employer.id, employeeId).subscribe(value => {
      this.notificationService.error('Employee ' + employeeId + ' removed');
      // removed
    },
      err => {
        this.log('Error removing employee', err);
      });
  }

  private xeroToRollitEmployee(employee: XeroEmployee): Employee {
    const result = {
      firstName: employee.FirstName,
      lastName: employee.LastName,
      sex: employee.Gender === 'M' ? "Male" : "Female",
      dob: employee.DateOfBirth,
      phone: employee.Phone,
      email: employee.Email,
      // title: employee.Title,
      // payrollNumber: employee.PayrollNumber,
      // superFundName: employee.SuperFundName,
      // superFundUsi: employee.SuperFundUsi,
      // superFundMemberNumber: employee.SuperMemberNumber,
    };

    return result;
  }
}
