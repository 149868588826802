import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrackService } from '@rollit/shared/common';
import { Nudge, NudgeStatus } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-dashboard-insights',
  templateUrl: './dashboard-insights.component.html',
  styleUrls: ['./dashboard-insights.component.scss']
})
export class DashboardInsightsComponent implements OnInit, OnDestroy {
  nudges: Nudge[] = [];
  private isDestroyed = new Subject();
  activeItem: number = 0;
  ready: boolean;
  isDesktop = false;

  constructor(private uiService: UIManagerService,
    private dashboardService: DashboardService,
    private trackService: TrackService
    
  ) {
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.dashboardService.getNudges().subscribe(result => {
      this.nudges = result.data;
      this.ready = true;
    });
  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  onClosed(nudge: Nudge) {
    // set nudge status to "dismissed"
    this.dashboardService.dismissNudge(nudge).subscribe(result => {
      // remove nudge from list
      const index = this.nudges.indexOf(nudge, 0);
      if (index > -1) {
        this.nudges.splice(index, 1);
      }
    });
  }

  onButtonPressed(nudge: Nudge) {
    this.trackService.performNudgeAction(nudge);
  }

  expandItem(event, id) {

    this.activeItem = id;
  }
}
