import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpUrlEncodingCodec, HttpParameterCodec } from "@angular/common/http";
import { LoggerService } from "@rollit/shared/data";
import { Observable } from "rxjs";
import { APP_ENVIRONMENT } from "@rollit/shared";


class MyHttpParameterCodec implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

export interface XeroEmployee {
    EmployeeID: string;
    FirstName?: string;
    LastName?: string;
    MiddleNames?: string;
    DateOfBirth?: any;
    Gender?: string;             // 'M' or 'F'
    Email?: string;
    Phone?: string;
    BankAccounts?: any[];
    LeaveBalances?: any[];
    SuperMemberships?: any[];
    Status?: string;             // "ACTIVE"
    StartDate?: string;
    UpdatedDateUTC?: any;
    extra?: any;
}

@Injectable()
export class XeroService {
    apiUrl = this.environment.apiUrl + '/../accounting/xero';
    log: any;

    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private http: HttpClient,
        private logger: LoggerService
    ) {
        this.log = this.logger.info('xeroService');
    }

    authorise(employeeId: number, redirectUri?: string) {
        const path = this.apiUrl + '/authorise';
        const redirect = redirectUri ? redirectUri : location.href;

        const params = new HttpParams({ encoder: new MyHttpParameterCodec() })
            .set("employer", "" + employeeId)
            .set("redirect", redirect);

        // Intercom('trackEvent', 'web-app-track-connect-click-xero-connect');

        location.href = path + '?' + params.toString();
    }

    getOrganisations(employerId: number): Observable<any> {
        const path = this.apiUrl + '/employer/' + employerId + '/organisations';

        return this.http.get<any>(path);
    }

    /**
     *
     * @param employerId The Rollit ID of the employer
     * @param offset Number of the first record to fetch, in multiples of 100.
     * @param max Note: max is fixed at 100
     */
    getEmployees(employerId: number, offset: number = 0, max: number = 100): Observable<XeroEmployee[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee';

        const params = new HttpParams()
            .set("offset", "" + offset);

        return this.http.get<XeroEmployee[]>(path, { params: params });
    }

    getEmployee(employerId: number, employeeId: string): Observable<XeroEmployee> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee/' + employeeId;

        return this.http.get<XeroEmployee>(path);
    }

    /**
     * Disconnect this employer from Xero
     * @param employerId The ID of the employer.
     */
    removeConnection(employerId: number): Observable<void> {
        const path = this.apiUrl + '/employer/' + employerId;

        return this.http.delete<void>(path);
    }
}
