import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { InputComponent } from '../input.component';
import { TypeDef, SelectTypeDef, Option } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { ValidationErrors, FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'lib-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent extends InputComponent implements OnInit {
  log: any;
  formControl: FormControl = new FormControl();
  selected: any;
  options: any[] = [];  // options to select from

  constructor(
    private logger: LoggerService
  ) {
    super();
    this.log = this.logger.info("inputSelectComp");
  }

  ngOnInit() { }

  handleTypeDef(typeDef: TypeDef) {
    // TODO use type definition to set params of this input, including validation.
    this.log("setting input def", typeDef);
    const validators: ValidatorFn[] = [];
    const def: SelectTypeDef = typeDef as SelectTypeDef;
    // this.label = def.label;
    this.log('this.prompt', this.prompt);
    //    this.options.push({ name: "Select", slug: "SElect", value: "select", visible: true });
    this.log('options', this.options);
    //this.options.push(def.options);
    def.options.forEach((option) => {
      this.options.push(option);
    });


    validators.push(Validators.required);
    this.formControl.setValidators(validators);


  }

  handleData(data: any) {
    this.log("handleData", data, this.formControl);
    if (data !== undefined) {
      this.log("setting input value:", data, this.formControl);
      this.selected = data;
      this.formControl.setValue(this.selected);
    } else {
      this.selected = this.options[0].slug;
      this.formControl.setValue(this.selected);
    }

  }

  getData() {
    return this.selected;
  }


  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

  get visibleOptions() {
    const result = this.options.filter(x => x.visible !== false);
    //this.log("Returning visible options", result);
    return result;
  }


}
