export const SCSS_VARS =
{
  "$rollit-primary": "#1b365f",
  "$rollit-secondary": "#8a9ab1",
  "$rollit-highlight": "#01518b",
  "$rollit-default": "#0d71b9",
  "$rollit-light": "#f4f4f4",
  "$rollit-lightest": "#fff",
  "$rollit-dark": "#182844",
  "$rollit-linear": "linear-gradient(90deg,#0d71b9 0,#01518b 100%)",
  "$rollit-positive": "#182844",
  "$rollit-negative": "#a31b5c",
  "$rollit-growth": "#509e72",
  "$rollit-savings": "#05a8db",
  "$rollit-lifestyle": "#0d71b9",
  "$rollit-investments": "#01518b",
  "$rollit-superannuation": "#1b365f",
  "$rollit-property": "#182844",
  "$rollit-realty": "#182844",
  "$rollit-mortgage": "#db0a6e",
  "$rollit-loans": "#a31b5c",
  "$rollit-loan": "#a31b5c",
  "$rollit-credit": "#761744",
  "$rollit-budget-income": "#05a8db",
  "$rollit-budget-living": "#a31b5c",
  "$rollit-budget-lifestyle": "#db0a6e",
  "$rollit-budget-housing": "#761744",
  "$rollit-budget-savings": "#05a8db",
  "$primary": "#1b365f",
  "$primary-alpha": "rgba(27,54,95,0.1)",
  "$primary-dark-alpha": "rgba(27,54,95,0.7)",
  "$secondary": "#757575",
  "$secondary-alpha": "rgba(117,117,117,0.5)",
  "$secondary-light": "#f4f4f4",
  "$secondary-light-alpha": "rgba(244,244,244,0.3)",
  "$secondary-light-dark": "#e6e6e6",
  "$secondary-dark": "#212121",
  "$tertiary": "#f4f4f4",
  "$highlight": "#0d71b9",
  "$highlight-alpha": "rgba(13,113,185,0.2)",
  "$highlight-light-alpha": "#0c619f29",
  "$highlight-light": "#04a8db",
  "$highlight-dark": "#0c71b9",
  "$primary-red": "#a31b5c",
  "$warn": "red",
  "$success": "#509e72",
  "$success-light": "rgba(80,158,114,0.7)",
  "$success-dark": "#489369",
  "$light": "#fff",
  "$light-alpha": "rgba(255,255,255,0.3)",
  "$light-alpha-dark": "rgba(255,255,255,0.7)",
  "$color-wheel-1": "#1b365f",
  "$color-wheel-2": "#c4c4c4",
  "$color-wheel-3": "#a31b5c",
  "$color-wheel-4": "#ef5350",
  "$color-wheel-5": "#ec407a",
  "$color-wheel-6": "#29b6f6",
  "$color-wheel-7": "#26c6da",
  "$color-wheel-8": "#26a69a",
  "$color-wheel-9": "#9ccc65",
  "$color-wheel-10": "#d4e157",
  "$color-wheel-11": "#ffee58",
  "$color-wheel-12": "#ffca28",
  "$color-wheel-13": "#ffa726",
  "$color-wheel-14": "#ff7043",
  "$color-wheel-15": "#8d6e63",
  "$color-wheel-16": "#f8bbd0",
  "$color-wheel-17": "#d1c4e9",
  "$color-wheel-18": "#c5cae9",
  "$color-wheel-19": "#b3e5fc",
  "$color-wheel-20": "#b2ebf2",
  "$color-wheel-21": "#b2dfdb",
  "$color-wheel-22": "#c8e6c9",
  "$color-wheel-23": "#dcedc8",
  "$color-wheel-24": "#f0f4c3",
  "$color-wheel-25": "#fff9c4",
  "$color-wheel-26": "#ffecb3",
  "$color-wheel-27": "#ffe0b2",
  "$color-wheel-28": "#ffccbc",
  "$color-wheel-29": "#d7ccc8",
  "$color-wheel-30": "#b71c1c",
  "$color-wheel-31": "#880e4f",
  "$color-wheel-32": "#4a148c",
  "$color-wheel-33": "#311b92",
  "$color-wheel-34": "#1a237e",
  "$color-wheel-35": "#0d47a1",
  "$color-wheel-36": "#01579b",
  "$color-wheel-37": "#006064",
  "$color-wheel-38": "#004d40",
  "$color-wheel-39": "#1b5e20",
  "$color-wheel-40": "#33691e",
  "$color-wheel-41": "#827717",
  "$color-wheel-42": "#f57f17",
  "$color-wheel-43": "#ff6f00",
  "$color-wheel-44": "#e65100",
  "$color-wheel-45": "#bf360c"
}
