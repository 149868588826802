<div *ngIf="!validCred" class="connect">
    <img src="/assets/images/connect-blue.svg" alt="Connect to Xero" (click)="onConnectXero()">
</div>

<div class="connection" *ngIf="xeroEmployees">
    <h3 (click)="connectionStatusClicked ? onCancelCloseConnectionClick() : onCloseConnectionClick() ">Connected to Xero : <b> {{xeroOrganisation?.Name}}</b> <i *ngIf="!connectionStatusClicked" class="material-icons">arrow_drop_down</i><i *ngIf="connectionStatusClicked" class="material-icons">arrow_drop_up</i></h3>
    <div *ngIf="connectionStatusClicked" class="close-connection">
        <img *ngIf="connectionStatusClicked" src="/assets/images/disconnect-blue.svg" alt="Connect to Xero" (click)="openConfirmationDialog()">
        <span (click)="onCancelCloseConnectionClick()">Cancel</span>
    </div>

</div>

<div *ngIf="xeroEmployees">

    <table mat-table [dataSource]="xeroEmployees">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- EmployeeID Column -->
        <ng-container matColumnDef="EmployeeID">
            <th mat-header-cell *matHeaderCellDef> EmployeeID </th>
            <td mat-cell *matCellDef="let element"> {{element.EmployeeID}} </td>
        </ng-container>

        <!-- FirstName Column -->
        <ng-container matColumnDef="FirstName">
            <th mat-header-cell *matHeaderCellDef> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
        </ng-container>

        <!-- MiddleNames Column -->
        <ng-container matColumnDef="MiddleNames">
            <th mat-header-cell *matHeaderCellDef> Middle Names </th>
            <td mat-cell *matCellDef="let element"> {{element.MiddleNames}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
        </ng-container>

        <!-- UpdatedDateUTC Column -->
        <ng-container matColumnDef="UpdatedDateUTC">
            <th mat-header-cell *matHeaderCellDef> Last updated </th>
            <td mat-cell *matCellDef="let element"> {{element.UpdatedDateUTC | date:'shortDate' }} </td>
        </ng-container>

        <!-- Mobile Column -->
        <ng-container matColumnDef="Mobile">
            <th mat-header-cell *matHeaderCellDef> Mobile </th>
            <td mat-cell *matCellDef="let element"> {{element.Mobile}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
        </ng-container>

        <!-- DateOfBirth Column -->
        <ng-container matColumnDef="DateOfBirth">
            <th mat-header-cell *matHeaderCellDef> Date of birth </th>
            <td mat-cell *matCellDef="let element"> {{element.DateOfBirth | date:'shortDate'}} </td>
        </ng-container>
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <button mat-button *ngIf="!element.extra" (click)="onboardEmployee(element)">Onboard</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>