import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';

@Component({
  selector: 'lib-health-score-chart',
  templateUrl: './health-score-chart.component.html',
  styleUrls: ['./health-score-chart.component.scss']
})
export class HealthScoreChartComponent implements OnInit {
  log: any;
  @Input() scoreIndex: number = 0;
  scoreIndexAnim = 0;
  scoreIndexCountUp = 0;
  constructor(private logger: LoggerService,) {
    this.log = this.logger.info('HealthScoreChartComponent');
  }

  ngOnInit() {
    this.log('scoreIndex', this.scoreIndex);
    /*
    setTimeout(() => {
      this.scoreIndexAnim = this.scoreIndex;
      this.animateValue(this.scoreIndexCountUp, this.scoreIndex, 200);
    }, 100);
    */
  }


  animateValue(start, end, duration) {
    //this.log(start, end);
    if (!Number.isNaN(start) && !Number.isNaN(end)) {
      if (start === end) {
        return;
      }
      const range = end - start;
      let current = start;
      const increment = end > start ? 1 : -1;
      const stepTime = Math.abs(Math.floor(duration / range));
      // this.log(increment, stepTime);
      const self = this;
      const timer = setInterval(function () {
        current += increment;
        // this.log(current);
        self.scoreIndexCountUp = current;
        if (current === end) {
          clearInterval(timer);
        }
      }, stepTime);
    }
  }
}
