import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, FormControl, ValidatorFn } from '@angular/forms';
import { InputComponent } from '../input.component';
import { TypeDef, Realty, RealtyTypeDef, RealtyType, OccupancyType, RealtyService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@rollit/shared/common';
import { trackById } from '@rollit/shared/utils';

const TEST_PROPERTY = {
  "state": "NSW",
  "address": "22 / 1 - 11 GEORGE STREET ST MARYS NSW 2760",
  "visible": true,
  "bedrooms": 3,
  "carparks": 1,
  "landarea": 8368,
  "bathrooms": 1,
  "property_id": "37743481b091057d",
  "property_type": RealtyType.Unit,
  "occupancy_type": OccupancyType.OwnerOccupied,
  "address_details": {
    "lga": "Penrith (C)",
    "sa1": 12405147135,
    "sa2": 124051471,
    "sa3": 12405,
    "sa4": 124,
    "state": "NSW",
    "street": "GEORGE",
    "address": "22 / 1 - 11 GEORGE STREET ST MARYS NSW 2760",
    "lga_code": "16350",
    "locality": "ST MARYS",
    "location": {
      "point": {
        "lat": -33.77094692,
        "lon": 150.76523351
      }
    },
    "postcode": "2760",
    "meshblock": 10607920000,
    "street_type": "ST",
    "unit_number": "22",
    "street_number": "1",
    "street_number_high": "11"
  }
};

/**
 * Provided a text input with address search - drop-down for matched addresses.
 * 
 * Uses the Rollit web service to search for properties.
 * 
 * Submits Property
 * 
 * 
 * TODO ability to search for property, and select.
 * TODO if property not found in property search, then create a property object with entered address. Populate object with what we have.
 */
@Component({
  selector: 'lib-input-realty',
  templateUrl: './input-realty.component.html',
  styleUrls: ['./input-realty.component.scss'],
})
export class InputRealtyComponent extends InputComponent implements OnInit, OnDestroy {
  isDestroyed = new Subject();
  log: any;
  formControl: FormControl = new FormControl();
  type = "text";
  length = 100;
  value: string;
  label: string;
  searchText = new Subject<string>();
  searchOptions: Array<Realty> = [];     // options returns from search
  yourProperties: Realty[];            // the user's selected investment option
  propertyValid = false;
  isSearch = true;
  showSearchOutput = false;
  trackById = trackById;
  isValid = false;

  property: Realty; // the property

  constructor(
    private logger: LoggerService, private realtyService: RealtyService, private dialog: MatDialog, private el: ElementRef,
  ) {
    super();
    this.log = this.logger.info("InputRealtyComponent");
    this.formControl.markAsTouched();
  }

  ngOnInit() {
    this.searchText
      .pipe(
        takeUntil(this.isDestroyed),
        debounceTime(500),
        distinctUntilChanged(),
        filter(text => text.length > 2),
        switchMap((searchValue, i) =>
          this.doSearch(searchValue)
        )
      ).subscribe(
        options => {
          this.searchOptions = options;
          this.formControl.updateValueAndValidity();
          this.log(this.formControl);
          this.showSearchOutput = true;
        }
      );
    this.getUserProperties();
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log("Setup realty input with def", typeDef);
    const def: RealtyTypeDef = typeDef as RealtyTypeDef;

    const validators: ValidatorFn[] = [];
    //this.options = def.options;

    this.formControl.setValidators(f => !this.propertyValid ? { notValid: true } : null);
    this.formControl.updateValueAndValidity();

  }

  handleData(data: Realty) {
    this.log('handleData', data);
    if (data?.property_id) {
      this.property = data;
      this.formControl.setValue(data.address);
      this.propertyValid = true;
      //this.onYourPropertyClick(data);
    }
  }

  getData() {
    return this.property;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }


  search(searchValue: string) {
    this.propertyValid = false;
    this.searchText.next(searchValue);
    if (searchValue.length < 3) {
      this.searchOptions = [];
    }
  }

  doSearch(searchValue: string): Observable<any> {
    this.log('doSearch', searchValue, this.isSearch)
    if (this.isSearch) {
      return this.realtyService.search(searchValue).pipe(
        catchError(err => of([]))
      );
    } else {
      this.setManualProperty();
      return Observable.of();
    }
  }

  setSearchProperty() {
    const formControl = this.el.nativeElement.querySelector('#formInput');
    formControl.focus();
    this.formControl.setValue('');
    this.isSearch = true;
    //this.search(this.formControl.value);
  }

  setManualProperty() {
    this.log('setManualProperty')
    this.isSearch = false;
    this.showSearchOutput = false;
    const formControl = this.el.nativeElement.querySelector('#formInput');
    formControl.focus();
    if (formControl.value.length > 8) {
      this.property = {
        address: formControl.value
      };

      this.propertyValid = true;
      this.log('setManualProperty', formControl.value);
      this.formControl.setValue(formControl.value);
      this.searchOptions = [];
      this.formControl.updateValueAndValidity();
      this.log(this.property);
    }

  }

  removeProperty() {
    this.property = undefined;
    this.formControl.setValue('');
    this.propertyValid = false;
  }

  onYourPropertyClick(property: Realty): void {
    this.log('onYourpropertyClick', property);

    // Check the property isn't already added
    let propCount = false;
    /*
    for (let userProperty of this.yourProperties) {
      this.log('Existing property: ' + userProperty.address_details.address + ' <> New Property: ' + property.address);
      if (userProperty.address_details.address === property.address) {
        this.log('Match: ' + userProperty.address + ' <> ' + property.address);
        this.onPropertyAlreadyAdded(property);
        propCount = true;
        return;
      }
    }
    */

    if (propCount === false) {
      // success put the property value
      this.realtyService.getRealty(property.property_id).subscribe(propertyObj => {
        this.log('getRealty', propertyObj);
        this.property = propertyObj;
        this.propertyValid = true;
        this.formControl.setValue(this.property.address);
        this.searchOptions = [];
        this.showSearchOutput = false;
        this.formControl.updateValueAndValidity();
      });

    }
  }

  onFocus() {
    this.log('focus');
  }

  onPropertyAlreadyAdded(property: Realty) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Realty already added',
        copy: 'It looks like you have already added this property:<br><br> ' + property.address,
        type: 'Realty',
        buttonMessage: 'Ok',
        acceptColor: 'primary',
        showCancel: false
      }
    });
  }

  getUserProperties() {
    this.realtyService.getUserRealties().subscribe(properties => {
      this.log('getUserProperties', properties);
      this.yourProperties = properties;
    });
  }
}


