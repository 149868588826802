import { AfterContentInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-horizontal-scroll-snap',
  templateUrl: './horizontal-scroll-snap.component.html',
  styleUrls: ['./horizontal-scroll-snap.component.scss'],
})
export class HorizontalScrollSnapComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() scrollToSection: Subject<string>;
  @Input() fullWidth: boolean;
  @Output() animFinished = new EventEmitter();
  scrollTarget: number = 0;
  animating = false;
  destroy = new Subject();
  log: any;
  ready: boolean;


  constructor(private logger: LoggerService) {
    this.log = this.logger.info('HorizontalScrollSnapComponent');

  }
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
  ngAfterContentInit(): void {
    this.setupSize('init');
  }

  ngOnInit(): void {
    this.setupSize('init');


    this.scrollToSection.pipe(takeUntil(this.destroy)).subscribe(section => {
      this.setupSize('init');
      // this.log('horizontalScrollSnap subscribe', this.scrollTarget);

      // this.log('value is changing', section);
      const containerElement = document.getElementById('horizontalScrollSnap');
      const targetElement = document.getElementById(section);

      const containerRect = containerElement.getBoundingClientRect();
      const targetRect = targetElement.getBoundingClientRect();

      const currentLeft = containerRect.left;
      const targetLeft = targetRect.left;

      const containerScrollLeft = containerElement.scrollLeft;

      const relativePosition = targetLeft - currentLeft;

      const leftTarget = ~~(relativePosition + containerScrollLeft);

      this.scrollTarget = leftTarget;

      const agent = window.navigator.userAgent.toLowerCase();
      const isIE = agent.indexOf('trident') > -1;

      if (isIE) {
        containerElement.scrollLeft = leftTarget;
        containerElement.scrollTop = 0;
      } else {
        containerElement.scrollTo({
          left: leftTarget,
          behavior: 'smooth',
        });
      }


    });

  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.setupSize('resize');
  }


  onWindowScroll(event) {

    // this.log('horizontalScrollSnap onWindowScroll', this.scrollTarget, event.target.scrollLeft);
    if (event.target.scrollLeft >= this.scrollTarget - 2 && event.target.scrollLeft <= this.scrollTarget + 2) {
      this.scrollTarget = event.target.scrollLeft;
      this.animFinished.next(true);
      this.animating = false;
    }
  }

  setupSize(origin) {
    const containerElement = document.getElementById('horizontalScrollSnap');
    containerElement.style.width = containerElement.clientWidth + 'px';
    //this.log('containerRect', origin, containerElement);
  }

}
