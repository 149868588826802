import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Guide, Category } from '../model/guide';

@Injectable()
export class GuideService {

  constructor(private http: HttpClient) {

  }

  /**
  * Fetch all guides.
  */
  public getGuides(): Observable<Array<Guide>> {
    return this.http.get<Array<Guide>>('https://rollitsuper.com.au/wp-json/wp/v2/posts?categories=24&per_page=50');
  }
  /**
  * Fetch all categories.
  */
  public getCategories(): Observable<Array<Category>> {
    return this.http.get<Array<Category>>('https://rollitsuper.com.au/wp-json/wp/v2/categories');
  }
}
