<div class="main-content-section">
  <div id="section-content" class="section-content">
    <app-top-menu></app-top-menu>

    <div class="top-navigation">
      <div class="back-button-content paragraph2" (click)="onReturnClick()"><i
          class="material-icons">arrow_back_ios_new</i> Back </div>
    </div>
    <div class="rollit-grid">

      <div class="rollit-grid-2">
        <div class="form-panel">

          <div *ngIf="connectedAccounts.length > 0 && gotAccounts === true" class="accounts">
            <div class="group-panel">

              <div class="heading heading2">Stop sharing accounts</div>


              <div class="item-holder" [ngClass]="{'desktop': isDesktop }">
                <div class="item-sub" *ngFor="let connectedAccount of connectedAccounts; trackBy: trackById">

                  <div class="paragraph2">{{connectedAccount.providerName}}</div>

                </div>
              </div>
              <div class="copy">
                <span class="paragraph1"><strong>Period </strong></span>
                <span *ngIf="yodleeProfile" class="paragraph1">{{yodleeProfile.startDate | date:'dd LLL yyyy'}} - {{yodleeProfile.endDate | date:'dd LLL yyyy'}}</span>
              </div>
              <div class="copy">
                <span class="paragraph1"> <strong>What happens to your data when you stop sharing it with
                    us?</strong></span>
                <span class="paragraph1">We will delete your data after you stop sharing it with us or close your
                  account.</span>
                <span class="paragraph1">Without this data, you will be limited in your ability to track your financial
                  wellness.</span>
              </div>
              <div class="copy">
                <span class="paragraph1"><strong> you consent to stop sharing this data?</strong></span>

              </div>
              <button (click)="stopSharing()" mat-flat-button
                class="new-account" color="primary">
                Yes, stop sharing
              </button>
            </div>
          </div>


        </div>


      </div>
      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button (click)="manageAccounts()" mat-flat-button class="new-account" color="primary">
            Add account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
