<div class="close"><i (click)="closeDialog()" class="material-icons">close</i></div>
<mat-dialog-content>
  <div *ngIf="profile" class="content">
    <h2>Change password</h2>
    <div class="subtitle">
        Change password for user {{profile.user?.username}}
    </div>
    
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit($event,formDirective)" id="passwordform" action="updatepassword" autocomplete="on" #formDirective="ngForm" >
        <div fxLayout="column">
          <span class="info"><i class="material-icons">info</i> Hint: password must be at least 8 characters</span>
          <span class="error" *ngIf="errorMessage.length > 0"><i class="material-icons">info</i> {{errorMessage}}</span>
          <input matInput type="text" name="username" hidden="true" [value]="profile.user.username" />

          <mat-form-field class="mat-form-field-full-width">
              <mat-label>Current Password</mat-label>
              <input matInput type="password" name="currentPassword" [formControl]="currentPassword" (focus)=onFocus($event)>
              <mat-error *ngIf="formGroup.controls['currentPassword'].hasError('required') && !incorrectCurrentPassword">
                  Field is required
              </mat-error>
              <mat-error *ngIf="formGroup.controls['currentPassword'].hasError('minlength') && !formGroup.controls['currentPassword'].hasError('required') && !incorrectCurrentPassword">
                  Must be at least 8 characters
              </mat-error>
              <mat-error *ngIf="incorrectCurrentPassword">
                Current password incorrect
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field-full-width">
                  <mat-label>New Password</mat-label>
                  <input matInput type="password" name="password" [formControl]="password" [errorStateMatcher]="errorStateMatcher">
                  <mat-error *ngIf="formGroup.controls['password'].hasError('required')">
                      Field is required
                  </mat-error>
                  <mat-error *ngIf="formGroup.controls['password'].hasError('minlength') && !formGroup.controls['password'].hasError('required')">
                      Must be at least 8 characters
                  </mat-error>
            </mat-form-field>

            <mat-form-field class="mat-form-field-full-width">
                  <mat-label>Confirm password</mat-label>
                  <input matInput type="password" name="password2" [formControl]="password2" [errorStateMatcher]="errorStateMatcher">
                  <mat-error *ngIf="formGroup.controls['password2'].hasError('required')">
                      Field is required
                  </mat-error>
                  <mat-error *ngIf="(formGroup.controls['password2'].hasError('notEquivalent') && !formGroup.controls['password2'].hasError('required')) || (formGroup.controls['password'].hasError('notEquivalent') && !formGroup.controls['password2'].hasError('required') )">
                      Passwords do not match
                  </mat-error>
            </mat-form-field>
        </div>
        <div class="button-container">
            <button mat-raised-button class="rollit-button" color="primary" type="submit" [disabled]="!formGroup.valid">SUBMIT</button>
        </div>
    </form>
  </div>
</mat-dialog-content>
