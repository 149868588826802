import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Budget, BudgetProgress, Income, LoggerService, MoneyAccount, MoneyService, TimeIntervalTable } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavingsService } from '../savings.service';


@Component({
  selector: 'app-savings',
  templateUrl: './savings.component.html',
  styleUrls: ['./savings.component.scss']
})
export class SavingsComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop = false;
  private isDestroyed = new Subject();
  sliding = false;
  sectionValue: Subject<string> = new Subject();
  totalAccountValue = 0;
  currentSlide = 0;
  accounts: MoneyAccount[];
  budgets: BudgetProgress;
  budget: any;
  activeRoute = 'property';
  projection: TimeIntervalTable;
  income: any;
  yodleeConnection: boolean;


  constructor(
    private savingsService: SavingsService,
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.log = this.logger.info('SavingsComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit() {

    // fetch budget progress
    this.savingsService.getBudgetProgress().subscribe(result => {
      this.log('Got budget progress', result);
      this.budgets = result;
    });

    // use this for income summary
    this.savingsService.getIncomeBudgets().subscribe(result => {
      this.log('Got income budget', result);
    });

    // use this for income details
    this.savingsService.getSummary().subscribe(result => {
      this.log('savings summary', result);
      this.totalAccountValue = result.balance;
      this.projection = result.projection;
      this.income = result.income;
      this.budget = result.budget;

    });

    this.savingsService.getSavingsProjections().subscribe(result => {
      this.log('getSavingsProjections', result);

    });

    this.getYodleeStatus();
  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  getYodleeStatus() {
    this.moneyService.getYodleeProfile().pipe(takeUntil(this.isDestroyed))
      .subscribe((result) => {
        this.log('getYodleeStatus', result);
        if (result.id) {
          this.yodleeConnection = true;
        }
      });
  }

  onBudgetEdit() {
    this.savingsService.startUpdateBudget();
  }

  onIncomeEdit() {
    this.savingsService.startUpdateIncome();
  }

  addNewGoal() {
    this.log('starting new goal service.');
    this.savingsService.startNewGoal();
  }
  manageAccounts() {

    this.router.navigate(['/accounts']);
  }



}
