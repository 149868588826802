<div class="savings-goal" [ngStyle]="{'border-top':'2px solid ' +sectionColor}">


  <div class="navigation" *ngIf="isDesktop && goalCount > 0">
    <div class="item prev paragraph2" [ngClass]="{'disabled': goalListId === 0 && inView}"
      (click)="goalListId !== 0 && inView ? scrollPrev() : false">
      <i class="material-icons">navigate_before</i> Previous
    </div>

    <div class="item next paragraph2" [ngClass]="{'disabled': goalListId  === goalCount && inView }"
      (click)="goalListId !== goalCount && inView ? scrollNext() : false">
      Next <i class="material-icons">navigate_next</i>
    </div>
  </div>

  <div class="savings-goal__completed" *ngIf="goal?.status">
    <!-- <i class="material-icons">bookmark</i> -->
  </div>

  <div class="heading heading2">{{ goal?.name }}</div>
  <div class="title paragraph1">{{goal?.category | titlecase}} goal</div>


  <div class="top-holder">
    <div class="savings-goal__upload" [ngStyle]="{ backgroundImage: 'url(' + imagePath + ')' }"></div>

    <div class="savings-goal__progress-bar">
      <app-progress-bar [progressSum]="progressSum" [targetSum]="targetSum"
        [section]="goal?.category">
      </app-progress-bar>
    </div>

  </div>

  <div *ngIf="isDesktop || showDetails" [@inOutAnimation]>

    <div class="bottom-holder">
      <div class="chart-details">
        <div class="heading-progress heading2">Goal progress</div>

        <div class="savings-chart-holder">
          <app-bar-chart-time *ngIf="chartData.length > 0 && showDetails  && inView" [seriesData]="chartData"
            [seriesCategories]="chartCategories" [title]="''" (graphClicked)="graphClicked($event[0], $event[1])"
            [timescale]="timescale" [showExpand]="false">
          </app-bar-chart-time>
        </div>
      </div>

      <div class="savings-goal__target-details">
        <app-title-group [details]="titleGroupData"></app-title-group>

      </div>


    </div>
    <div class="action-holder">
      <button mat-stroked-button *ngIf="!goal?.status" (click)="onEditClick()">
        <span class="paragraph2"> Edit </span>
      </button>
      <i class="material-icons" (click)="onDeleteClick()">delete</i>
    </div>
  </div>



  <div class="savings-goal__footer">
    <div *ngIf="!isDesktop" class="savings-goal__show-details paragraph2" (click)="detailsSwitch()">
      <ng-container *ngIf="!showDetails">Show more</ng-container>
      <ng-container *ngIf="showDetails">Show less</ng-container>
    </div>

  </div>
</div>
