import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TitleItemData } from '@rollit/shared/common';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SCSS_VARS } from '@rollit/shared';

interface PropertyInformation {
  userEstimateValue?: number;
  growthRate?: number;
  loanOutstanding?: number;
  interestRate?: number;
  repaymentAmount?: number;
  estimate?: number;
  estimateHigh?: number;
  estimateLow?: number;
}

@Component({
  selector: 'app-property-projections',
  templateUrl: './property-projections.component.html',
  styleUrls: ['./property-projections.component.scss']
})

export class PropertyProjectionsComponent implements OnInit {
  @Input() propertyInformation: PropertyInformation;
  @Input() projectionChartData: any = [];
  @Input() projectionChartCategories: any = [];
  @Input() projectedMortgageEndDate: any;
  @Input() inView: boolean;

  isDesktop: boolean;

  destroy = new Subject();
  log: any;
  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Age',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isNumber: true,
      hide: true
    },

    {
      title: 'Value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true
    },
    {
      title: 'Equity',
      data: new Date(),
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: true
    },
    {
      title: 'Mortgage',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isCurrency: true,
      hide: true
    }
  ];


  constructor(private logger: LoggerService, private uiService: UIManagerService,) {
    this.log = this.logger.info('PropertyProjectionsComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('ngOnInit projectionChartCategories', this.projectionChartCategories);
    this.log('ngOnInit projectedMortgageEndDate', this.projectedMortgageEndDate);
    this.log('ngOnInit projectionChartData', this.projectionChartData);
    this.log('ngOnInit propertyInformation', this.propertyInformation);
    this.log('ngOnInit inView', this.inView);
    this.setupTitleGroupDetails();
  }


  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.projectionChartCategories[0];
    this.titleGroupDetails[0]['hide'] = false;


    this.titleGroupDetails[1]['data'] = this.propertyInformation.userEstimateValue;
    this.titleGroupDetails[1]['hide'] = false;

    this.titleGroupDetails[2]['data'] = this.propertyInformation.loanOutstanding ? this.propertyInformation.userEstimateValue + this.propertyInformation.loanOutstanding : this.propertyInformation.userEstimateValue;
    this.titleGroupDetails[2]['hide'] = false;


    if (this.propertyInformation.loanOutstanding) {
      this.titleGroupDetails[3]['data'] = this.propertyInformation.loanOutstanding;
      this.titleGroupDetails[3]['hide'] = false;
    }
  }

  graphClicked(category, data): void {
    this.log(category, data[2]);

    this.titleGroupDetails[0]['data'] = category;

    this.titleGroupDetails[1]['data'] = data[0];

    this.titleGroupDetails[2]['data'] = data[2] ? data[2] : data[0];

    this.titleGroupDetails[3]['data'] = data[1];


  }

}
