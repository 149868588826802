import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from '@rollit/shared/common';
import { AppAuthGuardService } from '@rollit/shared/services';

import { Angulartics2Module } from 'angulartics2';
import { SubscriptionHomeComponent, PaymentFormComponent, SubscriptionCheckComponent, UpgradeFormComponent } from '@rollit/shared/subscription';

const appRoutes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full', data: { title: 'dashboard' }, canActivate: [AppAuthGuardService] },
  {
    path: 'subscription', component: SubscriptionHomeComponent, data: { title: 'Subscription' }, canActivate: [AppAuthGuardService]
  }, {
    path: 'signup', component: SubscriptionHomeComponent, data: { title: 'Subscription' }, canActivate: [AppAuthGuardService]
  }, {
    path: 'signup/pay',
    component: PaymentFormComponent,
    data: { title: 'Pay for access to Rollit' },
    canActivate: [AppAuthGuardService],
  }, {
    path: 'signup/subscription-check',
    component: SubscriptionCheckComponent,
    data: { title: 'Subscription Check' },
    canActivate: [AppAuthGuardService],
  }, {
    path: 'signup/upgrade',
    component: UpgradeFormComponent,
    data: { title: 'Upgrade your Rollit service' },
    canActivate: [AppAuthGuardService],
  }, {
    path: 'signup/upgrade/:employerId',
    component: UpgradeFormComponent,
    data: { title: 'Upgrade your Rollit service' },
    canActivate: [AppAuthGuardService],
  },
  { path: '404', component: PageNotFoundComponent, data: { title: 'Not Found' } },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, scrollPositionRestoration: 'enabled' } // <-- debugging purposes only
    ),
    Angulartics2Module.forRoot(),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
