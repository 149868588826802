import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-value',
  templateUrl: './asset-value.component.html',
  styleUrls: ['./asset-value.component.scss'],
})
export class AssetValueComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() change: string;

  constructor() {}

  ngOnInit(): void {}
}
