<div class='cashflow'>
  <lib-cashflow-expenses-stress-graphic [expensesStressResult]="expensesStressResult">
  </lib-cashflow-expenses-stress-graphic>

  <div class="info">
    <div class="chart">
      <lib-cashflow-expenses-chart [cashflowExpensesResult]="expensesStressAgePercentageResult"
        [cashflowExpensesPercent]="expensesStressAgePercentage" [graphicType]="1"></lib-cashflow-expenses-chart>
    </div>
    <div class="chart">
      <lib-cashflow-expenses-chart [cashflowExpensesResult]="assetsSavingsAgeAvgBalanceResult"
        [cashflowExpensesPercent]="assetsSavingsAgeAvgBalance" [graphicType]="2"></lib-cashflow-expenses-chart>
    </div>
    <div class="chart">
      <lib-cashflow-expenses-chart [cashflowExpensesResult]="assetsSavingsSpanAgePercentageResult"
        [cashflowExpensesPercent]="assetsSavingsSpanAgePercentage" [graphicType]="1"></lib-cashflow-expenses-chart>
    </div>

  </div>
