import { Component, Input, OnInit } from '@angular/core';
import { SCSS_VARS } from '@rollit/shared';
import { TitleItemData } from '@rollit/shared/common';
import { LoggerService, MoneyAccount } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-savings-projections',
  templateUrl: './savings-projections.component.html',
  styleUrls: ['./savings-projections.component.scss']
})
export class SavingsProjectionsComponent implements OnInit {

  @Input() projectionChartData: any;
  @Input() projectionChartCategories: any;
  @Input() balance: number;
  @Input() income: any;
  @Input() budget: any;

  isDesktop: boolean;
  careerBreakDays: any;

  destroy = new Subject();
  log: any;
  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Age',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isNumber: true,
      hide: true
    },

    {
      title: 'Annual income (net)',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true
    },
    {
      title: 'Savings balance',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: true
    },
    {
      title: 'Annual spending',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isCurrency: true,
      hide: true
    }
  ];


  constructor(private logger: LoggerService, private uiService: UIManagerService,) {
    this.log = this.logger.info('SavingsProjectionsComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {

    this.log('projectionChartCategories', this.projectionChartCategories);
    this.log('projectionChartData', this.projectionChartData);
    this.log('income', this.income);
    this.setupTitleGroupDetails();
    this.setupCareerBreak();
  }

  setupCareerBreak() {
    const startDate: any = new Date(this.income?.income_career_break_start);
    const endDate: any = new Date(this.income?.income_career_break_end);
    this.careerBreakDays = this.getFormatedStringFromDays(startDate, endDate);


  }

  getFormatedStringFromDays(d1, d2) {

    const numberOfDays = Math.round((d2 - d1) / (1000 * 60 * 60 * 24));

    const years = Math.floor(numberOfDays / 365);
    const months = Math.floor(numberOfDays % 365 / 30);
    const days = Math.floor(numberOfDays % 365 % 30);

    this.log("years = ", years);
    this.log("months = ", months);
    //console.log("weeks = ", week);
    this.log("days = ", days);


    const yearsDisplay = years > 0 ? years + (years === 1 ? " year " : " years ") : "";
    const monthsDisplay = months > 0 ? months + (months === 1 ? " month " : " months ") : "";
    const daysDisplay = days > 0 ? days + (days === 1 ? " day" : " days") : "";
    return yearsDisplay + monthsDisplay + daysDisplay;
  }

  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.projectionChartCategories[0];
    this.titleGroupDetails[0]['hide'] = false;

    this.titleGroupDetails[1]['data'] = this.budget.annual.totals.budget.income;
    this.titleGroupDetails[1]['hide'] = false;

    this.titleGroupDetails[2]['data'] = this.balance;
    this.titleGroupDetails[2]['hide'] = false;

    this.titleGroupDetails[3]['data'] = this.budget.annual.totals.budget.spending;
    this.titleGroupDetails[3]['hide'] = false;
  }

  graphClicked(category, data): void {
    this.log(category, data);
    this.titleGroupDetails[0]['data'] = category;
    this.titleGroupDetails[1]['data'] = data[0];
    this.titleGroupDetails[2]['data'] = data[2];
    this.titleGroupDetails[3]['data'] = data[1];
  }
}