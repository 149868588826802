<table mat-table [dataSource]="dataSource" class="table mat-elevation-z0">
  <ng-container *ngFor="let column of displayedColumns ; let i = index">
    <ng-container matColumnDef="{{ column }}">
      <th mat-header-cell *matHeaderCellDef class="table__header paragraph3">
        <span *ngIf="column !== 'Del'">{{ column }}</span>
        <span *ngIf="column === 'Del'"></span>
      </th>

      <td mat-cell *matCellDef="let element ; let j = index" class="table__values paragraph3">
        <span *ngIf="element[column] !== 'del'" title="{{ element[column] }}">{{ element[column] }}</span>
        <i *ngIf="element[column] === 'del'" class="delete material-icons" [id]="j" (click)="remove($event)"> delete
        </i>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
