import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfile } from '@rollit/shared/data';
import { debounceTime } from 'rxjs/operators';
import { NotificationService } from '@rollit/shared/data';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { MeService } from '@rollit/shared/data';
import { Employer, Employment } from '@rollit/shared/data';

declare const google;

@Component({
  selector: 'app-employer-information',
  templateUrl: './employer-information.component.html',
  styleUrls: ['./employer-information.component.scss']
})
export class EmployerInformationComponent implements OnInit {
  log: any;
  employerNameGroup: FormGroup;
  contactFirstNameGroup: FormGroup;
  contactLastNameGroup: FormGroup;
  contactEmailGroup: FormGroup;
  contactMobileGroup: FormGroup;
  abnGroup: FormGroup;
  employeeNumberGroup: FormGroup;
  addressGroup: FormGroup;

  inputsAreLocked = true;

  user: UserProfile;

  editMode = {
    name: false,
    firstName: false,
    lastName: false,
    email: false,
    mobile: false,
    abn: false,
    employeeNumber: false,
    address: false,
  };

  // Location services
  placesService;
  autocompleteService;

  // Options which are populated with autocomplete places
  autoCompleteOptions;

  // Make sure that when we programmatically update the location search input value we do not perform another auto-complete request
  autocompleteJustUpdated = false;

  // True when requesting autocomplete values
  autocompleteLoadingValues = false;

  constructor(private userService: MeService, private ngZone: NgZone, private notificationService: NotificationService,
    private cdr: ChangeDetectorRef, private dynamicScriptLoader: DynamicScriptLoaderService, private logger: LoggerService, ) {
    this.log = this.logger.info('employerInformationService');
  }

  ngOnInit() {
    this.userService.getProfile().subscribe(userProfile => {
      this.user = userProfile;
      this.user.employment = this.user.employment && this.user.employment.length > 0 ? this.user.employment : [];
      const employment: Employment = (this.user.employment && this.user.employment.length > 0)
        ? this.user.employment[0]
        : { employeeNumber: null, employer: null };
      if (!employment.employer) {
        employment.employer = {
          abn: null,
          contact: {},
          address: {}
        };
      } else {
        if (!employment.employer.contact) { // ensure a contact object is present
          employment.employer.contact = {};
        }
        if (!employment.employer.address) { // ensure an address object is present
          employment.employer.address = {};
        }
      }
      this.user.employment[0] = employment;
      this.setupForm();
    });
  }

  private loadGoogleMaps() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('googleMaps').then(data => {
      this.log('dynamicScriptLoader', data);
      this.log('google', google);
      setTimeout(() => this.setUpAddressLookup(), 100);
    }).catch(error => this.log(error));
  }

  setupForm() {
    const employment: Employment = this.user.employment[0];
    const employer: Employer = employment.employer;
    // If employer has an ID the employer is a registered one, so lock fields from being editable
    this.inputsAreLocked = !!employer.id;
    this.employerNameGroup = new FormGroup({
      employerName: new FormControl(employer.name, Validators.required)
    });
    this.contactFirstNameGroup = new FormGroup({
      contactFirstName: new FormControl(employer.contact.firstName, Validators.required)
    });
    this.contactLastNameGroup = new FormGroup({
      contactLastName: new FormControl(employer.contact.lastName, Validators.required)
    });
    this.contactEmailGroup = new FormGroup({
      contactEmail: new FormControl(employer.contact.email, Validators.required)
    });
    this.contactMobileGroup = new FormGroup({
      contactMobile: new FormControl(employer.contact.phone, Validators.required)
    });
    this.abnGroup = new FormGroup({
      companyABN: new FormControl(employer.abn, Validators.required)
    });
    this.employeeNumberGroup = new FormGroup({
      employeeNumber: new FormControl(employment.employeeNumber, Validators.required)
    });
    this.addressGroup = new FormGroup({
      // Non-standard control names for when autocomplete="off" doesn't work on some browsers
      a1: new FormControl(employer.address.street1, Validators.required),
      a2: new FormControl(employer.address.postcode, Validators.required),
      pc: new FormControl(employer.address.town, Validators.required),
      a3: new FormControl(employer.address.state, Validators.required)
    });
    this.loadGoogleMaps();
  }

  setUpAddressLookup() {
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.addressGroup.controls['a1'].valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (val && !this.autocompleteJustUpdated) {
        const autocompleteRequest = {
          input: val,
          types: ['address'],
          componentRestrictions: { country: 'au' }
        };
        this.autocompleteLoadingValues = true;
        this.autocompleteService.getPlacePredictions(autocompleteRequest, res => {
          // ngZone makes sure the autoCompleteOptions array change is picked up by angular, otherwise dropdown of options doesn't appear
          this.ngZone.run(() => {
            this.autocompleteLoadingValues = false;
            this.autoCompleteOptions = res;
          });
        });
      } else {
        this.autocompleteJustUpdated = false;
      }
    });
  }

  getAddressDetails(placeId: string) {
    const detailRequest = {
      placeId: placeId,
      fields: ['address_component']
    };
    // Get place address components using place id
    this.placesService.getDetails(detailRequest, response => {
      // Make sure angular knows about this callback running. Otherwise input the field's placeholders overlap their value
      this.ngZone.run(() => {
        if (response) {
          this.setAddressFormControls(response.address_components);
        }
      });
    });
  }


  setAddressFormControls(addressComponents) {
    if (addressComponents) {
      const address1 = this.addressGroup.controls['a1'];
      const address2 = this.addressGroup.controls['a2'];
      const address3 = this.addressGroup.controls['a3'];
      const postcode = this.addressGroup.controls['pc'];
      if (addressComponents.length >= 7) { // Make sure addressComponent[index] isn't 'undefined'
        address1.setValue(addressComponents[0].long_name + ' ' + addressComponents[1].long_name);
        address2.setValue(addressComponents[2].long_name);
        address3.setValue(addressComponents[4].long_name);
        postcode.setValue(addressComponents[6].long_name);
        this.autocompleteJustUpdated = true;
        this.autoCompleteOptions = []; // Empty autocomplete options
      } else {
        // Address components with a length smaller than 7 means building number is not present
        this.notificationService.error('Error', 'Please enter building number');
      }
    } else { this.notificationService.error('Error', 'Couldn\'t find that address'); }
  }

  onSubmit(submittedGroup: FormGroup, key: string) {
    if (submittedGroup.valid) {
      this.editMode[key] = false;
      this.cdr.detectChanges(); // Prevent ExpressionChangedAfterItHasBeenCheckedError
      const employment: Employment = this.user.employment[0];
      employment.employer.name = this.employerNameGroup.controls['employerName'].value
        ? this.employerNameGroup.controls['employerName'].value
        : null;
      employment.employer.contact.firstName = this.contactFirstNameGroup.controls['contactFirstName'].value
        ? this.contactFirstNameGroup.controls['contactFirstName'].value
        : null;
      employment.employer.contact.lastName = this.contactLastNameGroup.controls['contactLastName'].value
        ? this.contactLastNameGroup.controls['contactLastName'].value
        : null;
      employment.employer.contact.email = this.contactEmailGroup.controls['contactEmail'].value
        ? this.contactEmailGroup.controls['contactEmail'].value
        : null;
      employment.employer.contact.phone = this.contactMobileGroup.controls['contactMobile'].value
        ? this.contactMobileGroup.controls['contactMobile'].value
        : null;
      employment.employer.abn = this.abnGroup.controls['companyABN'].value
        ? this.abnGroup.controls['companyABN'].value
        : null;
      employment.employeeNumber = this.employeeNumberGroup.controls['employeeNumber'].value
        ? this.employeeNumberGroup.controls['employeeNumber'].value
        : null;
      employment.employer.address.street1 = this.addressGroup.controls['a1'].value
        ? this.addressGroup.controls['a1'].value
        : null;
      employment.employer.address.town = this.addressGroup.controls['a2'].value
        ? this.addressGroup.controls['a2'].value
        : null;
      employment.employer.address.postcode = this.addressGroup.controls['pc'].value
        ? this.addressGroup.controls['pc'].value
        : null;
      employment.employer.address.state = this.addressGroup.controls['a3'].value
        ? this.addressGroup.controls['a3'].value
        : null;

      const userProfile: UserProfile = this.user;
      if (!this.user.employment) {
        this.user.employment = [];
      }
      this.user.employment[0] = employment;
      this.userService.updateProfile(userProfile).subscribe(userProfileRes => {
        this.user = userProfileRes;
        this.setupForm();
      });
    }
  }

  focusInput(input) {
    // Timeout to allow input to setup
    setTimeout(() => input.focus(), 200);
  }
}
