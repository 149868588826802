import { Inject, Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Observable, Subscription, Subject } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

import { DialogService, Fact, NotificationService, Nudge, ValueType, YodleeProfile, YodleeService } from '@rollit/shared/data';
import { AccountMovement, AccountSource, AccountType, Balances, Budget, MoneyAccount, Period, Spending, Transaction, TransactionCategory, ProgressSummary } from '@rollit/shared/data';
import { ResultList, MoneyService, MeService, LoggerService } from '@rollit/shared/data';
import { Moment } from 'moment';
import * as moment_ from 'moment';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { Router } from '@angular/router';
const moment = moment_;

const currencyPipe = new CurrencyPipe('en-AU');

export function formatAud(value: number): string {
  return currencyPipe.transform(Math.abs(value), 'AUD', 'symbol-narrow', '1.0-0');
}

@Injectable()
export class TrackService {
  private log: any;
  private _isConnectedToMoneytree: boolean;
  private _yodleeProfile: YodleeProfile;
  // private router: ActivatedRoute;

  private _refreshSubject = new Subject<void>();
  private _refreshObervable = this._refreshSubject.asObservable();

  private sessionId: string;    // ID of the current dialog session.

  /**
   * Create a Moment from string values for year, month and day.
   *
   * @param year year in "YYYY" format.
   * @param month may be "MMMM", "MMM" or "DD MMM" when third parameter is not given.
   * @param day Day "DD" or nothing when month is "DD MMM".
   */
  static getMoment(year: string, month: string, day?: string): Moment {
    if (!day) {
      return moment(month + " " + year, ["DD MMM YYYY"]);
    }
    else {
      return moment(year + "-" + month + "-" + day, ["YYYY-MMMM-DD", "YYYY-MMM-DD"]);
    }
  }

  /**
   * Constructor
   */
  constructor(
    private moneyService: MoneyService,
    private yodleeService: YodleeService,
    private notificationService: NotificationService,
    // private messageService: MessageService,
    private userService: MeService,
    private router: Router,
    private dialogService: DialogService,
    private logger: LoggerService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.log = this.logger.info('trackService');
    // this.router = router;

    // listen for messages from the web service
    // this.messageService.messages$.subscribe(
    //   message => {
    //     if (message.type === 'track') {
    //       this.log('Track notification received.  Refresh data.');
    //       this._refreshSubject.next();
    //     }
    //   }
    // );

    this.userService.properties$.subscribe((props: any) => {
      if (props && props['moneytree']) {
        this._isConnectedToMoneytree = props.moneytree.status === 'Valid';
      }
    });

  }

  public getProgress(): Observable<ProgressSummary[]> {
    return this.moneyService.getProgressSummary();
  }

  public getYodleeProfile(): Observable<any> {
    if (!this._yodleeProfile) {
      return this.moneyService.getYodleeProfile().pipe(map(profile => {
        this._yodleeProfile = profile;
        return profile;
      }));
    }
    else {
      return of(this._yodleeProfile);
    }
  }

  /**
   * A service to determine whether the user has connected to Moneytree.
   */
  public isConnectedToMoneytree(): Observable<boolean> {
    this.log('isConnectedToMoneytree: ' + this._isConnectedToMoneytree);
    if (this._isConnectedToMoneytree == null) {
      return this.getYodleeProfile().pipe(mergeMap(profile => {
        this._isConnectedToMoneytree = profile != null;
        return of(this._isConnectedToMoneytree);
      }));
    }
    else {
      return of(this._isConnectedToMoneytree);
    }
  }

  public setConnectLater() {
    localStorage.setItem('connectLater', 'true');
  }

  public getConnectLater() {
    return localStorage.getItem('connectLater');
  }

  /**
   * User will get a persistemn observable and must un-subscribe when finished with it.
   */
  public getBalances(version?: string): Observable<Balances> {
    this.log('Fetching balances');
    return this.moneyService.getBalances(version);
  }

  public getMovement(tabIndex: number): Observable<AccountMovement> {
    const maxTransactions = 10;
    const timeFrames = this.getTimeFrameFromSelectedTab(tabIndex);
    return this.moneyService.getMovement(timeFrames.start, timeFrames.end, maxTransactions);
  }

  public getMovementString(date: string, selectedTab: number, currentYear: string, currentMonth: string): Observable<AccountMovement> {
    const maxTransactions = 10;
    const timeFrames = this.getTimeFrameFromString(date, selectedTab, currentYear, currentMonth);
    return this.moneyService.getMovement(timeFrames.start, timeFrames.end, maxTransactions);
  }

  public getSpendingString(date: string, selectedTab: number, currentYear: string, currentMonth: string): Observable<Spending> {
    const timeFrames = this.getTimeFrameFromString(date, selectedTab, currentYear, currentMonth);
    return this.moneyService.getSpending(timeFrames.start, timeFrames.end);
  }

  public getSpending(tabIndex: number): Observable<Spending> {
    const timeFrames = this.getTimeFrameFromSelectedTab(tabIndex);
    return this.moneyService.getSpending(timeFrames.start, timeFrames.end);
  }

  public getTransactions(offset?: number, max?: number): Observable<ResultList<Transaction>> {
    return this.moneyService.getTransactions(null, null, offset, max);
  }

  public getUncategorisedTransactions(offset?: number, max?: number): Observable<ResultList<Transaction>> {
    return this.moneyService.getTransactions(null, {categoryId: 1}, offset, max);
  }

  /**
   * Aquire an observable for refreshes to money data.
   */
  public get refresh$(): Observable<void> {
    return this._refreshObervable;
  }

  getTimeFrameFromSelectedTab(selectedTab: number): { start: Moment, end: Moment } {
    let timeframeStart: Moment;
    let timeframeEnd: Moment;
    switch (selectedTab) {
      case 0:
        timeframeStart = moment().startOf('isoWeek');   // start of monday
        timeframeEnd = timeframeStart.clone().add(1, 'week');
        break;
      case 1:
        timeframeStart = moment().startOf('month');
        timeframeEnd = timeframeStart.clone().add(1, 'month');
        break;
      case 2:
        timeframeEnd = moment().startOf('year');
        timeframeStart = timeframeEnd.clone().subtract(1, 'year');
        break;
      case 3:
        timeframeStart = moment().startOf('year');
        timeframeEnd = timeframeStart.clone().add(1, 'year');
        break;
    }
    return {
      start: timeframeStart,
      end: timeframeEnd
    };
  }

  public getTimeFrameFromString(date: string, selectedTab: number, currentYear: string, currentMonth: string): { start: Moment, end: Moment } {
    let timeframeStart: Moment;
    let timeframeEnd: Moment;
    this.log('date', date);
    this.log('selectedTab', selectedTab);
    switch (selectedTab) {
      case 0:
        const dayMonthStart = date.split(" - ")[0];
        timeframeStart = TrackService.getMoment(currentYear, dayMonthStart).startOf('week');
        timeframeEnd = timeframeStart.clone().endOf('week');
        break;
      case 1:
        timeframeStart = TrackService.getMoment(currentYear, date, '01').startOf('month');
        timeframeEnd = timeframeStart.clone().endOf('month');
        break;
      case 2:
        // this.log(dateString);
        timeframeStart = TrackService.getMoment(currentYear, 'January', '01').startOf('year');
        timeframeEnd = timeframeStart.clone().add(1, 'year');
        break;
      case 3:
        timeframeStart = moment(date).startOf('year');
        timeframeEnd = timeframeStart.clone().add(1, 'years');
        break;
    }
    return {
      start: timeframeStart,
      end: timeframeEnd
    };

  }


  /** ------------ manage accounts ------------ */

  public getAccounts(args?: { source?: AccountSource, type?: AccountType }) {
    return this.moneyService.getAccounts(args);
  }

  public createAccount(accountType: AccountType, name: string, balance: number): Observable<MoneyAccount> {
    const account: MoneyAccount = {
      accountType: accountType,
      nickname: name,
      currency: 'AUD',
      currentBalance: balance
    };

    return this.moneyService.createAccount(account).pipe(map(acc => {
      this.notificationService.info('Successfully added account');
      return acc;
    }));
  }

  public updateAccount(id: number, name: string, balance: number): Observable<MoneyAccount> {
    const account: MoneyAccount = {
      nickname: name,
      currentBalance: balance
    };
    return this.moneyService.updateAccount(id, account);
  }

  public updateAccountVisibility(id: number, visible: boolean): Observable<MoneyAccount> {
    const account: MoneyAccount = {
      visible: visible
    };
    return this.moneyService.updateAccount(id, account);
  }

  public removeAccount(id: number): Observable<void> {
    return this.moneyService.removeAccount(id).pipe(map(
      () => {
        this.notificationService.info('Account removed.');
        // TODO remove account from local list of accounts
      }
    ));
  }

  public getCategories(): Observable<TransactionCategory[]> {
    return this.moneyService.getCategories();
  }

  public updateTransaction(transactionId: number, transaction: Transaction): Observable<Transaction> {
    return this.moneyService.updateTransaction(transactionId, transaction);
  }

  public setBudget(period: Period, amount: number): Observable<Budget> {
    const budget = {
      period: period,
      currency: 'AUD',
      amount: amount
    };
    return this.moneyService.setBudget('spending', period, budget);
  }

  public onSynchroniseClick(): Subscription {
    return this.moneyService.refreshSourceData()
      .subscribe(data => {
        this.log('onSynchroniseClick');
        this._refreshSubject.next();
      });
  }

  /**
   * The UI must have a 'container-fastlink' element in the DOM.  This is where the FastLink
   * UI will be loaded.
   */
  public linkAccount(): any {
    this.yodleeService.connect();
  }

  /**
   * Sync feed data from Yodlee.
   * 
   * @returns 
   */
  public refreshSourceFeed(): Observable<any> {
    return this.moneyService.refreshSourceData();
  }

  /**
   * 
   * @param action 
   * @param params 
   */
  public performNudgeAction(nudge: Nudge) {
    const action = nudge.definition.action;
    const params = nudge.definition.params;
    this.log("performing nudge action", action, params);

    switch(action) {

      case "page.go":
        const path = params['path'];
        this.router.navigate([path]);
        break;
  
      case "dialog.start":
        // start a new survey
        const module = params['module'];
        const dialog = params['dialog'];
        const section = params['section'];
        const facts = [];
        if (nudge.account) {
          let account = nudge.account;
          // add account ID fact
          switch(account.accountType) {
            case AccountType.property:
              facts.push({ slug: 'assets_property_id', type: ValueType.Long, value: account.id, '@type': 'fact' });  // the property ID fact
              break;
            case AccountType.lifestyle:
              facts.push({ slug: 'assets_lifestyle_id', type: ValueType.Long, value: account.id, '@type': 'fact' });
              break;
            case AccountType.loan:
            case AccountType.creditcard:
                facts.push({ slug: 'debt_loan_id', type: ValueType.Long, value: account.id, '@type': 'fact' });  // the property ID fact
                break;
            case AccountType.mortgage:
              if (dialog == 'property') {
                let collateral = account.properties['collateral'];
                if (collateral) {
                  let propertyAccount = collateral[0];
                  facts.push({ slug: 'assets_property_id', type: ValueType.Long, value: propertyAccount.id, '@type': 'fact' });  // the property ID fact
                }
                else {
                  this.log("Could not launch property sirvey")
                  return;
                }
              }
              else {
                facts.push({ slug: 'debt_mortgage_id', type: ValueType.Long, value: account.id, '@type': 'fact' });
              }
              break;
            default:
              facts.push({ slug: 'account_id', type: ValueType.Long, value: nudge.account.id, '@type': 'fact' });
          }
        }
        this.startNewSurvey(module, dialog, facts, section);
        break;
  
      case "goal.new":
        // start new goal
        this.startAddGoal(params, nudge.account);
        break;

      case "bank_feed.connect":
        // start bank feed connection
        this.router.navigate(['accounts', 'manage-accounts']);
        break;

      // case "investment.account":
      //   break;
    
      default:
        // unhandled action
        this.log("unhandled action", action);
    }
  }

  private startAddGoal(params: any, account: MoneyAccount) {
    const dialog = "new";    // just a test dialog at this point

    this.log('starting new debt goal');
    const facts: Fact[] = [];
    

    // choose goal_type to match account type and subtype; 'debt_car', 'debt_creditcard', 'debt_personal', 'debt_other'
    if (params) {
      const goalCategory = params['goal_category'];
      let   goalType     = params['goal_type'];
      if (goalCategory) {
        facts.push({slug: 'goal_category', type: ValueType.String, value: 'debt', '@type': 'fact'});
      }
      if (!goalType) {    // determine goal type from goal category and account type
        if (goalCategory == 'debt' && account) {
          goalType = 'debt_other';
          if (account.type == AccountType.creditcard) {
            goalType = 'debt_creditcard';
          }
          else if (account.type == AccountType.loan) { 
            if (account.accountSubtype == 'vehicle_loan' || account.accountSubtype == 'vehicle') {
              goalType = 'debt_car';
            }
            else {
              goalType = 'debt_personal';
            }
          }
        }
      }
      if (goalType) {
        facts.push({slug: 'goal_type', type: ValueType.String, value: goalType, '@type': 'fact'});
      }
    }

    // add account id to facts
    if (account && account.id) {      // add account_id fact
      facts.push({slug: 'goal_account', type: ValueType.MoneyAccount, value: account, '@type': 'fact'});
    }
    
    this.startNewSurvey('goals', dialog, facts);
  }

  /**
   * Start a new dialog session.
   * 
   * @param module Dialog module
   * @param dialog Dialog slug.
   * @param facts initial facts to insert into the session.
   * @param section (optional) the section to show first. "_first" forces first section to show. If undefined, the earliest unanswered section is shown.
   */
   public startNewSurvey(module: string, dialog: string, facts: Fact[], section?: string) {
    this.sessionId = this.dialogService.generateSessionId();
    const returnPath = this.router.url;
    let queryParams = { 'session': this.sessionId, 'return': returnPath };
    if (section) {
      queryParams['start'] = section;
    }

    // initialise dialog with existing facts
    this.dialogService.initDialog(module, dialog, facts, this.sessionId).subscribe(result => {
      this.router.navigate(['survey', module, dialog], { queryParams });      // navigate to survey path
    });
  }

}
