import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileService, LoggerService, MeService, MoneyAccount, MoneyService, User } from '@rollit/shared/data';
import { ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvestmentsService } from '../../investments.service';
import { SCSS_VARS } from '@rollit/shared';
import * as moment_ from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ENVIRONMENT } from "@rollit/shared";
const moment = moment_;


@Component({
  selector: 'app-investments-hero',
  templateUrl: './investments-hero.component.html',
  styleUrls: ['./investments-hero.component.scss']
})
export class InvestmentsHeroComponent implements OnInit, OnDestroy {
  @Input() acc: any;
  @Input() propertyListId: number;
  @Input() propertyCount: number;
  @Input() investmentAccountCount: number;
  @Input() businessAccountCount: number;
  @Input() inView: boolean;
  @Output() clickNext = new EventEmitter();
  @Output() clickPrev = new EventEmitter();
  @Output() expandedProjections = new EventEmitter();

  log: any;
  expanded = false;
  destroy = new Subject();
  isDesktop: boolean;
  imagePath: string;
  me: User;

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Portfolio value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true
    },
    {
      title: 'Portfolio growth',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: true,
      additionalInfo: 'since last month'
    }

  ];

  projections: any;
  projectionChartData = [];
  projectionChartCategories = [];
  projectedMortgageEndDate: any;
  projectedMortgageEndAge: any;

  constructor(
    private logger: LoggerService,
    private dialog: MatDialog,
    private moneyService: MoneyService,
    private investmentsService: InvestmentsService,
    private fileService: FileService,
    private uiService: UIManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: MeService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.log = this.logger.info('InvestmentsHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });

    this.userService.me$.pipe(takeUntil(this.destroy)).subscribe(
      value => {
        if (value) {
          this.me = value;
          this.log(this.me);
        }
      }
    );
  }

  ngOnInit(): void {
    this.log('acc', this.acc);

    this.setupTitleGroupDetails();
    if (this.acc.accountSubtype === 'business') {
      this.setupProjections();
      this.setupPropertyImage();
    } else {
      this.setupProjectionsMulti();
      this.imagePath = "/assets/images/default/default_Investments.jpg";
    }

  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }


  setupPropertyImage() {

    // TODO: Handle multiple image, requires a carousel
    this.log('setupPropertyImage');
    this.moneyService.getAccountImages(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(accountImagesResult => {
      console.log('getAccountImages', accountImagesResult);
      if (accountImagesResult && accountImagesResult.length > 0) {
        this.log('if check', accountImagesResult);
        this.fileService.getFilePath(accountImagesResult[0]['id']).pipe(takeUntil(this.destroy)).subscribe(
          path => {
            this.imagePath = path;
          }
        );
      } else if (this.acc.accountSubtype === 'business') {
        this.imagePath = "/assets/images/default/default_start_a_business.jpg";
      } else {
        this.imagePath = "/assets/images/default/default_Investments.jpg";
      }
    });
  }

  setupTitleGroupDetails(): void {
    if (this.acc.accountSubtype === 'business') {

      this.moneyService.getAccountMovement(this.acc.id, moment().subtract(1, 'months'), moment()).pipe(takeUntil(this.destroy)).subscribe(movement => {
        this.log('movement', movement);

        this.titleGroupDetails[1]['title'] = 'Business growth';
        this.titleGroupDetails[1]['hide'] = false;
        this.titleGroupDetails[1]['data'] = movement;

      });

      this.titleGroupDetails[0]['title'] = 'Business value';
      this.titleGroupDetails[0]['data'] = this.acc.currentBalance;
      this.titleGroupDetails[0]['hide'] = false;




    } else {

      this.setupInvestmentGrowth();

      this.titleGroupDetails[0]['data'] = this.acc.currentBalance;
      this.titleGroupDetails[0]['hide'] = false;

      this.titleGroupDetails[1]['title'] = 'Portfolio growth';
      this.titleGroupDetails[1]['data'] = this.acc.growth;
      this.titleGroupDetails[1]['hide'] = false;
    }

  }

  setupInvestmentGrowth() {
    this.acc.accounts.forEach((accountVal, accountIndex) => {
      this.log('setupInvestmentGrowth accountVal', accountVal);
      this.moneyService.getAccountMovement(accountVal.id, moment().subtract(1, 'months'), moment()).pipe(takeUntil(this.destroy)).subscribe(movement => {
        this.log('setupInvestmentGrowth movement', movement);
        this.acc.growth += movement;
        this.log('setupInvestmentGrowth this.portfolioDetails.growth', this.acc.growth);

      });
    });
  }


  openSixPark() {
    window.open(this.environment.sixpark.landingUrl);
  }

  setupProjections() {
    this.investmentsService.getProjections(this.acc.id, 6).pipe(takeUntil(this.destroy)).subscribe(proj => {
      this.log('Got projections', proj);

      this.projections = proj;
      const projections = proj;

      const colorArray = [SCSS_VARS['$rollit-primary']];
      const chartCategoriesMap = [
        { short: 'INVT', section: 'investments', title: 'Investments' },
      ];
      const temp_array = [];

      projections.names.forEach((nameVal, nameIndex) => {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            this.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
          }
        });

        if (nameVal === 'Net') {
          temp_object['type'] = 'spline';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        // this.log(nameVal, nameIndex, colorArray[nameIndex]);

        projections.data.forEach((dataVal, dataIndex) => {
          this.projectionChartCategories.push(projections.data[dataIndex].label);
          temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
        });

        // this.log('temp_data_array', temp_data_array);
        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      // this.log('temp_array', temp_array);

      this.projectionChartData = temp_array;
      // this.log('this.chartData', this.projectionChartData);

    });
  }

  setupProjectionsMulti() {
    const temp_array = [];
    let getIndex = 0;
    this.acc.accounts.forEach((accountVal, accountIndex) => {

      this.investmentsService.getProjections(accountVal.id, 6).pipe(takeUntil(this.destroy)).subscribe(proj => {
        this.log('Got projections', proj, getIndex);

        this.projections = proj;
        const projections = proj;

        const colorArray = ['#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844', '#182844'];
        const chartCategoriesMap = [
          { short: 'INVT', section: accountVal.name, title: accountVal.name },
        ];


        projections.names.forEach((nameVal, nameIndex) => {
          const temp_object = {};
          const temp_data_array = [];

          temp_object['name'] = nameVal;
          chartCategoriesMap.forEach((chartVal, chartIndex) => {
            if (nameVal === chartVal.title) {
              // this.log(nameVal + '===' + chartVal.title);
              temp_object['section'] = chartVal.section;
            }
          });

          if (nameVal === 'Net') {
            temp_object['type'] = 'spline';
            temp_object['borderColor'] = 'white';
            temp_object['borderWidth'] = 0.2;
            temp_object['zIndex'] = 5;
          } else {
            temp_object['type'] = 'column';
          }
          temp_object['color'] = colorArray[getIndex];

          projections.data.forEach((dataVal, dataIndex) => {
            projections.data[dataIndex].values.forEach(element => {
            });
            this.projectionChartCategories.push(projections.data[dataIndex].label);
            temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
          });

          temp_object['data'] = temp_data_array;
          temp_array.push(temp_object);


        });
        if (getIndex === this.acc.accounts.length - 1) {
          this.projectionChartData = temp_array;
          this.log('this.projectionChartData', this.projectionChartData);

        }
        getIndex++;


      });

    });


  }


  // User actions

  graphClicked(category, name): void {
    this.log(category, name);
  }

  expandClicked() {
    this.expanded = !this.expanded;
    this.expandedProjections.emit(this.expanded);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  uploadComplete(file) {
    this.log('upload complete', file);
    this.moneyService.setAccountImages(this.acc.id, [file]).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('uploadComplete', result);
    });
  }

  startUpdateBusiness(accountId: number) {
    this.log('update business', accountId);
    this.investmentsService.startUpdateBusiness(accountId);
  }

  startUpdateInvestment(accountId: number) {
    this.log('update investment', accountId);
    if (accountId) {
      this.investmentsService.startUpdateInvestment(accountId); // only when edit clicked in context of an account.
    } else {
      this.investmentsService.startUpdateInvestment();    // investments edit has no account ID
    }
  }

  onAddInvestment() {
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/latest'], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    }).then(() => {
      this.investmentsService.startAddInvestment();
    });
  }


  onRemoveAccount() {
    this.log('Remove account clicked');
    this.openRemovePropertyConfirmationDialog(this.acc.id.toString());
  }

  openRemovePropertyConfirmationDialog(accountId: string) {
    this.log('Delete dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove account',
        copy: 'Are you sure you want to remove this account?',
        buttonMessage: 'Yes, remove account',
        type: 'Loan',
        showCancel: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result[0] === 'accept') {
        this.removeAccount(accountId);
      }
    });
  }

  removeAccount(propertyId: string): void {
    this.log('Remove property init');
    this.moneyService.removeAccount(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(account => {
      this.log('Property removed', account);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/investments']));
    });
  }

  scrollPrev() {
    this.clickPrev.next();
    this.log('clickPrev');
  }

  scrollNext() {
    this.clickNext.next();
    this.log('scrollNext');
  }


}
