import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { LifestyleAssetsComponent } from './components/lifestyle-assets.component';
import { LifestyleAssetsRoutingModule } from './lifestyle-assets-routing.module';
import { LifestyleAssetsService } from './lifestyle-assets.service';
import { LifestyleAssetsHeroComponent } from './components/lifestyle-assets-hero/lifestyle-assets-hero.component';
import { LifestyleAssetsProjectionsComponent } from './components/lifestyle-assets-projections/lifestyle-assets-projections.component';



@NgModule({
  declarations: [LifestyleAssetsComponent, LifestyleAssetsHeroComponent, LifestyleAssetsProjectionsComponent],
  imports: [
    CommonModule, RollitCommonModule, LifestyleAssetsRoutingModule
  ],
  providers: [
    LifestyleAssetsService
  ]
})
export class LifestyleAssetsModule { }
