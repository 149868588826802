import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployerService } from '@rollit/shared/data';
import { PaymentMethod } from '@rollit/shared/data';
import { Subscription, Observable } from 'rxjs';
import { Order } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { MatDialog } from '@angular/material/dialog';
import { PaymentComponent } from '../employer-billing/payment/payment.component';
import { Employer } from '@rollit/shared/data';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


@Component({
    selector: 'app-employer-billing',
    templateUrl: './employer-billing.component.html',
    styleUrls: ['./employer-billing.component.scss']
})
export class EmployerBillingComponent implements OnInit, OnDestroy {
    log: any;
    employer: Employer;
    employeeCount: number;
    paymentMethod: PaymentMethod;
    orders: Order[];
    displayedColumns: string[] = ['id', 'date', 'amount', 'status', 'download'];
    currentOrder: any;

    private _employerSubscription: Subscription;

    constructor(
        private employerService: EmployerService,
        private dialog: MatDialog,
        private logger: LoggerService,
        private http: HttpClient,
    ) {
        this.log = this.logger.info('employerBillingComponent');
    }

    ngOnInit(): void {
        this._employerSubscription = this.employerService.currentEmployer$().subscribe(value => {
            this.employer = value;
            this.log(this.employer);
            if (value) {
                this.paymentMethod = value.subscription ? value.subscription.paymentMethod : null;
                // this.employerService.getPaymentMethods(value.id).subscribe(result => {
                //     this.paymentMethods = result;
                // });
                this.employerService.getOrders(value.id).subscribe(result => {
                    this.log('got orders', result);
                    this.orders = result.data;
                    this.currentOrder = result.data[0];
                });
                this.employerService.getEmployeeCounts(value.id).subscribe(result => {
                    this.log('got employees', result);
                    this.employeeCount = result.active + result.inactive + result.notAccepted + result.notOnboarded;
                });
            }
        });
    }

    ngOnDestroy(): void {
        this._employerSubscription.unsubscribe();
    }

    trackById(index: number, item: any) {
        return item.id;
    }


    downloadPDF(url: string): Observable<any> {
        const httpOptions: Object = {
            responseType: 'blob'
        };
        return this.http.get(
            url, httpOptions)
            .pipe(
                tap(
                    // Log the result or error
                    data => console.log('You received data'),
                    error => console.log(error)
                )
            );
    }

    downloadInvoice(id: number) {
        document.execCommand('ClearAuthenticationCache', false);
        this.employerService.downloadInvoice(id).subscribe(result => {
            this.log(result);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                this.downloadPDF(result).subscribe(blob => {
                    this.log(blob);
                    const blobNew = new Blob([blob], { type: 'application/pdf' });
                    window.navigator.msSaveOrOpenBlob(blobNew, 'invoice.pdf');
                });
            } else {
                window.open(result);
            }
        });
    }



    editPaymentMethod() {
        this.log('this.employer.subscription', this.employer.subscription);
        const dialogRef = this.dialog.open(PaymentComponent, {
            panelClass: 'full-width-dialog',
            data: {
                employerId: this.employer.id,
                subscription: this.employer.subscription
            }
        }).afterClosed().subscribe(
            () => {
                this.employerService.getEmployer(this.employer.id).subscribe(
                    emp => {
                        this.employer.subscription = emp.subscription;
                        this.paymentMethod = emp.subscription.paymentMethod;
                    }
                );
            }
        );
    }

}
