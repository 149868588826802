/**
 * Result structures from the Rollit service.
 */

export interface Result<T> {
  status:  ResultStatus;
  data:    T;
}

export enum ResultStatus {
  OK = 'OK',
  Error = 'Error',
}

export interface ResultList<T> {
  total?: number;   // Total number of items that match request.
  offset?: number;  // The offset of the first item returned in the whole set.
  data: Array<T>;   // the data items
}

export interface Error {
  code: number;
  name: string;
  message: string;
  extra: string;
  cause: Error;
}
