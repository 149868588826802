import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIManagerService } from '@rollit/shared/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { LoggerService } from '@rollit/shared/data';
import { MeService } from '@rollit/shared/data';
import { UserProfile } from '@rollit/shared/data';

export interface MobilePromptDialogData {
  userProfile: UserProfile;
}

@Component({
  selector: 'mobile-prompt-dialog',
  templateUrl: './mobile-prompt-dialog.component.html',
  styleUrls: ['./mobile-prompt-dialog.component.scss']
})

export class MobilePromptDialogComponent implements OnDestroy {
  private isDestroyed = new Subject();
  isDesktop: Boolean;
  mobileForm: FormGroup;
  private log: any;
  formsCreated = false;
  userProfile: UserProfile;

  @ViewChild('formDirective') private formDirective: NgForm;

  constructor(
    public dialogRef: MatDialogRef<MobilePromptDialogComponent>,
    private uiService: UIManagerService,
    @Inject(MAT_DIALOG_DATA) private data: MobilePromptDialogData,
    private logger: LoggerService,
    private userService: MeService,
  ) {
    this.log = this.logger.info('MobilePromptDialogComponent');
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop =>
      this.isDesktop = isDesktop
    );
    this.userProfile = data.userProfile;
    this.log('data', data);
    this.setupForms();
  }

  setupForms() {
    this.mobileForm = new FormGroup({
      mobile: new FormControl(this.userProfile.phone ? this.userProfile.phone : '', [Validators.required, Validators.minLength(10)]),
    });

    this.mobileForm.valueChanges.subscribe(val => {
      this.log('formchanged', val);
      this.log('mobileForm change', this.mobileForm.valid);
    });

    this.formsCreated = true;

  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  onSubmit(formDirective: FormGroupDirective) {
    this.log('onCreateAccountSubmit', formDirective);
    const updatedUser = this.userProfile;
    updatedUser.phone = this.mobileForm.controls['mobile'].value;
    this.userService.updateProfile(updatedUser).subscribe(userRes => {
      this.log('userRes', userRes);
      this.userProfile = userRes;
      this.closeDialog();
    });
  }

  closeDialog(): void {
    this.dialogRef.close(this.userProfile);
  }


}
