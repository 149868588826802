import { Component, Renderer2, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { UIManagerService } from '@rollit/shared/services';
import { LoggerService } from '@rollit/shared/data';
import { takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { APP_ENVIRONMENT } from '@rollit/shared';

@Component({
  selector: 'drawer-navigation',
  templateUrl: './drawer-navigation.component.html',
  styleUrls: ['./drawer-navigation.component.scss']
})

export class DrawerNavigationComponent implements OnInit, OnDestroy {
  @ViewChild('drawerNavigation') drawerNavigation;
  log: any;
  isActive: Boolean = false;
  mediaSizeIsDesktop: Boolean = false;
  isHome: boolean;
  private isDestroyed = new Subject();

  /**
   * Constructor
   */
  constructor(
    private uiManagerService: UIManagerService,
    private renderer: Renderer2,
    private logger: LoggerService,
    private router: Router,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.log = this.logger.info('drawerNavigationComponent');

    this.uiManagerService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.mediaSizeIsDesktop = mediaSizeIsDesktop;
    });

    this.uiManagerService.drawerNavigationIsOpen$.pipe(takeUntil(this.isDestroyed)).subscribe((isOpen: boolean) => {
      this.toggleMenu(isOpen);
    });


    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const pathSplit = val.url.toString().split("/");
        const pageTitle = pathSplit[1];
        if (pageTitle === '') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      }
    });
  }

  ngOnInit() {
    //
  }

  ngOnDestroy() {
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  onCloseDrawerNavigation() {
    this.uiManagerService.drawerNavigationIsOpen = false;
  }

  toggleMenu(isActive: boolean) {
    this.isActive = isActive;
    if (this.isActive) {
      this.renderer.addClass(document.body, 'add-touch');
    } else {
      this.renderer.removeClass(document.body, 'add-touch');
    }
    return false;
  }

}
