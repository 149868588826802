import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppAuthGuardService } from '@rollit/shared/services';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { SubscriptionHomeComponent } from './subscription-home/subscription-home.component';
import { SubscriptionCheckComponent } from './subscription-check/subscription-check.component';
import { UpgradeFormComponent } from './upgrade-form/upgrade-form.component';

const routes: Routes = [{
    path: '',
    component: SubscriptionHomeComponent,
    data: { title: 'Your Subscription'},
    canActivate: [AppAuthGuardService],
  }, {
    path: 'pay',
    component: PaymentFormComponent,
    data: { title: 'Pay for access to Rollit'},
    canActivate: [AppAuthGuardService],
  }, {
    path: 'subscription-check',
    component: SubscriptionCheckComponent,
    data: { title: 'Subscription Check'},
    canActivate: [AppAuthGuardService],
  }, {
    path: 'upgrade',
    component: UpgradeFormComponent,
    data: { title: 'Upgrade your Rollit service'},
    canActivate: [AppAuthGuardService],
  }, {
    path: 'upgrade/:employerId',
    component: UpgradeFormComponent,
    data: { title: 'Upgrade your Rollit service'},
    canActivate: [AppAuthGuardService],
  }];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class SubscriptionRoutingModule {}
