import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, from, Observable, ReplaySubject } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { LoggerService } from '../other/logger.service';
import { DynamicScriptLoaderService } from '../other/dynamic-script-loader.service';

/**
 * Services for money tracking.  Includes budgets, money accounts, transactions.
 */

const TRACE = false;

declare var fastlink: any; // script for this is include in <head> for now


/**
 * Service to fetch money account information (data source via Moneytree).
 */
@Injectable()
export class YodleeService {
  log: any;
  apiUrl: string;

  private _statusSubject: ReplaySubject<any> = new ReplaySubject<any>(null);


  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private http: HttpClient,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('YodleeService');
    this.apiUrl = this.environment.apiUrl;
  }

  public status$() {
    return this._statusSubject.asObservable();
  }

  /**
   * Connect an institution
   */
  public connect() {
    this.getYodleeToken().subscribe(token => {
      this.loadFastlinkScript().subscribe(result => {
        this.openFastlink(token);
      });
    });
  }

  /**
 * Disconnect from an institution
 */
  public closeConnection() {
    this.log('close');
    fastlink.close();
  }

  private getYodleeToken(): Observable<string> {
    const path = '/me/money/yodlee/token';
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const options = {
      // headers,
      responseType: 'text' as 'json'
    };
    return this.http.get<string>(this.apiUrl + path, options);
  }

  /**
   * Ensure the Yodlee Fastlink script is loaded in the browser.
   * @returns 
   */
  private loadFastlinkScript(): Observable<any> {
    return from(
      this.dynamicScriptLoader.load('yodleeFastlink')
    );
  }

  /**
   * Launch the fastlink acount connection dialog
   * 
   * @param accessToken User's access token.
   */
  private openFastlink(accessToken: string) {
    const self = this;
    fastlink.open({
      fastLinkURL: this.environment.yodlee.fastlinkUrl,
      accessToken: 'Bearer ' + accessToken,
      forceIframe: true,
      params: {
        configName: 'Aggregation',

      },
      onSuccess: function (data) {
        // TODO handle connection
        console.log('success', data);
        self._statusSubject.next(data);
      },
      onError: function (data) {
        // TODO show error
        console.log('error', data);
        self._statusSubject.error(data);
      },
      onExit: function (data) {
        console.log('exit', data);
      },
      onEvent: function (data) {
        console.log('event', data);
      }
    },
      'container-fastlink');
  }
}
