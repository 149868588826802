<div class="main-content-section">
  <div id="section-content" class="section-content">
    <div class="form-panel">
      <div class="holder">

        <span class="heading heading1 section-color__lightest">Trusted, safe & secure</span>

        <img src="/assets/icons/trusted.svg">

        <span class="copy paragraph1 section-color__lightest">We use bank-grade security to keep your data private and
          protected at all times.</span>

        <button mat-flat-button class="rollit-button" [routerLink]="['/disclaimer']">Got it</button>

      </div>
    </div>
  </div>
</div>
