<div class="main-content-section">
  <div class="section-content" (scroll)="onElementScroll()">
    <app-top-menu></app-top-menu>

    <div class="rollit-grid">

      <app-section-title *ngIf="accounts.length  > 0" [title]="'Asset value'" [value]="totalAccountValue">
      </app-section-title>

      <div *ngIf="isDesktop && accounts.length > 0" class="rollit-grid-2">

        <app-horizontal-scroll-snap *ngIf="ready" [scrollToSection]="sectionValue" [fullWidth]="true"
          (animFinished)="animationCallback($event)">

          <section *ngFor="let account of accounts; let i = index">
            <app-lifestyle-assets-hero [inView]="currentViewSlide === i" [id]="account.id" [acc]="account"
              [propertyListId]="i" [propertyCount]="accounts.length - 1" (clickNext)="scrollNext()"
              (clickPrev)="scrollPrev()">
            </app-lifestyle-assets-hero>
          </section>

        </app-horizontal-scroll-snap>
      </div>

      <div *ngIf="!isDesktop && accounts.length > 0" class="rollit-grid-1">

        <ng-container *ngFor="let account of accounts">
          <app-lifestyle-assets-hero [id]="account.id" [inView]="expanded" [acc]="account" (clickNext)="scrollNext()"
            (clickPrev)="scrollPrev()" (expandedProjections)="expandedEvent($event,account.id)">
          </app-lifestyle-assets-hero>
        </ng-container>

      </div>


      <div *ngIf="accounts.length === 0 && ready" class="rollit-grid-2">
        <div class="group-panel blank">
          <span class="copy heading1">Lifestyle assets</span>

          <span class="copy paragraph1">Your lifestyle assets include items such as motorbikes, cars, caravans, boats
            and bicycles. Hey Coach will help you save for a new ride, or make your dream lifestyle asset purchase a
            reality. </span>

          <img class="image" src="/assets/images/example/lifestyle-example.png" />
        </div>
      </div>


      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button mat-stroked-button class="new-account" (click)="onAddAsset()">
            <span *ngIf="accounts && accounts.length > 0" class="paragraph1">Add another lifestyle asset</span>
            <span *ngIf="accounts && accounts.length === 0" class="paragraph1">Add a lifestyle asset</span>
          </button>
          <button mat-stroked-button class="launch-button" (click)="onFindCar()">
            <span class="paragraph1">Find my next car</span>
          </button>
        </div>

      </div>
    </div>

  </div>
</div>
