<div *ngIf="propertyEstimate" class="comparable-properties" [ngClass]="{'desktop': isDesktop}">
  <div class="heading2">Property market</div>


  <div class="comparable-properties-content">

    <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="comparableTabChange($event)"
      (click)="tabClick($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="title-top paragraph2">Sales</span>
        </ng-template>

        <div id="map0-{{property.property_id}}" class="map" [ngClass]="{'desktop': isDesktop}"></div>

        <div class="property-summary-list">
          <div *ngFor="let comparableProperty of propertyEstimate.comparable_sales; trackBy: trackById; let i = index"
            class="property-summary" id="row{{i}}" (mouseover)="highlightMarker(i)" (mouseout)="defaultMarker(i)">

            <div class="property-details">
              <span class="paragraph2">{{comparableProperty.address | titlecase }} </span>
              <span class="open-address paragraph3" (click)="addressClick(comparableProperty.address)">View property <i
                  class="material-icons paragraph3">launch</i></span>

              <div class="amenities">
                <span>
                  <i class="material-icons paragraph2">bathtub</i>
                  <span class="paragraph2">{{comparableProperty.bathrooms}}</span>
                </span>
                <span>
                  <i class="material-icons paragraph2">hotel</i>
                  <span class="paragraph2">{{comparableProperty.bedrooms}}</span>
                </span>
                <span><i class="material-icons paragraph2">directions_car</i>
                  <span class="paragraph2">{{comparableProperty.carparks}}</span>
                </span>
                <span>
                  <i class="material-icons paragraph2">zoom_out_map</i>
                  <span class="paragraph2">{{comparableProperty.landarea}}m2</span>
                </span>
              </div>
            </div>

            <div class="sale-price">
              <span class="paragraph2">{{comparableProperty.sale_price | currency:'':'symbol-narrow':"1.0"}}</span>
              <span class="sub-text paragraph2">Sale price</span>
              <span class="sub-text paragraph2">{{comparableProperty.sale_date | date:'MMM yyyy'}}</span>
            </div>

          </div>
          <div class="ids">
            <span class="paragraph3 section-color__secondary">&copy;2019 Insight Data Solutions Pty Limited ('IDS') and
              its suppliers and
              licensors.</span>
            <span class="paragraph3 section-color__secondary">Copyright in elements of this data may be held by
              Commonwealth, State and Local
              governments. All rights reserved. </span>
            <a class="paragraph3 section-color__secondary"
              href="https://about.rollitwealth.com/en/articles/3410254-ids-property-valuation-terms"
              target="_blank">Further Copyright notices and Disclaimers</a>
          </div>
        </div>

      </mat-tab>



      <mat-tab>
        <ng-template mat-tab-label>
          <span class="title-top paragraph2">Rentals</span>
        </ng-template>

        <div id="map1-{{property.property_id}}" class="map" [ngClass]="{'desktop': isDesktop}"></div>
        <div class="property-summary-list">
          <div
            *ngFor="let comparableProperty of propertyEstimate.comparable_rental_listings; trackBy: trackById; let i = index"
            class="property-summary" id="row{{i}}" (mouseover)="highlightMarker(i)" (mouseout)="defaultMarker(i)">

            <div class="property-details">
              <span class="paragraph2">{{comparableProperty.address | titlecase }} </span>
              <span class="open-address paragraph2" (click)="addressClick(comparableProperty.address)">View property <i
                  class="material-icons paragraph2">launch</i></span>

              <div class="amenities">
                <span>
                  <i class="material-icons paragraph2">bathtub</i>
                  <span class="paragraph2">{{comparableProperty.bathrooms}}</span>
                </span>
                <span>
                  <i class="material-icons paragraph2">hotel</i>
                  <span class="paragraph2">{{comparableProperty.bedrooms}}</span>
                </span>
                <span><i class="material-icons paragraph2">directions_car</i>
                  <span class="paragraph2">{{comparableProperty.carparks}}</span>
                </span>
                <span>
                  <i class="material-icons paragraph2">zoom_out_map</i>
                  <span class="paragraph2">{{comparableProperty.landarea}}m2</span>
                </span>
              </div>
            </div>

            <div class="sale-price">
              <span class="paragraph2">{{comparableProperty.rental_price | currency:'':'symbol-narrow':"1.0"}}</span>
              <span class="sub-text paragraph2">Rental price</span>
              <span class="sub-text paragraph2">{{comparableProperty.sale_date | date:'MMM yyyy'}}</span>
            </div>

          </div>
          <div class="ids">
            <span class="paragraph3">&copy;2019 Insight Data Solutions Pty Limited ('IDS') and its suppliers and
              licensors.</span>
            <span class="paragraph3">Copyright in elements of this data may be held by Commonwealth, State and Local
              governments. All rights reserved. </span>
            <a class="paragraph3" href="https://about.rollitwealth.com/en/articles/3410254-ids-property-valuation-terms"
              target="_blank">Further Copyright notices and Disclaimers</a>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>


  </div>
</div>
