import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-financial-security-graphic',
  templateUrl: './financial-security-graphic.component.html',
  styleUrls: ['./financial-security-graphic.component.scss']
})
export class FinancialSecurityGraphicComponent implements OnInit {
  @Input() assetsSuperConcernResult: string;
  @Input() assetsInvestmentPortfolioResult: string;
  @Input() assetsSuperAgeAvgBalanceResult: string;
  @Input() assetsSuperAgeAvgBalance: number;
  constructor() { }

  ngOnInit() {
  }

}
