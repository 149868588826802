import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';

export interface BudgetElement {
  name: string;
  budget: number;
  current: number;
  color: string;
  items: Array<{ title: string; value: number }>;
}

@Component({
  selector: 'app-budget-element',
  templateUrl: './budget-element.component.html',
  styleUrls: ['./budget-element.component.scss'],
})
export class BudgetElementComponent implements OnInit {
  percentage: number;
  log: any;
  @Input() budget: BudgetElement = {
    name: 'Income',
    budget: 3000,
    current: 2000,
    color: '#db0549',
    items: [
      { title: 'Eating out', value: 120 },
      { title: 'Entertainment', value: 80 },
    ],
  };

  constructor(private logger: LoggerService) {
    this.log = this.logger.info('BudgetElementComponent');
  }

  ngOnInit(): void {
    this.setBudget();
  }

  setBudget() {
    const initialBudget = (this.budget.current / this.budget.budget) * 100;
    this.log('initialBudget', this.budget.name, initialBudget);
    if (initialBudget > 100) {
      this.percentage = 100;
    } else if (initialBudget < 0) {
      this.percentage = 0;
    } else {
      this.percentage = initialBudget;
    }
  }
}
