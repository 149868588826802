import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileListAndDetailComponent } from './profile-list-and-detail/profile-list-and-detail.component';
import { ProfileLinksComponent } from './profile-links/profile-links.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { EmployerInformationComponent } from './employer-information/employer-information.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';
import { AppAuthGuardService } from '@rollit/shared/services';
import { AboutComponent } from '@rollit/shared/info';
//import { ChoiceFundComponent } from '@rollit/shared/common';
//import { ActionsConnectMoneytreeComponent } from '@rollit/shared/actions';
import { SubscriptionInformationComponent } from './subscription-information/subscription-information.component';

const profileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileListAndDetailComponent,
    data: {
      title: 'Profile',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    canActivate: [AppAuthGuardService],
    children: [
      { path: 'profile-links', component: ProfileLinksComponent, outlet: 'list' },
      { path: 'none', component: ProfileLinksComponent, outlet: 'detail' },
      { path: 'subscription-information', component: SubscriptionInformationComponent, outlet: 'detail', data: { title: 'Subscription' } },
      { path: 'personal-information', component: PersonalInformationComponent, outlet: 'detail', data: { title: 'Personal Information' } },
      { path: 'employer-information', component: EmployerInformationComponent, outlet: 'detail', data: { title: 'Employer Information' } },
      //{ path: 'super-information', component: SuperFundInformationComponent, outlet: 'detail', data: { title: 'Super Information' } },
      { path: 'change-password', component: ChangePasswordComponent, outlet: 'detail', data: { title: 'Change Password' } },
      { path: 'about-information', component: AboutComponent, outlet: 'detail', data: { title: 'About' } },
      //{ path: 'choice-form', component: ChoiceFundComponent, outlet: 'detail', data: { title: 'Choice of Fund Form' } },
      //{ path: 'moneytree', component: ActionsConnectMoneytreeComponent, outlet: 'detail', data: { title: 'Manage Moneytree' } },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(profileRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProfileRoutingModule { }
