<div class='home'>

  <div class="info">
    <div class="chart">
      <lib-home-situation-ownership-graphic [assetsHomeOwnershipPercentage]="assetsHomeOwnershipPercentage"
        [assetsHomeOwnershipResult]="assetsHomeOwnershipResult">
      </lib-home-situation-ownership-graphic>
    </div>
    <div class="chart">
      <lib-home-situation-age-percentage-chart
        [assetsHomeOwnershipAgePercentageResult]="assetsHomeOwnershipAgePercentageResult"
        [assetsHomeOwnershipAgePercentage]="assetsHomeOwnershipAgePercentage">
      </lib-home-situation-age-percentage-chart>
    </div>
    <div class="chart">
      <lib-home-situation-state-percentage-chart
        [assetsHomeOwnershipStatePercentageResult]="assetsHomeOwnershipStatePercentageResult"
        [assetsHomeOwnershipStatePercentage]="assetsHomeOwnershipStatePercentage"
        [profileAddressState]="profileAddressState">
      </lib-home-situation-state-percentage-chart>
    </div>
  </div>
</div>
