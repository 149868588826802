<div class='longterm'>
  <div class="description">

    <lib-financial-security-graphic [assetsSuperConcernResult]="assetsSuperConcernResult"
      [assetsInvestmentPortfolioResult]="assetsInvestmentPortfolioResult"
      [assetsSuperAgeAvgBalanceResult]="assetsSuperAgeAvgBalanceResult"
      [assetsSuperAgeAvgBalance]="assetsSuperAgeAvgBalance">
    </lib-financial-security-graphic>
  </div>
  <div class="info">
    <div class="chart">
      <lib-financial-security-barchart [financialSecurityPercent]="assetsInvestmentPropertyAgePercentage"
        [financialSecurityCopy]="assetsInvestmentPropertyAgePercentageResult">
      </lib-financial-security-barchart>
    </div>
    <div class="chart">
      <lib-financial-security-barchart [financialSecurityPercent]="assetsInvestmentSharesAgePercentage"
        [financialSecurityCopy]="assetsInvestmentSharesAgePercentageResult">
      </lib-financial-security-barchart>
    </div>
    <div class="chart">
      <lib-financial-security-barchart [financialSecurityPercent]="assetsInvestmentBusinessAgePercentage"
        [financialSecurityCopy]="assetsInvestmentBusinessAgePercentageResult">
      </lib-financial-security-barchart>
    </div>
  </div>
</div>
