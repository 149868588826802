

<div *ngIf="goals.length === 0" class="rollit-grid">
  <div class="rollit-grid-2">
    <div class="group-panel blank">
      <span class="copy heading1">Goal setting</span>
    
      <span class="copy paragraph1">Financial goals are an important step toward becoming financially secure and building wealth for the future. Hey Coach helps you create short and long-term financial goals and track your progress towards meeting those goals. </span>
    
      <img class="image" src="/assets/images/example/goal-example.png" />
    </div>
  </div>
  <div class="rollit-grid-1">
    <div class="sundry-holder">
      <button mat-stroked-button class="new-account" (click)="createNewGoal(null)">
        <span class="paragraph1">Set new goal</span>
      </button>
    </div>
  </div>
</div>





<div *ngIf="goals.length > 0" class="rollit-grid">

  <div class="rollit-grid-3">
    <app-breadcrumb *ngIf="showCompleted"></app-breadcrumb>
  </div>

  <div *ngIf="isDesktop" class="rollit-grid-2">
    <app-horizontal-scroll-snap *ngIf="ready" [scrollToSection]="sectionValue" [fullWidth]="true"
      (animFinished)="animationCallback($event)">
      <section *ngFor="let goal of goals; let i = index">

        <app-savings-goal [inView]="currentViewSlide === i" [id]="goal.id" [goal]="goal"
          (detailsOpened)="loadDetails($event)" (editGoal)="editGoal($event)" (removeGoal)="removeGoal($event)"
          colour="primary" [goalListId]="i" [goalCount]="goals.length - 1" (clickNext)="scrollNext()"
          (clickPrev)="scrollPrev()">
        </app-savings-goal>

      </section>
    </app-horizontal-scroll-snap>
  </div>

  <div *ngIf="!isDesktop" class="rollit-grid-1">
    <ng-container *ngFor="let goal of goals">

      <app-savings-goal [id]="goal.id" [inView]="true" [goal]="goal" (detailsOpened)="loadDetails($event)"
        (editGoal)="editGoal($event)" (removeGoal)="removeGoal($event)" (clickNext)="scrollNext()"
        (clickPrev)="scrollPrev()" (expandedProjections)="expandedEvent($event)">
      </app-savings-goal>

    </ng-container>
  </div>


  <!-- <div class=" rollit-grid-2">
    <div class="goal-holder">
      <div class="goal" *ngFor="let goal of goals">
        <app-savings-goal [goal]="goal" (detailsOpened)="loadDetails($event)" (editGoal)="editGoal($event)"
          (removeGoal)="removeGoal($event)" colour="primary">
        </app-savings-goal>
      </div>
    </div>

  </div> -->

  <div *ngIf="!showCompleted" class="rollit-grid-1">
    <div class="sundry-holder">
      <button mat-stroked-button class="new-account" (click)="createNewGoal(null)">
        <span class="paragraph1">Set new goal</span>
      </button>
    </div>
  </div>


  <!-- <div *ngIf="!showCompleted" class="rollit-grid-3">
    <div [routerLink]="['/coach/goals','history']" class="link paragraph2">Goal history
    </div>
  </div> -->

</div>
