import { Observable } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { APP_ENVIRONMENT } from "@rollit/shared";
import { Moment } from "moment";
import { PeriodPay, PeriodShares, Benefit } from '../model/benefits';
import { Period } from '../model/money';





@Injectable()
export class PayAndBenefitsService {
    apiUrl = this.environment.apiUrl;
    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private environment: any) { }

    /**
     * Fetch user's pay from an employer for a period of time.
     *
     * @param employerId the employer
     * @param from date from
     * @param to date to.
     */
    getPay(employerId: number, from: Moment, to: Moment): Observable<PeriodPay> {
        const path = '/me/benefits/pay';
        let params = new HttpParams();
        if (employerId !== null) {
            params = params.set('employer', '' + employerId);
        }
        if (from !== null) {
            params = params.set('from', from.toISOString());
        }
        if (to != null) {
            params = params.set('to', to.toISOString());
        }

        return this.http.get<PeriodPay>(this.apiUrl + path, { params: params });
    }

    getPayHistory(employerId: number, from: Moment, to: Moment, interval: Period): Observable<PeriodPay[]> {
        const path = '/me/benefits/pay-history';
        let params = new HttpParams();
        if (employerId !== null) {
            params = params.set('employer', '' + employerId);
        }
        if (from !== null) {
            params = params.set('from', from.toISOString());
        }
        if (to != null) {
            params = params.set('to', to.toISOString());
        }
        if (interval != null) {
            params = params.set('interval', '' + interval);
        }

        return this.http.get<PeriodPay[]>(this.apiUrl + path, { params: params });
    }

    getShares(employerId: number, from: Moment, to: Moment): Observable<PeriodShares> {
        const path = '/me/benefits/shares';
        let params = new HttpParams();
        if (employerId !== null) {
            params = params.set('employer', '' + employerId);
        }
        if (from !== null) {
            params = params.set('from', from.toISOString());
        }
        if (to != null) {
            params = params.set('to', to.toISOString());
        }

        return this.http.get<PeriodShares>(this.apiUrl + path, { params: params });
    }

    getBenefits(employerId: number, from: Moment, to: Moment): Observable<Benefit[]> {
        const path = '/me/benefits/benefits';
        let params = new HttpParams();
        if (employerId !== null) {
            params = params.set('employer', '' + employerId);
        }
        if (from !== null) {
            params = params.set('from', from.toISOString());
        }
        if (to != null) {
            params = params.set('to', to.toISOString());
        }

        return this.http.get<Benefit[]>(this.apiUrl + path, { params: params });
    }

}
