import { Observable, ReplaySubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { ResultList } from '../model/result';
import { Order, Subscription } from '../model/subscription';
import { PaymentMethod, PaymentDetails } from '../model/payment';
import { from } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { LoggerService } from '../other/logger.service';
import { Moment } from 'moment';
import { map, filter } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';
import { Employer, Employee, EmployeeCounts } from '../model/employer';
import { SortDirection } from '@angular/material/sort';



@Injectable()
export class EmployerService {
    log: any;
    _employerSubject = new ReplaySubject<Employer>(1);
    apiUrl = this.environment.apiUrl;

    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private http: HttpClient,
        private keycloak: KeycloakService,
        private subscriptionService: SubscriptionService,
        private logger: LoggerService
    ) {
        this.log = this.logger.info('employerService');
    }

    /**
     * Get the employers administered by the current user.
     */
    getEmployeesManagedByMe(): Observable<Employer[]> {
        const path = this.apiUrl + '/me/employer';

        return this.http.get<Employer[]>(path);
    }

    /**
     * Choose the employer to administer
     */
    set currentEmployer(value: Employer) {
        this._employerSubject.next(value);
    }

    /**
     * Observer on selected employer for administering.
     */
    currentEmployer$(): Observable<Employer> {
        return this._employerSubject.asObservable();
    }

    /**
     * Get an employer
     */
    getEmployer(id: number): Observable<Employer> {
        const path = this.apiUrl + '/employer/' + id;

        return this.http.get<Employer>(path);
    }

    /**
     *  Fetch employees for an employer.
     * @param employerId The employer ID
     * @param query A string to match users' name against
     * @param sortField  User field to sort on, firstName, lastName, username, phone, ...
     * @param sortDire  asc or desc.
     * @param offset The first result to get.  Used for paging through results.
     * @param max The maximum number of results.
     */
    getEmployees(employerId: number, query: string, sortField?: string, sortDir?: SortDirection, offset = 0, max = 100): Observable<ResultList<Employee>> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee';

        let params = new HttpParams()
            .set('offset', String(offset))
            .set('max', String(max));
        if (query) {
            params = params.set('q', query);
        }
        if (sortField) {
            params = params.set('sortField', sortField);
        }
        if (sortDir) {
            params = params.set('sortDir', sortDir);
        }

        return this.http.get<ResultList<Employee>>(path, { params: params });
    }


    getEmployeeCounts(employerId: number): Observable<EmployeeCounts> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee-count';

        return this.http.get<EmployeeCounts>(path);
    }

    /**
     * Onboard the employees.
     */
    putEmployees(employerId: number, employees: Employee[]): Observable<Employee[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/employee';

        return this.http.post<Employee[]>(path, employees);
    }

    removeEmployee(employerId: number, employeeId: number): Observable<void> {
        this.log(employeeId);
        const path = this.apiUrl + '/employer/' + employerId + '/employee/' + employeeId;
        this.log(path);
        return this.http.delete<void>(path);
    }

    getEmployeeSavings(employerId: number, from: Moment, to: Moment): Observable<number> {
        const path = this.apiUrl + '/employer/' + employerId + '/reward-savings';
        let params = new HttpParams();
        if (from) {
            params = params.set('from', from.toISOString());
        }
        if (to) {
            params = params.set('to', to.toISOString());
        }

        return this.http.get<number>(path, { params: params });
    }

    /**
     * Fetch the orders associated with the employer.
     */
    getOrders(employerId: number): Observable<ResultList<Order>> {
        const path = this.apiUrl + '/employer/' + employerId + '/order';

        return this.http.get<ResultList<Order>>(path);
    }

    getPaymentMethods(employerId: number): Observable<PaymentMethod[]> {
        const path = this.apiUrl + '/employer/' + employerId + '/payment/method';
        return this.http.get<PaymentMethod[]>(path);
    }

    createPaymentMethod(employerId: number, paymentDetails: PaymentDetails): Observable<PaymentMethod> {
        const path = this.apiUrl + '/employer/' + employerId + '/payment/method';
        return this.http.post<PaymentMethod>(path, paymentDetails);
    }

    setSubscriptionPaymentMethod(subscriptionId: number, paymentMethod: PaymentMethod): Observable<Subscription> {
        const path = this.apiUrl + '/subscription/' + subscriptionId + '/payment-method';
        return this.http.put<Subscription>(path, paymentMethod);
    }

    downloadInvoice(id: number): Observable<string> {
        return from(
            this.keycloak.getToken().then((token) => {
                return this.apiUrl + '/order/' + id + '.pdf?access_token=' + token;
            })
        );
    }

    /**
     * Get credential summary for particular app.
     * @param employeeId 
     * @param appName 
     */
    getCredential(employerId: number, appName: string): Observable<any> {
        const path = this.apiUrl + '/employer/' + employerId + '/credential/' + appName;
        return this.http.get<any>(path);
    }

    /**
     * Checks whether user has at least one of the given named features.
     * @param names Feature names
     */
    public hasSomeFeature$(names: string[]): Observable<boolean> {

        return this.currentEmployer$().pipe(
            map(employer => {
                if (!employer || !employer.subscription) {
                    return null;
                }
                return this.subscriptionService.hasSomeFeatures(names, employer.subscription.features);
            }),
            filter(val => {
                return val !== null;
            })
        );
    }

    /**
     * Checks whether user has all of the given named features.
     * 
     * @param names Feature names
     */
    public hasAllFeature$(names: string[]): Observable<boolean> {

        return this.currentEmployer$().pipe(
            map(employer => {
                if (!employer || !employer.subscription) {
                    return null;
                }
                return this.subscriptionService.hasAllFeatures(names, employer.subscription.features);
            }),
            filter(val => {
                return val !== null;
            })
        );
    }
}
