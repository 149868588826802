<mat-form-field class="input-text" appearance="outline">
  <mat-label *ngIf="prompt" class="paragraph1">{{prompt}}</mat-label>

  <input matInput [type]="type" [formControl]="formControl" (keyup)="update()" [placeholder]="placeholder"
    maxlength="255" />
  <mat-error>
    {{validationMessage}}
  </mat-error>
</mat-form-field>
<div class="description paragraph2" [ngClass]="{'error':!isValid,'single': !multi}">{{ description }}</div>
