<div class="rollit-grid">
  <div class="rollit-grid-2">

    <div class="group-panel chart">
      <div class="heading1" >Your financial projections</div>
      <app-bar-chart-time *ngIf="chartData.length > 0" [seriesData]="chartData" [seriesCategories]="chartCategories"
         (graphClicked)="graphClicked($event[0], $event[1], $event[2])"
        [showExpand]="false">
      </app-bar-chart-time>

      <div class="toggle-holder">
      <span>Annual</span> <mat-slide-toggle (toggleChange)="toggleChange()" [disabled]="toggleDisabled"></mat-slide-toggle> <span>Lifetime</span>
      </div>
      <div class="anchor-container">
        <a [href]="disclaimerUrl" target="_about" class="paragraph2">Projections disclaimer</a>
      </div>

    </div>
  </div>

  <div class="rollit-grid-1">
    <div class="group-panel">
      <app-title-group [details]="titleGroupDetails"></app-title-group>

      <div class="details">


        <div class="detail-list" *ngIf="gotFirstProjection">

          <ng-container *ngFor="let account of chartData;let i = index; trackBy: trackById;">

            <div *ngIf="i === 0" class="list-item">
              <span class="paragraph2">Assets</span>
              <span
                class="paragraph2">{{chartData[0].data[currentIndex] + chartData[1].data[currentIndex] + chartData[2].data[currentIndex] + chartData[3].data[currentIndex] + chartData[4].data[currentIndex]  | currency:'AUD': 'symbol' : '1.0-0'}}</span>
            </div>

            <div *ngIf="i === 5" class="list-item">
              <span class="paragraph2">Borrowings</span>
              <span
                class="paragraph2 section-color__negative">{{chartData[5].data[currentIndex] + chartData[6].data[currentIndex] + chartData[7].data[currentIndex] | currency:'AUD': 'symbol' : '1.0-0'}}</span>
            </div>

            <div *ngIf="i === 8" class="list-item">
              <span class="paragraph2">Net Wealth</span>
              <span
                class="paragraph2 section-color__growth">{{account.data[currentIndex] | currency:'AUD': 'symbol' : '1.0-0'}}</span>
            </div>

            <div *ngIf="i !== 8" class="list-item sub">
              <span class="paragraph2">{{account.name}}</span>
              <span
                class="paragraph2 section-color__{{account.coreSection}}">{{account.data[currentIndex]  | currency:'AUD': 'symbol' : '1.0-0'}}</span>
            </div>


          </ng-container>

        </div>
      </div>


    </div>

  </div>

  <div class="rollit-grid-1" *ngFor="let nudge of nudges">
    <app-nudge-summary [nudge]="nudge" (closed)="onNudgeClosed(nudge)" (buttonPressed)="onNudgeButtonPressed(nudge)">
    </app-nudge-summary>
  </div>

</div>
