<mat-form-field class="input-text" appearance="outline">
  <mat-label *ngIf="prompt && multi" class="paragraph1">{{prompt}}</mat-label>
  <mat-error *ngIf="!propertyValid && !isSearch">
    Please enter a fund name
  </mat-error>

  <input matInput id="formInput" [formControl]="formControl" [type]="type" autocomplete="off" autocorrect="off"
    (keyup)="search($event.target.value)" (focus)="removeProperty()" [placeholder]="placeholder" />

</mat-form-field>

<span *ngIf="!isSearch" class="set-search paragraph2" (click)="setSearchProperty()">Search for for your fund
  instead</span>

<div class="output-holder" *ngIf="isSearch === true && showSearchOutput === true">
  <div class="output">
    <ng-container *ngFor="let property of searchOptions; trackBy: trackById">
      <div class="item-property" (click)="onYourPropertyClick(property)">
        <span class="item-property-text paragraph2"
          title="{{property.address | titlecase}}">{{property.address | titlecase}}</span>
      </div>
    </ng-container>

    <div *ngIf="searchOptions.length === 0 && !propertyValid && formControl.value.length >= 3" class="item-property">

      <span class="item-property-text paragraph2">No results found.</span>

    </div>
  </div>
  <div *ngIf="searchOptions.length === 0 && !propertyValid && formControl.value.length >= 3"
    class="item-property unknown" (click)="setManualProperty()">Enter address manually</div>
</div>
