<div class="float-right">
    <ng-container *ngIf="!isLocked">
        <ng-container *ngIf="editMode">
            <i class="material-icons close" (click)="onCancel()">close</i>
            <i class="material-icons check" (click)="onComplete()">check</i>
        </ng-container>
        <ng-container *ngIf="!editMode">
            <i class="material-icons edit" (click)="onEditClick()">edit</i>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isLocked">
        <i *ngIf="!hideLock" class="material-icons lock">lock</i>
    </ng-container>
</div>
<div *ngIf="editMode">
    <ng-content select=".edit"></ng-content>
</div>
<div *ngIf="!editMode" (click)="onContainerClick()">
    <ng-content select=".detail"></ng-content>
</div>