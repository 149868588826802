import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { DetailedEstimate, FileService, LoggerService, MoneyAccount, MoneyService, OccupancyType, Realty, RealtyService } from '@rollit/shared/data';
import { PropertyService } from '../../property.service';
import { SCSS_VARS } from '@rollit/shared';
import * as moment_ from 'moment';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { UIManagerService } from '@rollit/shared/services';
import { Router } from '@angular/router';
const moment = moment_;

interface PropertyDetails {
  account?: MoneyAccount;
  projections?: any;
  property?: Realty;
  estimate?: DetailedEstimate;
}
@Component({
  selector: 'app-property-hero',
  templateUrl: './property-hero.component.html',
  styleUrls: ['./property-hero.component.scss']
})

export class PropertyHeroComponent implements OnInit, OnDestroy {
  @Input() prop: PropertyDetails;
  @Input() propertyListId: number;
  @Input() propertyCount: number;
  @Input() inView: boolean;
  @Output() expandedProjections = new EventEmitter();
  @Output() clickNext = new EventEmitter();
  @Output() clickPrev = new EventEmitter();
  log: any;
  expanded = false;
  destroy = new Subject();
  isDesktop: boolean;

  accountId: number;
  addressTitle: string;
  addressSubTitle: string;
  occupancyType: string;
  imagePath: string;
  loanOutstanding: number;
  interestRate: number;
  growthRate: number;
  repaymentAmount: number;
  userEstimateValue: number;

  propertyInformation: any;

  projectionChartData = [];
  projectionChartCategories = [];
  projectedMortgageEndDate: any;
  projectedMortgageEndAge: any;

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Equity',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: false
    },
    {
      title: 'Value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: false
    },

    {
      title: 'Mortgage free',
      data: new Date(),
      dataColor: SCSS_VARS['$rollit-growth'],
      isDate: true,
      hide: true
    },
    {
      title: 'Mortgage',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isCurrency: true,
      hide: true
    }
  ];


  occupancyTypes = [
    ['owner occupied', "Your home"],
    ['investment', "Your investment property"],
    ['lifestyle', "Your lifestyle home"]];


  loanAccount: MoneyAccount;

  constructor(
    private logger: LoggerService,
    private dialog: MatDialog,
    private propertyService: PropertyService,
    private moneyService: MoneyService,
    private realtyService: RealtyService,
    private fileService: FileService,
    private uiService: UIManagerService,
    private router: Router,
  ) {
    this.log = this.logger.info('PropertyHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('prop', this.prop);
    this.log('isDesktop', this.isDesktop);
    this.log('inView', this.inView);
    this.log('propertyCount', this.propertyCount);

    this.setupProperty();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  setupProperty(): void {
    this.accountId = this.prop.account.id;
    this.userEstimateValue = this.prop.account.currentBalance;

    this.growthRate = this.prop?.account?.properties?.estimate?.growth_rate;
    //this.log('growthRate', this.growthRate);
    //this.log('address_details', this.prop?.property?.address_details);
    if (this.prop?.property?.address_details) {
      this.setupPropertyImage();

      const streetNumber = this.prop.property.address_details?.street_number ? this.prop.property.address_details?.street_number : '';
      const unit_number = this.prop.property.address_details?.unit_number ? this.prop.property.address_details?.unit_number + '/' : '';
      const street = this.prop.property.address_details?.street ? this.prop.property.address_details?.street : '';
      const streetType = this.prop.property.address_details?.street_type ? this.prop.property.address_details?.street_type : '';

      this.addressTitle =
        unit_number + '' +
        streetNumber + ' ' +
        street + ' ' +
        streetType;

      const locality = this.prop.property.address_details?.locality ? this.prop.property.address_details?.locality : '';
      const state = this.prop.property.address_details?.state ? this.prop.property.address_details?.state : '';
      const postcode = this.prop.property.address_details?.postcode ? this.prop.property.address_details?.postcode : '';

      this.addressSubTitle =
        locality + ' ' +
        state + ' ' +
        postcode;

    } else {
      this.addressTitle = this.prop.account.nickname;
      this.userEstimateValue = this.prop.account.currentBalance;
      this.imagePath = '/assets/images/property-default.jpg';
    }

    //this.log('addressTitle', this.addressTitle);

    this.occupancyTypes.forEach((occupancyVal) => {
      this.log('occupancyVal', occupancyVal[0], this.prop.account.accountSubtype);
      if (occupancyVal[0] === this.prop.account.accountSubtype) {
        this.occupancyType = occupancyVal[1];
      }
    });

    if (this.prop?.account?.properties?.loan?.id) {
      this.moneyService.getAccount(this.prop.account.properties.loan.id).pipe(takeUntil(this.destroy)).subscribe(account => {
        this.log('getAccount', account);
        this.loanAccount = account;
        this.loanOutstanding = this.loanAccount?.currentBalance;
        this.interestRate = this.loanAccount?.properties?.terms?.interestRate;
        this.repaymentAmount = this.loanAccount?.properties?.terms?.repaymentAmount;

        this.propertyInformation = {
          userEstimateValue: this.userEstimateValue,
          estimate: this.prop?.account?.properties?.estimate?.estimate,
          estimateHigh: this.prop?.account?.properties?.estimate?.estimate_high,
          estimateLow: this.prop?.account?.properties?.estimate?.estimate_low,
          growthRate: this.growthRate,
          loanOutstanding: this.loanOutstanding,
          interestRate: this.interestRate,
          repaymentAmount: this.repaymentAmount,
        };

        this.log('propertyInformation', this.propertyInformation);
        this.setupProjections();
      });
    } else if (this.userEstimateValue) {
      this.propertyInformation = {
        userEstimateValue: this.userEstimateValue,
        estimate: this.prop?.account?.properties?.estimate?.estimate,
        estimateHigh: this.prop?.account?.properties?.estimate?.estimate_high,
        estimateLow: this.prop?.account?.properties?.estimate?.estimate_low
      }
      this.setupProjections();
    }


  }

  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.loanAccount ? this.userEstimateValue - Math.abs(this.loanAccount.currentBalance) : this.userEstimateValue;
    this.titleGroupDetails[1]['data'] = this.userEstimateValue;
    this.titleGroupDetails[2]['data'] = this.projectedMortgageEndDate;
    if (this.loanAccount) {
      this.titleGroupDetails[3]['data'] = this.loanAccount ? this.loanAccount.currentBalance : 0;
      this.titleGroupDetails[3]['hide'] = false;
    }

  }

  setupPropertyImage() {

    // TODO: Handle multiple image, requires a carousel

    this.moneyService.getAccountImages(this.prop.account.id).pipe(takeUntil(this.destroy)).subscribe(accountImagesResult => {
      console.log('setupPropertyImage getAccountImages', accountImagesResult);

      if (accountImagesResult && accountImagesResult.length > 0) {
        this.fileService.getFilePath(accountImagesResult[0]['id']).pipe(takeUntil(this.destroy)).subscribe(path => {
          this.imagePath = path;
        });
      } else {
        console.log('setupPropertyImage No getAccountImages', accountImagesResult, this.prop.property.property_id);
        this.realtyService.getImages(this.prop.property.property_id).subscribe(images => {
          this.log('setupPropertyImage Realty images', images);
          if (images !== null && images.length > 0) {
            this.imagePath = images[0].url;
          } else {
            // go
            this.getGoogleImages();
          }


        }, error => {
          this.log('setupPropertyImage getImages error', error);
          this.getGoogleImages();
        });
      }
    });
  }

  getGoogleImages() {
    if (this.prop?.property?.address_details?.location) {
      this.imagePath = "https://maps.googleapis.com/maps/api/streetview?size=640x410&location="
        + this.prop.property.address_details.location.point.lat
        + ","
        + this.prop.property.address_details.location.point.lon
        + "&fov=90&pitch=10&key=AIzaSyChqCMU3v1mGv7hWiC094tBuZgylF895C4";
    } else {
      this.imagePath = "/assets/images/default/default_property_home.jpg"
    }
  }


  setupProjections() {
    this.propertyService.getProjections(this.prop.account.id, 6).pipe(takeUntil(this.destroy)).subscribe(proj => {
      this.log('Got projections', proj, this.accountId);

      this.prop.projections = proj;
      const projections = proj;
      if (projections?.extra) {
        this.log('projections?.extra', projections?.extra);
        projections.extra.forEach(extra => {
          this.log('debtfree', extra);
          const debtFree = extra?.debtFree;
          if (debtFree) {
            this.log('debtfree found', extra);
            this.projectedMortgageEndDate = debtFree;
            this.titleGroupDetails[2]['hide'] = false;
          }
        });
      }

      const colorArray = [SCSS_VARS['$rollit-property'], SCSS_VARS['$rollit-mortgage'], SCSS_VARS['$rollit-growth']];
      const chartCategoriesMap = [
        { short: 'PROP', section: 'property', title: 'Property' },
        { short: 'MTG', section: 'property', title: 'Mortgage' },
        { short: 'NET', section: '', title: 'Net' },
      ];
      const temp_array = [];

      projections.names.forEach((nameVal, nameIndex) => {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            this.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
          }
        });

        if (nameVal === 'Net') {
          temp_object['type'] = 'spline';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        this.log(nameVal, nameIndex, colorArray[nameIndex]);

        projections.data.forEach((dataVal, dataIndex) => {
          this.projectionChartCategories.push(projections.data[dataIndex].label);
          temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
        });

        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      this.log(temp_array);

      this.projectionChartData = temp_array;
      this.log('setupProjections this.chartData', this.projectionChartData);
      this.log('setupProjections this.projectionChartCategories', this.projectionChartCategories);
      this.setupTitleGroupDetails();
    });
  }



  // User actions

  graphClicked(category, name): void {
    this.log(category, name);
  }

  expandClicked() {
    this.expanded = !this.expanded;
    this.expandedProjections.emit(this.expanded);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  uploadComplete(file) {
    this.log('upload complete', file);
    this.moneyService.setAccountImages(this.prop.account.id, [file]).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('uploadComplete', result);
    });
  }

  onEditProperty(accountId: number) {
    this.propertyService.startUpdateProperty(accountId);
  }

  onStartReviewLoan(accountId: number) {
    this.propertyService.startReviewLoan(accountId);
  }


  onRemoveProperty() {
    this.log('Remove property clicked');
    this.openRemovePropertyConfirmationDialog(this.prop.account.id.toString());
  }

  openRemovePropertyConfirmationDialog(propertyId: string) {
    this.log('Delete dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove property',
        copy: 'Are you sure you want to remove this property?<br><span class="paragraph2 section-color__secondary">(This will also remove the associated loan.)</span>',
        buttonMessage: 'Yes, remove property',
        type: 'Realty',
        showCancel: true
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove property dialog closed', result);
      if (result[0] === 'accept') {
        this.removeAccount(propertyId);
      }
    });
  }

  removeAccount(propertyId: string): void {
    this.log('Remove property init');
    this.moneyService.removeAccount(this.prop.account.id, true).pipe(takeUntil(this.destroy)).subscribe(account => {
      this.log('Property removed', account);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/property']));
    });
  }

  scrollPrev() {
    this.clickPrev.next();
    this.log('clickPrev');
  }

  scrollNext() {
    this.clickNext.next();
    this.log('scrollNext');
  }

}
