<div class="rollit-grid">
  <div class="rollit-grid-2">
    <div class="group-panel chart">
      <app-bar-chart-category *ngIf="chartData?.length > 0" [seriesData]="chartData"
        [seriesCategories]="chartCategories" [title]="'Your financial position'"
        (graphClicked)="graphClicked($event[0], $event[1])" [showExpand]="false">
      </app-bar-chart-category>
      <app-title-group #netRipple matRipple *ngIf="titleGroupDetailsReady" [details]="titleGroupDetails"
        [displayFullWidth]="isDesktop">
      </app-title-group>
    </div>
  </div>

  <div class="rollit-grid-1">

    <app-horizontal-scroll-snap *ngIf="!isDesktop" [scrollToSection]="sectionValue">
      <section #savingsRipple matRipple>
        <app-section-summary-card *ngIf="savingsData" [sectionTitles]="sectionTitles.savings"
          [cashIn]="savingsData?.movement.budget.income" [cashOut]="savingsData?.movement.budget.spending"
          [savings]="savingsData?.movement.budget.savings" [totalSavings]="savingsData?.position.net"
          [section]="'savings'" [title]="'Savings'" [padding]="true" [goal]="savingsData?.goal"
          (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </section>
      <section #lifestyleRipple matRipple>
        <app-section-summary-card *ngIf="lifestyleData" [sectionTitles]="sectionTitles.lifestyle"
          [cashIn]="lifestyleData?.position.assets" [cashOut]="lifestyleData?.position.debt"
          [savings]="lifestyleData?.position.net" [totalSavings]="lifestyleData?.position.assets"
          [section]="'lifestyle'" [title]="'Lifestyle assets'" [padding]="true" [goal]="lifestyleData?.goal"
          (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </section>
      <section #investmentsRipple matRipple>
        <app-section-summary-card *ngIf="investmentData" [sectionTitles]="sectionTitles.investments"
          [cashIn]="investmentData?.position.assets" [cashOut]="" [savings]="investmentData?.position.net"
          [totalSavings]="investmentData?.position.assets" [section]="'investments'" [title]="'Investments'"
          [padding]="true" [goal]="investmentData?.goal" (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </section>
      <section #superannuationRipple matRipple>
        <app-section-summary-card *ngIf="superannuationData" [sectionTitles]="sectionTitles.superannuation"
          [cashIn]="superannuationData?.position.assets" [cashOut]="2800"
          [savings]="superannuationData?.projection.balance" [totalSavings]="superannuationData?.position.assets"
          [section]="'superannuation'" [title]="'Superannuation'" [padding]="true" [goal]="superannuationData?.goal"
          (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </section>
      <section #propertyRipple matRipple>
        <app-section-summary-card *ngIf="propertyData" [sectionTitles]="sectionTitles.property"
          [cashIn]="propertyData?.position.assets" [cashOut]="propertyData?.position.debt"
          [savings]="propertyData?.position.net" [totalSavings]="propertyData?.position.assets" [section]="'property'"
          [title]="'Property'" [padding]="true" [goal]="propertyData?.goal" (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </section>
      <section #creditRipple matRipple>
        <app-section-summary-card *ngIf="loansData" [sectionTitles]="sectionTitles.loans"
          [cashIn]="loansData?.position.categories.loan" [cashOut]="loansData?.position.categories.creditcard"
          [savings]="0" [totalSavings]="loansData?.position.debt" [section]="'credit'" [title]="'Loans and cards'"
          [padding]="true" [goal]="loansData?.goal" (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </section>
    </app-horizontal-scroll-snap>

    <mat-accordion *ngIf="isDesktop" class="rollit-accordion-new">

      <app-rollit-expansion panelTitle="Savings" [expanded]="currentSection === 'savings'">

        <app-section-summary-card #savingsRipple matRipple *ngIf="savingsData" [sectionTitles]="sectionTitles.savings"
          [cashIn]="savingsData?.movement.budget.income" [cashOut]="savingsData?.movement.budget.spending"
          [savings]="savingsData?.movement.budget.savings" [totalSavings]="savingsData?.position.net"
          [section]="'savings'" [title]="'Savings'" [padding]="true" [goal]="savingsData?.goal"
          (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </app-rollit-expansion>

      <app-rollit-expansion panelTitle="Lifestyle assets" [expanded]="currentSection === 'lifestyle'">
        <app-section-summary-card #lifestyleRipple matRipple *ngIf="lifestyleData"
          [sectionTitles]="sectionTitles.lifestyle" [cashIn]="lifestyleData?.position.assets"
          [cashOut]="lifestyleData?.position.debt" [savings]="lifestyleData?.position.net"
          [totalSavings]="lifestyleData?.position.assets" [section]="'lifestyle'" [title]="'Lifestyle assets'"
          [padding]="true" [goal]="lifestyleData?.goal" (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </app-rollit-expansion>

      <app-rollit-expansion panelTitle="Investments" [expanded]="currentSection === 'investments'">
        <app-section-summary-card #investmentsRipple matRipple *ngIf="investmentData"
          [sectionTitles]="sectionTitles.investments" [cashIn]="investmentData?.position.assets" [cashOut]=""
          [savings]="investmentData?.position.net" [totalSavings]="investmentData?.position.assets"
          [section]="'investments'" [title]="'Investments'" [padding]="true" [goal]="investmentData?.goal"
          (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </app-rollit-expansion>

      <app-rollit-expansion panelTitle="Superannuation" [expanded]="currentSection === 'superannuation'">
        <app-section-summary-card #superannuationRipple matRipple *ngIf="superannuationData"
          [sectionTitles]="sectionTitles.superannuation" [cashIn]="superannuationData?.position.assets" [cashOut]="2800"
          [savings]="superannuationData?.projection.balance" [totalSavings]="superannuationData?.position.assets"
          [section]="'superannuation'" [title]="'Superannuation'" [padding]="true" [goal]="superannuationData?.goal"
          (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </app-rollit-expansion>

      <app-rollit-expansion panelTitle="Property" [expanded]="currentSection === 'property'">
        <app-section-summary-card #propertyRipple matRipple *ngIf="propertyData"
          [sectionTitles]="sectionTitles.property" [cashIn]="propertyData?.position.assets"
          [cashOut]="propertyData?.position.debt" [savings]="propertyData?.position.net"
          [totalSavings]="propertyData?.position.assets" [section]="'property'" [title]="'Property'" [padding]="true"
          [goal]="propertyData?.goal" (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </app-rollit-expansion>

      <app-rollit-expansion panelTitle="Loans and cards" [expanded]="currentSection === 'credit'">
        <app-section-summary-card #creditRipple matRipple *ngIf="loansData" [sectionTitles]="sectionTitles.loans"
          [cashIn]="loansData?.position.categories.loan" [cashOut]="loansData?.position.categories.creditcard"
          [savings]="0" [totalSavings]="loansData?.position.debt" [section]="'credit'" [title]="'Loans and cards'"
          [padding]="true" [goal]="loansData?.goal" (newGoal)="onNewGoal($event)">
        </app-section-summary-card>
      </app-rollit-expansion>
    </mat-accordion>
  </div>

  <div class="rollit-grid-1" *ngFor="let nudge of nudges">
    <app-nudge-summary [nudge]="nudge" (closed)="onNudgeClosed(nudge)" (buttonPressed)="onNudgeButtonPressed(nudge)">
    </app-nudge-summary>
  </div>

</div>
