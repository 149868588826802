import { CurrencyPipe, DatePipe, PercentPipe, registerLocaleData } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import * as handlebars_ from 'handlebars';
const Handlebars = handlebars_;
import localeEnAu from '@angular/common/locales/en-AU';
registerLocaleData(localeEnAu, 'en-AU');

// regioster handlebar helpers.
// const currencyPipe = new CurrencyPipe('en-AU', 'AUD');
// const datePipe = new DatePipe('en-AU');
// const percentPipe = new PercentPipe('en-AU');



@Component({
    selector: 'lib-handlebars',
    templateUrl: './handlebars.component.html',
    styleUrls: ['./handlebars.component.scss'],
  })
export class HandlebarsComponent implements OnChanges {
    @Input() template: string;
    @Input() context: any;

    rendered: string;     // rendered HTML
    private delegate: HandlebarsTemplateDelegate;


    constructor(
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe,
    ) {
        // register Handlebar helpers
        if ( !Handlebars.helpers['date'] ) {
            Handlebars.registerHelper("date", (value: any, format?: string, timezone?: string, locale?: string) => {
                // last argument passed to the helper is "context", so make sure it is not passsed as one of these arguments
                if (typeof(format) !== 'string')      { format = undefined; }
                else if (typeof(timezone) !== 'string') { timezone = undefined; }
                else if (typeof(locale) !== 'string') { locale = undefined; }
                return datePipe.transform(value, format, timezone, locale);
            });
        }
        if ( !Handlebars.helpers['currency'] ) {
            Handlebars.registerHelper("currency", (value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string) => {
                // last argument passed to the helper is "context", so make sure it is not passsed as one of these arguments
                if (typeof(currencyCode) !== 'string') { currencyCode = undefined; }
                if (typeof(display) !== 'string') { display = undefined; }
                if (typeof(locale) !== 'string') { locale = undefined; }
                if (typeof(digitsInfo) !== 'string') { digitsInfo = '1.0-0'; }

                return currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
            });
        }
        if ( !Handlebars.helpers['percent'] ) {
            Handlebars.registerHelper("percent", (value: any, digitsInfo?: string, locale?: string) => {
                // last argument passed to the helper is "context", so make sure it is not passsed as one of these arguments
                if (typeof(digitsInfo) !== 'string')      { digitsInfo = undefined; }
                else if (typeof(locale) !== 'string') { locale = undefined; }
                return percentPipe.transform(value, digitsInfo, locale);
            });
        }
    }

    ngOnChanges() {
        // console.log('Handlebars re-render', this.template, this.context);
        // re-render
        if (this.template) {
            this.delegate = Handlebars.compile(this.template);
            this.rendered = this.delegate(this.context);
        }
    }

  }