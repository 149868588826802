  <div class="main-content-section">
    <div class="section-content" (scroll)="onElementScroll()">
      <app-top-menu></app-top-menu>

      <div class="rollit-grid">

        <app-section-title *ngIf="accounts.length > 0" [title]="'Outstanding balance'" [value]="totalAccountValue">
        </app-section-title>

        <div *ngIf="isDesktop && accounts.length > 0" class="rollit-grid-2">
          <app-horizontal-scroll-snap *ngIf="ready" [scrollToSection]="sectionValue" [fullWidth]="true"
            (animFinished)="animationCallback($event)">
            <section *ngFor="let account of accounts; let i = index">

              <app-credit-cards-loans-hero [inView]="currentViewSlide === i" [id]="account.id" [acc]="account"
                [propertyListId]="i" [propertyCount]="accounts.length - 1" (clickNext)="scrollNext()"
                (clickPrev)="scrollPrev()">
              </app-credit-cards-loans-hero>
            </section>
          </app-horizontal-scroll-snap>
        </div>

        <div *ngIf="!isDesktop && accounts.length > 0" class="rollit-grid-1">
          <ng-container *ngFor="let account of accounts">
            <app-credit-cards-loans-hero [id]="account.id" [inView]="true" [acc]="account" (clickNext)="scrollNext()"
              (clickPrev)="scrollPrev()" (expandedProjections)="expandedEvent($event)"></app-credit-cards-loans-hero>
          </ng-container>
        </div>


        <div *ngIf="accounts.length === 0 && ready" class="rollit-grid-2">
          <div class="group-panel blank">
            <span class="copy heading1">Credit cards and loans</span>

            <span class="copy paragraph1">Take control of your loans and credit cards for good. Set debt repayment goals
              and track your repayments. Hey Coach helps you to get debt free faster and avoid interest costs.</span>

            <img class="image" src="/assets/images/example/credit-example.png" />
          </div>
        </div>


        <div class="rollit-grid-1">
          <div class="sundry-holder">

            <app-new-savings-goal class="goal-button" icon="home" [section]="'credit'" (newGoalClicked)="startNewGoal()">
            </app-new-savings-goal>

            <button mat-stroked-button class="new-account" (click)="onCreateLoan()">
              <span *ngIf="accounts && accounts.length > 0" class="paragraph1">Add another credit card or loan</span>
              <span *ngIf="accounts && accounts.length === 0" class="paragraph1">Add a credit card or loan</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
