import { InvestmentOptionFilter } from './fund';

/**
 * A dialog that is made of multiple sections.
 */
export interface Dialog {
    slug: string;               // a unique slug to identify the dialog
    name: string;
    title?: string;
    description?: string;
    sections: Section[];
}

/**
 * A section of dialog that contains one or more questions.
 */
export interface Section {
    type: SectionType;
    slug?: string;              // a unique slug to identify the section
    icon?: string;              // name of icon to display
    title?: string;             // a title for this section
    subTitle?: string;          // a sub-title for the section
    description?: string;       // detail on this section
    completed?: boolean;
    visible?: boolean;          // whether this section is to be presented to the user

    actions?: [{ label: string; action: string }];

    // QuestionSection (TODO should use sub interface)
    questions?: Question[];      // a list of questions for this sections (usually one)
    optional?: boolean;

    // For an InfoSection (TODO should use sub interface)
    content?: any;
}

export interface QuestionSection extends Section {
    questions: Question[];      // a list of questions for this sections (usually one)
    optional?: boolean;
}
export interface InfoSection extends Section {
    content?: any;
}

export enum SectionType {
    Question = "Question",
    Information = "Information",
    Redirect = "Redirect",
}

export interface Question {
    slug?: string;              // a unique slug to identify the question
    prompt?: string;            // the question prompt
    description?: string;       // some information on the question.
    answer?: Answer;            // a definition of the answer
    visible?: boolean;          // whether this question is to be presented to the user
}

export interface Answer {
    slug?: string;
    description?: string;       // more information about the answer.
    typeDef?: TypeDef;
    value?: any;
    visible?: boolean;
    default?: string;
    prompt?: string;
    placeholder?: string;
}

/**
 * A generic fact.
 */
export interface Fact {
    "@type"?: string;    // type of fact
    slug: string;       // an friendly identifier for this slug
    type?: ValueType;   // type of value
    value?: any;        // the value for this fact
}

export interface QuestionFact {
    "@type"?: string;    // type of fact
    slug: string;
    visible?: boolean;
}

/**
 * A type definition for an answer.
 * This is the parent interface 
 */
export interface TypeDef {
    type?: ValueType;             // the type of value to be given
}

/**
 * Definition for a Number answer.
 * Where type='Number'
 */
export interface NumberTypeDef extends TypeDef {
    format?: NumberFormat;
    min?: number;
    max?: number;
    interval?: number;
    negate?: boolean;       // whether the number output should be negative of the number input
}

export interface StringTypeDef extends TypeDef {
    length?: number;    // maximum length of string.  TODO use min and max attributes.
    class?: string;     // email, password, phone
}

export interface BooleanTypeDef extends TypeDef {
    trueName: string;
    falseName: string;
}

export interface DateTypeDef extends TypeDef {
    class: DateClass;
    earliest: string;   // ISO 8601 format
    latest: string;     // ISO 8601 format
}

export interface SelectTypeDef extends TypeDef {
    multi?: boolean;        // whether multiple items can be selected.  TODO change to max num selectable
    options: Option[];      // the possible options
}

/**
 * A composite Answer type of 1 or more Answers.
 * The name may be used to provide a pre-defined UI component. e.g. name:MailingAddress might lead to component that does address lookup.
 */
export interface CompositeTypeDef extends TypeDef {
    name?: string;
    fields?: Answer[];
}

/**
 * The answer value will be a Realty object
 */
export interface RealtyTypeDef extends TypeDef {
}

/**
 * The answer value will be a DetailedEstimate object
 */
export interface RealtyEstimateTypeDef extends TypeDef {
}

/**
 * Superannuation investment option search
 */
export interface SuperTypeDef extends TypeDef {
    display?: 'option' | 'product' | 'fund';
    filter?: InvestmentOptionFilter;
}

export interface AccountTypeDef extends TypeDef {
    categories?: string[];
}


export interface Option {
    slug: string;
    name: string;           // a name to display to the user
    value: any;             // the value
    visible: boolean;       // whether this option is to be presented to the user
}

export enum ValueType {
    String = "String",
    Boolean = "Boolean",
    Number = "Number",      // double floating point
    Long = "Long",          // long integer
    Date = "Date",
    Select = "Select",
    Composite = "Composite",
    Realty = "Realty",
    RealtyEstimate = "RealtyEstimate",
    Superannuation = "Superannuation",  // superannuation details
    MoneyAccount = "MoneyAccount",      // money account
    Sum = "Sum",      // money account
    // List = "List",   // do we need a list?
}

export enum NumberFormat {
    Integer = "Integer",
    Float = "Real",
    Currency = "Currency",
    Percentage = "Percentage",
    Year = "Year",
}

export enum DateClass {
    LocalDateTime = "LocalDateTime",
    LocalDate = "LocalDate",
    LocalTime = "LocalTime",
    OfinplanetDateTime = "OfinplanetDateTime",
    OfinplanetDate = "OfinplanetDate",
    OfinplanetTime = "OfinplanetTime",
    Year = "Year",
    YearMonth = "YearMonth",
    MonthDay = "MonthDay",
}

/**
 * Gives a section to answer within a dialog.
 */
export interface Progress {
    dialog?: Dialog;        // the dialog in context (short form)
    previous?: Section;     // the previous dialog section
    next?: Section;         // the current dialog section
    total?: number;         // total number of sections in the dialog
    completed?: number;     // number of completed sections
    facts?: Fact[];         // TODO possibly a list of relevant facts
}
