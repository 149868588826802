import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIManagerService } from '@rollit/shared/services';
import { LoggerService } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop = false;
  private isDestroyed = new Subject();

  activeRoute = 'home';

  constructor(
    private logger: LoggerService,
    private uiService: UIManagerService,
    private route: ActivatedRoute
  ) {
    this.log = this.logger.info('SurveyFormComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.topNavIsVisible.next(false);

    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }
}
