 <div class="title-holder">
   <div class="heading1" [ngClass]="!smallTitle ? 'heading1':'heading2'">{{title}}</div>
   <div *ngIf="showExpand" class="expand-button"><i class="material-icons">launch</i></div>
 </div>

 <div id="chartHolder" class="chart-holder" [ngClass]="{'show': showChart}">
   <highcharts-chart #chart [Highcharts]="Highcharts" [options]="chartOptions" [callbackFunction]="chartCallback"
     [(update)]="updateFlag" style="width: 90%; display: block; overflow: hidden;">
   </highcharts-chart>
 </div>
