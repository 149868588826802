import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIManagerService } from '@rollit/shared/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoggerService } from '@rollit/shared/data';

export interface ConfirmDialogData {
  title: string;
  copy: string;
  type: string;
  showCancel: boolean;
  acceptColor: string;
  buttonMessage: string;
  showCheckbox: boolean;
  checkboxLabel: string;
  cancelMessage?: string;
  showButtonTick?: boolean;
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent implements OnDestroy {
  private isDestroyed = new Subject();
  log: any;
  isDesktop: boolean;
  title: string;
  copy: string;
  type: string;
  buttonMessage: string;
  showCancel = true;
  cancelMessage: string = 'Cancel';
  acceptColor = 'warn';
  showCheckbox: boolean;
  checkboxLabel: string;
  checkboxChecked = false;
  showButtonTick = true;

  constructor(
    private logger: LoggerService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private uiService: UIManagerService,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData
  ) {
    this.log = this.logger.info('ConfirmationDialogComponent');
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop =>
      this.isDesktop = isDesktop
    );
    this.log(data);
    this.title = data.title;
    this.copy = data.copy;
    this.type = data.type;
    this.buttonMessage = data.buttonMessage;
    this.log('showCheckbox', data.showCheckbox);
    this.log('showCancel', data.showCancel);
    if (data.acceptColor) {
      this.acceptColor = data.acceptColor;
    }
    if (data.showCancel !== undefined) {
      this.showCancel = data.showCancel;
    }
    if (data.cancelMessage !== undefined) {
      this.cancelMessage = data.cancelMessage;
    }
    if (data.showCheckbox !== undefined) {
      this.showCheckbox = data.showCheckbox;
    }
    if (data.showButtonTick !== undefined) {
      this.showButtonTick = data.showButtonTick;
    }
    this.checkboxLabel = data.checkboxLabel;



  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  toggleCheckbox() {
    this.checkboxChecked = !this.checkboxChecked;
    this.log('checkboxChecked', this.checkboxChecked);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onAcceptClick() {
    this.dialogRef.close(['accept', this.checkboxChecked]);
  }

  onCancelClick() {
    this.dialogRef.close('cancel');
  }
}
