import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';

@Component({
  selector: 'lib-home-situation-age-percentage-chart',
  templateUrl: './home-situation-age-percentage-chart.component.html',
  styleUrls: ['./home-situation-age-percentage-chart.component.scss']
})
export class HomeSituationAgePercentageChartComponent implements OnInit {
  log: any;
  @Input() assetsHomeOwnershipAgePercentageResult: number;
  @Input() assetsHomeOwnershipAgePercentage: string;
  assetsHomeOwnershipAgePercentageFinal = 0;
  homeOwnershipAgePercentageAnim = 0;
  homeOwnershipAgePercentageCountUp = 0;
  constructor(private logger: LoggerService,) {
    this.log = this.logger.info('HomeSituationAgePercentageChartComponent');
  }

  ngOnInit() {
    this.assetsHomeOwnershipAgePercentageFinal = Math.round(+this.assetsHomeOwnershipAgePercentage * 100);
    /*
        setTimeout(() => {
          this.homeOwnershipAgePercentageAnim = +this.assetsHomeOwnershipAgePercentageFinal;
          this.animateValue(this.homeOwnershipAgePercentageCountUp, this.assetsHomeOwnershipAgePercentageFinal, 200);
        }, 100);
    */
  }

  animateValue(start, end, duration) {
    //this.log(start, end);
    if (!Number.isNaN(start) && !Number.isNaN(end)) {
      if (start === end) {
        return;
      }
      const range = end - start;
      let current = start;
      const increment = end > start ? 1 : -1;
      const stepTime = Math.abs(Math.floor(duration / range));
      // this.log(increment, stepTime);
      const self = this;
      const timer = setInterval(function () {
        current += increment;
        // this.log(current);
        self.homeOwnershipAgePercentageCountUp = current;
        if (current === end) {
          clearInterval(timer);
        }
      }, stepTime);
    }
  }
}
