import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[libInput]',
})
export class InputDirective {
    constructor(
        public viewContainerRef: ViewContainerRef,
    ) {
    }
}
