import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyComponent } from './components/property.component';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';


const propertyRoutes: Routes = [
  {
    path: 'property',
    component: PropertyComponent,
    data: {
      title: 'Property',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
  {
    path: 'property/:id',
    component: PropertyComponent,
    data: {
      title: 'Property',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(propertyRoutes)],
  exports: [RouterModule],
})

export class PropertyRoutingModule { }