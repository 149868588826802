import { Component, Input, OnInit } from '@angular/core';
import { SCSS_VARS } from '@rollit/shared';
import { TitleItemData } from '@rollit/shared/common';
import { LoggerService, MoneyAccount, MoneyService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lifestyle-assets-projections',
  templateUrl: './lifestyle-assets-projections.component.html',
  styleUrls: ['./lifestyle-assets-projections.component.scss']
})
export class LifestyleAssetsProjectionsComponent implements OnInit {

  @Input() acc: MoneyAccount;
  @Input() projectionChartData: any = [];
  @Input() projectionChartCategories: any = [];
  @Input() loanAccount: any;
  @Input() inView: boolean = false;
  isDesktop: boolean;
  destroy = new Subject();
  log: any;

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Age',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isNumber: true,
      hide: true,
    },

    {
      title: 'Lifestyle asset value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-primary'],
      isCurrency: true,
      hide: true,
    },
    {
      title: 'Net value',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: true,
    },
    {
      title: 'Lifestyle asset loan',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isCurrency: true,
      hide: true,
    }
  ];


  constructor(private logger: LoggerService, private uiService: UIManagerService, private moneyService: MoneyService) {
    this.log = this.logger.info('LifestyleAssetsProjectionsComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('projectionChartCategories', this.projectionChartCategories);
    this.log('projectionChartData', this.projectionChartData);
    this.log('acc', this.acc);
    this.log('loan acc', this.loanAccount);
    this.setupTitleGroupDetails();
  }



  setupTitleGroupDetails(): void {
    if (this.loanAccount) {
      this.titleGroupDetails[0]['data'] = this.projectionChartCategories[0];
      this.titleGroupDetails[0]['hide'] = false;

      this.titleGroupDetails[1]['data'] = this.projectionChartData[0].data[0];
      this.titleGroupDetails[1]['hide'] = false;

      this.titleGroupDetails[2]['data'] = this.projectionChartData[2].data[0];
      this.titleGroupDetails[2]['hide'] = false;

      this.titleGroupDetails[3]['data'] = this.projectionChartData[1].data[0];
      this.titleGroupDetails[3]['hide'] = false;
    } else {

      this.titleGroupDetails[0]['data'] = this.projectionChartCategories[0];
      this.titleGroupDetails[0]['hide'] = false;

      this.titleGroupDetails[1]['data'] = this.projectionChartData[0].data[0];
      this.titleGroupDetails[1]['hide'] = false;

      this.titleGroupDetails[2]['data'] = this.projectionChartData[0].data[0];
      this.titleGroupDetails[2]['hide'] = false;

      this.titleGroupDetails[3]['hide'] = true;
    }

  }

  graphClicked(category, data): void {
    this.log(category, data);
    if (this.loanAccount) {
      this.titleGroupDetails[0]['data'] = category;

      this.titleGroupDetails[1]['data'] = data[0];

      this.titleGroupDetails[2]['data'] = data[2];

      this.titleGroupDetails[3]['data'] = data[1];
    } else {
      this.titleGroupDetails[0]['data'] = category;

      this.titleGroupDetails[1]['data'] = data[0];

      this.titleGroupDetails[2]['data'] = data[0];

      this.titleGroupDetails[3]['data'] = 0;

    }
  }
}