
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lib-report-financial-security',
  templateUrl: './financial-security.component.html',
  styleUrls: ['./financial-security.component.scss']
})
export class FinancialSecurityComponent implements OnInit, OnDestroy {
  log: any;
  @Input() useQuery: boolean;
  @Input() assetsSuperConcern: string;
  assetsSuperConcernFinal: string;
  assetsSuperConcernResult: string;
  @Input() profileAgeRange: string;
  @Input() profileGender: string;
  @Input() assetsSuperAgeAvgBalance: string;
  assetsSuperAgeAvgBalanceResult: string;
  @Input() assetsInvestmentPortfolio: string;
  assetsInvestmentPortfolioFinal: string;
  assetsInvestmentPortfolioResult: string;
  @Input() assetsInvestmentBusinessAgePercentage: string;
  @Input() assetsInvestmentPropertyAgePercentage: string;
  @Input() assetsInvestmentSharesAgePercentage: string;
  assetsInvestmentBusinessAgePercentageResult: string;
  assetsInvestmentPropertyAgePercentageResult: string;
  assetsInvestmentSharesAgePercentageResult: string;

  @Input() reportText: any;

  constructor(private logger: LoggerService, private uiService: UIManagerService, private route: ActivatedRoute, private currencyPipe: CurrencyPipe, private dialogService: DialogService) {
    this.log = this.logger.info('FinancialSecurityComponent');
    uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit(): void {
    this.log('FinancialHealthScoreComponent useQuery', this.useQuery);
    if (this.useQuery === false) {
      this.log('FinancialHealthScoreComponent assetsSuperConcern', this.assetsSuperConcern);
      this.log('FinancialHealthScoreComponent profileAgeRange', this.profileAgeRange);
      this.log('FinancialHealthScoreComponent profileGender', this.profileGender);
      this.log('FinancialHealthScoreComponent assetsSuperAgeAvgBalance', this.assetsSuperAgeAvgBalance);
      this.log('FinancialHealthScoreComponent assetsInvestmentPortfolio', this.assetsInvestmentPortfolio);
      this.log('FinancialHealthScoreComponent assetsInvestmentBusinessAgePercentage', this.assetsInvestmentBusinessAgePercentage);
      this.log('FinancialHealthScoreComponent assetsInvestmentPropertyAgePercentage', this.assetsInvestmentPropertyAgePercentage);
      this.log('FinancialHealthScoreComponent assetsInvestmentSharesAgePercentage', this.assetsInvestmentSharesAgePercentage);
      this.setupData();
    } else {
      this.route.queryParams.subscribe(params => {
        this.log('params', params);
        this.assetsSuperConcern = params['assets_super_concern'];
        this.profileAgeRange = params['profile_age_range'];
        this.profileGender = params['profile_gender'];
        this.assetsSuperAgeAvgBalance = params['assets_super_age_avg_balance'];
        this.assetsInvestmentPortfolio = params['assets_investment_portfolio'];
        this.assetsInvestmentBusinessAgePercentage = params['assets_investment_business_age_percentage'];
        this.assetsInvestmentPropertyAgePercentage = params['assets_investment_property_age_percentage'];
        this.assetsInvestmentSharesAgePercentage = params['assets_investment_shares_age_percentage'];
        this.dialogService.getReportText().subscribe(data => {
          this.reportText = data;
          this.log('getReportText', data);
          this.setupData();
        });

      });
    }
  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }

  setupData() {
    this.assetsSuperConcernFinal = this.reportText.financial_security.assets_super_concern[this.assetsSuperConcern];
    var ageText = this.profileAgeRange;
    if (ageText === 'Under 34') {
      ageText = ageText.toLowerCase();
    } else if (ageText === '65 and over') {
      ageText = ageText.toLowerCase();
    } else {
      ageText = 'between ' + ageText;
    }


    if (this.assetsInvestmentPortfolio === 'investment_none') {
      this.assetsInvestmentPortfolioResult =
        this.reportText.financial_security.assets_investment_portfolio_age.investments_none
          .replace("[age_range]", ageText);
    } else {
      this.assetsInvestmentPortfolioResult =
        this.reportText.financial_security.assets_investment_portfolio_age.investments
          .replace("[age_range]", ageText);
    }

    this.assetsInvestmentPortfolio = this.reportText.financial_security.assets_investment_portfolio[this.assetsInvestmentPortfolio];

    this.assetsSuperConcernResult =
      this.reportText.financial_security.assets_super_concern_response
        .replace("[assets_super_concern]", this.assetsSuperConcernFinal)
        .replace("[assets_investment_portfolio]", this.assetsInvestmentPortfolio)

    this.assetsSuperAgeAvgBalanceResult =
      this.reportText.financial_security.assets_super_age_avg_balance
        .replace("[gender or Australians]", this.profileGender)
        .replace("[age_range]", ageText)
        .replace("[assets_super_age_av_balance]", this.currencyPipe.transform(this.assetsSuperAgeAvgBalance, '$', 'symbol', '1.0-0'));

    this.assetsInvestmentPropertyAgePercentageResult =
      this.reportText.financial_security.assets_investment_property_age_percentage
        .replace("[assets_investment_portfolio_age_property]", Math.round(+this.assetsInvestmentPropertyAgePercentage * 100) + '%')
        .replace("[age_range]", ageText);

    this.assetsInvestmentSharesAgePercentageResult =
      this.reportText.financial_security.assets_investment_shares_age_percentage
        .replace("[assets_investment_portfolio_age_shares]", Math.round(+this.assetsInvestmentSharesAgePercentage * 100) + '%')
        .replace("[age_range]", ageText);

    this.assetsInvestmentBusinessAgePercentageResult =
      this.reportText.financial_security.assets_investment_business_age_percentage
        .replace("[assets_investment_portfolio_age_business]", Math.round(+this.assetsInvestmentBusinessAgePercentage * 100) + '%');


  }
}