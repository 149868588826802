<div class="main-content-section">
  <div class="section-content">
    <app-top-menu></app-top-menu>

    <div class="rollit-grid">

      <div class="rollit-grid-3">

        <div class="holder">

          <a *ngIf="showLogout" mat-button color="primary" class="rollit-button" (click)="onLogout()">Log out</a>
          <div class="logo"><img src="/assets/images/hey-coach-logo-splash.png" /></div>
          <h1>Employer Administration</h1>

          <h4 *ngIf="employers?.length === 0">You do not administer any employers</h4>

          <div class="wrapper" *ngIf="employers?.length > 0 && !employer">
            <mat-form-field class="mat-form-field-wide">
              <mat-label>Select employer</mat-label>
              <mat-select (selectionChange)="onEmployerSelect($event)">
                <mat-option *ngFor="let e of employers; trackBy: trackById" [value]="e">
                  ({{e.id}}) {{e.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="wrapper" *ngIf="employers?.length > 0 && employer">
            <div class="row">
              <div *ngIf="employers?.length > 1; then selectBlock else headingBlock"></div>
              <ng-template #selectBlock>
                <mat-form-field class="mat-form-field-wide">
                  <mat-select (selectionChange)="onEmployerSelect($event)" [(ngModel)]="employer">
                    <mat-option *ngFor="let e of employers; trackBy: trackById" [value]="e">
                      ({{e.id}}) {{e.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-template>

              <ng-template #headingBlock>
                <h4>{{employer?.name}}</h4>
              </ng-template>

              <a mat-raised-button color="primary" *ngIf="!hasPremiumFeature" class="rollit-button upgrade-button"
                [routerLink]="['/subscription/upgrade/',employer.id]">Upgrade now</a>
            </div>
            <div class="navigation">
              <a routerLink="dashboard" routerLinkActive="active">Dashboard</a>
              <a routerLink="onboard" routerLinkActive="active">Onboard</a>
              <a routerLink="payroll" routerLinkActive="active">Payroll</a>
              <a routerLink="subscriptions" routerLinkActive="active">Subscriptions</a>
              <a routerLink="billing" routerLinkActive="active">Billing</a>
            </div>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
