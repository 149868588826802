<mat-form-field appearance="outline" class="input-select">
  <mat-label *ngIf="prompt && multi" class="paragraph1">{{prompt}}</mat-label>
  <mat-error *ngIf="hasError('required')">
    Field is required
  </mat-error>

  <mat-select placeholder="{{placeholder}}" [formControl]="formControl" (selectionChange)="handleSelect($event.value)"
    [ngClass]="{'disabled':selectedAccount === null}">
    <mat-option *ngFor="let ac of accounts" value="{{ac.code}}">
      {{ac.name}}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="description paragraph2" [ngClass]="{'error':!isValid,'single': !multi}">{{ description }}</div>
