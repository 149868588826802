import { Component, OnInit, OnDestroy, NgZone, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { MeService } from '@rollit/shared/data';
import { FileService } from '@rollit/shared/data';
import { NotificationService } from '@rollit/shared/data';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from '@rollit/shared/utils';
import { LoggerService } from '@rollit/shared/data';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { User, UserProfile, MailingAddress } from '@rollit/shared/data';

declare var google: any;


@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class PersonalInformationComponent implements OnInit, OnDestroy {
  fileUrl = this.environment.apiUrl + '/file';
  private log: any;
  // User
  me: User;
  profile: UserProfile;
  titleFormGroup: FormGroup;
  firstNameFormGroup: FormGroup;
  lastNameFormGroup: FormGroup;
  sexFormGroup: FormGroup;
  dobFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  mobileFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  // Hide TFN until further notice
  // tfnFormGroup: FormGroup;
  passwordGroup: FormGroup;
  today = new Date();

  editMode = {
    title: false,
    firstName: false,
    lastName: false,
    sex: false,
    dob: false,
    email: false,
    mobile: false,
    address: false,
    abn: false,
    employeeNumber: false,
    // Hide TFN until further notice
    // tfn: false,
    password: false,
  };

  @ViewChild('hiddenButton') hiddenButton;
  @ViewChild('hiddenPasswordButton') hiddenPasswordButton;

  // Profile image
  avatar: string = '/assets/images/default-profile.jpg';
  uploader: FileUploader = new FileUploader({ url: this.fileUrl });
  public hasBaseDropZoneOver: boolean = false;
  selectedFileName = '';

  // Location services
  placesService;
  autocompleteService;

  // Options which are populated with autocomplete places
  autoCompleteOptions;

  // Make sure that when we programmatically update the location search input value we do not perform another auto-complete request
  autocompleteJustUpdated = false;

  // True when requesting autocomplete values
  autocompleteLoadingValues = false;

  // Utils
  ngUnsubscribe = new Subject();
  isDesktop: boolean;

  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private _builder: FormBuilder,
    private userService: MeService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private uiService: UIManagerService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private logger: LoggerService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    public dialog: MatDialog,

  ) {
    this.log = this.logger.info('personalInformation');
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isDesktop => this.isDesktop = isDesktop);
  }

  /**
   * Called when the component is initialised.
   */
  ngOnInit() {
    this.userService.me$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      value => {
        if (value) {
          this.me = value;
          if (value.picture && value.picture.id) {
            this.fileService.getFilePath(value.picture.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
              path => {
                this.avatar = path;
              }
            );
          }
        }
      }
    );
    this.userService.getProfile().subscribe(
      value => {
        this.profile = value;
        this.setupPersonalInformationForm();
        this.setUpPasswordForm();
      }
    );
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.log('item uploaded' + response);
      this.notificationService.info('Upload successful', 'Your avatar has been saved.');
      this.uploader.progress = 0;
    };

  }

  private loadGoogleMaps() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('googleMaps').then(data => {
      this.log('dynamicScriptLoader', data);
      this.log('google', google);
      setTimeout(() => this.setUpAddressLookup(), 100);
    }).catch(error => this.log(error));
  }

  /**
   * Called when the component is destroyed.
   */
  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  setupPersonalInformationForm() {
    this.titleFormGroup = new FormGroup({
      title: new FormControl(this.profile.title, Validators.required),
    });
    this.firstNameFormGroup = new FormGroup({
      firstName: new FormControl(this.profile.firstName, Validators.required),
    });
    this.lastNameFormGroup = new FormGroup({
      lastName: new FormControl(this.profile.lastName, Validators.required),
    });
    this.sexFormGroup = new FormGroup({
      sex: new FormControl(this.profile.sex, Validators.required),
    });
    let dob;
    if (this.profile.dob) {
      dob = new Date(Date.parse(this.profile.dob));
    }
    this.dobFormGroup = new FormGroup({
      dob: new FormControl(dob, Validators.required),
    });
    this.emailFormGroup = new FormGroup({
      email: new FormControl(this.profile.email, Validators.compose([Validators.required, Validators.email])),
    });
    const mobPattern = '^(?:\\+?(61))? ?(?:\\((?=.*\\)))?(0?[2-57-8])\\)? ?(\\d\\d(?:[- ](?=\\d{3})|(?!\\d\\d[- ]?\\d[- ]))\\d\\d[- ]?\\d[- ]?\\d{3})$';
    this.mobileFormGroup = new FormGroup({
      mobile: new FormControl(this.profile.phone, [Validators.required]),
    });
    this.addressFormGroup = new FormGroup({
      address1: new FormControl(this.profile.homeAddress.street1, Validators.required),
      postcode: new FormControl(this.profile.homeAddress.postcode, Validators.required),
      address2: new FormControl(this.profile.homeAddress.town, Validators.required),
      address3: new FormControl(this.profile.homeAddress.state, Validators.required),
    });
    // Hide TFN until further notice
    // this.tfnFormGroup = new FormGroup({
    //  tfn: new FormControl(this.profile.tfn, Validators.required)
    // });

    // Set address lookup now that the address control has been created/reset. Otherwise addressControl.valueChanges subscriber event doesn't fire
    this.loadGoogleMaps();
  }

  setUpPasswordForm() {
    this.passwordGroup = this._builder.group(
      {

      });
  }

  setUpAddressLookup() {
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.addressFormGroup.controls['address1'].valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (val && !this.autocompleteJustUpdated) {
        const autocompleteRequest = {
          input: val,
          types: ['address'],
          componentRestrictions: { country: 'au' }
        };
        this.autocompleteLoadingValues = true;
        this.autocompleteService.getPlacePredictions(autocompleteRequest, res => {
          // ngZone makes sure the autoCompleteOptions array change is picked up by angular, otherwise dropdown of options doesn't appear
          this.ngZone.run(() => {
            this.autocompleteLoadingValues = false;
            this.autoCompleteOptions = res;
          });
        });
      } else {
        this.autocompleteJustUpdated = false;
      }
    });
  }

  getAddressDetails(option) {
    const detailRequest = {
      placeId: option.place_id,
      fields: ['address_component']
    };
    // Get place address components using place id
    this.placesService.getDetails(detailRequest, response => {
      // Make sure angular knows about this callback running. Otherwise input the field's placeholders overlap their value
      this.ngZone.run(() => {
        if (response) {
          this.setAddressFormControls(response.address_components, option);
        }
      });
    });
  }

  setAddressFormControls(addressComponents, selectedOption) {
    if (addressComponents) {
      // Form Controls
      const address1Ctrl = this.addressFormGroup.controls['address1'];
      const address2Ctrl = this.addressFormGroup.controls['address2'];
      const address3Ctrl = this.addressFormGroup.controls['address3'];
      const postcodeCtrl = this.addressFormGroup.controls['postcode'];
      // Lookup Values
      const subpremiseObj = addressComponents.find(component => component.types.includes('subpremise'));
      const subpremise = subpremiseObj ? subpremiseObj.long_name : null;
      const streetNumberObj = addressComponents.find(component => component.types.includes('street_number'));
      const streetNumber = streetNumberObj ? streetNumberObj.long_name : null;
      const streetNameObj = addressComponents.find(component => component.types.includes('route'));
      const streetName = streetNameObj ? streetNameObj.long_name : null;
      const localityObj = addressComponents.find(component => component.types.includes('locality'));
      const locality = localityObj ? localityObj.long_name : null;
      const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
      const state = stateObj ? stateObj.long_name : null;
      const postcodeObj = addressComponents.find(component => component.types.includes('postal_code'));
      const postcode = postcodeObj ? postcodeObj.long_name : null;

      // Make sure the user entered a street number, if not let them know one is required
      if (!streetNumber) { return this.notificationService.error('Error', 'Please enter a building number'); }

      // Exit with error if values not found
      if (!locality || !state || !postcode) { return this.notificationService.error('Error', 'Couldn\'t find that address'); }

      let address1Val = '';
      // BUGFIX - Places Autocomplete API is not designed to support 'subpremise' results. This code makes sure that the subpremise string
      // is displayed in the street name and number input.
      if (selectedOption.types.includes('route')) {
        // get all the user entered values before a match with the first word from the lookup result
        const regex = RegExp('^(.*)' + streetNumber.split(' ', 1)[0]);
        const result = regex.exec(selectedOption.description);
        if (Array.isArray(result)) {
          address1Val = result[1] + streetNumber + ' ' + streetName; // add the street name to the user-entered unit & street number
        } else { return this.notificationService.error('error', 'Couldn\'t find that address'); }
      } else {
        if (subpremise) {
          address1Val += subpremise + ' ';
        }
        if (streetNumber) {
          address1Val += streetNumber + ' ';
        }
        if (streetName) {
          address1Val += streetName;
        }
      }

      // Set form input values from result
      address1Ctrl.setValue(address1Val);
      address2Ctrl.setValue(locality);
      address3Ctrl.setValue(state);
      postcodeCtrl.setValue(postcode);
      this.autocompleteJustUpdated = true;
      // Empty autocomplete options
      this.autoCompleteOptions = [];
    } else { return this.notificationService.error('Error', 'Couldn\'t find that address'); }
  }

  onSubmitPersonalInformation(submittedGroup: FormGroup, key: string) {
    this.log('onSubmitPersonalInformation', submittedGroup, key);
    if (submittedGroup.valid) {
      this.editMode[key] = false;
      this.cdr.detectChanges(); // Prevent ExpressionChangedAfterItHasBeenCheckedError
      const homeAddress: MailingAddress = {
        street1: this.addressFormGroup.controls['address1'].value || this.profile.homeAddress.street1,
        postcode: this.addressFormGroup.controls['postcode'].value || this.profile.homeAddress.postcode,
        town: this.addressFormGroup.controls['address2'].value || this.profile.homeAddress.town,
        state: this.addressFormGroup.controls['address3'].value || this.profile.homeAddress.state,
      };
      const dob = this.dobFormGroup.controls['dob'].value || this.profile.dob;
      this.log('dob', dob);
      let dobSimplifiedISO;
      if (dob !== undefined) {
        const res = dob.toString().substring(0, 15);
        let dobISO;

        if (dob) {
          dobISO = new Date(res + " UTC").toISOString();
          dobSimplifiedISO = dobISO.split('T')[0];
        }
      } else {
        dobSimplifiedISO = null;
      }

      const updatedUser: UserProfile = {
        title: this.titleFormGroup.controls['title'].value || this.profile.title,
        firstName: this.firstNameFormGroup.controls['firstName'].value || this.profile.firstName,
        lastName: this.lastNameFormGroup.controls['lastName'].value || this.profile.lastName,
        sex: this.sexFormGroup.controls['sex'].value || this.profile.sex,
        dob: dobSimplifiedISO,
        email: this.emailFormGroup.controls['email'].value || this.profile.email,
        phone: this.mobileFormGroup.controls['mobile'].value || this.profile.phone,
        homeAddress: homeAddress,
        // Hide TFN until further notice
        // tfn: this.tfnFormGroup.controls['tfn'].value || this.profile.tfn,
      };
      this.userService.updateProfile(updatedUser).subscribe(userRes => {
        this.profile = userRes;
        this.notificationService.info('Success', 'Profile updated');
        this.setupPersonalInformationForm(); // Reset form with new values
      });
    }
  }



  onSubmitPassword(key: string) {
    if (this.passwordGroup.valid) {
      this.editMode[key] = false;
      this.cdr.detectChanges(); // Prevent ExpressionChangedAfterItHasBeenCheckedError
      const updatedPassword: User = {
        password: this.passwordGroup.controls['password'].value
      };
      this.userService.updateMe(updatedPassword).subscribe(res => this.log('me updated', res));
    }
  }

  onCompleteClicked() {
    this.hiddenButton.nativeElement.click();
  }

  onPasswordCompleteClicked() {
    this.hiddenPasswordButton.nativeElement.click();
  }

  focusInput(input) {
    // Timeout to allow input to setup
    this.log('focusInput', input);
    setTimeout(() => input.focus(), 200);
  }

  focusSelect(input) {
    // Timeout to allow input to setup
    this.log('focusSelect', input);
    setTimeout(() => input.open(), 200);
  }

  openChangePassword() {
    // prompt to change password.  This is commented out for now.
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'password-dialog',
      data: {
        url: this.environment.keycloak.url + '/realms/rollit/account/password',
        email: this.profile.email,
        profile: this.profile
      }
    });
  }
}
