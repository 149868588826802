
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountType, DialogService, Fact, LoggerService, MoneyAccount, MoneyService, TimeIntervalTable, ValueType } from '@rollit/shared/data';
import * as moment_ from 'moment';
import { Observable } from 'rxjs';

const moment = moment_;

@Injectable()
export class LoanService {
  log: any;
  sessionId: string;

  constructor(
    private moneyService: MoneyService,
    private dialogService: DialogService,
    private router: Router,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('LoanService');
  }

  getCreditCards(): Observable<MoneyAccount[]> {
    return this.moneyService.getAccounts({ type: 'creditcard' });
  }

  getLoans(): Observable<MoneyAccount[]> {
    return this.moneyService.getAccounts({ type: 'loan' });
  }

  getAccounts(): Observable<MoneyAccount[]> {
    return this.moneyService.getAccounts({ type: ['loan', 'creditcard'] });
  }

  getCreditCardsProjections(): Observable<TimeIntervalTable> {
    const category = 'creditcard';
    return this.moneyService.getProjections(category);
  }

  getLoansProjections(): Observable<TimeIntervalTable> {
    const category = 'loan';
    return this.moneyService.getProjections(category);
  }

  /**
   * Fetch projections for property account.
   * 
   * @param accountId 
   */
  getAccountProjections(accountId: number): Observable<TimeIntervalTable> {
    const from = moment().startOf('month');
    return this.moneyService.getAccountProjections(accountId, from, null);
  }

  /**
   * Start a dialog to update realty and mortgage accounts. 
   * 
   * @param id The ID of the existing property account.
   */
  startAddLoan() {
    const facts: Fact[] = [];
    this.startNewSurvey('track', 'loan', facts, '_first');
  }

  startUpdateLoan(accountId: number) {
    const facts: Fact[] = [{ slug: 'debt_loan_id', type: ValueType.Long, value: accountId, '@type': 'fact' }];  // the property ID fact
    this.startNewSurvey('track', 'loan', facts, '_first');
  }

  /**
   * Start dialog to create a new debt-related goal.
   */
  startNewGoal(account: MoneyAccount) {
    this.log('starting new debt goal');
    const facts: Fact[] = [{slug: 'goal_category', type: ValueType.String, value: 'debt', '@type': 'fact'}];

    // add account id to facts
    if (account && account.id) {      // add account_id fact
      facts.push({slug: 'goal_account', type: ValueType.MoneyAccount, value: account, '@type': 'fact'});
    }

    // choose goal_type to match account type and subtype; 'debt_car', 'debt_creditcard', 'debt_personal', 'debt_other'
    let goalType = 'debt_other';
    if (account.accountType == AccountType.creditcard) {
      goalType = 'debt_creditcard';
    }
    else if (account.accountType == AccountType.loan) { 
      if (account.accountSubtype == 'vehicle_loan') {
        goalType = 'debt_car';
      }
      else {
        goalType = 'debt_personal';
      }
    }
    facts.push({slug: 'goal_type', type: ValueType.String, value: goalType, '@type': 'fact'});

    // TODO should we force or prompt for a goal name ????  
    this.startNewSurvey('goals', 'new', facts /*, 'goal_name'*/); // if we set section to 'goal_name', the survey will prompt for goal name
  }

  /**
   * Start a new dialog session.
   * 
   * @param module Dialog module
   * @param dialog Dialog slug.
   * @param facts initial facts to insert into the session.
   * @param section (optional) the section to show first. "_first" forces first section to show. If undefined, the earliest unanswered section is shown.
   */
     public startNewSurvey(module: string, dialog: string, facts: Fact[], section?: string) {
      this.sessionId = this.dialogService.generateSessionId();
      const returnPath = this.router.url;
      let queryParams = { 'session': this.sessionId, 'return': returnPath };
      if (section) {
        queryParams['start'] = section;
      }
  
      // initialise dialog with existing facts
      this.dialogService.initDialog(module, dialog, facts, this.sessionId).subscribe(result => {
        this.router.navigate(['survey', module, dialog], { queryParams });      // navigate to survey path
      });
    }
  
}
