import { APP_ENVIRONMENT } from '@rollit/shared';
import { Inject, Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { LoggerService } from '../other/logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoanScore0, Account as LAccount } from '../model/loan';

@Injectable()
export class LoanService {
    apiUrl = this.environment.apiUrl;
    private log: any;
    private _loanScoreSubject = new BehaviorSubject<LoanScore0>(null);
    private _fetchingLoanScore: boolean = false;

    constructor(
        private http: HttpClient,
        private logger: LoggerService,
        @Inject(APP_ENVIRONMENT) private environment: any
    ) {
        this.log = this.logger.info('LoanService');
    }

    public get loanScore$(): Observable<LoanScore0> {
        if (this._loanScoreSubject.getValue() == null && !this._fetchingLoanScore) {
            this.log('fetching loan score for subject');
            this.getLoanScore().subscribe();
        }

        return this._loanScoreSubject.asObservable();
    }

    /**
     * Get existing loan score for the user.
     */
    public getLoanScore(refresh?: boolean): Observable<LoanScore0> {
        const path = '/me/loan/score';

        this.log('fetching existing loan score');
        this._fetchingLoanScore = true;

        let params = new HttpParams();
        if (refresh) {
            params = params.set('refresh', '' + refresh);
        }

        return this.http.get<any>(this.apiUrl + path, { params: params }).pipe(
            map(value => {
                this._loanScoreSubject.next(value);
                this._fetchingLoanScore = false;
                return value;
            })
        );
    }

    /**
     * Create a user on the UNO service, and return a report with a URL to report that can be sent to the
     * user.
     *
     * @param request A locan score request
     */
    public calculateLoanScore(request: LAccount): Observable<LoanScore0> {
        const path = '/me/loan/score';

        this.log('submitting new loan score request', request);
        return this.http.put<any>(this.apiUrl + path, request).pipe(
            map(value => {
                this._loanScoreSubject.next(value);
                this._fetchingLoanScore = false;
                return value;
            })
        );
    }

    public getLenders(): Observable<string[]> {
        const path = '/assets/data/lenders.json';

        this.log('fetching lenders');

        return this.http.get<string[]>(path);
    }

}
