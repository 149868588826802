import { Component, Renderer2, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UIManagerService, ItemLink } from '@rollit/shared/services';
import { FileService, LoggerService } from '@rollit/shared/data';
import { takeUntil } from 'rxjs/operators';
import { RealtyService } from '@rollit/shared/data';
import { LoanService } from '@rollit/shared/data';
import { MeService } from '@rollit/shared/data';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Features } from '@rollit/shared/data';
import { UserProfile } from '@rollit/shared/data';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { PlatformService } from '@rollit/shared/data';
import { HttpClient } from '@angular/common/http';
import { StriphtmlPipe } from '@rollit/shared/utils';
import { MobilePromptDialogComponent } from '../mobile-prompt-dialog/mobile-prompt-dialog.component';
import { MatDialog } from '@angular/material/dialog';

declare var Intercom; // defined by included Intercom javascript.

interface TokenResponse {
  expires_in: number;
  access_token: string;
  path: string;
  token_type: string;
  stores: string[];
}

@Component({
  selector: 'app-panel-navigation',
  templateUrl: './panel-navigation.component.html',
  styleUrls: ['./panel-navigation.component.scss']
})
export class PanelNavigationComponent implements OnInit, OnDestroy {
  activeNavigationItem: number;
  itemLinks: ItemLink[] = this.uiManagerService.mainLinks.default;
  profile: UserProfile;
  isHome: boolean = false;
  private log: any;
  private isDestroyed = new Subject();
  hasPremiumFeature = null;
  showUpgradeMobile = false;
  showUpgrade = false;
  rewardsUrl = this.environment.mba.url;
  isProd = this.environment.production;
  apiUrl = this.environment.apiUrl;
  defaultPic = '/assets/images/default-profile.jpg';   // default profile pic
  profilePicUrl: string = '/assets/images/default-profile.jpg';
  loadedProfile: boolean;
  mediaSizeIsDesktop: Boolean = false;
  sideNavIsCollapsed: Boolean;
  itemsReady: boolean;

  constructor(private uiManagerService: UIManagerService,
    private realtyService: RealtyService,
    private renderer: Renderer2,
    private userService: MeService,
    private loanService: LoanService,
    private logger: LoggerService,
    private router: Router,
    private platformService: PlatformService,
    private http: HttpClient,
    private dialog: MatDialog,
    private fileService: FileService,
    @Inject(APP_ENVIRONMENT) private environment: any) {

    this.log = this.logger.info('PanelNavigationComponent');

    this.showUpgradeMobile = this.environment.embedded.inAppPurchases || !(this.platformService.embedded && this.platformService.isIos);

    this.uiManagerService.sideNavIsCollapsed.pipe(takeUntil(this.isDestroyed)).subscribe(sideNavIsCollapsed => this.sideNavIsCollapsed = sideNavIsCollapsed);

    this.uiManagerService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.mediaSizeIsDesktop = mediaSizeIsDesktop;
    });

    this.userService.hasPremiumFeature$().pipe(takeUntil(this.isDestroyed)).subscribe(
      hasPremiumFeature => {
        this.log('hasPremiumFeature', hasPremiumFeature);
        this.hasPremiumFeature = hasPremiumFeature;
        this.showUpgrade = this.hasPremiumFeature != null && !this.hasPremiumFeature && this.showUpgradeMobile;
      }
    );

    this.userService.me$.pipe(takeUntil(this.isDestroyed)).subscribe(profile => {
      this.log('home profile', profile);
      if (profile) {
        this.profile = profile;
        if (profile.picture) {
          this.getFilePath(profile.picture.id);
        }
      }
    });

    // show or hide property menu item, depending on whether user has property feature enabled
    this.userService.hasSomeFeature$([Features.PROPERTY_BASIC, Features.PROPERTY_PREMIUM]).pipe(takeUntil(this.isDestroyed)).subscribe(hasPropertyFeature => {
      this.itemLinks.forEach((item) => {
        if (item.rLink === '/survey/setup') {
          item.hide = this.isProd;
        }
      });
      this.itemsReady = true;
    });


    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // this.log('event', event);
        this.setActiveLink(event.url);
      }
      // see also 

    });

  }

  ngOnInit(): void {
    this.log('init');

  }

  ngOnDestroy() {
    this.onCloseDrawerNavigation();
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  setActiveLink(url) {

    this.itemLinks.forEach((item) => {
      item.active = false;
      // this.log(url + item.rLink);
      if (url.indexOf(item.rLink) !== -1) {
        item.active = true;
      }
    });
  }

  getFilePath(id) {
    this.fileService.getFilePath(id).subscribe(
      path => {
        this.profilePicUrl = path != null ? path : this.defaultPic;
        this.loadedProfile = true;
      }
    );
  }

  onNavClick(item: ItemLink, event) {
    this.log('onNavClick', item.rLink, item.rList, item.rDetail, item.params, event);
    this.onCloseDrawerNavigation();
    event.preventDefault();
    event.stopPropagation();
    if (item.rLink === '/reward' && this.showUpgrade) {
      // be cool
      this.log('onNavClick upgrade');
    } else if (item.rLink === '/reward') {
      this.log('onNavClick mba');
      this.onRewardsClick();
    } else {
      if (item.params) {
        this.router.navigate([item.rLink], { queryParams: item.params });
      } else if (item.rList !== null) {
        this.router.navigate([item.rLink, { 'outlets': { 'list': [item.rList], 'detail': [this.mediaSizeIsDesktop ? item.rDetail : 'none'] } }]);
      } else {
        this.router.navigate([item.rLink]);
      }
    }
  }

  onProfileClick() {
    this.onCloseDrawerNavigation();
    this.router.navigate(['/profile', { 'outlets': { 'list': ['profile-links'], 'detail': [this.mediaSizeIsDesktop ? 'personal-information' : 'none'] } }]);

  }

  onCloseDrawerNavigation() {
    this.uiManagerService.drawerNavigationIsOpen = false;
  }

  onRewardsClick() {
    this.log('profile', this.profile);
    this.log('rewards clicked. embedded? ', this.platformService.embedded);

    if (!this.profile.phone || this.profile.phone.length < 10) {
      this.openLoanChoiceDialog();
    } else {
      this.openRewards();
    }

  }

  openLoanChoiceDialog() {
    const dialogRef = this.dialog.open(MobilePromptDialogComponent, {
      width: '400px', data: {
        userProfile: this.profile,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.phone.length === 10) {
        this.openRewards();
      }
    });
  }

  openRewards() {
    if (this.platformService.embedded) {
      this.fetchToken().subscribe(response => {
        this.log('got rewards token response', response);
        let url = this.rewardsUrl + response.path + '?token=' + response.access_token;
        this.log('opening rewards url', url);
        Intercom('trackEvent', 'web-app-reward-home-click-myreweards-open');
        window.open(url, 'rollit-rewards');                      // load URL in rewards page
      },
        err => {
          this.log('Problem loading rewards', err);
        });
    }
    else {
      let r = Math.random().toString(36).substring(7);
      const newPage = window.open('', 'rollit-rewards ' + r);  // open the page first, to avoid popup being blocked.
      newPage.document.writeln('<div style="margin:100px auto;width:200px;display:flex;flex-direction:column;align-items:center;justify-content:center"><img style="margin:10px;" src="//app.rollitsuper.com/assets/icons/loading-icon.svg" /> <p style="font-family:arial;margin-left:10px;color:#1B365F">Loading rewards...</p></div> ');

      // navigate to the "My Rewards" rewards site.
      this.fetchToken().subscribe(response => {
        this.log('got rewards token response', response);
        let url = this.rewardsUrl + response.path + '?token=' + response.access_token;
        this.log('opening rewards url', url);
        const agent = window.navigator.userAgent.toLowerCase();
        const isIE = agent.indexOf('trident') > -1;
        if (isIE) {
          this.log('intercept ie');
          url = this.rewardsUrl;
        }
        Intercom('trackEvent', 'web-app-reward-home-click-myreweards-open');
        newPage.location.href = url;                      // load URL in rewards page
      },
        err => {
          newPage.document.writeln('Problem loading rewards.');
          this.log('Problem loading rewards', err);
          newPage.close();
        });
    }
  }

  onClick(url: string): void {
    const striphtml = new StriphtmlPipe();
    url = striphtml.transform(url);
    window.open(url, '_blank');
    Intercom('trackEvent', 'web-app-reward-home-click');
  }

  fetchToken(): Observable<TokenResponse> {
    const path = '/me/rewards/mba/token';
    return this.http.get<TokenResponse>(this.apiUrl + path);
  }

  fetchRewardsSite(token: string) {
    const url = this.rewardsUrl + token;
    this.http.get<any>(url).subscribe(
      response => {
        this.log('got rewards response', response);
      },
      err => {
        this.log('got rewards error', err);
      },
      () => {
        this.log('rewards complete');
      }
    );
  }

}
