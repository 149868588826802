import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Router } from '@angular/router';

/**
 * Display messages to the user.  Currently uses Snackbar.
 */
@Injectable()
export class NotificationService {
  private log: any;
  constructor(private snack: MatSnackBar, private logger: LoggerService, private router: Router) {
    this.log = this.logger.info('notificationService');
  }

  /**
   * Display information to the user.
   *
   * @param title A title for the notification
   * @param message Extra message for the notification
   */
  public info(title: string, message?: string, route?: string) {

    const text = message ? title + ': ' + message : title;
    const snackRef = this.snack.open(text, 'OK', { duration: 5000 });
    snackRef.onAction().subscribe(() => {
      snackRef.dismiss();
      if (route !== undefined) {
        this.log('Snackbar was pressed.' + route);
        this.router.navigate([route]);
      }
    });

  }

  /**
   * Display an error to the user.
   */
  public error(title: string, err?: string | HttpErrorResponse): void {
    let name = null;
    let message = null;
    if (typeof err === 'string') {
      message = err;
    } else if (err) {
      name = err.error && err.error.name ? err.error.name : err.name;
      message = err.error && err.error.message ? err.error.message : err.message;
    }

    // Shorten urls in the message
    if (message && message.replace) {
      const urlRegex = new RegExp(/(https?:\/\/[^\s]+)/g);
      message = message.replace(urlRegex, (url) => {
        return url.slice(0, 8) + '...';
      });
    }

    let text = name ? title + ': ' + name : title;
    if (message) {
      text += ' : ' + message;
    }

    const snackRef = this.snack.open(text, 'OK', { duration: 10000 });
    snackRef.onAction().subscribe(() => snackRef.dismiss());
  }

}
