<svg version="1.1" baseProfile="full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 100 170" preserveAspectRatio="xMidYMid meet">

  <mask id="homeStateMask">
    <rect x="0" y="0" width="100" height="100" fill="white" />
    <rect attr.x="{{mapMaskXPos}}" y="0" class="percent-segment" attr.width="{{homeOwnershipStatePercentageFinal}}"
      height="100" fill="black" />
  </mask>

  <g *ngIf="profileAddressState === 'NSW'">
    <path class="NSW" fill="#0d71b9"
      d="M 2.2312221,10 0,53.898523 6.5026363,54.708487 15.236494,62.02109 v 3.86643 l 8.741498,7.51891 h 6.112936 l 4.470085,1.42126 h 13.211584 l 0.809964,1.62757 c 0,0 0.404982,6.50263 1.016276,6.50263 0.611293,0 5.280048,4.66876 5.280048,4.66876 L 61.381521,89.86551 67.280504,75.43898 92.886067,36.621837 100,20.575379 V 15.48636 l -8.351801,-1.826239 -7.113932,6.112936 -5.685031,-5.486361 -11.179032,-1.627569 -3.858791,3.239856 z" />
    <path class="NSW" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 2.2312221,10 0,53.898523 6.5026363,54.708487 15.236494,62.02109 v 3.86643 l 8.741498,7.51891 h 6.112936 l 4.470085,1.42126 h 13.211584 l 0.809964,1.62757 c 0,0 0.404982,6.50263 1.016276,6.50263 0.611293,0 5.280048,4.66876 5.280048,4.66876 L 61.381521,89.86551 67.280504,75.43898 92.886067,36.621837 100,20.575379 V 15.48636 l -8.351801,-1.826239 -7.113932,6.112936 -5.685031,-5.486361 -11.179032,-1.627569 -3.858791,3.239856 z" />
  </g>

  <g *ngIf="profileAddressState === 'NT'">
    <path class="NT" fill="#0d71b9"
      d="M 28.623047 0.025390625 L 28.623047 1.1367188 L 26.623047 1.8027344 L 27.958984 3.578125 L 31.951172 3.578125 L 35.503906 0.025390625 L 30.839844 1.1367188 L 28.623047 0.025390625 z M 43.273438 1.1367188 L 43.496094 6.2421875 L 33.503906 6.6835938 L 23.070312 21.115234 L 24.847656 25.332031 L 20.853516 25.996094 L 24.847656 99.914062 L 77.458984 99.914062 L 78.570312 35.988281 L 61.646484 23.998047 L 71.90625 6.4648438 L 67.466797 6.4648438 L 66.138672 3.578125 L 59.480469 6.6835938 L 43.273438 1.1367188 z M 70.355469 15.787109 L 69.025391 18.003906 L 69.025391 19.779297 L 71.019531 19.779297 L 73.019531 21.115234 L 71.90625 18.003906 L 70.355469 15.787109 z " />
    <path class="NT" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 28.623047 0.025390625 L 28.623047 1.1367188 L 26.623047 1.8027344 L 27.958984 3.578125 L 31.951172 3.578125 L 35.503906 0.025390625 L 30.839844 1.1367188 L 28.623047 0.025390625 z M 43.273438 1.1367188 L 43.496094 6.2421875 L 33.503906 6.6835938 L 23.070312 21.115234 L 24.847656 25.332031 L 20.853516 25.996094 L 24.847656 99.914062 L 77.458984 99.914062 L 78.570312 35.988281 L 61.646484 23.998047 L 71.90625 6.4648438 L 67.466797 6.4648438 L 66.138672 3.578125 L 59.480469 6.6835938 L 43.273438 1.1367188 z M 70.355469 15.787109 L 69.025391 18.003906 L 69.025391 19.779297 L 71.019531 19.779297 L 73.019531 21.115234 L 71.90625 18.003906 L 70.355469 15.787109 z " />
  </g>

  <g *ngIf="profileAddressState === 'QLD'">
    <path class="QLD" fill="#0d71b9"
      d="M 40.400391 0.0703125 L 35.978516 8.7402344 L 35.623047 22.898438 L 26.597656 36.699219 L 15.449219 29.800781 L 14.742188 79.703125 L 28.720703 79.703125 L 27.839844 94.609375 L 63.408203 97.966797 L 65.705078 96.019531 L 72.785156 97.261719 L 75.792969 99.914062 L 79.513672 96.90625 L 84.644531 97.929688 L 83.402344 82.181641 L 76.503906 71.5625 L 75.619141 67.136719 L 71.548828 66.251953 L 68.009766 52.628906 L 56.503906 43.072266 L 56.154297 35.462891 L 54.027344 32.273438 L 53.146484 21.835938 L 49.691406 18.828125 L 46.597656 18.828125 L 43.587891 5.5566406 L 40.400391 0.0703125 z " />
    <path class="QLD" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 40.400391 0.0703125 L 35.978516 8.7402344 L 35.623047 22.898438 L 26.597656 36.699219 L 15.449219 29.800781 L 14.742188 79.703125 L 28.720703 79.703125 L 27.839844 94.609375 L 63.408203 97.966797 L 65.705078 96.019531 L 72.785156 97.261719 L 75.792969 99.914062 L 79.513672 96.90625 L 84.644531 97.929688 L 83.402344 82.181641 L 76.503906 71.5625 L 75.619141 67.136719 L 71.548828 66.251953 L 68.009766 52.628906 L 56.503906 43.072266 L 56.154297 35.462891 L 54.027344 32.273438 L 53.146484 21.835938 L 49.691406 18.828125 L 46.597656 18.828125 L 43.587891 5.5566406 L 40.400391 0.0703125 z " />
  </g>

  <g *ngIf="profileAddressState === 'SA'">
    <path class="SA" fill="#0d71b9"
      d="M 4 0 L 6.1210938 46.007812 L 21.529297 43.501953 L 31.929688 47.357422 L 35.974609 47.931641 L 38.292969 49.667969 L 53.890625 71.4375 L 67.572266 58.720703 L 70.078125 58.916016 L 65.646484 73.9375 L 73.351562 70.089844 L 73.738281 79.142578 L 79.707031 78.953125 L 81.630859 91.853516 L 89.917969 100.14062 L 95.115234 0 L 4 0 z " />
    <path class="SA" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 4 0 L 6.1210938 46.007812 L 21.529297 43.501953 L 31.929688 47.357422 L 35.974609 47.931641 L 38.292969 49.667969 L 53.890625 71.4375 L 67.572266 58.720703 L 70.078125 58.916016 L 65.646484 73.9375 L 73.351562 70.089844 L 73.738281 79.142578 L 79.707031 78.953125 L 81.630859 91.853516 L 89.917969 100.14062 L 95.115234 0 L 4 0 z " />
  </g>

  <g *ngIf="profileAddressState === 'TAS'">
    <path class="TAS" fill="#0d71b9"
      d="M 11.632812 0.16210938 L 4.2871094 11.375 L 18.207031 38.052734 L 16.660156 67.4375 L 22.072266 68.597656 L 34.445312 97.982422 L 46.818359 97.208984 L 49.910156 99.914062 L 54.550781 99.914062 L 80.841797 79.037109 L 86.253906 62.796875 L 95.533203 56.998047 L 95.533203 17.560547 L 45.271484 21.041016 L 11.632812 0.16210938 z " />
    <path class="TAS" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 11.632812 0.16210938 L 4.2871094 11.375 L 18.207031 38.052734 L 16.660156 67.4375 L 22.072266 68.597656 L 34.445312 97.982422 L 46.818359 97.208984 L 49.910156 99.914062 L 54.550781 99.914062 L 80.841797 79.037109 L 86.253906 62.796875 L 95.533203 56.998047 L 95.533203 17.560547 L 45.271484 21.041016 L 11.632812 0.16210938 z " />
  </g>

  <g *ngIf="profileAddressState === 'VIC'">
    <path class="VIC" fill="#0d71b9"
      d="M 2.6953125 10 L 0 70.830078 L 3.4179688 73.005859 L 3.7324219 73.9375 L 4.6640625 74.046875 L 5.1796875 73.628906 L 5.1796875 72.902344 L 12.330078 73.113281 L 25.597656 81.402344 L 39.792969 72.078125 L 38.134766 70.015625 L 42.902344 68.361328 L 43.732422 71.263672 L 40 75.902344 L 41.660156 77.5625 L 42.798828 77.355469 L 46.216797 79.839844 L 49.119141 80.154297 C 49.119141 80.154297 54.292929 85.855812 56.164062 87.201172 C 58.035197 88.546533 58.550781 85.748047 58.550781 85.748047 L 57.376953 84.09375 C 59.413035 82.846327 62.175781 83.160156 62.175781 83.160156 C 62.175781 83.160156 70.566346 76.716394 72.123047 75.427734 C 73.679748 74.139074 94.613281 72.078125 94.613281 72.078125 L 100 68.550781 L 82.175781 56.427734 L 81.660156 50.830078 L 77.707031 45.236328 L 60.087891 42.849609 C 56.567273 40.158887 49 38.912109 49 38.912109 L 44.650391 41.607422 L 41.556641 40.46875 L 37.619141 34.0625 L 28.603516 27.427734 A 40.144329 40.144329 0 0 0 27.251953 21.298828 C 26.422055 18.91223 23.005859 18.912109 23.005859 18.912109 L 20.103516 20.779297 L 13.056641 10 L 2.6953125 10 z " />
    <path class="VIC" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 2.6953125 10 L 0 70.830078 L 3.4179688 73.005859 L 3.7324219 73.9375 L 4.6640625 74.046875 L 5.1796875 73.628906 L 5.1796875 72.902344 L 12.330078 73.113281 L 25.597656 81.402344 L 39.792969 72.078125 L 38.134766 70.015625 L 42.902344 68.361328 L 43.732422 71.263672 L 40 75.902344 L 41.660156 77.5625 L 42.798828 77.355469 L 46.216797 79.839844 L 49.119141 80.154297 C 49.119141 80.154297 54.292929 85.855812 56.164062 87.201172 C 58.035197 88.546533 58.550781 85.748047 58.550781 85.748047 L 57.376953 84.09375 C 59.413035 82.846327 62.175781 83.160156 62.175781 83.160156 C 62.175781 83.160156 70.566346 76.716394 72.123047 75.427734 C 73.679748 74.139074 94.613281 72.078125 94.613281 72.078125 L 100 68.550781 L 82.175781 56.427734 L 81.660156 50.830078 L 77.707031 45.236328 L 60.087891 42.849609 C 56.567273 40.158887 49 38.912109 49 38.912109 L 44.650391 41.607422 L 41.556641 40.46875 L 37.619141 34.0625 L 28.603516 27.427734 A 40.144329 40.144329 0 0 0 27.251953 21.298828 C 26.422055 18.91223 23.005859 18.912109 23.005859 18.912109 L 20.103516 20.779297 L 13.056641 10 L 2.6953125 10 z " />
  </g>

  <g *ngIf="profileAddressState === 'WA'">
    <path class="WA" fill="#0d71b9"
      d="M 68.148438 0 L 58.955078 8.4589844 L 60.166016 12.539062 L 55.783203 12.539062 L 55.783203 16.625 L 52.158203 13.753906 L 49.138672 17.533203 L 50.625 22.212891 L 46.09375 29.154297 L 29.175781 36.578125 L 27.515625 36.578125 L 26.761719 38.064453 C 21.476118 42.293926 19.359375 46.677734 19.359375 46.677734 L 18 45.017578 L 18 53.322266 L 21.927734 61.027344 L 22.228516 62.386719 L 19.511719 64.955078 L 27.367188 75.460938 L 32.955078 87.138672 L 34.164062 93.181641 L 31.898438 95.166016 L 31.898438 97.734375 L 41.5625 100 L 48.652344 94.238281 L 62.535156 91.521484 L 64.955078 87.441406 L 66.314453 85.953125 L 71.767578 83.082031 L 74.486328 82.927734 L 76.294922 81.722656 L 82.339844 80.0625 L 78.560547 4.2285156 L 68.148438 0 z " />
    <path class="WA" fill="#bdbdbd" mask="url(#homeStateMask)"
      d="M 68.148438 0 L 58.955078 8.4589844 L 60.166016 12.539062 L 55.783203 12.539062 L 55.783203 16.625 L 52.158203 13.753906 L 49.138672 17.533203 L 50.625 22.212891 L 46.09375 29.154297 L 29.175781 36.578125 L 27.515625 36.578125 L 26.761719 38.064453 C 21.476118 42.293926 19.359375 46.677734 19.359375 46.677734 L 18 45.017578 L 18 53.322266 L 21.927734 61.027344 L 22.228516 62.386719 L 19.511719 64.955078 L 27.367188 75.460938 L 32.955078 87.138672 L 34.164062 93.181641 L 31.898438 95.166016 L 31.898438 97.734375 L 41.5625 100 L 48.652344 94.238281 L 62.535156 91.521484 L 64.955078 87.441406 L 66.314453 85.953125 L 71.767578 83.082031 L 74.486328 82.927734 L 76.294922 81.722656 L 82.339844 80.0625 L 78.560547 4.2285156 L 68.148438 0 z " />
  </g>


  <foreignObject height="50" width="26"
    attr.x="{{homeOwnershipStatePercentageFinal <= 74 ? homeOwnershipStatePercentageFinal : homeOwnershipStatePercentageFinal - 26}}"
    y="45">
    <xhtml:div class="title-chart" [ngClass]="{'light':homeOwnershipStatePercentageFinal > 73}">
      {{homeOwnershipStatePercentageFinal}}%
    </xhtml:div>
  </foreignObject>
  <foreignObject height="50" width="100%" x="0" y="109">
    <xhtml:div class="title">
      {{assetsHomeOwnershipStatePercentageResult}}
    </xhtml:div>
  </foreignObject>
</svg>
