
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, Fact, FundService, InvestmentOption, LoggerService, MoneyAccount, MoneyService, ReportService, SectionReport, TimeIntervalTable, ValueType } from '@rollit/shared/data';
import * as moment_ from 'moment';
import { Observable } from 'rxjs';

const moment = moment_;

@Injectable()
export class SuperannuationService {
  log: any;
  sessionId: string;

  constructor(
    private moneyService: MoneyService,
    private reportService: ReportService,
    private dialogService: DialogService,
    private fundService: FundService,
    private router: Router,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('SuperannuationService');
  }

  getAccounts(): Observable<MoneyAccount[]> {
    return this.moneyService.getAccounts({ type: 'superannuation' });
  }

  getProjections(): Observable<TimeIntervalTable> {
    const category = 'superannuation';
    return this.moneyService.getProjections(category);
  }

  getSummary(): Observable<SectionReport> {
    const from = moment().startOf('month');
    return this.reportService.getSectionSummary('superannuation', from);
  }

  /**
   * Fetch projections for property account.
   * 
   * @param accountId 
   */
  getAccountProjections(accountId: number): Observable<TimeIntervalTable> {
    const from = moment().startOf('month');
    return this.moneyService.getAccountProjections(accountId, from, null);
  }

  /**
   * Start a dialog to update realty and mortgage accounts. 
   * 
   * @param id The ID of the existing property account.
   */
  startAddAccount() {
    const facts: Fact[] = [];
    const returnPath = this.router.url;
    this.startNewSurvey('super', facts, returnPath);
  }

  startUpdateAccount(accountId: number) {
    const facts: Fact[] = [{ slug: 'assets_super_id', type: ValueType.Long, value: accountId, '@type': 'fact' }];  // the property ID fact
    const returnPath = this.router.url;
    this.startNewSurvey('super', facts, returnPath);
  }

  private startNewSurvey(dialog: string, facts: Fact[], returnPath: string) {
    this.sessionId = this.dialogService.generateSessionId();

    // initialise dialog with existing facts
    this.dialogService.initDialog('track', dialog, facts, this.sessionId).subscribe(result => {
      // redirect to survey path
      this.router.navigate(['survey', 'track', dialog], { queryParams: { 'session': this.sessionId, 'return': returnPath, 'start': '_first' } });
    });
  }

  sendDocs(option: InvestmentOption) {
    this.fundService.sendInvestmentDocs(option).subscribe(result => {
      this.log('Documents sent for ', option);
    });
  }
}
