import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileService, LoggerService, MoneyAccount, MoneyService } from '@rollit/shared/data';
import { ConfirmationDialogComponent, TitleItemData } from '@rollit/shared/common';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoanService } from '../../loan.service';
import { SCSS_VARS } from '@rollit/shared';
import * as moment_ from 'moment';
import { Router } from '@angular/router';
const moment = moment_;


@Component({
  selector: 'app-credit-cards-loans-hero',
  templateUrl: './credit-cards-loans-hero.component.html',
  styleUrls: ['./credit-cards-loans-hero.component.scss']
})
export class CreditCardsLoansHeroComponent implements OnInit, OnDestroy {
  @Input() acc: MoneyAccount;
  @Input() propertyListId: number;
  @Input() propertyCount: number;
  @Input() inView: boolean;
  @Output() clickNext = new EventEmitter();
  @Output() clickPrev = new EventEmitter();

  log: any;
  expanded = false;
  destroy = new Subject();
  isDesktop: boolean;
  imagePath: string;
  collateralExists = false;
  timescale: string;

  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Outstanding balance',
      data: 0,
      dataColor: SCSS_VARS['$rollit-negative'],
      isCurrency: true,
      hide: false
    },
    {
      title: 'Debt free',
      data: new Date(),
      dataColor: SCSS_VARS['$rollit-growth'],
      isDate: true,
      hide: true
    }


  ];


  projections: any;
  projectionChartData = [];
  projectionChartCategories = [];
  projectedDebtFreeDate: any;

  constructor(
    private logger: LoggerService,
    private dialog: MatDialog,
    private moneyService: MoneyService,
    private loanService: LoanService,
    private fileService: FileService,
    private uiService: UIManagerService,
    private router: Router,
  ) {
    this.log = this.logger.info('CreditCardsLoansHeroComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    if (this.acc?.properties?.collateral?.length > 0) {
      this.collateralExists = true;
    }
    this.setupPropertyImage();
    this.setupProjections();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }


  setupPropertyImage() {

    // TODO: Handle multiple image, requires a carousel


    this.moneyService.getAccountImages(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(accountImagesResult => {
      console.log('getAccountImages', accountImagesResult);

      if (accountImagesResult && accountImagesResult.length > 0) {
        this.fileService.getFilePath(accountImagesResult[0]['id']).pipe(takeUntil(this.destroy)).subscribe(
          path => {
            this.imagePath = path;
          }
        );
      } else {
        if (this.acc.accountSubtype === "vehicle_loan") {
          this.imagePath = "/assets/images/default/default_car_loan.jpg";
        } else if (this.acc.accountSubtype === 'lifestyle') {
          this.imagePath = "/assets/images/default/default_pay_personal_loan.jpg";
        } else if (this.acc.accountSubtype === 'creditcard') {
          this.imagePath = "/assets/images/default/default_cc.jpg";
        } else {
          this.imagePath = "/assets/images/default/default_pay_personal_loan.jpg";
        }
      }
    });
  }

  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.acc.currentBalance;
    this.titleGroupDetails[1]['data'] = this.projectedDebtFreeDate;
    this.log('projectedDebtFreeDate', this.projectedDebtFreeDate);
  }


  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  setupProjections() {
    this.loanService.getAccountProjections(this.acc.id).pipe(takeUntil(this.destroy)).subscribe(proj => {
      this.log('Got projections', proj);

      this.timescale = 'Age';

      const timedays = this.datediff(new Date(proj.data[0]['from']), new Date(proj.data[0]['to']));
      this.log('timedays', timedays);

      if (timedays < 32 || timedays < 365) {
        this.log('timescale months');
        this.timescale = 'Date';
      }

      this.projections = proj;
      const projections = proj;

      if (proj?.extra[0]?.debtFree) {
        this.projectedDebtFreeDate = proj.extra[0].debtFree;
        this.log('projectedDebtFreeDate', proj.extra[0].debtFree, proj);
        this.titleGroupDetails[1]['hide'] = false;
      } else {
        this.projectedDebtFreeDate = undefined;
      }

      const colorArray = [SCSS_VARS['$rollit-negative']];
      const chartCategoriesMap = [
        { short: 'PROP', section: 'property', title: 'Property' },
      ];
      const temp_array = [];

      projections.names.forEach((nameVal, nameIndex) => {
        const temp_object = {};
        const temp_data_array = [];

        temp_object['name'] = nameVal;
        chartCategoriesMap.forEach((chartVal, chartIndex) => {
          if (nameVal === chartVal.title) {
            this.log(nameVal + '===' + chartVal.title);
            temp_object['section'] = chartVal.section;
          }
        });

        if (nameVal === 'Net') {
          temp_object['type'] = 'spline';
          temp_object['borderColor'] = 'white';
          temp_object['borderWidth'] = 0.2;
          temp_object['zIndex'] = 5;
        } else {
          temp_object['type'] = 'column';
        }
        temp_object['color'] = colorArray[nameIndex];

        this.log(nameVal, nameIndex, colorArray[nameIndex]);

        projections.data.forEach((dataVal, dataIndex) => {
          if (this.timescale === 'Date') {
            const date = moment(projections.data[dataIndex].from.replace('+11:00', ''));
            const month = date.format('MMM');
            const year = date.format('YY');
            this.log('dates', projections.data[dataIndex].from);
            this.log('dates', this.acc.nickname, date);
            this.projectionChartCategories.push(month + ' ' + year);
          } else {
            this.projectionChartCategories.push(projections.data[dataIndex].label);
          }

          temp_data_array.push(projections.data[dataIndex].values[nameIndex]);
        });

        temp_object['data'] = temp_data_array;
        temp_array.push(temp_object);


      });
      this.log(temp_array);

      this.projectionChartData = temp_array;
      this.log('this.chartData', this.projectionChartData);

      this.setupTitleGroupDetails();

    });

  }


  // User actions

  graphClicked(category, name): void {
    this.log(category, name);
  }

  expandClicked() {
    this.expanded = !this.expanded;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  uploadComplete(file) {
    this.log('upload complete', file);
    this.moneyService.setAccountImages(this.acc.id, [file]).pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('uploadComplete', result);
    });
  }

  onEditAccount(accountId: number) {
    this.loanService.startUpdateLoan(accountId);
  }

  onRemoveAccount() {
    this.log('Remove account clicked');
    this.openRemovePropertyConfirmationDialog(this.acc.id.toString());
  }

  openRemovePropertyConfirmationDialog(accountId: string) {
    this.log('Delete dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Remove account',
        copy: 'Are you sure you want to remove this account?',
        buttonMessage: 'Yes, remove account',
        type: 'Loan',
        showCancel: true,
        checkboxLabel: 'Also remove associated asset',
        showCheckbox: this.collateralExists,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result[0] === 'accept') {
        this.removeAccount(accountId, result[1]);
      }
    });
  }

  removeAccount(propertyId: string, removeRelated: boolean): void {
    this.log('Remove property init');
    this.moneyService.removeAccount(this.acc.id, removeRelated).pipe(takeUntil(this.destroy)).subscribe(account => {
      this.log('Property removed', account);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/credit']));
    });
  }

  scrollPrev() {
    this.clickPrev.next();
    this.log('clickPrev');
  }

  scrollNext() {
    this.clickNext.next();
    this.log('scrollNext');
  }

  onCreateGoal(account: MoneyAccount) {
    this.loanService.startNewGoal(account);
  }

}
