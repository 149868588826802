import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

import { ResultList } from '../model/result';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { InvestmentOption, InvestmentProduct, InvestmentOptionFilter, RateHistogram } from '../model/fund';

@Injectable()
export class FundService {
  apiUrl = this.environment.apiUrl;
  private _fundUpdateDate = new ReplaySubject<string>(1);
  private _activeFundId = new ReplaySubject<string>(1);
  private _featuredFundListOrder = new ReplaySubject<number[]>(1);
  private _firstRankedFundInList = new ReplaySubject<InvestmentOption>(1);

  /**
   * Constructor
   */
  constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private environment: any) {
    this.activeFundId = null;
    this.featuredFundListOrder = null;
    this.firstRankedFundInList = null;
  }

  get fundUpdateDate$(): Observable<string> {
    return this._fundUpdateDate.asObservable();
  }
  set fundUpdateDate(value: string) {
    this._fundUpdateDate.next(value);
  }

  /**
   * The id of the fund currently being viewed, null when none active
   */
  get activeFundId$(): Observable<string> {
    return this._activeFundId.asObservable();
  }
  set activeFundId(value: string) {
    this._activeFundId.next(value);
  }

  /**
   * The order of the featured fund results. Used to keep the same order throughout the session.
   */
  get featuredFundListOrder$(): Observable<number[]> {
    return this._featuredFundListOrder.asObservable();
  }
  set featuredFundListOrder(value: number[]) {
    this._featuredFundListOrder.next(value);
  }

  get firstRankedFundInList$(): Observable<InvestmentOption> {
    return this._firstRankedFundInList.asObservable();
  }
  set firstRankedFundInList(value: InvestmentOption) {
    this._firstRankedFundInList.next(value);
  }

  public getProducts(fundId?: number, name?: string): Observable<ResultList<InvestmentProduct>> {
    const path = '/super/product';
    let params = new HttpParams();
    if (fundId) {
      params = params.set('fund', '' + fundId);
    }
    if (name) {
      params = params.set('name', '' + name);
    }

    return this.http.get<ResultList<InvestmentProduct>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch products that have been flagged as featured.
   */
  public getFeaturedProducts(count?: number): Observable<ResultList<InvestmentProduct>> {
    const path = '/super/product';
    let params = new HttpParams();
    params = params.set('featured', '' + true);
    params = params.set('max', count == null ? '5' : count.toString());

    return this.http.get<ResultList<InvestmentProduct>>(this.apiUrl + path, { params: params });
  }

  public getTopInvestmentOptions(count?: number): Observable<ResultList<InvestmentOption>> {
    const path = '/super/investment-option';
    const params = new HttpParams().set('max', count == null ? '5' : count.toString());

    return this.http.get<ResultList<InvestmentOption>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch a filtered list of investment options.
   */
  public getInvestmentOptions(filter: InvestmentOptionFilter, offset: number, max: number): Observable<ResultList<InvestmentOption>> {
    // console.log('getInvestmentOptions', filter, offset, max);
    const path = '/super/investment-option';
    let params = new HttpParams();
    if (filter.returnYears != null) {
      params = params.set('returnYears', '' + filter.returnYears);
    }
    if (filter.returnMin != null) {
      params = params.set('returnMin', '' + filter.returnMin);
    }
    if (filter.returnMax != null) {
      params = params.set('returnMax', '' + filter.returnMax);
    }
    if (filter.feesMin != null) {
      params = params.set('feesMin', '' + filter.feesMin);
    }
    if (filter.feesMax != null) {
      params = params.set('feesMax', '' + filter.feesMax);
    }
    if (filter.sectors != null && filter.sectors.length > 0) {
      for (const sector of filter.sectors) {
        params = params.append('sector', sector);
      }
    }
    if (filter.ethical != null) {
      params = params.set('ethical', '' + filter.ethical);
    }
    if (filter.indexed != null) {
      params = params.set('indexed', '' + filter.indexed);
    }
    if (filter.mySuper != null) {
      params = params.set('mySuper', '' + filter.mySuper);
    }
    if (filter.funds != null && filter.funds.length > 0) {
      for (const fundId of filter.funds) {
        params = params.append('fund', '' + fundId);
      }
    }
    if (filter.products != null && filter.products.length > 0) {
      for (const productId of filter.products) {
        params = params.append('product', '' + productId);
      }
    }
    if (filter.name != null) {
      params = params.set('name', '' + filter.name);
    }
    if (filter.countOnly != null) {
      params = params.set('countOnly', '' + filter.countOnly);
    }
    if (filter.sortField != null) {
      params = params.set('sortField', filter.sortField);
    }
    if (filter.sortDir != null) {
      params = params.set('sortDir', filter.sortDir);
    }
    if (offset != null) {
      params = params.set('offset', '' + offset);
    }
    if (max != null) {
      params = params.set('max', '' + max);
    }

    return this.http.get<ResultList<InvestmentOption>>(this.apiUrl + path, { params: params });
  }

  public getInvestmentOption(id: number): Observable<InvestmentOption> {
    const path = '/super/investment-option/' + id;
    return this.http.get<InvestmentOption>(this.apiUrl + path);
  }

  /**
   * Get an investment option, ranked in comparison to options that match the filter.
   * @param id The id of the investment option to compare.
   * @param filter To filter investment options to compare to.
   */
  public getInvestmentOptionCompared(id: number, filter: InvestmentOptionFilter): Observable<InvestmentOption> {
    const path = '/super/investment-option/' + id + '/compared';

    let params = new HttpParams();
    if (filter.returnYears != null) {
      params = params.set('returnYears', '' + filter.returnYears);
    }
    if (filter.feesMin != null) {
      params = params.set('feesMin', '' + filter.feesMin);
    }
    if (filter.feesMax != null) {
      params = params.set('feesMax', '' + filter.feesMax);
    }
    if (filter.sectors != null && filter.sectors.length > 0) {
      for (const sector of filter.sectors) {
        params = params.append('sector', sector);
      }
    }
    if (filter.ethical != null) {
      params = params.set('ethical', '' + filter.ethical);
    }
    if (filter.indexed != null) {
      params = params.set('indexed', '' + filter.indexed);
    }
    if (filter.mySuper != null) {
      params = params.set('mySuper', '' + filter.mySuper);
    }

    return this.http.get<InvestmentOption>(this.apiUrl + path, { params: params });
  }

  /**
   * Get available investment sectors.
   */
  public getSectors(): Observable<Array<String>> {
    const path = '/super/investment-option/sectors';
    return this.http.get<Array<String>>(this.apiUrl + path);
  }

  /**
   * Histogram of investment returns
   */
  public getReturnHistogram(filter: InvestmentOptionFilter): Observable<RateHistogram> {
    const path = '/super/investment-option/return-histogram';
    let params = new HttpParams();
    if (filter.returnYears != null) {
      params = params.set('returnYears', '' + filter.returnYears);
    }
    if (filter.sectors != null && filter.sectors.length > 0) {
      for (const sector of filter.sectors) {
        params = params.append('sector', sector);
      }
    }
    if (filter.ethical != null) {
      params = params.set('ethical', '' + filter.ethical);
    }
    if (filter.indexed != null) {
      params = params.set('indexed', '' + filter.indexed);
    }
    if (filter.mySuper != null) {
      params = params.set('mySuper', '' + filter.mySuper);
    }
    //console.log('params', params);
    return this.http.get<RateHistogram>(this.apiUrl + path, { params: params });
  }

  /**
   * Histogram of investment fees
   */
  public getFeeHistogram(filter: InvestmentOptionFilter): Observable<RateHistogram> {
    const path = '/super/investment-option/fee-histogram';
    let params = new HttpParams();
    if (filter.sectors != null && filter.sectors.length > 0) {
      for (const sector of filter.sectors) {
        params = params.append('sector', sector);
      }
    }
    if (filter.ethical != null) {
      params = params.set('ethical', '' + filter.ethical);
    }
    if (filter.indexed != null) {
      params = params.set('indexed', '' + filter.indexed);
    }
    if (filter.mySuper != null) {
      params = params.set('mySuper', '' + filter.mySuper);
    }
    return this.http.get<RateHistogram>(this.apiUrl + path, { params: params });
  }


  /* ----------------- current user's super investments -------------------------- */


  /**
   * Send an email to the user with docs for given investment
   */
  public sendInvestmentDocs(option: InvestmentOption): Observable<any> {
    const path = '/me/investment/docs';
    return this.http.put<any>(this.apiUrl + path, option);
  }


}
