<div class="main-content-section">
  <div class="section-content">
    <div class="container" *ngIf="subscriptionChecked">
      <div class="information">
        <div class="logo"><img src="/assets/images/hey-coach-logo-splash.png" /></div>
        <h3>Oh no! There seems to be a problem with your subscription.</h3>
        <p>Please get in touch at <a href="mailto:support@hey.coach">support@hey.coach</a></p>
        <button mat-raised-button class="rollit-button " color="primary" (click)="onOpenIntercomChat()">Chat with
          Rollit</button>
        <a mat-button color="primary" class="rollit-button" (click)="onLogout()">Log out</a>
      </div>
    </div>
  </div>
</div>
