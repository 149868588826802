import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() progressSum = 0;
  @Input() targetSum = 1;
  @Input() section: string;

  constructor() { }

  get progressPercent() {
    return Math.min((this.progressSum / this.targetSum) * 100, 100);
  }

  ngOnInit(): void { }
}
