import {
  Component,
  ComponentFactoryResolver,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InnerTopMenuDirective } from './inner-top-menu.directive';
import { InnerTopMenuItem } from './inner-top-menu/inner-top-menu-item';
import { InnerTopMenuComponent } from './inner-top-menu/inner-top-menu.component';

declare var Intercom; // defined by included Intercom javascript.

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit, OnDestroy {
  @ViewChild(InnerTopMenuDirective, { static: true })
  appInnerTopMenu: InnerTopMenuDirective;
  navData: any;
  log: any;
  intercomOpen = false;
  hasNotifications = false;
  drawerNavigationIsActive: boolean;
  showSubNav: boolean;
  private _isDestroyed = new Subject();
  isDesktop: boolean;
  sideNavIsVisible: boolean;

  constructor(
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver, private logger: LoggerService, private _ngZone: NgZone, private uiService: UIManagerService) {
    this.log = this.logger.info('TopMenuComponent');
    this.uiService.mediaSizeIsDesktop$.subscribe(isDesktop => this.isDesktop = isDesktop);
    this.uiService.sideNavIsVisible.subscribe(sideNavIsVisible => this.sideNavIsVisible = sideNavIsVisible);
    this.uiService.drawerNavigationIsOpen$.pipe(takeUntil(this._isDestroyed)).subscribe(isActive => this.drawerNavigationIsActive = isActive);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.intercomOpen === true) {
      Intercom('hide');
      this.intercomOpen = false;
      // history.go(1);
    }
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.loadComponent(data.topMenuItem);
      this.navData = this.route;
      this.log('navData', this.navData);
      this.showSubNav = this.navData.data._value.topMenuItem.data.subNav;
    });

    this.hasNotifications = false;
    this.intercomOpen = false;
    const self = this;

    Intercom('onUnreadCountChange', function (unreadCount) {
      if (unreadCount > 0) {
        self.hasNotifications = true;
      } else {
        self.hasNotifications = false;
      }
      self._ngZone.run(() => { });
    });

    Intercom('onShow', function () {
      self.log('onShow');
      if (document.getElementsByClassName('intercom-launcher-frame').length !== 0) {
        document.getElementsByClassName("intercom-launcher-frame")[0]['style'].display = 'block';
      }

    });

    Intercom('onHide', function () {
      self.log('onHide ');
      document.getElementsByClassName("intercom-launcher-frame")[0]['style'].display = 'none';
    });

  }

  ngOnDestroy(): void {
    this._isDestroyed.next();
    this._isDestroyed.complete();
  }

  onHideIntercom() {
    this.intercomOpen = false;
    this._ngZone.run(() => { });
  }

  onDrawerNavigationPressed(): void {
    this.uiService.drawerNavigationIsOpen = !this.drawerNavigationIsActive;
  }

  onChatClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (document.getElementsByClassName('intercom-messenger-frame').length === 0) {
      Intercom('show');
      this.intercomOpen = true;
    } else {
      Intercom('hide');
      this.intercomOpen = false;
    }
  }



  loadComponent(item: InnerTopMenuItem) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      item.component
    );

    const viewContainerRef = this.appInnerTopMenu.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<
      InnerTopMenuComponent
    >(componentFactory);
    componentRef.instance.data = item.data;
  }
}
