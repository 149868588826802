import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { LoggerService, MoneyService } from "@rollit/shared/data";

import { UIManagerService } from "@rollit/shared/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { trackById } from '@rollit/shared/utils';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {
  private log: any;
  connectedTransactions: Array<any> = [];
  connectedTransactionsSorted: Array<any> = [];
  gotTransactions: boolean;
  destroy = new Subject();
  trackById = trackById;
  selectedTransaction: any;
  mediaSizeIsDesktop: boolean;
  itemsPerFetch = 100;
  requestCount = 1;
  throttle = 300;
  itemsLoading = false;
  allItemsLoaded = false;
  transactionsFetched: boolean;
  transactionId: number;
  accountId: number;
  transactionFilter: boolean;

  constructor(
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
  ) {
    this.log = this.logger.info('TransactionsComponent');
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.mediaSizeIsDesktop = mediaSizeIsDesktop;
      });
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: { id: string }) => {
      this.log('route params', params);
      if (params.id) {
        this.accountId = +params.id;
      } else {
        this.accountId = null;
      }


      this.setupTransactions();

    });
  }

  ngOnInit() {
  }

  setupTransactions() {

    this.moneyService.getTransactions(this.accountId, null, 0, 100).subscribe(response => {
      this.log('transactions', response);

      response.data.forEach(transaction => {
        //   this.log(transaction);
        if (transaction.descriptionRaw.indexOf('projection') === -1) {
          let topCategory = 'primary'
          if (transaction?.topCategory?.slug) {
            topCategory = transaction?.topCategory?.slug;
          }
          const newAccount = {
            id: transaction.id,
            accountId: transaction.account.id,
            nickname: transaction.account.nickname,
            date: transaction.date,
            amount: transaction.amount,
            description: transaction.descriptionPretty,
            category: transaction?.category?.name,
            topCategory: topCategory,
          };
          this.connectedTransactions.push(newAccount);

        }

      });

      const data = new Set(this.connectedTransactions.map(item => item.date));

      data.forEach((date) => {
        this.log('date', date);
        this.connectedTransactionsSorted.push({
          date: date,
          transactions: this.connectedTransactions.filter(i => i.date === date)
        });
      });

      this.log('connectedTransactionsSorted', this.connectedTransactionsSorted);
      this.log('connectedTransactions', this.connectedTransactions);

      this.gotTransactions = true;
    });
  }

  onTransactionDetail(transactionId) {
    this.router.navigateByUrl('/transactions/transaction-detail/' + transactionId);
  }

  ngOnDestroy() {
    this.uiService.sideNavIsVisible.next(false);
    this.destroy.next(); this.destroy.complete();
  }

  onFilter() {
    console.log('filter');
    this.router.navigateByUrl('/transactions/filter');
  }

  manageAccounts() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/accounts/manage-accounts']));
  }

  onReturnClick() {
    this.router.navigate(['/accounts/']);
  }

  fetchMovements(fromFirst?: boolean) {
    this.log('fetchMovements');
    if (!this.allItemsLoaded) {
      this.itemsLoading = true;
      if (fromFirst) {
        this.connectedTransactions = [];
        this.requestCount = 0;
        this.transactionsFetched = false;
      }
      const offset = (this.itemsPerFetch * this.requestCount) + 1;
      this.moneyService.getTransactions(this.accountId, null, offset, this.itemsPerFetch).pipe().subscribe(response => {
        if (response.data && response.data.length > 0) {
          this.transactionsFetched = true;
          const transactionResult: Array<any> = [];
          this.requestCount++;
          response.data.forEach(transaction => {
            //   this.log(transaction);
            if (transaction.descriptionRaw.indexOf('projection') === -1) {
              let topCategory = 'primary'
              if (transaction?.topCategory?.slug) {
                topCategory = transaction?.topCategory?.slug;
              }
              const newAccount = {
                id: transaction.id,
                accountId: transaction.account.id,
                nickname: transaction.account.nickname,
                date: transaction.date,
                amount: transaction.amount,
                description: transaction.descriptionPretty,
                category: transaction?.category?.name,
                topCategory: topCategory,
              };
              transactionResult.push(newAccount);
            }

          });

          const data = new Set(transactionResult.map(item => item.date));

          data.forEach((date) => {
            this.connectedTransactionsSorted.push({
              date: date,
              transactions: transactionResult.filter(i => i.date === date)
            });
          });

        } else {
          this.allItemsLoaded = true;
        }
        this.itemsLoading = false;
      });
    }

  }



  onScrollDown() {
    this.log('onScrollDown');
    this.fetchMovements();

  }


}
