import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rollit-expansion',
  templateUrl: './rollit-expansion.component.html',
  styleUrls: ['./rollit-expansion.component.scss']
})
export class RollitExpansionComponent implements OnInit {
  @Input() panelTitle: string;
  @Input() panelSection: string;
  @Input() panelDescription?: string; // Put in mat-panel-description
  @Input() accordionDescription?: string; // Put in div.accordion-description
  @Input() expanded: boolean;
  @Input() disabled: boolean;
  @Input() child: boolean;
  panelOpenState: boolean;

  constructor() { }

  ngOnInit() {
  }

}
