<button mat-button class="rollit-button " color="primary" (click)="onFileUploadClick()"
  [ngStyle]="{'display': hideUploadButton ? 'none' : 'inline-block'}">

  <span class="paragraph3">{{text}}</span>
</button>
<ul>
  <li *ngFor="let file of files">
    <mat-progress-bar [value]="file.progress"></mat-progress-bar>
    <span id="file-label">
      {{file.data.name}}
      <a id="file-error" title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry"><i
          class="material-icons">refresh</i></a>
      <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel"><i class="material-icons">cancel</i></a>
    </span>
  </li>
</ul>
<input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}" style="display:none;" />
