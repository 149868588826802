import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { BillingCycle, DiscountCode, Order, Price, Product } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { CustomerType } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-individual-plan-information',
  templateUrl: './individual-plan-information.component.html',
  styleUrls: ['./individual-plan-information.component.scss']
})
export class IndividualPlanInformationComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Input() price: Price;
  @Input() discount: DiscountCode;
  @Input() billingCycle: BillingCycle;
  @Output() discountCode = new EventEmitter<string>();
  @Output() submitOrder = new EventEmitter<Order>();
  @Input() signupError: Boolean;
  subscriberType = CustomerType.individual;
  mediaSizeIsDesktop: boolean;
  billingCycleEnum = BillingCycle;
  private isDestroyed = new Subject();

  constructor(private _uIManagerService: UIManagerService) {
    this._uIManagerService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => this.mediaSizeIsDesktop = mediaSizeIsDesktop);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  onDiscountCode(code: string) {
    this.discountCode.emit(code);
  }

  onSubmitOrder(order: Order) {
    this.submitOrder.emit(order);
  }

}
