<div class="wrapper" *ngIf="displayFilterInMenu" [ngClass]="{'scrolled-up': scrollUpMenuButton}">
    <button (click)="onClick()" fxLayout="row" fxLayoutAlign="left center">
    <img src="assets/icons/filter-icon.svg" alt="Open Filter Menu">
    <span class="text">
      Net return
      <span>for</span> {{fundType}} 
      <span>over</span> {{fundYears}} year<span *ngIf="fundYears !== 1">s</span>
    </span>
  </button>
</div>
