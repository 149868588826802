import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-privacy-overlay',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  log: any;
  isDesktop: boolean;
  private isDestroyed = new Subject();

  constructor(
    private logger: LoggerService,
    private uiService: UIManagerService
  ) {
    this.log = this.logger.info('PrivacyComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.topNavIsVisible.next(false);
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop => {
      this.isDesktop = mediaSizeIsDesktop;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.log('Destroying SetupStatus');

    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.topNavIsVisible.next(true);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();

  }

}
