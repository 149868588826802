import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negate'
})
export class NegatePipe implements PipeTransform {

  transform(value: number): any {
    return (value !== undefined) ? Math.abs(value) : value ;
  }

}
