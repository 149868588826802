import { Component, OnInit, OnDestroy } from '@angular/core';
import { Employer, MeService, LoggerService, Features, EmployerService } from '@rollit/shared/data';
import { trackById } from '@rollit/shared/utils';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var Intercom; // defined by included Intercom javascript.
@Component({
    selector: 'app-employer-container',
    templateUrl: './employer-container.component.html',
    styleUrls: ['./employer-container.component.scss']
})
export class EmployerContainerComponent implements OnInit, OnDestroy {
    selectEmployer = true;
    employers: Employer[];
    employer: Employer;
    private isDestroyed = new Subject();
    hasPremiumFeature: Boolean = false;
    showLogout: Boolean = false;
    trackById = trackById;

    private log: any;

    constructor(
        private employerService: EmployerService,
        private logger: LoggerService,
        private userService: MeService,
        private uiService: UIManagerService
    ) {
        this.log = logger.info("employer");
        this.uiService.sideNavIsVisible.next(true);
    }

    ngOnInit(): void {
        this.employerService.getEmployeesManagedByMe().subscribe(employers => {
            this.log('employers, choosing first', employers);

            this.employers = employers;

            if (employers.length === 0) {
                // no employers
            }
            else if (employers.length === 1) {
                this.employer = employers[0];
                this.employerService.currentEmployer = this.employer;   // set the currently selected employer
            }
            else {
                // prompt user to select employer
            }
            if (employers.length > 0) {
                Intercom('trackEvent', 'web-app-employer-home');
            }
        });

        this.employerService.hasAllFeature$(
            [
                Features.TRACK_MT,
                Features.REWARDS_ALL,
                Features.SUPER_CHOICE,
                Features.PROPERTY_PREMIUM
            ]).pipe(takeUntil(this.isDestroyed)).subscribe(
                hasPremiumFeature => {
                    this.log('hasPremiumFeature', hasPremiumFeature);
                    this.hasPremiumFeature = hasPremiumFeature;
                }
            );
        this.checkSubscription();
    }

    ngOnDestroy() {
        this.isDestroyed.next(); this.isDestroyed.complete();
    }

    checkSubscription() {
        // TODO this will not determine if employee or employer subscription has lapsed.
        // TODO to do this properly, check user.subscriptions and query those.
        this.log('checkSubscription ()');

        this.userService.getProfile().subscribe(
            (profile) => {
                if (profile.employment.length <= 0) {
                    this.employerService.getEmployeesManagedByMe().subscribe(employees => {
                        this.log('checkSubscription () getEmployeesManagedByMe', employees);
                        if (employees.length > 0) {
                            // This is an employer admin without a valid user subscription
                            this.showLogout = true;
                            this.uiService.sideNavIsVisible.next(false);
                        }
                    });
                }
            }
        );
    }

    onLogout() {
        this.userService.logout();
    }

    onEmployerSelect(event) {
        // employer: Employer
        this.employer = event.value;
        this.employerService.currentEmployer = this.employer;   // set the currently selected employer
        this.log('onEmployerSelect', event.value);
        return false;
    }
}
