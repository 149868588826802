<ng-container>

  <div class="group-panel">

    <div class="navigation"
         *ngIf="isDesktop && propertyCount > 0">
      <div class="item prev paragraph2"
           [ngClass]="{'disabled': propertyListId === 0 }"
           (click)="propertyListId !== 0 ? scrollPrev() : false">
        <i class="material-icons">navigate_before</i> Previous
      </div>

      <div class="item next paragraph2"
           [ngClass]="{'disabled': propertyListId  === propertyCount }"
           (click)="propertyListId !== propertyCount ? scrollNext() : false">
        Next <i class="material-icons">navigate_next</i>
      </div>
    </div>

    <app-section-hero [title]="addressTitle"
                      [description]="addressSubTitle"
                      [imageCaption]="occupancyType"
                      [showDelete]="true"
                      [imagePath]="imagePath"
                      [expanded]="isDesktop"
                      (expandClicked)="expandClicked()"
                      (uploadComplete)="uploadComplete($event)">




      <div class="titleGroup">
        <app-title-group [details]="titleGroupDetails"
                         [displayAsColumn]="!loanAccount && isDesktop"></app-title-group>
      </div>

      <div class="heroContent">
        <button mat-stroked-button
                class="new-property"
                (click)="onStartReviewLoan(accountId)"
                *ngIf="!isDesktop">
          <span class="paragraph1">Start loan review</span>
        </button>
        <app-property-projections *ngIf="projectionChartCategories.length > 0 && projectionChartData.length > 0"
                                  [inView]="(inView && isDesktop) || expanded"
                                  [propertyInformation]="propertyInformation"
                                  [projectedMortgageEndDate]="projectedMortgageEndDate"
                                  [projectionChartData]="projectionChartData"
                                  [projectionChartCategories]="projectionChartCategories">
        </app-property-projections>



        <div class="action-holder">
          <button mat-stroked-button
                  (click)="onEditProperty(accountId)">
            <span class="paragraph2"> Edit </span>
          </button>
          <i class="delete material-icons"
             (click)="onRemoveProperty()">
            delete
          </i>
        </div>
        <div class="compare"
             *ngIf="!isDesktop && prop?.estimate?.comparable_sale_listings && expanded">
          <app-property-compare id="compare"
                                [property]="prop?.property"
                                [propertyEstimate]="prop?.estimate">
          </app-property-compare>
        </div>
      </div>
    </app-section-hero>
  </div>
</ng-container>