import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { AccountSource, AccountType, LoggerService, MoneyAccount, MoneyService } from "@rollit/shared/data";
import { UIManagerService } from "@rollit/shared/services";
import { trackById } from '@rollit/shared/utils';
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-incoming-accounts',
  templateUrl: './incoming-accounts.component.html',
  styleUrls: ['./incoming-accounts.component.scss']
})
export class IncomingAccountsComponent implements OnInit, OnDestroy {
  private log: any;
  trackById = trackById;
  destroy = new Subject();
  isDesktop: boolean;

  radioControl: FormControl = new FormControl();
  accountsControl: FormControl = new FormControl();

  pendingAccounts: MoneyAccount[] = [];
  currentAccount: MoneyAccount;
  accountIndex = -1;   // index of the current account
  matchedAccounts: MoneyAccount[] = [];

  constructor(
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router
  ) {
    this.log = this.logger.info('ManageAccountsComponent');

    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit() {
    // get pending/unmatched accounts.
    this.moneyService.getPendingAccounts().subscribe(acs => {
      this.pendingAccounts = acs;
      if (this.pendingAccounts.length > 0) {
        this.accountIndex = 0;
        this.currentAccount = this.pendingAccounts[this.accountIndex];
        this.initPage();
      } else {
        this.accountIndex = -1;
      }
    });

  }

  ngOnDestroy() {
    this.uiService.loaderIsVisible.next(false);
    this.destroy.next(); this.destroy.complete();
  }

  /**
   * Step to the next account.
   */
  nextStep() {
    // 0. if no more accounts, exit.
    // TODO exit page

    // 1. process account actions
    this.process().subscribe(res => {
      // 2. setup next account question
      if (this.accountIndex < this.pendingAccounts.length - 1) {
        this.currentAccount = this.pendingAccounts[++this.accountIndex];
        this.initPage();
      } else {
        // close this page, or show final screen.  Whatever is required.
        this.onReturnClick();
      }
    });
  }

  onReturnClick() {
    // TODO leave this page.
    this.router.navigate(['finances', 'position'], {replaceUrl: true});
  }

  /**
   * Process user action based on selections.
   * - when ignore account, mark aggregateState as "success" and ensure visible = false
   * - when new account, mark aggregateState as "success" and ensure visible = true
   * - when integrate, depends on account type:
   *    - savings: add to savings group
   *    - loan, credit card, superannuation, mortgage: patch account
   * 
   */
  process(): Observable<MoneyAccount> {
    this.log('Got selected action: ', this.radioControl.value);
    const account = this.currentAccount;
    switch(this.radioControl.value) {

      case 'match':
        // patch matched account with pending Yodlee account.
        this.log('Got matched account value', this.accountsControl.value);
        const matchedAccountId = this.accountsControl.value as number;
        return this.moneyService.patchAccount(matchedAccountId, account).pipe(map(res => {
          this.log('Patched account', res);
          return res;
        }));

      case 'new':
        // enable account: account.visible = true, account.aggregationState = 'success'
        account.aggregationState = 'success';
        account.visible = true;
        return this.moneyService.updateAccount(account.id, account).pipe(map(res => {
          this.log('Made Yodlee account visible as a new account', res);
          // Note: for savings accounts, there will be a side-effect of disabling any user-sourced savings accounts
          return res;
        }));

      case 'ignore':
        // hide account: account.visible = false, account.aggregationState = 'success'
        account.aggregationState = 'success';
        account.visible = false;
        return this.moneyService.updateAccount(account.id, account).pipe(map(res => {
          this.log('Ignoring Yodlee account', res);
          return res;
        }));
      }
  }

  initPage() {
    // TODO for mortgages do we want to lookup the property rather than the mortgage.

    this.matchedAccounts = [];
    const accountTypes = [this.currentAccount.accountType]
    if (this.currentAccount.accountType == 'loan') {
      accountTypes.push('mortgage');
    }
    this.moneyService.getAccounts({source: AccountSource.user, type: accountTypes}).subscribe(res => {
      this.matchedAccounts = res;
    });
  }
}
