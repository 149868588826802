import { APP_ENVIRONMENT } from '@rollit/shared';
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggerService } from '../other/logger.service';
import { Observable, ReplaySubject, Subject } from "rxjs";
import { MoneyAccount, AccountSource, AccountType } from "../model/money";
import { map, flatMap } from 'rxjs/operators';
import { MoneyService } from './money.service';
import { DetailedEstimate, Estimate, EstimateSource, OccupancyType, Realty, RealtyImage, RealtyType } from '../model/realty';





/**
 * A service for property/realty.
 */
@Injectable()
export class RealtyService {
    apiUrl = this.environment.apiUrl;
    private log: any;

    private _estimatesSubjects: Map<string, ReplaySubject<DetailedEstimate>> = new Map();
    private _imagesSubjects: Map<string, ReplaySubject<RealtyImage[]>> = new Map();

    private _activeRealty = new ReplaySubject<Realty>(1);   // the property viewed at in detail

    public propertyUpdated$ = new Subject<string>();            // for notifying when property details are updated


    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private http: HttpClient,
        private logger: LoggerService,
        private moneyService: MoneyService,
    ) {
        this.log = this.logger.info('realtyService');
    }

    /**
     * The id of the property currently being viewed, null when none active
     */
    get activeRealty$(): Observable<Realty> {
        return this._activeRealty.asObservable();
    }
    set activeRealty(value: Realty) {
        this._activeRealty.next(value);
    }

    private _getEstimateSubject(propertyId: string): ReplaySubject<DetailedEstimate> {
        let s = this._estimatesSubjects.get(propertyId);
        if (!s) {
            s = new ReplaySubject<DetailedEstimate>(1);
            this._estimatesSubjects.set(propertyId, s);
        }
        return s;
    }
    private _getImagesSubject(propertyId: string): ReplaySubject<RealtyImage[]> {
        let s = this._imagesSubjects.get(propertyId);
        if (!s) {
            s = new ReplaySubject<RealtyImage[]>(1);
            this._imagesSubjects.set(propertyId, s);
        }
        return s;
    }
    /**
     * Search for properties.
     *
     * @param query a string to search properties for
     */
    public search(query: string): Observable<Realty[]> {
        const path = '/realty/search';

        let params = new HttpParams();
        params = params.set('q', query);

        return this.http.get<Realty[]>(this.apiUrl + path, { params: params });
    }

    /**
     * Fetch details of a property.
     * @param propertyId The ID of a property.
     */
    public getRealty(propertyId: string): Observable<Realty> {
        const path = '/realty/' + propertyId;
        return this.http.get<Realty>(this.apiUrl + path);
    }

    /**
     * Get all property/realty accounts for the current user in context.
     */
    public getUserProperties(): Observable<Realty[]> {
        // fetch user accounts from source 'ids'
        return this.moneyService.getAccounts({ source: AccountSource.ids }).pipe(
            map(accounts => {
                const properties = [];
                for (const account of accounts) {
                    properties.push(this.fromAccount(account));
                }
                return properties;
            })
        );
    }

    public getUserRealties(): Observable<Realty[]> {
        // fetch user accounts from source 'ids'
        return this.moneyService.getAccounts({ type: 'realty' }).pipe(
            map(accounts => {
                const properties = [];
                for (const account of accounts) {
                    properties.push(this.fromAccount(account));
                }
                return properties;
            })
        );
    }

    /**
     * Get property from user Rollit account.
     *
     * @param propertyId The ID of the property
     */
    public getUserRealty(propertyId: string): Observable<Realty> {
        // fetch user account from source 'ids' with sourceAccountId = propertyId
        return this.moneyService.getSourceAccount(AccountSource.ids, propertyId).pipe(
            map(account => {
                return this.fromAccount(account);
            })
        );
    }

    public getUserRealtyAccount(propertyId: string): Observable<MoneyAccount> {
        // fetch user account from source 'ids' with sourceAccountId = propertyId
        return this.moneyService.getSourceAccount(AccountSource.ids, propertyId);
    }

    public addRealtyToUser(propertyId: string): Observable<MoneyAccount> {
        this.log('addRealtyToUser', propertyId);

        return this.getRealty(propertyId).pipe(
            flatMap(property => this.getDetailedEstimate(property.property_id, property.bedrooms, property.bathrooms, property.carparks, property.landarea).pipe(
                flatMap(propertyEstimate => {
                    const propertyAccount: MoneyAccount = this.toAccount(property, propertyEstimate);      // convert property to account
                    // Save account to User
                    return this.moneyService.createAccount(propertyAccount);
                }))
            )
        );
    }

    public updateRealtyToUser(property: Realty, estimate?: DetailedEstimate) {
        this.log('updateRealtyToUser', property.property_id);

        // First fetch property details
        this.moneyService.getSourceAccount(AccountSource.ids, property.property_id).subscribe((account: MoneyAccount) => {
            this.log('getSourceAccount', account);
            if (estimate) {
                this.updateAccount(account.id, property, estimate);
            }
            else {
                // get the Realty Estimate
                this.getDetailedEstimate(property.property_id, property.bedrooms, property.bathrooms, property.carparks, property.landarea).subscribe(propertyEstimate => {
                    this.log('updateRealtyToUser getEstimate', propertyEstimate);
                    this.updateAccount(account.id, property, propertyEstimate);
                });
            }
        });
    }

    private updateAccount(accountId: number, property: Realty, estimate: DetailedEstimate) {
        const propertyAccount: MoneyAccount = this.toAccount(property, estimate);      // convert property to account

        // Save account to User
        this.moneyService.updateAccount(accountId, propertyAccount).subscribe(data => {
            this.log('moneyService.updateAccount', data);
            this.propertyUpdated$.next(property.property_id);
        });
    }

    public removeRealtyFromUser(propertyId: string): Observable<void> {
        this.log('removeRealtyFromUser', propertyId);

        // TODO remove from subject maps - estimates and images.
        return this.getUserRealtyAccount(propertyId).pipe(
            flatMap(account => this.moneyService.removeAccount(account.id))
        );
    }

    /**
     * Get an estimate for a property
     *
     * @param propertyId ID of the property.
     * @param bedrooms Override the number of bedrooms
     * @param bathrooms Override the number of bathrooms
     * @param carparks Override the number of carparks
     * @param landarea Override the size of property in square-metres
     */
    public getEstimate(propertyId: string, bedrooms?: number, bathrooms?: number, carparks?: number, landarea?: number): Observable<Estimate> {
        const path = '/realty/' + propertyId + '/estimate';
        let params = new HttpParams();
        if (bedrooms) {
            params = params.set('bedrooms', '' + bedrooms);
        }
        if (bathrooms) {
            params = params.set('bathrooms', '' + bathrooms);
        }
        if (carparks) {
            params = params.set('carparks', '' + carparks);
        }
        if (landarea) {
            params = params.set('landarea', '' + landarea);
        }

        return this.http.get<Estimate>(this.apiUrl + path, { params: params });
    }

    /**
     * Get a detailed estimate of the property.
     *
     * @param propertyId ID of the property.
     * @param bedrooms Override the number of bedrooms
     * @param bathrooms Override the number of bathrooms
     * @param carparks Override the number of carparks
     * @param landarea Override the size of property in square-metres
     */
    public getDetailedEstimate(propertyId: string, bedrooms?: number, bathrooms?: number, carparks?: number, landarea?: number): Observable<DetailedEstimate> {
        const path = '/realty/' + propertyId + '/estimate/detail';
        let params = new HttpParams();
        if (bedrooms) {
            params = params.set('bedrooms', '' + bedrooms);
        }
        if (bathrooms && bathrooms < 10) {
            params = params.set('bathrooms', '' + bathrooms);
        }
        if (carparks) {
            params = params.set('carparks', '' + carparks);
        }
        if (landarea && landarea > 100) {
            params = params.set('landarea', '' + landarea);
        }
        return this.http.get<DetailedEstimate>(this.apiUrl + path, { params: params }).pipe(
            map(value => {
                this._getEstimateSubject(propertyId).next(value);
                return value;
            })
        );

        // return this.http.get<DetailedEstimate>(this.apiUrl + path, { params: params }).pipe(
        //     catchError((err: HttpErrorResponse) => {
        //         this.log('Detailed estimate returned error code ' + err.status);
        //         if (err.status === 400) {
        //             return this.http.get<DetailedEstimate>(this.apiUrl + path);
        //         }
        //         else {
        //             return throwError(err);
        //         }
        //     })
        // );
    }

    public detailedEsimate$(propertyId: string): Observable<DetailedEstimate> {
        return this._getEstimateSubject(propertyId).asObservable();
    }

    createDummyDetailedEstimate(property: Realty): DetailedEstimate {
        this.log('property', property);
        const dummyDetailedEstimate: DetailedEstimate = {
            "property_id": property.property_id,
            "address": property.address,
            "confidence": null,
            "estimate_date": null,
            "state": property.state,
            "estimate": 0,
            "percent_fsd": 0,
            "property_type": "House",
            "location": {
                "lat": property.address_details.location.point.lat,
                "lon": property.address_details.location.point.lon,
            },
            "estimate_high": 0,
            "estimate_low": 0,
            "bedrooms": property.bedrooms,
            "bathrooms": property.bathrooms,
            "carparks": property.bathrooms,
            "landarea": property.landarea,
            "last_sale_price": 0,
            "last_sale_date": null,
            "is_on_market": false,
            "list_price": 0,
            "list_date": null,
            "weekly_rent_estimate": 0,
            "percent_rent_yield_estimate": 0,
            "last_12_mnths_locality_data_similar_properties": {
                "sales": 0,
                "median_price": 0,
                "high_price": 0,
                "low_price": 0,
                "number_similar_properties": 0,
                "mean_percent_return": 0,
                "mean_percent_rental_yield": 0,
                "mean_days_on_market": 0,
                "percent_vendor_expectation_error": 0
            },
            "comparable_sales": [],
            "comparable_sale_listings": [],
            "comparable_rental_listings": [],
        };
        return dummyDetailedEstimate;


    }


    /**
     * Fetch property images
     * @param propertyId ID of the property.
     */
    public getImages(propertyId: string): Observable<RealtyImage[]> {
        const path = '/realty/' + propertyId + '/images';

        return this.http.get<RealtyImage[]>(this.apiUrl + path).pipe(
            map(value => {
                this._getImagesSubject(propertyId).next(value);
                return value;
            })
        );
    }

    public image$(propertyId: string): Observable<RealtyImage[]> {
        return this._getImagesSubject(propertyId).asObservable();
    }


    public toAccount(property: Realty, estimate: DetailedEstimate): MoneyAccount {
        property.visible = !(property.occupancy_type === OccupancyType.Renting || property.occupancy_type === OccupancyType.Tracking);

        const account: MoneyAccount = {
            source: AccountSource.ids,
            sourceAccountId: property.property_id,
            nickname: property.address,
            accountType: AccountType.realty,
            accountSubtype: property.property_type,
            currentBalance: estimate.estimate,
            currency: 'AUD',
            visible: property.visible,
            properties: { property: property, estimate: estimate }
        };
        if (property.estimate_source) {
            switch (property.estimate_source) {
                case EstimateSource.Automatic:
                    account.currentBalance = estimate.estimate;
                    break;
                case EstimateSource.Lower:
                    account.currentBalance = estimate.estimate_low;
                    break;
                case EstimateSource.Upper:
                    account.currentBalance = estimate.estimate_high;
                    break;
                case EstimateSource.Manual:
                    account.currentBalance = property.estimate;
                    break;
            }
        }

        return account;
    }

    public fromAccount(account: MoneyAccount): Realty {
        if (!account) {
            return null;
        }

        // account.source           // should be AccountSource.ids
        // account.accountType      // should be AccountType.realty
        const property: Realty = account.properties
            ? (account.properties.property ? account.properties.property : (account.properties.property_id ? account.properties : {}))
            : {};

        property.property_id = account.sourceAccountId;
        property.address = account.nickname;
        property.property_type = account.accountSubtype as RealtyType;
        property.visible = account.visible;

        return property;
    }

    public fromAccountToEstimate(account: MoneyAccount): DetailedEstimate {
        if (!account) {
            return null;
        }

        // account.source           // should be AccountSource.ids
        // account.accountType      // should be AccountType.realty
        const estimate: DetailedEstimate = account.properties && account.properties.estimate ? account.properties.estimate : {};

        return estimate;
    }

}
