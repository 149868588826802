<div class="dialog">
  <div class="heading">
    <span class="heading2">{{title}}</span>
    <i (click)="closeDialog()" class="material-icons">close</i>
  </div>

  <div mat-dialog-content>
    <div class="copy paragraph1" [innerHtml]="copy"></div>
    <mat-checkbox *ngIf="showCheckbox === true" [checked]="checkboxChecked" (change)="toggleCheckbox()">
      <label class="paragraph1" (click)="toggleCheckbox()">{{checkboxLabel}}</label>
    </mat-checkbox>
    <div>

      <button mat-raised-button class="rollit-button " [color]="acceptColor" class="rollit-button mat-full-width-button"
        (click)="onAcceptClick()"><i class="material-icons" *ngIf="showButtonTick">done</i>{{buttonMessage}} </button>

      <div *ngIf="showCancel === true" class="cancel-button paragraph2" (click)="onCancelClick()">{{cancelMessage}}
      </div>
    </div>
  </div>
</div>
