import { Component, OnInit } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { InputComponent } from '../input.component';
import { TypeDef, MoneyService, AccountSource, MoneyAccount, AccountTypeDef } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';



/**
  * 
 * Uses the Rollit web service to search for accounts.
 * 
 * Submits account match details
 */
@Component({
  selector: 'lib-input-account-match',
  templateUrl: './input-account-match.component.html',
  styleUrls: ['./input-account-match.component.scss'],
})
export class InputAccountMatchComponent extends InputComponent implements OnInit {

  log: any;
  formControl: FormControl = new FormControl();
  value: string;
  label: string;
  idsOpen: boolean = false;
  validatorList: Array<any> = [];
  categories: string[];   // a slug for an account category: savings, lifestyle, investment, superannuation, property, mortgage, loan, creditcard
  accounts: MoneyAccount[] = [];
  selectedAccount: MoneyAccount = null;  // the account that has been selected

  accountExisting = { title: "Existing account", value: 0 };
  accountNew = { title: "Create new account", value: 0 };
  accountDisable = { title: "Do not match this account", value: 0 };

  accountTypes = [
    this.accountExisting,
    this.accountNew,
    this.accountDisable,
  ];

  preferredAccountMatch: number = 0;
  accountExistingEdit = false;
  accountMatchChoice: any;


  constructor(
    private logger: LoggerService,
    private moneyService: MoneyService,
  ) {
    super();
    this.log = this.logger.info("InputRealtyEstimateComponent");
  }

  ngOnInit() {

  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log("Setup account match input with def", typeDef);

    const validators = [];
    const def: AccountTypeDef = typeDef as AccountTypeDef;
    this.categories = def.categories;

    this.formControl.setValidators(validators);
    this.validatorList.push(['required', 'Field is required']);

    this.moneyService.getAccounts({ type: this.categories }).subscribe(result => {
      let tempAccounts = [];
      if (this.categories.indexOf('investment') >= 0) {   // handle investment differently
        result.forEach((ac) => {
          if (ac.accountType == 'investment' && ac.accountSubtype !== "business") {
            // only include on-business accounts
            tempAccounts.push(ac);
          }
        });
      } else {
        tempAccounts = result;
      }
      this.accounts = tempAccounts;
      if (!this.selectedAccount) {
        this.log('setValue', this.accounts[0].id);
        this.formControl.setValue(this.accounts[0].id.toString());
        this.selectedAccount = this.accounts[0];
        this.log(this.formControl.value);
      }
    });
  }

  /**
   * Estimate received from dialog service.
   * 
   * @param data 
   */
  handleData(data: any) {
    this.accountMatchChoice = data;

    this.log('handleData', data);


  }

  handleSelect(data: any) {
    this.log("handleData", data);
    this.accountMatchChoice = 0;
  }

  /**
   * Return the data for this component.
   */
  getData() {
    return this.accountMatchChoice;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }


  /**
   * When account match has changed.
   * 
   * @param e
   */
  onPreferredAccountChange(e) {
    this.log('onPreferredAccountChange', e);
  }

  toggleIds() {
    this.idsOpen = !this.idsOpen;
  }


}
