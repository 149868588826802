import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractControl, FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class CustomValidators {
  static matchingPasswords(control: AbstractControl) {
    // return (control: AbstractControl) => {
      // console.log(control);
      let pass2 = control.value;
      let pass = control.value;
      if (control.parent) {
        pass2 = control.parent.controls['password2'].value;
        pass = control.parent.controls['password'].value;
      }
      // console.log('pass', pass);
      // console.log('pass2', pass2);
      // console.log('match', pass === pass2 ? true : false);
      return pass === pass2 ? null : { notEquivalent: true };

    // };
  }
}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    control.parent.controls['password'].updateValueAndValidity();
    control.parent.controls['password2'].updateValueAndValidity();
    const invalidPass2 = !!(control.parent.controls['password2'] && control.parent.controls['password2'].touched && control.parent.controls['password2'].invalid && control.parent.controls['password'].dirty);
    const invalidPass = !!(control.parent.controls['password'] && control.parent.controls['password'].touched && control.parent.controls['password'].invalid && control.parent.controls['password2'].dirty);
    // console.log('invalidPass2', control.parent.controls['password2']);
    // console.log('invalidPass', control.parent.controls['password']);
    return (invalidPass2 || invalidPass);
  }
}
