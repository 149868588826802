import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { Router } from "@angular/router";
import { LoggerService, MoneyService, YodleeService } from "@rollit/shared/data";
import { UIManagerService } from "@rollit/shared/services";
import { trackById } from '@rollit/shared/utils';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { APP_ENVIRONMENT } from "@rollit/shared";

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.scss']
})
export class ManageAccountsComponent implements OnInit, OnDestroy {
  isProd = this.environment.production;
  private log: any;
  private _yodleeSubscription = null;
  fastLinkOpen: boolean;
  fastLinkReady: boolean;
  trackById = trackById;
  currentStep = 0;
  waitForYodlee: boolean;
  accountsLinked: boolean;
  destroy = new Subject();
  isDesktop: boolean;
  accountsRefreshed: boolean;
  consentPeriod: number = 6;
  consentDateStart = moment();
  consentDateEnd = moment().add(this.consentPeriod, 'months');

  steps = [
    { id: 0, slug: 'trusted-secure' },
    { id: 1, slug: 'consent-period' },
    { id: 2, slug: 'container-fastlink' },
    { id: 3, slug: 'linking-accounts' },
  ]

  constructor(
    private yodleeService: YodleeService,
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router,
    private _location: Location,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.log = this.logger.info('ManageAccountsComponent');

    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });

    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    }

  }

  ngOnInit() {

    this._yodleeSubscription = this.yodleeService.status$().subscribe(
      result => {
        this.log('Got Yodlee success', result);
        this.moneyService.refreshSourceData().subscribe(r => {
          this.log('Account source data refreshed', r);
          this.accountsRefreshed = true;
        },
          err => {
            this.log('Got Yodlee error', err);
          });
      },
      err => {
        this.log('Got Yodlee error', err);
      }
    );
  }

  receiveMessage: any = (event: any) => {
    // Not all callbacks seem to be returned in the fastLink object,
    // here we are using the postMessage event listener to gather more data from fastlink

    // check that the message comes from Yodlee
    if (event.origin.indexOf('yodlee') !== -1) {
      this.log('Yodlee postMessage data', event.data);

      // if waitForYodlee is true it means we are waiting for Yodlee to load the iframe
      if (this.waitForYodlee) {
        this.waitForYodlee = false;
        this.displayYodlee();
      }

      // The close event is not returned
      if (event.data.status === 'USER_CLOSE_ACTION') {
        this.log('Yodlee postMessage event: User closed Yodlee');
        this.log('Yodlee postMessage data', event.data);
        this.onReturnClick();
      }
      // The exit event is not returned
      if (event.data.action === 'exit') {
        this.log('Yodlee postMessage: User exited Yodlee');
        this.log('Yodlee postMessage data', event.data);

        // accountStatus called when user clicks 'save & exit'
        // if (event.data.fnToCall === 'accountStatus') {
        //   this.nextStep();
        // }
        // this.onReturnClick();
      }
      // The success event is returned, capturing here but may not be required
      if (event.data.status === 'SUCCESS') {
        this.log('Yodlee postMessage: User success');
        this.log('Yodlee postMessage data', event.data);
        this.nextStep();
      }

      // Resize the iframe
      if (event.data.fnToCall === 'resizeIframeWindow') {
        this.log('Yodlee postMessage: resizeIframeWindow');
        this.log('Yodlee postMessage data', event.data);
        this.styleIframe(event.data.height);
      }

    }
  }

  nextStep() {
    const currentStepEl = document.querySelectorAll('[data-step="' + this.currentStep + '"]')[0];
    currentStepEl.classList.add('outNext');
    if (this.currentStep === 2) {
      const frame = document.getElementById('fl-frame');
      frame.style.display = 'none';
      // this.closeFastLink();
    }

    if (this.currentStep === 1) {
      const date = moment().add(this.consentPeriod, 'months');
      this.log('date', date);
      this.moneyService.setYodleeExpiry(date).subscribe(result => {

        this.log('result', result);
      });
    }
    setTimeout(() => {
      this.currentStep++;
      if (this.currentStep === 2) {
        this.openFastLink();
      }
      setTimeout(() => {
        const nextStepEl = document.querySelectorAll('[data-step="' + this.currentStep + '"]')[0];
        this.log(nextStepEl);
        nextStepEl.classList.remove('inNext');
      }, 50);
    }, 200);
  }

  ngOnDestroy() {
    if (this.fastLinkReady) {
      this.yodleeService.closeConnection();
    }

    this.uiService.loaderIsVisible.next(false);

    this.destroy.next(); this.destroy.complete();

    if (this._yodleeSubscription) {
      this._yodleeSubscription.unsubscribe();
      this._yodleeSubscription = null;
    }
  }

  openFastLink() {
    this.log('opening FastLink');
    this.uiService.loaderIsVisible.next(true);
    this.yodleeService.connect();
    this.fastLinkOpen = true;
    this.waitForYodlee = true;
  }

  displayYodlee() {
    setTimeout(() => {
      this.uiService.loaderIsVisible.next(false);
      this.fastLinkReady = true;
    }, 500);
  }

  styleIframe(height) {
    if (this.fastLinkReady) {
      this.log('styleIframe', height);
      const frame = document.getElementById('fl-frame');
      setTimeout(() => {
        frame.style.height = height + 'px';
        frame.style.borderRadius = '4px';
        frame.style.transition = 'height .2s ease';
      }, 50);
    }
  }

  consentPeriodChange(e) {
    this.consentPeriod = e.value;
    this.consentDateEnd = moment().add(this.consentPeriod, 'months');
    this.log(e, this.consentPeriod);
  }

  onReturnClick() {
    this.closeFastLink();
    this._location.back();
  }

  closeFastLink() {
    if (this.fastLinkReady) {
      this.yodleeService.closeConnection();
      this.fastLinkOpen = false;
      this.fastLinkReady = false;
    }
  }

  openLink() {
    window.open('https://about.rollitwealth.com/en/articles/5264412-account-connections-terms-of-use', "_blank");

  }
}
