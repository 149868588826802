import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { QuestionBase } from '../../question-base';
import { QuestionService } from '../../question.service';
import { debounceTime } from 'rxjs/operators';
import { SubscriptionService } from '@rollit/shared/data';
import { RadioQuestion } from '../../question-radio';
import { NotificationService } from '@rollit/shared/data';
import { CustomerType } from '@rollit/shared/data';

declare const google;

@Component({
  selector: 'app-user-subscription-form-question',
  templateUrl: './user-subscription-form-question.component.html',
  styleUrls: ['./user-subscription-form-question.component.scss']
})
export class UserSubscriptionFormQuestionComponent implements OnInit {

  @Input() question:              QuestionBase<any>;
  @Input() form:                  FormGroup;
  @Input() parentForm:            NgForm;
  @Input() tradingNameFormGroup:  FormGroup;
  @Output() triggerSaveForm = new EventEmitter<boolean>();

  // Location services
  placesService       /*: google.maps.places.PlacesService */;
  autocompleteService /*: google.maps.places.AutocompleteService */;

  // Options which are populated with autocomplete places
  autoCompleteOptions /*: google.maps.places.AutocompletePrediction[] */;

  // Make sure that when we programmatically update the location search input value we do not perform another auto-complete request
  autocompleteJustUpdated = false;

  // True when requesting autocomplete values
  autocompleteLoadingValues = false;

  // Used in ABN lookup
  organizationNameIsSet = false;
  differentTradingNameIsSet = false;

  // Fields we disable autocomplete for
  browserAutocompleteDisabledQuestions = ['address1', 'postcode', 'address2', 'address3'];

  get isValid() {
    return this.form.controls[this.question.key].valid; }

  hasError(errorType: string) {
    return this.form.controls[this.question.key].hasError(errorType);
  }

  constructor(private qs: QuestionService,
              private ss: SubscriptionService,
              private ls: NotificationService,
              private ngZone: NgZone) { }

  ngOnInit() {
    if (this.question.key === 'address1') {
      this.setUpAddressLookup();
    }
    this.setUpRadioQuestion();
  }

  setUpAddressLookup() {
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.form.controls['address1'].valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (val && !this.autocompleteJustUpdated) {
        const autocompleteRequest = {
          input: val,
          types: ['address'],
          componentRestrictions: { country: 'au' }
        };
        this.autocompleteLoadingValues = true;
        this.autocompleteService.getPlacePredictions(autocompleteRequest, res => {
          // ngZone makes sure the autoCompleteOptions array change is picked up by angular, otherwise dropdown of options doesn't appear
          this.ngZone.run(() => {
            this.autocompleteLoadingValues = false;
            this.autoCompleteOptions = res;
          });
        });
      } else {
        this.autocompleteJustUpdated = false;
      }
    });
  }

  considerAllOrganisationNames(possibleOrgNames: any[]) {
    // Loop through possible organisation names and set if they are present on the data response.
    for (const possibleName of possibleOrgNames) {
      const result = this.setOrganisationName(possibleName);
      if (result && !this.organizationNameIsSet) {
        // First we set the "registeredName" form field.
        this.form.controls['registeredName'].setValue(result);
        this.organizationNameIsSet = true;
      } else if (result && this.organizationNameIsSet) {
        // If we find another value we set this as the value for the "registeredName" field.
        this.setTradingName(result);
        return;
      }
    }
  }

  setRegisteredName(name: string) {
    this.form.controls['registeredName'].setValue(name);
    this.organizationNameIsSet = true;
  }

  setTradingName(val: string) {
    this.form.controls['differentTradingName'].setValue(true);
    this.tradingNameFormGroup.controls['tradingName'].setValue(val);
    this.differentTradingNameIsSet = true;
  }

  setUpRadioQuestion() {
    if (this.question instanceof RadioQuestion) {
      const q: RadioQuestion = this.question;
      if (!q.bigRadio) {
        this.form.controls[q.key].valueChanges.subscribe(val => {
          this.onRadioControlChange(val);
        });
      }
    }
  }

  setOrganisationName(name: any): string {
    if (name) {
      return name.organisationName;
    }
    return null;
  }

  onRadioControlChange(val: string) {
    this.handleDynamicGroupRendering(val);
    this.triggerSaveForm.emit(true);
  }

  onBigRadioChange(val: string) {
    this.form.controls[this.question.key].setValue(val);
    this.handleDynamicGroupRendering(val);
    this.triggerSaveForm.emit(true);
  }

  handleDynamicGroupRendering(val) {
    switch (this.question.key) {
      case 'type':
        if (val === CustomerType.individual) {
          this.qs.displayBusinessFormGroup.next(false);
          this.qs.displayCompanyDetailsFormGroup.next(false);
          this.qs.displayIndividualFormGroup.next(true);
        } else if (val === CustomerType.employer) {
          this.qs.displayBusinessFormGroup.next(true);
          this.qs.displayCompanyDetailsFormGroup.next(true);
          this.qs.displayIndividualFormGroup.next(false);
        }
        break;
      // case 'sunsuperDefault': // Remove temporarily for pilot
      //   if (val === 'yes') {
      //     this.qs.displaySunsuperDefaultFormGroup.next(true);
      //   } else {
      //     this.qs.displaySunsuperDefaultFormGroup.next(false);
      //   }
      //   break;
      case 'differentTradingName':
        if (val === 'yes') {
          this.qs.displayTradingNameFormGroup.next(true);
        } else {
          this.qs.displayTradingNameFormGroup.next(false);
        }
        break;
    }
  }

  getAddressDetails(option) {
    const detailRequest = {
      placeId: option.place_id,
      fields: ['address_component']
    };
    // Get place address components using place id
    this.placesService.getDetails(detailRequest, response => {
      // Make sure angular knows about this callback running. Otherwise input the field's placeholders overlap their value
      this.ngZone.run(() => {
        if (response) {
          this.setAddressFormControls(response.address_components, option);
        }
      });
    });
  }

  setAddressFormControls(addressComponents, selectedOption) {
    if (addressComponents) {
      // Form Controls
      const address1Ctrl = this.form.controls['address1'];
      const address2Ctrl = this.form.controls['address2'];
      const address3Ctrl = this.form.controls['address3'];
      const postcodeCtrl = this.form.controls['postcode'];
      // Lookup Values
      const subpremiseObj = addressComponents.find(component => component.types.includes('subpremise'));
      const subpremise = subpremiseObj ? subpremiseObj.long_name : null;
      const streetNumberObj = addressComponents.find(component => component.types.includes('street_number'));
      const streetNumber = streetNumberObj ? streetNumberObj.long_name : null;
      const streetNameObj = addressComponents.find(component => component.types.includes('route'));
      const streetName = streetNameObj ? streetNameObj.long_name : null;
      const localityObj = addressComponents.find(component => component.types.includes('locality'));
      const locality = localityObj ? localityObj.long_name : null;
      const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
      const state = stateObj ? stateObj.long_name : null;
      const postcodeObj = addressComponents.find(component => component.types.includes('postal_code'));
      const postcode = postcodeObj ? postcodeObj.long_name : null;

      // Make sure the user entered a street number, if not let them know one is required
      if (!streetNumber) { return this.ls.error('Please enter a building number'); }

      // Exit with error if values not found
      if (!locality || !state || !postcode) { return this.ls.error('Couldn\'t find that address'); }

      let address1Val = '';
      // BUGFIX - Places Autocomplete API is not designed to support 'subpremise' results. This code makes sure that the subpremise string
      // is displayed in the street name and number input.
      if (selectedOption.types.includes('route')) {
        // get all the user entered values before a match with the first word from the lookup result
        const regex = RegExp('^(.*)' + streetNumber.split(' ', 1)[0]);
        const result = regex.exec(selectedOption.description);
        if (Array.isArray(result)) {
          address1Val = result[1] + streetNumber + ' ' + streetName; // add the street name to the user-entered unit & street number
        } else { return this.ls.error('Couldn\'t find that address'); }
      } else {
        if (subpremise) {
          address1Val += subpremise + ' ';
        }
        if (streetNumber) {
          address1Val += streetNumber + ' ';
        }
        if (streetName) {
          address1Val += streetName;
        }
      }

      // Set form input values from result
      address1Ctrl.setValue(address1Val);
      address2Ctrl.setValue(locality);
      address3Ctrl.setValue(state);
      postcodeCtrl.setValue(postcode);
      this.autocompleteJustUpdated = true;
      // Empty autocomplete options
      this.autoCompleteOptions = [];
    } else { return this.ls.error('Couldn\'t find that address'); }
  }

  onValueChange() {
    if (this.isValid) {
      this.triggerSaveForm.emit(true);
    }
  }
}
