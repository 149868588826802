<svg version="1.1" baseProfile="full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 300 170" preserveAspectRatio="xMidYMin meet">

  <path id="path8" style="display:inline;fill:#1b365f;fill-opacity:1;stroke-width:0.426026"
    d="m 49.106908,36.828377 a 13.142926,13.142926 0 0 0 -13.193524,13.077848 0.58791693,0.58791693 0 0 0 -0.0046,0.07246 c 0,1.712637 -2.624516,8.192369 -3.293382,9.023124 a 0.58791693,0.58791693 0 0 0 0.447659,0.945242 h 2.803296 v 6.114141 a 2.0747504,2.0747504 0 0 0 2.074378,2.074397 h 5.807109 c 0.664604,1.695584 1.951201,5.355494 1.810613,6.838068 a 0.58791693,0.58791693 0 0 0 0.583299,0.643183 h 0.0391 c 6.245549,-0.426025 14.472023,-4.073372 15.873652,-5.69228 a 0.57939643,0.57939643 0 0 0 0.102348,-0.583288 c -1.764295,-4.83374 -1.967861,-9.824946 -0.618239,-15.25626 A 13.142926,13.142926 0 0 0 49.106936,36.82844 Z" />
  <path id="path14"
    d="m 48.766581,43.502523 a 4.5371852,4.5371852 0 0 0 -4.537347,4.541497 4.486062,4.486062 0 0 0 1.584282,3.43817 1.9085999,1.9085999 0 0 1 0.426024,1.073391 0.58791693,0.58791693 0 0 0 0.406066,0.491763 0.58791693,0.58791693 0 0 0 -0.0065,1.119155 0.58791693,0.58791693 0 0 0 0.184729,1.145772 h 0.883672 a 0.58791693,0.58791693 0 0 0 0.219669,1.133295 h 1.614248 a 0.58791693,0.58791693 0 0 0 0.219669,-1.133295 h 0.896986 a 0.58791693,0.58791693 0 0 0 0.183895,-1.146606 0.58791693,0.58791693 0 0 0 0.01251,-1.111672 0.5836567,0.5836567 0 0 0 0.421039,-0.498412 1.9256411,1.9256411 0 0 1 0.396068,-1.030951 4.5371852,4.5371852 0 0 0 -2.9048,-8.022107 z m -0.08654,1.179891 a 3.3613513,3.3613513 0 0 1 3.413205,3.361606 3.4082143,3.4082143 0 0 1 -1.252286,2.6244 2.8075165,2.8075165 0 0 0 -0.668997,1.231482 h -0.852048 v -2.642691 l 1.231476,-1.234819 a 0.58791693,0.58791693 0 0 0 0,-0.852054 0.59217721,0.59217721 0 0 0 -0.852058,0 l -0.980182,0.980195 -0.980201,-0.980195 a 0.59217721,0.59217721 0 0 0 -0.830413,0 0.58791693,0.58791693 0 0 0 0,0.852054 l 1.235635,1.236477 v 2.641033 h -0.831246 a 2.9012424,2.9012424 0 0 0 -0.732235,-1.273928 3.3613513,3.3613513 0 0 1 2.09935,-5.94356 z"
    style="fill:#ffffff;fill-opacity:1;stroke-width:0.426026" />
  <path id="path26" style="display:inline;fill:#05a8db;fill-opacity:1;stroke-width:0.426026"
    d="m 75.025477,21.662816 a 7.936879,7.936879 0 0 0 -7.906458,7.870685 6.9783185,6.9783185 0 0 0 2.47129,13.500554 h 1.071717 c 0.129348,0.01708 0.289894,0.04297 0.381095,0.05153 a 3.7405151,3.7405151 0 0 0 3.667825,3.10948 3.7405151,3.7405151 0 0 0 3.672819,-3.161071 h 10.194686 a 6.9783185,6.9783185 0 0 0 2.475452,-13.500555 6.9271955,6.9271955 0 0 0 -6.918781,-6.842235 6.8164282,6.8164282 0 0 0 -3.693611,1.077548 7.936879,7.936879 0 0 0 -5.416034,-2.105999 z m -5.40272,25.861985 a 2.3303665,2.3303665 0 0 0 0.0316,4.660504 2.3346268,2.3346268 0 0 0 2.329831,-2.330671 2.3303665,2.3303665 0 0 0 -2.361445,-2.329833 z" />
  <path id="path34"
    d="m 72.807148,26.244275 a 0.2257942,0.2257942 0 0 0 -0.226332,0.225489 v 13.518025 a 0.2257942,0.2257942 0 0 0 0.226332,0.225508 h 13.51303 a 0.2257942,0.2257942 0 0 0 0,-0.450996 H 73.032636 V 26.469764 a 0.2257942,0.2257942 0 0 0 -0.225488,-0.225489 z m 6.118304,0.984351 a 0.22153392,0.22153392 0 0 0 -0.209681,0.221338 v 10.735533 a 0.2257942,0.2257942 0 0 0 0.225488,0.226327 h 2.130139 a 0.22153392,0.22153392 0 0 0 0.222161,-0.226327 V 27.449964 a 0.22153392,0.22153392 0 0 0 -0.222161,-0.221338 h -2.130139 a 0.22153392,0.22153392 0 0 0 -0.01575,0 z m 4.350126,4.503237 a 0.2257942,0.2257942 0 0 0 -0.209681,0.225493 v 6.228141 a 0.2257942,0.2257942 0 0 0 0.225488,0.226327 h 2.130139 a 0.2257942,0.2257942 0 0 0 0.225498,-0.226327 v -6.228141 a 0.2257942,0.2257942 0 0 0 -0.225498,-0.225493 h -2.130139 a 0.2257942,0.2257942 0 0 0 -0.01575,0 z m -8.675301,3.15193 a 0.2257942,0.2257942 0 0 0 -0.225488,0.226328 v 3.075376 a 0.2257942,0.2257942 0 0 0 0.225488,0.226327 h 2.108504 a 0.2257942,0.2257942 0 0 0 0.226331,-0.226327 v -0.0049 h 0.0083 V 34.883033 H 76.7088 Z m 4.583954,3.072045 H 80.8459 v 0.0049 h -1.661669 z"
    style="display:inline;fill:#ffffff;fill-opacity:1;stroke-width:0.426026" />
  <path id="path48" style="display:inline;fill:#05a8db;fill-opacity:1;stroke-width:0.426026"
    d="m 22.995289,22.879324 a 5.934553,5.934553 0 0 0 -4.017296,1.566814 5.1123215,5.1123215 0 0 0 -2.765019,-0.804621 5.1762254,5.1762254 0 0 0 -5.171394,5.112323 5.2145676,5.2145676 0 0 0 1.84889,10.079851 h 7.616898 l 0.01334,0.0081 a 2.7776945,2.7776945 0 0 0 5.484258,0.03736 c 0.0681,-0.0064 0.18218,-0.02463 0.277088,-0.03736 h 0.799633 a 5.2145676,5.2145676 0 0 0 1.84889,-10.088177 5.934553,5.934553 0 0 0 -5.935252,-5.874516 z m 4.055582,19.29435 a 1.7424495,1.7424495 0 0 0 -1.759023,1.742391 1.7424495,1.7424495 0 0 0 1.738212,1.742375 1.7424495,1.7424495 0 0 0 0.02085,-3.484766 z" />
  <path id="path916"
    style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.426026;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000"
    d="m 20.073014,26.287539 c -0.185442,-1.27e-4 -0.338631,0.158285 -0.331997,0.343645 l -9.26e-4,-0.0081 v 3.910788 l 0.0019,-0.0081 c -0.0065,0.185331 0.146564,0.344611 0.332006,0.344484 h 3.923268 c 0.175139,4.2e-4 0.324084,-0.138959 0.336157,-0.313696 l 9.27e-4,-0.0081 v -0.0081 c -0.0046,-2.347481 -1.912786,-4.252809 -4.260269,-4.255272 z m -0.980192,1.456971 c -2.350364,0 -4.260268,1.909918 -4.260268,4.260282 0,2.350348 1.909904,4.260261 4.260268,4.260261 1.131648,0.0034 2.218144,-0.448372 3.014635,-1.252285 l 0.0037,-0.0034 0.0019,-0.0034 c 0.05735,-0.06183 0.09043,-0.142827 0.09154,-0.227166 v -0.0015 -0.0034 c -4.26e-4,-0.08437 -0.0315,-0.165512 -0.08738,-0.228824 l -0.0046,-0.0049 -2.680971,-2.677648 v -3.784186 c 0,-0.18335 -0.153652,-0.336156 -0.336991,-0.336156 z m 1.227325,3.68031 c -0.136353,-4.68e-4 -0.260299,0.0826 -0.312039,0.208856 l -9.26e-4,10e-4 v 0.001 c -0.05105,0.127365 -0.0176,0.274729 0.0832,0.36778 l -0.0065,-0.0064 2.774174,2.778318 9.26e-4,10e-4 c 0.06236,0.06118 0.146536,0.09436 0.233809,0.09404 0.08756,4.88e-4 0.172072,-0.03268 0.234652,-0.09404 h 0.0019 v -10e-4 c 0.803459,-0.796733 1.254945,-1.882315 1.252286,-3.013805 0,-0.183351 -0.153642,-0.336997 -0.337,-0.336997 h -3.922434 z"
    inkscape:label="pie" />
  <path id="path10"
    d="m 281.56383,30.791697 a 0.97801205,0.97801205 0 0 0 -0.67747,0.274381 L 261.10629,49.325298 245.61118,47.577 V 36.704346 a 2.0347622,2.0347622 0 0 0 1.82761,-2.024309 2.0347622,2.0347622 0 0 0 -2.03483,-2.034818 2.0347622,2.0347622 0 0 0 -2.03482,2.034818 2.0347622,2.0347622 0 0 0 1.82761,2.024309 v 10.84432 a 0.97386792,0.97386792 0 0 0 -0.32699,0.08583 l -14.57078,6.726096 a 0.97801205,0.97801205 0 0 0 -0.57224,0.887111 v 13.675592 a 0.97801205,0.97801205 0 0 0 0.97855,0.977751 h 50.84397 a 0.97801205,0.97801205 0 0 0 0.99475,-0.986663 V 31.783247 a 0.97801205,0.97801205 0 0 0 -0.98018,-0.991518 z"
    style="fill:#05a8db;fill-opacity:1;stroke-width:0.414411" />
  <path style="fill:#1b365f;stroke-width:0.414411" class="cls-1"
    d="m 281.54929,77.720894 h -58.66415 a 0.97801205,0.97801205 0 0 1 -0.978,-0.978001 V 18.078742 a 0.97801205,0.97801205 0 0 1 1.95602,0 v 57.686133 h 57.68613 a 0.97801205,0.97801205 0 1 1 0,1.956019 z"
    id="path8-2" />
  <path id="circle8" style="fill:#bdbdbd;stroke-width:0.418801"
    d="m 150.67757,90.028696 c -1.27214,7e-6 -2.3034,1.031281 -2.3034,2.303418 5.7e-4,1.190572 0.90835,2.184529 2.09399,2.292774 V 155.9974 H 44.929956 v 0.31409 7.29059 c -1.084374,0.20862 -1.86814,1.15744 -1.868252,2.2617 -4e-6,1.27214 1.031272,2.30342 2.303415,2.30342 1.272142,0 2.303416,-1.03128 2.303412,-2.30342 -2e-6,-1.27214 -1.031274,-2.30341 -2.303412,-2.30341 -0.0055,-2e-5 -0.01091,-2e-5 -0.01636,0 v -7.24888 H 150.46817 v 7.25951 c -1.18565,0.10824 -2.09343,1.1022 -2.094,2.29278 0,1.27214 1.03127,2.30342 2.30341,2.30342 1.27214,0 2.30341,-1.03128 2.30341,-2.30342 -1.5e-4,-1.19089 -0.90805,-2.18533 -2.09401,-2.29359 v -7.2587 h 105.11941 v 7.24888 c -1.26574,0.009 -2.28708,1.03763 -2.28705,2.30341 0,1.27214 1.03127,2.30342 2.30341,2.30342 1.27214,-1e-5 2.3034,-1.03128 2.3034,-2.30342 1.2e-4,-1.11698 -0.80117,-2.07308 -1.90097,-2.26823 v -7.28406 -0.31409 H 256.00639 150.88698 V 94.624888 c 1.18565,-0.108235 2.09344,-1.102195 2.09401,-2.292774 0,-1.272141 -1.03127,-2.303416 -2.30341,-2.303418 z"
    sodipodi:nodetypes="scccccccssscccccscccccssccccccccss" />
  <rect style="fill:none;stroke:#05a8db;stroke-width:0.603251;stroke-miterlimit:10;stroke-dasharray:2.40093, 1.20047"
    class="cls-2" x="102.45411" y="11.107485" width="95.961449" height="81.07457" id="rect24" />

  <foreignObject width="30%" x="0" y="75" height="80">
    <xhtml:div class="title centre">
      {{assetsSuperConcernResult}}
    </xhtml:div>
  </foreignObject>
  <foreignObject y="10" height="80" width="28%" x="110">
    <xhtml:div class="title centre">
      <span>{{assetsInvestmentPortfolioResult}}</span>
    </xhtml:div>
  </foreignObject>
  <foreignObject height="70" width="30%" x="210" y="80">
    <xhtml:div class="title">
      <xhtml:span>{{assetsSuperAgeAvgBalanceResult}}</xhtml:span>
    </xhtml:div>
  </foreignObject>
  <foreignObject height="60" width="30%" x="208" y="20">
    <xhtml:div class="value">
      {{assetsSuperAgeAvgBalance | currency: '$' : 'symbol' : '1.0-0' }}
    </xhtml:div>
  </foreignObject>
</svg>
