import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';
import { DashboardProjectionComponent } from './components/dashboard-projection/dashboard-projection.component';
import { DashboardGoalsComponent } from './components/dashboard-goals/dashboard-goals.component';
import { DashboardPositionComponent } from './components/dashboard-position/dashboard-position.component';
import { DashboardComponent } from './components/dashboard.component';
import { DashboardInsightsComponent } from './components/dashboard-insights/dashboard-insights.component';
import { CompleteSetupComponent } from './components/complete-setup/complete-setup.component';
import { StartHealthCheckComponent } from './components/start-healthcheck/start-healthcheck.component';
import { DashboardlandingComponent } from './components/dashboard-landing/dashboard-landing.component';

const dashboardRoutes: Routes = [
  
  {
    path: 'landing',
    component: DashboardlandingComponent,
    data: {
      title: '',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['finances', 'coach'] },
      },
    },
    children: [],
  },
  {
    path: 'finances',
    component: DashboardComponent,
    data: {
      title: 'finances',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: true, primaryItems: ['finances', 'coach'] },
      },
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'position' },
      {
        path: 'position',
        component: DashboardPositionComponent,
        data: { title: 'Your position' },
      },
      {
        path: 'projections',
        component: DashboardProjectionComponent,
        data: { title: 'Your Projections' },
      },
     
    ],
  },

  {
    path: 'coach',
    component: DashboardComponent,
    data: {
      title: 'coach',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: true, primaryItems: ['finances', 'coach'] },
      },
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'goals' },

      {
        path: 'goals',
        component: DashboardGoalsComponent,
        data: { title: 'Goals' },
      },
      {
        path: 'goals/:id',
        component: DashboardGoalsComponent,
        data: { title: 'Goals', show: false },
      },
      {
        path: 'goals/:history',
        component: DashboardGoalsComponent,
        data: { title: 'Goals', show: false },
      },
      {
        path: 'actions',
        component: DashboardInsightsComponent,
        data: { title: 'Actions' },
      },
      
    ],
  },
  {
    path: 'complete-setup',
    component: CompleteSetupComponent,
    data: {
      title: '',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [],
  },
  {
    path: 'start-healthcheck',
    component: StartHealthCheckComponent,
    data: {
      title: '',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [],
  }
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
