import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, Fact, Goal, LoggerService, MoneyService, TimeIntervalTable, ValueType } from '@rollit/shared/data';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class GoalService {
  log: any;
  sessionId: string;

  constructor(
    private moneyService: MoneyService,
    private dialogService: DialogService,
    private router: Router,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('GoalService');
  }

  getProjection(goal: Goal): Observable<TimeIntervalTable> {
    const from = goal.startDate ? moment(goal.startDate) : moment().startOf('month');
    return this.moneyService.getGoalProjection(goal.id, from, null, 7);
  }

  /**
   * Start a new goal dialog
   */
  startNewGoal() {
    const facts: Fact[] = [{ slug: 'goal_category', type: ValueType.String, value: 'savings', '@type': 'fact' }];
    const returnPath = this.router.url;
    this.startNewSurvey('goals', 'new', facts, returnPath);
  }

  /**
   * Start a nw goal dialog
   */
  startEditGoal(goalId: number) {
    const facts: Fact[] = [{ slug: 'goal_id', type: ValueType.Long, value: goalId, '@type': 'fact' }];
    const returnPath = this.router.url;
    this.startNewSurvey('goals', 'edit', facts, returnPath);
  }

  /**
   * Navigate to survey dialog after setting initial facts.
   * 
   * @param module 
   * @param dialog 
   * @param facts 
   * @param returnPath 
   */
  private startNewSurvey(module: string, dialog: string, facts: Fact[], returnPath: string) {
    this.sessionId = this.dialogService.generateSessionId();

    // initialise dialog with existing facts
    this.dialogService.initDialog(module, dialog, facts, this.sessionId).subscribe(result => {
      // redirect to survey path
      this.router.navigate(['survey', module, dialog], { queryParams: { 'session': this.sessionId, 'return': returnPath, 'start': '_first' } });
    });
  }

}
