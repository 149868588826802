<div class="goal-card goal-card--{{ _colour }}">

  <div class="top">
    <div class="copy">
      <span class="header">{{ header }}</span>
      <span class="value">{{ description }}</span>
    </div>

    <div class="close">
      <i class="material-icons">close</i>
    </div>
  </div>
  <div class="bottom">
    <div class="button">Setup</div>
    <div class="icon">
      <i class="material-icons">{{ icon }}</i>
    </div>
  </div>
</div>
