<div class="breadcrumb">
  <a class="breadcrumb__back-button" mat-button (click)="onBackClick($event)">
    <div class="breadcrumb__back-button-content paragraph2">
      <i class="material-icons">arrow_back_ios_new</i>
      Back
    </div>
  </a>
  <!--   
  <div class="breadcrumb__bottom">
    <ng-container *ngFor="let element of elements; last as isLast">

      <a *ngIf="!isLast" mat-button class="breadcrumb__navigation paragraph2" [routerLink]="element.url">
        {{ element.label }} </a>

      <span *ngIf="isLast" mat-button class="breadcrumb__navigation paragraph2">
        {{ activeRoute?.data?._value?.title }} </span>

      <ng-container *ngIf="!isLast">/</ng-container>

    </ng-container>
  </div> -->
</div>
