
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UIManagerService } from '@rollit/shared/services';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'app-profile-list-and-detail',
  templateUrl: './profile-list-and-detail.component.html',
  styleUrls: ['./profile-list-and-detail.component.scss']
})
export class ProfileListAndDetailComponent implements OnInit, OnDestroy {

  detailIsDisplayed = false;
  isDesktop: boolean;
  unsubscribe = new Subject();

  constructor(private router: Router, private uiService: UIManagerService) {
    Intercom('trackEvent', 'web-app-profile-home-visit');
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit() {
    this.unsubscribe = new Subject();
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.unsubscribe)).subscribe(isDesktop => {
      const previousStateWasDesktop = this.isDesktop;
      this.isDesktop = isDesktop;
      if (!previousStateWasDesktop && this.isDesktop && this.router.url && this.router.url.includes('none')) {
        this.router.navigateByUrl(this.router.url.replace('none', 'personal-information'));
      }
    });
    this.detailIsDisplayed = this.router.url && !this.router.url.includes('none');
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.detailIsDisplayed = e.url && !e.url.includes('none');
      }
    });
  }

  ngOnDestroy() {
    this.uiService.sideNavIsVisible.next(false);
    this.uiService.displayContentAsFullPage.next(false);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
