import { Component, OnInit, Input, Inject } from '@angular/core';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { KeycloakService } from 'keycloak-angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';



@Component({
    selector: 'app-investment-change',
    templateUrl: './investment-change.component.html',
    styleUrls: ['./investment-change.component.scss']
})
export class InvestmentChangeComponent implements OnInit {

    _url: string;
    innerHtml: SafeHtml;
    apiUrl = this.environment.apiUrl;

    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private keycloakService: KeycloakService,
        private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void { }

    @Input("investmentId")
    set investmentId(value: number) {
        // get access token
        this.keycloakService.getToken().then(
            token => {
                this._url = this.apiUrl + '/me/investment/employer-advice.pdf?investment=' + value + "&access_token=" + token;
                this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(
                    '<object data="' + this._url + '" type="application/pdf" width="100%" height="100%" style="display:block;" ng:if="url">'
                    // <iframe [src]="url" width="100%" height="100%" style="border: none;">
                    //    This browser cannot embed PDFs. To view it please <a href="{{url}}">Download PDF</a>
                    // </iframe>
                    + '</object>'
                );
            }
        );

    }

    get url() {
        // return this._url ? this._url : null;
        if (this._url) {
            return this.sanitizer.bypassSecurityTrustUrl(this._url);
        }
        else {
            return null;
        }
    }
}
