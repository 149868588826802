<div class="main-content-section">
  <div class="section-content">
    <app-top-menu></app-top-menu>

    <div class="rollit-grid">

      <app-section-title [title]="'Savings balance'" [value]="totalAccountValue"></app-section-title>

      <div class="rollit-grid-2">

        <app-savings-hero *ngIf="budgets && projection && income" [budgets]="budgets" [budget]="budget"
          [projection]="projection" [income]="income" [balance]="totalAccountValue">
        </app-savings-hero>

      </div>

      <div class="rollit-grid-1">
        <div class="sundry-holder">

          <app-new-savings-goal icon="home" [section]="'savings'" (newGoalClicked)="addNewGoal()">
          </app-new-savings-goal>

          <button *ngIf="!yodleeConnection" (click)="manageAccounts()" mat-flat-button class="new-account" color="primary">
            Connect accounts
          </button>

          <button *ngIf="yodleeConnection" (click)="manageAccounts()" mat-flat-button class="new-account" color="primary">
            Manage accounts
          </button>

          

        </div>
      </div>
    </div>

  </div>
</div>
