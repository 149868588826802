import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, FormControl, ValidatorFn } from '@angular/forms';
import { InputComponent } from '../input.component';
import { TypeDef, SuperTypeDef, InvestmentOption, FundService, InvestmentOptionFilter, Superannuation, OptionSnapshot } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { Observable, Subject, } from 'rxjs';
import 'rxjs/add/observable/of';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { trackById } from '@rollit/shared/utils';

const TEST_SUPER = {
  "id": 493,
  "number": 46509233,
  "name": "WA Super Personal - Sustainable Future",
  "description": "This investment option aims to screen out companies that don't meet environmental, social and governance standards determined by an investment manager. A pre-mixed investment option that follows an ethical strategy could sit anywhere along the risk spectrum - from high growth to conservative.",
  "sector": "Balanced",
  "ethical": true,
  "growthAssetWeighting": 60.0,
  "maxFees": {
    "investmentManagementFees": { "name": "Investment management fee", "feeType": "Percentage", "value": 0.55 },
    "ongoingManagementFees": { "name": "Ongoing management fee", "feeType": "Percentage", "value": 0.08 },
    "indirectCostRatio": { "name": "Indirect cost ratio", "feeType": "Percentage", "value": 0.26 },
    "totalCostRatio": { "name": "Total cost ratio", "feeType": "Percentage", "value": 0.89 },
    "memberManagementFees": { "name": "Member management fee", "feeType": "Dollar", "value": 72.8 },
    "others": [
      { "name": "Buy spread", "feeType": "Percentage", "value": 0.0 },
      { "name": "Sell spread", "feeType": "Percentage", "value": 0.0 },
      { "name": "Contribution fees", "feeType": "Percentage", "value": 0.0 },
      { "name": "Establishment fees", "feeType": "Percentage", "value": 0.0 },
      { "name": "Investment switching fees", "feeType": "Percentage", "value": 0.0 },
      { "name": "Termination fees", "feeType": "Percentage", "value": 0.0 },
      { "name": "Withdrawal fees", "feeType": "Percentage", "value": 0.0 }
    ]
  },
  "performance": [{ "timestamp": "2020-09-30T00:00:00Z", "values": { "return_1": { "type": "Number", "name": "return_1", "value": 7.86 }, "return_3": { "type": "Number", "name": "return_3", "value": 8.68 }, "return_5": { "type": "Number", "name": "return_5", "value": 9.26 }, "return_7": { "type": "Number", "name": "return_7", "value": 7.86 }, "return_10": { "type": "Number", "name": "return_10", "value": 7.76 }, "change_1": { "type": "Number", "name": "return_1", "value": 5.61 }, "dollar_return_3": { "type": "Number", "name": "return_3", "value": 0.28365670284560673 }, "dollar_return_5": { "type": "Number", "name": "return_5", "value": 0.5570622851689804 }, "dollar_return_7": { "type": "Number", "name": "return_7", "value": 0.6983332621821559 }, "dollar_return_10": { "type": "Number", "name": "return_10", "value": 1.1114258601773823 }, "dollar_return_1": { "type": "Number", "name": "return_1", "value": 0.078600001335144 }, "rank_1": { "type": "Number", "name": "rank_1", "value": 1 }, "rank_3": { "type": "Number", "name": "rank_3", "value": 1 }, "rank_5": { "type": "Number", "name": "rank_5", "value": 1 }, "rank_7": { "type": "Number", "name": "rank_7", "value": 4 }, "rank_10": { "type": "Number", "name": "rank_10", "value": 7 } } }],
  "isPerformanceCurrent": true,

  "product": {
    "id": 82,
    "fund": {
      "id": 60,
      "name": "WA Local Government Superannuation Plan",
      "description": "WA Super is a Western Australian based industry style super fund available to anyone who is eligible to have a superannuation account.",
      "abn": "18 159 499 614"
    },
    "name": "WA Super Super Solutions - Personal",
    "description": "WA Super enables you can take control of your super and manage your fund investment the way you want to by choosing how your super is invested.",
    "logo": { "id": 99, "name": "Fund image 10.jpg", "description": "", "path": "/api/file/99", "localPath": "file:/usr/local/rollit/files/10/58/99", "contentType": "image/jpeg" },
    "number": 46509210,
    "type": "Superannuation",
    "category": "Personal",
    "usi": "WAL0001AU",
    "website": "www.wasuper.com.au",
    "productDisclosure": "https://www.wasuper.com.au/members/super/pds-and-fact-sheets/",
    "statementOfCompliance": {
      "id": 199, "name": "WA+Super+Super+Solutions+-+Personal+-+Letter+of+Compliance.pdf",
      "description": "",
      "path": "/api/file/199",
      "localPath": "file:/usr/local/rollit/files/8b/69/199",
      "contentType": "application/pdf"
    },
    "signupUrl": "https://www.wasuper.com.au/join-now",
    "enquiriesNumber": "1300 998 542",
    "assessmentStatus": "AAA"
  }
};

/**
 * Provided a text input with super investment item search - drop-down for matched super options.
 * 
 * Uses the FundService to search for super investment option.
 * 
 * Returns Superannuation summary object as data.
 * 
 * TODO if investment not found in search, then create a Superannuation object with entered name. Populate object with what we have.
 */
@Component({
  selector: 'lib-input-super',
  templateUrl: './input-super.component.html',
  styleUrls: ['./input-super.component.scss'],
})
export class InputSuperComponent extends InputComponent implements OnInit, OnDestroy {
  isDestroyed = new Subject();
  log: any;
  formControl: FormControl = new FormControl();
  type = "text";
  length = 100;
  label: string;
  searchText = new Subject<string>();
  searchOptions: Array<InvestmentOption> = [];     // options returns from search
  investmentValid = false;
  investmentOption: InvestmentOption; // the user's selected investment option
  value: Superannuation;
  filter: InvestmentOptionFilter;           // the filter to use when searching for super.
  display: 'option' | 'product' | 'fund';   // which text to display for each investment option
  isSearch = true;
  showSearchOutput = false;
  trackById = trackById;

  constructor(
    private logger: LoggerService, private superService: FundService, private dialog: MatDialog, private el: ElementRef
  ) {
    super();
    this.log = this.logger.info("InputSuperComponent");
    this.formControl.markAsTouched();
  }

  ngOnInit() {
    this.searchText
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchValue, i) =>
          this.doSearch(searchValue)
        ),
        takeUntil(this.isDestroyed)
      )
      .subscribe(
        options => {
          this.log('options', options);
          this.searchOptions = options.data;
          this.showSearchOutput = true;
        }
      );
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log("Setup super input with def", typeDef);
    const def: SuperTypeDef = typeDef as SuperTypeDef;
    const validators: ValidatorFn[] = [];
    //this.options = def.options;
    this.filter = def.filter;       // the investment option filter to use
    this.display = def.display;     // which text to display in the list

    this.formControl.setValidators(f => !this.investmentValid ? { notValid: true } : null);
    this.formControl.updateValueAndValidity();
  }

  handleData(data: Superannuation) {
    if (data) {
      this.value = data;
      this.log(data);
      this.formControl.setValue(data.fundName);
      this.investmentValid = true;
    } else {
      // no data
    }

    // FOR TESTING
    // if (!this.property) {
    //   this.investmentOption = TEST_PROPERTY;
    // }
  }

  getData() {
    return this.value;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

  removeSuper() {
    if (this.isSearch) {
      this.value = undefined;
      this.formControl.setValue('');
      this.investmentValid = false;
    }
  }

  setSearchInvestment() {
    const formControl = this.el.nativeElement.querySelector('#formInput');
    formControl.focus();
    this.formControl.setValue('');
    this.isSearch = true;
    this.investmentValid = false;
    //this.search(this.formControl.value);
  }

  search(searchValue: string) {
    this.investmentValid = false;
    this.log('searchValue', searchValue);

    if (searchValue.length < 3) {
      this.searchOptions = [];
    } else {
      this.searchText.next(searchValue);
    }
  }

  doSearch(searchValue: string): Observable<any> {
    this.log('doSearch', searchValue);
    if (this.isSearch) {
      const optionFilter = this.filter || new InvestmentOptionFilter();
      optionFilter.name = searchValue;
      optionFilter.returnYears = null;
      optionFilter.sortField = 'name';
      optionFilter.sortDir = 'desc';
      this.log('optionFilter', optionFilter);
      return this.superService.getInvestmentOptions(optionFilter, 0, 50);
    } else {
      this.setManualInvestment();
      return Observable.of();

    }
  }

  setManualInvestment() {
    this.isSearch = false;
    this.showSearchOutput = false;
    const formControl = this.el.nativeElement.querySelector('#formInput');
    formControl.focus();
    this.investmentOption = {
      name: formControl.value
    };
    this.value = this.toSuper(this.investmentOption);
    this.formControl.setValue(formControl.value);
    this.investmentValid = true;
    this.searchOptions = [];
    this.formControl.updateValueAndValidity();
    this.log(this.investmentOption);
  }

  onYourInvestmentClick(investment: InvestmentOption): void {
    this.value = this.toSuper(investment);
    this.investmentOption = investment;
    this.investmentValid = true;
    this.formControl.setValue(this.value.fundName);
    this.searchOptions = [];
    this.formControl.updateValueAndValidity();
  }

  /**
   * Convert an InvestmentOption into a Superannuation summary object.
   * @param investment The InvestmentOption to convert.
   */
  toSuper(investment: InvestmentOption): Superannuation {
    let superannuation: Superannuation = {};

    if (investment) {
      superannuation.optionId = investment.id;
      superannuation.fundName = investment.name;
      superannuation.fundType = "SF";   // not a "SMSF"
      superannuation.productId = investment.product ? investment.product.id : null;

      // determine annual return
      if (investment.performance && investment.performance.length > 0) {
        const snapshot: OptionSnapshot = investment.performance[0];
        // we want the 3 year return, but fallback if none
        if (snapshot.values['return_3'] && snapshot.values['return_3'].value) {
          superannuation.annualReturn = snapshot.values['return_3'].value;
        }
        else if (snapshot.values['return_5'] && snapshot.values['return_5'].value) {
          superannuation.annualReturn = snapshot.values['return_5'].value;
        }
        else if (snapshot.values['return_7'] && snapshot.values['return_7'].value) {
          superannuation.annualReturn = snapshot.values['return_7'].value;
        }
        else if (snapshot.values['return_1'] && snapshot.values['return_7'].value) {
          superannuation.annualReturn = snapshot.values['return_1'].value;
        }
      }
      if (superannuation.annualReturn) {
        // convert range of return % from [0..100] to [0..1]
        superannuation.annualReturn = superannuation.annualReturn / 100.0;
      }
    }

    return superannuation;
  }

}


