import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { AccountSource, LoggerService, MoneyService } from "@rollit/shared/data";
import { UIManagerService } from "@rollit/shared/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { trackById } from '@rollit/shared/utils';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  private log: any;
  accountIdParam: any;
  selectedAccount: any;
  gotAccounts: boolean;
  destroy = new Subject();
  trackById = trackById;


  constructor(
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
  ) {
    this.log = this.logger.info('AccountDetailComponent');
    this.uiService.sideNavIsVisible.next(true);
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: { id: string }) => {
      this.log('route params', params);
      if (params.id !== 'none') {
        this.log('account', params.id);
        this.accountIdParam = params.id;
        this.setupAccount();
      } else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/accounts']));
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.uiService.sideNavIsVisible.next(false);
    this.destroy.next(); this.destroy.complete();
  }

  setupAccount() {
    this.moneyService.getAccount(+this.accountIdParam).subscribe(account => {
      this.log('accounts', account);
      const newAccount = {
        id: account.id,
        nickname: account.nickname,
        source: account.source,
        providerName: account.properties.yodlee['providerName'],
        currentBalance: account.currentBalance,
        lastUpdated: account.properties.yodlee['lastUpdated'],
      };
      this.selectedAccount = newAccount;
      this.gotAccounts = true;
    }, err => {
      this.log('err: ', err);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/accounts']));
    });
  }

  manageAccounts() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/accounts/manage-accounts']));
  }

  onReturnClick() {
    this.router.navigate(['/accounts']);
  }
}
