<div class="holder" [ngClass]="{'desktop':isDesktop}">

  <div class="bar-chart">
    <app-bar-chart-time *ngIf="projectionChartData?.length > 0 && inView" class="chart"
      [seriesData]="projectionChartData" [seriesCategories]="projectionChartCategories"
      [title]="'Lifestyle asset projections'" (graphClicked)="graphClicked($event[0], $event[1])" [showExpand]="false"
      [smallTitle]="true">
    </app-bar-chart-time>
    <div *ngIf="!inView" class="heading1 heading2">Lifestyle asset projections</div>
  </div>


  <div class="details">
    <div class="titleGroup">
      <app-title-group [details]="titleGroupDetails"></app-title-group>
    </div>

    <div class="detail-list">
      <div class="list-item">
        <span class="paragraph2">Your valuation</span>
        <span class="paragraph2">{{acc?.currentBalance  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div *ngIf="acc?.properties?.asset?.depreciation" class="list-item">
        <span class="paragraph2">Depreciation</span>
        <span class="paragraph2">{{acc?.properties?.asset?.depreciation  | percent:'1.2'}}</span>
      </div>

      <div *ngIf="loanAccount?.currentBalance" class="list-item">
        <span class="paragraph2">Outstanding loan</span>
        <span
          class="paragraph2 section-color__negative">{{loanAccount?.currentBalance  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div *ngIf="loanAccount?.properties?.terms?.interestRate" class="list-item">
        <span class="paragraph2">Interest rate</span>
        <span class="paragraph2">{{loanAccount?.properties?.terms?.interestRate   | percent:'1.2'}}</span>
      </div>

      <div *ngIf="loanAccount?.properties?.terms?.repaymentAmount" class="list-item">
        <span class="paragraph2">Monthly repayments</span>
        <span
          class="paragraph2">{{loanAccount?.properties?.terms?.repaymentAmount  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>


    </div>
  </div>
</div>
