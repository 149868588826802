<form name="payment" id="payment">
    <mat-radio-group *ngIf="subscriberType !== 'Individual'">
        <mat-radio-button (change)="radioChange($event)" color="primary" value="card" checked="checked">Credit Card</mat-radio-button>
        <mat-radio-button (change)="radioChange($event)" color="primary" value="eft">Bank transfer</mat-radio-button>
    </mat-radio-group>
    <ng-container id="credCardForm" *ngIf="cardMethodSelected === true">
        <div class="loader-wrapper" *ngIf="!cardFormLoaded">
            <img alt="Loading" class="loader" src="assets/icons/loading-icon.svg">
        </div>
        <div class="input-wrapper">
            <div class="text-field" [id]="inputIdMappings.cardNumber"></div>
        </div>
        <div class="input-wrapper">
            <div class="text-field" [id]="inputIdMappings.cvv"></div>
        </div>
        <div class="input-wrapper">
            <div class="text-field" [id]="inputIdMappings.expirationDate"></div>
        </div>
        <div class="input-wrapper">
            <div class="text-field" [id]="inputIdMappings.postalCode"></div>
        </div>
    </ng-container>
    <ng-container id="credCardForm" *ngIf="cardMethodSelected === false">
        <h4>Please Pay to:</h4>
        <p>Roll-it Super Pty Ltd<br>
            Bank of Melbourne<br>
            BSB: 193-879<br>
            ACC: 415 202 229</p>

        <p>Payment Terms are 7 Days from Invoice Date. </p>
    </ng-container>

    <div class="input-wrapper" id="discount">
        <input name="discount" class="sq-input text-field" placeholder="Discount code" [formControl]="discountCtrl" />
    </div>
    <div id="discount-wrapper">
        <div class="discount">
            <div *ngIf="discount && !!discount.id && !discount.message" class="success">Discount applied!</div>
            <div *ngIf="discount && !!discount.id && discount.message" class="success">{{ discount.message }}</div>
            <div *ngIf="discount && !discount.id" class="error">{{ discount.message }}</div>
            <div *ngIf="!discount" class="info">No discount code applied</div>
        </div>
    </div>

    <div class="row-wrapper">
        <div class="space-width" id="prices-wrapper">
            <div class="prices">
                <div class="space-between">
                    <span class="label">Net: </span>
                    <span id="value-price-net" class="value">{{ price?.net | currency:'AUD' }}</span>
                </div>
                <div class="space-between" *ngIf="price?.discount">
                    <span class="label">Discount: </span>
                    <span class="value">{{ price?.discount | currency:'AUD' }}</span>
                </div>
                <div class="space-between">
                    <span class="label">Tax: </span>
                    <span class="value">{{ price?.tax | currency:'AUD' }}</span>
                </div>
                <div class="space-between" id="total">
                    <span class="label">Total: </span>
                    <span class="value">{{ price?.total | currency:'AUD' }}</span>
                </div>
            </div>
        </div>
        <div class="space-width" id="your-plan">
            <h5>Your plan</h5>
            <ul class="dashed">
                <li>{{subscriberType}} plan</li>
                <li>{{originalPrice?.total | currency:'AUD'}} GST included, per {{billingCycle}}</li>

                <li>12 month term</li>
                <!--                <li class="green"><span>30 day free trial</span><i class="material-icons">done</i></li>-->
            </ul>
        </div>
    </div>
    <ng-container *ngIf="cardMethodSelected === true">
        <button mat-raised-button class="rollit-button " color="primary" id="submit-form" (click)="requestCardNonce($event)">Complete
            payment</button>
    </ng-container>
    <ng-container *ngIf="cardMethodSelected === false">
        <button mat-raised-button class="rollit-button " color="primary" id="submit-form-eft" (click)="submitEFT($event)">Complete
            subscription</button>
    </ng-container>
    <img alt="Loading" *ngIf="submitRequest && !signupError" class="loader blue" src="assets/icons/loading-icon.svg">
</form>