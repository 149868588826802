import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-subscription-home',
    templateUrl: './subscription-home.component.html',
    styleUrls: ['./subscription-home.component.scss']
})
export class SubscriptionHomeComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
