import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-health-score-graphic',
  templateUrl: './health-score-graphic.component.html',
  styleUrls: ['./health-score-graphic.component.scss']
})
export class HealthScoreGraphicComponent implements OnInit {
  @Input() profileFinancialGoalResult: string;
  @Input() scoreIndexCategoryResult: string;
  constructor() { }

  ngOnInit() {
  }

}
