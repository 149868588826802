<div class="header">
  <div class="profile" *ngIf="profile" (click)="onProfileClick()">
    <div class="image" [ngStyle]="{backgroundImage:'url('+ profilePicUrl +')'}"></div>
    <div class="details" [ngClass]="{'collapsed' : sideNavIsCollapsed}">
      <span class="name" title="{{profile.firstName}} {{profile.lastName}}">{{profile.firstName}}
        {{profile.lastName}}</span>
      <span class="email" title="{{profile.email}}">{{profile.email}}</span>
    </div>
  </div>

  <i *ngIf="!mediaSizeIsDesktop" (click)="onCloseDrawerNavigation()" class="material-icons close">close</i>

</div>

<div class="spacer"></div>

<div *ngIf="itemsReady" class="mat-tab-links">
  <div class="mat-tab-item" (click)="onNavClick(item, $event)" *ngFor="let item of itemLinks"
    id="nav-{{item.title | lowercase}}"
    [ngClass]="{'hide': item.hide || (item.rLink === '/reward' && showUpgrade), 'active': item.active, 'spacer-item' : item.spacer}">
    <div class="title">
      <i class="material-icons" *ngIf="item.icon.indexOf('icon-') === -1">{{item.icon}}</i>
      <span *ngIf="item.icon.indexOf('icon-') !== -1" class="{{item.icon}} filter-icon"></span>
      <span *ngIf="!sideNavIsCollapsed" class="text" [innerHTML]="item.title"></span>
    </div>
    <div *ngIf="item.spacer" class="spacer"></div>
  </div>
</div>
