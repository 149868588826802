import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '@rollit/shared/data';

@Component({
  selector: 'app-form-field-item',
  templateUrl: './form-field-item.component.html',
  styleUrls: ['./form-field-item.component.scss']
})
export class FormFieldItemComponent implements OnInit {

  @Input() isLocked: boolean; // When true the user cannot edit this field
  @Input() hideLock: boolean; // When true the user cannot edit this field
  @Input() isValid: boolean;
  @Input() editMode = false;
  @Output() editModeChange = new EventEmitter<boolean>();
  @Output() editClicked = new EventEmitter<boolean>();
  @Output() cancelClicked = new EventEmitter<boolean>();
  @Output() completeClicked = new EventEmitter<boolean>();

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
  }

  onEditClick() {
    this.editClicked.emit();
    this.editModeChange.emit(true);
  }

  onCancel() {
    this.cancelClicked.emit();
    this.editModeChange.emit(false);
  }

  onComplete() {
    this.completeClicked.emit();
    if (this.isValid) {
      this.editModeChange.emit(false);
    } else {
      this.notificationService.info('Warning', 'Please fix errors before continuing');
    }
  }

  onContainerClick() {
    if (!this.editMode) {
      this.onEditClick();
    }
  }

}
