<div class="column-holder">

  <div class="content-holder">





    <div class="column-holder">

      <div class="button-wrapper">
        <button type="button" mat-raised-button class="rollit-button " color="primary" (click)="addEmployee()"><i
            class="material-icons">add</i>ADD
          EMPLOYEE</button>

        <!-- <button type="button" mat-raised-button class="rollit-button " color="primary" (click)="exportUsers()"><i
            class="material-icons">get_app</i>Export
          EMPLOYEES</button> -->
      </div>


    </div>


    <mat-form-field appearance="outline" class="search-input">
      <i *ngIf="searchActive" class="material-icons search-close" (click)="emptySearch($event)">close</i>
      <mat-label>Search employees</mat-label>

      <input matInput placeholder="Search for an employee" (keyup)="applyFilter($event.target.value)">
    </mat-form-field>


  </div>

  <div class="content-right">
    <div class="content-item">
      <span class="title">Subscriptions:</span>
      <span class="description">{{totalEmployeesStatic}}</span>
    </div>
    <div class="content-item">
      <span class="title">Active:</span>
      <span class="description">{{activeEmployees}}</span>
    </div>
    <div class="content-item">
      <span class="title">Inactive:</span>
      <span class="description">{{inactiveEmployees}}</span>
    </div>
    <div class="content-item">
      <span class="title">Subscription period:</span>
      <span class="description">{{employer?.subscription?.timeStarted | date:'dd MMM y'}} -
        {{employer?.subscription?.timeDue | date:'dd MMM y'}}</span>
    </div>
    <div class="content-item">
      <span class="title color-secondary">End Subscription:</span>
      <span class="description">Subscription auto-renewal at the subscription end date.
        <br>Subscription can be cancelled one month prior to end date.
      </span>
    </div>
  </div>
</div>

<div class="table-holder">
  <table mat-table class="rollit-table" [dataSource]="dataSource" matSort [hidden]="!tableReady" multiTemplateDataRows
    [trackBy]="trackById">
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row"> {{row?.firstName}} {{row?.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let row"> {{row?.email}} </td>
    </ng-container>

    <ng-container matColumnDef="payrollNumber">
      <th mat-header-cell *matHeaderCellDef>Payroll Number</th>
      <td mat-cell *matCellDef="let row"> {{row?.payrollNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="timeCreated">
      <th mat-header-cell *matHeaderCellDef>Start Date</th>
      <td mat-cell *matCellDef="let row">{{row?.timeCreated | date}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="cell-right">Edit</th>
      <td mat-cell *matCellDef="let row" (click)="editEmployee($event, row)">
        <button mat-raised-button color="primary" class="rollit-button mat-tiny-button mat-icon-only-button">
          <i class="material-icons">edit</i>
        </button>
      </td>
    </ng-container>

    <!-- Secondary Column -->
    <ng-container matColumnDef="secondary">
      <td mat-cell [attr.colspan]="displayedColumns.length" *matCellDef="let row" class="detail-cell">
        <div class="details-holder">
          <div class="first">
            <div><span>Title:</span><span>{{row?.title}}</span></div>
            <div><span>First name:</span><span>{{row?.firstName}}</span></div>
            <div><span>Last name:</span><span>{{row?.lastName}}</span></div>
            <div><span>Sex:</span><span>{{row?.sex}}</span></div>
            <div><span>Date of birth:</span><span>{{row?.dob | date:'dd MMM y'}}</span></div>
            <div><span>Phone number:</span><span>{{row?.phone}}</span></div>
          </div>
          <div class="second">
            <div><span>Payroll Number:</span><span>{{row?.payrollNumber}}</span></div>
            <div><span>Super fund member number:</span><span>{{row?.superFundMemberNumber}}</span></div>
            <div><span>Super fund USI:</span><span>{{row?.superFundUsi}}</span></div>
            <div><span>Subscription added:</span><span>{{row?.employment?.timeCreated | date:'dd MMM y'}}</span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = dataIndex; columns: displayedColumns" (click)="toggleDetailsRow(i)"
      id="parent-row-{{i}}" class="parent-row"></tr>
    <tr mat-row *matRowDef="let row; let i = dataIndex;  columns: ['secondary'];" id="row-{{i}}" class="expanded-row">
    </tr>
  </table>
  <mat-paginator #paginator [length]="totalEmployees" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>
