<div *ngIf="details" class="title-group">
  <div class="title-group__holder" [ngClass]="{'column':displayAsColumn}">
    <div class="title-group__item" *ngFor="let item of details"
      [ngClass]="{'hide':item.hide, 'fullWidth':displayFullWidth}">
      <p class="paragraph1 title">{{ item?.title }}</p>

      <div class="title-group__value heading2 value" [ngStyle]="{ color: item.dataColor }">
        <ng-container *ngIf="item.isCurrency">
          {{ item?.data | currency: 'USD':'symbol-narrow':'1.0-0' }}
        </ng-container>
        <ng-container *ngIf="item.isDate">
          {{ item?.data | date: 'MMM y' }}
        </ng-container>
        <ng-container *ngIf="item.isString">
          {{ item?.data }}
        </ng-container>
        <ng-container *ngIf="item.isNumber">
          {{ item?.data }}
        </ng-container>
      </div>
      <div *ngIf="item.additionalInfo" class="title-group__additional-info">
        {{ item.additionalInfo }}
      </div>
    </div>
  </div>
</div>
