<div class="holder" [ngClass]="{'desktop':isDesktop}">

  <div class="bar-chart">
    <app-bar-chart-time *ngIf="projectionChartData.length > 0 && inView" class="chart"
      [seriesData]="projectionChartData" [seriesCategories]="projectionChartCategories"
      [title]="'Repayment projections'" [smallTitle]="true" [timescale]="timescale"
      (graphClicked)="graphClicked($event[0], $event[1], $event[2])" [showExpand]="false">
    </app-bar-chart-time>
    <div *ngIf="!inView" class="heading1 heading2">Repayment projections</div>
  </div>

  <div class="details">
    <div class="titleGroup">
      <app-title-group [details]="titleGroupDetails"></app-title-group>
    </div>

    <div class="detail-list" *ngIf="acc">

      <div *ngIf="acc?.accountType === 'creditcard'" class="list-item">
        <span class="paragraph2">Credit card limit</span>
        <span class="paragraph2">-{{acc?.properties?.terms?.limit  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Outstanding balance</span>
        <span class="paragraph2">{{acc?.currentBalance  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Monthly repayments</span>
        <span
          class="paragraph2">{{acc?.properties?.terms?.repaymentAmount  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Interest rate</span>
        <span class="paragraph2">{{acc?.properties?.terms?.interestRate   | percent:'1.2'}}</span>
      </div>

      <div *ngIf="projectedDebtFreeDate" class="list-item">
        <span class="paragraph2">Debt free</span>
        <span class="paragraph2">{{projectedDebtFreeDate | date: 'MMM y' }}</span>
      </div>

    </div>
  </div>
</div>
