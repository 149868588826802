import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';


const PrivacyDisclaimerRoutes: Routes = [
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
    data: {
      title: 'Disclaimer',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'Privacy',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(PrivacyDisclaimerRoutes)],
  exports: [RouterModule],
})
export class PrivacyDisclaimerRoutingModule { }
