import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { UIManagerService, ItemLink } from '@rollit/shared/services';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LocationStrategy, PlatformLocation } from '@angular/common';

declare var Intercom; // defined by included Intercom javascript.

@Component({
  selector: 'footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss']
})
export class FooterNavigationComponent implements OnInit {

  backgroundIsBlue: boolean;
  isDesktop: boolean;
  isVisible: boolean;
  router: Router;
  itemWidth: String;
  hasNotifications = false;
  intercomOpen = false;
  navigationLinks: Array<any>;
  currentURL: String;
  filterIsActive: boolean;

  itemLinksHome: ItemLink[] = this.uiService.footerLinks.home;
  itemLinksExplore: ItemLink[] = this.uiService.footerLinks.explore;
  itemLinksTrack: ItemLink[] = this.uiService.footerLinks.track;
  itemLinks: ItemLink[] = this.uiService.footerLinks.default;

  constructor(private uiService: UIManagerService, router: Router, private _ngZone: NgZone) {
    this.router = router;
    this.uiService.bottomNavIsPrimary.subscribe(val => this.backgroundIsBlue = val);
    this.uiService.bottomNavIsVisible.subscribe(val => this.isVisible = val);
    this.uiService.filterMenuIsOpen$.subscribe(isActive => this.filterIsActive = isActive);
    this.uiService.mediaSizeIsDesktop$.subscribe(isDesktop => this.isDesktop = isDesktop);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        this.setupLinks();
      }
    });

  }



  ngOnInit() {


    this.hasNotifications = false;
    this.intercomOpen = false;
    const self = this;



  }

  setupLinks() {
    if (this.currentURL.indexOf('track') > -1) {
      this.navigationLinks = this.itemLinksTrack;
    } else if (this.currentURL.indexOf('explore') > -1) {
      this.navigationLinks = this.itemLinksExplore;
    } else if (this.currentURL.indexOf('home') > -1) {
      this.navigationLinks = this.itemLinksHome;
    } else {
      this.navigationLinks = this.itemLinks;
    }
    this.itemWidth = 100 / this.navigationLinks.length + '%';
  }
  onFilterPressed(): void {
    this.uiService.filterMenuIsOpen = !this.filterIsActive;
  }

  onHideIntercom() {
    this.intercomOpen = false;
    this._ngZone.run(() => { });
  }

  onNavClick(item: ItemLink, event) {
    // Stop the event from firing the <a> tags routerLink default behaviour since we want to handle the routing ourselves.
    // routerLink is required for angular to decide whether route is active
    event.preventDefault();
    event.stopPropagation();
    Intercom('trackEvent', 'web-app-bottom-nav-click-' + item.rLink);
    if (item.rList === null) {
      this.router.navigate([item.rLink]);
    } else if (item.rLink === 'filter') {
      this.onFilterPressed();
    } else {
      this.router.navigate([item.rLink, { 'outlets': { 'list': [item.rList], 'detail': [this.isDesktop ? item.rDetail : 'none'] } }]);
    }

    return false;
  }

}
