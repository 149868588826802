// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  deployment: 'demo',

  production: false,

  useServiceWorker: true,  // whether to use a service worker for this app.

  embedded: {
    waitForToken: true,    // whether to wait for token before completing app intialisation.
    inAppPurchases: true,
  },

  locale: 'en-AU',

  intercom: {
    appId: 'msfec4lf'
  },

  // The base URL for the web service API
  apiUrl: 'https://api.demo.hey.coach/api',

  // URL of the main Rollit-super site
  siteUrl: 'app.demo.hey.coach/',

  agreementUrl: {
    app: "http://about.hey.coach/en/articles/3544361-terms-of-use-app-store",
    web: "https://about.hey.coach/privacy-and-terms/roll-it-super-individual-agreement"
  },
  adviceDisclaimerUrl: 'https://about.hey.coach/en/articles/6061581-general-advice-disclaimer',
  projectionDisclaimerUrl: 'https://about.hey.coach/en/articles/6083076-projections-disclaimer',
  privacyUrl: 'https://about.hey.coach/en/articles/2344921-privacy-policy',
  FSGUrl: 'https://about.hey.coach/privacy-and-terms/financial-services-guide',


  square: {
    applicationId: 'sandbox-sq0idb-4daegBLpcbRGsHwBFxW5Ig',    // the application ID
    locationId: 'JTN03Z4J0J58T',                               // the location ID
    js: "https://js.squareupsandbox.com/v2/paymentform",
  },

  // Member Benefits Australia
  mba: {
    url: "https://rollit.test.rewards-plus.com.au"
  },

  yodlee: {
    fastlinkUrl: "https://development-node.yodlee.com.au/authenticate/ANZDevexPreProd-132/?channelAppName=anzdevexpreprod"
  },


  keycloak: {
    "url": "https://api.demo.hey.coach/auth",
    "realm": "rollit",
    "clientId": "rollit-app",
  },

  storeTokens: true,  // whether to store access tokens locally for re-use.
};
