<div class="uploader">
  <div class="existing-users" *ngIf="existingEmployees.length > 0">
    <h3>The following users are already Rollit users and will be skipped.</h3>
    <p *ngFor="let existing of existingEmployees">{{existing['Email Address']}}</p>
  </div>



  <div *ngIf="csvRecords?.length > 0;then content else other_content"></div>

  <ng-template #other_content>
    <h3 *ngIf="uploaderHasRun" class="error">There were no valid records in the CSV.</h3>
  </ng-template>


  <form #dataForm="ngForm">
    <ng-template #content>
      <div class="table-container">

        <table mat-table class="rollit-table" [dataSource]="csvRecords" [trackBy]="trackByIndex">
          <ng-container *ngFor='let rh of requiredHeaders' matColumnDef="{{rh.name}}">
            <th mat-header-cell *matHeaderCellDef>
              <span class="th-row" *ngIf="rh.name === 'Row'">{{rh.name}}</span>
              <mat-form-field *ngIf="rh.name !== 'Row'" class="full-width">
                <mat-label>{{rh.name}}</mat-label>
                <select matNativeControl required [(ngModel)]="rh.field" [name]="rh.name">
                  <option></option>
                  <option [value]="h" *ngFor="let h of headers">{{h}}</option>
                </select>
              </mat-form-field>
            </th>

            <td mat-cell *matCellDef="let record; let i=index;">
              <span class="td-row" *ngIf="rh.name === 'Row'">{{i}}</span>
              <mat-form-field *ngIf="rh.field" class="full-width" floatLabel="never">
                <input matInput *ngIf="rh.type === 'alphanumeric'" [name]="rh.prop + i" type="text"
                  placeholder="{{rh.name}}" [(ngModel)]="record[rh.field]" alphaNumeric
                  [required]="rh.optional ? null : 'required'" />
                <input matInput *ngIf="rh.type === 'string'" [name]="rh.prop + i" type="text" placeholder="{{rh.name}}"
                  [(ngModel)]="record[rh.field]" [required]="rh.optional ? null : 'required'" />
                <input matInput *ngIf="rh.type === 'email'" [name]="rh.prop + i" type="email" placeholder="{{rh.name}}"
                  [(ngModel)]="record[rh.field]" email [required]="rh.optional ? null : 'required'" />
                <input matInput *ngIf="rh.type === 'phone'" [name]="rh.prop + i" type="tel" placeholder="{{rh.name}}"
                  [(ngModel)]="record[rh.field]" telephoneNumber [required]="rh.optional ? null : 'required'" />
                <input matInput *ngIf="rh.type === 'date'" [name]="rh.prop + i" type="text" placeholder="{{rh.name}}"
                  [(ngModel)]="record[rh.field]" birthDate [required]="rh.optional ? null : 'required'" />
                <input matInput *ngIf="rh.type === 'sex'" [name]="rh.prop + i" type="text" placeholder="{{rh.name}}"
                  [(ngModel)]="record[rh.field]" sex [required]="rh.optional ? null : 'required'" />
                <mat-hint class="error" *ngIf="!isValid(rh.prop + i)" align="start">{{getMessage(rh.prop+i)}}
                </mat-hint>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="csvColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let rows; columns: csvColumns"></tr>
        </table>

      </div>
      <button mat-raised-button class="rollit-button " (click)="onSubmit()" color="primary" type="submit"
        [disabled]="!isDataValid() || uploading">ON-BOARD ALL
        {{csvRecords?.length}}
        EMPLOYEES</button>
    </ng-template>
  </form>

</div>
