import { Inject, Injectable } from "@angular/core";
import { KeycloakInitOptions, KeycloakService, KeycloakEventType } from "keycloak-angular";
import { APP_ENVIRONMENT } from "@rollit/shared";
import { Subject } from "rxjs";
import { KeycloakOnLoad } from 'keycloak-js';

export const TOKENS = 'tokens';
export const bearerExcludedUrls = [
  '/favicon',
  '/assets/',
  '/clients/public',
  '/manifest.json',
  '/ngsw.json',
  '/*.js'
];

export interface TokenInfo {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;     // "bearer"
  "not-before-policy": number;
  session_state: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _initSubject = new Subject<boolean>();

  constructor(
    private keycloak: KeycloakService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    if (this.environment.storeTokens) {
      // store tokens when auth success
      keycloak.keycloakEvents$.subscribe(event => {
        if (event.type === KeycloakEventType.OnAuthRefreshSuccess || event.type === KeycloakEventType.OnAuthSuccess) {
          this.storeTokens();     // when auth
        }
      });
    }
  }

  get init$() {
    return this._initSubject.asObservable();
  }

  storeTokens() {
    const instance = this.keycloak.getKeycloakInstance();
    const tokens = {
      token: instance.token,
      refreshToken: instance.refreshToken,
      idToken: instance.idToken
    };
    localStorage.setItem(TOKENS, JSON.stringify(tokens));
  }

  /**
   *
   */
  async init(onload: KeycloakOnLoad = 'login-required'): Promise<boolean> {
    try {
      const options: KeycloakInitOptions = {
        onLoad: onload,
        checkLoginIframe: false,
      };

      // try tokens in local storage
      if (this.environment.storeTokens) {
        const tokensStr = localStorage.getItem(TOKENS);
        if (tokensStr) {
          const tokens = JSON.parse(tokensStr);
          options.token = tokens.token;
          options.refreshToken = tokens.refreshToken;
          options.idToken = tokens.idToken;
        }
      }

      const result = await this.keycloak.init({
        config: this.environment.keycloak,
        initOptions: options,
        bearerExcludedUrls: bearerExcludedUrls,
      });

      this._initSubject.next(true);
      return result;
    } catch (error) {
      this._initSubject.next(false);
      throw error;
    }
  }

  async initWithToken(tokens: TokenInfo) {
    try {
      const options: KeycloakInitOptions = {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        token: tokens.access_token,
        refreshToken: tokens.refresh_token,
      };

      const result = await this.keycloak.init({
        config: this.environment.keycloak,
        initOptions: options,
        bearerExcludedUrls: bearerExcludedUrls,
      });

      this._initSubject.next(result);
      return result;
    } catch (error) {
      this._initSubject.next(false);
      throw error;
    }
  }
}
