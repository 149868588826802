<!-- <div class="asset-borrowings">
  <div class="overlap"> -->

<div class="item">
  <span class="title"><span class="dot assets">&#8226;</span> {{ title }}</span>
  <span class="value"> {{ value }} </span>
  <!-- <span class="change"> <span class="down">-</span>$1,000 </span> -->
</div>

<!-- <div class="item right">
      <span class="title">
        <span class="dot borrowings">&#8226;</span> Borrowings
      </span>
      <span class="value"> $120,000 </span>
      <span class="change"> <span class="down">-</span>$1,000 </span>
    </div>
  </div>
</div> -->
