import { Account } from "./loan";
import { MoneyAccount } from "./money";
import { User } from "./user";

export interface NudgeCategory {
    id: number;
    name: string;
    slug: string;
}

export interface NudgeCard {
    id: number;
    name: string;
    title: string;
    description: string;
    type: string;       // nudge type
    category: NudgeCategory;
    section: string;        // the Hey Coach section this card relates to.
    priority: number;
    action: string;         // action to be taken
    params: any;            // the params for the action
    actionText: string;
    enabled: boolean;
}

export enum NudgeStatus {
    dismissed = 'dismissed',        // the user dismissed the nudge
    actioned = 'actioned',          // the user has actioned the nudge
    cancelled = 'cancelled',        // cancelled by the system. usually a change in financial situation
    lapsed = 'lapsed'               // open for too long
}

export interface Nudge {
    id: number;
    user: User;
    title: string;
    description: string;
    status: NudgeStatus;
    timeCreated: string;
    timeUpdated: string;
    timeClosed: string;         // date-time that this nudge was closed.
    definition: NudgeCard;      // the card defining this nudge
    properties: any;            // extra properties of this nudge
    account?: MoneyAccount;     // optional associated account
}
