import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { Order, Price, DiscountCode, Product, PaymentType } from '@rollit/shared/data';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '@rollit/shared/data';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { SubscriptionService } from '@rollit/shared/data';
import { CustomerType } from '@rollit/shared/data';

const TRACE = true;

@Component({
  selector: 'app-free-details',
  templateUrl: './free-details.component.html',
  styleUrls: ['./free-details.component.scss']
})
export class FreeDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  log: any;
  @Input() product: Product;
  @Input() type: string;
  @Input() price: Price;
  @Input() discount: DiscountCode;
  @Input() subscriberType: CustomerType;
  @Input() signupError: Boolean;
  @Input() numberOfEmployeesControl: FormControl;
  public _discountCode: string;
  originalPrice: Price;
  discountCtrl = new FormControl();
  submitRequest = false;
  cardMethodSelected = true;
  paymentType: PaymentType = PaymentType.Free;
  cardFormLoaded = false;
  numberOfEmployees: number;

  unsubscribe = new Subject<void>();

  @Output() discountCode = new EventEmitter<string>();
  @Output() submitOrder = new EventEmitter<Order>();


  constructor(private renderer: Renderer2,
    private notificationService: NotificationService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private ss: SubscriptionService,
    private logger: LoggerService) {
    this.log = this.logger.info('payment-details');

  }

  ngOnInit() {
    this.discountCtrl.valueChanges.pipe(debounceTime(500)).pipe(takeUntil(this.unsubscribe)).subscribe(discount => {
      this.onDiscountChange(discount);
    });
    if (this.numberOfEmployeesControl) {
      this.numberOfEmployeesControl.valueChanges.subscribe(value => {
        this.numberOfEmployees = value;
        this.getPriceWithoutTrial();
      });
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  submitFree(event) {
    const order: Order = {
      items: [],
      payments: [{
        // type: PaymentMethodType.EFT,
        // save: true,
      }],
      customer: {},
      discountCode: { code: this._discountCode },
    };

    this.submitOrder.emit(order);
    this.submitRequest = true;
  }

  public onDiscountChange(discountCode: string) {
    if (TRACE) {
      this.log('Discount code', discountCode);
    }
    this._discountCode = discountCode;
    this.discountCode.emit(this._discountCode);
    this.getPriceWithoutTrial();
  }

  getPriceWithoutTrial() {
    const product = this.product;

    if (this.subscriberType === CustomerType.employer) {
      if (this.numberOfEmployees) {
        product.attributes[0].value = this.numberOfEmployees;
      }
    }
    this.log('this.type', this.type);
    this.log('this.subscriberType', this.subscriberType);
    this.log('this.product', product);
    const order: Order = {
      items: [{ product: product }],
      discountCode: { code: this._discountCode },
    };

    this.ss.checkOrder(order).subscribe(
      result => {
        this.log('Got check-order response', result);
        this.originalPrice = result.price;
      },
      err => {
        console.error('Problem checking  order', err);
        this.notificationService.error('Problem checking order', err.error.message);
      }
    );
  }

}
