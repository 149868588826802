import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';

@Component({
  selector: 'lib-report-financial-health-score',
  templateUrl: './financial-health-score.component.html',
  styleUrls: ['./financial-health-score.component.scss']
})
export class FinancialHealthScoreComponent implements OnInit, OnDestroy {
  log: any;
  @Input() useQuery: boolean;
  @Input() scoreIndex: number;
  @Input() profileFinancialGoal: string;
  profileFinancialGoalResult: string;
  @Input() scoreIndexCategory: string;
  scoreIndexCategoryResult: string;

  @Input() reportText: any;

  constructor(private logger: LoggerService, private uiService: UIManagerService, private route: ActivatedRoute, private dialogService: DialogService) {
    this.log = this.logger.info('FinancialHealthScoreComponent');
    this.uiService.displayContentAsFullPage.next(true);


  }

  ngOnInit(): void {
    this.log('FinancialHealthScoreComponent useQuery', this.useQuery);
    if (this.useQuery === false) {
      this.log('FinancialHealthScoreComponent scoreIndex', this.scoreIndex);
      this.log('FinancialHealthScoreComponent profileFinancialGoal', this.profileFinancialGoal);
      this.log('FinancialHealthScoreComponent scoreIndexCategory', this.scoreIndexCategory);
      this.setupData();
    } else {
      this.route.queryParams.subscribe(params => {
        this.log('params', params);
        this.scoreIndex = +params['score_index'];
        this.profileFinancialGoal = params['profile_financial_goal'];
        this.scoreIndexCategory = params['score_index_category'];
        this.dialogService.getReportText().subscribe(data => {
          this.reportText = data;
          this.log('getReportText', data);
          this.setupData();
        });

      });
    }
  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }

  setupData() {
    this.profileFinancialGoalResult = this.reportText.health_score.financial_goal[this.profileFinancialGoal];
    this.scoreIndexCategoryResult = this.reportText.health_score.score_index_category[this.scoreIndexCategory];
  }

}
