<div class="content-holder">
  <div class="chart" *ngIf="totalEmployees">
    <div class="chart-holder">

      <div id="chartLegendPayWrapper" class="chart-wrapper">
        <div class="overview-upper">
          {{ this.totalEmployees }}
        </div>
        <div class="overview-lower">Subscriptions</div>
      </div>

      <div class="holder" [ngClass]="{'show': showChart}">
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" (resized)="chart?.reflow()"
          [callbackFunction]="getInstance.bind(this)"></highcharts-chart>
      </div>
      <!-- <canvas id="subscriptionsDonutChart" width="350" height="300" baseChart [data]="doughnutChartData" [options]="doughnutChartOptions" [labels]="doughnutChartLabels" [colors]="doughnutChartColors" chartType="doughnut"></canvas> -->
    </div>
    <div class="accordion-holder">
      <div class="rollit-accordion-item" *ngFor="let s of this.chartOptions.series[0]['data']; let i = index">
        <div class="rollit-accordion-group">
          <span class="rollit-accordion-color-block" [ngStyle]="{ background: s.color }"></span>
          <span class="rollit-accordion-description">{{ s.name }}</span>
        </div>
        <span class="rollit-accordion-value">{{ s.y }}</span>
      </div>
    </div>
  </div>

  <div class="content">
    <h3>{{ employer?.name }}</h3>
    <div class="content-item">
      <span class="title">Subscription type:</span>
      <span class="description">{{employer?.subscription?.product?.name}}</span>
    </div>
    <div class="content-item">
      <span class="title">No. subscriptions:</span>
      <span class="description">{{this.totalEmployees}}</span>
    </div>
    <div class="content-item">
      <span class="title">Subscription period:</span>
      <span class="description">{{employer?.subscription?.timeStarted | date:'dd MMM y'}} -
        {{employer?.subscription?.timeDue | date:'dd MMM y'}}</span>
    </div>
    <div class="content-item">
      <span class="title">Agreement:</span>
      <span class="description"><a target="_blank"
          href="https://about.rollitwealth.com/privacy-and-terms/roll-it-super-employer-agreement">Customer
          agreement</a></span>
    </div>

  </div>
</div>
