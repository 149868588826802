import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, OnDestroy, Renderer2, Inject } from '@angular/core';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { Order, Price, DiscountCode, PaymentMethodType, Product, PaymentType } from '@rollit/shared/data';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '@rollit/shared/data';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { DynamicScriptLoaderService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { SCSS_VARS } from '@rollit/shared';
import { SubscriptionService } from '@rollit/shared/data';
import { ConfirmationDialogComponent } from '@rollit/shared/common';
import { CustomerType } from '@rollit/shared/data';

declare var SqPaymentForm: any; // script for this is include in <head> for now

const TRACE = true;

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  log: any;
  @Input() billingCycle: string;              // billingCycle
  @Input() price: Price;
  @Input() discount: DiscountCode;
  @Input() subscriberType: CustomerType;    // member type; Individual, Employer
  @Input() signupError: Boolean;
  @Input() numberOfEmployeesControl: FormControl;
  public _discountCode: string;
  private _product: Product;
  originalPrice: Price;
  discountCtrl = new FormControl();
  submitRequest = false;
  cardMethodSelected = true;
  paymentType: PaymentType = PaymentType.Free;
  cardFormLoaded = false;
  @Input() numberOfEmployees: number;

  unsubscribe = new Subject<void>();

  @Output() discountCode = new EventEmitter<string>();
  @Output() submitOrder = new EventEmitter<Order>();

  paymentForm: any;
  nonce: string;

  // Map fieldName of sq input to the id we use
  inputIdMappings = {
    cardNumber: 'sq-card-number',
    cvv: 'sq-cvv',
    expirationDate: 'sq-expiration-date',
    postalCode: 'sq-postal-code'
  };

  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private renderer: Renderer2,
    private notificationService: NotificationService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private ss: SubscriptionService,
    private logger: LoggerService,
    private dialog: MatDialog
  ) {
    this.log = this.logger.info('payment-details');
  }

  @Input()
  set product(value: Product) {
    this._product = value;
    this.log('My product', value);
    this.getPriceWithoutTrial();
  }

  get product() {
    return this._product;
  }

  ngOnInit() {
    this.discountCtrl.valueChanges.pipe(debounceTime(500)).pipe(takeUntil(this.unsubscribe)).subscribe(discount => {
      this.onDiscountChange(discount);
    });
    if (this.numberOfEmployeesControl) {
      this.numberOfEmployeesControl.valueChanges.subscribe(value => {
        this.numberOfEmployees = value;
        this.getPriceWithoutTrial();
      });
    }
  }

  radioChange(event: MatRadioChange) {
    this.cardMethodSelected = event.value === 'card';
    this.log('this.paymentMethodSelected ', this.cardMethodSelected);
    if (event.value === 'card') {
      // give the form a little time before building
      setTimeout(() => this.buildForm(), 200);
    }
  }

  ngAfterViewInit() {
    this.loadSquarePayments();
  }

  buildForm() {
    const self = this;
    this.cardFormLoaded = false;

    // Create and initialize a payment form object
    this.paymentForm = new SqPaymentForm({

      // Initialize the payment form elements
      applicationId: this.environment.square.applicationId,
      locationId: this.environment.square.locationId,
      autoBuild: false,
      inputClass: 'sq-input',

      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [{
        fontSize: '1em',
        color: SCSS_VARS['$primary']
      }],

      // Initialize the credit card placeholders
      cardNumber: {
        elementId: self.inputIdMappings.cardNumber,
        placeholder: 'Card number *'
      },
      cvv: {
        elementId: self.inputIdMappings.cvv,
        placeholder: 'CVV *'
      },
      expirationDate: {
        elementId: self.inputIdMappings.expirationDate,
        placeholder: 'Expiry date *'
      },
      postalCode: {
        elementId: self.inputIdMappings.postalCode,
        placeholder: 'Postcode *'
      },

      // SqPaymentForm callback functions
      callbacks: {

        /*
         * callback function: methodsSupported
         * Triggered when: the page is loaded.
         */
        // methodsSupported: function (methods) {
        //   if (TRACE) {
        //     this.log('method supported', methods);
        //   }
        // },

        /*
         * callback function: createPaymentRequest
         * Triggered when: a digital wallet payment button is clicked.
         */
        // createPaymentRequest: function () {
        //   if (TRACE) {
        //     this.log('createPaymentRequest');
        //   }

        //   const paymentRequestJson = {};
        //   /* ADD CODE TO SET/CREATE paymentRequestJson */
        //   return paymentRequestJson ;
        // },

        /*
         * callback function: validateShippingContact
         * Triggered when: a shipping address is selected/changed in a digital
         *                 wallet UI that supports address selection.
         */
        validateShippingContact: (contact) => {
          if (TRACE) {
            self.log('validateShippingContact', contact);
          }

          const validationErrorObj = {};
          /* ADD CODE TO SET validationErrorObj IF ERRORS ARE FOUND */
          return validationErrorObj;
        },

        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: (errors, nonce, cardData, billingContact, shippingContact) => {
          self.renderer.removeClass(document.getElementById(self.inputIdMappings.cardNumber), 'sq-input--error');
          self.renderer.removeClass(document.getElementById(self.inputIdMappings.cvv), 'sq-input--error');
          self.renderer.removeClass(document.getElementById(self.inputIdMappings.expirationDate), 'sq-input--error');
          self.renderer.removeClass(document.getElementById(self.inputIdMappings.postalCode), 'sq-input--error');

          if (TRACE) {
            self.log('cardNonceResponse', errors, nonce);
            self.log('card data', cardData);
            self.log('billingContact', billingContact);
            self.log('shippingContact', shippingContact);
          }

          if (errors) {
            // Log errors from nonce generation to the Javascript console
            console.warn('Encountered payment errors:');
            self.submitRequest = false;
            let fieldCount = 0;
            let errorMessage = ';'
            errors.forEach(error => {
              if (self.inputIdMappings[error.field]) {
                self.renderer.addClass(document.getElementById(self.inputIdMappings[error.field]), 'sq-input--error');
                fieldCount++;
              }
              // console.warn('  ' + error.message);
              errorMessage = error.message;
              // self.notificationService.error('Error', error.message);
            });
            if (fieldCount === 0) {
              self.onSubmitError(errorMessage);
              self.log('fieldCount', fieldCount);
            }

            return;
          }
          self.nonce = nonce;

          // Send order to PaymentFormComponent where order.items is added and then submitted
          const order: Order = {
            items: [],
            payments: [{
              type: PaymentMethodType.Card,
              cardNonce: nonce,
              billingAddress: billingContact,
              shippingAddress: shippingContact,
              save: true,
            }],
            customer: {},
            discountCode: { code: this._discountCode },
          };

          self.submitOrder.emit(order);
          self.submitRequest = true;
        },

        /*
         * callback function: unsupportedBrowserDetected
         * Triggered when: the page loads and an unsupported browser is detected
         */
        unsupportedBrowserDetected: function () {
          if (TRACE) {
            self.log('unsupported browser');
          }
          self.notificationService.error('Your browser is not supported.');
        },

        /*
         * callback function: inputEventReceived
         * Triggered when: visitors interact with SqPaymentForm iframe elements.
         */
        inputEventReceived: (inputEvent) => {
          switch (inputEvent.eventType) {
            case 'focusClassAdded':
              // For the focussed field, remove the error class
              self.renderer.removeClass(document.getElementById(self.inputIdMappings[inputEvent.field]), 'sq-input--error');
              break;
          }
        },

        /*
         * callback function: paymentFormLoaded
         * Triggered when: SqPaymentForm is fully loaded
         */
        paymentFormLoaded: function () {
          if (TRACE) {
            self.log('payment form loaded');
          }
          self.cardFormLoaded = true;
        }
      }
    });

    this.paymentForm.build();
  }

  onSubmitError(errorMessage) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Payment error',
        copy: 'Please check your details or try again later.<br><br><span class="dialogError">Error: ' + errorMessage + '</span>',
        type: 'Payment',
        buttonMessage: 'Ok',
        acceptColor: 'primary',
        showCancel: false
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private loadSquarePayments() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('squarePayments').then(data => {
      // this.log('dynamicScriptLoader', data);
      // this.log('SqPaymentForm', SqPaymentForm);
      setTimeout(() => this.buildForm(), 200);
    }).catch(error => this.log(error));
  }

  /**
   * function: requestCardNonce
   *
   * requestCardNonce is triggered when the "Pay with credit card" button is
   * clicked
   *
   * Modifying this function is not required, but can be customized if you
   * wish to take additional action when the form button is clicked.
   */
  public requestCardNonce(event) {
    if (TRACE) {
      this.log('requesting card nonce');
    }

    // Request a nonce from the SqPaymentForm object
    this.submitRequest = true;
    this.paymentForm.requestCardNonce();
  }

  submitEFT(event) {
    const order: Order = {
      items: [],
      payments: [{
        type: PaymentMethodType.EFT,
        save: true,
      }],
      customer: {},
      discountCode: { code: this._discountCode },
    };
    // order.trialCode = TRIAL_CODE;   // ask for trial period iusing trial code.

    this.submitOrder.emit(order);
    this.submitRequest = true;
  }

  submitFree() {
    const order: Order = {
      items: [],
      payments: [],
      customer: {},
      discountCode: { code: this._discountCode },
    };
    // order.trialCode = TRIAL_CODE;   // ask for trial period using trial code.

    this.submitOrder.emit(order);
    this.submitRequest = true;
  }

  public onDiscountChange(discountCode: string) {
    if (TRACE) {
      this.log('Discount code', discountCode);
    }
    this._discountCode = discountCode;
    this.discountCode.emit(this._discountCode);
    this.getPriceWithoutTrial();
  }

  getPriceWithoutTrial() {
    const product = this.product;

    if (!product) {
      this.log("No product, so cannot get non-trial price");
      return;
    }

    if (this.subscriberType === CustomerType.employer) {
      if (this.numberOfEmployees) {
        product.attributes[0].value = this.numberOfEmployees;
      }
    }
    this.log('this.billingCycle', this.billingCycle);
    this.log('this.subscriberType', this.subscriberType);
    this.log('this.product', product);
    const order: Order = {
      items: [{ product: product }],
      discountCode: { code: this._discountCode },
    };

    this.ss.checkOrder(order).subscribe(
      result => {
        this.log('Got check-order response', result);
        this.originalPrice = result.price;
      },
      err => {
        console.error('Problem checking  order', err);
        this.notificationService.error('Problem checking order', err.error.message);
      }
    );
  }

}
