import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { InvestmentsComponent } from './components/investments.component';
import { InvestmentsRoutingModule } from './investments-routing.module';
import { InvestmentsService } from './investments.service';
import { InvestmentsHeroComponent } from './components/investments-hero/investments-hero.component';
import { InvestmentsProjectionsComponent } from './components/investments-projections/investments-projections.component';
import { SixParkComponent } from './components/six-park/six-park.component';
@NgModule({
  imports: [
    CommonModule, RollitCommonModule, InvestmentsRoutingModule
  ],
  declarations: [
    InvestmentsComponent,
    InvestmentsHeroComponent,
    InvestmentsProjectionsComponent,
    SixParkComponent
  ],
  providers: [
    InvestmentsService
  ]
})
export class InvestmentsModule { }
