import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SubscriptionHomeComponent } from './subscription-home/subscription-home.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { QuestionService } from './question.service';
import { RollitCommonModule } from '@rollit/shared/common';
import { UserSubscriptionFormQuestionComponent } from './user-subscription-form/user-subscription-form-question/user-subscription-form-question.component';
import { IndividualPlanInformationComponent } from './payment-form/individual-plan-information/individual-plan-information.component';
import { CompanyPlanInformationComponent } from './payment-form/company-plan-information/company-plan-information.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { NotificationService } from '@rollit/shared/data';
import { CostAccordionComponent } from './cost-accordion/cost-accordion.component';
import { SubscriptionCheckComponent } from './subscription-check/subscription-check.component';
import { FreeDetailsComponent } from './free-details/free-details.component';
import { UpgradeFormComponent } from './upgrade-form/upgrade-form.component';

@NgModule({
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    RollitCommonModule,
    // DataServicesModule, // do not realod here. Already defined in the app.
  ],
  declarations: [
    SubscriptionHomeComponent,
    PaymentFormComponent,
    UpgradeFormComponent,
    UserSubscriptionFormQuestionComponent,
    IndividualPlanInformationComponent,
    CompanyPlanInformationComponent,
    PaymentDetailsComponent,
    FreeDetailsComponent,
    CostAccordionComponent,
    SubscriptionCheckComponent,
  ],
  exports: [
    UserSubscriptionFormQuestionComponent,
  ],
  providers: [
    QuestionService,
    NotificationService
  ]
})
export class SubscriptionModule { }
