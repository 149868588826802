import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIManagerService } from '@rollit/shared/services';
import { FundService, InvestmentOption } from '@rollit/shared/data';
import { MeService } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { PlatformService } from '@rollit/shared/data';
import { Features } from '@rollit/shared/data';
import { Router } from '@angular/router';

declare var Intercom; // defined by included Intercom javascript.

@Component({
  selector: 'app-join-dialog',
  templateUrl: './join-dialog.component.html',
  styleUrls: ['./join-dialog.component.scss']
})
export class JoinDialogComponent implements OnDestroy {
  fundName: string;
  fundID: string;
  joinUrl: string;
  isDesktop: boolean;
  fund: InvestmentOption;
  hasSuperFeature: boolean = null;
  showUpgrade = false;
  private isDestroyed = new Subject();

  /**
   * Constructor
   */
  constructor(
    public dialogRef: MatDialogRef<JoinDialogComponent>,
    private uiService: UIManagerService,
    private userService: MeService,
    private fundService: FundService,
    private platformService: PlatformService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any, @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.showUpgrade = this.environment.embedded.inAppPurchases || !(this.platformService.embedded && this.platformService.isIos);

    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop =>
      this.isDesktop = isDesktop
    );
    this.fundName = data.fundName;
    this.fundID = data.fundID;
    this.joinUrl = data.joinUrl;
    this.fund = data.fund;

    // show or hide doc creation.
    this.userService.hasSomeFeature$([Features.SUPER_CHOICE]).pipe(takeUntil(this.isDestroyed)).subscribe(
      hasFeature => {
        console.log('has super feature ', hasFeature);
        this.hasSuperFeature = hasFeature;
      }
    );
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onCreateClick(event) {
    this.fundService.sendInvestmentDocs(this.fund).subscribe();
    Intercom('trackEvent', 'web-app-explore-join-dialog-click-create-docs');
    window.open(this.joinUrl);
    return null;
  }

  onLaterClick() {
    Intercom('trackEvent', 'web-app-explore-join-dialog-click-through');
    window.open(this.joinUrl);
  }

  onUpgradeClick(event: Event) {
    if (this.platformService.useInAppPurchases) {
      event.preventDefault();
      event.stopPropagation();
      this.platformService.emit("upgrade", "doit");
    } else {
      this.router.navigate(['/subscription/upgrade']);
    }
  }

}
