import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';
import { AccountDetailComponent } from './components/account-detail/account-detail.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { IncomingAccountsComponent } from './components/incoming-accounts/incoming-accounts.component';
import { ManageAccountsComponent } from './components/manage-accounts/manage-accounts.component';
import { RemoveAccountsComponent } from './components/remove-accounts/remove-accounts.component';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { TransactionsFilterComponent } from './components/transactions-filter/transactions-filter.component';
import { TransactionsComponent } from './components/transactions/transactions.component';


const accountsRoutes: Routes = [
  {
    path: 'accounts',
    component: AccountsComponent,
    data: {
      title: 'Accounts',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['accounts', 'transactions'] },
      },
    }
  },

  {
    path: 'accounts/manage-accounts',
    component: ManageAccountsComponent,
    data: {
      title: 'Manage accounts',
    },
  },
  {
    path: 'accounts/incoming-accounts',
    component: IncomingAccountsComponent,
    data: {
      title: 'Incoming accounts',
    },
  },
  {
    path: 'accounts/remove-accounts',
    component: RemoveAccountsComponent,
    data: {
      title: 'Stop sharing',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['accounts', 'transactions'] },
      },
    },
  },
  {
    path: 'accounts/:id',
    component: AccountDetailComponent,
    data: {
      title: 'Account',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['accounts', 'transactions'] },
      },
    }
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    data: {
      title: 'Account transactions',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['accounts', 'transactions'] },
      },
    },
  },
  {
    path: 'transactions/:id',
    component: TransactionsComponent,
    data: {
      title: 'Account transactions',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['accounts', 'transactions'] },
      },
    },
  },
  {
    path: 'transactions/transaction-detail/:id',
    component: TransactionDetailComponent,
    data: {
      title: 'transaction',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'multi', subNav: false, primaryItems: ['accounts', 'transactions'] },
      },
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(accountsRoutes)],
  exports: [RouterModule],
})
export class AccountsRoutingModule { }
