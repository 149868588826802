import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class AppAuthGuardService extends KeycloakAuthGuard {

  /**
   * Indicates if the user is authenticated or not.
   */
  protected authenticated: boolean;
  /**
   * Roles of the logged user. It contains the clientId and realm user roles.
   */
  protected roles: string[];

  constructor(protected router: Router, protected keycloak: KeycloakService) {
    super(router, keycloak);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        this.authenticated = await this.keycloak.isLoggedIn();
        this.roles         = this.keycloak.getUserRoles(true);
        const result       = await this.isAccessAllowed(route, state);
        resolve(result);
      } catch (error) {
        reject('An error happened during access validation. Details:' + error);
      }
    });
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      // this.authenticated = await this.keycloak.isLoggedIn();
      if (!this.authenticated) {
        this.keycloak.login();                   // Not authenticated, so prompt for login.
        // return resolve(false);
      }

      const requiredRoles = route.data.roles;
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        // this.roles         = this.keycloak.getUserRoles(true);
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        let granted = false;
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
        resolve(granted);
      }
    });
  }
}
