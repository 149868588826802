<div class="nudge-card section-background-color__{{nudge.section}} ">



  <div class="nudge-card__header-holder">
    <div class="nudge-card__header-holder-left">
      <!-- <i class="nudge-card__movement-icon material-icons">add_circle</i> -->
      <span class="heading2 nudge-card__header" [ngClass]="headingCurrency ? 'heading1':'heading2'">
        <lib-handlebars [template]="nudge.title" [context]="nudge"></lib-handlebars>
      </span>
    </div>
    <i class="nudge-card__section-icon material-icons">{{this.icon}}</i>
  </div>

  <span class="nudge-card__value">
    <span class="paragraph2">
      <lib-handlebars [template]="nudge.description" [context]="nudge"></lib-handlebars>
    </span>
  </span>

  <div class="nudge-card__bottom">
    <button mat-button class="rollit-button-new mat-button nudge-card__button" (click)="onButtonPressed()">
      <span class="title">{{ nudge.actionText || 'Optimise' }}</span>
    </button>
    <div class="paragraph2 nudge-card__bottom-section">

      <span class="nudge-card__close" (click)="onClosed()">Later</span>
      <!-- <lib-handlebars [template]="title" [context]="nudge"></lib-handlebars> -->
    </div>
  </div>
</div>
