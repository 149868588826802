<div class="main-content-section">
  <div id="section-content" class="section-content manage">
    <div class="form-panel" [ngClass]="{'desktop':isDesktop}">

      <!-- Navigation -->
      <div class="top-navigation">
        <div class="skip paragraph1" (click)="onReturnClick()">
          <em class="material-icons">close</em>
        </div>
      </div>

      <!-- Progress bar -->
      <div class="progress">
        <span class="paragraph3">{{accountIndex + 1}} of {{pendingAccounts.length}}</span>
        <div class="progress-line">
          <div class="percent" *ngIf="pendingAccounts"
            [ngStyle]="{'width': (100 / pendingAccounts.length) * (accountIndex+1) + '%' }"></div>
        </div>
      </div>



      <!-- Savings -->
      <div *ngIf="currentAccount?.type === 'savings'" class="section anim">
        <div class="content trusted-secure">
          <span class="title heading2">Match your new<br> linked account</span>
          <span class="sub-title heading2">{{currentAccount.nickname}}
            {{currentAccount.currentBalance | currency}}</span>
          <div class="trusted">
            <mat-radio-group class="radio-group" [formControl]="radioControl">
              <mat-radio-button value="new">Add to financial position</mat-radio-button>
              <mat-radio-button value="ignore">Do not include in financial position</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          next
        </button>
      </div>

      <!-- Credit card or loan -->
      <div *ngIf="currentAccount?.type === 'creditcard' || currentAccount?.type === 'loan'" class="section anim">
        <div class="content trusted-secure">
          <span class="title heading2">Match your new<br> linked account</span>
          <span class="sub-title heading2">{{currentAccount.nickname}}
            {{currentAccount.currentBalance | currency}}</span>
          <div class="trusted">
            <mat-radio-group class="radio-group" [formControl]="radioControl">
              <mat-radio-button class="radio-button" *ngIf="matchedAccounts?.length > 0" value="match">
                <mat-form-field class="input-select" appearance="outline">
                  <!-- <mat-label>Select an existing account</mat-label> -->

                  <mat-select [formControl]="accountsControl" placeholder="Select an existing account">
                    <mat-option *ngFor="let ac of matchedAccounts" [value]="ac.id">
                      {{ac.nickname}} {{ac.currentBalance | currency}}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </mat-radio-button>
              <mat-radio-button value="new">Create a new account</mat-radio-button>
              <mat-radio-button value="ignore">Do not match this account</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          next
        </button>
      </div>

      <!-- Mortgage -->
      <div *ngIf="currentAccount?.type === 'mortgage'" class="section anim">
        <div class="content trusted-secure">
          <span class="title heading2">Match your new<br> linked account</span>
          <span class="sub-title heading2">{{currentAccount.nickname}}
            {{currentAccount.currentBalance | currency}}</span>
          <div class="trusted">
            <mat-radio-group class="radio-group" [formControl]="radioControl">
              <mat-radio-button class="radio-button" *ngIf="matchedAccounts?.length > 0" value="match">
                <mat-form-field class="input-select" appearance="outline">
                  <!-- <mat-label>Select an existing account</mat-label> -->
                  <mat-select [formControl]="accountsControl" placeholder="Select an existing account">
                    <mat-option *ngFor="let ac of matchedAccounts" [value]="ac.id">
                      {{ac.nickname}} {{ac.currentBalance | currency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-radio-button>
              <mat-radio-button value="new">Create a new account</mat-radio-button>
              <mat-radio-button value="ignore">Do not match this account</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          Got it
        </button>
      </div>

      <!-- Superannuation -->
      <div *ngIf="currentAccount?.type === 'superannuation'" class="section anim">
        <div class="content trusted-secure">
          <span class="title heading2">Match your<br> superannuation account</span>
          <span class="sub-title heading2">{{currentAccount.nickname}}
            {{currentAccount.currentBalance | currency}}</span>
          <div class="trusted">
            <mat-radio-group class="radio-group" [formControl]="radioControl">
              <mat-radio-button class="radio-button" *ngIf="matchedAccounts?.length > 0" value="match">
                <mat-form-field class="input-select" appearance="outline">
                  <!-- <mat-label>Select an existing account</mat-label> -->
                  <mat-select [formControl]="accountsControl" placeholder="Select an existing account">
                    <mat-option *ngFor="let ac of matchedAccounts" [value]="ac.id">
                      {{ac.nickname}} {{ac.currentBalance | currency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-radio-button>
              <mat-radio-button value="new">Create a new account</mat-radio-button>
              <mat-radio-button value="ignore">Do not match this account</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          Got it
        </button>
      </div>

      <!-- investment -->
      <div *ngIf="currentAccount?.type === 'investment'" class="section anim">
        <div class="content trusted-secure">
          <span class="title heading2">Match your<br> investment account</span>
          <span class="sub-title heading2">{{currentAccount.nickname}}
            {{currentAccount.currentBalance | currency}}</span>
          <div class="trusted">
            <mat-radio-group class="radio-group" [formControl]="radioControl">
              <mat-radio-button class="radio-button" *ngIf="matchedAccounts?.length > 0" value="match">
                <mat-form-field class="input-select" appearance="outline">
                  <!-- <mat-label>Select an existing account</mat-label> -->
                  <mat-select [formControl]="accountsControl" placeholder="Select an existing account">
                    <mat-option *ngFor="let ac of matchedAccounts" [value]="ac.id">
                      {{ac.nickname}} {{ac.currentBalance | currency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-radio-button>
              <mat-radio-button value="new">Create a new account</mat-radio-button>
              <mat-radio-button value="ignore">Do not match this account</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          Got it
        </button>
      </div>

    </div>
  </div>
</div>
