<ng-container>

  <div class="group-panel">

    <div class="navigation" *ngIf="isDesktop && propertyCount > 0">
      <div class="item prev paragraph2" [ngClass]="{'disabled': propertyListId === 0 }"
        (click)="propertyListId !== 0 ? scrollPrev() : false">
        <i class="material-icons">navigate_before</i> Previous
      </div>

      <div class="item next paragraph2" [ngClass]="{'disabled': propertyListId  === propertyCount }"
        (click)="propertyListId !== propertyCount ? scrollNext() : false">
        Next <i class="material-icons">navigate_next</i>
      </div>
    </div>

    <app-section-hero [title]="acc.nickname" [description]="" imageCaption="" [showDelete]="true"
      [imagePath]="imagePath" [expanded]="isDesktop" (expandClicked)="expandClicked()"
      (uploadComplete)="uploadComplete($event)">
      <div class="titleGroup">
        <app-title-group [details]="titleGroupDetails" [displayAsColumn]="isDesktop"></app-title-group>
      </div>
      <div class="heroContent">
        <app-investments-projections *ngIf="projectionChartData.length > 0 && projectionChartCategories.length > 0"
          [inView]="(inView && isDesktop) || expanded" [acc]="acc" [projectionChartData]="projectionChartData"
          [projectionChartCategories]="projectionChartCategories">
        </app-investments-projections>
        <div class="action-holder">
          <button mat-stroked-button
            (click)="acc.accountSubtype === 'business' ? startUpdateBusiness(acc.id) : startUpdateInvestment(acc.id)">
            <span class="paragraph2"> Edit </span>
          </button>
          <i *ngIf="acc.accountSubtype === 'business'" class="delete material-icons" (click)="onRemoveAccount()">
            delete
          </i>
        </div>
      </div>
    </app-section-hero>
  </div>

  <button *ngIf="!isDesktop && acc.accountSubtype !== 'business' && investmentAccountCount > 0" mat-stroked-button class="new-account" (click)="onAddInvestment()">
    <span class="paragraph1">Add another investment</span>
  </button>
  <button *ngIf="!isDesktop && acc.accountSubtype !== 'business' && me?.extra?.sixparkStatus === 'connected'" mat-stroked-button class="new-account" color="primary" (click)="openSixPark()">
    <span class="paragraph1">Access your Six Park account</span>
  </button>
  <button *ngIf="!isDesktop && acc.accountSubtype !== 'business' && !me?.extra?.sixparkStatus" mat-stroked-button class="new-account" color="primary" [routerLink]="['/six-park']">
    <span class="paragraph1">Create your investment profile</span>
  </button>

</ng-container>
