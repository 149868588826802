<ng-container *ngIf="reportTextComplete && reportComplete">

  <div class="report-header">
    <i class="material-icons " [ngClass]="{'report-icon':!isDesktop}">medical_services</i>
    <div class="heading">Report snapshot</div>
    <i *ngIf="!isDesktop" class="material-icons arrow-icon">arrow_downward</i>
  </div>

  <lib-report-financial-health-score [useQuery]="false" [reportText]="reportText" [scoreIndex]="report.score_index"
    [profileFinancialGoal]="report.profile_financial_goal" [scoreIndexCategory]="report.score_index_category">
  </lib-report-financial-health-score>
  <lib-report-heading [bg]="1" [title]="'My home situation'"></lib-report-heading>
  <lib-report-home-situation [useQuery]="false" [reportText]="reportText"
    [assetsHomeOwnership]="report.assets_home_ownership"
    [assetsHomeOwnershipAgePercentage]="report.assets_home_ownership_age_percentage"
    [assetsHomeOwnershipStatePercentage]="report.assets_home_ownership_state_percentage"
    [profileAgeRange]="report.profile_age_range" [profileAddressState]="report.profile_address_state">
  </lib-report-home-situation>
  <lib-report-heading [bg]="3" [title]="'My cashflow management'"></lib-report-heading>
  <lib-report-cashflow-management [useQuery]="false" [reportText]="reportText" [expensesStress]="report.expenses_stress"
    [expensesStressAgePercentage]="report.expenses_stress_age_percentage"
    [assetsSavingsSpan]="report.assets_savings_span"
    [assetsSavingsSpanAgePercentage]="report.assets_savings_span_age_percentage"
    [assetsSavingsAgeAvgBalance]="report.assets_savings_age_avg_balance">
  </lib-report-cashflow-management>
  <lib-report-heading [bg]="2" [title]="'My long-term financial security'"></lib-report-heading>
  <lib-report-financial-security [useQuery]="false" [reportText]="reportText"
    [assetsSuperConcern]="report.assets_super_concern" [profileAgeRange]="report.profile_age_range"
    [profileGender]="report.profile_gender" [assetsSuperAgeAvgBalance]="report.assets_super_age_avg_balance"
    [assetsInvestmentPortfolio]="report.assets_investment_portfolio"
    [assetsInvestmentBusinessAgePercentage]="report.assets_investment_business_age_percentage"
    [assetsInvestmentPropertyAgePercentage]="report.assets_investment_property_age_percentage"
    [assetsInvestmentSharesAgePercentage]="report.assets_investment_shares_age_percentage">
  </lib-report-financial-security>
  <div class="footer paragraph3">
    Roll-it Super Pty Ltd. provides general information only. You should assess whether the information is
    appropriate for you having regard to your objectives, financial situation and needs and consider obtaining
    independent professional advice before making an investment decision. If information relates to a specific
    financial product you should obtain a copy of the PDS for that product and consider that statement before
    making a decision whether to acquire the product. Roll-it Super Pty Ltd. ACN 611 756 140. AFSL Authorised
    Representative No. 1273703 of MSC Advisory Pty Ltd. ACN 607 459 441. AFSL 480649 (MSCA).
  </div>
</ng-container>
