import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardInnerTopMenuComponent } from '@rollit/shared/common';
import { CreditCardsLoansComponent } from './components/credit-cards-loans.component';

const creditCardsLoansRoutes: Routes = [
  {
    path: 'credit',
    component: CreditCardsLoansComponent,
    data: {
      title: 'Loans and cards',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
  {
    path: 'credit/:id',
    component: CreditCardsLoansComponent,
    data: {
      title: 'Loans and cards',
      topMenuItem: {
        component: DashboardInnerTopMenuComponent,
        data: { type: 'single', subNav: false },
      },
    },
    children: [
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(creditCardsLoansRoutes)],
  exports: [RouterModule],
})
export class CreditCardsLoansRoutingModule { }
