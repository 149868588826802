import { PaymentMethod, PaymentDetails } from './payment';
import { User } from './user';
import { Employer } from './employer';



export interface Signup {
    id?: string;     // an id for this sign-up session
    type?: CustomerType;
    productType?: string;
    captcha?: string;     // to ensure signup submissions cannot be automated
    user: User;       // user details, firstName, lastName
    employer?: Employer;   // business details for an Employer signup.
    product: Product;
    trialCode?: TrialCode;
    discountCode: DiscountCode;
    data?: any;        // extra data
    complete?: boolean;        // extra data

}

export interface Customer {
    type?: CustomerType;
    user?: User;        // for individual customer type
    employer?: Employer;    // for employer customer type
    subscriptions?: any[];       // list of subscriptions for this customer
}

/**
 * Selection of member plan.
 */
export interface Order {
    id?: number;
    customer?: Customer;
    items?: OrderItem[];
    status?: string;
    discountCode?: DiscountCode;
    trialCode?: TrialCode;
    price?: Price;
    payments?: PaymentDetails[];        // to pay for the order.
    timeCreated?: string;                  // date string
    timeUpdated?: string;                  // date string
}

export interface OrderItem {
    id?: number;
    product?: Product;
    discountCode?: DiscountCode;
}

export enum OrderStatus {
    Provisional  = 'Provisional',
    Quoted       = 'Quoted',
    Accepted     = 'Accepted',
    Confirmed    = 'Confirmed',
    Paid         = 'Paid',
    Cancelled    = 'Cancelled',
}


export interface Price {
    net?: number;   // amount before discount and tax
    discount?: number;   // discount before tax
    tax?: number;   // the amount of tax
    total?: number;   // total price
}

export interface DiscountCode {
    id?: number;
    code?: string;
    description?: string;
    expiry?: string;        // date
    message?: string;
}

export interface TrialCode {
    id?: number;
    code?: string;
    description?: string;
    expiry?: string;        // date
    message?: string;
}

/**
 * Rollit product on offer.  Subscription plans.
 */
export interface Product {
    id?: number;
    name?: string;
    description?: string;
    properties?: any;
    attributes?: ProductAttribute[];
}

export interface ProductAttribute {
    id?: number;
    name?: string;
    description?: string;
    type?: string;
    value?: any;
}

/**
 * A feature of the Rollit service.
 */
export interface Feature {
    id: number;
    name: string;
    description: string;
}

/**
 * Application features that a user may have through their subscription.
 */
export const Features = {
    EMPLOYEE_BENEFITS: 'employee.benefits', // Enterprise
    PROPERTY_BASIC: 'property.basic',
    PROPERTY_PREMIUM: 'property.premium', // Premium
    LENDING_LOAN_SCORE: 'lending.loan_score',
    TRACK_MT: 'track.moneytree', // Premium
    REWARDS_ALL: 'rewards.complete', // Premium
    SUPER_CHOICE: 'super.choice', // Premium
};
/**
 * A customer subscription.
 */
export interface Subscription {
    id?: number;
    customer: Customer;
    product?: Product; // the chosen subscription plan
    type?: string;  // Trial, Foundation, Standard, AppStore, PlayStore
    status?: SubscriptionStatus;  // status of the subscription
    statusMessage?: string;
    paymentMethod?: PaymentMethod;
    timeStarted?: string;  // timestamp
    timeDue?: string;  // timestamp
    timeConcluded?: string;  // timestamp
    lastBillDate?: string;
    lastBillAmount?: number;
    numEmployees?: number;
    features?: Feature[];
}

export enum BillingCycle {
    monthly = 'month',
    annually = 'year'
}

export enum CustomerType {
    employer = 'Employer',
    individual = 'Individual'
}

export enum SubscriptionStatus {
    Trial = 'Trial',
    Pending = 'Pending',
    Active = 'Active',
    Lapsed = 'Lapsed',			/* no subscription */
    Cancelled = 'Cancelled'
}
