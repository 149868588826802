<div class="main-content-section ">
  <div id="section-content" class="section-content">
    <app-top-menu></app-top-menu>
    <div class="top-navigation">
    <div  *ngIf="gotTransactions && accountId !== null" class="back-button-content paragraph2" [routerLink]="['/accounts']" ><i
    class="material-icons">arrow_back_ios_new</i> Back </div>
</div>
    <div class="rollit-grid">

      <div class="rollit-grid-2">

        <div class="form-panel">

          <div>
            <div class="group-panel">

            <div class="heading-top heading2" *ngIf="gotTransactions && accountId !== null && connectedTransactionsSorted.length > 0">Transactions for account:<br><span class="paragraph1">{{connectedTransactionsSorted[0].transactions[0].nickname}}</span></div>
            <div class="heading-top heading2" *ngIf="gotTransactions && (accountId === null || connectedTransactionsSorted.length === 0)">Transactions</div>
              <!-- Transaction list -->

              <div
                *ngIf="connectedTransactions?.length > 0 && gotTransactions === true && !selectedTransaction && !transactionFilter"
                class="transactions">

                <!-- <button class="filter" mat-stroked-button (click)="onFilter()">
                  <i class="material-icons">search</i>
                  <span class="paragraph2"> Filter </span>
                </button> -->

                

                <div #infiniteScroll infinite-scroll class="item-holder infinite-scroll"
                  [ngClass]="{'desktop': mediaSizeIsDesktop }" *ngIf="connectedTransactions.length > 0"
                  [class.desktop]="mediaSizeIsDesktop" [scrollWindow]="false" (scrolled)="onScrollDown()"
                  [fromRoot]="mediaSizeIsDesktop ? null : true"
                  [infiniteScrollContainer]="mediaSizeIsDesktop ? null : '.section-content'"
                  [infiniteScrollThrottle]="throttle" [infiniteScrollDistance]="1">
                  <div *ngFor="let transaction of connectedTransactionsSorted"
                    title="Category: {{transaction?.topCategory | titlecase}}">

                    <span class="heading paragraph1 ">{{transaction.date | date:'EEEE d MMMM y'}}</span>

                    <div class="item" *ngFor="let obj of transaction.transactions"
                      (click)="onTransactionDetail(obj.accountId + '-'+obj.id)">
                      <!-- <i
                        class="material-icons category-icon {{obj?.topCategory}}  budget-color__{{obj?.topCategory}}"></i> -->
                      <div class="detail">
                        <div class="detail-holder">
                          <span class="paragraph2 nickname">{{obj.category}}
                          </span>
                          <span
                            class="description paragraph2 section-color__secondary">{{obj?.description | lowercase }}</span><br>
                          <span *ngIf="obj.nickname" class="paragraph2 section-color__secondary">{{obj.nickname}}
                          </span>

                        </div>
                        <span class="paragraph2  budget-color__{{obj?.topCategory}}"
                          [ngClass]="{'section-color__negative': obj?.amount < 0 && obj?.topCategory === 'primary'}">{{obj?.amount | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
                      </div>

                    </div>



                  </div>

                  <div *ngIf="allItemsLoaded" class="done-loading paragraph2">No more transactions to load</div>
                </div>

              </div>

              <div *ngIf="connectedTransactions?.length === 0 && gotTransactions === true">You don't have any transactions yet.</div>

              <!-- Transaction filter -->

              <div *ngIf="transactionFilter">Filter</div>



            </div>
          </div>
        </div>
      </div>

      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button (click)="manageAccounts()" mat-flat-button class="new-account" color="primary">
            Add account
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
