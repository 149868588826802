import { InjectionToken, NgModule } from '@angular/core';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
  ],
  exports: [
  ]
})
export class RollitSharedModule { }

export const APP_ENVIRONMENT = new InjectionToken<any>('app.environment');
