import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';

import { DataServicesModule } from '@rollit/shared/data';

import { MaxCharactersDirective } from './utils/max-characters.directive';
import { OnlyNumberDirective } from './utils/only-number.directive';
import { OnlyNumberFormControlDirective } from './utils/only-number-form-control-directive';

//import { LoanChoiceDialogComponent } from './loan-choice-dialog/loan-choice-dialog.component';
import { MobilePromptDialogComponent } from './mobile-prompt-dialog/mobile-prompt-dialog.component';
import { InvestmentChangeComponent } from './investment-change/investment-change.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CustomIntercomFabComponent } from './custom-intercom-fab/custom-intercom-fab.component';
// import { SuperFundInformationComponent } from './super-fund-information/super-fund-information.component';
import { FormFieldItemComponent } from './form-field-item/form-field-item.component';
// import { ChartOverviewComponent } from './chart-overview/chart-overview.component';
import { RollitExpansionComponent } from './accordion/rollit-expansion/rollit-expansion.component';
// import { HeadingPrimaryComponent } from './heading-primary/heading-primary.component';
import { BackButtonComponent } from './back-button/back-button.component';
//import { JoinDialogComponent } from './join-dialog/join-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { ChoiceFundComponent } from './choice-fund/choice-fund.component';
// import { FundSummaryYourFundComponent } from './fund-summary-your-fund/fund-summary-your-fund.component';
// import { FeatureFundSummaryComponent } from './feature-fund-summary/feature-fund-summary.component';
// import { FavoriteFundButtonComponent } from './favorite-fund-button/favorite-fund-button.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
//import { ConnectButtonComponent } from './connect-button-verbose/connect-button-verbose.component';
import { DrawerNavigationComponent } from './drawer-navigation/drawer-navigation.component';
import { FilterButtonVerboseComponent } from './filter-button-verbose/filter-button-verbose.component';
import { FooterNavigationComponent } from './footer-navigation/footer-navigation.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { HorizontalScrollSnapComponent } from './horizontal-scroll-snap/horizontal-scroll-snap.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { DashboardInnerTopMenuComponent } from './top-menu/dashboard-inner-top-menu/dashboard-inner-top-menu.component';
import { InnerTopMenuDirective } from './top-menu/inner-top-menu.directive';
import { RollitUtilsModule } from '@rollit/shared/utils';
import { TrackService } from './track.service';
import { AssetValueComponent } from './asset-value/asset-value.component';
import { TitleGroupComponent } from './title-group/title-group.component';
import { BarChartCategoryComponent } from './bar-chart-category/bar-chart-category.component';
import { BarChartTimeComponent } from './bar-chart-time/bar-chart-time.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SectionHeroComponent } from './section-hero/section-hero.component';
import { BudgetComponent } from './accordion/budget/budget.component';
import { TableComponent } from './table/table.component';
import { SuperannuationFundInfoComponent } from './superannuation-fund-info/superannuation-fund-info.component';
import { GoalComponent } from './goal/goal.component';
import { SavingsGoalComponent } from './goal/savings-goal/savings-goal.component';
import { NewSavingsGoalComponent } from './goal/new-savings-goal/new-savings-goal.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProgressBarComponent } from './goal/progress-bar/progress-bar.component';
import { SavingsGoalSummaryComponent } from './goal/savings-goal-summary/savings-goal-summary.component';
import { SectionSummaryCardComponent } from './section-summary-card/section-summary-card.component';
import { DashboardInnerSubMenuComponent } from './top-menu/dashboard-inner-sub-menu/dashboard-inner-sub-menu.component';
import { PanelNavigationComponent } from './panel-navigation/panel-navigation.component';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { BudgetElementComponent } from './accordion/budget/budget-element/budget-element.component';
import { GoalService } from './goal/goal.service';
import { HandlebarsComponent } from './handlebars/handlebars.component';
import { NudgeExpandableComponent } from './nudge/components/nudge-expandable/nudge-expandable.component';
import { NudgeSummaryComponent } from './nudge/components/nudge-summary/nudge-summary.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatTabsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatCarouselModule.forRoot(),
    DataServicesModule,
    RollitUtilsModule,
    HighchartsChartModule,
  ],
  exports: [
    RouterModule,
    MatToolbarModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatGridListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCarouselModule,
    HandlebarsComponent,
    InvestmentChangeComponent,
    FileUploadComponent,
    CustomIntercomFabComponent,
    //SuperFundInformationComponent,
    FormFieldItemComponent,
    //ChartOverviewComponent,
    RollitExpansionComponent,
    //HeadingPrimaryComponent,
    BackButtonComponent,
    //FundSummaryYourFundComponent,
    //FeatureFundSummaryComponent,
    //FavoriteFundButtonComponent,
    SideNavigationComponent,
    //ConnectButtonComponent,
    DrawerNavigationComponent,
    //FilterButtonVerboseComponent,
    //FooterNavigationComponent,
    LoadingScreenComponent,
    //MainNavigationComponent,
    NudgeExpandableComponent,
    NudgeSummaryComponent,
    HorizontalScrollSnapComponent,
    TopMenuComponent,

    TitleGroupComponent,
    BarChartCategoryComponent,
    BarChartTimeComponent,
    BreadcrumbComponent,
    SectionTitleComponent,
    SectionHeroComponent,
    BudgetComponent,
    BudgetElementComponent,
    TableComponent,
    SuperannuationFundInfoComponent,
    GoalComponent,
    SavingsGoalComponent,
    NewSavingsGoalComponent,

    OnlyNumberDirective,
    OnlyNumberFormControlDirective,
    MaxCharactersDirective,

    // StriphtmlPipe,
    AssetValueComponent,
    SavingsGoalSummaryComponent,
    SectionSummaryCardComponent,
  ],
  declarations: [
    OnlyNumberDirective,
    OnlyNumberFormControlDirective,
    MaxCharactersDirective,
    CustomIntercomFabComponent,
    //SuperFundInformationComponent,
    FormFieldItemComponent,
    PageNotFoundComponent,
    InvestmentChangeComponent,
    //ChoiceFundComponent,
    //JoinDialogComponent,
    FileUploadComponent,
    //ChartOverviewComponent,
    RollitExpansionComponent,
    //HeadingPrimaryComponent,
    BackButtonComponent,
    ConfirmationDialogComponent,
    //LoanChoiceDialogComponent,
    MobilePromptDialogComponent,
    //FundSummaryYourFundComponent,
    //FeatureFundSummaryComponent,
    //FavoriteFundButtonComponent,
    SideNavigationComponent,
    //ConnectButtonComponent,
    DrawerNavigationComponent,
    FilterButtonVerboseComponent,
    FooterNavigationComponent,
    LoadingScreenComponent,
    MainNavigationComponent,
    NudgeExpandableComponent,
    NudgeSummaryComponent,
    HorizontalScrollSnapComponent,
    TopMenuComponent,
    DashboardInnerTopMenuComponent,
    InnerTopMenuDirective,
    AssetValueComponent,
    TitleGroupComponent,
    BarChartCategoryComponent,
    BarChartTimeComponent,
    HandlebarsComponent,

    BreadcrumbComponent,
    SectionTitleComponent,
    SectionHeroComponent,
    BudgetComponent,
    BudgetElementComponent,
    TableComponent,
    SuperannuationFundInfoComponent,
    GoalComponent,
    SavingsGoalComponent,
    NewSavingsGoalComponent,
    ProgressBarComponent,
    SavingsGoalSummaryComponent,
    SectionSummaryCardComponent,
    DashboardInnerSubMenuComponent,
    PanelNavigationComponent,
    // StriphtmlPipe,
  ],
  providers: [
    TrackService,
    GoalService,
  ],
  entryComponents: [
    //JoinDialogComponent,
    ConfirmationDialogComponent,
    //LoanChoiceDialogComponent,
    MobilePromptDialogComponent,
    MobilePromptDialogComponent,
  ],
})
export class RollitCommonModule { }
