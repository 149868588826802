<svg version="1.1" baseProfile="full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" class="donut"
  preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 130">
  <g *ngIf="graphicType === 1">
    <g transform="scale(.7 .7) translate(20, 0)">
      <circle class="donut-ring" cx="50%" cy="50" fill="transparent" r="42" stroke="#d2d3d4" stroke-location="inside"
        stroke-width="12"></circle>
      <circle class="donut-segment" cx="50%" cy="50" fill="transparent" r="42" stroke="#0c71b9" stroke="#0c71b9"
        stroke-dashoffset="-65" attr.stroke-dasharray="calc({{cashflowExpensesPercentFinal}} * 263.76 / 100) 263.76"
        stroke-width="12"></circle>
    </g>
    <foreignObject height="60" width="70" x="10" y="22">
      <xhtml:div class="value">{{cashflowExpensesPercentFinal}}<xhtml:span>%</xhtml:span>
      </xhtml:div>
    </foreignObject>
  </g>
  <g *ngIf="graphicType === 2" transform="scale(.7 .7) translate(20, 15)">
    <path
      d="M 1.2421875,18.71289 A 1.2415961,1.2415961 0 0 0 0,19.945312 v 45.230469 a 1.2415961,1.2415961 0 0 0 1.2421875,1.232422 H 79.878906 a 1.2327275,1.2327275 0 0 0 1.232422,-1.232422 V 19.945312 A 1.2327275,1.2327275 0 0 0 79.878906,18.71289 Z"
      style="fill:#0d71b9;stroke-width:0.886854" />
    <path
      d="M 1.2421875,18.71289 A 1.2415961,1.2415961 0 0 0 0,19.945312 v 9.267578 1.242188 H 1.2421875 A 10.518092,10.518092 0 0 0 11.759766,19.980468 v -1.232422 h -1.304688 l 0.0098,-0.03516 z m 69.4042965,0 0.0098,0.03516 h -1.3125 v 1.232422 a 10.518092,10.518092 0 0 0 10.535156,10.47461 h 1.232422 V 29.177734 19.945312 A 1.2327275,1.2327275 0 0 0 79.878906,18.71289 Z M 0,54.666015 v 1.3125 1.117188 8.115234 a 1.2327275,1.2327275 0 0 0 1.2324219,1.232422 h 9.2324221 l -0.01953,-0.07031 h 1.324219 V 65.140625 A 10.518092,10.518092 0 0 0 1.2324219,54.666015 Z m 79.746094,0 A 10.518092,10.518092 0 0 0 69.34375,65.140625 v 1.232421 h 1.322266 l -0.01953,0.07031 h 9.232422 a 1.2327275,1.2327275 0 0 0 1.23242,-1.232419 v -8.115234 -2.429688 h -1.232422 a 10.518092,10.518092 0 0 0 -0.132812,0 z"
      style="fill:#05a8db;fill-opacity:1;stroke-width:0.886854" />
    <circle style="stroke-width:0.886854" r="13.054496" cy="42.888588" cx="41.079094" style="fill:#fff" />
    <path
      d="m 40.640625,34.126953 a 0.78930036,0.78930036 0 0 0 -0.820313,0.789062 v 0.81836 c -2.454522,0.308026 -3.583984,2.018128 -3.583984,3.730468 0,2.069772 2.122597,3.035776 3.583984,3.5625 v 4.417969 A 7.6358158,7.6358158 0 0 1 37,46.152343 a 0.7981689,0.7981689 0 0 0 -1.109375,0.125 0.78930036,0.78930036 0 0 0 0.125,1.107422 9.2410222,9.2410222 0 0 0 3.804687,1.646484 v 1.173829 a 0.78043182,0.78043182 0 0 0 0.789063,0.78125 0.78043182,0.78043182 0 0 0 0.789063,-0.78125 V 49.11914 c 1.835633,-0.194448 3.990234,-1.289537 3.990234,-3.490234 0,-2.43885 -2.375581,-3.201524 -3.945313,-3.707031 l -0.04492,-0.01367 V 37.29883 a 5.6581307,5.6581307 0 0 1 2.5,0.978515 0.78930036,0.78930036 0 0 0 1.003906,-1.214844 7.0948347,7.0948347 0 0 0 -3.503906,-1.355468 v -0.791016 a 0.78930036,0.78930036 0 0 0 -0.757813,-0.789062 z m -0.820313,3.193359 v 4.003906 c -1.075265,-0.462971 -2.003906,-1.044124 -2.003906,-1.859375 0,-1.160762 0.725179,-1.925496 2.003906,-2.144531 z m 1.578126,6.253906 c 1.421557,0.484074 2.421874,0.970796 2.421874,2.054688 0,1.169377 -1.361923,1.758954 -2.421874,1.923828 z"
      style="fill:#05a8db;fill-opacity:1;stroke-width:0.886854" />
    <path
      d="m 19.955078,0 a 1.2327275,1.2327275 0 0 0 0,2.464843 h 77.421875 v 43.988282 a 1.2327275,1.2327275 0 0 0 1.232422,1.232421 1.2327275,1.2327275 0 0 0 1.232422,-1.222656 V 1.232421 A 1.2327275,1.2327275 0 0 0 98.609375,0 Z m -4.470703,4.425781 a 1.2327275,1.2327275 0 0 0 0.04492,2.464844 h 77.412109 v 43.988281 a 1.2327275,1.2327275 0 0 0 1.234375,1.232422 1.2327275,1.2327275 0 0 0 1.232422,-1.222657 V 5.658203 A 1.2327275,1.2327275 0 0 0 94.175781,4.425781 H 15.529297 a 1.2327275,1.2327275 0 0 0 -0.04492,0 z m -4.425781,4.425781 a 1.2327275,1.2327275 0 0 0 0.04492,2.464844 h 77.404298 v 43.988281 a 1.2327275,1.2327275 0 0 0 1.232422,1.232422 1.2327275,1.2327275 0 0 0 1.232422,-1.22461 V 10.083984 A 1.2327275,1.2327275 0 0 0 89.740234,8.851562 H 11.103516 a 1.2327275,1.2327275 0 0 0 -0.04492,0 z m -4.4257815,4.425781 a 1.232728,1.232728 0 0 0 0.044922,2.464844 H 84.074219 v 43.988281 a 1.2327275,1.2327275 0 0 0 1.232422,1.232422 1.2327275,1.2327275 0 0 0 1.232421,-1.224609 V 14.509765 A 1.2327275,1.2327275 0 0 0 85.306641,13.277343 H 6.6777344 a 1.232728,1.232728 0 0 0 -0.044922,0 z"
      style="fill:#0d71b9;fill-opacity:1;stroke-width:0.886854" />
  </g>

  <foreignObject width="100%" x="0" height="50" y="80">
    <xhtml:div class="title">
      {{cashflowExpensesResult}}
    </xhtml:div>
  </foreignObject>
</svg>
