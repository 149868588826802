<div class="close-row"><i (click)="closeDialog()" class="material-icons close">close</i></div>
<h1 mat-dialog-title>Join Super Fund</h1>
<span *ngIf="hasSuperFeature !== null">
    <div mat-dialog-content *ngIf="hasSuperFeature">
        <p>We're about to transfer you to the {{fundName}} member sign-up page. Remember to check the Product Disclosure
            Statement (PDS) and consider your life insurance needs before you switch super funds. </p>

        <h4>Employer Documents</h4>
        <span >
            <p>Hit the button below if you would like us to email a copy of the paperwork you’ll need to give your employer (so
                they can pay your super to the new fund). If you would prefer to wait, you can create the documents later in the
                ‘Actions’ section of the Rollit platform.</p>
            <div>
                <button mat-raised-button color="primary" class="rollit-button mat-full-width-button" (click)="onCreateClick($event)" cdkFocusInitial><i *ngIf="isDesktop" class="material-icons">email</i> Create Docs</button>
                <button mat-raised-button color="primary" class="rollit-button mat-full-width-button" (click)="onLaterClick()">Later,
                    thanks</button>
            </div>
        </span>

        <h5>General advice disclaimer</h5>
        <p><small>Rollit provides general information only and does not take into account your personal objectives,
                financial situation or needs. You should assess whether the information is appropriate for you having regard to
                your objectives, financial situation and needs and consider obtaining independent professional advice before
                making an investment decision. If information relates to a specific financial product you should obtain a copy of
                the product disclosure statement for that product and consider that statement before make a decision whether to
                acquire the product.</small></p>
    </div>
    <div mat-dialog-content *ngIf="!hasSuperFeature">
        <p>Please upgrade your subscription to be taken to the {{fundName}} member sign-up page and to
            create documentation to notify your employer of your super choice.
            <a *ngIf="showUpgrade" mat-raised-button color="primary" class="rollit-button upgrade-button  mat-full-width-button" (click)="closeDialog(); onUpgradeClick($event)">Upgrade now</a>
        </p>
    </div>
</span>