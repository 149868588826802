<div class="main-content-section">
  <div class="section-content complete-setup">
    <app-top-menu></app-top-menu>
    <div class="holder">
      <img class="logo" src="/assets/images/hey-coach-logo-trim.png" />
      <span class="heading2">Complete your set-up here</span>
      <span class="paragraph1">You’re almost there!</span>
      <span class="paragraph2">Get immediate access to your<br> financial position in just a few taps.</span>
      <button mat-raised-button color="primary" class="rollit-button" routerLink="/survey/setup">Fast-track your
        set-up</button>
    </div>
  
    <div class="image-holder" [ngClass]="{'desktop':isDesktop}">
      <!-- <img src="/assets/images/HC-Set-up-iphone.png" /> -->
    </div>

  </div>
</div>
