import { MailingAddress } from './user';

/**
 * Model interfaces for customer subscription payments.
 */

export enum PaymentMethodType {
    Card = 'Card',
    EFT = 'EFT',
}

export enum PaymentType {
    Card = "Card",
    EFT = "EFT",
    Free = "Free",
}

export interface PaymentMethod {
    id: number;
    type: PaymentMethodType;
    cardId: string;       // ID of card at the payment service.
    cardBrand: string;
    last4: string;
    expMonth: number;
    expYear: number;
    cardholderName: string;
    billingAddress: MailingAddress;
}

export interface PaymentDetails {
    type?: PaymentMethodType;        // Card or EFT
    email?: string;
    billingAddress?: MailingAddress;
    shippingAddress?: MailingAddress;
    cardNonce?: string;
    cardholderName?: string;
    save?: boolean;  // whether to save the payment details
    discountCode?: string;   // a discount code
    amount?: number;   // used when making a payment
}

/**
 * @todo: fields to be determined
 */
export interface PaymentResult {
    id?: string;           // transaction ID
    status?: string;           // status
    message?: string;           // transaction text
    amount?: number;
}