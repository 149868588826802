
import { Component, OnInit, OnDestroy, } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { EmployerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject, Subscription } from 'rxjs';
import { LoggerService } from '@rollit/shared/data';
import { MatDialog } from '@angular/material/dialog';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { FormGroup, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Employer } from '@rollit/shared/data';

declare var Intercom; // defined by included Intercom javascript.

/**
 * Submnit a CSV file with employee information.
 */
@Component({
  selector: 'app-manage-employees',
  templateUrl: './onboard-employees.component.html',
  styleUrls: ['./onboard-employees.component.scss']
})
export class OnboardEmployeesComponent implements OnInit, OnDestroy {
  private log: any;
  employer: Employer;
  uploader: FileUploader;
  uploadedSuccess: Boolean;
  uploadedSuccessFilename: String;
  uploadedFail: Boolean;
  uploadedFailFilename: String;
  uploadedFailMessage: String;

  isDesktop: boolean;
  ngUnsubscribe = new Subject<void>();

  fileGroup: FormGroup;

  file: File;   // the CSV file
  validationErrors: any[];
  showLoader: boolean = false;
  fileUpLoadStatus: string = "UPLOAD CSV FILE";


  private _employerSubscription: Subscription;

  /**
   * Constructor
   */
  constructor(
    private employerService: EmployerService,
    private uiService: UIManagerService,
    private logger: LoggerService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.log = this.logger.info('manageEmployeesComponent');
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isDesktop => this.isDesktop = isDesktop);

    this.fileGroup = new FormGroup({
      acceptFile: new FormControl()
    });

  }

  /**
   * Initialise the component.
   */
  ngOnInit(): void {
    this._employerSubscription = this.employerService.currentEmployer$().subscribe(value => {
      this.employer = value;
    });
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    this._employerSubscription.unsubscribe();
  }

  fileChangeListener(event) {
    this.log('File Event');
    this.showLoader = true;
    this.fileUpLoadStatus = "LOADING FILE";
    setTimeout(() => {
      const files = event.srcElement.files;
      if (files && files.length > 0) {
        const file: File = files.item(0);
        this.file = file;
      }
    }, 200);
  }

  /**
   * Download a sample CSV file.
   */
  onDownloadSample() {
    Intercom('trackEvent', 'web-app-profile-click-employee-csv');
    this.http.get("/assets/files/Roll-it Super - sample employee data 0119.csv", { responseType: 'blob' }).subscribe((blob) => {
      FileSaver.saveAs(blob, "Rollit - sample employee data.csv");
    });
  }
  onCsvLoaded() {
    this.showLoader = false;
    this.fileUpLoadStatus = "SELECT FILE";
  }
  onCsvErrors(errs: any[]) {
    this.validationErrors = errs;
  }

  /**
   * Onboard new employees
   */
  /*
  submitEmployees() {
    this.employerService.putEmployees(this.employer.id, this.employees)
      .subscribe(
        value => {
          this.log('Employees onboarded', value);
          this.notificationService.info('Success', value.length + ' employees on-boarded');
        },
        err => {
          this.log('Error onboarding employees', err);
          this.notificationService.error('Problem on-boarding employees', err);
        }
      );
  }
  */

  addUser() {
    const dialogRef = this.dialog.open(AddEmployeeComponent, {
      panelClass: 'full-width-dialog',
      data: {
        employerID: this.employer.id
      }
    });
  }
}
