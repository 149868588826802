<ng-container>

  <div class="group-panel">

    <div class="navigation" *ngIf="isDesktop && propertyCount > 0">
      <div class="item prev paragraph2" [ngClass]="{'disabled': propertyListId === 0 && inView}"
        (click)="propertyListId !== 0 && inView ? scrollPrev() : false">
        <i class="material-icons">navigate_before</i> Previous
      </div>

      <div class="item next paragraph2" [ngClass]="{'disabled': propertyListId  === propertyCount&& inView }"
        (click)="propertyListId !== propertyCount && inView ? scrollNext() : false">
        Next <i class="material-icons">navigate_next</i>
      </div>
    </div>

    <app-section-hero [title]="acc.nickname" [description]="''" imageCaption="" [showDelete]="true"
      [imagePath]="imagePath" [expanded]="isDesktop" (expandClicked)="expandClicked()"
      (uploadComplete)="uploadComplete($event)">


      <div class="titleGroup">
        <app-title-group [details]="titleGroupDetails" [displayAsColumn]="isDesktop"></app-title-group>
      </div>

      <div class="heroContent">
        <app-credit-cards-loans-projections
          *ngIf="projectionChartData.length > 0 && projectionChartCategories.length > 0"
          [inView]="(inView && isDesktop) || expanded" [acc]="acc" [projectionChartData]="projectionChartData"
          [timescale]="timescale" [projectionChartCategories]="projectionChartCategories"
          [projectedDebtFreeDate]="projectedDebtFreeDate">
        </app-credit-cards-loans-projections>

        <div class="action-holder">
          <button mat-stroked-button (click)="onEditAccount(acc.id)">
            <span class="paragraph2"> Edit </span>
          </button>



          <i class="delete material-icons" (click)="onRemoveAccount()">
            delete
          </i>
        </div>
      </div>
    </app-section-hero>
  </div>
</ng-container>
