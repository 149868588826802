import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { LoggerService } from '../other/logger.service';
import { PaymentMethod } from '../model/payment';
import { Employee } from '../model/employer';
import { Signup, Subscription, Order, CustomerType, BillingCycle, Feature } from '../model/subscription';
import { User } from '../model/user';

const NAME_SESSIONID = 'sessionId';


@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    apiUrl = this.environment.apiUrl;
    private log: any;
    constructor(@Inject(APP_ENVIRONMENT) private environment: any, private http: HttpClient, private logger: LoggerService) {
        this.log = this.logger.info('subscriptionService');
    }

    public newSignup(): Signup {
        return {
            user: {},
            employer: {
                name: null,
                tradingName: null,
                address: null,
                abn: null,
                contact: {}
            },
            discountCode: {},
            trialCode: {},
            product: {}
        };
    }

    /**
     * Fetch a signup that is in progress.
     */
    public getSignupProgress(): Observable<Signup> {
        const path = '/signup/session';
        const sessionId = localStorage.getItem(NAME_SESSIONID);
        this.log('Fetching signup for ID', sessionId);
        if (sessionId) {
            return this.http.get<Signup>(this.apiUrl + path + '/' + sessionId); // , { params: params });
        } else {
            return of(null);
        }
    }

    /**
     * Persist a signup.
     * @param details The signup details.
     */
    public persistSignupProgress(details: Signup): Observable<Signup> {
        let path = '/signup/session';
        if (!details.id) {
            details.id = localStorage.getItem(NAME_SESSIONID);
        }

        if (details.id) {
            path += '/' + details.id;
            this.log('Persisting signup details', this.apiUrl + path, details);

            return this.http.put<Signup>(this.apiUrl + path, details).pipe(
                map(s => {
                    if (s && s.id) {
                        this.log('Persisted signup', s);
                        localStorage.setItem(NAME_SESSIONID, s.id);
                    }
                    return s;
                })
            );
        } else {
            this.log('Creating signup details', this.apiUrl + path, details);

            return this.http.post<Signup>(this.apiUrl + path, details).pipe(
                map(s => {
                    if (s && s.id) {
                        this.log('Persisted signup', s);
                        localStorage.setItem(NAME_SESSIONID, s.id);
                    }
                    return s;
                })
            );
        }
    }

    /**
     * Submit the signup to the web service.  The web service will create a user account and send a validation email
     * to the user.
     *
     * @param details The signup details
     */
    public submitSignup(details: Signup): Observable<Signup> {
        const path = '/signup/submission';
        if (!details.id) {
            details.id = localStorage.getItem(NAME_SESSIONID);
        }
        this.log('Submitting signup details', this.apiUrl + path, details);

        return this.http.post<Signup>(this.apiUrl + path, details).pipe(
            map(s => {
                if (s && s.id) {
                    localStorage.setItem(NAME_SESSIONID, s.id);
                }
                return s;
            })
        );
    }

    /**
     * Send another verification email with this email address.
     *
     * @param email
     */
    public resendVerification(email: string): Observable<Signup> {
        const path = '/signup/submission';
        const signup = {
            user: { email: email }
        };
        return this.http.put<Signup>(this.apiUrl + path, signup);
    }

    /**
     * Validate a "verify email" token.  If valid, the server will set the email address as valid on auth server,
     * and the Signup details will be returned to the caller.  The app should then redirect to a payment page
     * which requires authorisation.
     *
     * @param token
     */
    public validateToken(token: string): Observable<Signup> {
        const path = '/signup/submission/' + token;
        return this.http.get<Signup>(this.apiUrl + path);
    }

    /**
     * Set a user password
     */
    public setPassword(token: string, password: string): Observable<Signup> {
        const path = '/signup/submission/' + token + '/password';
        const user = { password: password };

        return this.http.put<Signup>(this.apiUrl + path, user);
    }

    /**
     * Fetch signup for currently authenticated user
     */
    public getMySignup(): Observable<Signup> {
        const path = '/signup/me';
        return this.http.get<Signup>(this.apiUrl + path);
    }

    /**
     * Return a subscription by ID.
     */
    public getSubscription(id: number): Observable<Subscription> {
        const path = '/subscription/' + id;
        return this.http.get<Subscription>(this.apiUrl + path);
    }

    /**
     * Set the payment method for a subscription.
     * @param subscriptionId The ID of the subscription.
     * @param paymentMethod  The payment method.
     */
    public setSubscriptionPaymentMethod(subscriptionId: number, paymentMethod: PaymentMethod): Observable<Subscription> {
        const path = this.apiUrl + '/subscription/' + subscriptionId + '/payment-method';
        return this.http.put<Subscription>(path, paymentMethod);
    }

    /**
     *
     * @param token
     */
    public checkOrder(order: Order): Observable<Order> {
        const path = '/signup/me/order';
        return this.http.put<Order>(this.apiUrl + path, order);
    }

    /**
     *
     * @param order Pay for order.
     */
    public submitOrder(order: Order): Observable<Order> {
        const path = '/signup/me/order';
        return this.http.post<Order>(this.apiUrl + path, order);
    }

    /**
     * Order a new product, affecting individual subscription.
     */
    public individualUpgrade(order: Order): Observable<Order> {
        const path = '/me/order';
        return this.http.post<Order>(this.apiUrl + path, order);
    }

    /**
     * Order a new product, affecting employer subscription.
     */
    public employerUpgrade(employerId: number, order: Order): Observable<Order> {
        const path = "/employer/" + employerId + "/order";
        return this.http.post<Order>(this.apiUrl + path, order);
    }

    /**
     * Fetch details for the current user
     */
    public getMe(): Observable<User> {
        const path = '/me';

        return this.http.get<User>(this.apiUrl + path);
    }

    public updateMe(user: User): Observable<User> {
        const path = '/me';

        return this.http.put<User>(this.apiUrl + path, user);
    }

    public getProductId(type: CustomerType, planType: string, billingCycle: BillingCycle): number {
        let productId: number;
        if (type && billingCycle) {
            if (type === CustomerType.individual) {
                productId = planType === 'free' ? 5 : (billingCycle === BillingCycle.monthly ? 7 : 2);
            } else {
                productId = planType === 'free' ? 6 : (billingCycle === BillingCycle.monthly ? 8 : 4);
            }
        }
        return productId;
    }

    /**
     * Returns an array of all available features on the service.
     */
    public getAvailableFeatures(): Observable<Feature[]> {
        const path = '/subscription/features';
        return this.http.get<Feature[]>(this.apiUrl + path);
    }

    /**
     * Returns true if at least one feature name exists in features list.
     * 
     * @param names 
     * @param features 
     */
    public hasSomeFeatures(names: string[], features: Feature[]): boolean {
        if (features) {
            const namesTest = (nm: string) => {
                const featureTest = (f: Feature) => {
                    return f.name === nm;
                };
                return features.some(featureTest);
            };
            return names.some(namesTest);
        }
        return false;
    }

    /**
     * Returns true if all given feature names exists in features list.
     *
     * @param names List of feature names to check for
     * @param features List of features to check names against.
     */
    public hasAllFeatures(names: string[], features: Feature[]): boolean {
        if (features) {
            const featureTest = (nm: string) => {
                const namesTest = (f: Feature) => {
                    return f.name === nm;
                };
                return features.some(namesTest); // is there a feature with this name
            };
            return names.every(featureTest);  // is every one of these names in the user's features
        }
        return false;
    }


    /**
     * This function will onboard an employee if the Employer has pre-loaded the Employee
     * information on the Rollit service.
     *
     * @param employerId The ID of the employer to onboard with.
     * @param employee Employee must contain an employeeNumber, email address, and dob.
     */
    public signupEmployee(employerId: number, employee: Employee): Observable<Employee> {
        const path = '/signup/employer/' + employerId + '/employee';

        return this.http.post<Employee>(this.apiUrl + path, employee);
    }
}
