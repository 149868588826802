import { QuestionBase } from './question-base';

export class RadioQuestion extends QuestionBase<string> {
  controlType = 'radio';
  bigRadio = false;
  wrapRadio = false; // Whether to wrap the second button on a new line
  options: {key: string, value: string}[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.bigRadio = options['bigRadio'] || false;
    this.wrapRadio = options['wrapRadio'] || false;
    this.options = options['options'] || [];
  }
}
