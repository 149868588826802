import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIManagerService } from '@rollit/shared/services';

@Component({
  selector: 'lib-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {

  constructor(private uiService: UIManagerService) {

    this.uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }

}
