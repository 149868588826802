import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { ConfirmationDialogComponent, TitleItemData, TrackService } from '@rollit/shared/common';
import { LoggerService, MoneyService, Nudge, SectionReport } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import * as moment_ from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
const moment = moment_;
@Component({
  selector: 'app-dashboard-position',
  templateUrl: './dashboard-position.component.html',
  styleUrls: ['./dashboard-position.component.scss'],
})
export class DashboardPositionComponent implements OnInit, OnDestroy {
  destroy = new Subject();

  chartData = [];
  tempData = [];
  chartCategories = [];
  sectionValue: Subject<string> = new Subject();
  log: any;
  total: any;
  titleGroupDetails: Array<TitleItemData>;
  titleGroupDetailsReady = false;
  currentSection = 'savings';
  isDesktop: boolean;

  savingsData: SectionReport;
  lifestyleData: SectionReport;
  investmentData: SectionReport;
  superannuationData: SectionReport;
  propertyData: SectionReport;
  loansData: SectionReport;

  nudges: Nudge[];

  sectionTitles = {
    savings: [{ title: 'Income', color: 'savings' }, { title: 'Spending', color: 'negative' }, { title: 'Savings', color: 'growth' }],
    lifestyle: [{ title: 'Value', color: 'lifestyle' }, { title: 'Loans', color: 'negative' }, { title: 'Net value', color: 'growth' }],
    investments: [{ title: 'Portfolio', color: 'investments' }, { title: '', color: '' }, { title: 'Portfolio growth', color: 'growth    ' }],
    superannuation: [{ title: 'Current balance', color: 'superannuation' }, { title: '', color: '' }, { title: 'Retirement projection', color: 'growth' }],
    property: [{ title: 'Value', color: 'property' }, { title: 'Mortgage', color: 'negative' }, { title: 'Equity', color: 'growth' }],
    loans: [{ title: 'Loans', color: 'loans' }, { title: 'Credit cards', color: 'negative' }, { title: 'Repayments', color: 'growth' }],
  };

  @ViewChild('netRipple', { read: MatRipple }) netRipple: MatRipple;
  @ViewChild('savingsRipple', { read: MatRipple }) savingsRipple: MatRipple;
  @ViewChild('lifestyleRipple', { read: MatRipple }) lifestyleRipple: MatRipple;
  @ViewChild('investmentsRipple', { read: MatRipple }) investmentsRipple: MatRipple;
  @ViewChild('superannuationRipple', { read: MatRipple }) superannuationRipple: MatRipple;
  @ViewChild('propertyRipple', { read: MatRipple }) propertyRipple: MatRipple;
  @ViewChild('creditRipple', { read: MatRipple }) creditRipple: MatRipple;

  constructor(
    private dashboardService: DashboardService,
    private trackService: TrackService,
    private logger: LoggerService,
    private uiManagerService: UIManagerService,
    private moneyService: MoneyService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.log = this.logger.info('DashboardPositionComponent');
    this.uiManagerService.mediaSizeIsDesktop$.pipe(takeUntil(this.destroy)).subscribe(isDesktop => this.isDesktop = isDesktop);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnInit(): void {

    this.checkUnmatchedAccounts();

    this.tempData = [{ y: 0, color: '#05A8DB', name: 'Savings' },
    { y: 0, color: '#0D71B9', name: 'Lifestyle' },
    { y: 0, color: '#01528B', name: 'Investment' },
    { y: 0, color: '#1B365F', name: 'Superannuation' },
    { y: 0, color: '#182844', name: 'Property' },
    { y: 0, color: '#509E72', name: 'Net position' },
    { y: 0, color: '#DB0A6E', name: 'Mortgage' },
    { y: 0, color: '#A31B5C', name: 'Loan' },
    { y: 0, color: '#761744', name: 'Credit Card' }];

    this.chartCategories = [
      { short: 'SAV', section: 'savings', title: 'Savings' },
      { short: 'LIFE', section: 'lifestyle', title: 'Lifestyle assets' },
      { short: 'INVT', section: 'investments', title: 'Investments' },
      { short: 'SUPR', section: 'superannuation', title: 'Superannuation' },
      { short: 'PROP', section: 'property', title: 'Property' },
      { short: 'NET', section: 'net', title: 'Net wealth' },
      { short: 'MTG', section: 'property', title: 'Mortgage' },
      { short: 'LOAN', section: 'credit', title: 'Loans' },
      { short: 'CC', section: 'credit', title: 'Credit cards' }
    ];

    // get current financial position data from DashboardService
    this.dashboardService.getPosition().pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got financial position', result);
      this.total = result.total;
      this.setupTitleGroupDetails();
      result.balances.forEach((nameVal, nameIndex) => {

        this.tempData.forEach((chartVal, chartIndex) => {
          // this.log(chartVal.name + '===' + nameVal.name);
          if (chartVal.name === nameVal.name) {
            this.log('Got', chartVal.name, nameVal.name, nameVal.amount);
            this.tempData[chartIndex].y = nameVal.amount;
          }
        });
      });
      this.tempData[5]['y'] = this.total.amount;
      this.chartData = this.tempData;

      this.log('chartData', this.chartData);

      const from = moment().subtract(1, 'months');
      const to = moment();

      this.moneyService.getMovement(from, to).pipe(takeUntil(this.destroy)).subscribe(result => {
        this.log('getMovement', result);
        this.titleGroupDetails[2].data = result.amount;
      });
    });

    this.dashboardService.getSectionSummary('savings').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got savings summary', result);
      this.savingsData = result;
    });
    this.dashboardService.getSectionSummary('savings').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got savings summary', result);
      this.savingsData = result;
    });
    this.dashboardService.getSectionSummary('lifestyle').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got lifestyle summary', result);
      this.lifestyleData = result;
    });
    this.dashboardService.getSectionSummary('investments').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got investments summary', result);
      this.investmentData = result;
    });
    this.dashboardService.getSectionSummary('superannuation').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got superannuation summary', result);
      this.superannuationData = result;
    });
    this.dashboardService.getSectionSummary('property').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got property summary', result);
      this.propertyData = result;
    });
    this.dashboardService.getSectionSummary('loans').pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('got loans summary', result);
      this.loansData = result;
    });

    this.dashboardService.getNudges().subscribe(result => {
      this.nudges = result.data.length <= 3 ? result.data : result.data.slice(0, 3);
      this.log('nudges', this.nudges);
    });
  }

  setupTitleGroupDetails(): void {

    this.titleGroupDetails = [

      {
        title: 'Net wealth',
        data: this.total.amount,
        dataColor: '#509E72',
        isCurrency: true,
        hide: false
      },

      {
        title: 'Assets',
        data: this.total.balances[0].amount,
        dataColor: '#182844',
        isCurrency: true,
        hide: false
      },
      {
        title: 'Growth',
        data: 0,
        dataColor: '#509E72',
        additionalInfo: 'Since last month',
        isCurrency: true,
        hide: false
      },
      {
        title: 'Borrowings',
        data: this.total.balances[1].amount,
        dataColor: '#a31b5c',
        isCurrency: true,
        hide: false
      }
    ];

    this.titleGroupDetailsReady = true;
    this.log('titleGroupDetailsReady', this.titleGroupDetailsReady);
    this.log(this.titleGroupDetails);
  }

  graphClicked(category, name): void {
    this.log(category, name);

    switch (category) {
      case 'savings': {
        this.savingsRipple.launch({ centered: true, radius: 600 });
        this.currentSection = category;
        this.sectionValue.next(category);
        break;
      }
      case 'lifestyle': {
        this.lifestyleRipple.launch({ centered: true, radius: 600 });
        this.currentSection = category;
        this.sectionValue.next(category);
        break;
      }
      case 'investments': {
        this.investmentsRipple.launch({ centered: true, radius: 600 });
        this.currentSection = category;
        this.sectionValue.next(category);
        break;
      }
      case 'superannuation': {
        this.superannuationRipple.launch({ centered: true, radius: 600 });
        this.currentSection = category;
        this.sectionValue.next(category);
        break;
      }
      case 'property': {
        this.propertyRipple.launch({ centered: true, radius: 600 });
        this.currentSection = category;
        this.sectionValue.next(category);
        break;
      }
      case 'credit': {
        this.creditRipple.launch({ centered: true, radius: 600 });
        this.currentSection = category;
        this.sectionValue.next(category);
        break;
      }
      case 'net': {
        this.netRipple.launch({ centered: true, radius: 600 });
        break;
      }

    }
  }

  onNewGoal(category: string) {
    this.dashboardService.startAddGoal(category);
  }

  checkUnmatchedAccounts() {
    this.log('Check for unamtched accounts');
    this.dashboardService.getPendingAccounts().subscribe(res => {
      if (res.length > 0) {
        this.openUnmatchedAccountsConfirmationDialog();
      }
    });
  }

  openUnmatchedAccountsConfirmationDialog() {
    this.log('Unamtched accounts dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Linked accounts',
        copy: 'Your new linked account requires attention',
        buttonMessage: 'Review now',
        type: 'Account',
        showCancel: true,
        cancelMessage: 'Later',
        showButtonTick: false,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result && result[0] === 'accept') {
        this.log('start unmatched accounts survey')
        this.router.navigate(['accounts', 'incoming-accounts'], { skipLocationChange: true });
      }
    });
  }

  onNudgeClosed(nudge: Nudge) {
    // set nudge status to "dismissed"
    this.dashboardService.dismissNudge(nudge).subscribe(result => {
      // remove nudge from list
      const index = this.nudges.indexOf(nudge, 0);
      if (index > -1) {
        this.nudges.splice(index, 1);
      }
    });
  }

  onNudgeButtonPressed(nudge: Nudge) {
    this.trackService.performNudgeAction(nudge);
  }
}

