import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, HostListener, AfterViewInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import * as Highcharts from 'highcharts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-bar-chart-category',
  templateUrl: './bar-chart-category.component.html',
  styleUrls: ['./bar-chart-category.component.scss']
})
export class BarChartCategoryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart') componentRef;
  @Output() graphClicked: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() seriesData: Array<any>;
  @Input() seriesCategories: Array<any>;
  @Input() showExpand: Boolean;
  log: any;
  isDesktop: Boolean;

  chart;
  Highcharts: typeof Highcharts = Highcharts; // required
  updateFlag: Boolean;
  showChart: Boolean;
  chartReady: Boolean;
  getInstance: any;
  destroy = new Subject();
  chartFindElementCount = 0;

  chartRanges: Array<any>;

  chartRangeHigh = [
    { tick: -100000000, position: -4 },
    { tick: -10000000, position: -3 },
    { tick: -1000000, position: -2 },
    { tick: -100000, position: -1 },
    { tick: 0, position: 0 },
    { tick: 100000, position: 1 },
    { tick: 1000000, position: 2 },
    { tick: 10000000, position: 3 },
    { tick: 100000000, position: 4 }
  ];

  chartRangeMedium = [
    { tick: -10000000, position: -4 },
    { tick: -1000000, position: -3 },
    { tick: -100000, position: -2 },
    { tick: -10000, position: -1 },
    { tick: 0, position: 0 },
    { tick: 10000, position: 1 },
    { tick: 100000, position: 2 },
    { tick: 1000000, position: 3 },
    { tick: 10000000, position: 4 }
  ];

  chartRangeLow = [
    { tick: -1000000, position: -4 },
    { tick: -100000, position: -3 },
    { tick: -10000, position: -2 },
    { tick: -1000, position: -1 },
    { tick: 0, position: 0 },
    { tick: 1000, position: 1 },
    { tick: 10000, position: 2 },
    { tick: 100000, position: 3 },
    { tick: 1000000, position: 4 }
  ];

  chartRangeLowest = [
    { tick: -100000, position: -4 },
    { tick: -10000, position: -3 },
    { tick: -1000, position: -2 },
    { tick: -100, position: -1 },
    { tick: 0, position: 0 },
    { tick: 100, position: 1 },
    { tick: 1000, position: 2 },
    { tick: 10000, position: 3 },
    { tick: 100000, position: 4 }
  ];



  chartOptions: Highcharts.Options = {

    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    series: [{
      colorByPoint: true,
      type: 'column',
      animation: false,

    }],
    yAxis: {
      offset: 30,
      tickLength: 30,
      tickPosition: "inside",
      tickWidth: 1,
      tickColor: "#8A9AB1",
      gridLineColor: '#8A9AB1',
      tickPositions: [-4, -3, -2, -1, 0, 1, 2, 3, 4],
      title: {
        text: null
      },
      labels: {
        style: {
          color: '#8A9AB1',
          fontSize: '.6em',
          whiteSpace: 'nowrap'
        },

        formatter: function (e) {

          const color = '#1B365F';
          const index = e.axis.tickPositions.indexOf(e.pos);
          let val = this.getRangeValue(index);
          if (val >= 0) {
            val = '$' + ~~val;
          } else {
            val = '-$' + -(~~val)
          }

          const finalValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return '<span class="paragraph3" style="color:' + color + ';">' + finalValue + '</span>';
        }.bind(this),
        align: 'left',
        y: -4,
        x: 0,
      }

    },
    xAxis: {
      lineColor: "#8A9AB1",
      title: {
        text: null
      },
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        cursor: 'pointer',


        borderWidth: 0,

        point: {
          events: {
            click: function (e) {
              const p = e.point;
              this.clickIt(p.category.section, p.y, this);
            }.bind(this),
            mouseOver: function (e) {
            }.bind(this),
          }
        },
      },
      column: {
        pointPadding: 0.05,
        groupPadding: 0,
        borderRadius: 3,
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          formatter: function () { return this.x; },
          style: {
            fontWeight: 'normal',
            fontSize: '.6em',
          }
        }
      }
    },
    tooltip: {
      padding: 0,
      borderRadius: 10,
      useHTML: true,
      hideDelay: 5000,
      outside: false,
      formatter: function () {
        let value;
        const originalValue = this.point['originalValue'];

        if (originalValue >= 0) {
          value = '$' + ~~originalValue;
        } else {
          value = '-$' + -(~~originalValue)
        }
        const finalValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return '<div class="paragraph2" style="color:' + this.color + ';text-align:center;padding:2px 5px;z-index:400;position:relative;font-weight:500">' + this.x['title'] + '<br/>' + finalValue + '</div>';
      },
      positioner: function (boxWidth, boxHeight, point) {
        // Position Y
        let finalPlotY = boxHeight;
        if (point.plotY > this.chart.chartHeight / 2) {
          finalPlotY = ((this.chart.chartHeight / 2) - (boxHeight / 2)) - 10;
        } else if ((point.plotY - boxHeight) <= 0) {
          finalPlotY = 2;
        } else {
          finalPlotY = (point.plotY - (boxHeight / 2)) - 10;
        }

        // Position X
        let finalPlotX = 0;
        if ((point.plotX + boxWidth) >= this.chart.chartWidth) {
          finalPlotX = this.chart.chartWidth - (boxWidth + 3);
        } else {
          finalPlotX = point.plotX;
        }

        return { x: finalPlotX, y: finalPlotY };
      },
    },
    chart: {
      marginTop: 20,
      marginBottom: 5,
      marginLeft: 30,
      marginRight: 0,
      animation: false,
      events: {
        load: function (e) {
          // console.log('e', e)
          const chart = e.target;
          chart.series.forEach(function (series) {
            series.points.forEach(function (point) {
              //console.log(point);
              if (point.y >= 0 && point.category.short !== 'CC' && point.category.short !== 'LOAN' && point.category.short !== 'MTG') {

                point.update({
                  dataLabels: {
                    formatter: function () {

                      return '<span class="paragraph3">' + this.x.short + '</span>';
                    },
                    color: point.color,
                    verticalAlign: "bottom",
                    y: 22
                  }
                }, true, false);
              } else {
                point.update({
                  dataLabels: {
                    formatter: function () {
                      return '<span class="paragraph3">' + this.x.short + '</span>';
                    },
                    color: point.color,
                    verticalAlign: "top",
                    y: -17
                  }
                }, true, false);
              }
            });
          });
        }.bind(this),
        redraw: function () {
          //console.log('chartRedraw');
        }
      }
    }
  };


  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    //this.log('callback', chart);
    this.chart = chart;
  }

  constructor(
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private uiService: UIManagerService,) {

    const self = this;
    this.log = this.logger.info('BarChartCategoryComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });

  }

  ngOnDestroy(): void {
    // this.log(this.chart);
    this.chart = null;
    //this.log(this.chart);
    this.componentRef.chart = null;

    this.destroy.next();
    this.destroy.complete();
  }


  ngAfterViewInit(): void {
    //this.log('ngAfterViewInit() chart', this.chart);
    setTimeout(() => {
      this.initializeChart();
    }, 100);

  }


  ngOnInit(): void {
    //this.log('map1 this.seriesData', this.seriesData);
    this.chartRanges = this.getChartRange();
    this.chartOptions.series[0]['data'] = this.seriesData.map((val) => {
      let newVal = this.convert(val.y);
      let range = this.findRange(newVal);
      let y = range.index + (newVal - range.low) / (range.high - range.low);

      return {
        y: y,
        originalValue: val.y,
        color: val.color,
        name: val.name,
      };

    });

    this.chartOptions.xAxis['categories'] = this.seriesCategories;
    //this.log('map2 this.seriesData', this.seriesData);
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    setTimeout(() => {
      this.sizeChart();
    }, 50);
  }

  initializeChart() {
    //this.log('initializeChart()');
    const targetElement = this.elementRef.nativeElement.querySelector("#chartHolder");
    const targetWidth = targetElement['offsetWidth'];
    const targetHeight = targetElement['offsetHeight'];
    //this.log('initializeChart() targetElement', targetElement, targetWidth, targetHeight);

    if (targetWidth > 0) {
      //this.log('initializeChart() ready');
      if (this.isDesktop) {
        this.chartOptions.chart.height = targetHeight;
      }
      this.chartOptions.chart.width = targetWidth;

      //this.cdr.markForCheck();
      this.updateFlag = true;
      this.initChart();

    } else {
      //this.log('initializeChart() not ready');
      if (this.chartFindElementCount < 10) {
        setTimeout(() => {
          this.initializeChart();
          this.chartFindElementCount++;
        }, 60);
      }
    }

  }


  initChart() {
    //this.log('initChart()');
    setTimeout(() => {
      if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        const evt = document.createEvent('UIEvents');
        evt['initUIEvent']('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      } else {
        window.dispatchEvent(new Event('resize'));
      }
    }, 100);
  }


  sizeChart() {
    //this.log('sizeChart()');
    const targetElement = this.elementRef.nativeElement.querySelector("#chartHolder");
    const targetChartContainer = this.elementRef.nativeElement.querySelector(".highcharts-container");
    const targetChartParent = this.elementRef.nativeElement.querySelector("highcharts-chart");
    const targetWidth = targetElement['offsetWidth']
    const targetHeight = targetElement['offsetHeight'];

    targetChartContainer.style.width = targetWidth + 'px';
    targetChartParent.style.width = targetWidth + 'px';
    if (this.isDesktop) {
      targetChartContainer.style.height = targetHeight + 'px';
    }

    this.chart.setSize(targetWidth, null, false);

    //this.log('sizeChart() targetElement', targetWidth, targetHeight);

    this.showChart = true;
  }

  convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    const final = +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    return +this.textTruncate(final);
  }

  textTruncate(str) {
    length = 10;
    if (str.length > length) {
      return str.substring(0, length);
    } else {
      return str;
    }
  }


  clickIt(category, name) {
    const section = category;
    if (section !== '') {
      this.graphClicked.emit([section, name]);
    }
  }

  getChartRange() {
    const highest = Math.max.apply(Math, this.seriesData.map(function (o) { return o.y; }));
    const lowest = Math.min.apply(Math, this.seriesData.map(function (o) { return o.y; }));
    //this.log('getChartRange', highest, lowest);
    let range;

    if (highest >= 10000000 || lowest <= -10000000) {
      // this.log('high', highest, lowest);
      range = this.chartRangeHigh;
    } else if (highest >= 1000000 || lowest <= -1000000) {
      // this.log('medium', highest, lowest);
      range = this.chartRangeMedium;
    } else if (highest >= 100000 || lowest <= -100000) {
      //  this.log('low', highest, lowest);
      range = this.chartRangeLow;
    } else {
      // this.log('lowest', highest, lowest);
      range = this.chartRangeLowest;
    }

    return range;
  }

  getRangeValue(index) {
    // this.log('getRangeValue', index)
    return this.chartRanges[index].tick;
  }

  findRange(val) {
    // console.log('findRange', val)
    for (var i = 0; i < this.chartRanges.length - 1; i++) {
      var low = this.chartRanges[i].tick,
        high = this.chartRanges[i + 1].tick;


      if (low <= val && high > val) {

        //console.log('low', low, 'high', high, 'index', this.chartRanges[i].position);
        return {
          low: low,
          high: high,
          index: this.chartRanges[i].position
        };
      }
    }
  }

}
