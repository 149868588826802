import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { SurveyFormComponent } from './form/survey-form/survey-form.component';
import { ReportComponent } from './report/report.component';
import { CashflowManagementComponent } from './report/sections/cashflow-management/cashflow-management.component';
import { FinancialHealthScoreComponent } from './report/sections/financial-health-score/financial-health-score.component';
import { FinancialSecurityComponent } from './report/sections/financial-security/financial-security.component';
import { FooterComponent } from './report/sections/footer/footer.component';
import { HeaderComponent } from './report/sections/header/header.component';
import { HomeSituationComponent } from './report/sections/home-situation/home-situation.component';
import { SetupStatusComponent } from './setup-status/setup-status.component';

const surveyRoutes: Routes = [
    { path: 'survey/setup', component: SetupStatusComponent, data: { title: 'Survey Status' } },
    { path: 'survey/:module/:slug', component: SurveyFormComponent, data: { title: 'Survey' } },
    { path: 'setup-status', component: SetupStatusComponent, data: { title: 'Setup status' } },
    {
        path: 'report',
        component: ReportComponent,
        data: { title: 'Financial Health Report' },
    }, {
        path: 'graphic/header',
        component: HeaderComponent,
        data: { title: 'Financial Health Report' },
    }
    , {
        path: 'graphic/financial-health-score',
        component: FinancialHealthScoreComponent,
        data: { title: 'Financial Health Report' },
    }
    , {
        path: 'graphic/home-situation',
        component: HomeSituationComponent,
        data: { title: 'Financial Health Report' },
    }
    , {
        path: 'graphic/cashflow-management',
        component: CashflowManagementComponent,
        data: { title: 'Financial Health Report' },
    }
    , {
        path: 'graphic/financial-security',
        component: FinancialSecurityComponent,
        data: { title: 'Financial Health Report' },
    }
    , {
        path: 'graphic/footer',
        component: FooterComponent,
        data: { title: 'Financial Health Report' },
    }
    , {
        path: 'graphic/header',
        component: HeaderComponent,
        data: { title: 'Financial Health Report' },
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(surveyRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SurveyRoutingModule { }
