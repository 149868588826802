import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { EmployerService } from '@rollit/shared/data';
import { Subscription, Observable, merge, Subject } from 'rxjs';
import { User } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { startWith, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Employer, Employee } from '@rollit/shared/data';
// import { ExportXls } from '@rollit/shared/utils';

@Component({
    selector: 'app-employer-subscriptions',
    templateUrl: './employer-subscriptions.component.html',
    styleUrls: ['./employer-subscriptions.component.scss'],
})
export class EmployerSubscriptionsComponent implements OnInit, OnDestroy, AfterViewInit {
    log: any;
    employer: Employer;
    employees: Employee[] = [];
    displayedColumns: string[] = ['lastName', 'email', 'payrollNumber', 'timeCreated', 'edit'];
    dataSource: MatTableDataSource<User>;
    tableReady: Boolean = false;
    activeEmployees: number;       // the number of employees this employer has registered with Rollit
    totalEmployees: number;
    totalEmployeesStatic: number;
    inactiveEmployees: number;
    notOnboarded: number;
    notAccepted: number;
    expandedRow: number;
    filteredOptions: Observable<any[]>;
    proxyValue: any;
    pageSize = 5;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    searchTerm = '';
    searchActive = false;
    isLoadingResults = false;
    isRateLimitReached = false;
    dialogRef: MatDialogRef<AddEmployeeComponent>;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    private _employerSubscription: Subscription;
    private searchSub$ = new Subject<string>();

    constructor(
        private employerService: EmployerService,
        public dialog: MatDialog,
        private logger: LoggerService,
        // private exportXLS: ExportXls,
    ) {
        this.log = this.logger.info('employerSubscriptionComponent');

    }

    ngOnInit(): void {
        this._employerSubscription = this.employerService.currentEmployer$().subscribe(value => {
            this.employer = value;
            if (value) {
                this.employerService.getEmployeeCounts(value.id).subscribe(employeeResult => {
                    this.totalEmployees = employeeResult.active + employeeResult.inactive + employeeResult.notOnboarded + employeeResult.notAccepted;
                    this.totalEmployeesStatic = this.totalEmployees;
                    this.notOnboarded = employeeResult.notOnboarded;
                    this.notAccepted = employeeResult.notAccepted;
                    this.inactiveEmployees = employeeResult.inactive + this.notOnboarded + this.notAccepted;
                    this.activeEmployees = employeeResult.active;

                    this.log('employeeResult', employeeResult);
                    this.log('totalEmployees', this.totalEmployees);
                    this.log('inactiveEmployees', this.inactiveEmployees);
                    this.log('activeEmployees', this.activeEmployees);
                });
            }
        });
    }

    ngAfterViewInit() {
        // If the user changes the sort order, reset back to the first page.

        this.sort.sortChange.subscribe(() => { this.paginator.pageIndex = 0; this.log('active', this.sort.active, this.sort.direction, this.totalEmployees); });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.log('data', data);
                    this.log('pageSizeOptions', this.pageSizeOptions, this.totalEmployees);
                    if (data["pageSize"]) {
                        this.pageSize = data["pageSize"];
                    }
                    this.getEmployees(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.searchTerm);
                })
            ).subscribe();

        this.searchSub$.pipe(
            debounceTime(400),
            distinctUntilChanged()
        ).subscribe((filterValue: string) => {
            if (filterValue.length > 2) {
                this.log('search', filterValue.trim().toLowerCase());
                this.searchTerm = filterValue.trim().toLowerCase();
                this.paginator.pageIndex = 0;
                this.getEmployees(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.searchTerm);
                this.searchActive = true;
            } else if (filterValue.length === 0) {
                this.searchTerm = '';
                this.searchActive = false;
                this.getEmployees(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.searchTerm);
            } else {
                this.searchActive = true;
            }

        });
    }

    applyFilter(filterValue: string) {
        this.searchSub$.next(filterValue);
    }

    emptySearch(event) {
        this.log('emptySearch', event.target.nextSibling.value);
        event.target.nextSibling.value = '';
        this.searchTerm = '';
        this.searchActive = false;
        this.getEmployees(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.searchTerm);
        this.searchSub$.next('');
    }


    ngOnDestroy(): void {
        this._employerSubscription.unsubscribe();
    }

    trackById(index: number, item: any) {
        return item.id;
    }

    getEmployees(sortTitle: string, sortDirection, pageIndex: number, searchTerm?: string) {
        this.employerService.getEmployees(this.employer.id, searchTerm, sortTitle, sortDirection, pageIndex * this.pageSize, this.pageSize).subscribe(result => {
            // remove null objects
            for (let i = result.data.length - 1; i >= 0; i--) {
                if (result.data[i] === null) {
                    this.log('item null');
                    result.data.splice(i, 1);
                }
            }
            // Align sort data
            let activeCount = 0;
            for (const user of result.data) {
                if (user.user.enabled === true) {
                    activeCount++;
                    user["status"] = 'Active';
                    this.log('enabled');
                } else {
                    user["status"] = 'Inactive';
                }
                if (!!user.employment) {
                    user["timeCreated"] = user.employment.timeCreated;
                }
            }
            this.log('getEmployees', result);
            this.totalEmployees = result.total;
            this.employees = result.data;
            this.dataSource = new MatTableDataSource(this.employees);
            this.tableReady = true;
            this.log('this.employees', this.employees);
        });
    }
    toggleDetailsRow(row: number) {
        this.log(row + ' ' + this.expandedRow);
        if (this.expandedRow !== undefined) {
            // document.getElementById('row-' + this.expandedRow).style. = "none";
            document.getElementById('row-' + this.expandedRow).classList.remove('active');
            document.getElementById('parent-row-' + this.expandedRow).classList.remove('active');
            if (this.expandedRow > 0) {
                document.getElementById('parent-row-' + (this.expandedRow - 1)).classList.remove('remove-border');
            }
        }
        if (row !== this.expandedRow) {
            this.expandedRow = row;
            // document.getElementById('row-' + this.expandedRow).style.display = "table-row";
            document.getElementById('row-' + this.expandedRow).classList.add('active');
            document.getElementById('parent-row-' + this.expandedRow).classList.add('active');
            if (this.expandedRow > 0) {
                document.getElementById('parent-row-' + (this.expandedRow - 1)).classList.add('remove-border');
            }
        } else {
            this.expandedRow = undefined;
        }

    }

    addEmployee() {
        this.dialogRef = this.dialog.open(AddEmployeeComponent, {
            panelClass: 'full-width-dialog',
            data: {
                employerID: this.employer.id
            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            this.getEmployees(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.searchTerm);
            this.log('dialogRefClosed');
        });
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent) {
        this.log(event.option.value);
        this.proxyValue = ' ';
        this.editEmployee(null, event.option.value);
    }

    editEmployee(event: Event, employee: Employee) {
        if (event !== null) {
            event.stopPropagation();
        }
        this.dialogRef = this.dialog.open(AddEmployeeComponent, {
            panelClass: 'full-width-dialog',
            data: {
                employerID: this.employer.id,
                employee: employee,

            }
        });
        this.dialogRef.afterClosed().subscribe(result => {
            this.getEmployees(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.searchTerm);
            this.log('dialogRefClosed');
        });

    }

    // exportUsers() {
    //     this.employerService.getEmployees(this.employer.id, null, null, null, 0, 10000).subscribe(allResult => {
    //         for (const user of allResult.data) {
    //             if (user.user.enabled === true) {
    //                 user["status"] = 'Active';
    //             } else {
    //                 user["status"] = 'Inactive';
    //             }
    //         }
    //         this.exportXLS.exportAsExcelFile(allResult.data, 'employee');
    //     });
    // }


}
