import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, HostListener, Renderer2, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import * as Highcharts from 'highcharts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

Highcharts.AST.allowedAttributes.push('data-category');
Highcharts.AST.allowedAttributes.push('data-index');
Highcharts.AST.allowedAttributes.push('data-points');

@Component({
  selector: 'app-bar-chart-time',
  templateUrl: './bar-chart-time.component.html',
  styleUrls: ['./bar-chart-time.component.scss']
})
export class BarChartTimeComponent implements OnInit, AfterViewInit {
  @ViewChild('chart') componentRef: ElementRef;
  @Output() graphClicked: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() seriesData: Array<any> = [];
  @Input() seriesCategories: Array<any> = [];
  @Input() showExpand: boolean;
  @Input() smallTitle: boolean;
  @Input() timescale: string;

  log: any;
  self: any;

  chart: any;
  Highcharts: typeof Highcharts = Highcharts; // required
  updateFlag = false;
  showChart: boolean;
  getInstance: any;
  isDesktop: boolean;
  chartReady: boolean;
  chartFindElementCount = 0;
  destroy = new Subject();



  chartOptions: Highcharts.Options = {

    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    yAxis: {
      offset: 40,
      tickLength: 40,
      tickPosition: "inside",
      tickWidth: 1,
      tickColor: "#8A9AB1",
      gridLineColor: '#8A9AB1',
      title: {
        text: null
      },
      labels: {
        style: {
          color: '#8A9AB1',
          fontSize: '.6em',
          whiteSpace: 'nowrap'
        },
        formatter: function () {
          let color = '#1B365F';
          var val;
          if (this.value >= 0) {
            val = '$' + ~~this.value;
          } else {
            val = '-$' + -(~~this.value)
          }
          var finalValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return '<span class="paragraph3" style="color:' + color + ';">' + finalValue + '</span>';
        },
        align: 'left',
        y: -5,
        x: 0,
      }

    },
    xAxis: {
      lineColor: "#8A9AB1",
      title: {
        text: '<span class="paragraph3">Age</span>',
        y: -22,
        style: {
          color: '#1B365F',
          whiteSpace: 'nowrap',
        },

      },
      labels: {
        enabled: true,
        y: -6,
        style: {
          whiteSpace: 'nowrap'
        },
        useHTML: true,
        formatter: function (e) {
          let colorClass = 'section-color__secondary';
          let j = 0;
          let points = [];
          let category = '';
          let index = 0;
          let finalPoints = [];
          //console.log('chartDoing', e);
          e.chart.yAxis[0].series.forEach(element => {
            let i = 0;
            points = element.points;
            //   //console.log('chartDoing element', element);
            element['processedYData'].forEach(el => {

              if (points !== undefined && points.length > 0 && i === this.pos && el > 0) {

                category = points[i].category;
                index = points[i].index;
                finalPoints = [];
                //console.log('points', points[i])

                e.chart.series.forEach((series, ind) => {
                  series.data.forEach((seriesPoints) => {
                    if (seriesPoints.index === index) {
                      finalPoints.push(seriesPoints.y);
                    }
                  });
                });
                //       // console.log('p.category', p.category, 'finalPoints', finalPoints, 'p.index', p.index);


                if (Object.keys(points[i]).indexOf('shapeArgs') > -1) {
                  // console.log('pos', points[i]);
                  if (points[i].shapeArgs.height > 10) {
                    colorClass = 'section-color__lightest';
                  }
                }
              }
              i++;
            });

            j++;
          });

          return '<span class="paragraph3 ' + colorClass + ' xaxis-el" style="cursor:pointer;" data-category="' + category + '" data-index="' + index + '" data-points="' + finalPoints.toString() + '">' + this.value + '</span>';
          //return '<span class="paragraph3 ' + colorClass + ' xaxis-el" style="cursor:pointer;" >' + this.value + '</span>';
        },

      }
    },
    plotOptions: {
      series: {

        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        },

        cursor: 'pointer',


        borderWidth: 0,
        point: {
          events: {
            click: function (e) {
              //this.log(e);
              const p = e.point;
              const finalPoints = [];
              this.chart.series.forEach((series, ind) => {
                series.data.forEach((seriesPoints) => {
                  if (seriesPoints.index === p.index) {
                    finalPoints.push(seriesPoints.y);
                  }
                });
              });
              this.clickIt(p.category, finalPoints, p.index);
            }.bind(this)
          }
        },
      },
      column: {

        pointPadding: 0.05,
        groupPadding: 0,
        stacking: 'normal',
        borderRadius: 3,
        dataLabels: {
          enabled: false,
          allowOverlap: true,
          formatter: function () { return this.x },
          style: {
            fontWeight: 'normal',
            fontSize: '.6em',
          }
        }
      }
    },
    tooltip: {
      padding: 0,
      borderRadius: 10,
      useHTML: true,
      shared: true,
      split: false,
      hideDelay: 3000,
      formatter: function (e) {
        //console.log('e', e)
        var finalString = '';
        var finalPositive = 0;
        var finalNegative = 0;

        var finalNet = 0;
        var finalRetirement = 0;

        var foundPos = false
        var foundNeg = false
        var foundNet = false
        var foundRetirement = false

        var finalPositiveString = '';
        var finalNegativeString = '';
        var finalNetString = '';
        var retirementAge = '';

        this.points.forEach(element => {
          //console.log('element', element);
          var value;

          if (element.series.name === 'Net') {
            finalNet = element.y;
            value = element.y;
            foundNet = true;
          } else if (element.series.name === 'Retirement') {
            finalRetirement = element.y;
            value = element.y;
            foundRetirement = true;
            retirementAge = element.series.userOptions.custom.retirementAge;

          } else {
            if (element.y >= 0) {
              foundPos = true;
              finalPositive += element.y;
              value = '$' + ~~element.y;
            } else {
              foundNeg = true
              finalNegative -= element.y;
              value = '-$' + -(~~element.y)
            }
          }
          var tempValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          finalString += '<span style="color:' + element.color + ';">' + tempValue + '</span><br/>';
        });

        if (foundPos) {
          finalPositive = ~~finalPositive;
          finalPositiveString = '<span class="section-color__positive">$' + finalPositive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</span><br/>';
        }

        if (foundNeg) {
          finalNegative = (~~finalNegative);
          finalNegativeString = '<span class="section-color__negative">-$' + finalNegative.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</span><br/>';
        }

        if (foundNet) {
          finalNet = ~~finalNet;
          finalNetString = '<span  class="section-color__growth">$' + finalNet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</span><br/>';
        }

        if (foundRetirement) {
          finalRetirement = ~~finalRetirement;
          finalNetString = '<span  class="section-color__growth">$' + finalRetirement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</span><br/>';

          return '<div style="text-align:center;padding:2px 5px;">' + finalNetString + '</div>';
        } else {
          return '<div style="text-align:center;padding:2px 5px;">' + finalPositiveString + ' ' + finalNegativeString + ' ' + finalNetString + '</div>';
        }


      }
    },
    chart: {
      type: 'column',
      marginTop: 20,
      marginBottom: 5,
      marginLeft: 40,
      marginRight: 0,
      animation: false,
      events: {
        load: function (e) {

          const self = e.target;

          // Move the xAxis labels to the 0 value on the yAxis
          var xx = (-1) * (self.yAxis[0].translate(0, false, false));
          self.xAxis[0].labelGroup.translate(0, xx);
          var xOffset = 13;
          var xPos = (self.xAxis[0].width / 2) + xOffset;
          self.xAxis[0].axisTitle.translate(-xPos, xx);

          // Setup the Net spline series
          var chart = self;
          var series = self.series.filter(elem => elem.type === 'spline');
          series.forEach(series => {
            self.addSeries({
              data: series.userOptions.data,
              showInLegend: false,
              enableMouseTracking: false,
              color: '#fff',
              type: 'spline',
              zIndex: series.options.zIndex - 1,
              marker: {
                symbol: series.symbol,
                radius: series.options.marker.radius + 2
              },
              lineWidth: series.options.lineWidth + 4
            });
          });

        }.bind(this),
        redraw: function (e) {

          // Move the xAxis labels to the 0 value on the yAxis
          const xx = (-1) * (e.target['yAxis'][0].translate(0, false, false));
          this.xAxis[0]['labelGroup'].translate(0, xx);
          const xOffset = 13;
          const xPos = (this.xAxis[0]['width'] / 2) + xOffset;
          this.xAxis[0]['axisTitle'].translate(-xPos, xx);

        }
      }

    }
  };

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    //this.log('callback', chart);
    this.chart = chart;
    //this.sizeChart(this.chart);
  }

  constructor(
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private uiService: UIManagerService,
    private render: Renderer2) {

    const self = this;
    this.log = this.logger.info('BarChartTimeComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });



  }

  ngOnDestroy(): void {
    //this.log(this.chart);
    this.chart = null;
    //this.log(this.chart);

    this.destroy.next();
    this.destroy.complete();
  }


  ngAfterViewInit(): void {


    setTimeout(() => {
      this.initializeChart();
    }, 100);
  }

  ngOnInit(): void {
    this.seriesData.forEach((cat, index) => {
      const seriesIndex = index;
      cat.data.forEach((val, dataIndex) => {
        //this.log('seriesdata', val)
        if (val) {
          this.seriesData[seriesIndex].data[dataIndex] = this.convert(val);
          //this.log('seriesdata new', this.seriesData[seriesIndex].data[dataIndex]);
        }

      });
    });

    this.chartOptions.series = this.seriesData;
    this.log('seriesData', this.seriesData);


    if (this.timescale) {
      if (this.timescale !== 'Age') {
        this.chartOptions.xAxis['title'].text = '<span class="paragraph3"></span>';
      }
    }

    this.chartOptions.xAxis['labels'].rotation = 0;
    this.chartOptions.series = this.seriesData;
    this.chartOptions.xAxis['categories'] = this.seriesCategories;

  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    //this.log('sizeChange');

    setTimeout(() => {
      this.sizeChart(this.chart);
    }, 60);


  }

  initializeChart() {
    //this.log('initializeChart()');
    const targetElement = this.elementRef.nativeElement.querySelector("#chartHolder");
    const targetWidth = targetElement['offsetWidth']
    const targetHeight = targetElement['offsetHeight'];
    this.log('initializeChart() targetElement', targetElement, targetWidth, targetHeight);

    if (targetWidth > 0) {
      // this.log('initializeChart() ready');
      this.chartOptions.chart.width = targetWidth;
      this.chartOptions.chart.height = targetHeight;



      //this.cdr.markForCheck();
      this.updateFlag = true;
      this.initChart();

    } else {
      // this.log('initializeChart() not ready');
      if (this.chartFindElementCount < 10) {
        setTimeout(() => {
          this.initializeChart();
          this.chartFindElementCount++;
        }, 60);
      }

    }

  }

  initChart() {
    // this.log('initChart()');
    setTimeout(() => {
      if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        const evt = document.createEvent('UIEvents');
        evt['initUIEvent']('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      } else {
        window.dispatchEvent(new Event('resize'));
      }
    }, 50);
  }


  convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    const final = +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    return +this.textTruncate(final);
  }

  textTruncate(str) {
    length = 10;
    if (str.length > length) {
      return str.substring(0, length);
    } else {
      return str;
    }
  }


  sizeChart(chart: any) {

    //this.log('sizeChart', chart);
    const targetElement = this.elementRef.nativeElement.querySelector("#chartHolder");
    const targetChartContainer = this.elementRef.nativeElement.querySelector(".highcharts-container");
    const targetChartParent = this.elementRef.nativeElement.querySelector("highcharts-chart");
    const targetWidth = targetElement['offsetWidth']
    const targetHeight = targetElement['offsetHeight'];
    //this.log('targetChartParent', targetChartParent);

    // this.log('targetChartContainer', targetChartContainer);

    targetChartContainer.style.width = targetWidth + 'px';
    targetChartContainer.style.height = targetHeight + 'px';
    targetChartParent.style.width = targetWidth + 'px';

    if (chart) {
      chart.setSize(targetWidth, targetHeight, false);
      // this.log('sizeChart() targetElement', targetWidth, targetHeight);
    }

    this.initXaXis();

    this.showChart = true;
  }

  initXaXis() {
    // Allow the x axis labels to be clicked on
    // this.log('initXaXis()');
    const classArr: any = this.elementRef.nativeElement.querySelectorAll('.xaxis-el');
    classArr.forEach(element => {
      this.render.listen(element, 'click', (target) => {
        //console.log('clicked', target);
        if (target.target.dataset.points) { }
        const category = target.target.dataset.category;
        const index = target.target.dataset.index;
        const points = target.target.dataset.points.split(',');
        const finalPoints = [];
        points.forEach(element => {
          finalPoints.push(+element);
        });
        //this.log('points', points);
        this.clickIt(category, finalPoints, index);
      });
    });

  }

  clickIt(category, finalPoints, index) {
    // this.log('click', category, finalPoints, index);
    const section = category;

    if (section !== '') {
      this.graphClicked.emit([section, finalPoints, index]);
    }

  }

}
