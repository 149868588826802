import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';

@Component({
  selector: 'lib-report-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  log: any;
  @Input() useQuery: boolean;
  @Input() firstName: string;

  constructor(private logger: LoggerService, private uiService: UIManagerService, private route: ActivatedRoute, private currencyPipe: CurrencyPipe) {
    this.log = this.logger.info('HeaderComponent');
    uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit(): void {
    if (this.useQuery === false) {
      this.log('FinancialHealthScoreComponent firstName', this.firstName);
    } else {
      this.route.queryParams.subscribe(params => {
        this.log('params', params);
        this.firstName = params['first_name'];
      });
    }
  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }
}
