import { Directive, HostListener, Input, ElementRef, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[maxCharacters]'
})
export class MaxCharactersDirective {

  @Input() maxChars: number;
  @Input() minChars: number;
  controlValue: string;

  constructor(private el: ElementRef, @Optional() private control: NgControl) {
    console.log('constructed InputTextFilterDirective');
  }

  @HostListener('input', ['$event']) onInput(event) {
    // console.log('constructed InputTextFilterDirective onInput', this.controlValue);
    this.getValue();
    this.checkString();
  }

  @HostListener('change') onChange() {
    // console.log('constructed InputTextFilterDirective onCHange');
    this.getValue();
    this.controlValue = this.controlValue.replace(/^\s+|\s+$/g, "");
    // console.log('this.controlValue replaced', this.controlValue);
    this.checkString();
  }

  private getValue() {
    if (this.control) {
      this.controlValue = this.control.value;
    } else {
      this.controlValue = (this.el.nativeElement as HTMLInputElement).value;
    }
  }

  private checkString() {
    // console.log('this.controlValue', this.controlValue);
    if (this.controlValue.length >= this.maxChars) {
      if (this.control) {
        this.control.control.setValue(this.controlValue.substring(0, this.maxChars));
      } else {
        (this.el.nativeElement as HTMLInputElement).value = this.controlValue.substring(0, this.maxChars);
      }
    } else if (this.controlValue.length === 0) {
      if (this.control) {
        this.control.control.setValue(this.controlValue);
      } else {
        (this.el.nativeElement as HTMLInputElement).value = this.controlValue;
      }
    }
  }
}
