<div class="summary-card" [ngClass]="{'_self-padding': padding}" id="{{section}}">
  <div class="summary-card__header">
    <div>
      <div class="summary-card__title heading2 section-color__secondary">{{title}}</div>
      <div class="summary-card__savings section-color__primary heading1">
        {{ totalSavings | currency: 'USD':'symbol-narrow':'1.0-0' }}
      </div>
      <div class="summary-card__date paragraph2">
        <i class="material-icons">today</i>
        {{ date | date: 'MMM y' }}
      </div>
    </div>
    <a class="summary-card__more heading2" [routerLink]="'/'+section">
      More <i class="material-icons">keyboard_arrow_right</i>
    </a>
  </div>
  <div class="summary-card__details">
    <div class="item">
      <div class="paragraph2  section-color__secondary">{{sectionTitles[0].title}}</div>
      <div class="sum section-color__{{sectionTitles[0].color}} paragraph1">
        {{ cashIn | currency: 'USD':'symbol-narrow':'1.0-0' }}
      </div>
    </div>
    <div class="item" *ngIf="sectionTitles[1].title !== ''">
      <div class="paragraph2 section-color__secondary">{{sectionTitles[1].title}}</div>
      <div class="sum section-color__{{sectionTitles[1].color}}  paragraph1">
        {{ cashOut | currency: 'USD':'symbol-narrow':'1.0-0' }}
      </div>
    </div>
    <div class="item" *ngIf="sectionTitles[2].title !== ''">
      <div class="paragraph2  section-color__secondary">{{sectionTitles[2].title}}</div>
      <div class="sum section-color__{{sectionTitles[2].color}}  paragraph1">
        {{ savings | currency: 'USD':'symbol-narrow':'1.0-0' }}
      </div>
    </div>
  </div>
  <app-savings-goal-summary [goal]="goal" [section]="section" (newGoal)="newGoal.next($event)">
  </app-savings-goal-summary>
</div>
