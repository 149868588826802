<div class="main-content-section">
  <div class="section-content">
    <form id="second-step-form" *ngIf="isLoaded">
      <div class="close-row"><i (click)="closeDialog()" class="material-icons close">close</i></div>
      <h1><strong>Payment.</strong> Upgrade your platform.</h1>

      <div class="user" *ngIf="!isEmployer">
        For {{user?.firstName}} {{user?.lastName}}, username {{user?.username}}.
      </div>
      <div class="user" *ngIf="isEmployer">
        For employer {{employer?.name}}
      </div>

      <h3>{{text1}} <span>{{text2}}</span></h3>

      <div *ngIf="isEmployer" fxLayout="row" fxLayoutAlign="space-around center" id="employee-number">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutGap="15px">
          <div class="label">NUMBER OF EMPLOYEES</div>
          <mat-form-field>
            <input matInput [formControl]="numberOfEmployeesControl">
          </mat-form-field>
        </div>
        <div class="spacer" [ngStyle.lt-sm]="{'display': 'none'}"></div>
      </div>

      <div *ngIf="!isEmployer">
        <app-individual-plan-information [product]="product" [price]="price" [discount]="discount"
          [billingCycle]="billingCycle" (discountCode)="onDiscountCode($event)" (submitOrder)="submitOrder($event)"
          [signupError]="signupError"></app-individual-plan-information>

      </div>
      <div *ngIf="isEmployer">
        <app-company-plan-information [product]="product" [price]="price" [discount]="discount"
          [billingCycle]="billingCycle" [annualSubscription]="annualSubscription"
          [monthlySubscription]="monthlySubscription" [numberOfEmployeesControl]="numberOfEmployeesControl"
          [numberOfEmployees]="numberOfEmployees" (discountCode)="onDiscountCode($event)"
          (submitOrder)="submitOrder($event)" [signupError]="signupError"></app-company-plan-information>
      </div>
      <div class="center">
        <a class="build" target="_blank" href="{{agreementUrl}}">{{agreementTitle}}</a><br><a class="build"
          target="_blank" href="{{privacyUrl}}">Privacy Policy</a><br>
      </div>
    </form>
  </div>
</div>
