import { Component, OnInit } from '@angular/core';
import { InputComponent } from '../input.component';
import { TypeDef, LoggerService, AccountTypeDef, MoneyAccount, MoneyService, Loan1Service } from '@rollit/shared/data';
import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * TODO handle different account format definitions.  Show slider in some cases.
 */
@Component({
  selector: 'lib-input-lender',
  templateUrl: './input-lender.component.html',
  styleUrls: ['./input-lender.component.scss'],
})
export class InputLenderComponent extends InputComponent implements OnInit {
  log: any;
  formControl: FormControl = new FormControl();
  validatorList: Array<any> = [];
  isValid: boolean = true;
  validationMessage: string;
  categories: string[];   // a slug for an account category: savings, lifestyle, investment, superannuation, property, mortgage, loan, creditcard
  accounts: any[] = [];
  selectedAccount: any = null;  // the account that has been selected

  constructor(
    private moneyService: MoneyService,
    private loanService: Loan1Service,
    private logger: LoggerService
  ) {
    super();
    this.log = this.logger.info("InputLenderComponent");
  }

  ngOnInit() { }

  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    this.log('handling type def', typeDef);

    const validators = [];
    const def: AccountTypeDef = typeDef as AccountTypeDef;
    this.categories = def.categories;

    this.formControl.setValidators(validators);
    this.validatorList.push(['required', 'Field is required']);

    this.loanService.getLenders().subscribe(lenders => {
      this.accounts = lenders;
      this.log('accounts', this.accounts);
      if (!this.selectedAccount) {
        this.log('setValue', this.accounts[1].code);
        this.formControl.setValue(this.accounts[1].code.toString());
        this.selectedAccount = this.accounts[1];
        this.log(this.formControl.value);
      }
    });

    /*
        this.moneyService.getAccounts({ type: this.categories }).subscribe(result => {
          let tempAccounts = [];
          if (this.categories.indexOf('investment') >= 0) {   // handle investment differently
            result.forEach((ac) => {
              if (ac.accountType == 'investment' && ac.accountSubtype !== "business") {
                // only include on-business accounts
                tempAccounts.push(ac);
              }
            });
          } else {
            tempAccounts = result;
          }
          this.accounts = tempAccounts;
          if (!this.selectedAccount) {
            this.log('setValue', this.accounts[0].id);
            this.formControl.setValue(this.accounts[0].id.toString());
            this.selectedAccount = this.accounts[0];
            this.log(this.formControl.value);
          }
        });
    */
  }


  /**
   * Handle existing numeric value for this input.
   * @param data 
   */
  handleData(data: any) {
    this.log('handleData', data);
    this.selectedAccount = data;
    if (this.selectedAccount) {
      this.formControl.setValue(this.selectedAccount.id.toString());
    }
  }

  /**
   * Provide the numeric value, which may have been modified by the user.
   */
  getData(): any {
    this.log('getData', this.selectedAccount);
    return this.selectedAccount;
  }

  hasError(type: any): boolean {
    // this.log('hasError');
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    // this.log('errors');
    return this.formControl.errors;
  }

  handleSelect(code) {
    this.accounts.forEach(ac => {
      this.log(ac.code, '===', code);
      if (ac.id == code) {
        this.log('match', ac);
        this.selectedAccount = ac;
        this.log(this.formControl.value);
      }
    });
    this.log('account selected', this.selectedAccount);
    this.log(this.formControl.value);

  }

  inputChange(event) {
    this.log('inputChange', event.target.value, this.formControl.value);

    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

}
