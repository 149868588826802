import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { InputComponent } from '../input.component';
import { TypeDef, SelectTypeDef, Option } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { ValidationErrors, FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'lib-input-button-group',
  templateUrl: './input-button-group.component.html',
  styleUrls: ['./input-button-group.component.scss'],
})
export class InputButtonGroupComponent extends InputComponent implements OnInit {
  log: any;
  formControl: FormControl = new FormControl();
  options: Option[];    // options to select from
  selected: any;

  constructor(
    private logger: LoggerService
  ) {
    super();
    this.log = this.logger.info("inputButtonGroupComp");
  }

  ngOnInit() { }

  handleTypeDef(typeDef: TypeDef) {
    // TODO use type definition to set params of this input, including validation.
    this.log("setting input def:", typeDef);
    const validators: ValidatorFn[] = [];
    const def: SelectTypeDef = typeDef as SelectTypeDef;
    // this.label = def.label;
    this.options = def.options;
    validators.push(Validators.required);
    this.formControl.setValidators(validators);

  }

  handleData(data: any) {
    this.log("handleData:", data);
    if (data === undefined) {
      this.log("setting default:", this.options[0].slug);
      this.selected = this.options[0].slug;
      this.formControl.setValue(this.options[0].slug);
    } else {
      this.log("setting data:", data);
      this.selected = data;
      this.formControl.setValue(this.selected);
    }

  }

  getData() {
    return this.selected;
  }


  hasError(type: any): boolean {
    return false;
  }

  errors(): ValidationErrors {
    return null;    // TODO return validation errors, e.g. if "required" and no option selected
  }

  get visibleOptions() {
    return this.options.filter(x => x.visible !== false);
  }
}
