<div class="main-content-section">
  <div id="section-content" class="section-content manage">
    <div class="form-panel" [ngClass]="{'no-height': fastLinkOpen,'desktop':isDesktop}">

      <!-- Navigation -->
      <div class="top-navigation">
        <div class="skip paragraph1" (click)="onReturnClick()">
          <em class="material-icons">close</em>
        </div>
      </div>

      <!-- Progress bar -->
      <div class="progress">
        <span class="paragraph3">{{currentStep + 1}} of 4</span>
        <div class="progress-line">
          <div class="percent" [ngStyle]="{'width': (100 / 4) * (currentStep +1) + '%' }"></div>
        </div>
      </div>

      <!-- Secure -->
      <div *ngIf="currentStep === 0" id="trusted-secure" data-step="0" class="section anim">
        <div class="content trusted-secure">
          <span class="title heading2">Connect accounts</span>
          <img class="yodlee-secure" src="/assets/icons/yodlee-secure.svg">
          <span class="title heading2">We use Yodlee to link<br>your accounts</span>
          <div class="trusted">
            <img src="/assets/icons/trusted.svg">
            <div class="secure">
              <span class="heading2 section-color__growth"><strong>Secure</strong></span>
              <span class="paragraph1 section-color__secondary">Bank grade security</span>
              <span class="heading2 section-color__growth"><strong>Trusted</strong></span>
              <span class="paragraph1 section-color__secondary">25M+ trusted users worldwide</span>

            </div>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          next
        </button>
      </div>

      <!-- Consent -->
      <div *ngIf="currentStep === 1" id="consent-period" data-step="1" class="section anim inNext">
        <div class="content consent-period">
          <span class="title heading2">Confirm consent period</span>
          <div class="button-group-holder">
            <mat-button-toggle-group name="consentPeriod" aria-label="Consent Period" (change)="consentPeriodChange($event)">
              <mat-button-toggle value="3">3<br>Months</mat-button-toggle>
              <mat-button-toggle value="6" checked>6<br>Months</mat-button-toggle>
              <mat-button-toggle value="12">12<br>Months</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <span class="paragraph1">Sharing Period</span>
          <span class="paragraph2">{{consentDateStart | date:'dd LLL yyyy'}} - {{consentDateEnd | date:'dd LLL yyyy'}}</span>
          <div class="copy">
            <span class="paragraph2">What happens if I want to withdraw consent?</span>
            <span class="paragraph2 section-color__secondary">We will delete your data when we no longer need it to
              improve your financial
              wellness, you stop sharing it, or if your consent expires as long as we have no legal obligation to retain
              it.</span>
            <span class="link paragraph2  section-color__secondary " (click)="openLink()">How we delete your data</span>
          </div>
        </div>
        <button (click)="nextStep()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          next
        </button>
      </div>

      <!-- Yodlee -->
      <div *ngIf="currentStep === 2" data-step="2" class="section anim inNext">

        <!-- div must have this id for Fastlink to work -->
        <div *ngIf="fastLinkOpen" id="container-fastlink" class="" [ngClass]="{'show':fastLinkReady}">

        </div>
        <div *ngIf="!isProd"> <span><br><br>To test the Yodlee integration search for the below bank and
            use the credentials:<br>
            Bank name: <strong>Dag Site</strong><br>
            UserName: <strong>YodTest.site16441.2</strong><br>
            Password: <strong>site16441.2</strong><br>
          </span>
        </div>
      </div>

      <!-- Linking accounts -->
      <div *ngIf="currentStep === 3" id="linking-accounts" data-step="3" class="section anim inNext">
        <div class="content trusted-secure">
          <span class="title heading2">Linking accounts</span>
          <img class="yodlee-secure" src="/assets/icons/yodlee-secure.svg">
          <span class="title heading2">This may take a minute<br> or two. We’ll notify<br> you when complete.</span>
          <div class="trusted">
            <img src="/assets/icons/trusted.svg">
            <div class="secure">
              <span class="status heading2 section-color__growth">
                <i class="material-icons">check</i>
                <span class="text"><strong>connection</strong></span>
              </span>
              <span class="status heading2 section-color__growth"><i class="material-icons">check</i><strong
                  class="text">Logging
                  in</strong></span>
              <span class="status heading2"
                [ngClass]="{'section-color__growth':accountsRefreshed,'section-color__secondary': !accountsRefreshed}"><i
                  *ngIf="accountsRefreshed" class="material-icons">check</i> <span *ngIf="!accountsRefreshed"
                  class="icon-loader"></span><strong class="text">Retrieving your info</strong></span>
            </div>
          </div>
        </div>
        <button (click)="onReturnClick()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
          Got it
        </button>
      </div>
    </div>

  </div>
</div>
