
import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { User, UserProfile } from '@rollit/shared/data';
import { Subscription as Sub, Observable } from 'rxjs';
import { LoggerService } from '@rollit/shared/data';
import { Subscription, Order } from '@rollit/shared/data';
import { PaymentMethod } from '@rollit/shared/data';
import { MatDialog } from '@angular/material/dialog';
import { PaymentComponent } from '../payment/payment.component';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { PlatformService } from '@rollit/shared/data';
import { Router } from '@angular/router';
import { MeService } from '@rollit/shared/data';
import { SubscriptionService } from '@rollit/shared/data';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

declare var Intercom; // defined by included Intercom javascript.
declare var build: any;
declare var buildDate: any;

/**
 * An individual user's subscription details, including payment details.
 */
@Component({
  selector: 'app-subscription-information',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss']
})
export class SubscriptionInformationComponent implements OnInit, OnDestroy {
  log: any;
  profile: UserProfile;
  me: User;
  subscriptions = new Sub();  // rxjs subscriptions to Observables.
  subscriptionsList = [];

  subscription: Subscription; // a user subscription to the Rollit service.
  paymentMethod: PaymentMethod;
  orders: Order[];
  currentOrder: Order;

  agreementUrl: string;
  agreementTitle: string;
  privacyUrl: string;

  hasPremiumFeature = null;
  showUpgrade = false;
  displayedColumns: string[] = ['id', 'date', 'amount', 'status', 'download'];

  /**
   * Constructor
   */
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private userService: MeService,
    private subscriptionService: SubscriptionService,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private logger: LoggerService,
    private router: Router,
    private keycloakService: KeycloakService,
    private http: HttpClient,
  ) {
    this.log = this.logger.info('profileSubscriptionComponent');

    this.showUpgrade = this.environment.embedded.inAppPurchases || !(this.platformService.embedded && this.platformService.isIos);

    this.agreementUrl = this.platformService.embedded ? this.environment.agreementUrl.app : this.environment.agreementUrl.web;
    this.agreementTitle = this.platformService.embedded ? 'Terms of Use' : 'Agreement';
    this.privacyUrl = this.environment.privacyUrl;

    // listen for user profile
    this.subscriptions.add(
      this.userService.profile$.subscribe(value => {
        this.profile = value;
      })
    );

    // listen for current user details
    this.subscriptions.add(
      this.userService.me$.subscribe(me => {
        console.log('userService.me$.subscribe');
        this.me = me;
        if (me) {
          this.chooseSubscription(me.subscriptions);
        }
      })
    );

    this.subscriptions.add(
      this.userService.hasPremiumFeature$().subscribe(
        hasPremiumFeature => {
          this.hasPremiumFeature = hasPremiumFeature;
        }
      )
    );

  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.userService.me$.subscribe(value => {
        this.me = value;
        if (value) {
          this.userService.getOrders().subscribe(result => {
            this.log('got orders', result);
            this.orders = result.data;
            this.currentOrder = result.data[0];
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Select the user's individual subscription if one exists
   */
  private chooseSubscription(subscriptions: Subscription[]) {
    if (subscriptions) {
      this.subscriptionsList = subscriptions;
      for (const s of subscriptions) {
        if (!s.numEmployees) {      // assume individual will not have a numEmployees value
          this.log("using subscription", s);
          this.subscription = s;
          break;
        }
      }
    }
    this.agreementUrl = this.isAppStoreSubscription
      ? this.environment.agreementUrl.app
      : this.environment.agreementUrl.web;

    this.paymentMethod = this.subscription ? this.subscription.paymentMethod : null;
  }

  get isAppStoreSubscription(): boolean {
    return this.subscription && (this.subscription.type === "AppStore" || this.subscription.type === "PlayStore");
  }

  trackById(index: number, item: any) {
    return item.id;
  }


  downloadPDF(url: string): Observable<any> {
    const httpOptions: Object = {
      responseType: 'blob'
    };
    return this.http.get(
      url, httpOptions)
      .pipe(
        tap(
          // Log the result or error
          data => console.log('You received data'),
          error => console.log(error)
        )
      );
  }

  downloadInvoice(id: number) {
    document.execCommand('ClearAuthenticationCache', false);
    this.userService.downloadInvoice(id).subscribe(result => {
      this.log(result);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        this.downloadPDF(result).subscribe(blob => {
          this.log(blob);
          const blobNew = new Blob([blob], { type: 'application/pdf' });
          window.navigator.msSaveOrOpenBlob(blobNew, 'invoice.pdf');
        });
      } else {
        window.open(result);
      }
    });
  }

  onUpgradeClick(event: Event) {
    this.log("UPGRADE");

    if (this.platformService.useInAppPurchases) {
      event.preventDefault();
      event.stopPropagation();
      this.platformService.emit("upgrade", "doit");
    }
    else {
      this.router.navigate(['/subscription/upgrade']);
    }
  }

  editPaymentMethod() {
    const dialogRef = this.dialog.open(PaymentComponent, {
      panelClass: 'full-width-dialog',
      data: {
        userId: this.me.id,
        subscription: this.subscription
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // Do stuff after the dialog has closed
      this.log('close', result);
      this.paymentMethod = result.paymentMethod;


    });
  }
}
