import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { LoggerService } from '../other/logger.service';
import { User, UserProfile, UserSearchResponse } from '../model/user';
import { Subscription } from '../model/subscription';



/**
 * UserService
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  apiUrl = this.environment.apiUrl;
  private log: any;

  /**
   * Constructor
   */
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private http: HttpClient,
    private logger: LoggerService
  ) {
    this.log = this.logger.info('userService');
    this.log('Constructing MeService');
  }


  public getUser(id: number): Observable<User> {
    const path = '/user/' + id;
    return this.http.get<User>(this.apiUrl + path);
  }

  public updateUser(user: User): Observable<User> {
    const path = '/user/' + user.id;
    return this.http.put<User>(this.apiUrl + path, user);
  }

  public createUser(user: User): Observable<User> {
    const path = '/user';
    return this.http.post<User>(this.apiUrl + path, user);
  }

  /**
   * Fetch users.  Optionally provide a string to filter by.
   * @param searchString
   */
  public searchUsers(searchString: string): Observable<UserSearchResponse> {
    const path = '/user';
    let params = new HttpParams();
    if (searchString) {
      params = params.set('q', searchString);
    }
    return this.http.get<UserSearchResponse>(this.apiUrl + path, { params: params });
  }


  public getUserProfile(id: number): Observable<UserProfile> {
    const path = '/user/' + id + '/profile';

    return this.http.get<User>(this.apiUrl + path);
  }

  public updateUserProfile(id: number, userProfile: UserProfile): Observable<UserProfile> {
    const path = '/user/' + id + '/profile';

    return this.http.put<User>(this.apiUrl + path, userProfile);
  }

  public getUserSubscriptions(userId: number): Observable<Subscription[]> {
    const path = '/user/' + userId + '/subscription';

    return this.http.get<Subscription[]>(this.apiUrl + path);
  }
}
