<mat-expansion-panel class="budget-accordion mat-elevation-z0" [hideToggle]="true">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title class="budget-accordion__panel-title" [ngStyle]="{
        color: budget.color
      }">
      <div class="budget-accordion__panel-title-first">
        <div class="budget-accordion__panel-expand">
          <i class="budget-accordion__panel-expand-icon material-icons">expand_more</i>
        </div>
        <i class="budget-accordion__icon material-icons {{ budget.name | lowercase }}"></i>

        <span class="budget-accordion__main-title paragraph1"><strong>{{
          budget.name
        }}</strong></span>

        <div class="progress-bar__bar">
          <div class="progress-bar__progress" [ngStyle]="{
              width: percentage + '%',
              background: budget.color
            }"></div>
        </div>
        <span class="budget-accordion__main-value paragraph1"><strong>{{
          budget.current | currency: 'AUD':'symbol-narrow':'1.0-0'
        }}</strong></span>
      </div>
      <div class="budget-accordion__panel-title-second paragraph3">
        {{ budget.budget | currency: 'AUD':'symbol-narrow':'1.0-0' }}
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container *ngIf="budget.items">
    <div class="budget-accordion__details" *ngFor="let detail of budget.items">
      <span class="paragraph2">{{ detail.title }}</span><span class="paragraph2">
        {{ detail.value | currency: 'AUD':'symbol-narrow':'1.0-0' }}</span>
    </div>
  </ng-container>
</mat-expansion-panel>
