import { Component, Input, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { UIManagerService, ItemLink } from '@rollit/shared/services';
import { Router, NavigationEnd, ChildActivationStart } from '@angular/router';
import { MeService } from '@rollit/shared/data';
import { FileService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var Intercom; // defined by included Intercom javascript.
@Component({
  selector: 'main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit, OnDestroy {

  @Input() isBlueBackground: boolean;
  log: any;
  topNavIsVisible: boolean;
  isDesktop: boolean;
  drawerNavigationIsActive: boolean;
  pageTitle: string;
  hasNotifications = false;
  intercomOpen = false;
  intercomClone: any;
  defaultPic = '/assets/images/default-profile.jpg';   // default profile pic
  profilePicUrl: string = '/assets/images/default-profile.jpg';
  itemLinks: ItemLink[] = this.uiService.mainLinks.default;
  private isDestroyed = new Subject();
  
  constructor(
    private uiService: UIManagerService,
    private router: Router,
    private userService: MeService,
    private _ngZone: NgZone,
    private fileService: FileService,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('mainNavigationComponent');
    uiService.topNavIsVisible.pipe(takeUntil(this.isDestroyed)).subscribe(value => this.topNavIsVisible = value);
    uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop => this.isDesktop = isDesktop);
    this.uiService.drawerNavigationIsOpen$.pipe(takeUntil(this.isDestroyed)).subscribe(isActive => this.drawerNavigationIsActive = isActive);
    router.events.pipe(takeUntil(this.isDestroyed)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.log('router', val);
        const pathSplit = val.url.toString().split("/");
        for (const link of this.itemLinks) {
          if (link.rLink === '/' + pathSplit[1]) {
            this.pageTitle = link.title;
            link['active'] = true;
          } else {
            link['active'] = false;
          }
        }
        // this.pageTitle = pathSplit[1];

        if (this.pageTitle === '') {
          this.pageTitle = 'Home';
        }
        if (pathSplit[1] === 'employer') {
          this.pageTitle = 'Employer';
        }
        this.log('pageTitle', this.pageTitle);
      }
    });
    if (this.topNavIsVisible) {
      userService.me$.pipe(takeUntil(this.isDestroyed)).subscribe(
        (user) => {
          if (user.picture && user.picture.id) {
            fileService.getFilePath(user.picture.id).subscribe(
              path => { this.profilePicUrl = path != null ? path : this.defaultPic; }
            );
          }
        }
      );
    }

  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.intercomOpen === true) {
      Intercom('hide');
      this.intercomOpen = false;
      // history.go(1);
    }
  }

  ngOnInit() {
    this.hasNotifications = false;
    this.intercomOpen = false;
    const self = this;
    Intercom('onUnreadCountChange', function (unreadCount) {
      if (unreadCount > 0) {
        self.hasNotifications = true;
      } else {
        self.hasNotifications = false;
      }
      self._ngZone.run(() => { });
    });
    Intercom('onShow', function () {
      self.log('onShow');
      if (self.topNavIsVisible) {
        if (document.getElementsByClassName('intercom-launcher-frame').length !== 0) {
          document.getElementsByClassName("intercom-launcher-frame")[0]['style'].display = 'block';
        }
      }

    });
    Intercom('onHide', function () {
      self.log('onHide ');
      if (self.topNavIsVisible) {
        document.getElementsByClassName("intercom-launcher-frame")[0]['style'].display = 'none';
      }
    });

  }

  onDrawerNavigationPressed(): void {
    this.uiService.drawerNavigationIsOpen = !this.drawerNavigationIsActive;
  }

  onHideIntercom() {
    this.intercomOpen = false;
    this._ngZone.run(() => { });
  }

  onProfileClick() {
    this.router.navigate(['/profile', { 'outlets': { 'list': ['profile-links'], 'detail': [this.isDesktop ? 'personal-information' : 'none'] } }]);
  }

  onChatClick() {
    event.preventDefault();
    event.stopPropagation();
    if (document.getElementsByClassName('intercom-messenger-frame').length === 0) {
      Intercom('show');
      this.intercomOpen = true;
    } else {
      Intercom('hide');
      this.intercomOpen = false;
    }
  }


}
