<div class="budget-screen">
  <div class="budget-screen__stepper">

    <div class="budget-screen__stepper-date">

      <mat-form-field style="
      visibility: hidden;
      height: 1px;
      overflow: hidden;
      padding: 0;
      margin: 0;
      line-height: 0;
      display: block;">
        <input matInput [matDatepicker]="picker" type="text" (focus)="picker.open()" [max]="maxDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
      </mat-form-field>
      <div class="event-icon" (click)="picker.open()"><i class="material-icons">event</i></div>


      <div class=" paragraph1">
        <strong> {{ month | date: 'MMMM y' }}</strong>
      </div>
      <div class="budget-screen__stepper-details paragraph2" [innerHtml]="dateDetails"></div>
    </div>

  </div>

  <mat-accordion class="budget-screen__accordion budget-accordion">
    <ng-container *ngFor="let element of budgets">
      <app-budget-element class="budget-accordion__element" [budget]="element"></app-budget-element>
    </ng-container>
  </mat-accordion>

  <!-- <div class="budget-screen__buttons">
    <button (click)="editBudget.next()" mat-stroked-button>Edit Budget</button><button (click)="transactions.next()"
      mat-stroked-button>
      Transactions</button><button (click)="accounts.next()" mat-stroked-button>Accounts</button>
  </div> -->
</div>
