import { Component, OnInit } from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { InputComponent } from '../input.component';
import { TypeDef, DateTypeDef, LoggerService } from '@rollit/shared/data';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '@rollit/shared/utils';

@Component({
  selector: 'lib-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class InputDateComponent extends InputComponent implements OnInit {
  today = new Date();
  formControl: FormControl = new FormControl();
  log: any;

  constructor(private logger: LoggerService) {
    super();
    this.log = this.logger.info("InputDateComponent");
  }

  ngOnInit() { }

  handleTypeDef(typeDef: TypeDef) {
    // TODO use type definition to set params of this input, including validation.
    const def: DateTypeDef = typeDef as DateTypeDef;
    const validators = [];
    // TODO set validators
    // let validators: ValidatorFn[] = [];
    // this.formControl.setValidators(validators);
    validators.push(Validators.required);
    this.formControl.setValidators(validators);
  }

  handleData(data: any) {
    this.log('handleData', data);
    if (data !== undefined) {
      const dataDate = new Date(data).toISOString();
      this.log('dataDate', dataDate);
      this.formControl.setValue(dataDate);
    }

  }

  inputChange(event) {

    //this.formControl.setValue(event.target.value);
    this.log('inputChange', event.target.value, this.formControl);
    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

  getData() {
    return this.formControl.value;
  }

  hasError(type: any): boolean {
    return this.formControl.hasError(type);
  }

  errors(): ValidationErrors {
    return this.formControl.errors;
  }

}
