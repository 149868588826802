import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIManagerService } from '@rollit/shared/services';

@Component({
  selector: 'lib-report-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  constructor(private uiService: UIManagerService,) {

    this.uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }

}