<mat-form-field class="input-date" appearance="outline">
  <mat-label *ngIf="prompt && multi" class="paragraph1">{{prompt}}</mat-label>
  <mat-error>
    Please enter a valid date DD/MM/YYYY
  </mat-error>
  <input matInput id="picker" [matDatepicker]="picker" type="text" [formControl]="formControl"
    [placeholder]="placeholder" (focus)="picker.open()">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker startView="multi-year" (selectedChanged)="inputChange($event)"></mat-datepicker>
</mat-form-field>
<div class="description paragraph2" [ngClass]="{'error':hasError('required'),'single': !multi}">{{ description }}</div>
