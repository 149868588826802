<svg version="1.1" baseProfile="full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 100 170">
  <g>
    <path fill="#0d71b9"
      d="M 49.999997,0 A 50,50 0 1 0 100,50.000001 50,50 0 0 0 49.999997,0 Z M 49.821132,10.789475 A 39.210526,39.210526 0 0 1 89.210525,50.000001 39.205264,39.205264 0 0 1 49.999997,89.210533 39.210631,39.210631 0 0 1 49.821132,10.789475 Z m 0.282689,9.079974 c -0.02055,-5.25e-4 -0.04111,0.0039 -0.06168,0.0041 -0.05619,-6.2e-5 -0.111616,0.0068 -0.167558,0.01234 -0.04399,0.0048 -0.08797,0.0071 -0.131579,0.01542 -0.08581,0.01531 -0.168808,0.04025 -0.25185,0.06887 -0.01236,0.0044 -0.02472,0.0077 -0.037,0.01234 -0.236023,0.08752 -0.458033,0.222661 -0.643504,0.41838 l -0.103826,0.109968 -27.546259,26.104031 c -0.681494,0.645808 -0.710571,1.713653 -0.06476,2.395146 l 2.965666,3.129111 c 0.64581,0.681501 1.713654,0.710575 2.395148,0.06475 l 2.359169,-2.235813 v 21.705381 a 2.4947369,2.4947369 0 0 0 2.494859,2.494859 h 37.552418 a 2.4947369,2.4947369 0 0 0 2.494867,-2.494859 V 50.041081 l 2.283104,2.163854 c 0.681492,0.645808 1.750359,0.616725 2.396175,-0.06475 l 2.964638,-3.129119 c 0.645808,-0.681493 0.616734,-1.749338 -0.06475,-2.395146 l -7.579157,-7.182356 v -9.949637 c 0,-1.163397 -0.936727,-2.100116 -2.100125,-2.100116 H 65.65276 c -1.163401,0 -2.100122,0.936719 -2.100122,2.100116 v 2.552429 L 51.41961,20.538652 51.288032,20.399878 c -0.187881,-0.198261 -0.413257,-0.335262 -0.652756,-0.422493 -0.0037,-0.0014 -0.0077,-0.0017 -0.01131,-0.0031 -0.093,-0.03314 -0.18721,-0.05869 -0.283717,-0.07504 -0.02637,-0.0048 -0.05263,-0.0068 -0.07916,-0.01028 -0.0526,-0.0064 -0.10436,-0.01812 -0.157278,-0.01953 z" />
    <path fill="#bdbdbd" mask="url(#homeMask)"
      d="M 49.999997,0 A 50,50 0 1 0 100,50.000001 50,50 0 0 0 49.999997,0 Z M 49.821132,10.789475 A 39.210526,39.210526 0 0 1 89.210525,50.000001 39.205264,39.205264 0 0 1 49.999997,89.210533 39.210631,39.210631 0 0 1 49.821132,10.789475 Z m 0.282689,9.079974 c -0.02055,-5.25e-4 -0.04111,0.0039 -0.06168,0.0041 -0.05619,-6.2e-5 -0.111616,0.0068 -0.167558,0.01234 -0.04399,0.0048 -0.08797,0.0071 -0.131579,0.01542 -0.08581,0.01531 -0.168808,0.04025 -0.25185,0.06887 -0.01236,0.0044 -0.02472,0.0077 -0.037,0.01234 -0.236023,0.08752 -0.458033,0.222661 -0.643504,0.41838 l -0.103826,0.109968 -27.546259,26.104031 c -0.681494,0.645808 -0.710571,1.713653 -0.06476,2.395146 l 2.965666,3.129111 c 0.64581,0.681501 1.713654,0.710575 2.395148,0.06475 l 2.359169,-2.235813 v 21.705381 a 2.4947369,2.4947369 0 0 0 2.494859,2.494859 h 37.552418 a 2.4947369,2.4947369 0 0 0 2.494867,-2.494859 V 50.041081 l 2.283104,2.163854 c 0.681492,0.645808 1.750359,0.616725 2.396175,-0.06475 l 2.964638,-3.129119 c 0.645808,-0.681493 0.616734,-1.749338 -0.06475,-2.395146 l -7.579157,-7.182356 v -9.949637 c 0,-1.163397 -0.936727,-2.100116 -2.100125,-2.100116 H 65.65276 c -1.163401,0 -2.100122,0.936719 -2.100122,2.100116 v 2.552429 L 51.41961,20.538652 51.288032,20.399878 c -0.187881,-0.198261 -0.413257,-0.335262 -0.652756,-0.422493 -0.0037,-0.0014 -0.0077,-0.0017 -0.01131,-0.0031 -0.093,-0.03314 -0.18721,-0.05869 -0.283717,-0.07504 -0.02637,-0.0048 -0.05263,-0.0068 -0.07916,-0.01028 -0.0526,-0.0064 -0.10436,-0.01812 -0.157278,-0.01953 z" />
    <mask id="homeMask">
      <rect x="0" y="0" width="100" height="100" fill="black" />
      <rect x="0" y="0" width="100" attr.height="{{assetsHomeOwnershipPercentage}}" fill="white" />
    </mask>
  </g>
  <foreignObject height="40" width="100%" x="0" y="109">
    <xhtml:div class="title">
      {{assetsHomeOwnershipResult}}
    </xhtml:div>
  </foreignObject>
</svg>
