import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '@rollit/shared/data';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() path: string;
  elements: Array<{ label: string; url: string }> = [];
  log: any;
  activeRoute;


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private logger: LoggerService, private _location: Location) {
    this.log = this.logger.info('BreadcrumbComponent');
    this.activeRoute = activatedRoute;
  }

  ngOnInit(): void {
    this.createRouteElements();
  }

  createRouteElements() {

    this.elements = [];
    this.log('this.router');
    this.log('ActivatedRoute', this.activatedRoute);
    const rawElements = this.router.url.split('/');
    rawElements.shift();
    let currentPath = '';
    for (const element of rawElements) {
      currentPath += '/' + element;
      this.elements.push({ label: element, url: currentPath });
    }

    this.log(this.elements);
  }

  onBackClick(event) {
    if (this.path) {
      this.router.navigate([this.path]);
    }
    this._location.back()
  }

}
