import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export enum GoalColour {
  Blue = 'blue',
  Red = 'red',
}

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalComponent {
  @Input() header: string;
  @Input() description: string;
  @Input() icon: string;
  @Output() closed = new EventEmitter();

  _colour: GoalColour;
  @Input() set colour(value: string) {
    console.log(value, GoalColour[value], GoalColour[value] in GoalColour);
    if (value in GoalColour) {
      // If colour exists in Enum set the value
      this._colour = GoalColour[value];
    } else {
      // Else default to blue
      this._colour = GoalColour.Blue;
    }
  }

  onClosed() {
    this.closed.next();
  }
}
