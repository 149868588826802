import { Component, OnInit, Input, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { InputComponent } from '../input.component';
import { TypeDef, CompositeTypeDef, Answer, ValueType } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'lib-input-multi',
  templateUrl: './input-multi.component.html',
  styleUrls: ['./input-multi.component.scss'],
})
export class InputMultiComponent extends InputComponent implements OnInit {

  answers: Answer[];

  log: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private logger: LoggerService,
    private elRef: ElementRef
  ) {
    super();
    this.log = this.logger.info("inputMultiComponent");
  }

  ngOnInit() {
  }


  /**
   * use type definition to set params of this input, including validation.
   */
  handleTypeDef(typeDef: TypeDef) {
    const def: CompositeTypeDef = typeDef as CompositeTypeDef;
    this.answers = def.fields;
  }

  handleData(data: any) {
    // TODO populate sub-components
  }

  getData() {
    return null;
  }

  hasError(type: any): boolean {
    return false;
  }

  errors(): ValidationErrors {
    return {};
  }

}
