import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { SCSS_VARS } from '@rollit/shared';
import { Logger } from './logger.service';

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {
    private debug: boolean = false;
    private scopeArray: Array<string> = [];
    isProdMode = this.environment.production;

    constructor(@Inject(APP_ENVIRONMENT) private environment: any) {
        let debugParam: string = '';
        if (window && window.location.search) {
            console.log('window.location.search', window.location.search);
            const urlParams = new URLSearchParams(window.location.search);
            debugParam = urlParams.get('debug');
            const scopeParam = urlParams.get('scope');
            if (scopeParam) {
                this.scopeArray = scopeParam.split(':');
            }
        }
        console.log('debugParam', debugParam);
        if ((this.isProdMode && debugParam === 'true') || !this.isProdMode) {
            console.log('Debug is true');
            this.debug = true;
        } else {
            this.debug = false;
        }
    }

    info(scopeString: String) {
        if (this.debug === true) {
            let log = false;
            if (this.scopeArray.length > 0) {
                for (const scope in this.scopeArray) {
                    if (this.scopeArray[scope] === scopeString) {
                        log = true;
                    }
                }
            } else {
                log = true;
            }
            if (log === true) {
                let colorString = SCSS_VARS['$highlight'];
                return console.info.bind(console, '%c' + scopeString + ':', 'color: ' + colorString);
            } else {
                return noop;
            }
        } else {
            return noop;
        }
    }

    warn(scopeString: String) {
        if (this.debug === true) {
            let log = false;
            if (this.scopeArray.length > 0) {
                for (const scope in this.scopeArray) {
                    if (this.scopeArray[scope] === scopeString) {
                        log = true;
                    }
                }
            } else {
                log = true;
            }
            if (log === true) {
                return console.warn.bind(console, scopeString + ':');
            } else {
                return noop;
            }
        } else {
            return noop;
        }
    }

    error(scopeString: String) {
        if (this.debug === true) {
            let log = false;
            if (this.scopeArray.length > 0) {
                for (const scope in this.scopeArray) {
                    if (this.scopeArray[scope] === scopeString) {
                        log = true;
                    }
                }
            } else {
                log = true;
            }
            if (log === true) {
                return console.error.bind(console, scopeString + ':');
            } else {
                return noop;
            }
        } else {
            return noop;
        }
    }
}
