<div class="main-content-section">
  <div class="section-content">
    <app-top-menu *ngIf="isDesktop"></app-top-menu>
    <div class="rollit-grid">
      <div class="rollit-grid-3">



        <div *ngIf="!isDesktop"
             class="close"
             routerLink="/finances/position"><em class="material-icons">close</em></div>



        <div [ngClass]="!isDesktop  ? '':'group-panel'">
          <img *ngIf="!isDesktop"
               class="logo"
               alt="Hey Coach logo"
               src="/assets/images/hc-logo.png" />

          <div class="welcome" [ngClass]="!isDesktop  ? '':'desktop'">
            <div class="copy header heading1" [ngClass]="!isDesktop  ? '':'desktop'">
              Hello <span *ngIf="me?.firstName">{{me.firstName}}</span>
            </div>
            <div *ngFor="let p of progress; index as i" >
              <div *ngIf="i < 1 || isDesktop || showMoreProgress" class="copy paragraph1">
                <strong>
                  <lib-handlebars [template]="p.heading" [context]="p.values"></lib-handlebars>
                </strong>
              </div>
              <div *ngIf="i < 1 || isDesktop || showMoreProgress" class="copy paragraph1 progress">
                <lib-handlebars [template]="p.content" [context]="p.values"></lib-handlebars>
              </div>
            </div>
            <div *ngIf="!isDesktop && progress?.length > 1">
              <a *ngIf="!showMoreProgress" class="show" (click)="expandProgress()">Show more</a>
              <a *ngIf="showMoreProgress" class="show" (click)="expandProgress()">Show less</a>
            </div>
        
          </div>

          <ng-container *ngIf="nudges.length > 0 && ready">

            <div class="nudge-container" [ngClass]="!isDesktop  ? '':'desktop'">
              <div class="header-holder">
                <div class="copy header heading1" [ngClass]="!isDesktop  ? '':'desktop'">Your actions</div>
                <img *ngIf="isDesktop"
                     class="logo"
                     style="float:right;"
                     [ngClass]="!isDesktop  ? '':'desktop'"
                     alt="Hey Coach logo"
                     src="/assets/images/hc-logo.png" />
              </div>
              <app-nudge-expandable *ngFor="let nudge of nudges; let i = index"
                                    [id]="i"
                                    [activeItem]="i === activeItem"
                                    [nudge]="nudge"
                                    [isDesktop]="isDesktop"
                                    (closed)="onClosed(nudge)"
                                    (buttonPressed)="onButtonPressed(nudge)"
                                    (click)="i !== activeItem && expandItem($event, i)"
                                    [ngClass]="{'active':i === activeItem}">
              </app-nudge-expandable>
              <div class="anchor-container paragraph2">
                <a [href]="disclaimerUrl" target="_about">General advice disclaimer</a>
              </div>
    
            </div>
          </ng-container>

          <div *ngIf="nudges.length === 0 && ready" class="nudge-container" [ngClass]="!isDesktop  ? '':'desktop'">
            <div class="copy header heading1" [ngClass]="!isDesktop  ? '':'desktop'">Your actions</div>
            <div class="copy heading2">New actions coming soon
            </div>
            <span class="copy paragraph1">Hey Coach searches for new opportunities to help you take control and make
              financial moves with confidence. Check back in tomorrow for new insights to action.
            </span>
            <img class="image"
                 alt="Nudge action"
                 src="/assets/images/example/nudges-example-2.png" />
          </div>
        </div>
      </div>
    </div>






  </div>

</div>