<div class="holder" [ngClass]="{'desktop':isDesktop}">

  <div class="bar-chart">

    <app-bar-chart-time *ngIf="projectionChartData.length > 0 && inView" class="chart"
      [seriesData]="projectionChartData" [seriesCategories]="projectionChartCategories"
      [title]="acc.accountSubtype.charAt(0).toUpperCase() + acc.accountSubtype.substr(1).toLowerCase() +' projections'"
      (graphClicked)="graphClicked($event[0], $event[1], $event[2])" [showExpand]="false" [smallTitle]="true">
    </app-bar-chart-time>

    <div *ngIf="!inView" class="heading1 heading2">
      {{acc.accountSubtype.charAt(0).toUpperCase() + acc.accountSubtype.substr(1).toLowerCase() +' projections'}}</div>
  </div>


  <div class="details">

    <div class="titleGroup">
      <app-title-group [details]="titleGroupDetails"></app-title-group>
    </div>

    <div class="detail-list" *ngIf="acc && acc.accountSubtype !== 'business'">

      <div class="list-item">
        <span class="paragraph2">Portfolio value</span>
        <span class="paragraph2">{{acc?.currentBalance  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Annual return</span>
        <span class="paragraph2">{{acc?.annualReturn | percent:'1.2'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Portfolio contribution (annual)</span>
        <span class="paragraph2">{{acc?.contributions * 12  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>
      <div class="table-holder">
        <app-table *ngIf="investmentTableData.length > 0" [data]="investmentTableData" (removeElement)="remove($event)">
        </app-table>
      </div>
    </div>

    <div class="detail-list" *ngIf="acc && acc.accountSubtype === 'business'">

      <div class="list-item">
        <span class="paragraph2">Earnings (before tax)</span>
        <span
          class="paragraph2">{{acc?.properties?.business?.annualEarnings  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Multiple of earnings</span>
        <span class="paragraph2">{{acc?.properties?.business?.salePriceMultiple}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Your shareholdings</span>
        <span class="paragraph2">{{acc?.properties?.business?.percentageOwnership   | percent:'1.2'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Business loans</span>
        <span
          class="paragraph2">{{acc?.properties?.business?.loansBalance  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Revenue growth</span>
        <span class="paragraph2">{{acc?.properties?.business?.earningsGrowth  | percent:'1.2'}}</span>
      </div>

    </div>
  </div>
</div>
