import { Injectable } from '@angular/core';
import { QuestionBase } from './question-base';
import { DropdownQuestion } from './question-dropdown';
import { TextboxQuestion } from './question-textbox';
import { DateQuestion } from './question-date';
import { RadioQuestion } from './question-radio';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { BillingCycle, Signup, CustomerType } from '@rollit/shared/data';
import { map } from 'rxjs/operators';
import { LocationSearchQuestion } from './question-location-search';
import { CheckboxQuestion } from './question-checkbox';
import { SubscriptionService } from '@rollit/shared/data';

@Injectable()
export class QuestionService {

  displayIndividualFormGroup = new ReplaySubject<boolean>(1);
  displayBusinessFormGroup = new ReplaySubject<boolean>(1);
  displayCompanyDetailsFormGroup = new ReplaySubject<boolean>(1);
  displayTradingNameFormGroup = new ReplaySubject<boolean>(1);
  // displaySunsuperDefaultFormGroup = new ReplaySubject<boolean>(1);

  signupProgress$: ReplaySubject<Signup> = new ReplaySubject<Signup>(1);
  signupProgress: Signup;

  // Used in the tooltip displayed to alert the user which fields are missing
  displayNameMappings: {[key: string]: string} = {
    type: 'Type',
    tsandcs: 'Terms and conditions',
    email: 'Email',
    title: 'Title',
    firstName: 'First name',
    lastName: 'Last name',
    sex: 'Sex',
    mobile: 'Mobile',
    dob: 'Date of birth',
    address1: 'Street name and number',
    postcode: 'Postcode',
    address2: 'Suburb or town',
    address3: 'State or territory',
    employeesNumber: 'Approximate number of employees',
    abn: 'Company ABN',
    registeredName: 'Registered name',
    differentTradingName: 'Different trading name yes/no',
    tradingName: 'Trading name'
  };

  constructor(private subscriptionService: SubscriptionService) {
    // get the signup attached to the current user.
    this.subscriptionService.getMySignup().subscribe((signupProgress: Signup) => {
      if (signupProgress) {
        if (!signupProgress.type || signupProgress.type === CustomerType.individual) {
          this.displayIndividualFormGroup.next(true);
          this.displayBusinessFormGroup.next(false);
          this.displayCompanyDetailsFormGroup.next(false);
          this.displayTradingNameFormGroup.next(false);
        } else if (signupProgress.type === CustomerType.employer) {
          this.displayIndividualFormGroup.next(false);
          this.displayBusinessFormGroup.next(true);
          this.displayCompanyDetailsFormGroup.next(true);
          if (signupProgress.data && signupProgress.data['differentTradingName'] && signupProgress.data['differentTradingName'] === 'yes') {
            this.displayTradingNameFormGroup.next(true);
          } else {
            this.displayTradingNameFormGroup.next(false);
          }
        }
        this.signupProgress = signupProgress;
        this.signupProgress$.next(signupProgress);
      } else {
        this.displayIndividualFormGroup.next(true);
        this.displayBusinessFormGroup.next(false);
        this.displayCompanyDetailsFormGroup.next(false);
        this.displayTradingNameFormGroup.next(false);
        signupProgress = this.subscriptionService.newSignup();
        this.signupProgress = signupProgress;
        this.signupProgress$.next(signupProgress);
      }
    });
  }

  getCoreQuestions(): Observable<QuestionBase<any>[]> {

    return this.signupProgress$.pipe(map(signupProgress => {

      signupProgress.data = signupProgress.data || {};

      const coreQuestions: QuestionBase<any>[] = [

        new RadioQuestion({
          key: 'type',
          label: '',
          required: true,
          bigRadio: true,
          wrapRadio: true,
          options: [
            {key: CustomerType.individual, value: 'I\'m an individual'},
            {key: CustomerType.employer, value: 'We\'re a company'},
          ],
          value: signupProgress.type || CustomerType.individual,
          order: 1
        }),

        new CheckboxQuestion({
          key: 'tsandcs',
          label: 'Read and accept the <a href="https://intercom.help/rollitsuper/privacy-and-terms/terms-of-use" target="_rolit_tou">terms and conditions</a>'
          + ', then submit to proceed to payment',
          required: true,
          isChecked: false,
          order: 2,
          validators: [Validators.requiredTrue]
        })

      ];

      return coreQuestions.sort((a, b) => a.order - b.order);
    }));
  }

  getIndividualQuestions(): Observable<QuestionBase<any>[]> {

    return this.signupProgress$.pipe(map(signupProgress => {

      signupProgress.data = signupProgress.data || {};

      const individualQuestions: QuestionBase<any>[] = [

        new TextboxQuestion({
          key: 'email',
          label: 'Email',
          required: true,
          order: 1,
          value: signupProgress.user['email'] || '',
          validators: [Validators.email]
        }),

        new DropdownQuestion({
          key: 'title',
          label: 'Title',
          required: true,
          options: [
            {key: 'mr',  value: 'MR'},
            {key: 'mrs',  value: 'MRS'},
            {key: 'miss',   value: 'MISS'},
            {key: 'ms',   value: 'MS'},
            {key: 'dr',   value: 'DR'},
            {key: 'prof',   value: 'PROF'},
          ],
          value: signupProgress.user.profile['title'] || '',
          order: 2
        }),

        new TextboxQuestion({
          key: 'firstName',
          label: 'First name',
          required: true,
          value: signupProgress.user['firstName'] || '',
          order: 3
        }),

        new TextboxQuestion({
          key: 'lastName',
          label: 'Last name',
          required: true,
          value: signupProgress.user['lastName'] || '',
          order: 4
        }),

        new DropdownQuestion({
          key: 'sex',
          label: 'Sex',
          required: true,
          options: [
            {key: 'Female',  value: 'Female'},
            {key: 'Male',  value: 'Male'},
            {key: 'Other',   value: 'Intersex or intermediate'},
          ],
          value: signupProgress.user.profile['sex'] || '',
          order: 5
        }),

        new TextboxQuestion({
          key: 'mobile',
          label: 'Mobile phone number',
          required: true,
          value: signupProgress.user.profile['phone'] || '',
          order: 6
        }),

        new DateQuestion({
          key: 'dob',
          label: 'Date of birth (dd/MM/YYYY)',
          required: true,
          value: signupProgress.user.profile['dob'] || '',
          order: 7
        }),

        // new TextboxQuestion({ // Removed temporarily for pilot
        //   key: 'tfn',
        //   label: 'Tax File Number (TFN)',
        //   required: true,
        //   order: 8
        // }),

        new LocationSearchQuestion({
          key: 'address1',
          label: 'Street name and number',
          required: true,
          value: signupProgress.user.profile.homeAddress['street1'] || '',
          order: 8
        }),

        new TextboxQuestion({
          key: 'postcode',
          label: 'Postcode',
          required: true,
          value: signupProgress.user.profile.homeAddress['postcode'] || '',
          order: 9
        }),

        new TextboxQuestion({
          key: 'address2',
          label: 'Suburb or town',
          required: true,
          value: signupProgress.user.profile.homeAddress['town'] || '',
          order: 10
        }),

        new TextboxQuestion({
          key: 'address3',
          label: 'State or territory',
          required: true,
          value: signupProgress.user.profile.homeAddress['state'] || '',
          order: 11
        })

      ];

      return individualQuestions.sort((a, b) => a.order - b.order);
    }));

  }

  getBusinessQuestions(): Observable<QuestionBase<any>[]> {

    return this.signupProgress$.pipe(map(signupProgress => {

      signupProgress.data = signupProgress.data || {};

      const businessQuestions: QuestionBase<any>[] = [

        new TextboxQuestion({
          key: 'email',
          label: 'Representative email',
          required: true,
          value: signupProgress.user['email'] || '',
          order: 1
        }),

        new DropdownQuestion({
          key: 'title',
          label: 'Representative title',
          required: true,
          options: [
            {key: 'mr',  value: 'MR'},
            {key: 'mrs',  value: 'MRS'},
            {key: 'miss',   value: 'MISS'},
            {key: 'ms',   value: 'MS'},
            {key: 'dr',   value: 'DR'},
            {key: 'prof',   value: 'PROF'},
          ],
          value: signupProgress.user.profile['title'] || '',
          order: 2
        }),

        new TextboxQuestion({
          key: 'firstName',
          label: 'Representative first name',
          required: true,
          value: signupProgress.user['firstName'] || '',
          order: 3
        }),

        new TextboxQuestion({
          key: 'lastName',
          label: 'Representative last name',
          required: true,
          value: signupProgress.user['lastName'] || '',
          order: 4
        }),
      ];

      return businessQuestions.sort((a, b) => a.order - b.order);

    }));
  }

    getCompanyDetailsQuestions(): Observable<QuestionBase<any>[]> {

      return this.signupProgress$.pipe(map(signupProgress => {

        signupProgress.data = signupProgress.data || {};

        const companyDetailsQuestions: QuestionBase<any>[] = [

          new LocationSearchQuestion({
            key: 'address1',
            label: 'Street name and number',
            required: true,
            value: signupProgress.employer.address['street1'] || '',
            order: 1
          }),

          new TextboxQuestion({
            key: 'postcode',
            label: 'Postcode',
            required: true,
            value: signupProgress.employer.address['postcode'] || '',
            order: 2
          }),

          new TextboxQuestion({
            key: 'address2',
            label: 'Suburb or town',
            required: true,
            value: signupProgress.employer.address['town'] || '',
            order: 3
          }),

          new TextboxQuestion({
            key: 'address3',
            label: 'State or territory',
            required: true,
            value: signupProgress.employer.address['state'] || '',
            order: 4
          }),

          new TextboxQuestion({
            key: 'employeesNumber',
            label: 'Approximate number of employees',
            required: true,
            value: signupProgress.employer['numEmployees'] || '',
            order: 5
          }),

          // new RadioQuestion({ // Removed temporarily for pilot
          //   key: 'sunsuperDefault',
          //   label: 'Do you want to use Sunsuper as the default super fund for employees that don\'t select their own super fund?',
          //   options: [
          //     {key: 'yes',  value: 'Yes'},
          //     {key: 'no',  value: 'No'},
          //   ],
          //   order: 6
          // }),

          new TextboxQuestion({
            key: 'abn',
            label: 'Company ABN',
            required: true,
            value: signupProgress.employer['abn'] || '',
            order: 6
          }),

          new TextboxQuestion({
            key: 'registeredName',
            label: 'Registered business name',
            required: true,
            value: signupProgress.employer['name'] || '',
            order: 7
          }),

          new RadioQuestion({
            key: 'differentTradingName',
            label: 'Do you have a different trading name?',
            required: true,
            options: [
              {key: 'yes', value: 'Yes'},
              {key: 'no', value: 'No'},
            ],
            value: signupProgress.data['differentTradingName'] || '',
            // Make sure this question is at the bottom of this group since the tradingNameQuestions group contains the follow up question
            // and appears under this question's group in the DOM
            order: 100
          })

        ];

        return companyDetailsQuestions.sort((a, b) => a.order - b.order);
      }));
  }

  getTradingNameQuestions(): Observable<QuestionBase<any>[]> {
    return this.signupProgress$.pipe(map(signupProgress => {

      signupProgress.data = signupProgress.data || {};

      return [

        new TextboxQuestion({
          key: 'tradingName',
          label: 'Trading name',
          required: true,
          value: signupProgress.employer['tradingName'] || '',
          order: 1
        })

      ];
    }));

  }

  getBillingCycleQuestions(): Observable<QuestionBase<any>[]> {

    return this.signupProgress$.pipe(map(signupProgress => {

      signupProgress.data = signupProgress.data || {};

      return [

        new RadioQuestion({
          key: 'billingCycle',
          label: '',
          required: true,
          bigRadio: true,
          options: [
            {key: BillingCycle.monthly, value: 'BILLED MONTHLY'},
            {key: BillingCycle.annually, value: 'BILLED ANNUALLY'},
          ],
          value: signupProgress.data['billingCycle'] || BillingCycle.annually,
          order: 1
        })

      ];

    }));
  }

  // Convert questions into form group
  toFormGroup(questions: QuestionBase<any>[] ) {
    const group: any = {};

    for (const question of questions) {
      group[question.key] = new FormControl(question.value || '', question.validators);
    }
    return new FormGroup(group);
  }

}
