import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superannuation-fund-info',
  templateUrl: './superannuation-fund-info.component.html',
  styleUrls: ['./superannuation-fund-info.component.scss']
})
export class SuperannuationFundInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
