import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BillingCycle, DiscountCode, Order, Price, Product, CustomerType, TrialCode } from '@rollit/shared/data';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { MeService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EmployerService } from '@rollit/shared/data';
import { PlatformService } from '@rollit/shared/data';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { User } from '@rollit/shared/data';
import { Employer } from '@rollit/shared/data';
import { SubscriptionService } from '@rollit/shared/data';

declare var Intercom; // defined by included Intercom javascript.

/**
 * A form for a user to upgrade their subscription.
 *
 */
@Component({
  selector: 'app-upgrade-form',
  templateUrl: './upgrade-form.component.html',
  styleUrls: ['./upgrade-form.component.scss']
})
export class UpgradeFormComponent implements OnInit, OnDestroy {
  private log: any;
  customerType: CustomerType;
  billingCycle: BillingCycle;
  numberOfEmployees: number;
  product: Product;  // product based on type, billingCycle and number of employees.
  price: Price;
  trial: TrialCode;
  discount: DiscountCode;
  discountCode: string;
  user: User;
  employer: Employer;

  agreementUrl: string;
  agreementTitle: string;
  privacyUrl: string;

  billingCycleFormGroup: FormGroup;

  annualSubscription: number;
  monthlySubscription: number;
  annualCostPerEmployee: number;
  monthlyCostPerEmployee: number;
  numberOfEmployeesControl = new FormControl(); // for 'Employer' type only

  mediaSizeIsDesktop: boolean;
  billingCycleEnum = BillingCycle;

  isLoaded: boolean;
  isEmployer: boolean;
  signupError: boolean = false;

  text1 = "";
  text2 = "";
  planPrice = '$5.00+gst';    // TODO make this dynamic
  termsUrl: string;

  private isDestroyed = new Subject();


  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private ss: SubscriptionService,
    private userService: MeService,
    private employerService: EmployerService,
    private notificationService: NotificationService,
    private platformService: PlatformService,
    private uiService: UIManagerService,
    private route: ActivatedRoute,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('paymentFormComponent');
    this.termsUrl = this.platformService.embedded ? this.environment.agreementUrl.app : this.environment.agreementUrl.web;

    this.agreementUrl = this.platformService.embedded ? this.environment.agreementUrl.app : this.environment.agreementUrl.web;
    this.agreementTitle = this.platformService.embedded ? 'Terms of Use' : 'Agreement';
    this.privacyUrl = this.environment.privacyUrl;

    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(mediaSizeIsDesktop =>
      this.mediaSizeIsDesktop = mediaSizeIsDesktop
    );
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.appBackgroundIsPrimary.next(true);

    this.billingCycle = BillingCycle.monthly;

    // determine whether employer or individual (use path params)
    this.route.params.pipe(takeUntil(this.isDestroyed)).subscribe((params) => {
      this.log('Params', params);

      const employerId = params['employerId'];

      this.log('Upgrade employerId', employerId);

      if (employerId) {
        this.customerType = CustomerType.employer;
        this.isEmployer = true;
        this.employerService.getEmployer(employerId).subscribe(employer => {
          this.employerService.getEmployeeCounts(employerId).subscribe(result => {
            this.numberOfEmployees = result.active + result.inactive;
            this.numberOfEmployeesControl.setValue(result.active + result.inactive);
            this.employer = employer;
            this.checkProduct();    // get price of product
            this.updateText();
            this.isLoaded = true;
          });
        });
      }
      else {          // for individual upgrade
        this.customerType = CustomerType.individual;
        this.userService.me$.pipe(takeUntil(this.isDestroyed)).subscribe(me => {
          this.user = me;
          this.checkProduct();    // get price of product
          this.updateText();
          this.isLoaded = true;
        });
      }
    });


    // TODO get this from employer
    // this.numberOfEmployeesControl.setValue(progress.employer['numEmployees']);

    Intercom('trackEvent', 'web-app-signup-payment-form-visit');
  }

  ngOnInit() {
    this.numberOfEmployeesControl.valueChanges.subscribe(numberOfEmployees => {
      if (numberOfEmployees) {
        this.numberOfEmployees = parseInt(numberOfEmployees, 10);
        this.calculateAnnualCost(this.numberOfEmployees);
        this.checkProduct();    // get price for product
      } else {
        // Input value is blank, so reset subscription values
        this.numberOfEmployees = null;
        this.annualSubscription = null;
        this.monthlySubscription = null;
        this.annualCostPerEmployee = null;
        this.monthlyCostPerEmployee = null;
      }
    });

  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.appBackgroundIsPrimary.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  updateText() {
    this.text1 = "Clear and simple plan.";
    this.text2 = this.planPrice + " per user, per month billed over 12 months.";
  }

  onDiscountCode(code: string) {
    this.log('got discount code', code);
    this.discountCode = code;

    const order: Order = {
      items: [{
        product: this.product
      }],
      trialCode: this.trial,
      discountCode: this.discountCode ? { code: this.discountCode } : null,
    };

    this.checkOrder(order);      // calculate price of order.
  }

  /**
   * Calculate price of product.
   *
   * If product does not yet exist, create a product instance based on subscription
   * type and selected billing cycle.
   */
  checkProduct() {
    let product = this.product;
    if (!product) {
      product = {};
      product.id = this.ss.getProductId(this.customerType, 'upgrade', this.billingCycle);
      this.product = product;
      this.log('Created product', product);
    }
    if (this.customerType === CustomerType.employer) {
      // if (this.numberOfEmployees) {
      product.attributes = [];
      product.attributes.push({ name: 'Number of Employees', value: this.numberOfEmployees });
      // }
    }

    // get prices
    const order: Order = {
      items: [{ product: this.product }],
      trialCode: this.trial,
      discountCode: this.discountCode ? { code: this.discountCode } : null,
    };

    this.checkOrder(order);      // calculate price of order.
  }

  calculateAnnualCost(numberOfEmployees: number) {
    this.annualCostPerEmployee = null;
    this.monthlyCostPerEmployee = null;
    switch (true) {
      case numberOfEmployees === 1:
        this.annualCostPerEmployee = 20;
        this.monthlyCostPerEmployee = 25;
        break;
      case numberOfEmployees > 1 && numberOfEmployees <= 20:
        this.annualCostPerEmployee = 17.5;
        this.monthlyCostPerEmployee = 20;
        break;
      case numberOfEmployees > 20 && numberOfEmployees <= 50:
        this.annualCostPerEmployee = 15;
        this.monthlyCostPerEmployee = 17.5;
        break;
      case numberOfEmployees > 50 && numberOfEmployees <= 250:
        this.annualCostPerEmployee = 12.5;
        this.monthlyCostPerEmployee = 15;
        break;
      case numberOfEmployees > 250 && numberOfEmployees <= 500:
        this.annualCostPerEmployee = 10;
        this.monthlyCostPerEmployee = 12.5;
        break;
      case numberOfEmployees > 500 && numberOfEmployees <= 1000:
        this.annualCostPerEmployee = 8;
        this.monthlyCostPerEmployee = 10;
        break;
      case numberOfEmployees > 1000:
        this.annualCostPerEmployee = 6.80;
        this.monthlyCostPerEmployee = 8.50;
        break;
    }
    this.annualSubscription = numberOfEmployees * this.annualCostPerEmployee * 12;
    this.monthlySubscription = numberOfEmployees * this.monthlyCostPerEmployee * 12;
  }

  /**
   * Get price of selected product
   */
  checkOrder(order: Order) {
    this.log('checking order', order);

    this.ss.checkOrder(order)
      .subscribe(
        result => {
          this.log('Got check-order response', result);
          this.price = result.price;
          this.discount = result.discountCode;
        },
        err => {
          console.error('Problem checking  order', err);
          this.notificationService.error('Problem checking order', err.error.message);
        }
      );
  }

  /**
   * Save card payment information against user.
   *
   * @param order - the order with missing order.items
   */
  submitOrder(order: Order) {
    this.log('submitting order', order);

    order.customer = {
      // employer: this.employer,
      type: this.customerType
    };

    // Add the product onto the order received from PaymentDetailsComponent
    order.items.push({ product: this.product });

    if (this.customerType === CustomerType.employer) {
      this.ss.employerUpgrade(this.employer.id, order)
        .subscribe(
          result => {
            this.log('Got order response', result);
            Intercom('trackEvent', 'web-app-signup-payment-made');
            location.pathname = "/"; // reload the app so we can refresh the data
          },
          err => {
            console.error('Problem processing order', err);
            this.notificationService.error('Problem processing order', err.error.message);
          }
        );
    }
    else {
      this.ss.individualUpgrade(order)
        .subscribe(
          result => {
            this.log('Got order response', result);
            Intercom('trackEvent', 'web-app-signup-payment-made');
            location.pathname = "/"; // reload the app so we can refresh the data
          },
          err => {
            console.error('Problem processing order', err);
            this.notificationService.error('Problem processing order', err.error.message);
          }
        );
    }

  }

  closeDialog(): void {
    window.history.back();
  }

}
