
<div class="main-content-section">
    <div id="section-content" class="section-content manage">
      <div class="form-panel" [ngClass]="{'desktop':isDesktop}">
  
        <!-- Navigation -->
        <div class="top-navigation">
          <div class="skip paragraph1" [routerLink]="['/investments']">
            <em class="material-icons">close</em>
          </div>
        </div>
  
        <!-- Secure -->
        <div class="section">
          <div class="content">
              <i class="material-icons icon">stacked_line_chart</i>
            <span class="title heading1">Create your<br>investment profile</span>
                <span class="paragraph1">Your investment profile is a recommended investment strategy  based on your needs, attitude to risk and investment timeframe. </span>
                <span class="paragraph1">Hey Coach has partnered with Six Park to create your profile. Six Park are a world-class investment advisor and an expert at building diversified global investment portfolios.</span>
          </div>
          <button (click)="openSixPark()" mat-flat-button class="rollit-button mat-full-width-button" color="primary">
           <span class="paragraph1">Create your investment profile</span>  
          </button>
          <span class="paragraph1 section-color__secondary link" [routerLink]="['/investments']">Later</span>
        </div>
</div>
  
    </div>
  </div>
  