<div #nudgeRef
     class="nudge-card-holder"
     [ngClass]="{'collapsed' : !activeItem, 'desktop': isDesktop }">
  <div class="nudge-card section-background-color__{{nudge.section}} "
       [ngClass]="{'collapsed' : !activeItem, 'desktop': isDesktop }"
       [ngStyle]="{height: !activeItem ? isDesktop ? '90px' : '70px' : height+'px' }">

    <div class="nudge-holder">

      <div class="nudge-card__header-holder">
        <div class="nudge-card__header-holder-left">
          <!-- <i class="nudge-card__movement-icon material-icons">add_circle</i> -->
          <span class="heading1 nudge-card__header"
                [ngClass]="headingCurrency || !isDesktop  ? 'heading2':'heading2'">
            <lib-handlebars [template]="nudge.title"
                            [context]="nudge"></lib-handlebars>
          </span>
        </div>
        <i class="nudge-card__section-icon material-icons"
           [ngStyle]="{'font-size' : !isDesktop  ? '28px' : '34px'}">{{this.icon}}</i>
      </div>

      <span *ngIf="activeItem"
            class="nudge-card__value">
        <span [ngClass]="!isDesktop  ? 'paragraph1':'paragraph1'">
          <lib-handlebars [template]="nudge.description"
                          [context]="nudge"></lib-handlebars>
        </span>
      </span>

      <div *ngIf="activeItem"
           class="nudge-card__bottom">
        <button mat-button
                class="rollit-button-new mat-button nudge-card__button"
                (click)="onButtonPressed()">
          <span class="title">{{ nudge.actionText || 'Optimise' }}</span>
        </button>
        <div class="paragraph2 nudge-card__bottom-section">

          <span class="nudge-card__close"
                (click)="onClosed()">Later</span>
          <!-- <lib-handlebars [template]="title" [context]="nudge"></lib-handlebars> -->
        </div>
      </div>

    </div>

  </div>
  <div *ngIf="activeItem && backgroundColor"
       class="arrow">

    <svg width="100%"
         height="100%"
         viewBox="0 0 59 52"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M 30.06368,51.10649 1.4472722,1.4346975 H 58.683294 Z"
            [attr.fill]="backgroundColor"
            id="path2"
            style="stroke:none;stroke-width:1;stroke-opacity:1" />
      <path style="fill:none;fill-opacity:1;stroke:#ffffff;stroke-width:1.5px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
            d="M 1.4472722,1.4346975 30.06368,51.10649 58.683294,1.4346975"
            id="path1275" />
    </svg>

  </div>


</div>