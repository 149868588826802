import { Injectable, } from '@angular/core';
import { Result } from '@rollit/shared/data';
import { Report } from '@rollit/shared/data';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { ReportService } from '@rollit/shared/data';
import * as moment_ from 'moment';
const moment = moment_;

export interface ItemLink {
    rLink: string;  // The link we navigate to
    rList: string;  // The link for the list router-outlet
    rDetail: string;  // The link for the detail router-outlet
    title: string;
    rActiveLink?: string;  // The link which angular checks is active. When blank we use rLink
    icon?: string;  // icon to display
    exact?: boolean; // Value of routerLinkActiveOptions.exact for this ItemLink
    hide?: boolean;
    indicator?: string; // Type<IndicatorComponent>;    // An indicator component
    data?: any;
    spacer?: boolean;
    active?: boolean;
    params?: any;
}

@Injectable()
export class UIManagerService {
    public isStandalone = window && window.matchMedia('(display-mode: standalone)').matches;

    constructor(
        private reportService: ReportService
    ) {
        // Do initial setup of media size
        if (window.innerWidth > 1240) {
            this.mediaSizeIsDesktop = true;
        } else {
            this.mediaSizeIsDesktop = false;
        }
    }

    /*
     * Whether to show connect account button in menu
     * set to true when track home route is active, and false when it is torn down
     */
    get displayConnectAccountInMenu$() {
        return this._displayConnectAccountInMenu.asObservable();
    }
    set displayConnectAccountInMenu(value: boolean) {
        this._displayConnectAccountInMenu.next(value);
    }
    private _displayConnectAccountInMenu = new BehaviorSubject<boolean>(false);

    /**
     *
     */
    get displayJoinInMenu$() {
        return this._displayJoinInMenu.asObservable();
    }
    set displayJoinInMenu(value: boolean) {
        this._displayJoinInMenu.next(value);
    }
    private _displayJoinInMenu = new BehaviorSubject<boolean>(false);

    /**
     *
     */
    public displaySynchroniseDataInMenu = new BehaviorSubject<boolean>(false);

    /**
     *
     */
    get joinButtonUrl$() {
        return this._joinButtonUrl.asObservable();
    }
    set joinButtonUrl(value: string) {
        this._joinButtonUrl.next(value);
    }
    private _joinButtonUrl = new BehaviorSubject<string>('');

    /**
     *
     */
    public displayContentAsFullPage = new BehaviorSubject<boolean>(false);

    /*
     * Whether to show filter floating action button
     * set to true when explore home is active, then false when torn down
     */
    public displayFilterFAB = new BehaviorSubject<boolean>(false);

    /*
     * Whether to show filter button in menu
     * set to true when discover home route is active, and false when it is torn down
     */
    public displayFilterInMenu = new BehaviorSubject<boolean>(false);

    /*
     * Controls whether the mobile button in the top menu scrolls out of view
     */
    public scrollUpMenuButton = new BehaviorSubject<boolean>(false);


    /*
     * Whether the top nav/ bottom nav has a blue or white background - true for blue, false for white
     */
    public bottomNavIsPrimary = new BehaviorSubject<boolean>(false);

    /*
     * Whether the app has a blue or white background - true for blue, false for white
     */
    public appBackgroundIsPrimary = new BehaviorSubject<boolean>(false);

    /*
     * Whether the top nav is visible. Home ('/') requires no top nav to be hidden
     */
    public topNavIsVisible = new BehaviorSubject<boolean>(false);

    /*
     * Whether the side nav is visible.
     */
    public sideNavIsVisible = new BehaviorSubject<boolean>(false);

    /*
     * Whether the bottom nav is visible.
     */
    public bottomNavIsVisible = new BehaviorSubject<boolean>(true);

    /*
 * Whether the side nav is visible.
 */
    public loaderIsVisible = new BehaviorSubject<boolean>(false);

    /*
     * Whether side nav is collapsed
     */
    public sideNavIsCollapsed = new BehaviorSubject<boolean>(false);

    // get sideNavIsCollapsed$() {
    //     return this._sideNavIsCollapsed.asObservable();
    // }

    // set sideNavIsCollapsed(value: boolean) {
    //     this._sideNavIsCollapsed.next(value);
    // }

    // private _sideNavIsCollapsed = new ReplaySubject<boolean>(1);


    /*
     * Whether current users browser is desktop size
     */
    get mediaSizeIsDesktop$() {
        return this._mediaSizeIsDesktop.asObservable();
    }
    set mediaSizeIsDesktop(value: boolean) {
        this._mediaSizeIsDesktop.next(value);
    }
    private _mediaSizeIsDesktop = new ReplaySubject<boolean>(1);

    /*
     * Whether filter menu is open
     */
    get filterMenuIsOpen$() {
        return this._filterMenuIsOpen.asObservable();
    }
    set filterMenuIsOpen(value: boolean) {
        this._filterMenuIsOpen.next(value);
    }
    private _filterMenuIsOpen = new BehaviorSubject<boolean>(false);

    /*
     * Whether create loan menu is open
     */
    get createLoanIsOpen$() {
        return this._createLoanIsOpen.asObservable();
    }
    set createLoanIsOpen(value: boolean) {
        this._createLoanIsOpen.next(value);
    }
    private _createLoanIsOpen = new BehaviorSubject<boolean>(false);

    /*
     * Drawer navigation menu open status
     */
    get drawerNavigationIsOpen$() {
        return this._drawerNavigationIsOpen.asObservable();
    }
    set drawerNavigationIsOpen(value: boolean) {
        this._drawerNavigationIsOpen.next(value);
    }
    private _drawerNavigationIsOpen = new BehaviorSubject<boolean>(false);

    /*
     * Whether loading screen is visible
     */
    public loadingScreenIsVisible = new BehaviorSubject<boolean>(false);

    /*
     * State of Net Return Toggle; to show percentage or dollar value returns
     */
    public get netReturnToggle$() {
        return this._netReturnToggle.asObservable();
    }
    public set netReturnToggle(value: boolean) {
        this._netReturnToggle.next(value);
    }
    private _netReturnToggle = new BehaviorSubject<boolean>(true);

    /*
     **** Main Navigation Links ***
     */
    mainLinks: { [key: string]: ItemLink[] } = {
        default: [{
            rLink: '/landing',
            rList: null,
            rDetail: null,
            title: 'Home',
            icon: 'home',
            exact: true
        },{
            rLink: '/finances/position',
            rList: null,
            rDetail: null,
            title: 'Financial position',
            icon: 'track_changes',
            exact: true
        }, {
            rLink: '/finances/projections',
            rList: null,
            rDetail: null,
            title: 'Financial projections',
            icon: 'trending_up',
            exact: true
        }, {
            rLink: '/coach/goals',
            rList: null,
            rDetail: null,
            title: 'Financial goals',
            icon: 'emoji_events',
        }, {
            rLink: '/coach/actions',
            rList: null,
            rDetail: null,
            title: 'Coach actions',
            icon: 'lightbulb',
            spacer: true
        }, {
            rLink: '/savings',
            rList: null,
            rDetail: null,
            title: 'Savings',
            icon: 'savings'
        }, {
            rLink: '/lifestyle',
            rList: null,
            rDetail: null,
            title: 'Lifestyle assets',
            icon: 'directions_car'
        }, {
            rLink: '/investments',
            rList: null,
            rDetail: null,
            title: 'Investments',
            icon: 'stacked_line_chart'
        }, {
            rLink: '/superannuation',
            rList: null,
            rDetail: null,
            title: 'Superannuation',
            icon: 'insights'
        }, {
            rLink: '/property',
            rList: null,
            rDetail: null,
            title: 'Property',
            icon: 'domain',
        }, {
            rLink: '/credit',
            rList: null,
            rDetail: null,
            title: 'Loans and cards',
            icon: 'credit_card',
            spacer: false
        }, {
            rLink: '/accounts',
            rList: null,
            rDetail: null,
            title: 'Accounts',
            icon: 'account_balance_wallet',
            spacer: true
            // }, {
            //     rLink: '/reward',
            //     rList: null,
            //     rDetail: null,
            //     title: 'Rewards',
            //     icon: 'redeem',
        }, {
            rLink: '/profile',
            rList: 'profile-links',
            rDetail: 'personal-information',
            title: 'Profile',
            icon: 'person',
        }, {
            rLink: '/start-healthcheck',
            rList: null,
            rDetail: null,
            title: 'Financial health check',
            icon: 'medical_services',
        }, {
            rLink: '/survey/setup',
            rList: null,
            rDetail: null,
            title: 'Setup (for testing)',
            icon: 'check_box',
        }]
    };

    /**
     * Home page menu items
     */
    homeLinks: ItemLink[] = [{
        rLink: '/property',
        rList: null,
        rDetail: null,
        title: 'Property <br><span class="nowrap">& Lending</span>',
        icon: 'icon-house',
        indicator: 'PropertyIndicatorComponent'
    }, {
        rLink: '/explore',
        rActiveLink: '/explore',
        rList: null,
        rDetail: null,
        title: 'Super fund <br><span class="nowrap">comparison</span>',
        icon: 'icon-super_fund',
        indicator: 'SuperIndicatorComponent'
    }, {
        rLink: '/track',
        rList: null,
        rDetail: null,
        title: 'Your Money',
        icon: 'attach_money',
        indicator: 'TrackIndicatorComponent'
    }, {
        rLink: '/reward',
        rList: null,
        rDetail: null,
        title: 'Everyday <br>Rewards',
        icon: 'shopping_cart',
        indicator: 'RewardsIndicatorComponent'
    }, {
        rLink: '/guide',
        rList: null,
        rDetail: null,
        title: 'Financial <br>advice',
        icon: 'search',
        indicator: 'AdviceIndicatorComponent'
    }, {
        rLink: '/investments',
        rList: null,
        rDetail: null,
        title: 'INVESTMENT <br>MANAGEMENT',
        icon: 'trending_up',
        indicator: 'InvestmentsIndicatorComponent'
    }];

    /*
     **** Footer Links ***
     * Used in the footer navigation to determine which nav items to display on given page
     */
    footerLinks: { [key: string]: ItemLink[] } = {
        default: [],
        home: [],
        explore: [{
            rLink: 'filter',
            rList: '',
            rDetail: '',
            title: 'Filter',
            icon: 'filter_list',
        }],
        track: [{
            rLink: '/track/connect',
            rList: null,
            rDetail: null,
            title: 'Settings',
            icon: 'settings',
        }]
    };

    initHomeLinks(upgrade: boolean) {
        // request user metrics
        this.reportService.getMetrics(this.makeMetricsRequest()).subscribe((result) => {
            console.log('User metrics response', result);
            for (const reportItem of result) {
                if (reportItem.data.name === 'RewardSavings') {
                    reportItem.data.params['upgrade'] = upgrade;
                }
            }
            console.log('metrics updated', result);
            this.setLinksData(result);
        });
    }

    /**
     * Request for metrics.
     */
    makeMetricsRequest(): Report[] {
        const now = moment();
        const metricsRequest: Report[] = [
            // savings through the Rewards program
            { name: 'RewardSavings', params: {} },

            // net cash movement for past year
            {
                name: 'MoneyNetMovement', params: {
                    from: now.clone().subtract(1, "year").toISOString(true),
                    to: now.toISOString(true)
                }
            },

            // net cash movement for past month
            {
                name: 'MoneyNetMovement', params: {
                    from: now.clone().subtract(1, "month").toISOString(true),
                    to: now.toISOString(true)
                }
            },

            // get return on superannuation balance
            { name: 'SuperReturn', params: {} },

            // get value of property
            { name: 'RealtyValue', params: {} },

            // get and loan score
            { name: 'LoanScore', params: {} },
        ];

        return metricsRequest;
    }

    private setLinksData([rewardSavings, netYear, netMonth, superReturn, realtyValue, loanScore]: Result<Report>[]) {
        this.setLinkData('/reward', rewardSavings.data);
        this.setLinkData('/track', { year: netYear.data.content, month: netMonth.data.content });
        this.setLinkData('/explore', superReturn.data.content);
        this.setLinkData('/property', { realty: realtyValue.data.content, loan: loanScore.data.content });
        this.setLinkData('/guide', {});
        this.setLinkData('/investments', {});
    }

    /**
     * Set the data for a menu item.
     */
    private setLinkData(link: string, data: any) {
        const linkItem = this.getItemLink(link);
        if (linkItem) {
            linkItem.data = data;
            //   this.log('setLinkData', linkItem);
        } else {
            //   this.log('Cannot set data. No link found for ' + link);
        }
    }

    /**
     * Find a menu item from a link path.
     *
     * @param link The path of the route link.
     */
    private getItemLink(link: string): ItemLink {
        let result = null;
        for (const item of this.homeLinks) {
            if (item['rLink'] === link) {
                result = item;
                break;
            }
        }
        return result;
    }


}
