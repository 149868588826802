<div class="holder" [ngClass]="{'desktop':isDesktop}">

  <div class="bar-chart">
    <app-bar-chart-time *ngIf="projectionChartData.length > 0 && inView" class="chart"
      [seriesData]="projectionChartData" [seriesCategories]="projectionChartCategories" [title]="'Super projections'"
      [smallTitle]="true" (graphClicked)="graphClicked($event[0], $event[1], $event[2])" [showExpand]="false">

    </app-bar-chart-time>
    <div *ngIf="!inView" class="heading1 heading2">Super projections</div>
  </div>

  <div class="details">
    <div class="titleGroup">
      <app-title-group [details]="titleGroupDetails" [displayAsColumn]="isDesktop"></app-title-group>
    </div>

    <div class="detail-list" *ngIf="acc">


      <div class="list-item">
        <span class="paragraph2">Current super balance</span>
        <span class="paragraph2">{{acc?.currentBalance  | currency:'AUD':'symbol-narrow':'1.0-0'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Super contributions<br>(% of gross salary)</span>
        <span class="paragraph2">{{acc?.properties?.super?.contributions | percent:'1.2'}}</span>
      </div>

      <div class="list-item">
        <span class="paragraph2">Annual Return</span>
        <span class="paragraph2">{{acc?.properties?.super?.annualReturn | percent:'1.2'}}</span>
      </div>


      <div class="list-item">
        <span class="paragraph2">Retirement age</span>
        <span class="paragraph2">{{projectedRetirementAge}}</span>
      </div>

    </div>
  </div>
</div>
