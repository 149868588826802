import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Goal, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-dashboard-goals',
  templateUrl: './dashboard-goals.component.html',
  styleUrls: ['./dashboard-goals.component.scss'],
})
export class DashboardGoalsComponent implements OnInit, OnDestroy {
  goalCount = 0;
  showCompleted = false;
  private isDestroyed = new Subject();
  isDesktop = false;

  goals: Goal[] = [];
  log: any;

  sliding = false;
  sectionValue: Subject<string> = new Subject();
  totalAccountValue = 0;
  currentSlide = 0;
  currentViewSlide = 0;
  activeRoute = 'credit';
  useLatest = false;
  expanded = false;
  ready: boolean;
  mobileAnimating: boolean;

  newGoal = {
    "@type": "Nudge",
    "title": "Goal setting",
    "description": "Building wealth means setting goals. Start with short-term goals (such as reducing debt) to pave the way to long-term goals (such as home ownership and financial freedom).",
    "actionText": "Set goal",
    "section": "savings",
    "action": "dialog.start",
  }

  constructor(
    private dashboardService: DashboardService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.log = this.logger.info('DashboardGoalsComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });

    this.activatedRoute.params.subscribe(params => {
      this.setupComponent(params['history']);
      if (!params['history']) {
        this.showCompleted = false;
      } else {
        this.showCompleted = true;
      }
      this.log(params['history']);
    });

  }
  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  ngOnInit(): void {
    // fetch the goals
    this.dashboardService.getGoals().subscribe(result => {
      this.log('Got dashboard goals', result)
      this.goals = result.data;
      this.ready = true;
      this.checkSlideIndex();
    });
    this.setupScrollListener();
  }

  setupComponent(someParam) {
    //this.showCompleted = true;
  }

  public showGoalsHistory() {
    this.showCompleted = true;
  }

  public hideGoalsHistory() {
    this.log('hideGoalsHistory');
    this.showCompleted = false;
  }

  public loadDetails(goalId: number) {
    this.log('loadDetails', goalId);
  }
  public editGoal(goalId: number) {
    this.log('editGoal', goalId);
    this.dashboardService.startUpdateGoal(goalId);
  }

  public removeGoal(goalId: number) {
    this.log('removeGoal', goalId);
    this.dashboardService.deleteGoal(goalId).subscribe(() => {
      this.log('Goal ' + goalId + ' deleted');
      // now remove from goals list
      for (let i = 0; i < this.goals.length; i++) {
        if (this.goals[i].id === goalId) {
          this.goals.splice(i, 1);
          break;
        }
      }
    });
  }

  createNewGoal(category: string) {
    this.dashboardService.startAddGoal(category);
  }


  checkSlideIndex() {
    const activeId = this.activatedRoute.snapshot.paramMap.get('id');
    this.log('activeId', activeId);
    if (activeId === 'latest') {
      this.log('checkSlideIndex latest yes');
      this.useLatest = true;
      this.router.navigate(['coach/' + this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      }).then(() => {
        this.setSlideIndex();
      });
    } else if (activeId && !Number.isNaN(+activeId)) {
      this.log('activeId yes', +activeId);
      this.currentSlide = +activeId;
      if (!this.isDesktop) {
        this.mobileScrollToSection(this.currentSlide);
      }
    } else {
      this.router.navigate(['coach/' + this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  setSlideIndex() {
    const tempArray = Object.assign([], this.goals);
    this.log('sort this.goals', tempArray, this.goals);
    const latestObj = tempArray.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0];
    this.log('sort latest', latestObj);
    const isNew = this.isNew(latestObj.timeCreated);
    this.log('sort isNew', isNew);
    if (isNew) {
      const index = this.goals.findIndex(x => x.id === latestObj.id);
      this.log('sort index', index);
      this.log('sort this.goals', tempArray, this.goals);
      this.currentSlide = index;
    }
  }

  isNew(createdDate) {
    const today = new Date().getTime();
    createdDate = new Date(createdDate).getTime();
    this.log('sort', today, createdDate, today - createdDate, 5 * 60 * 1000);
    return Math.abs(today - createdDate) < 5 * 60 * 1000;
  }

  expandedEvent(expanded) {
    this.expanded = expanded;
    this.onElementScroll();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // this.log('window:resize');
    if (this.goals[this.currentSlide]) {
      this.sectionValue.next(this.goals[this.currentSlide].id.toString());
    }

  }


  scrollPrev() {
    this.log('scrollPrev()', this.currentSlide + ' <> ' + this.goals.length, this.sliding);
    if (this.currentSlide > 0) {
      this.sliding = true;
      this.currentSlide--;
      this.sectionValue.next(this.goals[this.currentSlide].id.toString());
    }
  }

  scrollNext() {
    this.log('scrollNext()', this.currentSlide + ' <> ' + this.goals.length, this.sliding);
    if (this.currentSlide < this.goals.length - 1) {
      this.sliding = true;
      this.currentSlide++;
      this.log(this.currentSlide);
      this.sectionValue.next(this.goals[this.currentSlide].id.toString());

    }
  }


  animationCallback(event) {
    this.log(event);
    this.sliding = false;
    this.currentViewSlide = this.currentSlide;
    //this.log(this.activatedRoute.snapshot.url[0] + '/' + this.currentViewSlide);
    this.router.navigate(['coach/' + this.activatedRoute.snapshot.url[0] + '/' + this.currentViewSlide], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  mobileScrollToSection(slideId) {
    this.log('mobileScrollToSection', slideId);
    if (slideId > 0) {
      this.mobileAnimating = true;
      const topTargetId = this.goals[slideId].id;
      //this.log('mobileScrollToSection topTargetId', topTargetId);
      const topTarget = document.getElementById(topTargetId.toString());
      //this.log('mobileScrollToSection topTarget', topTarget);

      if (topTarget !== null) {
        setTimeout(() => {
          topTarget.scrollIntoView({
            block: "start",
            behavior: 'smooth',
          });

          setTimeout(() => {
            this.mobileAnimating = false;
          }, 500);
        }, 300);
      } else {
        //this.log('mobileScrollToSection not ready');
        setTimeout(() => {
          this.mobileScrollToSection(slideId);
        }, 50);
      }
    }
  }

  setupScrollListener() {
    if (!this.isDesktop) {
      const scrollTarget = document.getElementsByClassName("section-content")[0];
      this.log('setupScrollListener', scrollTarget);
      scrollTarget.addEventListener("scroll", () => {
        this.onElementScroll();
      });
    }

  }

  onElementScroll() {

    if (!this.isDesktop && !this.mobileAnimating) {
      const scrollTarget = document.getElementsByClassName("section-content")[0];
      const allSections = document.getElementsByTagName('app-savings-goal');

      let lastEl;
      for (let i = 0; i < allSections.length; i++) {
        const htmlElement = allSections[i] as HTMLElement;
        const elBottomAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > (htmlElement.offsetTop + htmlElement.offsetHeight);
        const elTopAboveBottomView = (scrollTarget.scrollTop + scrollTarget.clientHeight) > htmlElement.offsetTop;
        const elTopAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > htmlElement.offsetTop;

        if (!elBottomAboveHalfView && elTopAboveBottomView && elTopAboveHalfView) {
          this.log('htmlElement', htmlElement.id);
          lastEl = htmlElement;
          this.router.navigate(['coach/' + this.activatedRoute.snapshot.url[0] + '/' + i], {
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      }

      //this.log('scroll', lastEl);
    }
  }

}
