import { Component, Input, OnInit } from '@angular/core';
import { SCSS_VARS } from '@rollit/shared';
import { TitleItemData } from '@rollit/shared/common';
import { LoggerService, MoneyAccount, SectionReport } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-superannuation-projections',
  templateUrl: './superannuation-projections.component.html',
  styleUrls: ['./superannuation-projections.component.scss']
})
export class SuperannuationProjectionsComponent implements OnInit {

  @Input() acc: MoneyAccount;
  @Input() projectionChartData: any = [];
  @Input() projectionChartCategories: any = [];
  @Input() projectedRetirementAge: number = 0;
  @Input() inView: boolean;

  isDesktop: boolean;

  destroy = new Subject();
  log: any;
  titleGroupDetails: Array<TitleItemData> = [
    {
      title: 'Age',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isNumber: true,
      hide: false
    },
    {
      title: 'Projected balance',
      data: 0,
      dataColor: SCSS_VARS['$rollit-growth'],
      isCurrency: true,
      hide: false
    }

  ];


  constructor(private logger: LoggerService, private uiService: UIManagerService,) {
    this.log = this.logger.info('SuperannuationProjectionsComponent');
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit(): void {
    this.log('projectionChartCategories', this.projectionChartCategories[0]);
    this.log('projectionChartData', this.projectionChartData);
    this.log('acc', this.acc);
    this.setupTitleGroupDetails();
  }



  setupTitleGroupDetails(): void {
    this.titleGroupDetails[0]['data'] = this.projectionChartCategories[0];
    this.titleGroupDetails[1]['data'] = this.acc.currentBalance;
  }

  graphClicked(category, data, index): void {
    this.log(category, data, index);
    this.titleGroupDetails[0]['data'] = category;
    this.titleGroupDetails[1]['data'] = +category !== +this.projectedRetirementAge ? data[0] : data[1];


  }
}