<div class='header'>
  <svg version="1.1" baseProfile="full" width="100%" xmlns="http://www.w3.org/2000/svg" class="donut"
    preserveAspectRatio="xMidYMid meet" height="100%" width="100%" viewBox="0 0 500 127">
    <foreignObject y="0" height="100%" width="100%" x="0">
      <xhtml:div class="content">
        <span class="title">{{firstName}}'s Financial Health Report!</span>
        <img src="/assets/images/report/rollit-white-clean.svg" />
      </xhtml:div>
    </foreignObject>
  </svg>
</div>
