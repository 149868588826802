<div *ngIf="fund?.product?.name" class="fund-detail-holder">
  <div class="heading2 title">About your super fund</div>

  <div class="row">
    <div>
      <div class="paragraph1">{{fund?.product?.name}}</div>
      <div class="paragraph1">{{fund?.sector}}</div>
    </div>
    <img *ngIf="fund?.product?.assessmentStatus" id="aaa-logo" src="/assets/images/AAA-Selecting.svg"
      alt="AAA selecting super">
  </div>
  <div class="row">
    <div class="paragraph2">Fund information</div>
  </div>
  <div class="description paragraph2 section-color__secondary">{{fund?.description}}</div>



  <div class="row">
    <div class="paragraph2">Fund ABN</div>
    <div class="paragraph2">{{fund?.product?.fund?.abn}}</div>
  </div>

  <div class="row">
    <div class="paragraph2">Member enquiries</div>
    <div class="paragraph2">{{fund?.product?.enquiriesNumber}}</div>
  </div>

  <div class="row">
    <div class="paragraph2">Fund website</div>
    <div class="paragraph2">{{fund?.product?.website}}</div>
  </div>



  <div class="spacer row">
    <div class="paragraph2">Net investment return (5YR)</div>
    <div class="paragraph2">{{fund?.performance[0]?.values.return_5.value / 100 | percent:'1.2'}}
    </div>
  </div>

  <div class="row">
    <div class="paragraph2">Management fees (%)</div>
    <div class="paragraph2">{{fund?.maxFees?.investmentManagementFees?.value / 100 | percent:'1.2'}}</div>
  </div>

  <div class="row">
    <div class="paragraph2">Management fees ($)</div>
    <div class="paragraph2">{{fund?.maxFees?.memberManagementFees?.value | currency:'AUD':'symbol-narrow':'1.0-0'}}
    </div>
  </div>

  <div class="row">
    <div class="paragraph2">Investment category</div>
    <div class="paragraph2">{{fund?.sector}}</div>
  </div>

  <div class="row">
    <div class="paragraph2">Growth asset allocation</div>
    <div class="paragraph2">{{fund?.growthAssetWeighting / 100 | percent:'1.2'}}</div>
  </div>

</div>
