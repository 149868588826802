import { NgModule } from '@angular/core';
import { NegatePipe } from './negate.pipe';
import { StriphtmlPipe } from './striphtml.pipe';
import { UniquePipe } from './unique.pipe';


@NgModule({
  declarations: [
    NegatePipe,
    StriphtmlPipe,
    UniquePipe,
  ],
  imports: [
  ],
  providers: [
  ],
  exports: [
    NegatePipe,
    StriphtmlPipe,
    UniquePipe,
  ]
})
export class RollitUtilsModule { }
