import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIManagerService } from '@rollit/shared/services';
import { speedDialFabAnimations } from '../side-navigation/side-navigation.animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var Intercom; // defined by included Intercom javascript.

@Component({
  selector: 'side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: speedDialFabAnimations
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  defaultPic = '/assets/images/default-profile.jpg';   // default profile pic
  profilePicUrl: string = '/assets/images/default-profile.jpg';
  isDesktop: boolean;
  showSideNav: boolean;
  sideNavIsCollapsed: boolean;
  isDestroyed = new Subject();

  constructor(private uiService: UIManagerService) {
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop => this.isDesktop = isDesktop);
    this.uiService.sideNavIsVisible.pipe(takeUntil(this.isDestroyed)).subscribe(val => this.showSideNav = val);
    this.uiService.sideNavIsCollapsed.pipe(takeUntil(this.isDestroyed)).subscribe(sideNavIsCollapsed => this.sideNavIsCollapsed = sideNavIsCollapsed);
  }

  ngOnInit() {
    this.uiService.sideNavIsCollapsed.next(false);
  }

  ngOnDestroy() {
    this.uiService.sideNavIsCollapsed.next(false);
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  onCollapsePressed(): void {
    Intercom('trackEvent', 'web-app-sidenav-fund-click-filter');
    this.sideNavIsCollapsed = !this.sideNavIsCollapsed;
    this.uiService.sideNavIsCollapsed.next(this.sideNavIsCollapsed);
  }

}
