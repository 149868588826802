<!--
<div class="sum"><span class="prefix">$</span>
  <span class="value">{{sumValue}}</span>
</div>
-->

<mat-form-field class="input-number" appearance="outline">
  <mat-label *ngIf="prompt && multi" class="paragraph1">{{prompt}}</mat-label>

  <input matInput [min]="0" type="text" [formControl]="formControl" class="paragraph1" [placeholder]="placeholder"
    value="" mask="separator.2" thousandSeparator="," separatorLimit="99999999999999" disabled="true"
    [allowNegativeNumbers]="true" />
  <span *ngIf="format === 'Currency'" matPrefix>$&nbsp;</span>
  <span *ngIf="format === 'Percentage'" matSuffix>%&nbsp;</span>
  <mat-error>
    {{validationMessage}}
  </mat-error>
</mat-form-field>
<div class="description paragraph2" [ngClass]="{'error':!isValid,'single': !multi}">{{ description }}</div>
