import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailedEstimate, LoggerService, MoneyAccount, Realty } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertyService } from '../property.service';

interface PropertyDetails {
  account?: MoneyAccount;
  projections?: any;
  property?: Realty;
  estimate?: DetailedEstimate;
}

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})

export class PropertyComponent implements OnInit, OnDestroy {
  log: any;
  isDesktop: boolean;
  private isDestroyed = new Subject();
  chartData = [];
  totalPropertyValue: number = 0;
  properties: PropertyDetails[] = [];   // list of user real-estate properties
  activeRoute = 'property';
  sectionValue: Subject<string> = new Subject();
  currentSlide = 0;
  currentViewSlide = 0;
  sliding = false;
  accountSubTypes = ['home', 'owner occupied', 'investment', 'lifestyle', 'other'];
  expanded = false;
  propertiesDone = false;
  useLatest = false;
  ready: boolean;
  mobileAnimating: boolean;

  constructor(
    private propertyService: PropertyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.log = this.logger.info('PropertyComponent');
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
        this.log('this.isDesktop', this.isDesktop);
      });


  }

  ngOnInit() {

    // fetch all user properties
    this.propertyService.getProperties().subscribe(result => {
      this.properties = [];
      this.log(result);
      this.accountSubTypes.forEach((accountType) => {
        result.forEach((ac) => {
          if (ac.accountSubtype === accountType) {
            const details: PropertyDetails = { account: ac };
            this.log('propertyAccount', ac);
            this.totalPropertyValue += ac.currentBalance;
            details.property = ac.properties['property'];
            details.estimate = ac.properties['estimate'];
            this.properties.push(details);    // add details to list of property details
          }

        });
      });
      this.log('ngOnInit', this.properties);
      this.propertiesDone = true;
      this.ready = true;
      this.checkSlideIndex();
    });

  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  checkSlideIndex() {
    const activeId = this.activatedRoute.snapshot.paramMap.get('id');
    this.log('activeId', activeId);
    if (activeId === 'latest') {
      this.log('checkSlideIndex latest yes');
      this.useLatest = true;
      this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      }).then(() => {
        this.setSlideIndex();
      });
    } else if (activeId && !Number.isNaN(+activeId)) {
      this.log('activeId yes', +activeId);
      this.currentSlide = +activeId;
      if (!this.isDesktop) {
        this.mobileScrollToSection(this.currentSlide);
      }
    } else {
      this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + 0], {
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  setSlideIndex() {
    const tempArray = Object.assign([], this.properties);
    this.log('sort this.accounts', tempArray, this.properties);
    const latestObj = tempArray.sort((a, b) => new Date(b.account.timeCreated).getTime() - new Date(a.account.timeCreated).getTime())[0];
    this.log('sort latest', latestObj);
    const isNew = this.isNew(latestObj.account.timeCreated);
    this.log('sort isNew', isNew);
    if (isNew) {
      const index = this.properties.findIndex(x => x.account.id === latestObj.account.id);
      this.log('sort index', index);
      this.log('sort this.accounts', tempArray, this.properties);
      this.currentSlide = index;
    }
  }

  isNew(createdDate) {
    const today = new Date().getTime();
    createdDate = new Date(createdDate).getTime();
    this.log('sort', today, createdDate, today - createdDate, 5 * 60 * 1000);
    return Math.abs(today - createdDate) < 5 * 60 * 1000;
  }

  onAddProperty() {
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/latest'], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    }).then(() => {
      this.propertyService.startAddProperty();
    });
  }

  onStartReviewLoan() {
    this.propertyService.startReviewLoan(this.properties[this.currentSlide].account.id);
  }

  expandedEvent(expanded) {
    this.expanded = expanded;
    this.onElementScroll();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.sectionValue.next(this.properties[this.currentSlide].account.id.toString());

    // Size the compare panel
    if (this.isDesktop) {
      const elements = document.getElementsByClassName('compare');
      if (elements.length > 0) {
        const holderElement = elements[0];
        const holderRect = holderElement.getBoundingClientRect();

        const targetElement = document.getElementsByClassName('property-summary-list')[0];
        const targetRect = targetElement.getBoundingClientRect();

        this.log('holderRect', holderRect.height, 'targetRect', targetRect.height);

        const finalSize = (holderRect.height / 100) * 52;

        targetElement['style'].height = finalSize + 'px';

        this.log('finalSize Rect', finalSize);
      }

    }


  }

  scrollPrev() {
    this.log('scrollPrev()', this.currentSlide + ' <> ' + this.properties.length);
    if (this.currentSlide > 0) {
      this.sliding = true;
      this.currentSlide--;
      this.sectionValue.next(this.properties[this.currentSlide].account.id.toString());
    }

  }

  scrollNext() {
    this.log('scrollNext()', this.currentSlide + ' <> ' + this.properties.length);
    if (this.currentSlide < this.properties.length - 1) {
      this.sliding = true;
      this.currentSlide++;
      this.log(this.currentSlide);
      this.sectionValue.next(this.properties[this.currentSlide].account.id.toString());
    }
  }

  animationCallback(event) {
    this.log('animationCallback', event);
    this.sliding = false;
    this.currentViewSlide = this.currentSlide;
    this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + this.currentViewSlide], {
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  mobileScrollToSection(slideId) {
    this.log('mobileScrollToSection', slideId);
    if (slideId > 0) {
      this.mobileAnimating = true;
      const topTargetId = this.properties[slideId].account.id;
      this.log('mobileScrollToSection topTargetId', topTargetId);
      const topTarget = document.getElementById(topTargetId.toString());
      this.log('mobileScrollToSection topTarget', topTarget);

      if (topTarget !== null) {
        setTimeout(() => {
          topTarget.scrollIntoView({
            block: "start",
            behavior: 'smooth',
          });
          setTimeout(() => {
            this.mobileAnimating = false;
          }, 500);
        }, 300);
      } else {
        this.log('mobileScrollToSection not ready');
        setTimeout(() => {
          this.mobileScrollToSection(slideId);
        }, 50);
      }
    }
  }


  onElementScroll() {
    if (!this.isDesktop && !this.mobileAnimating) {
      const scrollTarget = document.getElementsByClassName("section-content")[0];
      const allSections = document.getElementsByTagName('app-property-hero');

      let lastEl;
      for (let i = 0; i < allSections.length; i++) {
        const htmlElement = allSections[i] as HTMLElement;
        const elBottomAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > (htmlElement.offsetTop + htmlElement.offsetHeight);
        const elTopAboveBottomView = (scrollTarget.scrollTop + scrollTarget.clientHeight) > htmlElement.offsetTop;
        const elTopAboveHalfView = (scrollTarget.scrollTop + scrollTarget.clientHeight / 2) > htmlElement.offsetTop;

        if (!elBottomAboveHalfView && elTopAboveBottomView && elTopAboveHalfView) {
          this.log('htmlElement', htmlElement.id);
          lastEl = htmlElement;
          this.router.navigate([this.activatedRoute.snapshot.url[0] + '/' + i], {
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      }

      this.log('scroll', lastEl);
    }
  }

}
