import { Inject, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { APP_ENVIRONMENT } from '@rollit/shared';

interface Scripts {
    name: string;
    src: string;
}


declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {
    ScriptStore: Scripts[] = [
        { name: 'googleMaps', src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyChqCMU3v1mGv7hWiC094tBuZgylF895C4&libraries=places' },
        { name: 'squarePayments', src: this.environment.square.js },
        { name: 'nativescriptWebview', src: '/assets/scripts/nativescript-webview-interface.js' },
        { name: 'yodleeFastlink', src: 'https://cdn.yodlee.com/fastlink/v4/initialize.js' }
    ];
    private scripts: any = {};
    log: any;

    constructor(private logger: LoggerService, @Inject(APP_ENVIRONMENT) private environment: any) {
        this.log = logger.info('DynamicScriptLoaderService');
        this.ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                loading: false,
                src: script.src
            };
        });
    }

    public load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    private loadScript(name: string) {
        return new Promise((resolve, reject) => {
            if (!this.scripts[name].loaded && !this.scripts[name].loading) {
                this.log('Loading', name + ' loaded: ' + this.scripts[name].loaded + ' loading: ' + this.scripts[name].loading);
                this.scripts[name].loading = true;
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            this.scripts[name].loading = false;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        this.scripts[name].loading = false;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, loading: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                this.log('Already Loaded or loading', name + ' loaded: ' + this.scripts[name].loaded + ' loading: ' + this.scripts[name].loading);
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }

}
