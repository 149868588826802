import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-enable-cookies',
  templateUrl: './enable-cookies.component.html',
  styleUrls: ['./enable-cookies.component.scss']
})
export class EnableCookiesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EnableCookiesComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}