<div *ngIf="setupComplete" class="main-content-section">
  <div id="section-content" class="section-content survey" [ngClass]="{'info' : isInformation}" (scroll)="findAnswerPosition(false)">
    <div *ngIf="dialogSlug" class="form-panel">
      <div class="top-navigation" [ngClass]="{'info' : isInformation}" *ngIf="!isInformation || !isProd">
        <em (click)="progress?.previous ? onBackTap() : null"
          [style.visibility]="progress?.previous ? 'visible' : 'hidden'"
          class="previous-icon material-icons">arrow_back</em>
        <div class="skip paragraph1" *ngIf="!isInformation" (click)="onReturnClick()">
          <em class="material-icons">close</em>
        </div>
        <div class="continue-holder" [routerLink]="isMarketing ? '/signup' : '/'"
          *ngIf="progress && isInformation && section?.content?.graphic === 'InfoGraphic'">
          <div class="continue">Continue to Hey Coach </div><i class="material-icons">arrow_forward</i>
        </div>
      </div>

      <div *ngIf="progress && !isInformation" class="progress">
        <span class="paragraph3">{{progress.completed + 1}} of {{progress.total}}</span>
        <div class="progress-line">
          <div class="percent" [ngStyle]="{'width': (100 / progress.total) * (progress.completed +1) + '%' }"></div>
        </div>
      </div>

      <div class="loading-message paragraph1" [ngClass]="{'show':AnimStatus === 'outNext' || AnimStatus === 'outNextHold'}">
        Hold tight we're almost done.
      </div>

      <div class="section" *ngIf="section">

        <div class="information" *ngIf="section.type === 'Information' ; else question">
          <div class="content">
            <span *ngIf="section.icon" class="material-icons icon" [ngStyle]="{'margin-top':'5vh','text-align':'center'}">{{section.icon}}</span>
            <img *ngIf="section.content.graphic === 'InfoGraphic'" src="/assets/icons/success_icon.svg" alt="Information" />
            <h2 *ngIf="section.title" class="prompt" [ngClass]="{'margin': section.content.graphic !== 'InfoGraphic' && !section.icon }">
              <lib-handlebars [template]="section.title" [context]="section.content?.values"></lib-handlebars>
            </h2>
            <div *ngIf="section.content.message" class="message" [ngClass]="{'paragraph1': section?.title, 'heading2': !section?.title, 'margin': !section?.title}">
              <lib-handlebars [template]="section.content.message" [context]="section.content?.values"></lib-handlebars>
            </div>
          </div>
          <div class="report-holder" *ngIf="section.content.graphic === 'InfoGraphic'">
            <app-report-inline [dialogSlug]="dialogSlug" [authenticated]="authenticated" [module]="module" [sessionId]="sessionId"></app-report-inline>
          </div>
          <div *ngFor="let action of section.actions" class="full-width">
            <button *ngIf="action.action === 'submit'" (click)="onNextTap($event)" mat-flat-button
              class="continue rollit-button mat-full-width-button mat-light-button" color="primary">
                  {{action.label || "Continue"}}
            </button>
            <button *ngIf="action.action !== 'submit'" (click)="onActionTap($event, action.action)" mat-button
                class="mat-light-button">
                  {{action.label}}
            </button>
          </div>
        </div>

        <ng-template #question>
          <form class="main-form" (ngSubmit)="onNextTap($event)" #formDirective="ngForm">
            <div *ngIf="section.questions.length > 1 ; then multiQuestion else singleQuestion"></div>

            <ng-template #multiQuestion>
              <div class="question" [ngClass]="AnimStatus">
                <!-- this may be a placeholder in the answer-->
                <div class="copy">
                  <span *ngIf="section.icon" class="material-icons icon" [ngStyle]="{'margin-top':'5vh','text-align':'center','margin-bottom':'5px'}">{{section.icon}}</span>
                  <div *ngIf="section.title" class="prompt heading2">
                    <lib-handlebars [template]="section.title" [context]="section.content?.values"></lib-handlebars>
                  </div>
                  <div *ngIf="section.subTitle" class="description paragraph1">
                    <lib-handlebars [template]="section.subTitle" [context]="section.content?.values"></lib-handlebars>
                  </div>
                </div>
                <div class="answer">
                  <lib-answer *ngFor="let question of section.questions" class="lib-answer" [answer]="question.answer"
                    [prompt]="question.prompt" [description]="question.description" [multi]="true" [context]="context">
                  </lib-answer>
                </div>

                <button [disabled]="!formValid && AnimStatus === 'end'" mat-flat-button class="continue rollit-button mat-full-width-button"
                  color="primary" type="submit">{{section.actions[0]?.label || "Continue"}}</button>
        
                <!-- DEBUG -->
                <div *ngIf="showDebugInfo" class="trace right">
                  <h2 class="heading">Question detail</h2>
                  <!-- show typeDef of answer -->
                  <p>Type: {{section.questions[0].answer?.typeDef?.type}}</p>
                  <!-- type-specific detail -->
                  <div *ngIf="section.questions[0].answer?.typeDef?.type=='Select'">
                    <p>Options:</p>
                    <div *ngFor="let o of section.questions[0].answer.typeDef['options']">{{o.name}} |
                      {{o.slug}} :
                      {{o.value}}</div>
                  </div>
                  <div *ngIf="section.questions[0].answer?.typeDef?.type=='Composite'">
                    <p>{{section.questions[0].answer.typeDef['name']}}</p>
                    <p>Fields:</p>
                    <div *ngFor="let f of section.questions[0].answer.typeDef['fields']">{{f.label}}
                      :
                      {{f.typeDef.type}}</div>
                  </div>
                  <p>Section</p>
                  <p>{{stringify(section)}}</p>
                  <p>Raw:</p>
                  <p>{{stringify(section.questions[0].answer.typeDef)}}</p>
                  <p>Answer</p>
                  <p>{{stringify(section.questions[0].answer)}}</p>
                </div>

              </div>
            </ng-template>

            <ng-template #singleQuestion>
              <div class="question" [ngClass]="AnimStatus" *ngFor="let question of section.questions">
                <!-- this may be a placeholder in the answer-->
                <div class="copy">
                  <span *ngIf="section.icon" class="material-icons icon" [ngStyle]="{'margin-top':'5vh','text-align':'center','margin-bottom':'5px'}">{{section.icon}}</span>
                  <div class="prompt heading2">
                    <lib-handlebars [template]="section.title" [context]="section.content?.values"></lib-handlebars>
                  </div>
                  <div *ngIf="section.subTitle" class="description paragraph2">
                    <lib-handlebars [template]="section.subTitle" [context]="section.content?.values"></lib-handlebars>
                  </div>
                </div>

                <div *ngIf="section.content?.message">
                  <div *ngIf="section.content.message" class="message"
                    [ngClass]="{'paragraph1': section?.title, 'heading2': !section?.title, 'margin': !section?.title}">
                    <lib-handlebars [template]="section.content.message" [context]="section.content?.values"></lib-handlebars>
                  </div>
                </div>

                <div class="answer single">
                  <lib-answer class="lib-answer" [answer]="question.answer" [prompt]="question.prompt" [multi]="false"
                    (click)="inputChange()" (input)="inputChange()"></lib-answer>

                  <div id="description" *ngIf="question?.answer?.description" class="section-description paragraph2"
                    [ngClass]="{'show':AnimStatus === 'end' && showTip === true}">
                    <div class="tip">&#x25B2;</div>
                    <div class="content">
                      <i class="material-icons" (click)="closeTip($event)">close</i>
                      <span class="text">
                        <lib-handlebars [template]="question?.answer?.description" [context]="section.content?.values">
                        </lib-handlebars>
                      </span>
                    </div>
                  </div>
                </div>

                <div *ngFor="let action of section.actions" class="full-width">
                  <button *ngIf="action.action === 'submit'" (click)="onNextTap($event)" mat-flat-button
                    class="continue rollit-button mat-full-width-button paragraph1" color="primary">
                        {{action.label || "Continue"}}
                  </button>
                  <button *ngIf="action.action !== 'submit'" (click)="onActionTap($event, action.action)" mat-button
                      class="mat-light-button" [ngStyle]="{'text-align':'center', 'width':'100%','margin':'0 auto','color':'#8A9AB1'}">
                        {{action.label}} 
                  </button>
                </div>

                <!-- DEBUG -->
                <div *ngIf="showDebugInfo" class="trace right">
                  <h2 class="heading">Question detail</h2>
                  <!-- show typeDef of answer -->
                  <p>Type: {{question.answer?.typeDef?.type}}</p>
                  <!-- type-specific detail -->
                  <div *ngIf="question.answer?.typeDef?.type=='Select'">
                    <p>Options:</p>
                    <div *ngFor="let o of question.answer.typeDef['options']">{{o.name}} | {{o.slug}} :
                      {{o.value}}</div>
                  </div>

                  <div *ngIf="question.answer?.typeDef?.type=='Composite'">
                    <p>{{question.answer.typeDef.name}}</p>
                    <p>Fields:</p>
                    <div *ngFor="let f of question.answer.typeDef['fields']">{{f.label}}
                      :
                      {{f.typeDef.type}}</div>
                  </div>
                  <p>Section</p>
                  <p>{{stringify(section)}}</p>
                  <p>Raw:</p>
                  <p>{{stringify(question.answer.typeDef)}}</p>
                  <p>Answer</p>
                  <p>{{stringify(question.answer)}}</p>
                </div>

              </div>
            </ng-template>

          </form>
        </ng-template>
      </div>

      <!-- DEBUG -->
      <div *ngIf="showDebugInfo" class="trace">
        <div>
          <h2 class="heading">Facts detail</h2>
          <p>User Facts</p>
          <div *ngFor="let fact of facts">
            <div *ngIf="fact.slug">{{fact["@type"]}} | {{fact.slug}} =
              {{fact.value}} | {{fact.visible}}</div>
            <div *ngIf="fact.disQualifiedReason">Disqualified:
              {{fact.disQualifiedReason}}</div>
          </div>
          <button mat-raised-button class="continue rollit-button" color="primary" (click)="onClearFacts()">Clear
            facts</button>
          <p>Dialog Facts</p>
          <div *ngFor="let fact of dialogFacts">
            <div *ngIf="fact.answer && fact.slug">{{fact["@type"]}} |
              {{fact.answer}} = {{fact.slug}} | visible = {{fact.visible}}</div>
            <div *ngIf="!fact.answer && fact.slug">{{fact["@type"]}} |
              {{fact.slug}} | visible = {{fact.visible}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
