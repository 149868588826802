import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RollitCommonModule } from '@rollit/shared/common';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyDisclaimerRoutingModule } from './privacy-disclaimer-routing.module';



@NgModule({
  declarations: [PrivacyComponent, DisclaimerComponent],
  imports: [
    CommonModule,
    RollitCommonModule,
    PrivacyDisclaimerRoutingModule
  ]
})
export class PrivacyDisclaimerModule { }
