import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { UIManagerService } from '@rollit/shared/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppDateAdapter, APP_DATE_FORMATS } from '@rollit/shared/utils';
import { EmployerService } from '@rollit/shared/data';
import { LoggerService } from '@rollit/shared/data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Employee } from '@rollit/shared/data';

@Component({
  selector: 'add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class AddEmployeeComponent implements OnDestroy {
  isDesktop: boolean;
  userAdded: boolean = false;
  employerID: any;
  employee: Employee;
  isEdit: boolean = false;
  userRemovedConfirm: boolean = false;
  userRemoved: boolean = false;
  log: any;
  employees: any[] = [];
  today = new Date();
  newEmployeeForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    sex: new FormControl(''),
    dob: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    email: new FormControl({ value: '', disabled: this.isEdit }, Validators.compose([Validators.required, Validators.email])),
    payrollNumber: new FormControl(''),
    superFundName: new FormControl(''),
    superFundUsi: new FormControl(''),
    superFundMemberNumber: new FormControl(''),
  });
  isDestroyed = new Subject();

  /**
   * Constructor
   */
  constructor(
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    private uiService: UIManagerService,
    private employerService: EmployerService,
    private logger: LoggerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.log = this.logger.info('addEmployeeDialog');
    this.employerID = data.employerID;
    this.employee = data.employee;
    if (this.employee !== undefined) {
      this.getEmployee();
    }
    this.uiService.mediaSizeIsDesktop$.pipe(takeUntil(this.isDestroyed)).subscribe(isDesktop => this.isDesktop = isDesktop);
  }

  ngOnDestroy() {
    this.isDestroyed.next(); this.isDestroyed.complete();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getEmployee() {
    this.isEdit = true;
    this.log('Employee get', this.employee);
    // this.notificationService.info('Success', value.length + ' employees on-boarded');
    this.newEmployeeForm.controls.title.setValue(this.employee.title);
    this.newEmployeeForm.controls.firstName.setValue(this.employee.firstName);
    this.newEmployeeForm.controls.lastName.setValue(this.employee.lastName);
    this.newEmployeeForm.controls.sex.setValue(this.employee.sex);
    this.newEmployeeForm.controls.dob.setValue(this.employee.dob);
    this.newEmployeeForm.controls.phone.setValue(this.employee.phone);
    this.newEmployeeForm.controls.email.setValue(this.employee.email);
    this.newEmployeeForm.controls.payrollNumber.setValue(this.employee.payrollNumber);
    this.newEmployeeForm.controls.superFundName.setValue(this.employee.superFundName);
    this.newEmployeeForm.controls.superFundUsi.setValue(this.employee.superFundUsi);
    this.newEmployeeForm.controls.superFundMemberNumber.setValue(this.employee.superFundMemberNumber);
    this.newEmployeeForm.markAsTouched();
  }

  onSubmit() {
    this.log(this.employerID);
    this.log(this.newEmployeeForm.controls.firstName.value);

    this.employees = [{
      title: this.newEmployeeForm.controls.title.value ? this.newEmployeeForm.controls.title.value : null,
      firstName: this.newEmployeeForm.controls.firstName.value ? this.newEmployeeForm.controls.firstName.value : null,
      lastName: this.newEmployeeForm.controls.lastName.value ? this.newEmployeeForm.controls.lastName.value : null,
      sex: this.newEmployeeForm.controls.sex.value ? this.newEmployeeForm.controls.sex.value : null,
      dob: this.newEmployeeForm.controls.dob.value ? this.newEmployeeForm.controls.dob.value : null,
      phone: this.newEmployeeForm.controls.phone.value ? this.newEmployeeForm.controls.phone.value : null,
      email: this.newEmployeeForm.controls.email.value ? this.newEmployeeForm.controls.email.value : null,
      payrollNumber: this.newEmployeeForm.controls.payrollNumber.value ? this.newEmployeeForm.controls.payrollNumber.value : null,
      superFundName: this.newEmployeeForm.controls.superFundName.value ? this.newEmployeeForm.controls.superFundName.value : null,
      superFundUsi: this.newEmployeeForm.controls.superFundUsi.value ? this.newEmployeeForm.controls.superFundUsi.value : null,
      superFundMemberNumber: this.newEmployeeForm.controls.superFundMemberNumber.value ? this.newEmployeeForm.controls.superFundMemberNumber.value : null,
    }];
    this.log('employees', this.employees);
    this.employerService.putEmployees(this.employerID, this.employees).subscribe(
      value => {
        this.log('Employees onboarded', value);
        this.userAdded = true;
        // this.notificationService.info('Success', value.length + ' employees on-boarded');
      },
      err => {
        this.log('Error onboarding employees', err);
        // this.notificationService.error('Problem on-boarding employees', err);
      }
    );
  }

  removeEmployee() {
    this.log('employee', this.employee.id);
    this.userRemovedConfirm = true;
  }

  removeEmployeeConfirm() {
    this.log('employee', this.employee.id);
    this.employerService.removeEmployee(this.employerID, this.employee.id).subscribe(value => {
      this.log('return', value);
      this.userRemovedConfirm = true;
    },
      err => {
        this.log('Error removing employee', err);
      }
    );
    this.closeDialog();
  }

  addAnotherUser() {
    this.newEmployeeForm.reset();
    this.userAdded = false;
  }

  getErrorMessage(field) {
    switch (field) {

      case 'title': {
        return this.newEmployeeForm.controls.title.hasError('required') ? 'You must enter a value' : '';
      }
      case 'firstName': {
        return this.newEmployeeForm.controls.firstName.hasError('required') ? 'You must enter a value' : '';
      }
      case 'lastName': {
        return this.newEmployeeForm.controls.lastName.hasError('required') ? 'You must enter a value' : '';
      }
      case 'sex': {
        return this.newEmployeeForm.controls.sex.hasError('required') ? 'You must enter a value' : '';
      }
      case 'dob': {
        return this.newEmployeeForm.controls.dob.hasError('required') ? 'You must enter a value' : '';
      }
      case 'phone': {
        return this.newEmployeeForm.controls.phone.hasError('required') ? 'You must enter a value' : '';
      }
      case 'email': {
        return this.newEmployeeForm.controls.email.hasError('required') ? 'You must enter a value' :
          this.newEmployeeForm.controls.email.hasError('email') ? 'Not a valid email' : '';
      }
      case 'payrollNumber': {
        return this.newEmployeeForm.controls.payrollNumber.hasError('required') ? 'You must enter a value' : '';
      }
      case 'superFundName': {
        return this.newEmployeeForm.controls.superFundName.hasError('required') ? 'You must enter a value' : '';
      }
      case 'superFundUsi': {
        return this.newEmployeeForm.controls.superFundUsi.hasError('required') ? 'You must enter a value' : '';
      }
      case 'superFundMemberNumber': {
        return this.newEmployeeForm.controls.superFundMemberNumber.hasError('required') ? 'You must enter a value' : '';
      }
    }
  }

}
