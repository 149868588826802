import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TrackService } from '@rollit/shared/common';
import { MeService, Nudge, ProgressSummary, User } from '@rollit/shared/data';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { UIManagerService } from '@rollit/shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';

// default progress summary content
const defaultProgress: ProgressSummary = {
  slug: "default",
  heading: "Your financial progress",
  content: "After your first month with Hey Coach you will receive updates on your progress. We will run the numbers, clue you into changes, and provide insights on your behaviours.",
  values: {}
}

@Component({
  selector: 'app-dashboard-landing',
  templateUrl: './dashboard-landing.component.html',
  styleUrls: ['./dashboard-landing.component.scss']
})
export class DashboardlandingComponent implements OnInit, OnDestroy {
  nudges: Nudge[] = [];
  private isDestroyed = new Subject();
  activeItem: number = 0;
  ready: boolean;
  isDesktop = false;
  me:User;

  progress: ProgressSummary[] = [];
  showMoreProgress = false;
  disclaimerUrl = '';

  constructor(private uiService: UIManagerService,
    private dashboardService: DashboardService,
    private trackService: TrackService,
    private userService: MeService,
    @Inject(APP_ENVIRONMENT) private environment: any
  ) {
    this.uiService.displayContentAsFullPage.next(true);
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.appBackgroundIsPrimary.next(true)
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
      this.disclaimerUrl = this.environment.adviceDisclaimerUrl;
      
  }

  ngOnInit(): void {
    this.dashboardService.getNudges().subscribe(result => {
     this.nudges = result.data;
      this.ready = true;
    });
    this.userService.me$.pipe(takeUntil(this.isDestroyed)).subscribe(
      value => {
        if (value) {
          this.me = value;
          }
        }
    );
    this.trackService.getProgress().subscribe((result => {
      this.progress = result && result.length > 0 ? result : [defaultProgress];
    }));
  }

  ngOnDestroy(): void {
    this.uiService.displayContentAsFullPage.next(false);
    this.uiService.sideNavIsVisible.next(false);
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  onClosed(nudge: Nudge) {
    // set nudge status to "dismissed"
    this.dashboardService.dismissNudge(nudge).subscribe(result => {
      // remove nudge from list
      const index = this.nudges.indexOf(nudge, 0);
      if (index > -1) {
        this.nudges.splice(index, 1);
      }
    });
  }

  onButtonPressed(nudge: Nudge) {
    this.trackService.performNudgeAction(nudge);
  }

  expandItem(event, id) {
    this.activeItem = id;
  }

  expandProgress() {
    this.showMoreProgress = !this.showMoreProgress;
    return false;
  }
}
