<nav [ngClass]="{'desktop' : isDesktop}" *ngIf="topNavIsVisible">
    <div class="menu" (click)="onDrawerNavigationPressed()">
        <i *ngIf="drawerNavigationIsActive" class="material-icons">clear</i>
        <i *ngIf="!drawerNavigationIsActive" class="material-icons">menu</i>
        <span *ngIf="pageTitle">{{pageTitle | striphtml}}</span>
    </div>
    <div class="menu-profile">
        <i *ngIf="hasNotifications" class="notification-icon"></i>
        <i class="material-icons" (click)="onChatClick()">chat_bubble_outline</i>
        <span (click)="onProfileClick()" [ngStyle]="{backgroundImage:'url('+ profilePicUrl +')'}"></span>
    </div>
</nav>
<div class="highlight" [ngClass]="{'desktop' : isDesktop}"></div>