import { NgModule } from '@angular/core';
import { RollitCommonModule } from '@rollit/shared/common';
import { CommonModule, CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { PropertyComponent } from './components/property.component';
import { PropertyRoutingModule } from './property-routing.module';
import { PropertyService } from './property.service';
import { PropertyHeroComponent } from './components/property-hero/property-hero.component';
import { PropertyCompareComponent } from './components/property-compare/property-compare.component';
import { PropertyProjectionsComponent } from './components/property-projections/property-projections.component';



@NgModule({
  declarations: [PropertyComponent, PropertyHeroComponent, PropertyCompareComponent, PropertyProjectionsComponent],
  imports: [
    CommonModule, RollitCommonModule, PropertyRoutingModule
  ],
  providers: [
    PropertyService, CurrencyPipe, DatePipe, TitleCasePipe
  ]
})
export class PropertyModule { }
