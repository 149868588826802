
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { AccountSource, LoggerService, MoneyAccount, MoneyService } from "@rollit/shared/data";
import { UIManagerService } from "@rollit/shared/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { trackById } from '@rollit/shared/utils';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@rollit/shared/common";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { each } from "highcharts";


@Component({
  selector: 'app-remove-accounts',
  templateUrl: './remove-accounts.component.html',
  styleUrls: ['./remove-accounts.component.scss']
})
export class RemoveAccountsComponent implements OnInit {
  private log: any;
  connectedAccounts: Array<any> = [];
  accountsForRemoval: Array<any>;
  gotAccounts: boolean;
  destroy = new Subject();
  trackById = trackById;
  isDesktop: boolean;
  yodleeConnection = false;
  yodleeProfile: any;

  constructor(
    private moneyService: MoneyService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private _location: Location,
  ) {
    this.log = this.logger.info('RemoveAccountsComponent');
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit() {

    this.moneyService.getAccounts().subscribe(response => {
      this.log('accounts', response);

      response.forEach(account => {

        if (account.source === AccountSource.yodlee) {
          const providerName = account.properties.yodlee['providerName'];
          const providerId = account.properties.yodlee['id'];
          let hasProvider = false;
          let existingAccount;
          this.connectedAccounts.forEach(account => {
            if (account.providerName === providerName) {
              hasProvider = true;
              existingAccount = account;
            }
          });

          this.log(hasProvider, providerName);

          if (!hasProvider) {
            this.connectedAccounts.push({ providerName: providerName, id: providerId });
          }
          this.log('Updating connectedAccounts', this.connectedAccounts);
        }

      });

      this.accountsForRemoval = [...this.connectedAccounts];
      this.log('connectedAccounts', this.connectedAccounts);
      this.log('accountsForRemoval', this.accountsForRemoval);
      this.gotAccounts = true;
    });
    this.getYodleeStatus();
  }

  getYodleeStatus() {
    this.moneyService.getYodleeProfile().pipe(takeUntil(this.destroy))
      .subscribe((result) => {
        this.log('getYodleeStatus', result);
        this.yodleeProfile = result;
        if (result.id) {
          this.yodleeConnection = true;
        }
      });
  }

  ngOnDestroy() {
    this.uiService.sideNavIsVisible.next(false);
    this.destroy.next(); this.destroy.complete();
  }

  manageAccounts() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/accounts/manage-accounts']));
  }

  onReturnClick() {
    this._location.back();
  }

  showOptions(checkboxEvent: MatCheckboxChange): void {
    console.log(checkboxEvent);
    if (checkboxEvent.checked === true) {
      const accountToAdd = this.connectedAccounts.filter(obj => {
        return obj.id === checkboxEvent.source.value;
      });
      this.log('accountToAdd', accountToAdd[0]);
      this.accountsForRemoval.push(accountToAdd[0]);
    } else {
      this.accountsForRemoval = this.accountsForRemoval.filter(function (obj) {
        return obj.id !== checkboxEvent.source.value;
      });
    }

    this.log('connectedAccounts', this.connectedAccounts);
    this.log('accountsForRemoval', this.accountsForRemoval);
    //   this.openRemovePropertyConfirmationDialog(checkboxEvent.source.value, !checkboxEvent.checked, checkboxEvent);
  }

  stopSharing() {
    // this.accountsForRemoval.forEach(account => {
    //   this.log('account', account);
    //   this.moneyService.removeAccount(account.id).subscribe(result => {
    //     this.log('result', result);
    //   });
    // });
    this.moneyService.removeYodleeProfile().subscribe(result => {
      this.log('result', result);
    });
  }
}
