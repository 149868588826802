import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_ENVIRONMENT } from '@rollit/shared';
import { LoggerService } from "../other/logger.service";
import * as moment_ from 'moment';
import { ResultList } from "../model/result";
import { Nudge } from "../model/nudge";
import { Observable } from "rxjs";
const moment = moment_;


@Injectable()
export class NudgeService {
    log: any;
    apiUrl: string;
  
    constructor(
        @Inject(APP_ENVIRONMENT) private environment: any,
        private http: HttpClient,
        private logger: LoggerService,
    ) {
        this.log = this.logger.info('nudgeService');
        this.apiUrl = this.environment.apiUrl;
    }

    getNudges(): Observable<ResultList<Nudge>> {
        const path = '/me/nudge';
        return this.http.get<ResultList<Nudge>>(this.apiUrl + path);
    }

    /**
     * Update status.
     * 
     * @param nudgeId 
     * @param nudge 
     * @returns 
     */
    updateNudge(nudgeId: number, nudge: Nudge): Observable<Nudge> {
        const path = '/me/nudge/' + nudgeId;
        return this.http.put<Nudge>(this.apiUrl + path, nudge);
    }
}
