<div [formGroup]="form">

    <div [ngSwitch]="question.controlType">

        <mat-form-field *ngSwitchCase="'textbox'">
            <input matInput [placeholder]="question.label" [formControlName]="question.key" [id]="question.key" [type]="question['type']" (blur)="onValueChange()" [autocomplete]="browserAutocompleteDisabledQuestions.includes(question.key) ? 'nope' : 'on'">
            <mat-error *ngIf="hasError('required')">
                Field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="hasError('email')">
                Please input a valid email
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="'dropdown'">
            <mat-select [formControlName]="question.key" [placeholder]="question.label" (valueChange)="onValueChange()">
                <mat-option *ngFor="let opt of question['options']" [value]="opt.key">{{opt.value}}</mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('required')">
                Field is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <ng-container *ngSwitchCase="'radio'">
            <div *ngIf="question['bigRadio']" class="big-radio-wrapper" [ngClass]="{'wrap': question['wrapRadio']}">
                <button mat-raised-button class="rollit-button " type="button" [ngClass]="{'primary': form.controls[question.key].value === question['options'][0].key}" (click)="onBigRadioChange(question['options'][0].key)">{{ question['options'][0].value }}</button>
                <button mat-raised-button class="rollit-button " type="button" [ngClass]="{'primary': form.controls[question.key].value === question['options'][1].key}" (click)="onBigRadioChange(question['options'][1].key)">{{ question['options'][1].value }}</button>
            </div>
            <div *ngIf="!question['bigRadio']">
                <div class="question-label" [innerHTML]="question.label"></div>
                <mat-radio-group fxLayout="column" [formControlName]="question.key">
                    <mat-radio-button *ngFor="let opt of question['options']" color="primary" [value]="opt.key">{{opt.value}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
            <mat-checkbox [formControlName]="question.key">
                <span [innerHTML]="question.label"></span>
            </mat-checkbox>
        </ng-container>

        <div *ngSwitchCase="'date'">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" [placeholder]="question.label" [formControlName]="question.key" (dateChange)="onValueChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                <mat-error *ngIf="hasError('required')">
                    Field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field *ngSwitchCase="'location'">
            <input type="text" [placeholder]="question.label" aria-label="question.label" matInput [formControlName]="question.key" [matAutocomplete]="auto" [autocomplete]="browserAutocompleteDisabledQuestions.includes(question.key) ? 'nope' : 'on'">
            <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
                <mat-option *ngFor="let option of autoCompleteOptions" [value]="option.description" (onSelectionChange)="getAddressDetails(option)">
                    {{ option.description }}
                </mat-option>
            </mat-autocomplete>
            <mat-spinner matPrefix [diameter]="14" [strokeWidth]="1" *ngIf="autocompleteLoadingValues"></mat-spinner>
            <mat-error *ngIf="hasError('required')">
                Field is <strong>required</strong>
            </mat-error>
        </mat-form-field>

    </div>

</div>