import { MoneyAccount } from './money';

export enum LoanRepaymentType {
    PrincipalAndInterest = 'PRINCIPAL_AND_INTEREST',
    InterestOnly = 'INTEREST_ONLY',
}
export enum Loanurpose {
    OwnerOccupied = 'OWNER_OCCUPIED',
    Investment = 'INVESTMENT',
}
export enum LoanMaritalStatus {
    Single = "SINGLE",
    Married = "MARRIED",
    Defacto = "DEFACTO",
    Divorced = "DIVORCED"
}
export enum Frequency {
    Weekly = 'WEEKLY',
    Fortnightly = "FORTNIGHTLY",
    Monthly = "MONTHLY",
    Yearly = "YEARLY"
}
export enum Duration {
    Month = "MONTH",
}
export enum LoanEmploymentType {
    PAYG = "PAYG",
}


export interface PhoneNumber {
    fullNumber: string;
    preferred: boolean;
    type: string;       // "MOBILE", "WORK"
}

export interface CreditHistory {
    bankruptcy?: boolean;
    defaultsOrJudgements?: boolean;
}

export interface LoanEmployment {
    type: LoanEmploymentType;
    current: boolean;
}

export interface Profile {
    id?: number;
    maritalStatus?: LoanMaritalStatus;
    phoneNumbers?: PhoneNumber[];
    creditHistory?: CreditHistory;
    employments?: LoanEmployment[];
}

export interface Income {
    grossAmount: number;
    frequency: Frequency;
}

export interface Finances {
    id?: number;
    householdIncome?: Income;
}

export interface LoanDetails {
    remainingAmount: number;
    remainingLoanTerm?: number;
    remainingLoanTermDurationUnit?: Duration;
    repaymentAmount: number;
    repaymentFrequency: Frequency;
    repaymentType: LoanRepaymentType;
    interestRate: number;
    lenderCode: string;           // e.g. "CBA"
}

export interface ScorePreferences {
    loanFeature: {
        repaymentType: LoanRepaymentType;
        offset?: boolean;
        redraw?: boolean;
        extraRepayment?: boolean;
    };
    lender: {
        smallLenders?: boolean;
        majorLenders?: boolean;
    };
}

export interface Savings {
    netSavings: {
        unit: string;     // "YEAR",
        duration: number;
        value: number;
    };
    grossSavings: {
        unit: string;    // "YEAR",
        duration: number;
        value: number;
    };
    totalOngoingFees: number;
    unoCashBack: number;
    lenderRebate: number;
    estimatedSwitchingCosts: number;
}

export interface ScoreUrl {
    rel: string;        // e.g. "loanScore"
    href: string;       // the URL
}

export interface LoanScore {
    homeLoanId?: number;
    score?: number;
    estimatedSavings?: {
        currentLender?: Savings;
        newLender?: Savings;
    };
    uiUrls?: ScoreUrl[];
}

export interface Lender {
    code: string;
    name: string;
}

export interface LoanScoreSubmission {
    profile?: Profile;
    finances?: Finances;
    loanAccount?: MoneyAccount;              // User's loan account to create a score for.  If no ID, then create account.
    loanDetails?: LoanDetails;
    realtyAccount?: MoneyAccount;            // The realty/property account securing the loan.
    preferences?: ScorePreferences;
    termsOfUseConsent?: boolean;        // must be set to true to work.
}
