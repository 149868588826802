import { AfterContentInit, AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '@rollit/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InnerTopMenuComponent } from '../inner-top-menu/inner-top-menu.component';

@Component({
  selector: 'app-dashboard-inner-top-menu',
  templateUrl: './dashboard-inner-top-menu.component.html',
  styleUrls: ['./dashboard-inner-top-menu.component.scss'],
})
export class DashboardInnerTopMenuComponent
  implements InnerTopMenuComponent, OnDestroy, AfterViewInit {

  @Input() data: any;
  log: any;
  activeRoute;
  activatedRoute;
  private _isDestroyed = new Subject();

  constructor(private route: ActivatedRoute, private logger: LoggerService,) {
    this.log = this.logger.info('DashboardInnerTopMenuComponent');
    this.activatedRoute = route;
    this.route.url
      .pipe(takeUntil(this._isDestroyed))
      .subscribe((segment) => {
        this.activeRoute = segment[0].path;
      }
      );
  }

  ngAfterViewInit(): void {
    this.log('data', this.data);

    setTimeout(() => {
      this.positionArrow();
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    setTimeout(() => {
      this.positionArrow();
    }, 100);
  }

  positionArrow() {
    const arrowElement = document.getElementById('arrow');
    const targetElement = document.getElementsByClassName('mat-button-toggle-checked')[0];
    // this.log('positionArrow()');
    if (targetElement && targetElement.parentElement['offsetLeft'] !== 0 && arrowElement) {


      const buttonGroupXpos = targetElement.parentElement['offsetLeft'];

      const activeButtonXpos = targetElement['offsetLeft'];

      const activeButtonWidth = targetElement['offsetWidth'];

      const arrowWidth = arrowElement['offsetWidth'];

      const finalXpos = (buttonGroupXpos + activeButtonXpos) + (activeButtonWidth / 2) - (arrowWidth / 2);

      arrowElement.style.left = (buttonGroupXpos + activeButtonXpos) + (activeButtonWidth / 2) - (arrowWidth / 2) + 'px';



    } else {
      setTimeout(() => {
        this.positionArrow();
      }, 100);

    }


  }

  ngOnDestroy(): void {
    this._isDestroyed.next();
    this._isDestroyed.complete();
  }
}
