<div class="main-content-section">
  <div class="section-content" (scroll)="onElementScroll()">
    <app-top-menu></app-top-menu>

    <div class="rollit-grid">

      <app-section-title *ngIf="accounts.length > 0" [title]="'Portfolio value'" [value]="totalAccountValue">
      </app-section-title>

      <div *ngIf="isDesktop && accounts.length > 0" class="rollit-grid-2">
        <app-horizontal-scroll-snap *ngIf="ready" [scrollToSection]="sectionValue" [fullWidth]="true"
          (animFinished)="animationCallback($event)">
          <section *ngFor="let account of accounts; let i = index">
            <app-investments-hero [inView]="currentViewSlide === i" [id]="account.id" [acc]="account"
              [propertyListId]="i" [propertyCount]="accounts.length - 1" [investmentAccountCount]="investmentAccountCount" [businessAccountCount]="businessAccountCount" (clickNext)="scrollNext()"
              (clickPrev)="scrollPrev()">
            </app-investments-hero>
          </section>
        </app-horizontal-scroll-snap>
      </div>

      <div *ngIf="!isDesktop && accounts.length > 0" class="rollit-grid-1">
        <ng-container *ngFor="let account of accounts">
          <app-investments-hero [id]="account.id" [inView]="expanded" [acc]="account"  [propertyCount]="accounts.length - 1" [investmentAccountCount]="investmentAccountCount" [businessAccountCount]="businessAccountCount" (clickNext)="scrollNext()"
            (clickPrev)="scrollPrev()" (expandedProjections)="expandedEvent($event)">
          </app-investments-hero>
        </ng-container>
      </div>

      <div *ngIf="accounts.length === 0 && ready" class="rollit-grid-2">
        <div class="group-panel blank">
          <span class="copy heading1">Investments</span>
          <span class="copy paragraph1">Track your investment portfolio and business assets in one place. Establish your
            investment profile and recommended investment strategy based on your needs, attitude to risk and investment
            timeframe.</span>
          <img class="image" alt="Investment preview" src="/assets/images/example/investment-example.png" />
        </div>
      </div>

      <div class="rollit-grid-1">
        <div class="sundry-holder">
          <button *ngIf="accounts && investmentAccountCount === 0" mat-stroked-button class="new-account" (click)="onAddInvestment()">
            <span class="paragraph1">Add an investment</span>
          </button>
          <button *ngIf="isDesktop && investmentAccountCount > 0" mat-stroked-button class="new-account" (click)="onAddInvestment()">
            <span class="paragraph1">Add another investment</span>
          </button>
          <button *ngIf="(isDesktop || investmentAccountCount === 0) && me?.extra?.sixparkStatus === 'connected'" mat-stroked-button class="new-account" color="primary" (click)="openSixPark()">
            <span class="paragraph1">Access your Six Park account</span>
          </button>
          <button *ngIf="(isDesktop || investmentAccountCount === 0) && !me?.extra?.sixparkStatus" mat-stroked-button class="new-account" color="primary" [routerLink]="['/six-park']" >
            <span class="paragraph1">Create your investment profile</span>
          </button>          
          <button mat-stroked-button class="new-account" (click)="onAddBusiness()">
            <span *ngIf="accounts && businessAccountCount > 0" class="paragraph1">Add another business</span>
            <span *ngIf="accounts && businessAccountCount === 0" class="paragraph1">Add a business</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
