import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { AccountSource, LoggerService, MoneyAccount, MoneyService } from "@rollit/shared/data";
import { UIManagerService } from "@rollit/shared/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { trackById } from '@rollit/shared/utils';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@rollit/shared/common";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DashboardService } from "@rollit/shared/dashboard";

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
  private log: any;
  connectedAccounts: Array<any> = [];
  userAccounts: Array<any> = [];
  gotAccounts: boolean;
  destroy = new Subject();
  trackById = trackById;
  isDesktop: boolean;
  yodleeConnection: boolean;

  constructor(
    private moneyService: MoneyService,
    private dashboardService: DashboardService,
    private logger: LoggerService,
    private uiService: UIManagerService,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private _location: Location,
  ) {
    this.log = this.logger.info('AccountsComponent');
    this.uiService.sideNavIsVisible.next(true);
    this.uiService.mediaSizeIsDesktop$
      .pipe(takeUntil(this.destroy))
      .subscribe((mediaSizeIsDesktop) => {
        this.isDesktop = mediaSizeIsDesktop;
      });
  }

  ngOnInit() {
    this.getYodleeStatus();

    this.moneyService.getAccounts().subscribe(response => {
      this.log('accounts', response);

      response.forEach(account => {

        if (account.source === AccountSource.yodlee) {
          const providerName = account.properties.yodlee['providerName'];
          let hasProvider = false;
          let existingAccount;
          this.connectedAccounts.forEach(accountResult => {
            if (accountResult.providerName === providerName) {
              hasProvider = true;
              existingAccount = accountResult;
            }
          });

          const newAccount = {
            id: account.id,
            nickname: account.nickname,
            source: account.source,
            providerName: account.properties.yodlee['providerName'],
            currentBalance: account.currentBalance,
            lastUpdated: account.properties.yodlee['lastUpdated'],
            visible: account.visible,
            aggregationState: account.aggregationState,
          };

          this.log(hasProvider, providerName, newAccount);

          if (hasProvider) {
            existingAccount.accounts.push(newAccount);
          } else {
            this.connectedAccounts.push({ providerName: providerName, accounts: [newAccount] });
          }
          this.log('Updating connectedAccounts', this.connectedAccounts);
        }
        // } else {

        //   const newAccount = {
        //     id: account.id,
        //     nickname: account.nickname,
        //     source: account.source,
        //     providerName: 'User',
        //     currentBalance: account.currentBalance,
        //     visible: account.visible,
        //     aggregationState: account.aggregationState,
        //   };
        //   this.userAccounts.push(newAccount);
        // }

      });
      this.log('userAccounts', this.userAccounts);
      this.log('connectedAccounts', this.connectedAccounts);
      this.gotAccounts = true;
      if (this.connectedAccounts.length > 0) {
        this.checkUnmatchedAccounts();
      }
    });

  }

  ngOnDestroy() {
    this.uiService.sideNavIsVisible.next(false);
    this.destroy.next(); this.destroy.complete();
  }

  getYodleeStatus() {
    this.moneyService.getYodleeProfile().pipe(takeUntil(this.destroy))
      .subscribe((result) => {
        this.log('getYodleeStatus', result);
        if (result.id) {
          this.yodleeConnection = true;
        }
      });
  }

  manageAccounts() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/accounts/manage-accounts']));
  }

  onReturnClick() {
    this.router.navigate(['/accounts/']);
  }

  onAccountClick() {
    this.router.navigate(['/accounts/']);
  }


  showOptions(checkboxEvent: MatCheckboxChange): void {
    console.log(checkboxEvent);
    this.openRemovePropertyConfirmationDialog(checkboxEvent.source.value, !checkboxEvent.checked, checkboxEvent);
  }

  openRemovePropertyConfirmationDialog(accountId: string, visible: boolean, checkboxEvent: MatCheckboxChange) {
    this.log('Delete dialog opened', accountId, visible);

    let title = 'Remove from budget';
    let copy = 'Are you sure you want to hide this account from the budget? It will not show up in position or projection calculations.';
    let action = 'remove';

    if (!visible) {
      title = 'Include in budget';
      copy = 'Are you sure you want to include this account in the budget?';
      action = 'include';
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: title,
        copy: copy,
        buttonMessage: 'Yes, ' + action + ' account',
        type: 'Account',
        showCancel: true,

      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      this.log('Remove account dialog closed', result, checkboxEvent, visible);
      if (result && result[0] === 'accept') {
        // do account
        checkboxEvent.source.checked = !visible;
        this.accountVisibilityChange(accountId, !visible);
      } else {
        // setback
        checkboxEvent.source.checked = visible;
        this.log('checkboxEvent', checkboxEvent, visible);
      }
      this.cdr.detectChanges();
    });
  }

  accountVisibilityChange(accountId, visible) {
    this.log('visibility change', accountId, visible);
    const account: MoneyAccount = {
      visible: visible
    };
    this.moneyService.updateAccount(accountId, account).subscribe(result => {
      this.log(result);
    });

  }


  openLink() {
    window.open('https://about.rollitwealth.com/en/articles/5264412-account-connections-terms-of-use', "_blank");

  }

  checkUnmatchedAccounts() {
    this.log('Check for unamtched accounts');
    this.dashboardService.getPendingAccounts().subscribe(res => {
      if (res.length > 0) {
        this.openUnmatchedAccountsConfirmationDialog();
      }
    });
  }

  openUnmatchedAccountsConfirmationDialog() {
    this.log('Unamtched accounts dialog opened');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px', data: {
        title: 'Linked accounts',
        copy: 'Your new linked account requires attention',
        buttonMessage: 'Review now',
        type: 'Account',
        showCancel: true,
        cancelMessage: 'Later',
        showButtonTick: false,
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy)).subscribe(result => {
      console.log('Remove account dialog closed', result);
      if (result) {
        if (result[0] === 'accept') {
          this.log('start unmatched accounts survey')
          this.router.navigate(['accounts', 'incoming-accounts'], { skipLocationChange: true });
        }
      }

    });
  }

}
