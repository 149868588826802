import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpParameterCodec } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { ResultList } from '../model/result';
import { APP_ENVIRONMENT } from '@rollit/shared';
import { LoggerService } from '../other/logger.service';
import { map } from 'rxjs/operators';
import { YodleeProfile, Balances, MoneyAccount, AccountMovement, Spending, AccountSource, Transaction, TransactionCategory, Budget, Period, TimeIntervalTable, BudgetProgress, Goal, ProgressSummary } from '../model/money';
import { Moment } from 'moment';
import * as moment_ from 'moment';
const moment = moment_;

/**
 * Services for money tracking.  Includes budgets, money accounts, transactions.
 */

const TRACE = false;


export class MyHttpParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

/**
 * Service to fetch money account information (data source via Moneytree).
 */
@Injectable()
export class MoneyService {
  log: any;
  apiUrl: string;

  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private http: HttpClient,
    private logger: LoggerService,
  ) {
    this.log = this.logger.info('moneyService');
    this.apiUrl = this.environment.apiUrl;
  }

  /**
   * Get the user's Yodlee profile, if connected
   */
  public getYodleeProfile(): Observable<YodleeProfile> {
    const path = '/me/money/yodlee/profile';
    return this.http.get<YodleeProfile>(this.apiUrl + path);
  }

  /**
   * Disconnect user from Yodlee.
   * 
   * @returns 
   */
  public removeYodleeProfile(): Observable<void> {
    const path = '/me/money/yodlee/profile';
    return this.http.delete<void>(this.apiUrl + path);
  }

  /**
   * Set the expiry date for the Yodlee connection.
   * 
   * @param expiry 
   * @returns 
   */
  public setYodleeExpiry(expiry: Moment): Observable<void> {
    const path = '/me/money/yodlee/expiry';
    return this.http.put<void>(this.apiUrl + path, expiry);
  }

  /**
   * Get balances of assets and borrowings
   */
  public getBalances(version?: string): Observable<Balances> {
    const path = '/me/money/balances';
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (version) {
      params = params.set('v', version);
    }

    return this.http.get<Balances>(this.apiUrl + path, { params });
  }

  /**
   * Get net movements in assets.
   */
  public getMovement(from: Moment, to: Moment, maxTransactions: number = 3): Observable<AccountMovement> {
    const path = '/me/money/movement';
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params = params.set('from', from.toISOString(true));
    }
    if (to) {
      params = params.set('to', to.toISOString(true));
    }
    params = params.set('maxTransactions', maxTransactions.toString());

    return this.http.get<AccountMovement>(this.apiUrl + path, { params: params });
  }

  /**
   * Get total and categorised spending.
   */
  public getSpending(from: Moment, to: Moment): Observable<Spending> {
    const path = '/me/money/spending';
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params = params.set('from', from.toISOString(true));
    }
    if (to) {
      params = params.set('to', to.toISOString(true));
    }
    return this.http.get<Spending>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch projections.
   * @param category The category to get projections for, or null for overall projections
   * @param from The moment to take projections from.
   * @param period The interval of time between projections
   * @param numPeriods The number of projection points into the future to return
   */
  public getProjections(category: string, from: Moment = null, period: string = null, numPeriods: number = 6): Observable<TimeIntervalTable> {
    const path = category ? '/me/money/projection/' + category : '/me/money/projection';
    if (!from) {
      from = moment().startOf('month');
    }
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params = params.set('from', from.toISOString(true));
    }
    if (numPeriods) {
      params = params.set('num', numPeriods.toString());
    }
    if (period) {
      params = params.set('period', period)
    }
    return this.http.get<any>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch projections for an account.
   * 
   * @param accountId 
   * @param from 
   * @param period 
   * @param numPeriods 
   */
  public getAccountProjections(accountId: number, from: Moment, period: string = null, numPeriods: number = 6): Observable<TimeIntervalTable> {
    const path = '/me/money/projection/account/' + accountId;
    if (!from) {
      from = moment().startOf('month');
    }
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params = params.set('from', from.toISOString(true));
    }
    if (numPeriods) {
      params = params.set('num', numPeriods.toString());
    }
    if (period) {
      params = params.set('period', period);
    }

    return this.http.get<TimeIntervalTable>(this.apiUrl + path, { params: params });
  }

  /**
   * Sync account data between the rollit web service and Yodlee, pulling new information from moneytree.
   */
  public refreshSourceData(): Observable<any> {
    const path = '/me/money/refresh';
    return this.http.get<any>(this.apiUrl + path);
  }

  /**
   * Fetch a list of user accounts
   *
   * @param source (Optional) source of the accounts to be returned.
   */
  public getAccounts(args?: { source?: AccountSource, type?: string | string[] }): Observable<MoneyAccount[]> {
    const path = '/me/money/account';
    let params = new HttpParams();
    if (args) {
      if (args.source) {
        params = params.set('source', args.source);
      }
      if (args.type) {
        if (args.type instanceof Array) {
          const arr = args.type; // as string[];
          arr.forEach(type => {
            params = params.append('type', type);
          });
        } else {
          params = params.set('type', args.type);
        }
      }
    }

    return this.http.get<Array<MoneyAccount>>(this.apiUrl + path, { params: params });
  }

  public getSourceAccount(source: AccountSource, sourceAccountId: string): Observable<MoneyAccount> {
    const path = '/me/money/account';

    let params = new HttpParams();
    params = params.set('source', source);
    params = params.set('sourceAccountId', '' + sourceAccountId);

    return this.http.get<MoneyAccount[]>(this.apiUrl + path, { params: params }).pipe(
      map(accounts => {
        if (accounts && accounts.length > 0) {
          return accounts[0];
        }
        throwError({ message: 'Not found' });
      })
    );
  }

  /**
   * Fetch accounts with aggregation state of "pending".
   */
   public getPendingAccounts(): Observable<MoneyAccount[]> {
    return this.getAccounts({source: AccountSource.yodlee}).pipe(
      map(res => res.filter(v => v.aggregationState == 'pending'))
    )
  }


  /**
   * Create a new user's money account.
   */
  public createAccount(account: MoneyAccount): Observable<MoneyAccount> {
    const path = '/money/account';
    return this.http.post<MoneyAccount>(this.apiUrl + path, account);
  }

  /**
   * Update a money account details.
   */
  public updateAccount(accountId: number, account: MoneyAccount): Observable<MoneyAccount> {
    const path = '/money/account/' + accountId;
    return this.http.put<MoneyAccount>(this.apiUrl + path, account);
  }

  /**
   * Fetch an account details.
   */
  public getAccount(accountId: number): Observable<MoneyAccount> {
    const path = '/money/account/' + accountId;
    return this.http.get<MoneyAccount>(this.apiUrl + path);
  }

  /**
   * Path the identified account with pending Yodlee account.
   * 
   * This will copy details to the account and nullify the pending yodlee account.
   * @param accountId 
   * @param data 
   * @returns 
   */
  public patchAccount(accountId: number, data: any): Observable<MoneyAccount> {
    const path = '/money/account/' + accountId;
    return this.http.patch<MoneyAccount>(this.apiUrl + path, data);
  }

  public removeAccount(accountId: number, removeRelated: boolean = false): Observable<void> {
    const path = '/money/account/' + accountId;
    let params = new HttpParams();
    if (removeRelated) {
      params = params.set('deleteRelated', '' + removeRelated);
    }
    return this.http.delete<void>(this.apiUrl + path, { params });
  }

  /**
   * Update an account balance on a user account.
   */
  public updateAccountBalance(accountId: number, balance: number): Observable<MoneyAccount> {
    const path = '/money/account/' + accountId;
    const account: MoneyAccount = { id: accountId, currentBalance: balance };
    return this.http.put<MoneyAccount>(this.apiUrl + path, account);
  }

  /**
   * Fetch the net movement for an account.
   * 
   * @param accountId 
   * @param from 
   * @param to 
   * @returns 
   */
  public getAccountMovement(accountId: number, from?: Moment, to?: Moment): Observable<number> {
    const path = '/money/account/' + accountId + '/movement';

    if (!from) {
      from = moment().startOf('day').subtract(1, 'month');
    }
    if (!to) {
      to = from.add(1, 'month');
    }

    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params = params.set('from', from.toISOString(true));
    }
    if (from) {
      params = params.set('to', to.toISOString(true));
    }

    return this.http.get<number>(this.apiUrl + path, { params });
  }


  /**
   * Fetch balances for a user account
   */
  public getTransactions(
    accountId: number,
    filter: {
      categorySlug?: string,
      categoryId?: number,    // the source ID
      from?: Moment,
      to?: Moment
    },
    offset?: number,
    max?: number
  ): Observable<ResultList<Transaction>> {
    let path: string;
    let params = new HttpParams();
    if (accountId != null) {
      path = '/money/account/' + accountId + '/transactions';   // get transactions for account
    } else {
      path = '/me/money/transaction';   // get all my transactions
    }
    if (filter) {
      if (filter.categorySlug) {
        params = params.set('category', filter.categorySlug);
      }
      if (filter.categoryId) {
        params = params.set('categoryId', ''+filter.categoryId);
      }
      if (filter.from) {
        params = params.set('from', filter.from.toISOString(true));
      }
      if (filter.to) {
        params = params.set('to', filter.to.toISOString(true));
      }
    }
    if (offset) {
      params = params.set('offset', '' + offset);
    }
    if (max) {
      params = params.set('max', '' + max);
    }
    return this.http.get<ResultList<Transaction>>(this.apiUrl + path, { params });
  }

  /**
   * Note: only edit transactions for user-defined accounts.  Look at the source of the account data.
   */
  public addTransaction(accountId: number, transaction: Transaction): Observable<Transaction> {
    const path = '/money/account/' + accountId + '/transactions';
    return this.http.post<Transaction>(this.apiUrl + path, transaction);
  }

  /**
   * Update a transaction.
   * 
   * @param transactionId 
   * @param transaction 
   * @returns 
   */
  public updateTransaction(transactionId: number, transaction: Transaction): Observable<Transaction> {
    const path = '/money/account/' + transaction.account.id + '/transactions/' + transactionId;
    return this.http.put<Transaction>(this.apiUrl + path, transaction);
  }

  /**
   * Fetch a single transaction.
   * 
   * @param accountId 
   * @param transactionId 
   * @returns 
   */
  public getTransaction(accountId: number, transactionId: number): Observable<Transaction> {
    const path = '/money/account/' + accountId + '/transactions/' + transactionId;
    return this.http.get<Transaction>(this.apiUrl + path);
  }

  /**
   * Fetch transactoin categories.
   * 
   * @returns 
   */
  public getCategories(): Observable<Array<TransactionCategory>> {
    const path = '/me/money/categories';
    return this.http.get<Array<TransactionCategory>>(this.apiUrl + path);
  }

  /**
   * Set the list of images for this account.
   * 
   * @param accountId 
   * @param files 
   */
  public setAccountImages(accountId: number, files: File[]): Observable<File[]> {
    const path = '/money/account/' + accountId + '/images';
    return this.http.put<File[]>(this.apiUrl + path, files);
  }

  public getAccountImages(accountId: number): Observable<File[]> {
    const path = '/money/account/' + accountId + '/images';
    return this.http.get<File[]>(this.apiUrl + path);
  }

  /**
   * Fetch spending budgets
   */
  public getBudgets(category: string = null, period: Period = Period.month): Observable<Budget[]> {
    const path = '/me/money/budget';
    let params = new HttpParams().append('period', period.toString());
    if (category) {
      params = params.append('category', category);
    }

    return this.http.get<Budget[]>(this.apiUrl + path, { params });
  }

  /**
   * Get spending budget for the given period.
   * @param period
   */
  public getBudget(category: string, period: Period): Observable<Budget> {
    if (!period) {
      return of({});
    }
    const path = '/me/money/budget/' + category + '/' + period.toString();
    return this.http.get<Budget>(this.apiUrl + path);
  }

  /**
   * Set the spending budget for the given period
   * @param period 
   * @param budget 
   */
  public setBudget(category: string, period: Period, budget: Budget): Observable<Budget> {
    const path = '/me/money/budget/' + category + '/' + period.toString();
    return this.http.put<Budget>(this.apiUrl + path, budget);
  }

  public getBudgetProgress(from: Moment = null, period: Period = Period.month): Observable<BudgetProgress> {
    console.log('getting budget progress');
    if (!from) { from = moment().startOf('month'); }
    const path = '/me/money/budget/progress';
    const params = new HttpParams({ encoder: new MyHttpParameterCodec() })
      .set('from', from.toISOString(true))
      .set('period', period.toString());

    return this.http.get<BudgetProgress>(this.apiUrl + path, { params });
  }

  /**
   * Fetch a user's goals.
   * @param categories (optional) list of categories to filter on
   * @returns 
   */
  public getGoals(categories?: string[], offset: number = 0, max: number = 100): Observable<ResultList<Goal>> {
    const path = '/me/money/goal';
    let params = new HttpParams().set('offset', offset.toString()).set('max', max.toString());
    if (categories) {
      categories.forEach(category => {
        params = params.append('category', category);
      });
    }

    return this.http.get<ResultList<Goal>>(this.apiUrl + path, { params: params });
  }

  /**
   * Fetch a goal.
   * @param id 
   * @returns 
   */
  public getGoal(id: number): Observable<Goal> {
    const path = '/goal/' + id;
    return this.http.get<Goal>(this.apiUrl + path);
  }

  /**
   * Delete a goal
   * @param id 
   * @returns 
   */
  public deleteGoal(id: number): Observable<void> {
    const path = '/goal/' + id;
    return this.http.delete<void>(this.apiUrl + path);
  }

  public getGoalProjection(goalId: number, from: Moment, period: string = null, numPeriods: number = 10): Observable<TimeIntervalTable> {
    const path = '/goal/' + goalId + '/projection';
    let params = new HttpParams({ encoder: new MyHttpParameterCodec() });
    if (from) {
      params = params.set('from', from.toISOString(true));
    }
    if (numPeriods) {
      params = params.set('num', numPeriods.toString());
    }
    // TODO add query params from function params

    return this.http.get<TimeIntervalTable>(this.apiUrl + path, { params: params });
  }

  public getProgressSummary(): Observable<ProgressSummary[]> {
    const path = '/me/money/progress';

    return this.http.get<ProgressSummary[]>(this.apiUrl + path);
  }

}
