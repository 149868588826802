import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, LoggerService } from '@rollit/shared/data';
import { UIManagerService } from '@rollit/shared/services';

@Component({
  selector: 'lib-report-home-situation',
  templateUrl: './home-situation.component.html',
  styleUrls: ['./home-situation.component.scss']
})
export class HomeSituationComponent implements OnInit, OnDestroy {
  log: any;
  @Input() useQuery: boolean;
  @Input() assetsHomeOwnership: string;
  assetsHomeOwnershipResult: string;
  assetsHomeOwnershipPercentage: string;

  @Input() assetsHomeOwnershipAgePercentage: string;
  assetsHomeOwnershipAgePercentageResult: string;

  @Input() assetsHomeOwnershipStatePercentage: string;
  assetsHomeOwnershipStatePercentageResult: string;

  @Input() profileAgeRange: string;
  @Input() profileAddressState: string;

  @Input() reportText: any;

  constructor(private logger: LoggerService, private uiService: UIManagerService, private route: ActivatedRoute, private dialogService: DialogService) {
    this.log = this.logger.info('HomeSituationComponent');
    this.uiService.displayContentAsFullPage.next(true);
  }

  ngOnInit(): void {
    this.log('HomeSituationComponent useQuery', this.useQuery);
    if (this.useQuery === false) {
      this.log('HomeSituationComponent assetsHomeOwnership', this.assetsHomeOwnership);
      this.log('HomeSituationComponent assetsHomeOwnershipAgePercentage', this.assetsHomeOwnershipAgePercentage);
      this.log('HomeSituationComponent assetsHomeOwnershipStatePercentage', this.assetsHomeOwnershipStatePercentage);
      this.log('HomeSituationComponent profileAgeRange', this.profileAgeRange);
      this.log('HomeSituationComponent profileAddressState', this.profileAddressState);
      this.setupData();

    } else {
      this.route.queryParams.subscribe(params => {
        this.log('params', params);
        this.assetsHomeOwnership = params['assets_home_ownership'];
        this.assetsHomeOwnershipAgePercentage = params['assets_home_ownership_age_percentage'];
        this.assetsHomeOwnershipStatePercentage = params['assets_home_ownership_state_percentage'];
        this.profileAgeRange = params['profile_age_range'];
        this.profileAddressState = params['profile_address_state'];
        this.dialogService.getReportText().subscribe(data => {
          this.reportText = data;
          this.log('getReportText', data);
          this.setupData();
        });

      });
    }

  }

  ngOnDestroy() {
    this.uiService.displayContentAsFullPage.next(false);
  }

  setupData() {
    this.assetsHomeOwnershipResult = this.reportText.home_situation.assets_home_ownership[this.assetsHomeOwnership];
    var ageText = this.profileAgeRange;
    if (ageText === 'Under 34') {
      ageText = ageText.toLowerCase();
    } else if (ageText === '65 and over') {
      ageText = ageText.toLowerCase();
    } else {
      ageText = 'between ' + ageText;
    }
    // this.log(ageText, this.profileAgeRange);

    this.assetsHomeOwnershipAgePercentageResult =
      this.reportText.home_situation.assets_home_ownership_age_percentage[this.assetsHomeOwnership]
        .replace("[home_ownership_age_percentage]", Math.round(+this.assetsHomeOwnershipAgePercentage * 100) + '%')
        .replace("[age_range]", ageText);

    this.assetsHomeOwnershipStatePercentageResult =
      this.reportText.home_situation.assets_home_ownership_state_percentage[this.assetsHomeOwnership]
        .replace("[state]", this.profileAddressState)
        .replace("[home_ownership_state_percentage]", Math.round(+this.assetsHomeOwnershipStatePercentage * 100) + '%');

    switch (this.assetsHomeOwnership) {
      case 'renting': {
        this.assetsHomeOwnershipPercentage = '100';
        break;
      }
      case 'own_with_mortgage': {
        this.assetsHomeOwnershipPercentage = '50';
        break;
      }
      case 'own_no_mortgage': {
        this.assetsHomeOwnershipPercentage = '0';
        break;
      }
    }
  }
}